import React from 'react'
// import { useCommonStore } from '../../state/commonStore'
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import WrmIcon from '../WrmIcon'
import resizeImage from '../../utils/imageResize'
import { greekToEnglish } from '../../utils/helpers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadinputPrimary: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0, 
      width: '250%', 
      opacity: 0,
      height: '2.5rem',
    },
    uploadbtnPrimary: {
      height: '2.5rem',
      overflow: 'hidden',
      textTransform: 'none',
      fontSize: '0.875rem',
      fontWeight: 'normal',
      /**in case you need fixed width for the uploadbtn set the width to be the same as uploadinputPrimary's width  */
      width: theme.spacing(15),
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0.25rem 0.25rem 0.25rem 0',
      },
    },
  })
)
const ALL_ACCEPTED_EXTENSIONS = '.pdf, .doc, .docx, .txt, .jpg, .jpeg, .png, .zip'

interface AttachmentBtnHDRProps {
  attachments: any
  setAttachments: React.Dispatch<any>
  loading?: boolean
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  sizeLimitImg?: number
  sizeLimitDoc?: number
  acceptedFileExtensions?: string
  disabled?: boolean
  showAlert: (message: any, severity?: any) => void
}
/**This is a copy and stripped down version of AttachmentBtnHDR */
const AttachmentBtnHDR: React.FC<AttachmentBtnHDRProps> = ({
  attachments,
  setAttachments,
  loading = false,
  setLoading,
  sizeLimitImg = 10,
  sizeLimitDoc = 1,
  acceptedFileExtensions = ALL_ACCEPTED_EXTENSIONS,
  disabled = false,
  showAlert,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // const showAlert = useCommonStore(state => state.showAlert)

  const handleFileChange = e => {
    let files = Array.from(e.target.files)
    if (files && files.length > 0) {
      let file = e.target.files[0]
      let filetype = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      let reader = new FileReader()
      reader.onload = async (event: any) => {
        let resizeThreshold = 1.5
        let resizedImage
        if (event !== undefined && event.target !== undefined) {
          let data = event.target.result
          if (['jpg', 'jpeg', 'png'].includes(filetype)) {
            let sizeLimit = sizeLimitImg //size limit in MBs
            if (file.size > sizeLimit * 1024 * 1024) {
              showAlert(`${t('cp.report.imageSizeLimit.warning')} ${sizeLimit} MB`, 'E')
              !!setLoading && setLoading(false)
              return
            }
            if (file.size > 1024 * 1024 * resizeThreshold) {
              resizedImage = await resizeImage(data, filetype)
            }
          } else if (['bin'].includes(filetype)) {
            let sizeLimit = 2 //size limit in MBs
            if (file.size > sizeLimit * 1024 * 1024) {
              showAlert(`${t('cp.report.fileSizeLimit.warning')} ${sizeLimit} MB`, 'E')
              !!setLoading && setLoading(false)
              return
            }
          } else {
            let sizeLimit = sizeLimitDoc //size limit in MBs
            if (file.size > sizeLimit * 1024 * 1024) {
              showAlert(`${t('cp.report.fileSizeLimit.warning')} ${sizeLimit} MB`, 'E')
              !!setLoading && setLoading(false)
              return
            }
          }
          if (filetype !== 'txt') {
            data = JSON.stringify(Array.from(new Uint8Array(data)))
            if (file.size > 1024 * 1024 * resizeThreshold) {
              const dataTemp = await resizedImage.arrayBuffer()
              data = JSON.stringify(Array.from(new Uint8Array(dataTemp)))
            }
          }
          if (attachments.findIndex(a => greekToEnglish(a.filename) === greekToEnglish(file.name)) === -1) {
            //do not add a file with the same filename
            let attachment = {
              filename: file.name,
              data: data,
              type: filetype,
            }
            let currentAttachments = [...attachments]
            currentAttachments.push(attachment)
            setAttachments(currentAttachments)
          } else {
            showAlert(t("irriForm.attachments.duplicate.error", {val:greekToEnglish(file.name)}), 'W')
          }
          !!setLoading && setLoading(false)
        }
      }
      !!setLoading && setLoading(true)
      if (filetype === 'txt') {
        reader.readAsText(file, 'utf-8')
      } else {
        reader.readAsArrayBuffer(file)
      }
    }
  }

  return (
    <Button
      className={classes.uploadbtnPrimary}
      disabled={loading || disabled}
      startIcon={<WrmIcon icon='attach' style={{ width: 16, height: 16 }} />}>
      {t('btn.attach')}
      <input
        type='file'
        className={classes.uploadinputPrimary}
        multiple={false}
        onChange={handleFileChange}
        accept={acceptedFileExtensions}
      />
    </Button>
  )
}

export default AttachmentBtnHDR
