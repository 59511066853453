const IssueReportService = (apiClient?) => {
    const VALIDATION_ERROR_CODE = 2
    // const VALIDATION_ERROR_MESSAGE_REQUIRED = "cannotBeEmpty"
    const VALIDATION_ERROR_MESSAGE_REQUIRED = "is not allowed to be empty"
    const VALIDATION_ERROR_MESSAGE_REQUIRED_OBJECT = "must be of type object"
    const path = 'issueReports/'

    const getIssueReports = (tenant?, userId?) => {
      let fullpath = path
      let isFirstParam = true
      // console.log(`getIssueReports tenant = ${tenant}, userId  = ${userId}`)

      if(!!tenant){
        fullpath = `${fullpath}${isFirstParam ? '?' : '&'}tenant=${tenant}`
        isFirstParam = false
      }
      if(userId !== undefined){
        fullpath = `${fullpath}${isFirstParam ? '?' : '&'}createdBy=${userId}`
        isFirstParam = false
      }

      let cachePromise = true
      return apiClient.sendGetRequest(cachePromise, fullpath, null, true);//axios.get(API_URL, options);
    }
    const getIssueReportById = (issue_id) => {
      let fullpath = `${path}${issue_id}`
      let cachePromise = false
      return apiClient.sendGetRequest(cachePromise, fullpath, null, true);//axios.get(API_URL, options);
    }
    const postIssueReport = (data) => {
      return apiClient.sendRequest(data, false, path, null, true)//axios.post(API_URL, data, options);
    }
    const putIssueReport = (issue_id, data) => {
      let fullpath = `${path}${issue_id}`
      return apiClient.sendPutRequest(data, fullpath, null, true)//axios.post(API_URL, data, options);
    }
    const postIssueReportMessage = (issue_id, data) => {
      let fullpath = `${path}${issue_id}/message`
      return apiClient.sendRequest(data, false, fullpath, null, true)//axios.post(API_URL, data, options);
    }
    return({
      getIssueReports: getIssueReports,
      postIssueReport: postIssueReport,
      putIssueReport: putIssueReport,
      postIssueReportMessage: postIssueReportMessage,
      getIssueReportById: getIssueReportById,
      VALIDATION_ERROR_CODE: VALIDATION_ERROR_CODE,
      VALIDATION_ERROR_MESSAGE_REQUIRED:VALIDATION_ERROR_MESSAGE_REQUIRED,
      VALIDATION_ERROR_MESSAGE_REQUIRED_OBJECT:VALIDATION_ERROR_MESSAGE_REQUIRED_OBJECT
    })

}

export default IssueReportService
