import { useCommonStore } from '../state/commonStore'
import TemplateService from './TemplateService'

const IrriFormService = () => {

  const templateService: any = TemplateService('irriForm')

  const apiClient = useCommonStore.getState().apiClient
  const path = 'irriForm/'

  /**
   * Fetches irri forms for the provided array of cultivationPeriods
   * @param periods array of agriculturalPeriod.cultivationPeriod
   */
  const getIrriFormsPerAggiPeriodSummary = (periods: number[]) => {
    let data: any = {
      // operation: 'getIrriFormsPerAggiPeriodSummary',
      operation: 'getIrriForms',
      filter: {'cultivationPeriod': {$in: periods}}
    }
    return sendRequest(data)
  }

  const sendRequest = (data: any) => {
    let cachePromise = true
    let baseUrl = null
    let withToken = true
    return apiClient.sendRequest(data, cachePromise, path, baseUrl, withToken)
    // return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getIrriFormsPerAggiPeriodSummary: getIrriFormsPerAggiPeriodSummary,
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
    saveEntityAttachment: templateService.saveEntityAttachment,
    deleteEntityAttachment: templateService.deleteEntityAttachment
  })

}

export default IrriFormService
