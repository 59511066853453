import React, { useState, useEffect } from 'react'
import { useCollectionStore } from '../../state/collectionStore'
import { useCommonStore } from '../../state/commonStore'
import MeasurementsManager from '../../services/MeasurementsManager'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'

import MasterDetail from '../masterdetail/MasterDetail'
import WrmIcon from '../WrmIcon'
import MeasurementsFilter from './MeasurementsFilter'
import MeasurementsGraph from './MeasurementsGraph'

const API_URL = process.env.REACT_APP_WRM_API_URL
const MAX_GRAPH_DATA_LIMIT = 5000

const useStyles = makeStyles(theme => ({
  msrroot: {
  },
  tabsRoot: {
    minHeight: 'inherit',
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.palette.tertiary.dark,
    minWidth: 90,
    maxWidth: 200,
    minHeight: 'inherit',
  },
  tabLabelSelected: {
    color: theme.palette.primary.dark,
  },
  filtersBtn: {
    textTransform: 'none',
    minWidth: 105,
    fontSize: '0.875rem',
    fontWeight: 'normal',
  },
  filterIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  filterIconActive: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  graphBtn: {
    textTransform: 'none',
    minWidth: 105,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  graphIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  graphIconActive: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: 'white',
  },
  circular: {
    color: theme.palette.primary.main,
  },
  circularRed: {
    color: 'red',
  },
}))

const HydoorMeasurementsMd = (props) => {
  const measurementsManager = MeasurementsManager()
  const showAlert = useCommonStore(state => state.showAlert)
  useEffect(() => {
    // console.log(`useEffect series`)
    if (!!props.series && !!props.feature) {
      // console.log(`props.feature.properties.code ${props.feature.properties.code} props.series.length ${props.series.length} props.series[0].featureCode ${props.series.length > 0 ? props.series[0].featureCode : ''}`)

      if (!props.series.every(s => s.measurements)) {
        setLoadingMeasurements(true)
      } else {
        if (loadingMeasurements) setLoadingMeasurements(false)

        if (props.series.length === 0 || (props.series.length > 0 && props.feature.properties.code === props.series[0].featureCode)) {
          if (!!selectedMeasurement && (props.series.length === 0 || (props.series.length > 0 && props.series.findIndex(s => s._id === selectedMeasurement.series) === -1))) {
            setSelectedMeasurement(null)
          }
          prepareTabsData(props.series)
        }

      }

    }
  }, [props.series])
  useEffect(() => {
    // console.log(`useEffect featureType ${props.featureType}`)
    if (!!props.featureType) {
      prepareTabsColumns(props.featureType)
    }
  }, [props.featureType])

  const { t }: { t: any } = useTranslation()
  const classes = useStyles()

  const users = useCollectionStore(state => state.users)

  const getRowLabel = (fieldProcessedValues: any[]) => {
    if (fieldProcessedValues.length === 2) {
      let refDatetime = (typeof fieldProcessedValues[0] === 'string' ? moment(fieldProcessedValues[0], t(`datetime.format`) as string) : moment(fieldProcessedValues[0])).format(t(`datetime.format`) as string)
      return `${refDatetime} - ${fieldProcessedValues[1]}`
    }
    return ''
  }
  const metadata = {
    // title: 'net.entity.plural',
    defaultSortBy: 'refDatetime',
    defaultSortOrder: 'desc',
    rowLabel: getRowLabel,
    rowLabelFields: ['refDatetime', 'value'],
    noFilter: true,
    noAdd: props.noEdit,
    noEdit: props.noEdit,
    noDelete: props.noDelete,
    noDeletionReasonNote: true,
    // checkboxed: true,
    hideDetails: true,
    editOnMaster: true,
    compact: true,
    columns: [
      {code: '_id', label: '_id', type: 'string', hidden: true, isId: true},
      {code: 'series', label: 'series', type: 'string', hidden: true,},
    ],
  }
  const BooleanValues = [
    {code: true, label: t('btn.yes')},
    {code: false, label: t('btn.no')},
  ]
  const OnOffValues = [
    {code: 1, label: t('btn.on')},
    {code: 0, label: t('btn.off')},
  ]

  const prepareCol = (vt, mTypeCode, readOnly?) => {
    let col: any = { code: vt.fieldname, label: t(`msr.fld.${mTypeCode}.${vt.fieldname}`, t(`msr.fld.${vt.fieldname}`, vt.label)) }

    // if (!!vt.default) col.initialEditValue = vt.default
    // if (col.code === 'refDatetime') col.isId = true
    col.editable = !readOnly
    col.searchable = true
    col.filterable = true
    col.required = !vt.isOptional
    switch (vt.datatype) {
      case 'Number':
        col.type = 'number'
        col.format = '9.9'
        if (vt.lookup === 'OnOffValues') {
          col.lookup = ['OnOffValues']
          col.format = ''
        }
        break;
      case 'Date':
        col.type = 'date'
        col.disableFuture = !!vt.disableFuture
        break;
      case 'Datetime':
        col.type = 'datetime'
        col.disableFuture = !!vt.disableFuture
        break;
      case 'Time':
        col.type = 'datetime'
        col.disableFuture = !!vt.disableFuture
        break;
      case 'Boolean':
        col.type = 'boolean'
        col.lookup = ['BooleanValues']
        break;
      case 'Link':
        col.type = 'link'
        col.editable = false
        col.searchable = false
        col.filterable = false
        col.required = false
        break;
      default:
        col.type = 'string'
        break;
    }
    return col
  }
  const prepareTabsColumns = (featureType) => {
    // console.log(`prepareColumns ${featureType}`)
    if (!featureType) return
    let tabinfos: any[] = []
    let tabcolumns = {}
    let featureMeasurementTypes = props.measurementTypes.filter(mt => mt.featureType === props.featureType)
    featureMeasurementTypes
    .forEach(mType => {
      let useGroupMeta = false
      if (!!mType.group) {
        useGroupMeta = featureMeasurementTypes.findIndex(mt => mt.group === mType.group && mt._id !== mType._id) > -1
      }
      if (useGroupMeta) {
        let gType = props.measurementTypes.find(gt => gt.code === mType.group)
        if (tabinfos.findIndex(ti => ti.code === gType.code) < 0) {
          let columns:any;
          if (gType.code === "switchHours") {
            let enchancedValueTypes = gType.valueTypes.map(el=>el)
            enchancedValueTypes.push({
              datatype: "String",
              description: "User that added the measurement",
              fieldname: "addedBy",
              label: "personnel.specialty.inspection",
              level: ".",
              readOnly:true,
            })
            let enchangedGType = {...gType, valueTypes: enchancedValueTypes}
            tabinfos.push(enchangedGType)
            columns = enchangedGType.valueTypes.map(vt => {
              return prepareCol(vt, gType.code,vt.readOnly)
            })
          } else {
            tabinfos.push(gType)
            columns = gType.valueTypes.map(vt => {
              return prepareCol(vt, gType.code)
            })
          }
          let mtlookup = {}
          mtlookup[mType.code] = t(`msr.fld.${mType.code}`, mType.label)
          columns.push({
            code: 'measurementType',
            label: t('msr.fld.measurementType', 'Measure'),
            type: 'string',
            lookup: [gType.code],
            editable: 'onAdd',
            searchable: true,
            filterable: true,
            required: true,
          })
          tabcolumns[gType.code] = columns
        }
      } else {
        if (tabinfos.findIndex(ti => ti.code === mType.code) < 0) {
          tabinfos.push(mType)
          let columns = mType.valueTypes.map(vt => {
            return prepareCol(vt, mType.code)
          })
          if (!!mType.computedFields) {
            let computedColumns = mType.computedFields.map(cf => {
              return prepareCol(cf, mType.code, true)
            })
            //add
            columns.push(...computedColumns)
          }
          tabcolumns[mType.code] = columns
        }
      }
    })
    // console.log(`tabcolumns ${JSON.stringify(tabcolumns,null,2)}`)
    // console.log(`tabinfos ${JSON.stringify(tabinfos,null,2)}`)
    if (tabinfos.length > 0) {
      // props.setActiveTab(0)
      setTabsInfo(tabinfos)
      setTabsColumns(tabcolumns)
    }
  }
  const prepareTabsData = (series) => {
    if (!series) return
    // console.log(`prepareData series.length ${series.length}`)
    let tabdata = {}
    let featureMeasurementTypes = props.measurementTypes.filter(mt => mt.featureType === props.featureType)
    series.forEach(s => {
      let mType = featureMeasurementTypes.find(mt => mt.code === s.measurementType.code)
      if (!mType) return
      if (!!s.measurements) {
        s.measurements.forEach(m => {
          let useGroupMeta = !!mType.group
          if (!!mType.group) {
            useGroupMeta = featureMeasurementTypes.findIndex(mt => mt.group === mType.group && mt._id !== mType._id) > -1
          }
          if (useGroupMeta) {
            let gType = props.measurementTypes.find(gt => gt.code === mType.group)
            let rec:any;
            if (gType.code === "switchHours") {
              const user = users?.find(u=>u.user_id === m.addedBy)
              rec = {
                measurementType: mType.code,// or code
                _id: m._id,
                series: m.series,
                addedBy: user ? user.auth0User.given_name + " " + user.auth0User.family_name : m.addedBy,
              }
            } else {
              rec = {
                measurementType: mType.code,// or code
                _id: m._id,
                series: m.series,
              }
            }
            mType.valueTypes.forEach(vt => {
              let val
              if (!vt.level || vt.level === '.') {
                val = m[vt.fieldname]
              } else {
                val = !!m[vt.level] ? m[vt.level][vt.fieldname] : ''
              }
              rec[vt.groupfieldname] = !!val ? val : ''
              if (val === 0 && vt.datatype === 'Number') {
                rec[vt.groupfieldname] = val
              }
            })
            if (!!mType.computedFields) {
              mType.computedFields.forEach(cf => {
                switch (cf.fieldname) {
                  case 'consumption':
                    rec[cf.groupfieldname] = !!m.computedValues ? m.computedValues[cf.fieldName] : 0;
                    break;
                  case 'hasAttachment':
                    if (m[cf.basedOnField1] != null) {
                      rec[cf.groupfieldname] = `${API_URL}attachments/${m[cf.basedOnField1].attachment}`
                    } else {
                      rec[cf.groupfieldname] = ''
                    }
                    break;
                  default:
                    rec[cf.groupfieldname] = !!m.computedValues ? m.computedValues[cf.fieldName] : '';
                }
              })
            }
            if (!tabdata[gType.code]) {
              tabdata[gType.code] = []
            }
            tabdata[gType.code].push(rec)
          } else {
            let rec = {
              _id: m._id,
              series: m.series
            }
            mType.valueTypes.forEach(vt => {
              let val
              if (!vt.level || vt.level === '.') {
                val = m[vt.fieldname]
              } else {
                val = !!m[vt.level] ? m[vt.level][vt.fieldname] : ''
              }
              rec[vt.fieldname] = !!val ? val : ''
              if (val === 0 && vt.datatype === 'Number') {
                rec[vt.fieldname] = val
              }
            })

            if (!!mType.computedFields) {
              mType.computedFields.forEach(cf => {
                switch (cf.fieldname) {
                  case 'consumption':
                    // console.log(`Computed fields found !! ${JSON.stringify(cf)} look in measurement ${JSON.stringify(m)} `)
                    rec[cf.fieldname] = !!m.computedValues ? m.computedValues[cf.fieldname] : 0;
                    break;
                  case 'hasAttachment':
                    if (m[cf.basedOnField1] != null) {
                      rec[cf.fieldname] = `${API_URL}attachments/${m[cf.basedOnField1].attachment}`
                    } else {
                      rec[cf.fieldname] = ''
                    }
                    break;
                  default:
                    rec[cf.fieldname] = !!m.computedValues ? m.computedValues[cf.fieldname] : ''
                }
              })
            }
            if (!tabdata[mType.code]) {
              tabdata[mType.code] = []
            }
            tabdata[mType.code].push(rec)
          }
        })
      }
    }
    )
    // console.log(`tabdata ${JSON.stringify(tabdata,null,2)}`)
    setTabsData(tabdata)
  }
  const prepareData = () => {
    data = !!tabsData && !!tabsInfo && tabsData[tabsInfo[activeTab].code] ? tabsData[tabsInfo[activeTab].code] : []
  }
  const prepareMeta = () => {
    if (!tabsColumns) return
    meta = metadata
    meta.columns = [...meta.columns, ...tabsColumns[tabsInfo[activeTab].code]]
    // meta.noAdd = getNoAdd()
    // console.log(`meta ${JSON.stringify(meta,null,2)}`)
  }

  const [activeTab, setActiveTab] = useState(0)
  const [tabsColumns, setTabsColumns] = useState<any | null>(null)
  const [tabsData, setTabsData] = useState<any | null>(null)
  const [tabsInfo, setTabsInfo] = useState<any | null>(null)
  const [selectedMeasurement, setSelectedMeasurement] = React.useState<any | null>(null)
  const [isMeasurementsFilterOpened, setIsMeasurementsFilterOpened] = React.useState(false)
  const [isGraphDialogOpened, setIsGraphDialogOpened] = React.useState(false)
  const [loadingMeasurements, setLoadingMeasurements] = useState(false)

  let data:any[] = []
  let meta
  prepareMeta()
  prepareData()

  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex)
  }

  useEffect(()=>{
    /* This effect solves the issue where a measurement could be updated once, 
     * but threw an error if tried to edit it for the second time without unselecting it
     * -this bug also appears when creating a new measurment and imidiately editing it without unselecting it
     * Save measurement is triggered from the MasterDetail 
     * so the selectedMeasurement is populated by the default response of the MeasurementsManager.actSaveMeasurement
     * which is a raw measurement which lacks the measurementType property
     * so as when the selectedMeasurement by the save measurement and the component re-renders
     * and updates the tabsData the selectedMeasurement(raw measurement) is replaced by the 'enchanced' measurement */
    if (!!selectedMeasurement && !!tabsData && !!tabsInfo) {
      const updatedMeasurement = tabsData[tabsInfo[activeTab].code]?.find(el=>{
        return el._id === selectedMeasurement._id
      })
      if (!!updatedMeasurement && updatedMeasurement !== -1) {
        setSelectedMeasurement(updatedMeasurement)
      }
    }
  },[selectedMeasurement])

  const getMeasurementTypeId = (mlean) =>{
    let mType = tabsInfo[activeTab]
    if (!!mlean.measurementType) {//this is actually a group
      mType = props.measurementTypes.find(mt => mt.code === mlean.measurementType)
    }
    console.log(`mType ${JSON.stringify(mType,null,2)}`)
    if (mType.isGroup) {//this should not be a group, otherwise something went wrong
      return null
    }
    return mType._id
  }
  const doSaveMeasurement = (mlean) => {
    // console.log(`doSaveMeasurement mlean ${JSON.stringify(mlean,null,2)}`)
    let measurement = buildMeasurement(mlean)
    if (!measurement) {
      return Promise.reject('Could not build measurement')
    } else if (!!measurement.series) {
      return props.saveMeasurement(measurement)
    } else {
      return props.saveMeasurement(measurement, getMeasurementTypeId(mlean))
    }
    // console.log(`tabdata ${JSON.stringify(meas,null,2)}`)
  }
  const doDeleteMeasurement = (mlean) => {
    // console.log(`tabdata ${JSON.stringify(mlean,null,2)}`)
    // let meas = buildMeasurement(mlean)
    // console.log(`tabdata ${JSON.stringify(meas,null,2)}`)
    let series = props.series.find(s => s._id === mlean.series)
    return props.deleteMeasurement(series.measurements.find(m => m._id === mlean._id))
  }

  const cloneMeasurement = (measurement) => {
    let clone = { ...measurement }
    clone.extraValues = { ...measurement.extraValues }
    return clone
  }
  const buildMeasurement = (mlean) => {
    console.log(`buildMeasurement mlean ${JSON.stringify(mlean, null, 2)}`)
    let measurement: any
    let isNewRow = !mlean._id
    let mType = !mlean.measurementType ? tabsInfo[activeTab] : props.measurementTypes.find(mt => mt.code === mlean.measurementType)
    if (mType.isGroup) {//this should not be a group, otherwise something went wrong
      showAlert(`${t('error.message')} : Cannot identify measurement type`, 'E')
      return null
    }
    let series = props.series.find(s => !!mlean.series ? s._id === mlean.series : s.measurementType.code === mType.code)
    let seriesId = !!series ? series._id : null
    if (isNewRow) {
      measurement = {
        series: seriesId,
        notes: [],
        attachmentMetadata: {}
      }
    } else {
      measurement = cloneMeasurement(series.measurements.find(m => m._id === mlean._id))
    }
    //console.log(`mType ${JSON.stringify(mType, null, 2)}`)
    mType.valueTypes.forEach(vt => {
      let name = !!mType.group ? vt.groupfieldname : vt.fieldname
      let value = mlean[name]
      if (!value && !(vt.datatype === 'Boolean' || (vt.datatype === 'Number' && value === 0) ) ) {
        value = !!vt.default || vt.default === 0 ? vt.default : ''
      }
      let valueHost = measurement
      if (!!vt.level && vt.level !== ".") {
        if (!measurement[vt.level]) {
          measurement[vt.level] = {}
        }
        valueHost = measurement[vt.level]
      }
      valueHost[vt.fieldname] = value
    })
    console.log(`measurement ${JSON.stringify(measurement, null, 2)}`)
    return measurement
  }

  const getDateFormat = () => {
    //console.log(`getDateformat: ${JSON.stringify(tabsInfo[activeTab])}`)
    let currentValueType = tabsInfo[activeTab].valueTypes.filter(vt => (vt.fieldname === 'refDatetime'))
    let format = 'date.format'
    if (currentValueType.length > 0) {
      format = currentValueType[0].datatype === 'Datetime' ? 'datetime.format' : 'date.format'
    }
    console.log(`getDateformat: ${format}`)
    return format
  }
  const loadFilterFromSeries = (series) => {
    if (!!series && series.length > 0 && !!series[0].mfilter) {
      let loadedFilter = {
        series: series.map(s => { return { _id: s._id, selected: !s.seriesHidden } }),
        refDatetimeTo: !!series[0].mfilter && !!series[0].mfilter.refDatetime ? series[0].mfilter.refDatetime['$lte'] : '',
        refDatetimeFrom: !!series[0].mfilter && !!series[0].mfilter.refDatetime ? series[0].mfilter.refDatetime['$gte'] : '',
      }
      //console.log(`loadFilterFromSeries: ${JSON.stringify(loadedFilter)}`)
      return loadedFilter
    } else {
      return {
        series: filterSeriesByMTypes().map(s => { return { _id: s._id, selected: true } }),
        refDatetimeTo: '',
        refDatetimeFrom: ''
      }
    }

  }
  const loadCurrentFilter = () =>{
    let filteredSeriesByMtypes = filterSeriesByMTypes()
    let currentFilter = loadFilterFromSeries(filteredSeriesByMtypes)
    //console.log(`Load current filter from series:${JSON.stringify(currentFilter)}`)
    return currentFilter
  }

  const handleOnFiltersClick = () => {
    //setFilter(buildFilters(filter))
    setIsMeasurementsFilterOpened(true)
  }
  const handleOnGraphClick = () => {
    let filteredSeriesByMtypes = filterSeriesByMTypes()
    let seriesToBeDisplayed = filteredSeriesByMtypes.filter(s => !s.seriesHidden)
    let canShowGraph = false
    if (!!seriesToBeDisplayed && seriesToBeDisplayed.length > 0) {
      let tooManyDataSeries = seriesToBeDisplayed.filter(s => s.measurements && s.measurements.length >= MAX_GRAPH_DATA_LIMIT)
      let noDataSeries = seriesToBeDisplayed.filter(s => s.measurements && s.measurements.length === 0)
      let okDataSeries = seriesToBeDisplayed.filter(s => s.measurements && s.measurements.length > 0 && s.measurements.length < MAX_GRAPH_DATA_LIMIT)
      if (tooManyDataSeries.length > 0) {
        let mts = tooManyDataSeries.map(s => props.measurementTypes.find(mt => mt._id === s.measurementType._id))
        showAlert(`${t('net.measurements')} : ${mts.map(mt => t(`msr.fld.${mt.code}`, mt.label)).join('\n')}\n${t(`msr.graph.alert.maxDataExceeded`)} (${MAX_GRAPH_DATA_LIMIT})`, 'W')
      }
      if (noDataSeries.length > 0) {
        let mts = noDataSeries.map(s => props.measurementTypes.find(mt => mt._id === s.measurementType._id))
        showAlert(`${t('net.measurements')} : ${mts.map(mt => t(`msr.fld.${mt.code}`, mt.label)).join('\n')}\n${t(`msr.graph.alert.nodata`)}`, 'W')
      }
      if (okDataSeries.length > 0) {
        canShowGraph = true
      }
    } else {
      showAlert(t(`msr.graph.alert.nodata`), 'W')
    }
    if (canShowGraph){
      setIsGraphDialogOpened(true)
    }
  }
  const handleCloseFilterDialog = (filter?) => {
    if (!!filter) {
      console.log(`handleCloseFilterDialog reply: ${JSON.stringify(filter)}`)
      if (!!filter.refDatetimeFrom && !!filter.refDatetimeTo) {
        if (moment(filter.refDatetimeTo).isBefore(moment(filter.refDatetimeFrom))) {
          showAlert(t('msr.filter.refDatetimeToBeforeRefDatetimeFrom'), 'E')
          return
        }
      }
      setLoadingMeasurements(true)
      props.setGroupOfMeasurementsFilter(filter)
      .then(
        res => {
          if (loadingMeasurements) setLoadingMeasurements(false)
        }
        ,err => {
          if (loadingMeasurements) setLoadingMeasurements(false)
        }
      )
    }
    setIsMeasurementsFilterOpened(false)
  }

  const handleCloseGraphDialog = () => {
    setIsGraphDialogOpened(false)
  }
  const clearFilter = () => {
    let clearedFilter:any={}
    let df = measurementsManager.getDefaultFilter({
      isScadaEnabled: filterSeriesByMTypes().some(s => s.scadaDeviceCode),
      featureType: props.featureType
    })
    clearedFilter.refDatetimeFrom = df.refDatetime['$gte']
    clearedFilter.refDatetimeTo = df.refDatetime['$lte']
    clearedFilter.series = []
    filterSeriesByMTypes().forEach(fs => {
      clearedFilter.series.push({_id:fs._id, selected:true})
    })
    return clearedFilter
  }
  const filterSeriesByMTypes = () => {
    let mts = props.measurementTypes
    .filter(mt => (!mt.isGroup && (mt.group === tabsInfo[activeTab].code || mt.code === tabsInfo[activeTab].code)))
    // let result = props.series.filter(s => mts.map(mt => mt.code).indexOf(s.measurementType.code) !== -1)
    if (!props.series) {
      console.log(`filterSeriesByMTypes ERROR props.series is ${props.series}`)
    }
    let result = !!props.series ? props.series.filter(s => mts.findIndex(mt => mt.code === s.measurementType.code) > -1) : []
    //console.log(`filterSeriesByMTypes : ${JSON.stringify(result)}`)
    return result
  }
  const filterSeriesByFilter = (filter) => {
    if (!!filter && !!filter.series && filter.series.length > 0) {
      return filter.series
      .filter(s => s.selected)
      .map(s => props.series.find(fs => fs._id === s._id))
    } else {
      return props.series
    }
  }
  const findFieldtoDisplay = () => {
    let result
    let mts = props.measurementTypes.filter(mt => (!mt.isGroup && (mt.group === tabsInfo[activeTab].code || mt.code === tabsInfo[activeTab].code)))
    let vtFound = mts[0].valueTypes.find(vt => vt.isPrimary === true)
    if (!!vtFound) {
      result = vtFound
    } else {
      let cfFound = !!mts[0].computedFields?mts[0].computedFields.find(cf => cf.isPrimary === true):null
      if (!!cfFound) {
        cfFound.level='computedValues'
        result = cfFound
      } else {
        let defaultVt = mts[0].valueTypes.find(vt => vt.fieldname === 'value')
        result = defaultVt
      }
    }
    //console.log(`Build measurement graph based on: ${JSON.stringify(result)}`)
    return result
  }
  const buildGraphDialogTitle = () => {
    return `${t(`btn.graph`)} ${t(`msr.fld.${tabsInfo[activeTab].code}`)}`
  }
  const buildGraphDialogSubTitle = (filter) => {
    let mesTypeNames: string[] = []

    if (!!filter && !!filter.series && filter.series.length > 0) {
      mesTypeNames = filter.series
      .filter(s => s.selected)
      .map(s => {
          let series = props.series.find(fs => fs._id === s._id)
          if (!!series) {
            return t(`msr.fld.${series.measurementType.code}`, series.measurementType.label)
          } else {
            return ''
          }
        }
      )
      .filter(name => !!name)
      //console.log(`buildGraphDialogTitle: ${mesTypeNames}`)
    } else {
      let filteredMesTypes = props.measurementTypes.filter(mt => (!mt.isGroup && (mt.group === tabsInfo[activeTab].code || mt.code === tabsInfo[activeTab].code)))
      //console.log(`buildGraphDialogTitle: ${JSON.stringify(filteredMesTypes)}`)
      mesTypeNames = filteredMesTypes.map(fmt => t(`msr.fld.${fmt.code}`))
    }
    let typesLiteral = mesTypeNames.join(", ")

    let fromDate = ' - '
    let toDate = ' - '
    if (!!filter && !!filter.refDatetimeFrom) {
      fromDate = (typeof filter.refDatetimeFrom === 'string' ? moment(filter.refDatetimeFrom, t(`date.format`) as string) : moment(filter.refDatetimeFrom)).format(t(`date.format`) as string)
    }
    if (!!filter && !!filter.refDatetimeTo) {
      // toDate = (typeof filter.refDatetimeTo === 'string' ? moment(filter.refDatetimeTo, t(`date.format`)) : moment(filter.refDatetimeTo)).add(-1, 'day').format(t(`date.format`))
      toDate = (typeof filter.refDatetimeTo === 'string' ? moment(filter.refDatetimeTo, t(`date.format`) as string) : moment(filter.refDatetimeTo)).format(t(`date.format`) as string)
    }

    return [typesLiteral, fromDate, toDate]
  }

  // console.log(`Featurees render`)
  let compo:any = null
  if (!!meta) {
    let lookups = {
      BooleanValues: BooleanValues,
      OnOffValues: OnOffValues,
    }
    let tabInfo = tabsInfo[activeTab]
    if (tabInfo.isGroup) {
      lookups[tabInfo.code] = props.measurementTypes
      .filter(mt => mt.featureType === props.featureType && mt.group === tabInfo.code)
      .map(mt => {
        let li = {code: mt.code, label: t(`msr.fld.${mt.code}`, mt.label)}
        return li
      })
      // console.log(`lookups ${JSON.stringify(lookups,null,2)}`)
    }
    let heightCss
    switch (props.parent) {
      case 'HydrometersCatalog':
        heightCss = 'calc(100vh - 240px)'
        break;
      case 'FeaturesCatalog':
        heightCss = 'calc(100vh - 195px)'
        break;
      case 'LandparcelsCatalog':
        heightCss = 'calc(100vh - 240px)'
        break;
      case 'SwitchSlotCatalog':
        heightCss = 'calc(100vh - 240px)'
        break;
      default:
        heightCss = 'calc(100vh - 120px)'
        break;
    }
    compo = (
      <Box
        display='flex'
        flexDirection='column'
        // height='calc(100vh - 120px)'
        // height={props.parent === 'HydrometersCatalog' ? 'calc(100vh - 240px)' : (props.parent === 'FeaturesCatalog' ? 'calc(100vh - 195px)' : (props.parent === 'SwitchSlotCatalog' ? 'calc(100vh - 240px)' :'calc(100vh - 120px)'))}
        height={heightCss}
      >
        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          value={activeTab}
          indicatorColor="primary"
          className={classes.tabsRoot}
          onChange={handleTabChange}
        >
          {!!tabsInfo && tabsInfo.map(ti => (
            <Tab
              key={ti.code}
              label={t(`msr.fld.${ti.code}`, ti.label)}
              className={ti.code === tabsInfo[activeTab].code ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}
            />
          ))}
        </Tabs>
        {!!tabsInfo && <Divider variant='middle' />}
        <Box px={1} pt={1} mb={0}
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
        >
          {!loadingMeasurements && <Box mr={2} display='flex'>
            <Button
              className={classes.graphBtn}
              startIcon={(
                <WrmIcon
                  icon='graph'
                  className={classes.graphIconActive}
                />
              )}
              onClick={handleOnGraphClick}
              disabled={false}
            >
              {t('btn.graph')}
            </Button>
          </Box>}
          {!loadingMeasurements && <Box mr={2} display='flex'>
            <Button variant='outlined'
              className={classes.filtersBtn}
              startIcon={(
                <WrmIcon
                  icon='filter'
                  className={classes.filterIconActive}
                />
              )}
              onClick={handleOnFiltersClick}
              disabled={false}
            >
              {t('btn.filters')}
            </Button>
          </Box>}
        </Box>
        {loadingMeasurements ? (
        <Box p={5}>
          <CircularProgress className={classes.circular} size={200} />
        </Box>
        ) : (
/*
        <Box p={5}>
          <CircularProgress className={classes.circularRed} size={200} />
        </Box>
*/
        <MasterDetail
          data={data}
          meta={meta}
          selectedDataItem={selectedMeasurement}
          setSelectedDataItem={setSelectedMeasurement}
          users={users}
          lookups={lookups}
          actSaveDetail={doSaveMeasurement}
          actDeleteDetail={doDeleteMeasurement}
          // customMasterButtons={customMasterButtons}
        >
        </MasterDetail>
      )}
        {isMeasurementsFilterOpened &&
        <MeasurementsFilter
          series={props.series}
          open={isMeasurementsFilterOpened}
          loadCurrentFilter={loadCurrentFilter}
          handleCloseDialog={handleCloseFilterDialog}
          seriesMTypes={filterSeriesByMTypes()}
          clearFilter={clearFilter}
        />}
        {isGraphDialogOpened &&
        <MeasurementsGraph
          open={isGraphDialogOpened}
          feature={props.feature}
          series={filterSeriesByFilter(loadFilterFromSeries(filterSeriesByMTypes())).filter(s => s.measurements.length > 0 && s.measurements.length < MAX_GRAPH_DATA_LIMIT)}
          handleCloseDialog={handleCloseGraphDialog}
          title={buildGraphDialogTitle()}
          subtitle={buildGraphDialogSubTitle(loadFilterFromSeries(filterSeriesByMTypes()))}
          filter={loadFilterFromSeries(filterSeriesByMTypes())}
          fieldName={t(`msr.fld.${tabsInfo[activeTab].code}`, `msr.fld.value`)}
          valueFieldToDisplay={findFieldtoDisplay()}
          dateFormat={getDateFormat()}
        />}
      </Box>
    )
  }
  return compo

}

export default HydoorMeasurementsMd
