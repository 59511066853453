import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Divider,
  FormControlLabel,
} from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { DateRange } from '@material-ui/icons'
import clsx from 'clsx'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import AutocompleteHDR from '../presentationalComponents/AutocompleteHDR'
import { useCommonStore } from '../../state/commonStore'
import { useNetworkElementStore } from '../../state/networkElementStore'
import WrmIcon from '../WrmIcon'
import ButtonHDR from '../presentationalComponents/ButtonHDR'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'

const useStyles = makeStyles(theme => ({
  filtersBtn: {
    textTransform: 'none',
  },
  filtersBtnActive: {
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.tertiary.main,
  },
  clearBtn: {
    marginRight: 'auto',
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.tertiary.main,
  },
  dialogContent: {
    borderTop: `thin solid ${theme.palette.tertiary.light}`,
    borderBottom: `thin solid ${theme.palette.tertiary.light}`,
    minWidth: '600px',
    maxWidth: '700px',
  },
  filterInputContainer: {
    minWidth: 330,
    maxWidth: 330,
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  dialogRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0',
    width: '100%',
  },
  dialogRowCheckBoxes: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    width: '100%',
  },
  dialogRowLeft: {
    width: '40%',
  },
  dialogRowLeftPickers: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  dialogRowRight: {
    width: '60%',
  },
  dialogRowRightPickers: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogLabelText: {
    marginRight: '2rem',
  },
  datePicker: {
    minWidth: 155,
    maxWidth: 155,
  },
  inputdate: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 'normal',
    minWidth: 80,
    maxWidth: 150,
    color: theme.palette.tertiary.main,
  },
  tabLabelSelected: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  autoRoot: {
    maxWidth: '330px',
    minWidth: '330px',
    textAlign: 'left',
  },
}))

export default function NotificationFilterDialog(props) {
  const profile = useCommonStore(state => state.profile)
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const switches = useNetworkElementStore(state => state.switches)
  const pumps = useNetworkElementStore(state => state.pumps)
  const classes = useStyles()
  const { t } = useTranslation()
  const [activeNotificationFilterTab, setActiveNotificationFilterTab] = useState<string>(
    accessGranted('vwqlty') ? 'qualityControl' : 
      (accessGranted('VwMntn') ? 'maintenance' : 
        (accessGranted('Vwrrgt3') || accessGranted('Vwrrgt9') ? 'irrigation' : '')))

  const handleFilterTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveNotificationFilterTab(newValue)
    /**Irrigation date from/to pickers have different values from 'quality' and 'maintenance' */
    if (newValue === 'irrigation') {
      props.setStartDate(moment(currentAgriculturalPeriod?.startDate).startOf('year'))
      moment().isAfter(moment(currentAgriculturalPeriod?.endDate).endOf('year'))
        ? props.setEndDate(moment(currentAgriculturalPeriod?.endDate).endOf('year'))
        : moment()
    } else {
      props.setStartDate(moment().set('month', moment().get('month') - 1))
      props.setEndDate(moment())
    }
  }

  let isApplyButtonDisabled = () => {
    if (activeNotificationFilterTab === 'qualityControl') {
      if (
        !props.startDate &&
        !props.endDate &&
        props.selectSamplingPointsValue.length === 0 &&
        props.selectSeverityValue.length === 0
      ) {
        return false
      } else if (
        props.startDate &&
        props.endDate &&
        props.selectSamplingPointsValue.length &&
        props.selectSeverityValue.length
      ) {
        return false
      } else {
        return true
      }
    } else if (activeNotificationFilterTab === 'maintenance') {
      if (!props.startDate && !props.endDate && props.selectSeverityValue.length === 0) {
        return false
      } else if (props.startDate && props.endDate && props.selectSeverityValue.length) {
        return false
      } else {
        return true
      }
    } else if (activeNotificationFilterTab === 'irrigation') {
      if (
        !props.startDate &&
        !props.endDate &&
        props.selectWaterOutletValue.length === 0 &&
        props.selectSeverityValue.length === 0 &&
        props.selectedPumpValue.length === 0
      ) {
        return false
      } else if (
        props.startDate &&
        props.endDate &&
        props.selectSeverityValue.length &&
        (props.selectWaterOutletValue.length || props.selectedPumpValue.length) 
      ) {
        return false
      } else {
        return true
      }
    }
  }

  const fromMinDate = () => {
    if (activeNotificationFilterTab === 'irrigation') {
      return moment(currentAgriculturalPeriod?.startDate!).startOf('year')
    } else {
      return moment('01-01-2000')
    }
  }
  const fromMaxDate = () => {
    if (activeNotificationFilterTab === 'irrigation') {
      return moment(currentAgriculturalPeriod?.startDate).endOf('year')
    } else {
      return moment()
    }
  }

  const toMinDate = () => {
    if (activeNotificationFilterTab === 'irrigation') {
      return moment(currentAgriculturalPeriod?.startDate).startOf('year')
    } else {
      return moment('01-01-2000')
    }
  }

  const toMaxDate = () => {
    if (activeNotificationFilterTab === 'irrigation') {
      return moment(currentAgriculturalPeriod?.endDate).endOf('year')
    } else {
      return moment()
    }
  }

  return (
    <>
      <Button
        variant='outlined'
        className={classes.filtersBtn}
        startIcon={
          <WrmIcon className={props.filterIsActive ? classes.filtersBtnActive : classes.filtersBtn} icon='filter' />
        }
        onClick={() => props.setOpenFilterDialog(true)}
      >
        {t('btn.filters')}
      </Button>
      <Dialog
        open={props.openFilterDialog}
        onClose={(event, reason) => (reason === 'backdropClick') ? null : props.setOpenFilterDialog(false)}
        aria-labelledby='notification-filter-dialog'
        aria-describedby='notification-filter-dialog'
        maxWidth={'md'}
      >
        <DialogTitle id='notification-filter-dialog'>{t('alarms.calendar.filterTitle')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Tabs
            value={activeNotificationFilterTab}
            onChange={handleFilterTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            aria-label='notification filters tabs'
          >
            {accessGranted('vwqlty') && (
              <Tab
                label={t('alarms.calendar.filter.tabs.qualityControl')}
                value='qualityControl'
                className={
                  'qualityControl' === activeNotificationFilterTab
                    ? clsx(classes.tabLabel, classes.tabLabelSelected)
                    : classes.tabLabel
                }
              />
            )}
            {accessGranted('VwMntn') && (
              <Tab
                label={t('alarms.calendar.filter.tabs.maintenance')}
                value='maintenance'
                className={
                  'maintenance' === activeNotificationFilterTab
                    ? clsx(classes.tabLabel, classes.tabLabelSelected)
                    : classes.tabLabel
                }
              />
            )}
            {(accessGranted('Vwrrgt3') || accessGranted('Vwrrgt9')) && (
              <Tab
                label={t('mvb.irrigation')}
                value='irrigation'
                className={
                  'irrigation' === activeNotificationFilterTab
                    ? clsx(classes.tabLabel, classes.tabLabelSelected)
                    : classes.tabLabel
                }
              />
            )}
          </Tabs>
          <Divider />
          <>
            <Box className={classes.dialogRow}>
              <Box className={classes.dialogRowLeft}>
                <Typography align='left'>{t('alarms.urgencyLevel')}</Typography>
              </Box>

              <Box className={classes.dialogRowRight}>
                <FormControl className={classes.filterInputContainer}>
                  <Select
                    disabled={props.isLoadingNotifications}
                    multiple={true}
                    variant='outlined'
                    displayEmpty
                    classes={{ root: classes.input }}
                    value={props.selectSeverityValue}
                    onChange={e => props.handleSelectSeverityValue(e)}
                  >
                    <MenuItem value={'_ALL_ITEMS_'}>{t('cp.notification.filters.all')}</MenuItem>
                    <MenuItem value={'1'}>{t('alarms.severity.emergency')}</MenuItem>
                    <MenuItem value={'2'}>{t('alarms.severity.warning')}</MenuItem>
                    <MenuItem value={'3'}>{t('alarms.severity.briefing')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {activeNotificationFilterTab === 'qualityControl' && (
              <Box className={classes.dialogRow}>
                <Box className={classes.dialogRowLeft}>
                  <Typography align='left'>{t('net.samplingPoint.plural')}</Typography>
                </Box>
                <Box className={classes.dialogRowRight}>
                  <AutocompleteHDR
                    disabled={props.isLoadingNotifications}
                    size='small'
                    getOptionLabel={(o: string) => {
                      if (o === '_ALL_ITEMS_') return t('all-neutral')
                      const theFeature = props.samplingPoints?.find(el => el.properties.code === o)
                      return theFeature?.properties?.label || theFeature?.properties?.code || '-'
                    }}
                    getOptionSelected={(o: string, v: string) => o === v}
                    value={props.selectSamplingPointsValue}
                    options={
                      props.samplingPoints
                        ? ['_ALL_ITEMS_'].concat(props.samplingPoints.map(sp => sp.properties.code))
                        : []
                    }
                    onChange={(e, o) => props.handleSelectSamplingPoints(o)}
                    classesOverrides={{ autoRoot: classes.autoRoot }}
                  />
                </Box>
              </Box>
            )}
            {activeNotificationFilterTab === 'irrigation' && (
              <Box className={classes.dialogRow}>
                <Box className={classes.dialogRowLeft}>
                  <Typography align='left'>{t('net.switch.plural')}</Typography>
                </Box>
                <Box className={classes.dialogRowRight}>
                  <AutocompleteHDR
                    disabled={props.isLoadingNotifications}
                    size='small'
                    getOptionLabel={(o: string) => {
                      if (o === '_ALL_ITEMS_') return t('all-female')
                      const theSwitch = switches?.find(sw => sw.properties.code === o)
                      return theSwitch?.properties?.label || theSwitch?.properties?.code || '-'
                    }}
                    getOptionSelected={(o: string, v: string) => o === v}
                    value={props.selectWaterOutletValue}
                    options={switches ? ['_ALL_ITEMS_'].concat(switches.map(sp => sp.properties.code)) : []}
                    onChange={(e, o) => props.handleSelectWaterOutlets(o)}
                    classesOverrides={{ autoRoot: classes.autoRoot }}
                  />
                </Box>
              </Box>
            )}
            {/* Pumps */}
            {activeNotificationFilterTab === 'irrigation' && (
              <Box className={classes.dialogRow}>
                <Box className={classes.dialogRowLeft}>
                  <Typography align='left'>{t('net.pump.plural')}</Typography>
                </Box>
                <Box className={classes.dialogRowRight}>
                  <AutocompleteHDR
                    disabled={props.isLoadingNotifications}
                    size='small'
                    getOptionLabel={(o: string) => {
                      if (o === '_ALL_ITEMS_') return t('all-female')
                      const thePump = pumps?.find(p => p.properties.code === o)
                      return thePump?.properties?.label || thePump?.properties?.code || '-'
                    }}
                    getOptionSelected={(o: string, v: string) => o === v}
                    value={props.selectedPumpValue}
                    options={pumps ? ['_ALL_ITEMS_'].concat(pumps.map(sp => sp.properties.code)) : []}
                    onChange={(e, o) => props.handleSelectPumps(o)}
                    classesOverrides={{ autoRoot: classes.autoRoot }}
                  />
                </Box>
              </Box>
            )}
            {/* checkboxes */}
            <Typography>{t('alarms.calendar.filter.showNotifications')}</Typography>
            <Box className={classes.dialogRowCheckBoxes}>
              <Box className={classes.dialogRowLeftPickers}>
                <FormControlLabel
                  disabled={props.isLoadingNotifications}
                  checked={props.filterByLoggedUser} //'LOGGED_USER'
                  control={<CheckBoxNt name='LOGGED_USER' onChange={props.handleCheckboxesChange} />}
                  label={`${t('alarms.calendar.filter.forCurrentUser')} ${profile?.auth0User.given_name} ${
                    profile?.auth0User.family_name
                  }`}
                  labelPlacement='end'
                />
              </Box>
              <Box className={classes.dialogRowRightPickers}>
                <FormControlLabel
                  disabled={props.isLoadingNotifications}
                  checked={props.filterByAllPersonnel} //'ALL_PERSONNEL'
                  control={<CheckBoxNt name='ALL_PERSONNEL' onChange={props.handleCheckboxesChange} />}
                  label={t('alarms.calendar.filter.forAllUsers')}
                  labelPlacement='end'
                />
              </Box>
            </Box>
            {/* PICKERS */}
            <Box className={classes.dialogRow}>
              <Box className={classes.dialogRowLeftPickers}>
                <Typography className={classes.dialogLabelText}>{t('alarms.calendar.filter.from')}</Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disabled={props.isLoadingNotifications}
                    label={t('alarms.calendar.filter.date')}
                    autoOk
                    disableFuture
                    variant='inline'
                    className={classes.datePicker}
                    inputProps={{
                      className: classes.inputdate,
                    }}
                    emptyLabel={t('date.format.pickers.emptyLabel')!}
                    format={t('date.format.pickers')!}
                    value={props.startDate}
                    onChange={date => props.setStartDate(date?.startOf('day'))}
                    keyboardIcon={<DateRange color='primary' />}
                    minDate={fromMinDate()}
                    maxDate={fromMaxDate()}
                  />
                </MuiPickersUtilsProvider>
              </Box>

              <Box className={classes.dialogRowRightPickers}>
                <Typography className={classes.dialogLabelText}>{t('alarms.calendar.filter.to')}</Typography>

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disabled={props.isLoadingNotifications}
                    label={t('alarms.calendar.filter.date')}
                    autoOk
                    disableFuture
                    variant='inline'
                    className={classes.datePicker}
                    inputProps={{
                      className: classes.inputdate,
                    }}
                    emptyLabel={t('date.format.pickers.emptyLabel') as string}
                    format={t('date.format.pickers')!}
                    value={props.endDate}
                    onChange={date => props.setEndDate(date?.endOf('day'))}
                    keyboardIcon={<DateRange color='primary' />}
                    minDate={toMinDate()}
                    maxDate={toMaxDate()}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          </>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' className={classes.clearBtn} onClick={() => props.handleClearFilters()} 
            disabled={props.isLoadingNotifications} >
            {t('btn.clear')}
          </Button>
          <Button variant='outlined' className={classes.cancelBtn} onClick={() => props.setOpenFilterDialog(false)}
            disabled={props.isLoadingNotifications} >
            {t('btn.cancel')}
          </Button>
          <ButtonHDR 
            disabled={isApplyButtonDisabled() || props.isLoadingNotifications} 
            title='btn.apply'
            onClick={() => props.handleApplyFilters(activeNotificationFilterTab)}
            loading={props.isLoadingNotifications}/>
        </DialogActions>
      </Dialog>
    </>
  )
}
