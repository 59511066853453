import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  Box,
  makeStyles,
  Theme,
  createStyles,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@material-ui/core/'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogItemid: {
      fontSize: 18,
      fontWeight: 400,
      color: theme.palette.tertiary.dark,
    },
    dialogPaperRoot: {
      minWidth: '500px',
      maxWidth: 'calc(100vw - 200px)',
    },
    dialogTitleRoot: {
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
    contained: {
      textTransform: 'none',
      minWidth: 100,
      fontSize: '0.875rem',
      margin: '0.25rem',
      maxHeight: '40px',
      color: theme.palette.common.white,
    },
    outlined: {
      textTransform: 'none',
      minWidth: 100,
      fontSize: '0.875rem',
      margin: '0.25rem',
      maxHeight: '40px',
    },
    disabled: {
      backgroundColor: `${theme.palette.primary.main}!important`,
    },
  })
)

interface Props {
  isOpen: boolean
  yesBtnClickCallback: (...args: any) => void
  title: string
  itemName: string
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  noBtnClickCallback?: (...args: any) => void | undefined
  disableBackdropClick?: boolean
  withNote?: boolean
  customClasses?: any //custom classes
  withoutButtons?: boolean
  submitBtnDisabled?: boolean
  btnYesText?: string
  btnNoText?: string
  btnYesVariant?: 'contained' | 'outlined' | 'text'
  btnYesColor?: 'default' | 'inherit' | 'primary' | 'secondary'
  showAlert: (message: any, severity?: any) => void
}

/**THIS IS A STRIPPED DOWN VERSION OF < ConfirmationDialogHDR/> without any external dependancies */
const MdConfirmationDialog: React.FC<Props> = ({
  isOpen,
  title,
  itemName,
  setDialogOpen,
  yesBtnClickCallback,
  noBtnClickCallback = undefined,
  withNote = false,
  customClasses = {},
  disableBackdropClick = false,
  withoutButtons = false,
  children,
  submitBtnDisabled = false,
  btnYesText = '',
  btnNoText = '',
  btnYesVariant = 'contained',
  btnYesColor = 'primary',
  showAlert,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [noteValue, setNoteValue] = useState('')

  const handleDialogActionsBtnClick = (event: React.MouseEvent<HTMLButtonElement>, msg: 'yes' | 'no' | 'ignore') => {
    event.stopPropagation()
    switch (msg) {
      case 'yes':
        if (withNote) {
          if (!noteValue) {
            return showAlert(t('net.features.confirm.delete.feature.note.notEmpty'), 'W')
          } else {
            !!yesBtnClickCallback && yesBtnClickCallback(noteValue)
            // setDialogOpen(false)
            setNoteValue('')
            return
          }
        }
        !!yesBtnClickCallback && yesBtnClickCallback()
        // setDialogOpen(false)
        setNoteValue('')
        break
      case 'no':
        !!noBtnClickCallback && noBtnClickCallback()
        // setDialogOpen(false)
        setNoteValue('')
        break
      default:
        break
    }
  }

  return (
    <>
      <Dialog
        id='confirmDialog'
        open={isOpen}
        onClose={(e: React.MouseEvent<HTMLButtonElement>) =>
          disableBackdropClick ? handleDialogActionsBtnClick(e, 'ignore') : handleDialogActionsBtnClick(e, 'no')
        }
        classes={{
          paper: !!customClasses.dialogPaperRoot ? customClasses.dialogPaperRoot : classes.dialogPaperRoot,
        }}>
        {!!title && (
          <DialogTitle
            classes={{
              root: !!customClasses.dialogTitleRoot ? customClasses.dialogTitleRoot : classes.dialogTitleRoot,
            }}>
            {t(title)}
          </DialogTitle>
        )}
        <DialogContent className={customClasses.dialogContent ? customClasses.dialogContent : ''}>
          {itemName && (
            <Box display='flex' flexDirection='column'>
              <Box display='flex' className={classes.dialogItemid}>
                {itemName}
              </Box>
            </Box>
          )}
          {!!children && children}
          {withNote && (
            <Box mt={2} mb={1} display='flex'>
              <TextField
                label={t('net.features.confirm.delete.feature.note.prompt')}
                fullWidth
                multiline
                minRows={3}
                value={noteValue}
                onChange={e => setNoteValue(e.target.value)}
              />
            </Box>
          )}
        </DialogContent>
        {!withoutButtons && (
          <DialogActions className={customClasses.dialogActions ? customClasses.dialogActions : ''}>
            <Button
              onClick={e => handleDialogActionsBtnClick(e, 'no')}
              variant='outlined'
              className={classes.outlined}>
              {btnNoText ? t(btnNoText) : t('btn.no')}
            </Button>
            <Button
              disabled={submitBtnDisabled}
              variant={btnYesVariant}
              color={btnYesColor}
              // className={classes.contained}
              className={classes.outlined}
              classes={btnYesVariant === 'contained' ? { disabled: classes.disabled } : {}}
              onClick={e => handleDialogActionsBtnClick(e, 'yes')}>
              {btnYesText ? t(btnYesText) : t('btn.yes')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

export default MdConfirmationDialog
