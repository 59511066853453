import type { ComponentsPropsList } from '@material-ui/core/styles/props';

export interface Localization {
  props?: {
    MuiBreadcrumbs?: Pick<ComponentsPropsList['MuiBreadcrumbs'], 'expandText'>;
    MuiTablePagination?: Pick<
      ComponentsPropsList['MuiTablePagination'],
      'backIconButtonText' | 'labelRowsPerPage' | 'labelDisplayedRows' | 'nextIconButtonText'
    >;
    // The core package has no dependencies on the @material-ui/lab components.
    // We can't use ComponentsPropsList, we have to duplicate and inline the definitions.
    MuiRating?: {
      emptyLabelText?: string;
      getLabelText?: (value: number) => string;
    };
    MuiAutocomplete?: {
      clearText?: string;
      closeText?: string;
      loadingText?: React.ReactNode;
      noOptionsText?: React.ReactNode;
      openText?: string;
    };
    MuiAlert?: {
      closeText?: string;
    };
    MuiPagination?: {
      'aria-label'?: string;
      getItemAriaLabel?: (type: string, page: number, selected: boolean) => string | undefined;
    };
  };
}

export const elGR: Localization = {
  props: {
    MuiBreadcrumbs: {
      expandText: 'Προβολή διαδρομής',//'Show path',
    },
    MuiTablePagination: {
      backIconButtonText: 'Προηγούμενη σελίδα',//'Previous page',
      labelRowsPerPage: 'Εγγραφές ανά σελίδα:',//'Rows per page:',
      labelDisplayedRows: ({ from, to, count }) =>
  `${from}-${to} από ${count !== -1 ? count : `περισσότερες από ${to}`}`,
  // `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`,
      nextIconButtonText: 'Επόμενη σελίδα',//'Next page',
    },
    MuiRating: {
      getLabelText: value => `${value} Αστέρι${value !== 1 ? 'α' : ''}`,//`${value} Star${value !== 1 ? 's' : ''}`,
      emptyLabelText: 'Άδειο'//'Empty',
    },
    MuiAutocomplete: {
      clearText: 'Καθαρισμός',//'Clear',
      closeText: 'Κλείσιμο',//'Close',
      loadingText: 'Φόρτωση…',//'Loading…',
      noOptionsText: 'Καμία επιλογή',//'No options',
      openText: 'Άνοιγμα',//'Open',
    },
    MuiAlert: {
      closeText: 'Κλείσιμο',//'Close',
    },
    MuiPagination: {
      'aria-label': 'Πλοήγηση στις σελίδες',//'Pagination navigation',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? 'σελίδα' : 'Προβολή σελίδας'} ${page}`;//`${selected ? '' : 'Go to '}page ${page}`;
        }
        if (type === 'first') {
          return 'Προβολή πρώτης σελίδας';//'Go to first page';
        }
        if (type === 'last') {
          return 'Προβολή τελευταίας σελίδας';//'Go to last page';
        }
        if (type === 'next') {
          return 'Προβολή επόμενης σελίδας';//'Go to next page';
        }
        if (type === 'previous') {
          return 'Προβολή προηγούμενης σελίδας';//'Go to previous page';
        }
        return undefined;
      },
    },
  },
};
