import React, { useState, useRef } from 'react'
import moment from 'moment'
import FileSaver from 'file-saver'
import domtoimage from 'dom-to-image'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles, IconButton } from '@material-ui/core'
import { Print, GetApp as Download } from '@material-ui/icons'

import NotificationsManager from '../../services/NotificationsManager'
import { useCollectionStore } from '../../state/collectionStore'
import { useCommonStore } from '../../state/commonStore'
import { useNetworkElementStore } from '../../state/networkElementStore'
import { getLocalizedNotificationContent, notificationDataType } from '../../utils/helpers'
import NotificationsFilterDialog from './NotificationsFilterDialog'
import NotificationsTableDialog from './NotificationsTableDialog'
import PaginationCustom from './PaginationCustom'
import NotificationsCharts from './NotificationCharts'

function createMarkup(str: string, str2: string, n?: any) {
  if (!['alarmFired', 'invrtAlarmFired'].includes(n.notificationType)) {
    return { __html: str.concat('. ' + str2) }
  } else {
    return { __html: str.concat(str2) }
  }
}

/**Sorts notifications by sentDate from the newest to the oldest */
function sortNotificationsBySentDate(notifications:any[]) {
  // return array.sort((a, b) => new Date(a?.sentDate)?.getTime() - new Date(b?.sentDate)?.getTime());
  return notifications.sort((a, b) => new Date(b?.sentDate)?.getTime() - new Date(a?.sentDate)?.getTime());
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem 1rem',
  },
  listContainerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem 1rem',
    minHeight: 'calc(100vh - 220px)',
    flexFlow: 'column',
  },
  listDotContainer: {
    display: 'flex',
    justifyItems: 'center',
    marginRight: '1.5rem',
  },
  ul: {
    listStyle: 'none',
    paddingLeft: '0.25rem',
    paddingRight: '0.5rem', //ensures the downloaded image is not cropped by the scrollbar
    textAlign: 'left',
    width: '100%',
    overflowY: 'scroll',
    height: 'calc(100vh - 250px)',
  },
  customLi: {
    display: 'flex',
    marginBottom: '1rem',
    cursor: 'pointer',
  },
  liDotNew: {
    border: '3px solid #e95a0ccc',
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    background: '#ff0000',
    boxShadow: '0px 0px 6px 0px #E95A0C',
    marginTop: '1rem',
  },
  liDotRead: {
    border: '3px solid #808080',
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    background: '#808080',
    boxShadow: '0px 0px 6px 0px #756156',
    marginTop: '1rem',
  },
  typography: {
    margin: '0 0.5rem 0.5rem 0 !important',
    textAlign: 'justify',
  },
  iconBtn: {
    color: '#000',
  },
}))

export default function NotificationsCalendar(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const notificationsManager = NotificationsManager()
  const locale = useCommonStore(state => state.locale)
  const personnels = useCollectionStore(state => state.personnels)
  const profile = useCommonStore(state => state.profile)
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)
  const showAlert = useCommonStore(state => state.showAlert)
  const alarms = useCollectionStore(state => state.alarms)
  const samplingPoints = useNetworkElementStore(state => state.samplingPoints)
  const switches = useNetworkElementStore(state => state.switches)
  const pumps = useNetworkElementStore(state => state.pumps)

  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false)
  const [selectSeverityValue, setSelectSeverityValue] = useState<string[]>(['1','2','3',])
  const [selectSamplingPointsValue, setSelectSamplingPointsValue] = useState<string[]>(
    samplingPoints ? samplingPoints.map((sp) => sp.properties.code) : [])
  const [selectWaterOutletValue, setSelectWaterOutletValue] = useState<string[]>(
    switches ? switches.map((sp) => sp.properties.code) : [])
  const [selectedPumpValue, setSelectedPumpValue] = useState<string[]>(
    pumps ? pumps.map((sp) => sp.properties.code) : [])
  const [startDate, setStartDate] = useState<any>(moment().set('month', moment().get('month') - 1))
  const [endDate, setEndDate] = useState<any>(moment())
  const [filteredAlarmNotificationsMSGS, setFilteredAlarmNotificationsMSGS] = useState<null | any[]>(null)
  const [filterByLoggedUser, setFilterByLoggedUser] = useState(true)
  const [filterByAllPersonnel, setFilterByAllPersonnel] = useState(false)
  const [filterIsActive, setFilterIsActive] = useState(false)
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false)

  const listRef = useRef(null)
  const handleCheckboxesChange = (event) => {
    setFilterByLoggedUser(!filterByLoggedUser)
    setFilterByAllPersonnel(!filterByAllPersonnel)
  }

  /**Pagination */
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)

  const handlePaginationNextClick = () => {
    const totalPages = filteredAlarmNotificationsMSGS
      ? Math.ceil(filteredAlarmNotificationsMSGS.length / pageSize)
      : 0
    if (currentPage + 1 <= totalPages - 1) {
      setCurrentPage(currentPage + 1)
    }
  }
  const handlePaginationPreviousClick = () => {
    if (currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1)
    }
  }
  const handlePaginationFirstClick = () => {
    const totalPages = filteredAlarmNotificationsMSGS
      ? Math.ceil(filteredAlarmNotificationsMSGS.length / pageSize)
      : 0
    if (totalPages) {
      setCurrentPage(0)
    }
  }
  const handlePaginationLastClick = () => {
    const totalPages = filteredAlarmNotificationsMSGS
      ? Math.ceil(filteredAlarmNotificationsMSGS.length / pageSize)
      : 0
    if (totalPages > 1) {
      setCurrentPage(totalPages - 1)
    }
  }

  //**Filters**
  const handleClearFilters = () => {
    setSelectSeverityValue([])
    setSelectSamplingPointsValue([])
    setSelectedPumpValue([])
    setSelectWaterOutletValue([])
    setStartDate(null)
    setEndDate(null)
  }

  const handleApplyFilters = async (notificationType: string) => {
    if (notificationType === 'irrigation' && currentAgriculturalPeriod && startDate && endDate) {
      const startYear = moment(startDate).startOf('year');
      const endYear = moment(endDate).endOf('year');
      if (
        startYear.isBefore(moment(currentAgriculturalPeriod.startDate).startOf('year')) ||
        endYear.isAfter(moment(currentAgriculturalPeriod.endDate).endOf('year'))
      ) {
        return showAlert(t("irri.notifications.outOfRange", {val:moment(currentAgriculturalPeriod.startDate).year()}),'W')
      }
    }

    if (
      !selectSeverityValue.length &&
      !selectSamplingPointsValue.length &&
      !selectWaterOutletValue.length &&
      !startDate &&
      !endDate
    ) {
      setFilteredAlarmNotificationsMSGS(null)
      setOpenFilterDialog(false)
      setFilterIsActive(false)
      return
    }

    let filteredNotifications: any
    /**The filter is used to query the Notifications Collection */
    const filter:any = {
      severity: {$in: selectSeverityValue},
      webDoHide: { $in: [null, false] }
    }

    if (startDate && endDate) {
      filter.createdAt = {
        $gte: startDate.toDate(),
        $lte: endDate.toDate(),
      }
    } else if (startDate) {
      filter.createdAt = { $gte: startDate.toDate() }
    } else if (endDate) {
      filter.createdAt = { $lte: endDate.toDate() }
    }
    /**Apart from removing the default 500 notifications limit (limit:-1 equals to no limit)
     * the personnel_id is passed to options if we are fetching the notifications of a specific user */
    let options:any = { limit:-1 }
    switch (notificationType) {
      case 'qualityControl':
        filter.notificationType = 'alarmFired'
        break
      case 'maintenance':
        filter.notificationType = 'maintenancePlan'
        break
      case 'irrigation':
        filter.notificationType = { $in: ['irriEventPersonnel', 'invrtAlarmFired'] }
        /**The user can filter the irrigation notification by pumps(inverter) or by switchCode(PLC) and those fields are
         * mutually exlusive, so the $or operator is used to fetch the documents that match either of those two criteria
         * the first criteria is a fallback for the legacy notifications that have the `switchCode` property */
        filter.$or = [{ switchCode: { $in: selectWaterOutletValue } }, { featureCode: { $in: selectWaterOutletValue } }, { featureCode: { $in: selectedPumpValue } }]
        break
      default:
        break
    }

    setIsLoadingNotifications(true)
    if (filterByLoggedUser) {
      const currentUsersPersonnel = personnels?.find(p => p.users_user_id === profile?.user_id)
      options.personnel_id = currentUsersPersonnel?._id

      try {
        const userNotificationMsgsRequest = await notificationsManager.getFilteredNotificationMsgsByPersonnel(filter, options)
        filteredNotifications = [...userNotificationMsgsRequest.data]
      } catch (err) {
        console.log(`getFilteredPersonnelNotificationMsgs error:`)
        console.log(err)
        filteredNotifications = []
      }
      setIsLoadingNotifications(false)
    } else {
      let allUsersnotifications;

      try {
        allUsersnotifications = await notificationsManager.loadAllUsersFilteredNotifications(filter,options).then(res=>{
          if (res && res.length) {
            return res
          } else {
            return []
          }
        })
        setIsLoadingNotifications(false)
      } catch (err) {
        console.log(`loadAllUsersFilteredNotifications error:`)
        console.log(err)
        allUsersnotifications = []
        setIsLoadingNotifications(false)
      }
      filteredNotifications = [...allUsersnotifications]
    }

    /**In quality control we don't have access to the `samplingPointCode`
     * since it doesn't exist in notification or notificationMSG schema
     * instead the featureCode is interpolated from the measurementSeries (optionally it can be interpolated from the Alarm property on the Notification document)
     * that are populated as a runtime properties,
     * Because of the above we get from the server all notifications regardless of the sampling point codes that the user selected
     * and then filter them based on the runtime `notification.ms.featureCode` (measurement series) property   
     */
    if (notificationType === 'qualityControl') {
      filteredNotifications = filteredNotifications.filter((n) => {
        return selectSamplingPointsValue.includes(n.ms?.featureCode) || !n.ms
      })
    }

    if (filterByAllPersonnel) {
      /** enchance filteredNotifications with .messages property if it is availiable
       *  (it will be availiable if the alarm belongs to the LOGGED_IN user) */
      filteredNotifications = filteredNotifications.map((n) => {
        const enchancedNotification = props.notifications.find(
          (nn) => nn._id === n._id
        )
        return enchancedNotification
          ? { ...n, messages: enchancedNotification.messages }
          : { ...n }
      })
    }

    //sort Notifications from newest to oldest
    filteredNotifications = sortNotificationsBySentDate(filteredNotifications)

    setFilterIsActive(true)
    props.setFilterNotificationType(notificationType)
    setFilteredAlarmNotificationsMSGS(filteredNotifications)
    setOpenFilterDialog(false)
    setCurrentPage(0)
  }

  const handleSelectSamplingPoints = (value: string[] ) => {
    if (value.includes('_ALL_ITEMS_')) {
      if (selectSamplingPointsValue.length === samplingPoints?.length) {
        setSelectSamplingPointsValue([])
      } else {
        const allItems = samplingPoints?.map((sp) => sp.properties.code)
        setSelectSamplingPointsValue(allItems || [])
      }
    } else {
      setSelectSamplingPointsValue(value as string[])
    }
  }

  const handleSelectWaterOutlets = ( value: string[]) => {
    if (value.includes('_ALL_ITEMS_')) {
      if (selectWaterOutletValue.length === switches?.length) {
        setSelectWaterOutletValue([])
      } else {
        const allItems = switches?.map((sp) => sp.properties.code) || []
        setSelectWaterOutletValue(allItems)
      }
    } else {
      setSelectWaterOutletValue(value as string[])
    }
  }

  const handleSelectPumps = ( value: string[]) => {
    if (value.includes('_ALL_ITEMS_')) {
      if (selectedPumpValue.length === pumps?.length) {
        setSelectedPumpValue([])
      } else {
        const allItems = pumps?.map((sp) => sp.properties.code) || []
        setSelectedPumpValue(allItems)
      }
    } else {
      setSelectedPumpValue(value as string[])
    }
  }

  const handleSelectSeverityValue = (
    event: React.ChangeEvent<{ value: [string] }>
  ) => {
    if (event.target.value.includes('_ALL_ITEMS_')) {
      if (selectSeverityValue.length === 3) {
        setSelectSeverityValue([])
      } else {
        setSelectSeverityValue(['1', '2', '3'])
      }
    } else {
      setSelectSeverityValue(event.target.value as string[])
    }
  }

  if (props.activeTabCode !== 'tabCalendar') {
    return null
  }

  const handleSaveGraphClick = (event) => {
    // https://github.com/tsayen/dom-to-image/issues/183
    let listWrapper: any = listRef.current
    if (!!listWrapper) {
      domtoimage
        .toBlob(listWrapper, {
          width: listWrapper.scrollWidth,
          height: listWrapper.scrollHeight,
        })
        .then((blobres) => {
          FileSaver.saveAs(blobres, `alarms${Date.now()}.png`)
        })
    }
  }

  const handlePrintGraphReport = (event) => {
    let printoutNode: any = listRef.current
    var WinPrint: any = window.open(
      '',
      '',
      'left=0,top=0,width=850,height=800,toolbar=0,scrollbars=0,status=0'
    )
    WinPrint.document.write(printoutNode.innerHTML)
    WinPrint.document.close()
    WinPrint.document.title = `report${Date.now()}.png`
    var buttons = WinPrint.document.getElementsByTagName('button')
    for (let i = buttons.length - 1; i >= 0; i--) {
      buttons[i].remove()
    }
    WinPrint.focus()
    WinPrint.print()
    WinPrint.close()
  }

  return (
    <>
      <Box className={classes.row}>
        <IconButton
          className={classes.iconBtn}
          onClick={handleSaveGraphClick}
          disabled={
            !filteredAlarmNotificationsMSGS ||
            //@ts-ignore
            filteredAlarmNotificationsMSGS?.length === 0
          }
        >
          <Download />
        </IconButton>
        <IconButton
          className={classes.iconBtn}
          onClick={handlePrintGraphReport}
          disabled={
            !filteredAlarmNotificationsMSGS ||
            //@ts-ignore
            filteredAlarmNotificationsMSGS?.length === 0
          }
        >
          <Print />
        </IconButton>

        <NotificationsCharts
          filteredAlarmNotificationsMSGS={
            props.filterNotificationType === 'qualityControl'
              ? filteredAlarmNotificationsMSGS?.map((n) => {
                  let featureLabel = samplingPoints?.find(
                    (sp) => sp?.properties?.code === n?.ms?.featureCode
                  )?.properties?.label
                  return { ...n, featureLabel }
                })
              : []
          }
          samplingPoints={samplingPoints}
          startDate={startDate}
          endDate={endDate}
          filteredSamplingPoints={selectSamplingPointsValue}
        />

        <NotificationsTableDialog
          filteredAlarmNotificationsMSGS={
            props.filterNotificationType === 'qualityControl'
              ? filteredAlarmNotificationsMSGS?.map((n) => {
                  let featureLabel = samplingPoints?.find(
                    (sp) => sp?.properties?.code === n?.ms?.featureCode
                  )?.properties?.label
                  return { ...n, featureLabel }
                })
              : []
          }
          samplingPoints={samplingPoints}
          startDate={startDate}
          endDate={endDate}
          filteredSamplingPoints={selectSamplingPointsValue}
          filterIsActive={filterIsActive}
        />

        <NotificationsFilterDialog
          filterIsActive={filterIsActive}
          filterByAllPersonnel={filterByAllPersonnel}
          filterByLoggedUser={filterByLoggedUser}
          handleCheckboxesChange={handleCheckboxesChange}
          openFilterDialog={openFilterDialog}
          setOpenFilterDialog={setOpenFilterDialog}
          samplingPoints={samplingPoints}
          selectSeverityValue={selectSeverityValue}
          handleSelectSeverityValue={handleSelectSeverityValue}
          selectSamplingPointsValue={selectSamplingPointsValue}
          handleSelectSamplingPoints={handleSelectSamplingPoints}
          selectWaterOutletValue={selectWaterOutletValue}
          handleSelectWaterOutlets={handleSelectWaterOutlets}
          selectedPumpValue={selectedPumpValue}
          handleSelectPumps={handleSelectPumps}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          isLoadingNotifications={isLoadingNotifications}
        />
      </Box>
      <div className={classes.listContainerRow}>
        <ul className={classes.ul} ref={listRef}>
          {filteredAlarmNotificationsMSGS &&
            filteredAlarmNotificationsMSGS
              .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
              .map((n: any) => {
                const alarm = alarms?.find((al) => al._id === n.alarm)
                let locdata: notificationDataType = getLocalizedNotificationContent(n, locale)
                return (
                  <li
                    className={classes.customLi}
                    key={n.code}
                    onClick={(e) => props.handleOpenAlarmPopUp(n, null)}
                  >
                    <div className={classes.listDotContainer}>
                      <div
                        className={
                          !!n.messages
                            ? //@ts-ignore
                              !!n.messages[0].webReadDate
                              ? classes.liDotRead
                              : classes.liDotNew
                            : classes.liDotRead
                        }
                      />
                    </div>
                    <div>
                      <p
                        className={classes.typography}
                        dangerouslySetInnerHTML={createMarkup(
                          locdata.title,
                          locdata.content,
                          n
                        )}
                      />
                      {n.notificationType === 'qualityControl' && (
                        <p className={classes.typography}>
                          <strong>{t('alarms.assignedTo2')}:</strong>
                          {alarm &&
                            alarm.assignedTo.map((a, ind) => {
                              const per = personnels?.find(
                                (p) => p._id === a.personnelsId
                              )
                              return per ? (
                                <span
                                  key={a.personnelsId ? a.personnelsId : ind}
                                >{` ${per.firstname} ${per.lastname} - ${t(
                                  'alarms.assignedTo.phone'
                                )}: ${per.mobile ? per.mobile : '""'}. `}</span>
                              ) : null
                            })}
                        </p>
                      )}
                    </div>
                  </li>
                )
              })}
        </ul>
        <PaginationCustom
          data={filteredAlarmNotificationsMSGS}
          currentPage={currentPage}
          handleNextClick={handlePaginationNextClick}
          handlePreviousClick={handlePaginationPreviousClick}
          handleFirstClick={handlePaginationFirstClick}
          handleLastClick={handlePaginationLastClick}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div>
    </>
  )
}
