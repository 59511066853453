import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/'
import { Typography, FormControlLabel, Box, TextField } from '@material-ui/core/'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'

const useStyles = makeStyles(theme => ({
  byLoacationRoot: {
    color: 'black',
    display: 'flex',
    flexFlow: 'column',
  },
  coordinateSystemLabel: {
    marginRight: '1rem',
  },
  textField: {
    width: '95%',
  },
  textFieldDegrees: {
    width: '4.5rem',
  },
  textFieldMinutes: {
    width: '4rem',
  },
  textFieldSeconds: {
    width: '6.5rem',
  },
  contentRaw: {
    display: 'flex',
    width: '100%',
  },
  contentColumnLeft: {
    width: '30%',
    height: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  contentColumnMiddle: {
    width: '60%',
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  contentColumnRight: {
    width: '30%',
    height: '3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  mt2: {
    marginTop: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
  },
  divider: {
    border: 'none',
    height: '1px',
    margin: '8px 0',
    width: '100%',
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}))

interface SearchByLocationProps {
  coordinatesSystem: string
  longitude: string
  latitude: string
  lngDegrees: any
  latDegrees: any
  radius: string
  wgsUnits: string
  setLongitude: React.Dispatch<React.SetStateAction<string>>
  setLatitude: React.Dispatch<React.SetStateAction<string>>
  setLngDegrees: React.Dispatch<any>
  setLatDegrees: React.Dispatch<any>
  setRadius: React.Dispatch<React.SetStateAction<string>>
  handleCoordinateSystemChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleWgs84UnititsChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function SearchByLocation({
  coordinatesSystem,
  longitude,
  latitude,
  lngDegrees,
  latDegrees,
  radius,
  wgsUnits,
  setLongitude,
  setLatitude,
  setLngDegrees,
  setLatDegrees,
  setRadius,
  handleCoordinateSystemChange,
  handleWgs84UnititsChange,
}: SearchByLocationProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const inputPropsMinutesAndSeconds = { step: 1, min: 0, max: 59.9999 }

  return (
    <Box className={classes.byLoacationRoot}>
      <Box className={classes.contentRaw}>
        <Box className={classes.contentColumnLeft}>
          <Typography className={classes.coordinateSystemLabel}>{t('units.coordinateSystem')}</Typography>
        </Box>
        <Box className={classes.contentColumnMiddle}>
        <CheckBoxNt
          checked={coordinatesSystem === 'egsa87'}
          onChange={handleCoordinateSystemChange}
          name='egsa87'
          label='ΕΓΣΑ 87 ( Χ, Υ)'
        />
        </Box>
        <Box className={classes.contentColumnRight}>
          <CheckBoxNt checked={coordinatesSystem === 'wgs84'} onChange={handleCoordinateSystemChange} name='wgs84' label={'units.wgs84'} />
        </Box>
      </Box>
      <Box className={classes.contentRaw}>
        <Box className={classes.contentColumnLeft}>
          <Typography align='right' className={classes.coordinateSystemLabel}>
            {coordinatesSystem === 'wgs84' ? t('units.wgs84.lng') : t('units.egsa87.lng')}
          </Typography>
        </Box>
        <Box className={classes.contentColumnMiddle}>
          {wgsUnits !== 'dms' && (
            <TextField
              id='longitude'
              variant='outlined'
              type='number'
              required
              fullWidth
              size='small'
              className={classes.textField}
              placeholder={coordinatesSystem === 'wgs84' ? 'λλ.λλλλλλ' : 'χχχχχχ.χχχ'}
              value={longitude}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLongitude(e.target.value)}
            />
          )}
          {coordinatesSystem === 'wgs84' && wgsUnits === 'dms' && (
            <>
              <TextField
                id='longitude-degrees'
                variant='outlined'
                type='number'
                required
                inputProps={{ step: 1, max: 180, min: -180 }}
                size='small'
                className={classes.textFieldDegrees}
                placeholder='°' //'λλ'
                value={lngDegrees.d}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLngDegrees({ ...lngDegrees, d: e.target.value })
                }}
              />
              <TextField
                id='longitude-minutes'
                variant='outlined'
                type='number'
                required
                inputProps={inputPropsMinutesAndSeconds}
                size='small'
                className={classes.textFieldMinutes}
                placeholder="'" //'λλ'
                value={lngDegrees.m}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLngDegrees({ ...lngDegrees, m: e.target.value })
                }
              />
              <TextField
                id='longitude-seconds'
                variant='outlined'
                type='number'
                required
                inputProps={inputPropsMinutesAndSeconds}
                size='small'
                className={classes.textFieldSeconds}
                placeholder="''" //'λλ.λλλλ'
                value={lngDegrees.s}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLngDegrees({ ...lngDegrees, s: e.target.value })
                }
              />
            </>
          )}
        </Box>
        <Box className={classes.contentColumnRight}>
          {coordinatesSystem === 'wgs84' && (
            <FormControlLabel
              control={<CheckBoxNt checked={wgsUnits === 'dms'} onChange={handleWgs84UnititsChange} name='dms' />}
              label={t('units.degrees')}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.contentRaw}>
        <Box className={classes.contentColumnLeft}>
          <Typography align='right' className={classes.coordinateSystemLabel}>
            {coordinatesSystem === 'wgs84' ? t('units.wgs84.lat') : t('units.egsa87.lat')}
          </Typography>
        </Box>
        <Box className={classes.contentColumnMiddle}>
          {wgsUnits !== 'dms' && (
            <TextField
              id='latidute'
              variant='outlined'
              type='number'
              required
              fullWidth
              size='small'
              placeholder={coordinatesSystem === 'wgs84' ? 'φφ.φφφφφφ' : 'yyyyyyy.yyy'}
              className={classes.textField}
              value={latitude}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLatitude(e.target.value)}
            />
          )}
          {coordinatesSystem === 'wgs84' && wgsUnits === 'dms' && (
            <>
              <TextField
                id='latitude-degree'
                variant='outlined'
                type='number'
                required
                inputProps={{ step: 1, max: 90, min: -90 }}
                size='small'
                className={classes.textFieldDegrees}
                placeholder='°' //'φφ'
                value={latDegrees.d}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLatDegrees({ ...latDegrees, d: e.target.value })
                }
              />
              <TextField
                id='latitude-minute'
                variant='outlined'
                type='number'
                required
                inputProps={inputPropsMinutesAndSeconds}
                size='small'
                className={classes.textFieldMinutes}
                placeholder="'" //'φφ'
                value={latDegrees.m}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLatDegrees({ ...latDegrees, m: e.target.value })
                }
              />
              <TextField
                id='latitude-second'
                variant='outlined'
                type='number'
                required
                inputProps={inputPropsMinutesAndSeconds}
                size='small'
                className={classes.textFieldSeconds}
                placeholder="''" //'φφ.φφφφ'
                value={latDegrees.s}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLatDegrees({ ...latDegrees, s: e.target.value })
                }
              />
            </>
          )}
        </Box>
        <Box className={classes.contentColumnRight}>
          {coordinatesSystem === 'wgs84' && (
            <FormControlLabel
              className={classes.mt2}
              control={
                <CheckBoxNt
                  checked={wgsUnits === 'decimalDegrees'}
                  onChange={handleWgs84UnititsChange}
                  name='decimalDegrees'
                />
              }
              label={t('units.decimalDegrees')}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.contentRaw}>
        <Box className={classes.contentColumnLeft}>
          <Typography align='right' className={classes.coordinateSystemLabel}>
            {t('units.radius')}
          </Typography>
        </Box>
        <Box className={classes.contentColumnMiddle}>
          <TextField
            id='radius'
            variant='outlined'
            type='number'
            required
            fullWidth
            size='small'
            className={classes.textField}
            placeholder={t('units.radius') as string}
            value={radius}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRadius(e.target.value)}
          />
        </Box>
        <Box className={classes.contentColumnRight}></Box>
      </Box>
    </Box>
  )
}
