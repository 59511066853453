import TemplateService from './TemplateService'

const WaterLossService = () => {

  const templateService: any = TemplateService('waterLoss', 'waterLosses')

  return({
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  })

}

export default WaterLossService
