import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import TemplateService from './TemplateService'

const ActivityService = () => {

  const apiClient = useCommonStore.getState().apiClient
  const profile = useCommonStore.getState().profile

  const templateService: any = TemplateService('activity', 'activities')

  const path = 'activity/'

  const saveActivityAction = (entity: any, action: any) => {
    let isNewSlot = !action._id
    if (isNewSlot) {
      action.addedBy = profile?.user_id
    } else {
      action.modifiedBy = profile?.user_id
    }
    let data: any = {
      activity_id: entity._id,
      action: action,
    }
    if (isNewSlot) {
      data.operation = 'addActivityAction'
    } else {
      data.operation = 'updateActivityAction'
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('activities', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }
  const deleteActivityAction = (entity: any, action: any) => {
    let data: any = {
      operation: 'deleteActivityAction',
      activity_id: entity._id,
      action: action,
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('activities', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }

  const saveActivityActionAttachment = (entity: any, action: any, attachment: any) => {
    let data: any = {
      attachment: attachment,
      activity_id: entity._id,
      action_id: action._id,
    }
    let isNewAttachment = !attachment._id
    if (isNewAttachment) {
      data.operation = `addActivityActionAttachment`
      attachment.addedBy = profile?.user_id
      attachment.ownerCode = entity.code
    } else {
      data.operation = `updateActivityActionAttachment`
      attachment.modifiedBy = profile?.user_id
      attachment.ownerCode = entity.code
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('activities', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }
  const deleteActivityActionAttachment = (entity: any, action: any, attachment: any) => {
    let data: any = {
      operation: `deleteActivityActionAttachment`,
      attachment: attachment,
      activity_id: entity._id,
      action_id: action._id,
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('activities', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }

  const sendRequest = (data: any) => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
    saveEntityAttachment: templateService.saveEntityAttachment,
    deleteEntityAttachment: templateService.deleteEntityAttachment,
    saveActivityAction: saveActivityAction,
    deleteActivityAction: deleteActivityAction,
    saveActivityActionAttachment: saveActivityActionAttachment,
    deleteActivityActionAttachment: deleteActivityActionAttachment,
  })

}

export default ActivityService
