import React, { useState, useEffect, useRef, useMemo } from 'react'
import _ from 'lodash'
import 'leaflet-editable'
import 'leaflet.markercluster'
import L, { LatLngTuple } from 'leaflet'
import { useMapEvents, MapContainer, TileLayer, LayersControl, WMSTileLayer, Popup, ZoomControl, Circle } from 'react-leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import clsx from 'clsx'
import { makeStyles, useTheme, } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import { 
  Box,
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core/'

import { useTranslation } from 'react-i18next'
import { useUiStore } from '../state/uiStore'
import { useCommonStore } from '../state/commonStore'
import { useCollectionStore } from '../state/collectionStore'
import { useNetworkElementStore } from '../state/networkElementStore'
import useFeatureCollection from '../hooks/useFeatureCollection'
import FeatureService from '../services/FeatureService'
import gutils from '../utils/geometry_utils'
import {
  ALARM_COLOR,
  BaseMapLayers,
  DEFAULT_COLOR,
  DRAWN_LAYER_COLOR,
  FTC,
  LAYERS_THAT_DONT_SNAP_TO_PIPES,
  LEGEND_COLORS,
  LOCATION_HIGHLIGHT_COLOR,
  SELECTED_COLOR,
} from '../utils/constants'
import { alphabeticalSorter } from '../utils/helpers'
import useCoords from '../utils/useCoords'
import MapService from '../services/MapService'
import FeatureDetails from './network/FeatureDetails'
import FlowDirectionPopUp from './network/FlowDirectionPopUp'
import NotificationPopUp from './personnelNotifications/NotificationPopUp'
import WrmIcon from './WrmIcon'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  mapToolbar: {
    position: 'absolute',
    top: theme.spacing(7),
    right: theme.spacing(1),
  },
  featureMarkerDiv: {
    backgroundColor: 'inherit',
    '&:hover': {
      color: theme.palette.warning.main,
    },
  },
  featureClusterMarkerDivDefault: {
    color: '#1defd9',
  },
  featureMarkerDivDefault: {
    color: DEFAULT_COLOR
  },
  featureMarkerDivFreeDraw: {
    color: 'green'
  },
  featureMarkerInnerSvg: {
    // '&:hover': {
    //   stroke: 'black',
    //   fill: 'blue',
    // },
  },
  tgBtn: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
    borderRadius: 5,
    padding: '6px !important',
    marginRight: 2,
  },
  tBtn: {
    zIndex: 1000,
    color: theme.palette.tertiary.light,
    backgroundColor: '#F5F5F5',
    '&:hover': {
      backgroundColor: '#D5D5D5',
    },
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  tBtnSelected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lighter,
    '&:hover': {
      backgroundColor: '#BAEEEE',
    },
  },
  tooltip: {
    fontSize: 14,
  },
  legendItemContainer:{
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  legendTitle: {
    top: '-40px',
    left: '-20%',
    width: '250px',
    position: 'absolute',
    fontSize: '1rem',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '11px',
    width: '125px',
    height: '42px',
    borderRadius: '5px',
    margin: '0.3rem 0'
  },
  legendPaper:{
    margin: '32px',
    position: 'relative',
    marginRight: '4rem',
    backgroundColor: theme.palette.tertiary.light,
    overflowY:'visible'
  },
  legendScrollPaper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  legendContent: {
    padding:'0.5rem !important'
  },
  legendWaterSupply: { backgroundColor: LEGEND_COLORS.waterSupply },
  legendAgriculture: { backgroundColor: LEGEND_COLORS.agriculture },
  legendIndustrial: { backgroundColor: LEGEND_COLORS.industrial },
  legendEnergy: { backgroundColor: LEGEND_COLORS.energy },
  legendRecreation:{ backgroundColor: LEGEND_COLORS.recreation },
  legendDrainage: { backgroundColor: LEGEND_COLORS.drainage },
  legendDefault: { backgroundColor: LEGEND_COLORS.default },

  waterSupplyIcon: { color: LEGEND_COLORS.waterSupply },
  agricultureIcon: { color: LEGEND_COLORS.agriculture },
  industrialIcon: { color: LEGEND_COLORS.industrial },
  energyIcon: { color: LEGEND_COLORS.energy },
  recreationIcon:{ color: LEGEND_COLORS.recreation },
  drainageIcon: { color: LEGEND_COLORS.drainage },
  searchByLocationHighlight :{color: LOCATION_HIGHLIGHT_COLOR},

  "@keyframes pulse": {
    "0%": {
      strokeWidth: 6,
      stroke: "#ff1100cc"
    },
    "70%": {
        strokeWidth: 14,
        stroke: "#ff110099"
    },
    "100%": {
       strokeWidth: 6,
      stroke: "#ff1100cc"
    }
  },
  alarm:{
    animation: "$pulse 2s infinite"
  },
  customSnackbar: {
    display: 'flex',
    flexFlow:'column',
    zIndex: 1400,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth:'50vw',
    top: '24px',
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)',
    backgroundColor:'#3D3E3E',
    opacity:0.9,
    color:'#fff',
    borderRadius: '8px',
    padding: '0.25rem 0.5rem'
  },
}))

/** drawingLayerKey is a boolean(false by default) it is set to true only when the user is drawing a new pipe
 * when drawingLayerKey changes from true to false useFeatureEffect is triggered. If its value is true
 * then the callback(leafGeoJsonLayer) recreates each layer without the onEachFeature option,
 * this ensures that the clickFeature() doesn't block snapping on markers while drawing a new pipe */
// function useFeatureEffect(key, networkElementCollection, accessGranted, featureCallback, featuresWithActiveAlarms, drawingLayerKey, featuresFilter) {
function useFeatureEffect(options) {
  const {key, networkElementCollection, accessGranted, featureCallback, featuresWithActiveAlarms, drawingLayerKey, featuresFilter, map} = options
  useEffect(() => {
    if (!(accessGranted('vwntwr') || accessGranted('dtNtwr3'))) {
      return
    }
    let withFilter = !!networkElementCollection[key] && networkElementCollection[key]?.length > 100
    featureCallback(key, withFilter)
  // }, [networkElementCollection[key], featuresFilter, featuresWithActiveAlarms, drawingLayerKey])
  }, [options.networkElementCollection[options.key], options.featuresFilter, options.featuresWithActiveAlarms, options.drawingLayerKey, map])
}

const LMap = (props) => {
  const showAlert = useCommonStore(state => state.showAlert)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)
  const featureService = FeatureService()
  const XYZ = useCoords()
  const appSettings = useCollectionStore(state=> state.appSettings)
  const [urgentAlarmsNotificationsGroupedByFeatureType,setUrgentAlarmsNotificationsGroupedByFeatureType]=useState<any>(null)
  const networkElementsFilter = useUiStore(state => state.networkElementsFilter)
  const doDeleteFreeGeometry = useNetworkElementStore(state => state.doDeleteFreeGeometry)
  const selectedEntity = useUiStore(state => state.selectedEntity)
  const actSelectFeature = useUiStore(state => state.actSelectFeature)
  const setDoDeleteFreeGeometry = useNetworkElementStore(state => state.setDoDeleteFreeGeometry)
  const networkElementCollection = useFeatureCollection()
  const zustandMapRef = useUiStore(state => state.mainMap)
  const setMainMap = useUiStore(state => state.setMainMap)
  const circleMarkers = useUiStore(state => state.circleMarkers)
  const setCircleMarkers = useUiStore(state => state.setCircleMarkers)
  const flyTo = useUiStore(state => state.flyTo)
  /* used to access the flow direction arrows layer */
  // let flowDirectionArrowsRef = useRef(null)
  /** used when dragging a feature that is connected to a pipe,
   *  in order to show an alert notifying the user that the element was disconnected from the pipe,
   *  at the first dragStart it is set to true, afterwards it is reset to false so that the message is shown only once*/
  let isFirstDragEdit = useRef(false)

  const leafstateRef = useRef<any>({
    map: null,
    layers: {},
    selectedFeature: null,
    editing: false,
    editingFeature: null,
    drawing: false,
    drawingFeature: null,
    drawingLayer: null,
    drawingLayerKey: null,
    drawingGeometryType: null,
    lastPointCoords: null,
    ghostLineLayer: null,
    ghostPolyLayer: null,
    drawnLayer: null,
    featureDetailsTab: null,
    isFeatureDetailsDocked: false,
    mapLayerClicked: false,
    hasLocation: false,
    latlng: {
      lat: 37.98,
      lng: 23.73,
    },
    coordsControl: null,
    tooltips: [],
  }
  )
  const leafstate: any = leafstateRef.current
  const mapCenter = leafstate.latlng
  const lmap = zustandMapRef//leafstate.map
  

  //@ts-ignore
  L.Control.MyControl = L.Control.extend({
    onAdd: function(map) {
      var el = L.DomUtil.create('div', 'coodinates-control')
      el.innerHTML = leafstate.mouseXY
      // el.innerHTML = leafstateRef.current.mouseXY
      return el
    },
    onRemove: function(map) {
      // Nothing to do
    }
  })
  //@ts-ignore
  L.control.myControl = function(opts) {
    //@ts-ignore
    return new L.Control.MyControl(opts)
  }

  const featureDetailsTab = useUiStore(state => state.featureDetailsTab)
  const isFeatureDetailsDocked = useUiStore(state => state.isFeatureDetailsDocked)
  const isEditingGeometry = useUiStore(state => state.isEditingGeometry)
  const setEditingFeature = useUiStore(state => state.setEditingFeature)
  const isDrawingGeometry = useUiStore(state => state.isDrawingGeometry)
  const setDrawingFeature = useUiStore(state => state.setDrawingFeature)
  const drawing_id = useUiStore(state => state.drawing_id)
  const setDrawing_id = useUiStore(state => state.setDrawing_id)
  const doSaveGeometry = useUiStore(state => state.doSaveGeometry)
  const setDoSaveGeometry = useUiStore(state => state.setDoSaveGeometry)
  const doCancelGeometry = useUiStore(state => state.doCancelGeometry)
  const setDoCancelGeometry = useUiStore(state => state.setDoCancelGeometry)
  const doCancelDrawing = useUiStore(state => state.doCancelDrawing)
  const setDoCancelDrawing = useUiStore(state => state.setDoCancelDrawing)
  const actEditingGeometry = useUiStore(state => state.actEditingGeometry)
  const actDrawingGeometry = useUiStore(state => state.actDrawingGeometry)


  /**Clean up useEffect that will remove the zuMapRef/circles if the <Lmap> is unmounted */
  useEffect(() => {
    return () => {
      // Set mainMap to null when the component is unmounted
      setMainMap(null);
      // Empty all lefover CircleMarkers from the map
      setCircleMarkers([])
    };
  }, []);

  /** capture mouse coords on the map on mouseMove*/
  useEffect(()=>{
    const coordinatesSystem = appSettings.coordinatesSystem

    const captureXY = _.debounce((e) => {
      const lngLat = [e.latlng.lng,e.latlng.lat]
      let xyz = XYZ(coordinatesSystem, lngLat)
      switch (coordinatesSystem) {
        case 'wgs84':
          xyz[0] = t('userSettings.coords.lng') +':' + Number(xyz[0]).toFixed(7)
          xyz[1] = " " + t('userSettings.coords.lat') +':' + Number(xyz[1]).toFixed(7)
          break
        case 'wgs84DMS':
          xyz[0] = t('userSettings.coords.lng') +':' + xyz[0]
          xyz[1] = " " + t('userSettings.coords.lat') +':' + xyz[1]
          break
        case 'egsa87':
          xyz[0] = 'X:' + Number(xyz[0]).toFixed(3)
          xyz[1] = ' Y:' + Number(xyz[1]).toFixed(3)
          break
        default:
      }
      leafstate.mouseXY = xyz.join(',')

      if (!!leafstate.coordsControl) {
        leafstate.coordsControl.remove()
      }
      //@ts-ignore
      leafstate.coordsControl = L.control.myControl({
        position: 'bottomright'
      })
      leafstate.coordsControl.addTo(lmap)
    }, 50)

    if (!!lmap) {
      lmap.on('mousemove', captureXY)
    }

    return () => {
      if (!!lmap) {
        lmap.removeEventListener('mousemove', captureXY)
      }
    }
  },[appSettings.coordinatesSystem, leafstateRef.current.map])

  /** Enable Geoman snap for pipes */
  useEffect(()=>{
    if (!!lmap) {
      //GEOMAN CONTROLS - enable this for debugging draw/edit
      // lmap.pm.addControls({
      //   drawMarker: false,
      //   drawCircleMarker: false,
      //   // drawPolyline: false,
      //   drawRectangle: false,
      //   drawPolygon: false,
      //   editMode: false,
      //   dragMode: false,
      //   cutPolygon: false
      // })

      /* localize geoman in greek (el), currently the tooltip is disabled because
      the tooltip text on create line is wrong (click on an existing marker to close)
      so when/if the geoman team fixes this issue it can be uncommented (and the tooltips:false should be removed from the options)*/
      // lmap.pm.setLang('el')
      lmap.pm.setGlobalOptions({ pmIgnore: false, snapDistance:35, tooltips:false })

      /** Catch double click that finilizes the drawing feature and adds it to the map */
      lmap.on('pm:create', (e) => {
        const layer = e.layer
        layer.remove()
      });
      // listen to vertexes being added to currently drawn layer (called workingLayer)
      lmap.on('pm:drawstart', ({ workingLayer }) => {
        /** Store the last feature interacted with the mouse (the last feature snapped to the cursor)*/
        let lastFeatureInteractedWith:any = null
        /** Coordinates of the vertex of the last snap */
        let snapCoords:any = null
        let inNode:any = null
        let outNode:any = null
        workingLayer.on('pm:snap', (e) => {
            if ( e.layerInteractedWith?.feature?.properties?.featureType !== 'station') {
              lastFeatureInteractedWith = e.layerInteractedWith.feature
              snapCoords = e.snapLatLng
            }
        })

        workingLayer.on('pm:vertexadded', (e) => {
          let feature: null | any = null
          if (!lmap) return
          /**catch snapped Start Node or End Node */
          if (
            !!snapCoords &&
            e.latlng.lat === snapCoords.lat &&
            e.latlng.lng === snapCoords.lng
          ) {
            if (
              !leafstate.drawingLayer ||
              leafstate.drawingLayer?.feature?.geometry?.coordinates?.length === 0
            ) {
              inNode = lastFeatureInteractedWith
            } else {
              outNode = lastFeatureInteractedWith
            }
          } else {
            inNode = null
            outNode = null
          }

          let coords = [e.latlng.lng, e.latlng.lat]
          leafstate.lastPointCoords = coords

          if (!!leafstate.drawingFeature) {
            feature = leafstate.drawingFeature
          } else {
            feature = {
              isNew: true,
              _id: `${drawing_id + 1}`,
              type: 'Feature',
              properties: {},
              geometry: {
                type: leafstate.drawingGeometryType,
                coordinates: [],
              },
            }
            let prefix = leafstate.drawingGeometryType
            if (leafstate.drawingLayerKey !== 'drawnLayer') {
              feature.properties.featureType = leafstate.drawingLayerKey
              prefix = leafstate.drawingLayerKey
            }
            feature.properties.code = `${prefix}_${drawing_id + 1}`
            feature.properties.label = `${prefix}_${drawing_id + 1}`
            feature.properties.flowDirection = 'normal'

            leafstate.drawingFeature = feature
          }

          if (leafstate.drawingGeometryType === 'LineString') {
            feature.geometry.coordinates.push(coords)
          }
          if (inNode) {
            feature.properties.inNodeCode = inNode.properties.code
            feature.properties.inNodeFeatureType  = inNode.properties.featureType
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + inNode.properties.featureType)} - (${inNode.properties.label}) ${t("map.createPipe.toast.isInNode")}`,'S')
          }
          if (outNode) {
            feature.properties.outNodeCode = outNode.properties.code
            feature.properties.outNodeFeatureType  = outNode.properties.featureType
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + outNode.properties.featureType)} - (${outNode.properties.label}) ${t("map.createPipe.toast.isOutNode")}`,'S')
          }
          getPointElevationAsync(coords).then(res => {
            let options = {
              style: f => {
                let st: any = {
                  cursor: 'crosshair',
                }
                if (leafstate.drawingLayerKey === 'drawnLayer') {
                  st.color = DRAWN_LAYER_COLOR
                }
                return st
              },
              pointToLayer: pointToLayer,
              onEachFeature: onEachFeature,
              //without snapIgnore: true creating a new feature will break leaflet-geoman
              //cause it will try to snap to itself(the line beeing created) even when the geometry is empty
              //see https://github.com/geoman-io/leaflet-geoman/issues/540
              snapIgnore: true,
            }

            removeLayerFrom(leafstate.drawingLayer, leafstate.drawnLayer)
            leafstate.drawingLayer = L.geoJSON(feature, options).getLayers()[0]
            leafstate.drawnLayer.addLayer(leafstate.drawingLayer)

            feature.transient_length = computeFeatureLength(
              feature,
              leafstate.drawingLayer
            )
            feature.transient_area = computeFeatureArea(feature)
            feature.transient_elevationDiff = computeFeatureElevationDiff(
              feature,
              leafstate.drawingLayer
            )

            setDrawing_id(drawing_id + 1)
            //clone feature to trigger re-render...
            const theFeature = { ...feature }
            setDrawingFeature(theFeature)
            removeLayerFrom(leafstate.ghostLineLayer, leafstate.drawnLayer)
            removeLayerFrom(leafstate.ghostPolyLayer, leafstate.drawnLayer)
            leafstate.ghostLineLayer = null
            leafstate.ghostPolyLayer = null

            if (outNode) {
              //if outNode finilize shape and stop draw
              setDoSaveGeometry(true)
              props.setFlowPopupIsOpen(true)
            }
          })
        });
      });
    }
  },[lmap])

  useEffect(() => {
    if (props.urgentAlarmNotifications.length > 0) {
      const sortedMS:any = {}
      Object.keys(FTC).forEach(e => (sortedMS[e] = []))

      props.urgentAlarmNotifications.forEach(el => {
        if (!!el?.ms?.featureType) {
          sortedMS[el.ms.featureType].push(el)
        } else if (!!el.featureType) {
          //some notifications like "irriEventPersonnel" don't have measurement series, so they are
          //enchanced before pushing them into urgentAlarmsNotificationsGroupedByFeatureType
          el.ms =  { featureCode: el.featureCode } //Yes, this is exactly what it looks like, we mutate the react/zustand state without using setState 
          // sortedMS[el.featureType].push({ ...el, ms: { featureCode: el.featureCode } })
          sortedMS[el.featureType].push( el )
        } else if (!!el.switchCode) {
          //Legacy irrigation notifications don't have featureType/featureCode instead they have the switchCode property
          el.ms =  { featureCode: el.switchCode } //Yes, this is exactly what it looks like, we mutate the react/zustand state without using setState 
          sortedMS.switch.push(el)
          // sortedMS.switch.push({ ...el, ms: { featureCode: el.switchCode } })
        }
      })
      setUrgentAlarmsNotificationsGroupedByFeatureType(sortedMS)
    } else if (
      props.urgentAlarmNotifications.length === 0 &&
      !!urgentAlarmsNotificationsGroupedByFeatureType
    ) {
      //clear any urgentNotifications leftovers from state
      setUrgentAlarmsNotificationsGroupedByFeatureType(null)
    }
  }, [props.urgentAlarmNotifications.length])
  useEffect(() => {//leaflet-editable
    if (!!lmap) {
      lmap.editTools.on('editable:vertex:deleted', handleEditingEvent)
      lmap.editTools.on('editable:vertex:dragend', handleEditingEvent)
      lmap.editTools.on('editable:dragstart', handleEditingEvent)
      lmap.editTools.on('editable:dragend', handleEditingEvent)
      leafDrawnLayer()
    } else {
      console.log(`\n*** Could not set editing event handlers ***\n`)
    }
  }, [lmap])
  useEffect(() => {
    //center map to tenant center
    if (!!leafstate.map && appSettings.mapCenter) {
      let latlng = appSettings.mapCenter
      leafstate.latlng = panMapToLatLng(latlng)
      console.log(`New map center: ${JSON.stringify(leafstate.latlng,null,2)}`)
    }
  }, [appSettings?.mapCenter, leafstate?.map])
  useEffect(() => {
    leafSelectedFeature(selectedEntity)
  }, [selectedEntity])
  useEffect(() => {
    leafstate.isFeatureDetailsDocked = isFeatureDetailsDocked
    if (!isFeatureDetailsDocked) {
      if (!!selectedEntity) leafSelectedFeature(selectedEntity)
    } else {
      closeSelectedFeaturePop()
    }
  }, [isFeatureDetailsDocked])
  useEffect(() => {
    leafstate.featureDetailsTab = featureDetailsTab
  }, [featureDetailsTab])
  useEffect(() => {
    if (isEditingGeometry && !leafstate.editing) {
      let feature = leafstate.selectedFeature

      //if the feature's collection is hidden (by user choice), do not edit and show a message.
      let featureType = feature.properties.featureType
      if (!props.visibleLayers.includes(FTC[featureType].collection)) {
        showAlert(t('msg.alert.edit.geometry.hiddenlayer'), 'W')
        effectCancelFeature()
        return
      }

      makeFeatureVisible(feature, makeSelectedFeatureEditable)
    }
  }, [isEditingGeometry])
  useEffect(() => {
    if (!!lmap) {
      Object.keys(leafstate.layers).forEach(key => {
        let shouldShow = shouldShowLayer(key)
        let layer = leafstate.layers[key]
        if (!shouldShow && layer && lmap.hasLayer(layer)) {
          lmap.removeLayer(layer)
        } else if (shouldShow && layer && !lmap.hasLayer(layer)) {
          /**Disable snap for layers that should not be connected to pipes*/
          if (LAYERS_THAT_DONT_SNAP_TO_PIPES.includes(key)) {
            layer.options.pmIgnore = true
          }
          lmap.addLayer(layer)
        }
      })
    }
  }, [props.visibleLayers])
  useEffect(() => {
    if (!!leafstate.selectedFeature) {
      makeFeatureVisible(leafstate.selectedFeature)
    }
  }, [props.target_id])
  useEffect(() => {
    if (doSaveGeometry && (isDrawingGeometry || isEditingGeometry)) {
      effectSaveFeature()
    }
  }, [doSaveGeometry])
  useEffect(() => {
    if (doCancelGeometry && (isDrawingGeometry || isEditingGeometry)) {
      effectCancelFeature()
    }
  }, [doCancelGeometry])
  useEffect(() => {
    if (doCancelDrawing && isDrawingGeometry) {
      effectCancelDrawing()
    }
  }, [doCancelDrawing])
  useEffect(() => {
    if (doDeleteFreeGeometry) {
      effectDeleteFreeGeometry()
    }
  }, [doDeleteFreeGeometry])
  useEffect(()=>{
    //Zooms to coordinates when SearchByLocation is triggered
    if (!!lmap && !!networkElementsFilter.locationFilter.isActive && !!networkElementsFilter.locationFilter.center) {
      lmap.flyTo(new L.LatLng(+networkElementsFilter.locationFilter.center[0], +networkElementsFilter.locationFilter.center[1], 0) )
    }
  },[networkElementsFilter.locationFilter.isActive, networkElementsFilter.locationFilter.center])
  useEffect(()=>{
    if (props.selectedFeatureWithActiveAlarms && props.selectedFeatureWithActiveAlarms.feature) {
      if (!!lmap) {
        const currentZoom = lmap.getZoom()
        //Zoom in only if needed during flyTo (17 is an arbitary zoom level, nothing magic about it)
        const flyToZoom = currentZoom >= 17 ? currentZoom : 17
        const feature = props.selectedFeatureWithActiveAlarms.feature
        // panMapToLatLng([feature.geometry.coordinates[1],feature.geometry.coordinates[0]]) //can't get the xOffset to work properly so migrating to flyTo
        lmap.flyTo([feature.geometry.coordinates[1],feature.geometry.coordinates[0]],flyToZoom)
      }
    }
  },[props.selectedFeatureWithActiveAlarms])
  useEffect(()=>{
    if (props.flowPopupIsOpen) {
      const feature = leafstate.selectedFeature
      if (feature) {
        let featureCenter = L.geoJSON(feature).getBounds().getCenter()
        panMapToFeature(feature)
        //zo0om to feature Visibility
        setSelectedFeaturePointCoords([featureCenter.lng, featureCenter.lat])
      }
    }
  },[props.flowPopupIsOpen,leafstateRef.current.selectedFeature])

  const shouldShowLayer = (key) => {
    if (!lmap || !props.visibleLayers.includes(key)) return false
    let ftc = Object.values<any>(FTC).find(t => t.collection === key)
    if (!ftc) return false
    if (ftc.accessRight && !accessGranted(ftc.accessRight)) return false
    /**if we are drawing pipes the drawing layer should be always visible regardless of zoom */
    if (
      key === 'pipes' &&
      (leafstate.editingFeature?.properties?.featureType === 'pipe' ||
      leafstate.drawingFeature?.properties?.featureType === 'pipe')
    ) {
      return true
    }
    let mapZoomLevel = lmap.getZoom()
    if (!ftc.minZoom) return true
    return mapZoomLevel >= parseInt(ftc.minZoom)
  }
  /**
  * Converts a feature collection to a GeoJSON layer.
  * If a layer with the same key already exists then it is replaced.
  * params
  * key: the collection name, e.g. 'tanks'
  * withFilter: if true only the collection features within the map's viewing area
  *   are included in the layer (viewing-area-filtering).
  */

  const leafGeoJsonLayer = (key, withFilter?) => {
    if (!!lmap) {
      let options: any = {
        style: getDeafaultFeatureStyle,
        pointToLayer: pointToLayer,
        // onEachFeature: onEachFeature,
        // snapIgnore: true //debugging only
      }
      /**If the user is drawing a new pipe then remove the onEachFeature to ensure snapping works properly */
      if (leafstate.drawingLayerKey !== 'pipe') {
        options.onEachFeature = onEachFeature
      }
      /**Disable snap for layers that should not be connected(snapped) to pipes, sadly this doesn't work on point featurs(stations)*/
      if (LAYERS_THAT_DONT_SNAP_TO_PIPES.includes(key)) options.snapIgnore= true

      let filterCallbacks: any[] = []
      if (withFilter) {
        filterCallbacks.push(mapViewAreaFilter)
      }
      if (networkElementsFilter.isActive && networkElementsFilter.hideFeatures) {
        filterCallbacks.push(searchByLocationOrAttributesFilter)
      }
      if (filterCallbacks.length > 0) {
        options.filter = featureFilterGenerator(filterCallbacks)
      }
      let layer = L.geoJSON(
        networkElementCollection[key],
        options
      )

      if (key === 'hydrometers') {
        //@ts-ignore
        var markers = L.markerClusterGroup({
          // spiderfyOnMaxZoom: false,
          // showCoverageOnHover: false,
          zoomToBoundsOnClick: false,
          disableClusteringAtZoom: 24,
        	iconCreateFunction: function(cluster) {
            let isCluster = true
            return featureMarker('', 'hydrometer', isCluster)
        	},
        })
        markers.on('clusterclick', function (a) {
        	a.layer.spiderfy()
        })

        markers.addLayers(layer.getLayers())
        layer = markers
      }
      // //TODO: Consider using marker clustering for non-hydrometer featureTypes as well
      // else if (props[key] && props[key].features.length > 100) {
      //   let featureTypeInfo = Object.values(FTC).find(f => f.collection === key)
      //   let featureType = featureTypeInfo ? featureTypeInfo.code : ''
      //   var markers = L.markerClusterGroup({
      //     zoomToBoundsOnClick: false,
      //     disableClusteringAtZoom: 22,
      //   	iconCreateFunction: function(cluster) {
      //       let isCluster = true
      //       return featureMarker('', featureType, isCluster)
      //   	},
      //   })
      //   markers.on('clusterclick', function (a) {
      //   	a.layer.spiderfy()
      //   })
      //   markers.addLayers(layer.getLayers())
      //   layer = markers
      // }

      let oldlayer = leafstate.layers[key]
      if (oldlayer && lmap.hasLayer(oldlayer)) {
        lmap.removeLayer(oldlayer)
      }
      leafstate.layers[key] = layer
      let shouldShow = shouldShowLayer(key)
      if (!shouldShow && layer && lmap.hasLayer(layer)) {
        lmap.removeLayer(layer)
      } else if (shouldShow && !lmap.hasLayer(layer)) {
        lmap.addLayer(layer)
      }

      // use the first (with at least one feature) loaded layer to center the map (if it has not already been set with tenantSettings)
      // if (leafstate.latlng?.lat === 37.98 && layer.getLayers().length > 0) {
      //   console.log(`Shiftin Map based on layer ${key} (contains ${layer.getLayers().length} features)`)
      //   console.log(`Initial map center: ${JSON.stringify(leafstate.latlng,null,2)}`)
      //   if (layer.getBounds) {
      //     let latlng = layer.getBounds().getCenter()
      //     leafstate.latlng = latlng
      //     console.log(`New map center: ${JSON.stringify(leafstate.latlng,null,2)}`)
      //     lmap.panTo(latlng)
      //   }
      // }
    }
  }
  const leafDrawnLayer = () => {
    if (!!lmap) {
      let options: any = {
        style: getDeafaultFeatureStyle,
        pointToLayer: pointToLayer,
        onEachFeature: onEachFeature,
      }
      let layer = L.geoJSON(
        {
          type: 'FeatureCollection',
          //@ts-ignore
          features: []
        },
        options
      )
      leafstate.drawnLayer = layer
      // if (LAYERS_THAT_DONT_SNAP_TO_PIPES.includes(key)) layer.setStyle({pmIgnore: true})
      lmap.addLayer(layer)
    }
  }

  const options = {
    networkElementCollection: networkElementCollection,
    accessGranted: accessGranted,
    featureCallback: leafGeoJsonLayer,
    featuresWithActiveAlarms: urgentAlarmsNotificationsGroupedByFeatureType,
    drawingLayerKey: leafstateRef.current.drawingLayerKey==='pipe',
    featuresFilter: networkElementsFilter,
    map:lmap,
  }
  const featureCollections = Object.values(FTC).map(ftc => ftc.collection)
  for (let c = 0; c < featureCollections.length; c++) {
    useFeatureEffect({...options, key: featureCollections[c]})
  }
  // useFeatureEffect({...options, key: 'hydrants'})
  // useFeatureEffect({...options, key: 'hydrometers'})
  // ...
  // useFeatureEffect('hydrants', networkElementCollection, accessGranted,  leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('hydrometers', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('junctions', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('landparcels', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('pipes', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('pumps', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('reservoirs', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('samplingPoints', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('stations', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('switches', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('tanks', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('valves', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)
  // useFeatureEffect('wszones', networkElementCollection, accessGranted, leafGeoJsonLayer, urgentAlarmsNotificationsGroupedByFeatureType, leafstateRef.current.drawingLayerKey==='pipe', featuresFilter)

  const effectSaveFeature = () => {
    if (isDrawingGeometry) {
      saveFeature(leafstate.drawingFeature)
    } else if (isEditingGeometry) {
      commitGeometry()
    }
    if (isDrawingGeometry) {
      actDrawingGeometry(false)
      cleanupDrawingState()
    } else if (isEditingGeometry) {
      actEditingGeometry(false)
    }
    setDoSaveGeometry(false)
  }
  const effectCancelFeature = () => {
    if (isDrawingGeometry) {
      cancelFeature()
      /**Re-enable geoman draw mode after the clear button was pressed*/
      if (leafstate.drawingLayerKey=== "pipe") {
          lmap.pm.enableDraw('Line', {
            snappable: true,
            snapDistance: 35,
          });
      }
      setDrawing_id(drawing_id + 1)
      setDrawingFeature(null)
    } else if (isEditingGeometry) {
      revertEditedGeometry()
      actEditingGeometry(false)
    }
    setDoCancelGeometry(false)
  }
  const effectDeleteFreeGeometry = () => {
    let feature = leafstate.selectedFeature
    console.log(`effectDeleteFreeGeometry feature ${feature.properties.label}`)
    if (!feature.properties.featureType) {
      let layer = getLayerByFeature(feature)
      console.log(`effectDeleteFreeGeometry !!layer ${layer}`)
      if (!!layer) {
        console.log(`effectDeleteFreeGeometry removing layer`)
        removeLayerFrom(layer, leafstate.drawnLayer)
        leafstate.selectedFeature = null
        actSelectFeature(null)
        setDoDeleteFreeGeometry(false)
      }
    }
  }
  const effectCancelDrawing = () => {
    console.log(`effectCancelDrawing isDrawingGeometry ${isDrawingGeometry}`)
    if (isDrawingGeometry) {
      cancelFeature()
      cancelDrawing()
    }
    setDoCancelDrawing(false)
  }

  /**
  * When the user moves the map, the visible layers that require viewing-area-filtering are recreated.
  */
  const handleOnMoveend = event => {
    console.log(`handleOnMoveend`)
    if (!lmap || leafstate.editing) return
    console.log(`handleOnMoveend event`)
    console.log(event)

    Object.values(FTC).forEach(t => {
      let key = t.collection
      if (!networkElementCollection[key]) return
      let shouldShow = shouldShowLayer(key)

      if (shouldShow) {
        let layerSize = networkElementCollection[key]?.length
        if (layerSize > 100) {
          let layer = leafstate.layers[key]
          if (!layer) return
          let withFilter = true
          leafGeoJsonLayer(key, withFilter)
        }
      }
    })
  }

  /**
  * Function that performs viewing-area-filtering on a given feature.
  * Returns true if at least one point of the given feature is within the map's viewing area
  * params
  * feature: the feature that should be checked
  */
  const mapViewAreaFilter = feature => {
    let bbox = lmap.getBounds()
    let fcoords = feature.geometry.coordinates
    if (feature.geometry.type === 'Point') {
      let flatlng = L.GeoJSON.coordsToLatLng(fcoords)
      return bbox.contains(flatlng)
    } else if (feature.geometry.type === 'LineString') {
      // let flatlngs = L.GeoJSON.coordsToLatLngs(fcoords, 0)
      // return flatlngs.some(flatlng => bbox.contains(flatlng))
      let fbbox = L.geoJSON(feature).getBounds()
      // let fbbox = L.GeoJSON.geometryToLayer(feature).getBounds()
      return bbox.overlaps(fbbox)
    } else if (feature.geometry.type === 'Polygon') {
      // let flatlngs = L.GeoJSON.coordsToLatLngs(fcoords, 1)
      // return flatlngs.some(flatlng => bbox.contains(flatlng))
      let fbbox = L.geoJSON(feature).getBounds()
      return bbox.overlaps(fbbox)
    } else {
      return true
    }
  }
  /** Used when the user has filtered out features by location */
  const searchByLocationOrAttributesFilter = feature => {
    let code = feature.properties.code
    let featureCollection = FTC[feature.properties.featureType].collection
    return networkElementsFilter.highlightedFeatures[featureCollection].some(f => f === code)
  }
  /**
  * Combines the given set of feature filter functions to a single filter function.
  */
  const featureFilterGenerator = (filterCallbacks: any[]) => {
    return (feature) => {
      return filterCallbacks.every(cb => cb(feature))
    }
  }
  /**
  * When the user zooms in or out of the map, some layers need to be shown or hidden.
  * Note: this event also triggers the onMoveend event, hence the visible layers that require viewing-area-filtering are recreated
  */
  const handleOnZoomend = event => {
    console.log(`handleOnZoomend`)
    updateMapForZoomLevel()
  }
  const updateMapForZoomLevel = () => {
    let zoomLevel = lmap.getZoom()
    console.log(`handleOnZoomend zoomLevel ${zoomLevel}`)
    Object.values(FTC).forEach(t => {
      let key = t.collection
      let shouldShow = shouldShowLayer(key)
      let layer = leafstate.layers[key]
      if (!layer) {
        return
      }
      if (!shouldShow && layer && lmap.hasLayer(layer)) {
        lmap.removeLayer(layer)
      } else if (shouldShow && layer && !lmap.hasLayer(layer)) {
        /**Disable snap for layers that should not be connected to pipes*/
        if (LAYERS_THAT_DONT_SNAP_TO_PIPES.includes(key)) layer.options.pmIgnore = true
        lmap.addLayer(layer)
      }
    })
    setMapZoomLevel(zoomLevel)
  }
  /**
  * Sets on leaflet state the given feature as the currently selected feature.
  * The feature may be provided either by directly clicking on it on the map,
  * or by passing it via the selectedEntity property.
  * The function may also open the properties popup, or even calculate the feature's length and area.
  */
  const leafSelectedFeature = (feature, layer?, event?) => {
    if (!feature) return

    if (leafstate.mapLayerClicked) {
      leafstate.mapLayerClicked = false
      return
    }

    let selectedFromMap = !!event
    // let selectedFromMap = leafstate.mapLayerClicked

    let panMap = true
    if (!!leafstate.selectedFeature) {
      panMap = leafstate.selectedFeature._id !== feature._id

      let previousLayer = getLayerByFeature(leafstate.selectedFeature)
      leafstate.selectedFeature = feature
      styleLayer(previousLayer)
    } else {
      leafstate.selectedFeature = feature
    }

    if (!layer) {
      layer = getLayerByFeature(feature)
    }
    styleLayer(layer)

    if (selectedFromMap) {
      if (!leafstate.isFeatureDetailsDocked) {
        setSelectedFeaturePointCoords([event.latlng.lng, event.latlng.lat])
        setSelectedFeaturePopOpen(true)
      }
    } else {
      let featureCenter = L.geoJSON(feature).getBounds().getCenter()

      if (panMap) {
        panMapToFeature(feature)
      }

      if (!leafstate.isFeatureDetailsDocked) {
        setSelectedFeaturePointCoords([featureCenter.lng, featureCenter.lat])
        setSelectedFeaturePopOpen(true)
      }
    }

    if (!!layer && feature.geometry.type !== 'Point' && !feature.transient_length) {
      feature.transient_length = computeFeatureLength(layer.feature, layer)
      feature.transient_area = computeFeatureArea(layer.feature)
      feature.transient_elevationDiff = computeFeatureElevationDiff(layer.feature, layer)
    }

  }
  //Utility Functions
  const panMapToLatLng = (latLng, map?) => {
    if (!map) map = lmap
    if (!!map) {
      if (!props.entitiesOpen) {
        map.panTo(latLng)
        return latLng
      }
      let point = map.latLngToLayerPoint(latLng)
      let xShift = leafstate.isFeatureDetailsDocked || props.contentKey === 'firedalarms' ? 250 : 125
      let newpoint = L.point(point.x + xShift, point.y)
      let newcenter = map.layerPointToLatLng(newpoint)
      console.log(`panning map  to ${newcenter}`)
      map.panTo(newcenter)
      return newcenter
    }
  }
  const panMapToFeature = (feature) => {
    let map = lmap
    if (!map) {
      let layer = getLayerByFeature(feature)
      if (!!layer) {
        map = layer._map
      }
    }
    if (!!map) {
      let featureCenter = L.geoJSON(feature).getBounds().getCenter()
      panMapToLatLng(featureCenter, map)
    }
  }
  const makeFeatureVisible = (feature, callback?) => {
    console.log(`makeFeatureVisible ${feature.properties.label}`)
    zoomToFeatureMinLevel(
      feature,
      () => centerArroundFeature(
        feature,
        () => zoomToClusterBounds(
          feature,
          callback
        )
      )
    )
  }
  const zoomToFeatureMinLevel = (feature, callback?) => {
    console.log(`zoomToFeatureMinLevel ${feature.properties.label}`)
    if (!!lmap) {
      let featureType = feature.properties.featureType
      let ftc = FTC[featureType]
      let minZoom = !!ftc?.minZoom ? parseInt(ftc.minZoom) : 0
      let mapZoomLevel = lmap.getZoom()

      //if the feature's collection is not visible due to zoom level, zoom to the minimum zoom level for that collection
      if (mapZoomLevel < minZoom) {
        let featureCenter = L.geoJSON(feature).getBounds().getCenter()
        lmap.setZoomAround(featureCenter, minZoom)
        setTimeout(() => {
          if (!!callback) callback()
        }, 500)
      } else {
        if (!!callback) callback()
      }
    }
  }
  const centerArroundFeature = (feature, callback?) => {
    console.log(`centerArroundFeature ${feature.properties.label}`)
    let isVisible: boolean = mapViewAreaFilter(feature)
    let isClustered = feature.properties.featureType === 'hydrometer'
    
    if (!isVisible || !isClustered) {
      panMapToFeature(feature)
      setTimeout(() => {
        if (!!callback) callback()
      }, 500)
    } else {
      if (!!callback) callback()
    }
  }
  const zoomToClusterBounds = (feature, callback) => {
    console.log(`zoomToClusterBounds ${feature.properties.label}`)
    let isClustered = feature.properties.featureType === 'hydrometer'
    if (isClustered) {
      let markerlayer = leafstate.layers['hydrometers']
      let featureLayer = getLayerByFeature(feature)
      markerlayer.zoomToShowLayer(featureLayer, null)
      setTimeout(() => {
        featureLayer = getLayerByFeature(feature)//get layer again now that it's de-clustered
        styleLayer(featureLayer)
        if (!!callback) callback()
      }, 500)
    } else {
      if (!!callback) callback()
    }
  }
  const computeFeatureArea = (feature) => {
    let area = gutils.geometryArea(feature.geometry)
    return area / 1000 //area in 1000 m2, i.e. strema
  }
  const computeFeatureLength = (feature, layer) => {
    let length = 0;
    if (feature.geometry.type === 'Point') {
      return length
    }
    if (!layer) {
      layer = getLayerByFeature(feature)
    }
    let latlngs = layer.getLatLngs()
    if (feature.geometry.type === 'LineString') {
      latlngs.forEach((ll, i) => {
        if (i < latlngs.length - 1) {
          let ll2 = latlngs[(i+1)]
          let lllength = lmap.distance(ll, ll2)
          // console.log(`segment+${i} length ${lllength.toFixed(2)} ll ${ll} ll2 ${ll2}`)
          length += lllength
        }
      })
    } else if (feature.geometry.type === 'Polygon') {
      latlngs[0].forEach((ll, i) => {
        let ll2 = latlngs[0][(i+1) % latlngs[0].length]
        let lllength = lmap.distance(ll, ll2)
        // console.log(`segment+${i} length ${lllength.toFixed(2)} ll ${ll} ll2 ${ll2}`)
        length += lllength
      })
    }
    // return length / 1000 //length in km
    return length //length in m
  }
  const computeFeatureElevationDiff = (feature, layer) => {
    let diff = 0;
    if (feature.geometry.type === 'Point' || feature.geometry.type === 'Polygon') {
      return diff
    }
    if (!layer) {
      layer = getLayerByFeature(feature)
    }
    let latlngs = layer.getLatLngs()
    if (feature.geometry.type === 'LineString') {
      if (latlngs.length > 1) {
        let altFirst = latlngs[0].alt
        let altLast = latlngs[latlngs.length - 1].alt
        diff = altLast - altFirst
      }
    }
    return diff //diff in m
  }
  const getPointElevationAsync = (coords) => {
    let result: any = { data: {} }
    let msg
    return MapService().getPointElevation(coords)
    .then(
      res => {
        // console.log(`getPointElevation res.data ${JSON.stringify(res.data,null,2)}`)
        if (res.data.status === 'OK') {
          if (res.data.results.length > 0) {
            let elevation = res.data.results[0].elevation
            coords.push(elevation)
            // console.log(`gapis height ${elevation}`)
            result.data.elevation = elevation
          }
          if (res.data.results.length > 1) {
            msg = `gapis: More than one results. res.data ${JSON.stringify(res.data,null,2)}`
            result.error = msg
            console.log(`getPointElevationAsync gapis error: ${JSON.stringify(msg,null,2)}`)
          }
        } else {
          msg = `gapis: not OK. res.data ${JSON.stringify(res.data,null,2)}`
          result.error = msg
          console.log(`getPointElevationAsync gapis error: ${JSON.stringify(msg,null,2)}`)
        }
        return result
      }
      ,err => {
        msg = `gapis: Error: ${err}`
        result.error = msg
        console.log(`getPointElevationAsync gapis error: ${JSON.stringify(msg,null,2)}`)
        return result
      }
    )
  }
  const syncFeatureToLayer = (layer) => {
    layer.feature = layer.toGeoJSON(7) //i.e. precision: lng 9mm, lat 11mm, diagonal 14mm
  }
  const syncLayerToFeature = (layer) => {
    console.log(`syncLayerToFeature`)
    let feature = layer.feature
    let fcoords = feature.geometry.coordinates
    if (feature.geometry.type === 'Point') {
      let flatlng = L.GeoJSON.coordsToLatLng(fcoords)
      console.log(`syncLayerToFeature flatlng ${flatlng}`)
      layer.setLatLng(flatlng)
    } else if (feature.geometry.type === 'LineString') {
      let flatlngs = L.GeoJSON.coordsToLatLngs(fcoords, 0)
      console.log(`syncLayerToFeature flatlngs ${flatlngs}`)
      layer.setLatLngs(flatlngs)
    } else if (feature.geometry.type === 'Polygon') {
      let flatlngs = L.GeoJSON.coordsToLatLngs(fcoords, 1)
      console.log(`syncLayerToFeature flatlngs ${flatlngs}`)
      layer.setLatLngs(flatlngs)
    }
  }
  const getLayerByFeature = (feature) => {
    let layer
    if (!!feature.properties.featureType) {
      let ftc = FTC[feature.properties.featureType]
      // console.log(`getLayerByFeature ftc ${tstringify(ftc)}`)
      let collectionLayer = leafstate.layers[ftc.collection]
      layer = collectionLayer?.getLayers()?.find(l => l?.feature?._id === feature._id)
      if (!layer && leafstate.editing) {
        //### carefull that this doesn't break editing non-pipe features
        layer = collectionLayer.getLayers().find(l => l.feature._id === leafstate.selectedFeature._id)
      }
    } else {
      // layer = leafstate.drawnLayer.getLayers().find(l => l.feature.properties.id === feature.properties.id)
      layer = leafstate.drawnLayer.getLayers().find(l => l.feature._id === feature._id)
    }
    return layer
  }
  const removeLayerFrom = (layer, parent) => {
    if (!!layer && !!parent && parent.hasLayer  && parent.hasLayer(layer)) {
      parent.removeLayer(layer)
    }
  }
  const cloneFeature = (feature) => {
    let clone = Object.assign({}, feature)
    clone.properties = Object.assign({}, feature.properties)
    clone.geometry.coordinates = feature.geometry.coordinates.map(c => c)
    return clone
  }
  // const tstringify = (o) => {
  //   let s = `${!o ? o : Object.keys(o).map(k => `\n${k}: ${typeof o[k]} : ${(typeof o[k] === 'function') ? 'function' : `${o[k]}` }`) }\n\n`
  //   return s
  // }
  const debounced = (delay, fn) => {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    }
  }

  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  const [mapZoomLevel, setMapZoomLevel] = useState(12)
  const [rulerToolsOpen, setRulerToolsOpen] = useState(false)
  const [featureToolsOpen, setFeatureToolsOpen] = useState(false)
  const [selectedFeaturePopOpen, setSelectedFeaturePopOpen] = useState(false)
  const [selectedFeaturePointCoords, setSelectedFeaturePointCoords] = useState<any[] | null>(null)
  const [legendOpen,setLegendOpen] = useState<boolean>(false)

  const handleMapToolClick = (e, geometryType, layerKey) => {
    console.log(`handleMapToolClick drawing ${leafstate.drawing} geometryType ${geometryType} layerKey ${layerKey}`)
    props.setEntitiesOpen(true)
    if (leafstate.editing) {
      showAlert(t(`msg.alert.draw.geometry.notWhileEditing`), 'W')
      return
    }
    if (leafstate.drawing) {
      cancelFeature()
    }

    //GEOMAN enable snapping
    if (layerKey === 'pipe') {
      if (!!lmap) {
        lmap.pm.enableDraw('Line', {
          snappable: true,
          snapDistance: 35,
        });
      }
    }

    if (leafstate.drawingGeometryType !== geometryType || leafstate.drawingLayerKey !== layerKey) {
      leafstate.drawing = true
      leafstate.drawingGeometryType = geometryType
      leafstate.drawingLayerKey = layerKey
      leafstate.lastPointCoords = null
      // leafstate.preventDefaultClick = false //so that 'handleMapClick' will do nothing
      actDrawingGeometry(true)

      if (!!leafstate.selectedFeature) {
        let previousLayer = getLayerByFeature(leafstate.selectedFeature)
        leafstate.selectedFeature = null
        styleLayer(previousLayer)
        actSelectFeature(null)
      }

    } else {
      cancelDrawing()
      // leafstate.drawing = false
      // leafstate.drawingGeometryType = null
      // leafstate.drawingLayerKey = null
      // leafstate.lastPointCoords = null
      // actDrawingGeometry(false)
    }
    console.log(`handleMapToolClick drawing ${leafstate.drawing} leafstate.drawingGeometryType ${leafstate.drawingGeometryType} leafstate.drawingLayerKey ${leafstate.drawingLayerKey}`)
  }

  const cancelDrawing = () => {
    console.log(`cancelDrawing`)
    leafstate.drawing = false
    leafstate.drawingGeometryType = null
    leafstate.drawingLayerKey = null
    leafstate.lastPointCoords = null
    actDrawingGeometry(false)
  }
  const cancelFeature = () => {
    removeLayerFrom(leafstate.drawingLayer, leafstate.drawnLayer)
    cleanupDrawingState()
  }
  const cleanupDrawingState = () => {
    leafstate.drawingLayer = null
    leafstate.drawingFeature = null
    removeLayerFrom(leafstate.ghostLineLayer, leafstate.drawnLayer)
    removeLayerFrom(leafstate.ghostPolyLayer, leafstate.drawnLayer)
    setDrawing_id(drawing_id + 1)
    leafstate.ghostLineLayer = null
    leafstate.ghostPolyLayer = null
    leafstate.lastPointCoords = null
    lmap?.pm?.disableDraw()
  }

  const validateFeature = (feature): boolean => {
    if (!feature.geometry || !feature.geometry.coordinates) return false
    let valid: boolean = true
    if (['wszone', 'landparcel'].includes(feature.properties.featureType)) {
      valid = feature.geometry.coordinates.length >= 1
        && feature.geometry.coordinates[0].length > 3
        && feature.geometry.coordinates[0].every(pcoords => pcoords.length > 1)
    } else if (feature.properties.featureType === 'pipe') {
      valid = feature.geometry.coordinates.length >= 2 && feature.geometry.coordinates.every(pcoords => pcoords.length > 1)
    } else {
      valid = feature.geometry.coordinates.length > 1
    }
    return valid
  }
  const saveFeature = (feature) => {
    // console.log(`saveFeature ${JSON.stringify(feature,null,2)}`)
    if (!!feature.properties.featureType) {
      removeLayerFrom(leafstate.drawingLayer, leafstate.drawnLayer)
      if (feature.isNew) {//i.e. this is a new feature, so the _id is dummy and should be removed.
        delete feature._id
      }
      if (validateFeature(feature)) {
        _persistFeature(feature)
      } else {
        showAlert('Invalid geometry!', 'E')
      }
    } else {
      leafstate.selectedFeature = feature
      actSelectFeature(feature)
    }
    leafstate.drawing = false
    leafstate.drawingGeometryType = null
    leafstate.drawingLayerKey = null
  }
  const _persistFeature = (feature) => {
    switch (feature.properties.featureType) {
      case FTC.wszone.code:
        feature.properties.area = feature.transient_area
        feature.properties.perimeter = feature.transient_length
        break;
      case FTC.landparcel.code:
        feature.properties.area = feature.transient_area
        feature.properties.perimeter = feature.transient_length
        break;
      case FTC.pipe.code:
        feature.properties.pipeLength = feature.transient_length
        break;
      default:
    }
    featureService.saveFeature(feature)
  }

  const makeSelectedFeatureEditable = () => {
    // console.log(`makeSelectedFeatureEditable ${!!leafstate.selectedFeature ? leafstate.selectedFeature.properties.label : leafstate.selectedFeature}`)
    let feature = leafstate.selectedFeature
    if (!feature) return
    let layer = getLayerByFeature(feature)

    let featureClone = cloneFeature(feature)
    featureClone._id = 1
    leafstate.editing = true
    leafstate.editingFeature = featureClone

    // leafstate.editor = layer.enableEdit(leafstate.map)//leaflet-editable
    let lastFeatureInteractedWith:any = null
    let snapCoords:any = null
    let inNode:any=null
    let outNode:any=null
    let vertexIsInNode=false
    let vertexIsOutNode=false

    if (feature.properties.featureType === 'pipe') {
      layer.on('pm:snap',e=>{
        if ( e.layerInteractedWith?.feature?.properties?.featureType !== 'station') {
        lastFeatureInteractedWith = e.layerInteractedWith.feature
        snapCoords = e.snapLatLng
        }
      })

      layer.pm.enable()
      // leafstate.editor = layer.enableEdit(leafstate.map)//leaflet-editable
      let vertexIsLastNode = false
      /** set Events */
      layer.on('pm:markerdragstart',e=>{
        const lastVertex = leafstate.editingFeature.geometry.coordinates.at(-1)
        const markerCoords = e.markerEvent.target.getLatLng()
        vertexIsLastNode = currentVertexIsTheLastNode(markerCoords,L.latLng(lastVertex[1],lastVertex[0]))
      })
      layer.on('pm:markerdragend', (e) => {

        let vertexIsInNode=false
        let vertexIsOutNode=false
        /**In case the feature has no flowDirection set it to flowDirection === 'normal' */
        if (!feature?.properties?.flowDirection) feature.properties.flowDirection = 'normal'
        /**Check if the vertex edited is the first or the last one (potential nodeIn/nodeOut) */
        if (e.indexPath[0] === 0) {
          if (feature.properties.flowDirection === 'normal') vertexIsInNode = true
          if (feature.properties.flowDirection === 'reverse') vertexIsOutNode = true
        }
        if ((e.indexPath[0] === leafstate.editingFeature.geometry.coordinates.length - 1) && vertexIsLastNode) {
          if (feature.properties.flowDirection === 'normal')  vertexIsOutNode  = true
          if (feature.properties.flowDirection === 'reverse') vertexIsInNode = true
        }
        if ((vertexIsInNode ||
          vertexIsOutNode) &&
          !!snapCoords &&
          (e.markerEvent.target.getLatLng().lat === snapCoords.lat &&
          e.markerEvent.target.getLatLng().lng === snapCoords.lng)
        ) {
          if (vertexIsInNode) {
            inNode = lastFeatureInteractedWith
          } else if (vertexIsOutNode) {
            outNode = lastFeatureInteractedWith
          }
        } else {
          inNode = null
          outNode = null
        }

        const eClone = {...e}
        const eCloneFeature = _.cloneDeep(leafstate.editingFeature)
        eCloneFeature._id = leafstate.selectedFeature._id
        eClone.layer.feature = {...eCloneFeature}
        if (vertexIsInNode) {
          const previousInNode = e.layer.feature.properties?.inNodeCode || null
          const previousInNodeFeatureType = e.layer.feature.properties?.inNodeFeatureType || null
          const currentInNode = inNode?.properties?.code || null
          const currentInNodeFeatureType = inNode?.properties?.featureType || null
          eClone.layer.feature.properties.inNodeCode = currentInNode
          eClone.layer.feature.properties.inNodeFeatureType  = currentInNodeFeatureType

          //if the pipe had an inNode that was removed during current editing
          if (previousInNode && currentInNode===null) {
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + previousInNodeFeatureType)} ${t("map.createPipe.toast.isDisconnected")}`,'W')
          } else if (previousInNode && currentInNode && previousInNode !== currentInNode) {
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + previousInNodeFeatureType)} ${t("map.createPipe.toast.isReplacedWith")} ${t("net." +currentInNodeFeatureType)} - (${inNode?.properties?.label }) ${t("map.createPipe.toast.isReplacedWithInNode")}`,'S')
          } else if (eClone.layer.feature.properties.inNodeCode) {//else if (eClone.layer.feature.properties.inNodeCode) {
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + currentInNodeFeatureType)} - (${inNode.properties.label}) ${t("map.createPipe.toast.isInNode")}`,'S')
          }
        }
        if (vertexIsOutNode) {
          const previousOutNode = e.layer.feature.properties?.outNodeCode || null
          const previousOutNodeFeatureType = e.layer.feature.properties?.outNodeFeatureType || null //?.featureType
          const currentOutNode = outNode?.properties?.code || null
          const currentOutNodeFeatureType = outNode?.properties?.featureType || null

          eClone.layer.feature.properties.outNodeCode = outNode?.properties?.code || null
          eClone.layer.feature.properties.outNodeFeatureType  = outNode?.properties?.featureType || null

          if (previousOutNode && currentOutNode===null) {
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + previousOutNodeFeatureType)} ${t("map.createPipe.toast.isDisconnected")}`,'W')
          } else if (previousOutNode && currentOutNode && previousOutNode !== currentOutNode) {
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + previousOutNodeFeatureType)} ${t("map.createPipe.toast.isReplacedWith")} ${t("net."+currentOutNodeFeatureType)} - (${ outNode?.properties?.label }) ${t("map.createPipe.toast.isReplacedWithOutNode")}`,'S')
          } else  if (e.layer.feature.properties.outNodeCode) {
            showAlert(`${t("map.createPipe.toast.theFeature")} ${t("net." + currentOutNodeFeatureType)} - (${outNode.properties.label}) ${t("map.createPipe.toast.isOutNode")}`,'S')
           }
        }
        addEditedGeometryTooltips(layer)
        // handleEditingEvent(e)
        handleEditingEvent(eClone)
      });

      layer.on('pm:vertexremoved', e => {
        if (!leafstate.editingFeature?.geometry?.coordinates?.length) {
          showAlert('invalid geometry', 'E')
          return
        }
        const lineIsValid = e.layer.getLatLngs().length >= 2 ? true : false
        //if the first or the last vertex was removed, update in/outNode codes
        if (e.indexPath[0] === 0) {
          e.layer.feature.properties.flowDirection === 'normal'
            ? (vertexIsInNode = true)
            : (vertexIsOutNode = true)
        }
        if (
          e.indexPath[0] ===
          leafstate.editingFeature.geometry.coordinates.length - 1
        ) {
          e.layer.feature.properties.flowDirection === 'normal'
            ? (vertexIsOutNode = true)
            : (vertexIsInNode = true)
        }

        const eClone = { ...e }
        const eCloneFeature = _.cloneDeep(leafstate.editingFeature)
        eCloneFeature._id = leafstate.selectedFeature._id
        eClone.layer.feature = { ...eCloneFeature }

        if (!lineIsValid) {
          showAlert(t("map.createPipe.toast.cancelEdits"),'E')
        }
        if (vertexIsInNode && lineIsValid) {
          if (eClone.layer.feature.properties?.inNodeCode) {
            showAlert(
              `${t('map.createPipe.toast.theFeature')} ${t(
                'net.' + eClone.layer.feature.properties?.inNodeFeatureType
              )} ${t('map.createPipe.toast.isDisconnected')}`,
              'W'
            )
          }
          eClone.layer.feature.properties.inNodeCode = null
          eClone.layer.feature.properties.inNodeFeatureType = null
        }
        if (vertexIsOutNode && lineIsValid) {
          if (eClone.layer.feature.properties?.outNodeCode) {
            showAlert(
              `${t('map.createPipe.toast.theFeature')} ${t(
                'net.' + eClone.layer.feature.properties?.outNodeFeatureType
              )} ${t('map.createPipe.toast.isDisconnected')}`,
              'W'
            )
          }
          eClone.layer.feature.properties.outNodeCode = null
          eClone.layer.feature.properties.outNodeFeatureType = null
        }
        addEditedGeometryTooltips(layer)
        /** Check if the line is still valid:
         * if the user deletes vertexes and only one vertex is left it is no longer a valid line,
         * in that case cancel edits and revert geometry */
        const featureGeometryValidationLayer = layer.toGeoJSON(7)
        if (featureGeometryValidationLayer.geometry.coordinates.length === 0) {
          revertEditedGeometry()
          actEditingGeometry(false)
          setDoCancelGeometry(false)
        } else {
          handleEditingEvent(eClone)
        }
      })
    } else {
      //not a pipe, so use leaflet-editable
      leafstate.editor = layer.enableEdit(leafstate.map)//leaflet-editable
    }
    addEditedGeometryTooltips(layer)

    setEditingFeature(leafstate.editingFeature)
  }
  const currentVertexIsTheLastNode = (mouseVertex,elementLastVertex) => {
    if (mouseVertex.lat === elementLastVertex.lat && mouseVertex.lng === elementLastVertex.lng) {
      return true
    }
    const elementLastVertexLNGDecimalLength = elementLastVertex.lng.toString().split('.')[1].length
    const elementLastVertexLATDecimalLength = elementLastVertex.lat.toString().split('.')[1].length

    const roundedMouseCoords = ()=>{
      let roundedVertex =  {
        lat: mouseVertex.lat.toFixed(elementLastVertexLATDecimalLength+1),
        lng: mouseVertex.lng.toFixed(elementLastVertexLNGDecimalLength+1)
      }
      roundedVertex.lat = +roundedVertex.lat.slice(0,roundedVertex.lat.length-1)
      roundedVertex.lng = +roundedVertex.lng.slice(0,roundedVertex.lng.length-1)
      return roundedVertex
    }
    const roundedMouseVertex = roundedMouseCoords()
    if (roundedMouseVertex.lat === elementLastVertex.lat && roundedMouseVertex.lng === elementLastVertex.lng) {
      return true
    }
    if (
      +mouseVertex.lat.toFixed(elementLastVertexLATDecimalLength) ===
        elementLastVertex.lat &&
      +mouseVertex.lng.toFixed(elementLastVertexLNGDecimalLength) ===
        elementLastVertex.lng
    ) {
      return true
    }
    return false
  }
  const removeEditedGeometryTooltips = () => {
    leafstate.tooltips.forEach(tt => {
      tt.remove()
    })
  }
  const addEditedGeometryTooltips = (layer) => {
    if (leafstate.tooltips.length) {
      leafstate.tooltips.forEach((toolTip)=>toolTip.remove())
    }
    leafstate.tooltips = []
    if (layer.feature.geometry.type === 'Point') return
    let latlngs = layer.getLatLngs()
    if (layer.feature.geometry.type === 'Polygon') {
      latlngs = latlngs[0]
    }
    // console.log(`latlngs ${latlngs} latlngs.length ${latlngs.length}`)
    latlngs.forEach((ll, i) => {
      // console.log(`latlng ${ll} i ${i} label ${getPointLabel(i)}`)
      // let p = lmap.latLngToLayerPoint(ll)
      // console.log(`p bef ${p}`)
      // p.x = p.x - center.x
      // p.y = p.y - center.y
      // console.log(`p aft ${p}`)
      // layer.bindTooltip(getPointLabel(i), {offset: p, permanent: true}).openTooltip()
      let tt = L.tooltip({permanent: true, direction: 'auto'})
      tt.setContent(getPointLabel(i))
      tt.setLatLng(ll)
      // layer.bindTooltip(tt).openTooltip()
      tt.addTo(lmap)
      leafstate.tooltips.push(tt)
    })
  }
  const getPointLabel = (order) => {
    // const pointLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    // return `${order < 26 ? '' : pointLetters[Math.floor(order / 26) - 1]}${pointLetters[order % 26]}`
    const pointLetters = t('net.pointLetters')
    let modulator = pointLetters.length
    return `${order < modulator ? '' : pointLetters[Math.floor(order / modulator) - 1]}${pointLetters[order % modulator]}`
  }
  const handleEditingEvent = (e) => {
    // console.log(`handleEditingEvent e ${e.type}`)
    let latlng:any = undefined
    switch (e.type) {
      /** GEOMAN events that handle ONLY pipes editing*/
      case "pm:markerdragend":{
        latlng = e.markerEvent.target.getLatLng()
        let coords = [latlng.lng, latlng.lat]
        getPointElevationAsync(coords)
        .then(
          res => {
            if (!!res.data.elevation) {
              latlng.alt = res.data.elevation
            }
            updateEditedGeometry(e.layer)
          }
        )
        break;
      }
      case 'pm:vertexremoved':{
        updateEditedGeometry(e.layer)
        break;
      }
      case 'editable:dragstart':{
        if (e.sourceTarget?._latlng.lat === leafstate.selectedFeature?.geometry?.coordinates[1] &&
          e.sourceTarget?._latlng.lng === leafstate.selectedFeature?.geometry?.coordinates[0]) {
          isFirstDragEdit.current = true
        }
        break;
      }
      case 'editable:dragend'://carefuill: no break !!
      case 'editable:vertex:dragend':
        latlng = e.layer._latlng
        // console.log(`e.vertex ${tstringify(e.vertex)}`)
        if (!latlng) latlng = e.vertex.latlng
        // console.log(`latlng ${tstringify(latlng)}`)
        let coords = [latlng.lng, latlng.lat]
        getPointElevationAsync(coords)
        .then(
          res => {
            if (!!res.data.elevation) {
              latlng.alt = res.data.elevation
              // console.log(`after latlng ${tstringify(latlng)}`)
            }
            updateEditedGeometry(e.layer)
          }
          // ,err => {
          //   updateEditedGeometry(e.layer)
          // }
        )
        break;
      case 'editable:vertex:deleted':
        updateEditedGeometry(e.layer)
        break;
      default:
        break;
    }
  }
  const updateEditedGeometry = (layer) => {
    /** Check if the feature was connected to any pipes and show an alert
     * to notify the user he is about to mutate the pipes in/out nodes
     * at this stage the state is not mutated so if the action is canceled the pipes aren't mutated
     * the pipes in/out nodes are updated in commitGeometry() function */
    if (
      !LAYERS_THAT_DONT_SNAP_TO_PIPES.includes(
        FTC[layer.feature.properties.featureType].collection
      ) && isFirstDragEdit.current
    ) {
      let snappedPipes = leafstate.layers.pipes
        .getLayers()
        .filter(
          el =>
            el.feature.properties.inNodeCode ===
              leafstate.selectedFeature.properties.code ||
            el.feature.properties.outNodeCode ===
              leafstate.selectedFeature.properties.code
        )
      if (snappedPipes.length) {
        const snappedPipesLabels = snappedPipes.map(
          el => el.feature.properties.label
        )
        showAlert(
          `${
            t('map.createPipe.toast.theFeature') +
            ' ' +
            leafstate.selectedFeature.properties.label
          } ${
            snappedPipesLabels.length === 1
              ? t('map.createPipe.toast.isDisconnected')
              : t('map.createPipe.toast.isDisconnectedMultiple')
          } ${snappedPipesLabels.join(',')}`,
          'W'
        )
      }
    }
    let feature = layer.toGeoJSON(7) //i.e. precision: lng 9mm, lat 11mm, diagonal 14mm
    feature.transient_length = computeFeatureLength(feature, layer)
    feature.transient_area = computeFeatureArea(feature)
    feature.transient_elevationDiff = computeFeatureElevationDiff(feature, layer)
    feature._id = leafstate.editingFeature._id + 1
    // console.log(`updateEditedGeometry feature._id ${feature._id}`)
    leafstate.editingFeature = feature
    // setDrawing_id(drawing_id + 1)
    setDrawing_id(feature._id)
    setEditingFeature(leafstate.editingFeature)
    isFirstDragEdit.current = false
    // console.log(`updateEditedGeometry leafstate.editingFeature ${JSON.stringify(leafstate.editingFeature,null,2)}`)
  }
  const commitGeometry = () => {
    console.log(`commitGeometry`)
    let feature = leafstate.selectedFeature
    /**Check if the geometry of the edited feature was altered*/
    const editedGeometryIsChanged = ()=>{
      return !((feature.geometry.coordinates[0] === leafstate.editingFeature.geometry.coordinates[0]) &&
      (feature.geometry.coordinates[1] === leafstate.editingFeature.geometry.coordinates[1]))
    }
    /** Check if the edited feature was connected to pipe(s) */
    if (!LAYERS_THAT_DONT_SNAP_TO_PIPES.includes(FTC[feature.properties.featureType].collection)
       && (editedGeometryIsChanged())) {
      let snappedPipes = leafstate.layers.pipes.getLayers()
        .filter(
          el =>
            el.feature.properties.inNodeCode ===
              leafstate.selectedFeature.properties.code ||
            el.feature.properties.outNodeCode ===
              leafstate.selectedFeature.properties.code
        )
      let promises:any = []

      snappedPipes.forEach(pipe => {
        if (pipe.feature.properties.inNodeCode === feature.properties.code) {
          pipe.feature.properties.inNodeCode = null
          pipe.feature.properties.inNodeFeatureType = null
        } else if (pipe.feature.properties.outNodeCode === feature.properties.code) {
          pipe.feature.properties.outNodeCode = null
          pipe.feature.properties.outNodeFeatureType = null
        }
        promises.push(featureService.saveFeature(pipe.feature,true))
      })
      if (snappedPipes.length) {
        Promise.all(promises).then(res=>{
        }).catch(err=>showAlert(`Error updating pipes: ${err}`, 'E'))
      }
    }
    // console.log(`commitGeometry leafstate.selectedFeature._id ${leafstate.selectedFeature._id}`)
    // console.log(`commitGeometry leafstate.editingFeature._id ${leafstate.editingFeature._id}`)
    if (!feature) return
    let layer = getLayerByFeature(feature)
    syncFeatureToLayer(layer)
    leafstate.selectedFeature = layer.feature
    // console.log(`commitGeometry layer.feature._id ${layer.feature._id}`)

    removeEditedGeometryTooltips()

    if (!!leafstate.editor) {
      leafstate.editor.disable()
      leafstate.editor = null
    }
    let editingFeatureClone = {...leafstate.editingFeature}
    leafstate.editing = false
    leafstate.editingFeature = null
    setDrawing_id(drawing_id + 1)
    if (editingFeatureClone.properties.featureType==='pipe') {
      editingFeatureClone._id = layer.feature._id
      saveFeature(editingFeatureClone)
    } else {
      saveFeature(leafstate.selectedFeature)
    }
    // saveFeature(leafstate.selectedFeature)
    setEditingFeature(null)
  }
  const revertEditedGeometry = () => {
    console.log(`revertEditedGeometry`)
    let feature = leafstate.selectedFeature
    if (!feature) return
    let layer = getLayerByFeature(feature)
    if (!!layer){//maybe the feature is not displayed on map (due to either visible window filtering or zoom level filtering)
      if (!layer._map) layer._map = leafstate.map
      if (!layer._mapToAdd) layer._mapToAdd = leafstate.map
      syncLayerToFeature(layer)
      layer.pm.disable()
      let withFilter = !!networkElementCollection.pipes && networkElementCollection.pipes?.length > 100
      leafGeoJsonLayer('pipes', withFilter)
    }

    removeEditedGeometryTooltips()

    if (!!leafstate.editor) {
      leafstate.editor.disable()
      leafstate.editor = null
    }

    leafstate.editing = false
    leafstate.editingFeature = null
    setDrawing_id(drawing_id + 1)
    leafSelectedFeature(leafstate.selectedFeature)
  }

  //leaflet handling of feature click
  const clickFeature = (e) => {
    // console.log(`clickFeature leafstate.drawing ${leafstate.drawing} leafstate.editing ${leafstate.editing}`)
    if (leafstate.drawing || leafstate.editing) return
    // console.log(`clickFeature e ${tstringify(e)}`)
    // console.log(`clickFeature e ${e.latlng}`)
    // leafstate.preventDefaultClick = true //so that 'handleMapClick' will do nothing
    let layer = e.target
    // console.log(`clickFeature layer._map ${tstringify(layer._map)}`)

    if (!!layer.feature.properties.featureType) {//or else it's a free-draw-layer feature
      const featureType = layer.feature.properties.featureType
      const code = layer.feature.properties.code
      //check if the feature has an active alarm
      if (
        props.urgentAlarmNotifications.length > 0 &&
        !!urgentAlarmsNotificationsGroupedByFeatureType &&
        urgentAlarmsNotificationsGroupedByFeatureType[featureType].length > 0 &&
        urgentAlarmsNotificationsGroupedByFeatureType[featureType].some(
          el => el.ms.featureCode === code
        )
      ) {
        props.handleOpenAlarmPopUp(null,layer.feature)
        return
      }

      if (!leafstate.selectedFeature || leafstate.selectedFeature._id !== layer.feature._id) {
        leafSelectedFeature(layer.feature, layer, e)
        leafstate.mapLayerClicked = true //so that when the selectedFeature effect calls 'leafSelectedFeature' it will do nothing
        actSelectFeature(layer.feature, leafstate.featureDetailsTab)
      } else {
        leafSelectedFeature(layer.feature, layer, e)
      }

    }
    //handle free-draw-layer clicks here
    else {

      if (!leafstate.selectedFeature || leafstate.selectedFeature._id !== layer.feature._id) {
        leafSelectedFeature(layer.feature, layer, e)
        leafstate.mapLayerClicked = true //so that when the selectedFeature effect calls 'leafSelectedFeature' it will do nothing
        actSelectFeature(layer.feature)
      } else {
        leafSelectedFeature(layer.feature, layer, e)
      }

    }

  }

  //handle map click
  const handleMapClick = (e) => {
    if (leafstate.drawing) {
      handleDrawingMapClick(e)
    }
  }
  //handle map click while drawing
  const handleDrawingMapClick = (e) => {
    let feature: null | any = null
    // console.log(`updateDrawingFeature drawnLayer ${!!leafstate.drawnLayer} drawingLayer ${!!leafstate.drawingLayer} drawingFeature ${!!leafstate.drawingFeature}`)
    // console.log(`updateDrawingFeature leafstate.drawingFeature ${JSON.stringify(leafstate.drawingFeature,null,2)}`)

    if (leafstate.drawingLayerKey ==='pipe' && (leafstate.drawing || leafstate.editing)) {
      /** if the user creates a new pipe return,
       *  all the coordinates,leafstate updates and any additional logic will be handled in the
       *  'pm:drawstart' && 'pm:vertexadded' geoman event handlers   */
      return
    }
    if (!lmap) return

    let coords = [e.latlng.lng, e.latlng.lat]
    leafstate.lastPointCoords = coords

    if (!!leafstate.drawingFeature) {
      // feature = cloneFeature(leafstate.drawingFeature)
      feature = leafstate.drawingFeature
    } else {
      feature = {
        isNew: true,
        _id: `${drawing_id + 1}`,
        type: 'Feature',
        properties: {
        },
        geometry: {
          type: leafstate.drawingGeometryType,
          coordinates: []
        }
      }
      let prefix = leafstate.drawingGeometryType
      if (leafstate.drawingLayerKey !== 'drawnLayer') {
        feature.properties.featureType = leafstate.drawingLayerKey
        prefix = leafstate.drawingLayerKey
      }
      feature.properties.code = `${prefix}_${drawing_id + 1}`
      feature.properties.label = `${prefix}_${drawing_id + 1}`

      leafstate.drawingFeature = feature
    }

    if (leafstate.drawingGeometryType === 'Point') {
      feature.geometry.coordinates = coords
    } else if (leafstate.drawingGeometryType === 'LineString') {
      feature.geometry.coordinates.push(coords)
    } else if (leafstate.drawingGeometryType === 'Polygon') {
      if (feature.geometry.coordinates.length === 0) {
        feature.geometry.coordinates.push([])
      }
      if (feature.geometry.coordinates[0].length === 0) {
        feature.geometry.coordinates[0].push(coords)
        feature.geometry.coordinates[0].push(coords)
      } else if (feature.geometry.coordinates[0].length > 0) {
        feature.geometry.coordinates[0].splice(feature.geometry.coordinates[0].length - 1, 0, coords)
      }
    }

    getPointElevationAsync(coords)
    .then(
      res => {

        let options = {
          style: (f) => {
            let st: any = {
              cursor: 'crosshair'
            }
            if (leafstate.drawingLayerKey === 'drawnLayer') {
              st.color = DRAWN_LAYER_COLOR
            }
            return st
          },
          pointToLayer: pointToLayer,
          onEachFeature: onEachFeature,
          //without snapIgnore: true creating a new feature will break leaflet-geoman
          //see https://github.com/geoman-io/leaflet-geoman/issues/540
          snapIgnore: true,
        }
        removeLayerFrom(leafstate.drawingLayer, leafstate.drawnLayer)
        leafstate.drawingLayer = L.geoJSON(feature, options).getLayers()[0]
        leafstate.drawnLayer.addLayer(leafstate.drawingLayer)

        feature.transient_length = computeFeatureLength(feature, leafstate.drawingLayer)
        feature.transient_area = computeFeatureArea(feature)
        feature.transient_elevationDiff = computeFeatureElevationDiff(feature, leafstate.drawingLayer)

        setDrawing_id(drawing_id + 1)
        // setDrawingFeature(feature)
        setDrawingFeature({...feature})
        removeLayerFrom(leafstate.ghostLineLayer, leafstate.drawnLayer)
        removeLayerFrom(leafstate.ghostPolyLayer, leafstate.drawnLayer)
        leafstate.ghostLineLayer = null
        leafstate.ghostPolyLayer = null
      }
    )
  }
  //handle map mouse move while drawing
  const handleMapMouseMove = (e) => {
    // console.log(`handleMouseMove lastPointCoords ${leafstate.lastPointCoords}`)
    if (leafstate.drawing && leafstate.lastPointCoords && leafstate.drawingGeometryType !== 'Point') {
      // console.log(`handleMouseMove millis ${new Date().getTime()}`)
      // console.log(`handleMouseMove latlng ${JSON.stringify(e.latlng,null,2)}`)
      let mousePointCoords = [e.latlng.lng, e.latlng.lat]
      // if (leafstate.drawingGeometryType === 'LineString' || (leafstate.drawingGeometryType === 'Polygon' && drawingFeature.geometry.coordinates[0].length < 3)) {
      let line = {
        type: 'Feature',
        properties: {
          id: `ghostLine`,
        },
        geometry: {
          type: 'LineString',
          coordinates: [leafstate.lastPointCoords, mousePointCoords]
        }
      }
      // setGhostLine(line)
      removeLayerFrom(leafstate.ghostLineLayer, leafstate.drawnLayer)
      let options = {
        style: (f) => {
          return {
            color: 'red',
            cursor: 'crosshair'
          }
        },
        weight: 2,
        dashArray: '4',
      }
      if (leafstate.drawingLayerKey !== 'pipe') {
        //pipes are handles with geoman so no need for ghostline, besides ghostline breaks snapping
        //@ts-ignore
        leafstate.ghostLineLayer = L.geoJSON(line, options).getLayers()[0]
        leafstate.drawnLayer.addLayer(leafstate.ghostLineLayer)
      }

      // console.log(`handleMapMouseMove drawingFeature ${!!leafstate.drawingFeature}`)
      if (leafstate.drawingGeometryType === 'Polygon' && leafstate.drawingFeature && leafstate.drawingFeature.geometry.coordinates[0].length >= 3) {
        let firstPointCoordinates = leafstate.drawingFeature.geometry.coordinates[0][0]
        let poly = {
          type: 'Feature',
          properties: {
            id: `ghostPoly`,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [[firstPointCoordinates, leafstate.lastPointCoords, mousePointCoords, firstPointCoordinates]]
          }
        }
        // console.log(`handleMouseMove poly ${JSON.stringify(poly,null,2)}`)
        // setGhostPoly(poly)
        let options = {
          style: (f) => {
            return {
              color: 'red',
              cursor: 'crosshair'
            }
          },
          weight: 1,
          dashArray: '1 4',
        }
        removeLayerFrom(leafstate.ghostPolyLayer, leafstate.drawnLayer)
        //@ts-ignore
        leafstate.ghostPolyLayer = L.geoJSON(poly, options).getLayers()[0]
        leafstate.drawnLayer.addLayer(leafstate.ghostPolyLayer)
      }
    }
  }
  //handle keyboard Esc while drawing
  const handleKeydown = e => {
    // console.log(`handleKeydown e ${Object.keys(e).map(k => `\n${k}: ${e[k]}`)}`)
    if (leafstate.drawing && leafstate.drawingFeature) {
      switch (e.key) {
    //     case 'Enter':
    // console.log(`handleKeydown key ${e.key} ctrlKey ${e.ctrlKey} shiftKey ${e.shiftKey} altKey ${e.altKey}`)
    // console.log(`handleKeydown keyCode ${e.keyCode} which ${e.which}`)
    //       saveFeature()
    //       break;
        case 'Escape':
    console.log(`handleKeydown key ${e.key} ctrlKey ${e.ctrlKey} shiftKey ${e.shiftKey} altKey ${e.altKey}`)
    console.log(`handleKeydown keyCode ${e.keyCode} which ${e.which}`)
          setDoCancelGeometry(true)
          cancelFeature()
          break;
        default:

      }
    }
  }
  const closeSelectedFeaturePop = () => {
    // console.log(`closeSelectedFeaturePop selectedFeaturePopOpen ${selectedFeaturePopOpen} selectedFeature ${JSON.stringify(selectedFeature,null,2)}`)
    setSelectedFeaturePopOpen(false)
  }
  const handleLocationFound = (e) => {
    console.log(`handleLocationFound e ${Object.keys(e).map(k => `\n${k}: ${e[k]}`)}`)
    leafstate.hasLocation = true
    leafstate.latlng = e.latlng
  }

  //fired, if passed as an option, when creating a layer from a feature (or collection of features)
  const onEachFeature = (feature, layer) => {
    // console.log(`onEachFeature feature.properties.code ${feature.properties.code}`)
    layer.on({
      // mouseover:debounced(50, highlightFeature),
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: clickFeature,
    })
    if (!!layer) {
      let tt = L.tooltip({
        // permanent: true,
        direction: 'bottom',
        className: 'text',
        opacity: 0.7,
      })
      .setContent(feature.properties.label)
      if (!!layer.getLatLng) {
        tt.setLatLng(layer.getLatLng())
      }
      layer.bindTooltip(tt)
      // .addTo(layer._map)
    }
  }
  const highlightFeature = (e) => {
    let layer = e.target
    // console.log(`highlightFeature featureType ${layer.feature.properties.featureType}`)
    if (!!layer.setStyle) {
      layer.setStyle({color: !!layer.feature.properties.featureType ? theme.palette.warning.main : 'lightgreen'})
    }
    // if (!!layer.bringToFront && !L.Browser.ie && !L.Browser.opera) {
    //   /** Why ? what does it solve? it introduces a bug that lines (pipes) inside polygones (zones)
    //    * cannot be highlighted or selected.
    //    */
    //     layer.bringToFront()
    // }
  }
  const resetHighlight = (e) => {
    let layer = e.target
    // console.log(`resetHighlight featureType ${layer.feature.properties.featureType}`)
    if (!!layer.setStyle) {
      layer.setStyle(getDeafaultFeatureStyle(layer.feature))
      // if (!!layer.feature.properties.featureType) {
      //   let ftc = FTC[layer.feature.properties.featureType]
      //   let collectionLayer = leafstate.layers[ftc.collection]
      //   console.log(`resetHighlight for featureType ${layer.feature.properties.featureType}`)
      //   collectionLayer.resetStyle()
      // } else {
      //   console.log(`resetHighlight for drawnLayer`)
      //   leafstate.drawnLayer.resetStyle()
      // }
    }
  }
  //fired, if passed as an option, when creating a layer from a feature (or collection of features)
  const pointToLayer = (pointFeature, latlng) => {
    // console.log(`pointToLayer pointFeature ${JSON.stringify(pointFeature,null,2)}`)
    // console.log(`pointToLayer pointFeature.properties.code ${pointFeature.properties.code}`)
    let color = ''
    if (!!leafstate.selectedFeature && pointFeature._id === leafstate.selectedFeature._id) {
      color = SELECTED_COLOR
    } else if (!pointFeature.properties.featureType) {
      color = DRAWN_LAYER_COLOR
    // } else {
    //   let ftc = FTC[pointFeature.properties.featureType]
    //   color = !!ftc ? ftc.color : 'blue'
    }
    return L.marker(latlng, {
      // autoPan: true,
      // autoPanSpeed: 30,
      // pmIgnore: true,
      snapIgnore: LAYERS_THAT_DONT_SNAP_TO_PIPES.includes(pointFeature.properties.featureType) ? true : false,
      riseOnHover: true,
      riseOffset: 250,
      icon: featureMarker(
        color,
        pointFeature.properties.featureType,
        false,
        pointFeature.properties.network,
        pointFeature.properties.code
      ),
    })
  }
  /**
  * Sets the style for a layer based on its feature.
  * The returned style differs if the given feature is the currently selected feature.
  * Note: features with point geometry use the 'featureMarker' function
  * while features with multi-line or polygon geometry use the 'getDeafaultFeatureStyle' function instead
  */
  const styleLayer = layer => {
    if (!layer) return
    // console.log(layer)
    if (!!layer._icon) {
      let ftc = FTC[layer.feature.properties.featureType]
      let color = ''
      if (!!leafstate.selectedFeature && layer.feature._id === leafstate.selectedFeature._id) {
        color = SELECTED_COLOR
        // layer.setZIndexOffset(layer.zIndexOffset + 10000)
        layer.setZIndexOffset(10000)
      } else if (!layer.feature.properties.featureType) {
        color = DRAWN_LAYER_COLOR
      }
      layer.setIcon(
        featureMarker(
          color,
          !!ftc ? ftc.code : '',
          false,
          layer.feature.properties.network,
          layer.feature.properties.code
        )
      )
      if (!!leafstate.selectedFeature && layer.feature._id === leafstate.selectedFeature._id) {
        layer.setZIndexOffset(10000)
      } else {
        layer.setZIndexOffset(0)
      }
    } else if (!!layer.setStyle) {
      layer.setStyle(getDeafaultFeatureStyle(layer.feature))
    }
  }
  /**
  * Gets the default style for a feature of multi-line or polygon geometry.
  * The returned style differs if the given feature is the currently selected feature.
  * Note: features with point geometry use the 'featureMarker' function instead
  */
  const getDeafaultFeatureStyle = (feature) => {
    let style = {
      color: DRAWN_LAYER_COLOR,
      fillColor: "",
      weight: ""
    }
    let ftc = FTC[feature.properties.featureType]
    let isHighlighted:boolean = false

    if (feature.geometry.type === "LineString") {
      //Default line.wight = 3 is too thin for the user to select or highlight
      style.weight = '4'
    }
    if (
      networkElementsFilter.isActive &&
      !feature.isNew &&
      networkElementsFilter.highlightedFeatures[ftc.collection].some(
        f => f === feature.properties.code
      )
    ) {
      isHighlighted = true
    }
    if (!!leafstate.selectedFeature && feature._id === leafstate.selectedFeature._id) {
      style.color = SELECTED_COLOR
      style.weight = feature.geometry.type === "LineString" ? '6' : '3'
      if (!!feature.properties.network) {
        style.fillColor = LEGEND_COLORS[feature.properties.network]
      } else if (!feature.properties.featureType) {
        style.fillColor = DRAWN_LAYER_COLOR
      } else {
        style.fillColor = DEFAULT_COLOR
      }
    } else if (isHighlighted) {
      console.log(`isHighlighted`)
      style.color = LOCATION_HIGHLIGHT_COLOR
    } else if (feature.properties.network && feature.geometry.type !=='Point') {
      style.color = LEGEND_COLORS[feature.properties.network]
    } else if (feature.isNew) {
      style.color = DRAWN_LAYER_COLOR
    } else if (!feature.properties.network) {
      style.color = DEFAULT_COLOR
    } else if (!!ftc && ftc.color) {
      style.color = ftc.color
    } else if (!!feature.properties.color) {
      style.color = feature.properties.color
    } else if (feature.geometry.type === 'Polygon') {
      style.color = 'green'
    }
    return style
  }
  /**
  * Gets the default marker icon for a feature of point geometry.
  * The returned icon style differs if the given feature is the currently selected feature.
  * Note: features with multi-line or polygon geometry use the 'getDeafaultFeatureStyle' function instead
  */
  const featureMarker = (color, featureType, isCluster?, network?, code?) => {
    // console.log(`featureType ${featureType}`)
    let innerSvg: string | null = null
    switch (featureType) {
      case FTC.hydrant.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22" name="${featureType}_map_icon"">
            <path d="M7.5 16.5C7.5 16.2239 7.72386 16 8 16H10C10.2761 16 10.5 16.2239 10.5 16.5V18.3571C10.5 18.6333 10.2761 18.8571 10 18.8571H8C7.72386 18.8571 7.5 18.6333 7.5 18.3571V16.5Z" fill="white" stroke="#979797"/>
            <path d="M19.5 16.5C19.5 16.2239 19.7239 16 20 16H22C22.2761 16 22.5 16.2239 22.5 16.5V18.3571C22.5 18.6333 22.2761 18.8571 22 18.8571H20C19.7239 18.8571 19.5 18.6333 19.5 18.3571V16.5Z" fill="white" stroke="#979797"/>
            <rect x="10.5" y="11.5" width="9" height="13" fill="#9EE1E1" stroke="#979797"/>
            <path d="M8.5 25C8.5 24.7239 8.72386 24.5 9 24.5H21C21.2761 24.5 21.5 24.7239 21.5 25V27C21.5 27.2761 21.2761 27.5 21 27.5H9C8.72386 27.5 8.5 27.2761 8.5 27V25Z" fill="white" stroke="#979797"/>
            <rect x="13.5" y="2.5" width="3" height="2.85714" rx="0.5" fill="white" stroke="#979797"/>
            <path d="M10.1977 8.57141C10.4437 6.59826 12.1269 5.07141 14.1667 5.07141H15.8334C17.8732 5.07141 19.5564 6.59826 19.8025 8.57141H10.1977Z" fill="#9EE1E1" stroke="#979797"/>
            <path d="M17.1667 17.75C17.1667 18.8771 16.214 19.8214 15 19.8214C13.7861 19.8214 12.8334 18.8771 12.8334 17.75C12.8334 16.623 13.7861 15.6786 15 15.6786C16.214 15.6786 17.1667 16.623 17.1667 17.75Z" fill="white" stroke="#979797"/>
            <path d="M8.5 9C8.5 8.72386 8.72386 8.5 9 8.5H21C21.2761 8.5 21.5 8.72386 21.5 9V11C21.5 11.2761 21.2761 11.5 21 11.5H9C8.72386 11.5 8.5 11.2761 8.5 11V9Z" fill="white" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.hydrometer.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22"  name="${featureType}_map_icon"">
            <mask id="path-1-inside-1" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 25C21.0751 25 26 20.0751 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 20.0751 8.92487 25 15 25ZM15 20C18.3137 20 21 17.3137 21 14C21 10.6863 18.3137 8 15 8C11.6863 8 9 10.6863 9 14C9 17.3137 11.6863 20 15 20Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 25C21.0751 25 26 20.0751 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 20.0751 8.92487 25 15 25ZM15 20C18.3137 20 21 17.3137 21 14C21 10.6863 18.3137 8 15 8C11.6863 8 9 10.6863 9 14C9 17.3137 11.6863 20 15 20Z" fill="#9EE1E1" stroke="#979797"/>
            <path d="M17.9022 9.75949L17.9022 9.75949L13.425 14.5291C13.2045 14.7641 13.2605 15.1432 13.5396 15.3044L15.2811 16.3099C15.5602 16.471 15.9166 16.3299 16.0098 16.0215L17.9022 9.75949Z" fill="#9EE1E1" stroke="#979797"/>
            <path d="M8 22V27H22V22" stroke="#979797" fill="none"/>
          </svg>
        `
        break;
      case FTC.junction.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22"  name="${featureType}_map_icon"">
            <mask id="path-1-inside-1" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 6.06087V4.5873L13.3267 4.58731V6.06088L14.2689 6.06088V10.5241C14.2689 11.4489 13.5493 12.1986 12.6616 12.1986H8.47357V11.5199H7V18.8619H8.47357V18.2268L12.2689 18.2268C13.3735 18.2268 14.2689 19.1223 14.2689 20.2268V25.2222H20.055L20.055 10.5241V6.06087L21 6.06087Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 6.06087V4.5873L13.3267 4.58731V6.06088L14.2689 6.06088V10.5241C14.2689 11.4489 13.5493 12.1986 12.6616 12.1986H8.47357V11.5199H7V18.8619H8.47357V18.2268L12.2689 18.2268C13.3735 18.2268 14.2689 19.1223 14.2689 20.2268V25.2222H20.055L20.055 10.5241V6.06087L21 6.06087Z" fill="#9EE1E1" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.landparcel.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22" name="${featureType}_map_icon"">
            <path d="M0.5 6.12679C0.5 5.49174 0.8999 4.92551 1.49839 4.71315L11.7536 1.0742C12.4778 0.817216 13.2791 1.14912 13.6095 1.84294L16.766 8.47156C16.9361 8.82872 16.9582 9.23861 16.8275 9.61199L14.5683 16.067C14.3577 16.6686 13.7899 17.0714 13.1525 17.0714H2C1.17157 17.0714 0.5 16.3999 0.5 15.5714V6.12679Z" fill="" stroke="#979797"/>
            <path d="M8.57142 12.8571H4.28571V12H6.74999C6.43713 10.7957 5.48999 9.85286 4.28571 9.54C4.55999 9.47143 4.84713 9.42857 5.14285 9.42857C7.03713 9.42857 8.57142 10.9629 8.57142 12.8571ZM12.8571 9.54C12.5828 9.47143 12.2957 9.42857 12 9.42857C10.7443 9.42857 9.65142 10.1057 9.05142 11.1129C9.17571 11.3957 9.27856 11.6914 9.33856 12C9.39428 12.2786 9.42428 12.5657 9.42428 12.8571H10.2814H12.8528V12H10.3886C10.7057 10.7957 11.6528 9.85286 12.8571 9.54ZM10.1314 9.00857C10.4657 8.11286 11.0871 7.36286 11.8843 6.86571C10.0457 6.92571 8.57142 8.43 8.57142 10.2857C8.57142 10.29 8.57142 10.2943 8.57142 10.2943C8.97856 9.75 9.51428 9.30857 10.1314 9.00857ZM8.32285 8.07857C7.96285 7.14 7.23428 6.38143 6.29999 6C6.91713 6.79714 7.28571 7.79143 7.28571 8.87571C7.28571 8.96571 7.27285 9.05143 7.26856 9.13714C7.45285 9.24 7.62428 9.36 7.79142 9.48857C7.88142 8.98286 8.06999 8.50714 8.32285 8.07857Z" fill="#979797"/>
          </svg>
        `
        break;
      case FTC.pipe.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22" name="${featureType}_map_icon"">
            <mask id="path-1-inside-1" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27197 4.28566H6.54963V5.50503H11.3699C15.0584 5.50503 18.0486 8.37509 18.0486 11.9155V15.2601H12.8218V11.9155C12.8218 11.1458 12.1717 10.5219 11.3699 10.5219H6.54963V11.6019H5.27197V10.5219V5.50503V4.28566ZM24.4369 21.2175H19.6166C18.8148 21.2175 18.1648 20.5936 18.1648 19.8239V17.699H19.3263L19.3263 16.4796H18.1648V16.4793H12.938V16.4796H11.6603V17.699H12.938V19.8239C12.938 23.3643 15.9281 26.2344 19.6166 26.2344H24.4369V27.454H25.7146V26.2344V21.2175V20.1377H24.4369V21.2175Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27197 4.28566H6.54963V5.50503H11.3699C15.0584 5.50503 18.0486 8.37509 18.0486 11.9155V15.2601H12.8218V11.9155C12.8218 11.1458 12.1717 10.5219 11.3699 10.5219H6.54963V11.6019H5.27197V10.5219V5.50503V4.28566ZM24.4369 21.2175H19.6166C18.8148 21.2175 18.1648 20.5936 18.1648 19.8239V17.699H19.3263L19.3263 16.4796H18.1648V16.4793H12.938V16.4796H11.6603V17.699H12.938V19.8239C12.938 23.3643 15.9281 26.2344 19.6166 26.2344H24.4369V27.454H25.7146V26.2344V21.2175V20.1377H24.4369V21.2175Z" fill="#9EE1E1" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.pump.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22"  name="${featureType}_map_icon"">
            <rect x="6.5" y="7.5" width="17" height="15" rx="0.5" fill="#9EE1E1" stroke="#979797"/>
            <rect x="11.5" y="4.5" width="7" height="3" rx="0.5" fill="none" stroke="#979797"/>
            <path d="M10 12L20 12" stroke="#979797" stroke-linecap="round"/>
            <path d="M10 15L20 15" stroke="#979797" stroke-linecap="round"/>
            <path d="M10 18L20 18" stroke="#979797" stroke-linecap="round"/>
            <rect x="6.5" y="22.5" width="4" height="3" rx="0.5" fill="none" stroke="#979797"/>
            <rect x="19.5" y="22.5" width="4" height="3" rx="0.5" fill="none" stroke="#979797"/>
            <path d="M4.5 8C4.5 7.72386 4.72386 7.5 5 7.5H6C6.27614 7.5 6.5 7.72386 6.5 8V22C6.5 22.2761 6.27614 22.5 6 22.5H5C4.72386 22.5 4.5 22.2761 4.5 22V8Z" fill="none" stroke="#979797"/>
            <path d="M2.5 11C2.5 10.7239 2.72386 10.5 3 10.5H4C4.27614 10.5 4.5 10.7239 4.5 11V20C4.5 20.2761 4.27614 20.5 4 20.5H3C2.72386 20.5 2.5 20.2761 2.5 20V11Z" fill="#9EE1E1" stroke="#979797"/>
            <rect x="23.5" y="7.5" width="2" height="15" rx="0.5" fill="none" stroke="#979797"/>
            <rect x="25.5" y="10.5" width="2" height="10" rx="0.5" fill="#9EE1E1" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.reservoir.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22"  name="${featureType}_map_icon"">
            <mask id="path-1-inside-1" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6334 2.27903L10 5.67949L6.60531 8.98232C6.21832 9.35885 6 9.87585 6 10.4158V23C6 24.1046 6.89543 25 8 25H22C23.1046 25 24 24.1046 24 23V10.4158C24 9.87585 23.7817 9.35885 23.3947 8.98232L20 5.67949L16.3666 2.27903C15.5977 1.55935 14.4023 1.55935 13.6334 2.27903ZM17.1214 18.2958C17.1923 18.239 17.2588 18.1802 17.3209 18.1191C18.1238 17.3348 18.2304 16.2185 17.557 15.3391L16.4164 13.8493L15.8018 13.047L15.0001 12L12.9786 14.6397L12.443 15.3391C12.1453 15.7279 12 16.1631 12 16.5953C12 17.2168 12.3 17.8321 12.8786 18.2958C13.4646 18.7651 14.2324 19 15.0001 19C15.7679 19 16.5356 18.7651 17.1214 18.2958Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6334 2.27903L10 5.67949L6.60531 8.98232C6.21832 9.35885 6 9.87585 6 10.4158V23C6 24.1046 6.89543 25 8 25H22C23.1046 25 24 24.1046 24 23V10.4158C24 9.87585 23.7817 9.35885 23.3947 8.98232L20 5.67949L16.3666 2.27903C15.5977 1.55935 14.4023 1.55935 13.6334 2.27903ZM17.1214 18.2958C17.1923 18.239 17.2588 18.1802 17.3209 18.1191C18.1238 17.3348 18.2304 16.2185 17.557 15.3391L16.4164 13.8493L15.8018 13.047L15.0001 12L12.9786 14.6397L12.443 15.3391C12.1453 15.7279 12 16.1631 12 16.5953C12 17.2168 12.3 17.8321 12.8786 18.2958C13.4646 18.7651 14.2324 19 15.0001 19C15.7679 19 16.5356 18.7651 17.1214 18.2958Z" fill="#9EE1E1" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.samplingPoint.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="-3 0 33 30" x="14" y="-3" width="22"  name="${featureType}_map_icon"">
            <path d="M10.6079 16.9405L18.4062 7.22647L22.831 10.7787L15.0327 20.4927C14.5492 21.0951 13.7965 21.416 13.0271 21.3477C12.5486 21.3053 12.0805 21.5048 11.7798 21.8794L11.0968 22.7302C10.6137 23.332 9.7987 23.4476 9.27157 23.0244C8.80638 22.6509 8.74745 21.9234 9.18633 21.3767L9.95725 20.4163C10.258 20.0417 10.3516 19.5416 10.2067 19.0836C9.97375 18.3471 10.1243 17.5429 10.6079 16.9405Z" fill="none" stroke="#979797"/>
            <path d="M19.7288 5.5792L20.7492 4.30818C21.7301 3.08628 23.5158 2.89093 24.7377 3.87185C25.9596 4.85277 26.1549 6.63851 25.174 7.8604L24.1537 9.13143L19.7288 5.5792Z" fill="#9EE1E1" stroke="#979797"/>
            <rect x="18.0641" y="4.09882" width="10.1239" height="3.3862" rx="1.5" transform="rotate(38.757 18.0641 4.09882)" fill="#9EE1E1" stroke="#979797"/>
            <path d="M6.05243 25.7961L6.05367 25.7946C6.58225 25.1751 6.65044 24.2993 6.20935 23.608C6.20935 23.608 6.20935 23.608 6.20935 23.608L5.25889 22.1184L5.25884 22.1183L4.74672 21.316L4.74667 21.3159L4.50009 20.9295L3.237 22.9087L3.23698 22.9087L2.79067 23.608C2.79065 23.608 2.79064 23.608 2.79062 23.6081C2.59453 23.9154 2.5 24.2571 2.5 24.5953C2.5 25.0818 2.69526 25.5666 3.07866 25.9353L6.05243 25.7961ZM6.05243 25.7961C6.01244 25.8433 5.96877 25.8897 5.92128 25.9354M6.05243 25.7961L5.92128 25.9354M5.92128 25.9354C5.53191 26.3098 5.01869 26.5 4.50011 26.5M5.92128 25.9354L4.50011 26.5M4.50011 26.5C3.98159 26.5 3.46835 26.3098 3.07873 25.9354L4.50011 26.5Z" fill="#9EE1E1" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.station.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22"  name="${featureType}_map_icon"">
            <path d="M25 25.5H20.3889L20.3889 13.0555H22.9445H24C24.8285 13.0555 25.5 13.7271 25.5 14.5555V25C25.5 25.2761 25.2762 25.5 25 25.5Z" fill="#9EE1E1" stroke="#979797"/>
            <mask id="path-2-inside-1" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4C4.89543 4 4 4.89543 4 6.00001L4.00005 25C4.00005 25.5523 4.44776 26 5.00005 26H21.1111V6C21.1111 4.89543 20.2157 4 19.1111 4H12.5556H6ZM15.846 21.3684H10.5811V24.8421H15.846V21.3684ZM8.88888 14.421H12.8376V16.7368H8.88888V14.421ZM12.8376 8.63152H8.88888V10.9473H12.8376V8.63152Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4C4.89543 4 4 4.89543 4 6.00001L4.00005 25C4.00005 25.5523 4.44776 26 5.00005 26H21.1111V6C21.1111 4.89543 20.2157 4 19.1111 4H12.5556H6ZM15.846 21.3684H10.5811V24.8421H15.846V21.3684ZM8.88888 14.421H12.8376V16.7368H8.88888V14.421ZM12.8376 8.63152H8.88888V10.9473H12.8376V8.63152Z" fill="#9EE1E1" stroke="#979797"/>
            <path d="M4 6.00001L5 6L4 6.00001ZM4.00005 25L5.00005 25L4.00005 25ZM21.1111 26V27H22.1111V26H21.1111ZM10.5811 21.3684V20.3684H9.58108V21.3684H10.5811ZM15.846 21.3684H16.846V20.3684H15.846V21.3684ZM10.5811 24.8421H9.58108V25.8421H10.5811V24.8421ZM15.846 24.8421V25.8421H16.846V24.8421H15.846ZM12.8376 14.421H13.8376V13.421H12.8376V14.421ZM8.88888 14.421V13.421H7.88888V14.421H8.88888ZM12.8376 16.7368V17.7368H13.8376V16.7368H12.8376ZM8.88888 16.7368H7.88888V17.7368H8.88888V16.7368ZM8.88888 8.63152V7.63152H7.88888V8.63152H8.88888ZM12.8376 8.63152H13.8376V7.63152H12.8376V8.63152ZM8.88888 10.9473H7.88888V11.9473H8.88888V10.9473ZM12.8376 10.9473V11.9473H13.8376V10.9473H12.8376ZM5 6C5 5.44772 5.44771 5 6 5V3C4.34314 3 3 4.34315 3 6.00001L5 6ZM5.00005 25L5 6L3 6.00001L3.00005 25L5.00005 25ZM5.00005 25L5.00005 25L3.00005 25C3.00005 26.1046 3.89548 27 5.00005 27V25ZM21.1111 25H5.00005V27H21.1111V25ZM20.1111 6V26H22.1111V6H20.1111ZM19.1111 5C19.6634 5 20.1111 5.44771 20.1111 6H22.1111C22.1111 4.34315 20.768 3 19.1111 3V5ZM12.5556 5H19.1111V3H12.5556V5ZM6 5H12.5556V3H6V5ZM10.5811 22.3684H15.846V20.3684H10.5811V22.3684ZM11.5811 24.8421V21.3684H9.58108V24.8421H11.5811ZM15.846 23.8421H10.5811V25.8421H15.846V23.8421ZM14.846 21.3684V24.8421H16.846V21.3684H14.846ZM12.8376 13.421H8.88888V15.421H12.8376V13.421ZM13.8376 16.7368V14.421H11.8376V16.7368H13.8376ZM8.88888 17.7368H12.8376V15.7368H8.88888V17.7368ZM7.88888 14.421V16.7368H9.88888V14.421H7.88888ZM8.88888 9.63152H12.8376V7.63152H8.88888V9.63152ZM9.88888 10.9473V8.63152H7.88888V10.9473H9.88888ZM12.8376 9.94731H8.88888V11.9473H12.8376V9.94731ZM11.8376 8.63152V10.9473H13.8376V8.63152H11.8376Z" fill="#979797" mask="url(#path-2-inside-1)"/>
          </svg>
        `
        break;
      case FTC.switch.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="-2 -2 27 27" x="14" y="-3" width="22" name="${featureType}_map_icon"">
            <path d="M18.9455 3.2727V2.8727H18.5455H5.45455H5.05455V3.2727V20.7272V21.1272H5.45455H18.5455H18.9455V20.7272V3.2727ZM9.81819 9.41815H9.41819V9.81815V10.5091H6.94546V4.76361H17.0546V10.5091H14.5818V9.81815V9.41815H14.1818H9.81819ZM5.45455 1.49088H18.5455C19.5245 1.49088 20.3273 2.29361 20.3273 3.2727V20.7272C20.3273 21.7063 19.5245 22.5091 18.5455 22.5091H5.45455C4.47547 22.5091 3.67274 21.7063 3.67274 20.7272V3.2727C3.67274 2.29361 4.47547 1.49088 5.45455 1.49088ZM17.0546 13.4909V19.2363H6.94546V13.4909H17.0546Z" fill="#9EE1E1" stroke="#979797" stroke-width="0.8"/>
          </svg>
        `
        break;
      case FTC.tank.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22" name="${featureType}_map_icon"">
            <path d="M9.29368 14.4309L6 15.2222V23C6 24.1046 6.89543 25 8 25H22C23.1046 25 24 24.1046 24 23V15.2222L20.7063 14.4309C19.5499 14.153 18.3321 14.3024 17.2771 14.8516C15.8499 15.5946 14.1501 15.5946 12.7229 14.8516C11.6679 14.3024 10.4501 14.153 9.29368 14.4309Z" fill="#9EE1E1"/>
            <path d="M3.75 15L7.93203 14.1779C9.34208 13.9007 10.8044 14.1056 12.084 14.7596V14.7596C13.9153 15.6956 16.0847 15.6956 17.916 14.7596V14.7596C19.1956 14.1056 20.6579 13.9007 22.068 14.1779L26.25 15" stroke="#979797" stroke-linecap="round"/>
            <rect x="5.57355" y="5.5" width="18.8529" height="19" rx="1.5" fill="none" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.valve.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22" name="${featureType}_map_icon"">
            <rect x="8.22186" y="2.76318" width="13.5564" height="24.4736" rx="1.5" stroke="#979797" fill="none"/>
            <mask id="path-2-inside-1" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26 14.8842C26 20.9593 21.0751 25.8842 15 25.8842C8.92492 25.8842 4.00006 20.9593 4.00006 14.8842C4.00006 8.80909 8.92492 3.88423 15 3.88423C21.0751 3.88423 26 8.80909 26 14.8842ZM20.1141 19.5698C18.8583 20.9743 17.0324 21.8583 15 21.8583C12.9917 21.8583 11.1851 20.9951 9.93077 19.6195L13.8218 17.373C14.1834 17.5281 14.5817 17.614 15.0001 17.614C15.4472 17.614 15.8713 17.516 16.2523 17.3401L20.1141 19.5698ZM21.6996 16.4737L17.9763 14.3241C17.8869 13.4226 17.3972 12.6388 16.6874 12.1531V8.35083C19.6592 9.10268 21.8583 11.7945 21.8583 15C21.8583 15.5059 21.8035 15.999 21.6996 16.4737ZM13.3129 8.35075C10.341 9.10249 8.14172 11.7944 8.14172 15C8.14172 15.5297 8.20177 16.0454 8.31545 16.5406L12.017 14.4035C12.0848 13.4687 12.5823 12.6529 13.3129 12.1529V8.35075Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26 14.8842C26 20.9593 21.0751 25.8842 15 25.8842C8.92492 25.8842 4.00006 20.9593 4.00006 14.8842C4.00006 8.80909 8.92492 3.88423 15 3.88423C21.0751 3.88423 26 8.80909 26 14.8842ZM20.1141 19.5698C18.8583 20.9743 17.0324 21.8583 15 21.8583C12.9917 21.8583 11.1851 20.9951 9.93077 19.6195L13.8218 17.373C14.1834 17.5281 14.5817 17.614 15.0001 17.614C15.4472 17.614 15.8713 17.516 16.2523 17.3401L20.1141 19.5698ZM21.6996 16.4737L17.9763 14.3241C17.8869 13.4226 17.3972 12.6388 16.6874 12.1531V8.35083C19.6592 9.10268 21.8583 11.7945 21.8583 15C21.8583 15.5059 21.8035 15.999 21.6996 16.4737ZM13.3129 8.35075C10.341 9.10249 8.14172 11.7944 8.14172 15C8.14172 15.5297 8.20177 16.0454 8.31545 16.5406L12.017 14.4035C12.0848 13.4687 12.5823 12.6529 13.3129 12.1529V8.35075Z" fill="#9EE1E1" stroke="#979797"/>
          </svg>
        `
        break;
      case FTC.wszone.code:
        innerSvg = `
          <svg class="${classes.featureMarkerInnerSvg}" viewBox="0 0 30 30" x="14" y="-3" width="22" name="${featureType}_map_icon"">
            <path d="M7.27683 11.8225C7.40024 11.3609 7.73617 10.9857 8.18138 10.8123L19.7135 6.31921C20.5748 5.98367 21.5316 6.49931 21.7249 7.40314L24.9195 22.3386C25.136 23.351 24.2861 24.2732 23.2594 24.1399L6.32871 21.9411C5.42935 21.8243 4.83855 20.9423 5.07279 20.0661L7.27683 11.8225Z" fill="#9EE1E1" stroke="#979797"/>
          </svg>
        `
        break;
      default:
        innerSvg = `
          <svg viewBox="0 0 30 30" x="15" y="9" width="24" fill="none">
            <circle cx="12" y="12" r="12" fill="white" />
          </svg>
        `
    }

      let icon;
      if (
        props.urgentAlarmNotifications?.length > 0 &&
        !!urgentAlarmsNotificationsGroupedByFeatureType &&
        urgentAlarmsNotificationsGroupedByFeatureType[featureType]?.length > 0 &&
        urgentAlarmsNotificationsGroupedByFeatureType[featureType].some(
          el => el.ms.featureCode === code
        )
      ) {
        //FEATURE HAS ALARM =>style svg for keyframes animation
        const alarmNumber = urgentAlarmsNotificationsGroupedByFeatureType[featureType].filter(f=>f.ms.featureCode===code).length
         icon = L.divIcon({
          html: `
             <svg width="${isCluster ? 45 : 30}" viewBox="10 4 30 44" fill="none" overflow="visible" xmlns="http://www.w3.org/2000/svg">
                <path class="${classes.alarm}" id="alarm" d="M25 4.16675C16.9375 4.16675 10.4166 10.6876 10.4166 18.7501C10.4166 29.6876 25 45.8334 25 45.8334C25 45.8334 39.5833 29.6876 39.5833 18.7501C39.5833 10.6876 33.0625 4.16675 25 4.16675Z" fill="black" stroke="${ALARM_COLOR}" stroke-width="6" />
                <path d="M 25 5.827 C 17.793 5.827 11.965 11.753 11.965 19.079 C 11.965 29.017 25 43.689 25 43.689 C 25 43.689 38.035 29.017 38.035 19.079 C 38.035 11.753 32.207 5.827 25 5.827 Z" fill="currentColor"/>
                <rect x="13.9706" y="8.08813" width="22.0588" height="22.0588" rx="11.0294" fill="white"/>
                <circle cx="9" y="9" r="10" fill="white" stroke="${ALARM_COLOR}" stroke-width="1" />
                <text x="${alarmNumber > 9 ? 3 : 6}" y="4.5" font-weight="bold" fill="black">${alarmNumber}</text>
                ${innerSvg}
              <defs>
                <filter id="filter1_d" x="6.41663" y="4.16675" width="37.1667" height="49.6667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
              </defs>
            </svg>
          `,
          iconAnchor: isCluster ? [22, 66] : [15, 44],
          iconSize: isCluster ? [45, 66] : [30, 44],
          className: getFeatureMarkerClass(featureType, isCluster,network,code)
        })
      } else {
         icon = L.divIcon({
          html: `
             <svg width="${isCluster ? 45 : 30}" viewBox="10 4 30 44" fill="none" overflow="visible" xmlns="http://www.w3.org/2000/svg">
                 ${color === SELECTED_COLOR ?
                `
                <path d="M25 4.16675C16.9375 4.16675 10.4166 10.6876 10.4166 18.7501C10.4166 29.6876 25 45.8334 25 45.8334C25 45.8334 39.5833 29.6876 39.5833 18.7501C39.5833 10.6876 33.0625 4.16675 25 4.16675Z" fill="${SELECTED_COLOR}" stroke="${SELECTED_COLOR}" stroke-width="6" />
                 <path d="M 25 5.827 C 17.793 5.827 11.965 11.753 11.965 19.079 C 11.965 29.017 25 43.689 25 43.689 C 25 43.689 38.035 29.017 38.035 19.079 C 38.035 11.753 32.207 5.827 25 5.827 Z" fill="currentColor"/>`
                : `<path d="M 25 5.827 C 17.793 5.827 11.965 11.753 11.965 19.079 C 11.965 29.017 25 43.689 25 43.689 C 25 43.689 38.035 29.017 38.035 19.079 C 38.035 11.753 32.207 5.827 25 5.827 Z" fill="${!!color ? color : "currentColor"}"/>`
                }
                <rect x="13.9706" y="8.08813" width="22.0588" height="22.0588" rx="11.0294" fill="white"/>
                ${innerSvg}
              <defs>
                <filter id="filter1_d" x="6.41663" y="4.16675" width="37.1667" height="49.6667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
              </defs>
            </svg>
          `,
          iconAnchor: isCluster ? [22, 66] : [15, 44],
          iconSize: isCluster ? [45, 66] : [30, 44],
          // className: clsx(classes.featureMarkerDiv, classes.featureMarkerDivTank),
          className: getFeatureMarkerClass(featureType, isCluster,network,code)
        })
      }
    return icon
  }
  const getFeatureMarkerClass = (featureType, isCluster?, network?, code?) => {

    let isHighlighted: boolean = false
    if (featureType) {
      isHighlighted = networkElementsFilter.isActive 
      if (networkElementsFilter.isActive) {
        isHighlighted = isHighlighted && networkElementsFilter.highlightedFeatures[FTC[featureType].collection].some(f => f === code)
      }
    }

    let featureClass = isCluster ? classes.featureClusterMarkerDivDefault : classes.featureMarkerDivDefault
    if (isHighlighted) {
      return clsx(classes.featureMarkerDiv, featureClass, classes.searchByLocationHighlight)
    } else if (network) {
      return clsx(classes.featureMarkerDiv, featureClass, classes[`${network}Icon`])
    } else if (!featureType) {//i.e. a free-draw point
      return clsx(classes.featureMarkerDiv, featureClass, classes.featureMarkerDivFreeDraw)
    }
    return clsx(classes.featureMarkerDiv, featureClass)
  }

  /**shows arrowheads (leaflet-arrowheads plugin) along a polyline, direction of the arrowheads is dependant on the flowDirection */
  const toggleLineArrows = (feature,flowDirection,layer?) =>{
    let featureLayer = layer || getLayerByFeature(feature)
    addEditedGeometryTooltips(featureLayer)
    // let coords = featureLayer.getLatLngs()
    // if (flowDirectionArrowsRef.current) {
    //   //@ts-ignore
    //   flowDirectionArrowsRef.current.remove()
    // }
    //   flowDirectionArrowsRef.current = L.polyline(coords, {color: 'black'}).arrowheads({
    //     fill: true,
    //     color: 'black',
    //     fillColor: 'orange',
    //     frequency: 5,
    //     size: '20px',
    //     yawn: flowDirection === 'reverse' ? 270 : 90, //270 for 'reverse',90 for 'normal'
    //   })
    //   //@ts-ignore
    //   flowDirectionArrowsRef?.current?.addTo(lmap)
  }
  const hideLineArrows = (feature,layer?)=>{
    // let featureLayer = layer || getLayerByFeature(feature)
    //@ts-ignore
    // flowDirectionArrowsRef.current?.remove()
    removeEditedGeometryTooltips()
  }

  const handleCloseFlowPopUp = (e)=>{
    hideLineArrows(leafstate.selectedFeature)
    props.setFlowPopupIsOpen(false)
  }

  function MyMapEventHandlerComponent() {
    if (!lmap) return null;
    // onClick={handleMapClick}
    // onMouseMove={leafstate.drawing ? handleMapMouseMove : null}
    // onMoveend={handleOnMoveend}
    // onZoomend={debounced(50, handleOnZoomend)}
    let eventOptions: any = {
      click: handleMapClick,
      moveend: handleOnMoveend,
      zoomend: _.debounce(handleOnZoomend, 60),
    }
    if (leafstate.drawing) {
      eventOptions.mousemove = handleMapMouseMove
    }
    const map = useMapEvents(eventOptions);
    return null;
  }
  const EventHandlerComponentMemo = useMemo(() => {
    return !!lmap ? <MyMapEventHandlerComponent /> : null
  }, [lmap, leafstate.drawing, networkElementCollection, networkElementsFilter, leafstate, props.visibleLayers])

  return (
    <div
      // id='lmap'
      style={{position:'relative'
      }}
      onKeyDown={handleKeydown}
      tabIndex={-1}
    >
      {leafstate.drawing === true && leafstate.drawingLayerKey=== "pipe" &&
       <section className={classes.customSnackbar}>
         <Typography variant="body1">
           {leafstate.drawingFeature == null && t("map.createPipe.mapToolTipText.draw1") }
           {leafstate.drawingFeature && leafstate.map?.pm?.globalDrawModeEnabled() && t("map.createPipe.mapToolTipText.draw2") }
           {leafstate.drawingFeature && !leafstate.map?.pm?.globalDrawModeEnabled() &&  t("map.createPipe.mapToolTipText.draw3")  }
          </Typography>
         {leafstate.map?.pm?.globalDrawModeEnabled() && <Typography variant="body2">{t("map.createPipe.mapToolTipText.cancel")}</Typography>}
      </section>}
      {leafstate.editing === true && leafstate.editingFeature?.properties?.featureType === "pipe" &&
       <section className={classes.customSnackbar}>
         <Typography variant="body1">
            {t(("map.createPipe.mapToolTipText.edit"))}
          </Typography>
         <Typography variant="body2">{t("map.createPipe.mapToolTipText.cancel")}</Typography>
      </section>}
      <Box mt={-1.25}
        display='flex'
        flexDirection='column'
        alignItems='flex-end'
        className={classes.mapToolbar}
      >
      {accessGranted('VwMsrm') && props.contentKey === 'entities' && (
        <Tooltip title={`${t(`mtb.measurementTools`)}`} classes={{tooltip: classes.tooltip}} arrow placement='left' enterDelay={1000} enterNextDelay={500} >
          <IconButton key='rulerTools'
            className={rulerToolsOpen ? clsx(classes.tBtnSelected, classes.tgBtn, classes.tBtn) : clsx(classes.tgBtn, classes.tBtn)}
            onClick={(e) => {setRulerToolsOpen(!rulerToolsOpen); if (featureToolsOpen) setFeatureToolsOpen(false)}}
          >
            <WrmIcon
              icon='measurementTools'
            />
          </IconButton>
        </Tooltip>
      )}
      {accessGranted('VwMsrm') && rulerToolsOpen && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-end'
        >
        <Tooltip title={`${t(`mtb.measure.altitude`)}`} classes={{tooltip: classes.tooltip}} arrow placement='left' enterDelay={1000} enterNextDelay={500} >
          <IconButton key='marker'
            className={leafstate.drawing && leafstate.drawingGeometryType === 'Point' && leafstate.drawingLayerKey === 'drawnLayer' ? clsx(classes.tBtnSelected, classes.tBtn) : classes.tBtn}
            onClick={(e) => handleMapToolClick(e, 'Point', 'drawnLayer')}
          >
            <WrmIcon
              icon='marker'
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={`${t(`mtb.measure.length`)}`} classes={{tooltip: classes.tooltip}} arrow placement='left' enterDelay={1000} enterNextDelay={500} >
          <IconButton key='ruler'
            className={leafstate.drawing && leafstate.drawingGeometryType === 'LineString' && leafstate.drawingLayerKey === 'drawnLayer' ? clsx(classes.tBtnSelected, classes.tBtn) : classes.tBtn}
            onClick={(e) => handleMapToolClick(e, 'LineString', 'drawnLayer')}
          >
            <WrmIcon
              icon='ruler'
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={`${t(`mtb.measure.area`)}`} classes={{tooltip: classes.tooltip}} arrow placement='left' enterDelay={1000} enterNextDelay={500} >
          <IconButton key='rulerArea'
            className={leafstate.drawing && leafstate.drawingGeometryType === 'Polygon' && leafstate.drawingLayerKey === 'drawnLayer' ? clsx(classes.tBtnSelected, classes.tBtn) : classes.tBtn}
            onClick={(e) => handleMapToolClick(e, 'Polygon', 'drawnLayer')}
          >
            <WrmIcon
              icon='rulerArea'
            />
          </IconButton>
        </Tooltip>
        </Box>
      )}
      {accessGranted('dtNtwr3') && props.contentKey === 'entities' && (
        <Tooltip title={`${t(`mtb.addEntities`)}`} classes={{tooltip: classes.tooltip}} arrow placement='left' enterDelay={1000} enterNextDelay={500} >
          <IconButton key='featureTools'
            className={featureToolsOpen ? clsx(classes.tBtnSelected, classes.tgBtn, classes.tBtn) : clsx(classes.tgBtn, classes.tBtn)}
            onClick={(e) => {setFeatureToolsOpen(!featureToolsOpen); if (rulerToolsOpen) setRulerToolsOpen(false)}}
          >
            <WrmIcon
              icon='mapTools'
            />
          </IconButton>
        </Tooltip>
      )}
      {accessGranted('dtNtwr3') && featureToolsOpen && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-end'
        >
        {Object.values<any>(FTC)
          .filter(ft => !ft.accessRight || accessGranted(ft.accessRight))
          .sort((a, b) => alphabeticalSorter(t(`net.${a.code}.plural`), t(`net.${b.code}.plural`)))
          .map(ft => (
          <Tooltip key={ft.code} title={`${t(`net.${ft.code}`)}`} classes={{tooltip: classes.tooltip}} arrow placement='left' enterDelay={1000} enterNextDelay={500} >
            <IconButton
              /**In case we are in a 'completed'(past) agricultural period the user should not be able to create new land parcels*/
              style={ft.code === 'landparcel' && currentAgriculturalPeriod?.status !== 'active' ? {cursor:'not-allowed'} : {}}
              className={leafstate.drawing && leafstate.drawingLayerKey === ft.code ? clsx(classes.tBtnSelected, classes.tBtn) : classes.tBtn}
              onClick={(e) => {
                ft.code === 'landparcel' && currentAgriculturalPeriod?.status !== 'active'
                  ? ()=>{}
                  : handleMapToolClick(e, ft.geometryType, ft.code)
              }}
            >
              <WrmIcon
                icon={ft.code}
              />
            </IconButton>
          </Tooltip>
        ))}
        </Box>
      )}
      {(accessGranted('vwntwr') || accessGranted('dtNtwr3')) && (
        <Tooltip title={`${t(`mtb.legend`)}`} classes={{tooltip: classes.tooltip}} arrow placement='left' enterDelay={1000} enterNextDelay={500} >
          <IconButton key='legend'
            className={rulerToolsOpen ? clsx(classes.tBtnSelected, classes.tgBtn, classes.tBtn) : clsx(classes.tgBtn, classes.tBtn)}
            onClick={()=>setLegendOpen(true)}
          >
            <WrmIcon
              icon='legend'
            />
          </IconButton>
        </Tooltip>
      )}
      </Box>
      <MapContainer
        center={mapCenter}
        style={{height: '100vh', cursor: leafstate.drawing ? 'crosshair' : ''}}
        whenCreated={m => {
          leafstate.map = m //store map instance in leafstate
          setMainMap(m)
          // mapRef.current = m //store map instance as a ref
          // setMap(m) //store map instance as a react state variable
          // rmap = m //store map instance as a plain variable
        }}
        //@ts-ignore
        editable={true}//leaflet-editable

        //@ts-ignore
        zoom={mapZoomLevel}
        maxZoom={30}
        zoomControl={false}
      >
        {/* attach event handlers */}
        {EventHandlerComponentMemo}

        {props.flowPopupIsOpen && selectedFeaturePointCoords && leafstate.selectedFeature &&
        <Popup
          position={[selectedFeaturePointCoords[1], selectedFeaturePointCoords[0]]}
          //@ts-ignore
          closeButton={false} 
          // onClose={ handleCloseFlowPopUp }
          // onClose={ () => handleCloseFlowPopUp(undefined) }
          eventHandlers={{
            //@ts-ignore
            onclose: handleCloseFlowPopUp
          }}
        >
          <FlowDirectionPopUp
            hideLineArrows={hideLineArrows}
            toggleLineArrows={toggleLineArrows}
            saveFlow={saveFeature}
            feature={leafstate.selectedFeature}
            isOpen={props.flowPopupIsOpen}
            setFlowPopupIsOpen={props.setFlowPopupIsOpen}
          />
        </Popup>}
        {!!selectedEntity && selectedFeaturePopOpen && selectedFeaturePointCoords &&
        <Popup
          //@ts-ignore
          className='selectedFeaturePop'
          position={[selectedFeaturePointCoords[1], selectedFeaturePointCoords[0]]}
          offset={[0, leafstate.selectedFeature.geometry.type === 'Point' ? -36 : 0]}
          //@ts-ignore
          autoPan={false}
          maxWidth={600}
          //@ts-ignore
          closeButton={false}
          // onClose={closeSelectedFeaturePop}
          eventHandlers={{
            //@ts-ignore
            onclose: closeSelectedFeaturePop
          }}
        >
          {!!leafstate.selectedFeature &&
          <FeatureDetails
            noEdit={props.noEdit}
            noDelete={props.noDelete}
            // theFeature={leafstate.selectedFeature}
            target_id={props.target_id}
            setTarget_id={props.setTarget_id}
            onClose={closeSelectedFeaturePop}
          />}
        </Popup>}
        {/* Notification Popup - this will trigger only when there is a feature to zoom to */}
        {!!props.selectedFeatureWithActiveAlarms?.feature &&
          <Popup
            //@ts-ignore
            closeOnClick={false}
            //@ts-ignore
            className='selectedFeaturePop'
            position={
              props.selectedFeatureWithActiveAlarms.feature?.geometry?.coordinates
                ? L.GeoJSON.coordsToLatLng(props.selectedFeatureWithActiveAlarms.feature?.geometry?.coordinates)
                : leafstate.map.getCenter()
            }
            offset={[0, props.selectedFeatureWithActiveAlarms?.feature?.geometry?.type !== 'Point' ? 0 : -36]}
            maxWidth={600}
            closeButton={false}
            onClose={()=>props.setSelectedFeatureWithActiveAlarms(null)}
          >
            <NotificationPopUp
              handleClosePopUp={props.handleCloseAlarmPopUp}
              selectedFeatureWithActiveAlarms={props.selectedFeatureWithActiveAlarms}
              inventoryItems={props.inventoryItems || []}
              maintenanceService={props.maintenanceService}
              repairs={[]} //testing
          />
          </Popup>}
        <ZoomControl position='topleft' />
        <LayersControl position='topright' >
          {BaseMapLayers
            .filter(b => b.checked || accessGranted('VwMpLy'))
            .map(b => (
            <LayersControl.BaseLayer
              key={b.name}
              name={b.name}
              checked={b.checked}>
              {b.wms ? (
                <WMSTileLayer
                  url={b.url}
                  attribution={ b.attribution }
                  maxZoom={b.maxZoom ? b.maxZoom : 18}
                />
              ) : (
                <TileLayer
                  attribution={b.attribution}
                  url={b.url}
                  maxZoom={b.maxZoom ? b.maxZoom : 18}
                />
              )}
            </LayersControl.BaseLayer>
          ))}
        </LayersControl>
        {networkElementsFilter.locationFilter.isActive &&  <>
        <Circle 
          center={networkElementsFilter.locationFilter.center as LatLngTuple} 
          pathOptions={{color: "red", fillColor: "red", fillOpacity: 0.9}}
          //@ts-ignore
          radius={1} 
        />
        <Circle 
          center={networkElementsFilter.locationFilter.center as LatLngTuple} 
          pathOptions={{color: "red", fillColor: "#00b1b1"}}
          //@ts-ignore
          radius={+networkElementsFilter.locationFilter.radius} 
        />
        </>}
        {!!circleMarkers && !!circleMarkers.length && 
          circleMarkers.map((c,i)=> (
            <React.Fragment key={i}>
              <Circle 
                center={c.center} 
                pathOptions={{color: "red", fillColor: "red", fillOpacity: 0.9}}
                //@ts-ignore
                radius={1} 
              />
              <Circle 
                center={c.center}
                pathOptions={{color: c.color ? c.color : "red", fillColor: "#00b1b1"}}
                //@ts-ignore
                radius={c.radius ? c.radius : 20}
              />
            </React.Fragment>
          ))
        }
      </MapContainer>
      <Dialog
        open={legendOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>setLegendOpen(false)}
        // aria-labelledby="legend-dialog"
        // aria-describedby="legend-dialog-slide-description"
        classes={{paper:classes.legendPaper, scrollPaper: classes.legendScrollPaper}}
      >
        <DialogContent className={classes.legendContent}>
          <h1 className={classes.legendTitle}>{t('mtb.legend.title')}</h1>
          <Box className={classes.legendItemContainer}>
            <div className={clsx(classes.legendWaterSupply, classes.legendItem)}>{t('water.networks.waterSupply')}</div>
            <div className={clsx(classes.legendAgriculture, classes.legendItem)}>{t('water.networks.agriculture')}</div>
            <div className={clsx(classes.legendIndustrial, classes.legendItem)}>{t('water.networks.industrial')}</div>
            <div className={clsx(classes.legendEnergy, classes.legendItem)}>{t('water.networks.energy')}</div>
            <div className={clsx(classes.legendRecreation, classes.legendItem)}>{t('water.networks.recreation')}</div>
            <div className={clsx(classes.legendDrainage, classes.legendItem)}>{t('water.networks.drainage')}</div>
            <div className={clsx(classes.legendDefault, classes.legendItem)}>{t('water.networks.default')}</div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )

}
export default LMap
