export interface MaintenancePlanNotes {
    text: string,
    addedBy: string,
    modifiedBy: string,
    _id: string,
    createdAt: string,
    updatedAt: string
}

export interface MaintenancePlanItemNoteAdd {
    maintenancePlan_id: string,
    text: string
}
export interface MaintenancePlanItemNoteEdit {
    maintenancePlan_id: string,
    text: string,
    note_id: string,
}

export class MaintenanceEvent {
    attachments: Array<any>
    code: string
    comment: string
    completionDate: Date
    cost: number
    creationDate: Date
    emWorkflow_id: string
    estimatedStartDate: Date
    hoursOnCompletion: number
    inventoryItem_id: string
    kmOnCompletion: number
    maintenancePlan_id: string
    responsible_id: string
    schedulePeriod: number
    scheduleType: string
    startDate: Date
    status: string
    tenantCode: string
    _id: string
}

export class MaintenancePlan{
    code: string
    creationDate: Date
    description: string
    emWorkflow_id: string
    inventoryItem_id: string
    notifyByEmail: boolean
    notifyBySms: boolean
    responsible_id: string
    scheduleNotifyPct: number
    schedulePeriod: number
    scheduleType: string
    status: string
    tenantCode: string
    title: string
    _id: string

}

export class MaintenancePlanUnscheduled{
    code: string
    estimatedStartDate: Date
    creationDate: Date
    description: string
    emWorkflow_id: string
    inventoryItem_id: string
    notifyByEmail: boolean
    notifyBySms: boolean
    responsible_id: string
    scheduleNotifyPct: number
    schedulePeriod: number
    scheduleType: string
    status: string
    tenantCode: string
    title: string
    _id: string
}