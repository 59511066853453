import React, { useEffect, useState } from 'react'
import { makeStyles, Box, Typography, IconButton, Tabs, Tab, Divider } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import NotificationsManager from '../../services/NotificationsManager'
import { useCommonStore } from '../../state/commonStore'
import NotificationAccordion from './NotificationAccordion'
import NotificationsCalendar from './NotificationsCalendar'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem 1rem',
  },
  column: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0.25rem 1rem',
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 'normal',
    minWidth: 80,
    maxWidth: 120,
    color: theme.palette.tertiary.main,
  },
  tabLabelSelected: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tabPanelTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '1rem 0 2rem',
    alignSelf: 'flex-start',
  },
}))

export type TabCode = 'tabNew' | 'tabRead' | 'tabCalendar'

export default function NotificationsList(props) {
  const notificationsManager = NotificationsManager()
  const profile = useCommonStore(state => state.profile)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const classes = useStyles()
  const { t } = useTranslation()
  const [activeTabCode, setActiveTabCode] = useState<TabCode>('tabNew')
  const [openPanel, setOpenPanel] = useState<object>({
    urgent: true,
    warning: false,
    update: false,
  }) //Severity: "1 - Urgent","2 - Warning", "3 - Update"
  const [filterNotificationType, setFilterNotificationType] = useState<string>('qualityControl')

  const handleExpandPannelClick = (val: string) => {
    setOpenPanel({ ...openPanel, [val]: !openPanel[val] })
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: TabCode) => {
    setActiveTabCode(newValue)
  }
  useEffect(() => {
    if (profile) props.actInventoryItems()
  }, [profile])
  return (
    <>
      <Box className={classes.row}>
        <Typography variant='h6' component='h1'>
          {t('mvb.notificationsCatalog')}
        </Typography>
        <IconButton aria-label='close' onClick={props.handleClose}>
          <CloseRounded fontSize='inherit' />
        </IconButton>
      </Box>
      <Tabs
        value={activeTabCode}
        onChange={handleTabChange}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        aria-label='notification tabs'
      >
        <Tab
          label={t('notificationsCatalog.tabNew')}
          value='tabNew'
          className={'tabNew' === activeTabCode ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}
        />
        <Tab
          label={t('notificationsCatalog.tabRead')}
          value='tabRead'
          className={'tabRead' === activeTabCode ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}
        />
        <Tab
          label={t('notificationsCatalog.tabCalendar')}
          value='tabCalendar'
          className={
            'tabCalendar' === activeTabCode ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel
          }
        />
      </Tabs>
      <Divider />
      {activeTabCode === 'tabNew' && (
        <>
          <Box className={classes.column}>
            <Typography align='left' className={classes.tabPanelTitle} component='h2'>
              {t('notificationsCatalog.urgencyLevel')}
            </Typography>

            <NotificationAccordion
              trimText={true}
              notifications={props.notifications.filter(n => n.messages && !n.messages[0].webReadDate)}
              tab={activeTabCode}
              openPanel={openPanel}
              handleExpandPannelClick={handleExpandPannelClick}
              handleOpenAlarmPopUp={props.handleOpenAlarmPopUp}
              accessGranted={accessGranted}
              doUpdateMultipleNotificationWebReadDate= {notificationsManager.doUpdateMultipleNotificationWebReadDate}
            />
          </Box>
        </>
      )}
      {activeTabCode === 'tabRead' && (
        <>
          <Box className={classes.column}>
            <Typography align='left' className={classes.tabPanelTitle} component='h2'>
              {t('notificationsCatalog.urgencyLevel')}
            </Typography>

            <NotificationAccordion
              trimText={false}
              notifications={props.notifications.filter(n => n.messages && n.messages[0].webReadDate)}
              tab={activeTabCode}
              openPanel={openPanel}
              handleExpandPannelClick={handleExpandPannelClick}
              handleOpenAlarmPopUp={props.handleOpenAlarmPopUp}
              accessGranted={accessGranted}
            />
          </Box>
        </>
      )}
      <>
        <Box className={'classes.column'}>
          <NotificationsCalendar
            //activeTabCode is used by NotificationCalendar internaly in order to render the component conditionaly
            activeTabCode={activeTabCode}
            notifications={props.notifications}
            handleOpenAlarmPopUp={props.handleOpenAlarmPopUp}
            filterNotificationType={filterNotificationType}
            setFilterNotificationType={setFilterNotificationType}
            inventoryItems={props.inventoryItems}
          />
        </Box>
      </>
    </>
  )
}
