import React from 'react'
import { Typography, Button, makeStyles, Tab, Tabs, Divider, Box, IconButton, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import SelectNt from '../reusableComponents/SelectNt'
import AutocompleteNt from '../reusableComponents/AutocompleteNt'
import TextAreaNt from '../reusableComponents/TextAreaNt'
import { useNetworkElementStore } from '../../state/networkElementStore'
import { Pump } from '../../utils/types/networkElementTypes'
import MenuItemNt from '../reusableComponents/MenuItemNt'

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0.25rem 1rem',
  },
  tabPanelTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '0.75rem 0 0.5rem 0',
    alignSelf: 'flex-start',
  },
}))

export default function Experimental() {
  const classes = useStyles()
  const { t } = useTranslation()

  const pumps = useNetworkElementStore(state => state.pumps)
  const [selectedPumps, setSelectedPumps] = React.useState<Pump[]>([])
  const [selectedPump, setSelectedPump] = React.useState<Pump | null>(null)

  const handlePumpsChange = (event, newVal) => {
    setSelectedPumps(newVal)
  }

  const getOptionLabel = (option: any) => option?.properties?.label
  const getOptionSelected = (o, v) => o?.properties?.code === v?.properties?.code
  const getOptionDisabled = o => !o?.properties?.code

  const handlePumpChange = (event, newVal) => {
    setSelectedPump(newVal)
  }

  /**SELECT */

  const [selectValue, setSelectValue] = React.useState('')

  const handleSelectChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    child: React.ReactNode
  ) => {
    //@ts-ignore
    setSelectValue(event.target.value)
  }
  const DATA = [
    {
      label: 'Ten',
      value: '10',
    },
    {
      label: 'Twenty',
      value: '20',
    },
    {
      label: 'thirty',
      value: '30',
    },
    {
      label: 'Fourty',
      value: '40',
    },
    {
      label: 'Fifty',
      value: '50',
    },
  ]
console.log(pumps)
  return (
    <Box className={classes.column}>
      <Typography align='left' className={classes.tabPanelTitle} component='h2'>
        {t('userSettings.coordinateSystem')}
      </Typography>
      <Box>
        <TextAreaNt />
        <Box style={{ paddingLeft: 8 }} className={classes.column}>
          <AutocompleteNt
            options={pumps || []}
            value={selectedPumps}
            onChange={handlePumpsChange}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            getOptionDisabled={getOptionDisabled}
            multiple
            label='net.pump.plural'
          />
        </Box>
        <Box style={{ paddingLeft: 8 }} className={classes.column}>

        </Box>
        <Box style={{ paddingLeft: 8 }} className={classes.column}>
          <AutocompleteNt
            options={pumps || []}
            value={selectedPumps}
            onChange={handlePumpsChange}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            getOptionDisabled={getOptionDisabled}
            multiple
            label='Required'
            required

          />
        </Box>
        <Box style={{ paddingLeft: 8 }} className={classes.column}>
          <AutocompleteNt
            options={pumps || []}
            value={selectedPump}
            onChange={handlePumpChange}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            getOptionDisabled={getOptionDisabled}
            required
          />
        </Box>

        <Box>
          <Box className={classes.column}>
            <SelectNt title='indicator.detectionLimit' value={selectValue} onChange={handleSelectChange}>
              {DATA.map((el, i) => (
                <MenuItemNt key={i} value={el.value}>
                  {t(el.label) as string}
                </MenuItemNt>
              ))}
            </SelectNt>
          </Box>
        </Box>
        <Box>
          <Box className={classes.column}>
            <SelectNt
              title=''
              required
              value={selectValue}
              options={DATA}
              onChange={handleSelectChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
