import React from 'react'

import SimpleErrorBoundaryContent from './SimpleErrorBoundaryContent'

class SimpleErrorBoundary extends React.Component<any, any> {

  constructor(props) {
    super(props)
    // console.log(`SimpleErrorBoundary constructor`)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // console.log(`SimpleErrorBoundary componentDidCatch`)
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  handleReturnClick = (e) => {
    this.setState({
      error: null,
      errorInfo: null
    })
  }

  render() {
    if (this.state.errorInfo) {
      console.log(`SimpleErrorBoundary render error`)
      return (
        <SimpleErrorBoundaryContent
          error={!!this.state.error ? this.state.error.toString() : 'Unknown Error'}
          errorInfo={!!this.state.errorInfo ? this.state.errorInfo.componentStack : ''}
          handleReturnClick={this.handleReturnClick}
          accessGranted={this.props.accessGranted}
        />
      )
    } else {
      // console.log(`SimpleErrorBoundary render component`)
      return this.props.children
    }
  }
}

export default SimpleErrorBoundary
