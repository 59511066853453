import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  msg: {
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  alert: {
    boxShadow: `0px 6px 7px 0px #00000040`,
    borderRadius: '8px',
  },
}))

interface ToasterProps {
  /**Determines if the `<Alert/>` is rendered or not */
  open: boolean
  /**
   * Callback fired when the component requests to be closed.
   * When provided and no `action` prop is set, a close icon button is displayed that triggers the callback when clicked.
   *
   * @param {object} event The event source of the callback.
   */
  onClose: () => void
  /** The text that will be displayed inside the `<Alert/>` */
  message: string
  /**
   * The severity of the alert. This defines the color and icon used.
   */
  severity: 'I' | 'W' | 'S' | 'E'
  /**Key for the `<Snackbar/>` it's needed when multiple toasts are opened one after another*/
  alertKey: number | string
}

/**Renders a `<Snackbar/>` with an `<Alert/>` 
 * 
 * ⚠️ The `<Toaster/>` is rendered inside the <Main/> component
 * To trigger the `<Snackbar/>` inside a component:
 * import the the global function `const showAlert = useCommonStore(state => state.showAlert)`
 * call the `showAlert('your message', 'severity')`
 */
export const Toaster = (props: ToasterProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let alertSeverity: any = 'info'
  let hideDuration: null | number = null
  switch (props.severity) {
    case 'E':
      alertSeverity = 'error'
      break
    case 'W':
      alertSeverity = 'warning'
      break
    case 'S':
      alertSeverity = 'success'
      hideDuration = 2000
      break
    case 'I':
      alertSeverity = 'info'
      hideDuration = 2000
      break
    default:
      alertSeverity = 'info'
      hideDuration = 2000
  }

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={hideDuration}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      key={props.alertKey}
      // TransitionComponent={(props) => <Slide {...props} direction="down" />}
    >
      <Alert
        className={classes.alert}
        variant='filled'
        severity={alertSeverity}
        onClose={props.onClose}
        classes={{ message: classes.msg }}
      >
        {t(props.message)}
      </Alert>
    </Snackbar>
  )
}

export default Toaster
