import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import TemplateService from './TemplateService'

const ProcedureService = () => {

  const apiClient = useCommonStore.getState().apiClient
  const profile = useCommonStore.getState().profile

  const templateService: any = TemplateService('procedure')

  const path = 'procedure/'

  const saveProcedureStep = (entity: any, step: any) => {
    let isNewSlot = !step._id
    if (isNewSlot) {
      step.addedBy = profile?.user_id
    } else {
      step.modifiedBy = profile?.user_id
    }
    let data: any = {
      procedure_id: entity._id,
      step: step,
    }
    if (isNewSlot) {
      data.operation = 'addProcedureStep'
    } else {
      data.operation = 'updateProcedureStep'
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('procedures', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }
  const deleteProcedureStep = (entity: any, step: any) => {
    let data: any = {
      operation: 'deleteProcedureStep',
      procedure_id: entity._id,
      step: step,
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('procedures', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }

  const sendRequest = (data: any) => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
    saveProcedureStep: saveProcedureStep,
    deleteProcedureStep: deleteProcedureStep,
  })

}

export default ProcedureService
