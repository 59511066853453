import React from 'react'
import { FormControl, FormHelperText, makeStyles, createStyles, Theme, Box, TextField } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import InfoNt from './InfoNt'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    rootInput: {
      [`& fieldset`]: {
        borderRadius: '8px',
      },
      '& input::placeholder': {
        fontStyle: 'italic',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.dark,
        },
      },
      '& textarea::placeholder': {
        fontStyle: 'italic !important',
      },
      '& .Mui-disabled': {
        '& fieldset': {
          borderColor: `${theme.palette.custom.grey3Disabled} !important`,
        },
      },
    },
    formHelperText: {
      ...theme.typography.h3,
      color: theme.palette.tertiary.dark,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
    helperTextContainer: {
      display: 'flex',
      alignItems: 'start',
    },
    textArea: {
      backgroundColor:theme.palette.common.white,
      // minWidth: 300,
      maxHeight: 600,
    },
    dynamicWidth: {
      //@ts-ignore
      width: ({ width }) => width,
    },
  })
)

interface TextAreaNtProps {
  /**The label that describes the textArea (renders a `<FormHelperText/>`)*/
  label?: string
  /** Will display an `info` icon next to the TextArea label */
  infoContent?: string
  /**Determines the width of the component,
   * if a number is passed the value is interpolated into pixels (eg 500 -> '500px'),
   * passing a string values like `100%`, `20rem`, `initial` etc can be passed to the styles
   * the value of the width will apply classes to `<TextArea/>` and the `<FormControl/>` that wraps it */
  width?: string | number
  /**  If `true`, the `input` element will be disabled.   */
  disabled?: boolean
  /** The short hint displayed in the input before the user enters a value.   */
  placeholder?: string
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: string
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Determines the minimun number of rows,
   * the number of minimun rows also determines the height of the component,
   * `minRows=1` will render a component with height 56px,
   * Each line adds 19px to the components minimum height (56px for one line, `minRows=2` will set the height to 75px)
   * defaults to `3` (94px) */
  minRows?: string | number
  /** MaxRows determines the number of maximum rows that the component will have
   * before displaying a vertical scroll bar (the component will stop stretching when maxRows is reached), defaults to `5`
   */
  maxRows?: string | number
}

/**
 * Renders a material-ui `<TextArea/>` Default dimensions `width:320px`, `height: 95px`
 * Width can be directly controled by passing the `width` param
 * height should be modified by the combination of `minRows` & `maxRows` props.
 */
export function TextAreaNt({
  disabled = false,
  label = '',
  infoContent = '',
  placeholder = '',
  width = 320,
  minRows = 3,
  maxRows = 5,
  ...props
}: TextAreaNtProps) {
  const classes = useStyles({ width: width })
  const { t } = useTranslation()

  return (
    <FormControl className={clsx(classes.margin, classes.dynamicWidth)} variant='outlined'>
      {label && (
        <Box className={classes.helperTextContainer}>
          <FormHelperText
            // id='outlined-weight-helper-text'
            className={classes.formHelperText}
          >
            {t(label)}
          </FormHelperText>
          {infoContent && <InfoNt content={t(infoContent)} />}
        </Box>
      )}
      <TextField
        // id='outlined-multiline-static'
        className={clsx(classes.dynamicWidth, classes.textArea)}
        multiline
        minRows={minRows}
        maxRows={maxRows}
        variant='outlined'
        disabled={disabled}
        placeholder={placeholder ? t(placeholder)! : ''}
        {...props}
        classes={{
          root: classes.rootInput,
        }}
      />
    </FormControl>
  )
}

export default TextAreaNt
