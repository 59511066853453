import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Divider,
  Select,
  MenuItem,
} from '@material-ui/core'
import {
  FirstPage,
  LastPage,
  NavigateNext,
  NavigateBefore,
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  paginationBtnContainer: {
    display: 'flex',
  },
  pageNumber: {
    marginRight: '1rem',
  },
}))

export default function PaginationCustom({
  data, //filteredAlarmNotificationsMSGS
  currentPage,
  handleNextClick,
  handlePreviousClick,
  handleFirstClick,
  handleLastClick,
  pageSize,
  setPageSize,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const totalDataItems = data ? data.length : null
  return (
    <Box className={classes.row}>
      <Divider />
      <Box className={classes.paginationContainer}>
        {!!totalDataItems && (
          <Typography className={classes.pageNumber}>{`${
            currentPage === 0 ? currentPage + 1 : currentPage * pageSize + 1
          } - ${
            currentPage === 0
              ? currentPage +
                data?.slice(currentPage * pageSize, currentPage * pageSize + pageSize).length
              : currentPage * pageSize +
                data?.slice(currentPage * pageSize, currentPage * pageSize + pageSize).length
          } ${
            data ? t('alarms.calendar.pagination.of') + ' ' + data.length : null
          }`}</Typography>
        )}
        <Box className={classes.paginationBtnContainer}>
          <Box display="flex" alignItems="center" mr="1rem">
          <Typography className={classes.pageNumber}>{t('pagination.rows.per.page.short')}</Typography>
            <Select value={pageSize} onChange={(e)=>setPageSize(e.target.value)} >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Box>
          <IconButton
            disabled={!totalDataItems}
            onClick={handleFirstClick}
            size='small'>
            <FirstPage />
          </IconButton>
          <IconButton
            disabled={!totalDataItems}
            onClick={handlePreviousClick}
            size='small'>
            <NavigateBefore />
          </IconButton>
          <IconButton
            disabled={!totalDataItems}
            onClick={handleNextClick}
            size='small'>
            <NavigateNext />
          </IconButton>
          <IconButton
            disabled={!totalDataItems}
            onClick={handleLastClick}
            size='small'>
            <LastPage />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
