import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
} from '@material-ui/core'
import { Print, GetApp as Download } from '@material-ui/icons'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from 'recharts'
import WrmIcon from '../WrmIcon'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FileSaver from 'file-saver'
import domtoimage from 'dom-to-image'

const COLORS = [
  '#42a5f5',
  '#5c6bc0',
  '#26c6da',
  '#66bb6a',
  '#9ccc65',
  '#76ff03',
  '#ef5350',
  '#ff5722',
  '#f06292',
  '#3d5afe',
  '#00e5ff',
  '#ffeb3b',
  '#26a69a',
  '#69f0ae',
  '#795548',
  '#e040fb',
  '#ffab40',
  '#c6ff00',
  '#53B4B4',
  '#3f51b5',
  '#f44336',
  '#ffc107',
  '#009688',
  '#e91e63',
  '#4caf50',
  '#ff9800',
  '#03a9f4',
  '#8bc34a',
  '#9c27b0',
  '#3d5722',
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill='black'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
  },
  titleTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  graphBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  // graphIcon: {
  //   width: theme.spacing(2),
  //   height: theme.spacing(2),
  // },
  graphIconActive: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  spLabelsSort: {
    maxWidth: '650px',
    marginTop: '1rem',
  },
  spLabelsLong: {
    maxWidth: '1250px',
    marginTop: '1rem',
  },
  dialogActions: {
    flexFlow: 'column',
  },
  arrowBtn: {
    textTransform: 'none',
    fontSize: '1.375rem',
    lineHeight: 1.375,
    fontWeight: 400,
    minHeight: theme.spacing(4),
    minWidth: theme.spacing(4),
    padding: 0,
    marginRight: 4,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  blueBtn: {
    textTransform: 'none',
    minWidth: 100,
    width: theme.spacing(9),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    margin: '0.5rem  0.5rem  0.5rem auto !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

export default function NotificationsCharts({
  filteredAlarmNotificationsMSGS,
  samplingPoints,
  startDate,
  endDate,
  filteredSamplingPoints,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [chartDataLine, setChartDataLine] = useState(null)
  const [chartDataPie, setChartDataPie] = useState<any>(null)
  const [activeChart, setActiveChart] = useState<'line' | 'pie'>('line')

  const chartRef = useRef(null)

  const handleClickDialogOpen = () => {
    setOpen(true)
  }

  const handleClickDialogClose = () => {
    setOpen(false)
  }

  const handleToggleCharts = () => {
    if (activeChart === 'line') {
      setActiveChart('pie')
    } else {
      setActiveChart('line')
    }
  }

  const handleSaveGraphClick = event => {
    let chartWrapper: any = chartRef.current
    if (!!chartWrapper) {
      domtoimage.toBlob(chartWrapper).then(blobres => {
        FileSaver.saveAs(blobres, `chart${Date.now()}.png`)
      })
    }
  }

  const handlePrintGraphReport = event => {
    let printoutNode: any = chartRef.current
    var WinPrint: any = window.open(
      '',
      '',
      'left=0,top=0,width=950,height=800,toolbar=0,scrollbars=0,status=0'
    )
    WinPrint.document.write(printoutNode.innerHTML)
    WinPrint.document.close()
    WinPrint.document.title = `report${Date.now()}.png`
    var buttons = WinPrint.document.getElementsByTagName('button')
    for (let i = buttons.length - 1; i >= 0; i--) {
      buttons[i].remove()
    }
    let titleContainer =  WinPrint.document.getElementById('title-container')
    titleContainer.innerHTML = ""

    var printGraphTitle = document.createElement("h5");
    var text = document.createTextNode(`${t('alarms.charts.startDate')} ${getStartDate()} 
    ${t('alarms.charts.untill')} ${getEndDate()}
    ${t('alarms.charts.totalAlarms')} ${filteredAlarmNotificationsMSGS.length}`);
    printGraphTitle.appendChild(text);
    titleContainer.appendChild(printGraphTitle)

    WinPrint.focus()
    WinPrint.print()
    WinPrint.close()
  }

  useEffect(() => {
    if (filteredAlarmNotificationsMSGS && samplingPoints) {
      let start: any
      let end: any
      if (startDate && endDate) {
        start = moment(startDate)
        end = moment(endDate)
      } else if (startDate && !endDate) {
        start = moment(startDate)
        end = moment()
        if (moment.duration(end.diff(start)).asDays() > 365) {
          end = moment().set('year', moment(start).get('year') + 1)
        }
      } else if (!startDate && endDate) {
        end = moment(endDate)
        start = moment(end).subtract(365, 'day')
      } else {
        end = moment()
        start = moment().set('year', moment().get('year') - 1)
      }

      let dataTEMP: any = []

      if (start && end) {
        //@ts-ignore
        var duration = moment.duration(start.diff(end))
        var daysDif = duration.asDays()
        const days = Math.ceil(Math.abs(daysDif))

        /** GET LABELS */
        if (days <= 14) {
          let i = moment(start)
          while (i < end) {
            dataTEMP.push({
              name: i.format('dd D-MMM'),
              upperLimit: moment(i).endOf('day'),
            })
            i.set('day', i.get('day') + 1)
            i.startOf('day')
          }
        } else if (days <= 98) {
          let i = moment(start)
          while (i < end) {
            dataTEMP.push({
              name: i.format('dd D-MMM'),
              upperLimit: moment(i).endOf('day'),
            })
            i.set('day', i.get('day') + 7)
            i.startOf('day')
            //@ts-ignore
            if (i >= end) {
              dataTEMP.push({
                //@ts-ignore
                name: end.format('dd D-MMM'),
                //@ts-ignore
                upperLimit: moment(end).endOf('day'),
              })
            }
          }
        } else {
          let i = moment(start)
          //both i and end are moment date objects, comparisson runs on dates
          while (i < end) {
            dataTEMP.push({
              name: i.format('MMM YY'),
              upperLimit: moment(i).endOf('month'),
            })
            i.set('month', i.get('month') + 1)
            i.startOf('month')
          }
        }

        const pieData: any = []
        filteredAlarmNotificationsMSGS.forEach(el => {
          //generate  PieChartData
          if (!pieData.some(item => item?.name === el.featureLabel)) {
            pieData.push({ name: el.featureLabel, alarms: 1 })
          } else {
            const index = pieData.findIndex(
              item => item.name === el.featureLabel
            )
            pieData[index] = {
              ...pieData[index],
              alarms: pieData[index].alarms + 1,
            }
          }
          //generate LineChartData
          for (let i = 0; i < dataTEMP.length; i++) {
            if (moment(el.sentDate) < dataTEMP[i].upperLimit) {
              dataTEMP[i].alarms = dataTEMP[i].alarms ? ++dataTEMP[i].alarms : 1
              break
            }
          }
        })

        const finalData = dataTEMP.map(el => {
          return { name: el.name, alarms: el.alarms ? el.alarms : 0 }
        })

        setChartDataPie(pieData)
        setChartDataLine(finalData)
        //@ts-ignore
        // window.moment = moment
      }
    }
  }, [filteredAlarmNotificationsMSGS, samplingPoints])

  const generateAlarmsSamplingPointsLabels = () => {
    // const labels = filteredAlarmNotificationsMSGS?.map(el => {
    //   //@ts-ignore
    //   return el?.featureLabel
    // })
    // const labelsSet = new Set(labels)
    // return Array.from(labelsSet).join(', ')
    const result = filteredSamplingPoints.map(
      spCode =>
        samplingPoints.find(sp => sp.properties.code === spCode).properties
          .label
    )
    return result.join(', ')
  }
  const getStartDate = () => {
    if (startDate) {
      return moment(startDate).format('DD-MMMM-YYYY')
    } else if (endDate) {
      const aYearBeforeEndDate = moment(
        moment(endDate).set('year', endDate.get('year') - 1)
      )
      return aYearBeforeEndDate.format('DD-MMMM-YYYY')
    } else {
      const aYearBeforeToday = moment().set('year', moment().get('year') - 1)
      return aYearBeforeToday.format('DD-MMMM-YYYY')
    }
  }
  const getEndDate = () => {
    if (endDate) {
      return moment(endDate).format('DD-MMMM-YYYY')
    } else if (startDate) {
      let aYearAfterStartDate = moment(
        moment(startDate).set('year', startDate.get('year') + 1)
      )
      if (aYearAfterStartDate > moment()) aYearAfterStartDate = moment()
      return aYearAfterStartDate.format('DD-MMMM-YYYY')
    } else {
      const today = moment()
      return today.format('DD-MMMM-YYYY')
    }
  }

  return (
    <div>
      <Button
        className={classes.graphBtn}
        variant='contained'
        color='primary'
        startIcon={
          <WrmIcon
            icon='graph2'
            style={{ width: 24, height: 24 }}
            className={classes.graphIconActive}
          />
        }
        onClick={handleClickDialogOpen}
        disabled={
          !chartDataLine ||
          !filteredAlarmNotificationsMSGS ||
          filteredAlarmNotificationsMSGS?.length === 0
        }>
        {t('btn.chart')}
      </Button>
      {open && filteredAlarmNotificationsMSGS && (
        //if the filters are active the dialog will be rendered if not render null
        <Dialog
          maxWidth='lg'
          open={open}
          onClose={handleClickDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogContent>
            <div ref={chartRef}>
              <Box className={classes.titleContainer} id="title-container">
                <Box className={classes.titleTop}>
                  <Typography>{`${t(
                    'alarms.charts.startDate'
                  )} ${getStartDate()}`}</Typography>
                  <Typography >{`${t(
                    'alarms.charts.totalAlarms'
                  )}${filteredAlarmNotificationsMSGS.length}`}</Typography>
                </Box>
                <Box className={classes.titleBottom}>
                  <Typography>{`${t(
                    'alarms.charts.endDate'
                  )} ${getEndDate()}`}</Typography>
                  <Box>
                    <Button
                      className={classes.arrowBtn}
                      onClick={handleToggleCharts}>
                      &lt;
                    </Button>
                    <Button
                      className={classes.arrowBtn}
                      onClick={handleToggleCharts}>
                      &gt;
                    </Button>
                    <IconButton
                      aria-label='save'
                      onClick={handleSaveGraphClick}>
                      <Download />
                    </IconButton>
                    <IconButton
                      aria-label='print'
                      onClick={handlePrintGraphReport}>
                      <Print />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              {activeChart === 'line' && chartDataLine && (
                <div
                  style={{
                    background: '#fff',
                    //@ts-ignore
                    width: chartDataLine?.length > 15 ? '1250px' : '650px',
                    overflowY: 'scroll',
                  }}>
                  <LineChart
                    width={
                      chartDataLine
                        ? //@ts-ignore
                          chartDataLine?.length > 15
                          ? 1200
                          : 600
                        : 600
                    }
                    height={300}
                    data={chartDataLine}
                    margin={{
                      top: 5,
                      right: 50,
                      left: 20,
                      bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                      dataKey='name'
                      interval={0}
                      angle={45}
                      dx={15}
                      dy={20}
                      height={60}
                    />
                    <YAxis />
                    <Tooltip />
                    <Line
                      dataKey='alarms'
                      stroke='#ff0000'
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                      dot={false}
                    />
                  </LineChart>
                  <Typography
                    className={
                      chartDataLine
                        ? //@ts-ignore
                          chartDataLine?.length > 15
                          ? classes.spLabelsLong
                          : classes.spLabelsSort
                        : classes.spLabelsSort
                    }>
                    {`${t('alarms.charts.samplingPoints')} 
                ${generateAlarmsSamplingPointsLabels()}
                `}
                  </Typography>
                </div>
              )}
              {activeChart === 'pie' &&
                chartDataPie &&
                chartDataPie?.length > 0 && (
                  //padding bottom is added to the #chartContainer to fix domtoimage bug(otherwise legend appears cropped)
                  <div style={{ paddingBottom: '5rem', background: '#fff' }}>
                    <PieChart width={500} height={500}>
                      <Legend />
                      <Tooltip />
                      <Pie
                        data={chartDataPie}
                        cx={250}
                        cy={200}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={200}
                        fill='#8884d8'
                        dataKey='alarms'>
                        {chartDataPie &&
                          //@ts-ignore
                          chartDataPie.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                      </Pie>
                    </PieChart>
                  </div>
                )}
              {activeChart === 'pie' && chartDataPie?.length === 0 && (
                <Typography variant='h5'>
                  {t('msr.graph.alert.nodata')}
                </Typography>
              )}
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Divider style={{ width: '100%' }} />
            <Button
              className={classes.blueBtn}
              onClick={handleClickDialogClose}
              autoFocus>
              {t('btn.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
