import i18n from 'i18next'

import { useCommonStore } from '../state/commonStore'
import { useCollectionStore } from '../state/collectionStore'
import { buildErrorMessage } from '../utils/helpers'
import { NotificationTypes } from '../utils/constants'
import MeasurementSeriesService from './MeasurementSeriesService'
import NotificationMsgService from '../services/NotificationMsgService'
import NotificationService from '../services/NotificationService'
import { Notification, NotificationMsg } from '../utils/types/collectionStoreTypes'

interface NotificationsManagerType {
  /**Loads the notifications for the individual consumer (a logged in consumer) */
  loadConsumerNotifications: (consumer_id: string, options?: any) => any
  /**Loads the notifications for the individual operator (a logged in operator) */
  loadPersonnelNotifications: (personnel_id: string, options?: any, filter?: any) => any
  /**Loads consumer notifications for the operator (eg < ConsumerPortal > -> NetworkNotifications tab).
   *Currently this is fired when ConsumerPortal is opened by the operator  */
  loadConsumerNotificationsForOperator: () => any
  actSaveNotification: (notification: Notification) => any
  /**Used to load the NotificationMSG inside the < ConsumerPortal > -> NetworkNotifications tab -> < NotificationDetails >
   *component when a notification is selected by an operator */
  loadNotificationMsg: (filter?) => any
  /**Typical use cases are to update the:
   * webReadDate (when a user opens a notification)
   * webDoHide (when a user deletes a notification) */
  updateNotificationMsg: (notificationMsg: NotificationMsg) => any
  updatePersonnelNotificationsMsg: (notificationMsg: NotificationMsg) => any
  /**Currently used only in < Irrigation > -> < IrriControlRoom > ->  < IrrigationNotificationsContainer > */
  actDeletePersonnelNotificationMsg: (notificationMsg: NotificationMsg) => any
  /**Updates the webReadDate for PersonnelNotificationMSGs */
  doUpdateNotificationWebReadDate: (notification: Notification) => void
  /**Marks multiple notifications as read (adds webReadDate to the notificationMSG)
   * currently used in the Notification Bell at Vertical Navigation
   * < NotificationsList > -> < NotificationAccordion >*/
  doUpdateMultipleNotificationWebReadDate: (notifications: Notification[], webReadDate: string | Date) => any
  /**Loads notifications for ALL USERS (used in Bell Icon in vertical navigation -> Notification Log )
   * since it doesn't need the notificationmsg, only the notifications are fetched
   * ⚠️ sets the `filteredNotifications` at CollectionStore  */
  loadAllUsersFilteredNotifications: (filter?: any, options?: any) => any
  /** Fetches notifications(and notification messages) for a given personnel by combining filters for the Notification & NotificationMSGs collections
   * ⚠️ sets the `filteredNotifications` at CollectionStore.
   * In order to fetch the (filtered) personnel Notifications a double query needs to be executed on both the Notifications and NotificationsMSG collections
   * @param filter - an object that will have the mongoose filter for querying the Notification collection
   * @param options - an object with the options
   * @param `options.personnel_id` - the id of the personnel, it will be used at the NotificationsMSG query
   * @param `options.limit` - limits (how many documents are fetched, -1 equals to infinite) if undefined defaults to 500 at the backend
   */
  getFilteredNotificationMsgsByPersonnel: (personnel_id: string, options?: any, filter?: any) => Promise<any>
}

/**A collection of helper methods dealing with Notifications */
const NotificationsManager = () => {
  const t = i18n.t
  const apiClient = useCommonStore.getState().apiClient
  const showAlert = useCommonStore.getState().showAlert
  const profile = useCommonStore.getState().profile
  const measurementSeriesService = MeasurementSeriesService(apiClient)
  const setCollectionItems = useCollectionStore.getState().setItems

  const notificationMsgService = NotificationMsgService(apiClient)
  const notificationService = NotificationService()
  
  /**
   * The state varibles below ARE NOT REFRESHED because NotificationsManager is NOT a react component.
   * We need to call useCollectionStore.getState().<state variabler> whenever we want its current value.
   * const consumers = useCollectionStore.getState().consumers 
   * const consumerNotifications = useCollectionStore.getState().consumerNotifications
   * const personnelNotifications = useCollectionStore.getState().personnelNotifications
   */
  const setFilteredNotifications = _notifications => {
    setCollectionItems('filteredNotifications', _notifications)
  }
  const setConsumerNotifications = _notifications => {
    setCollectionItems('consumerNotifications', _notifications)
  }
  const setPersonnelNotifications = _notifications => {
    setCollectionItems('personnelNotifications', _notifications)
    // useCollectionStore.getState().setItems('personnelNotifications', _notifications)
  }
  const loadConsumerNotifications = (consumer_id, options?: any) => {
    let consumerNotifications = useCollectionStore.getState().consumerNotifications
    if (consumerNotifications.length > 0) {
      if (
        options?.action === 'add' &&
        consumerNotifications.findIndex(n => n._id == options.item.notification._id) === -1 // item.notification is an object
      ) {
        let notification = { ...options.item.notification }
        options.item.notification = notification._id
        notification.messages = [{ ...options.item }] //this is a runtime property, it is not sored in the db
        let updatedNotifications = [...consumerNotifications]
        updatedNotifications.push(notification)
        setConsumerNotifications(updatedNotifications)
        return Promise.resolve({ data: updatedNotifications })
      } else if (
        options?.action === 'update' &&
        consumerNotifications.findIndex(n => n._id == options.item.notification) > -1 // item.notification is an _id
      ) {
        let updatedNotifications = consumerNotifications.map(n => n._id.toString() === options.item.notification.toString() ? options.item : n)
        setConsumerNotifications(updatedNotifications)
        return Promise.resolve({ data: updatedNotifications })
      } else {
        return Promise.resolve({ data: consumerNotifications })
      }
    } else {
      let filter = { webDoHide: { $in: [null, false] } }
      return notificationMsgService.getConsumerNotificationMsgs(consumer_id, filter).then(
        res => {
          if (!res.data.error) {
            console.log(`loadConsumerNotifications cnt ${res.data.length}`)
            let messages = res.data
            let notifications = messages.map(m => {
              let notification = { ...m.notification }
              notification.messages = [
                {
                  ...m,
                  notification: m.notification._id,
                },
              ] //this is a runtime property, it is not sored in the db
              return notification
            })
            setConsumerNotifications(notifications)
            return res
          } else {
            console.log(`ERROR loadConsumerNotifications: ${JSON.stringify(res.data, null, 2)}`)
            return { data: [] }
          }
        },
        err => {
          console.log(`ERROR loadConsumerNotifications: ${JSON.stringify(err, null, 2)}`)
          return { data: [] }
        }
      )
    }
  }
  /**Loads personnelNotifications messeges by calling internally the notificationMsgService.getPersonnelNotificationMsgs method
   * by default fetches the last 500 notifications, cannot apply complex filters related to Notification collection (severity and other stuff cannot be passed here)
   * since it queries only the Notificationmsgs collection and populates the `notification` property.
   * ⚠️ sets the `personnelNotifications` at CollectionStore.
   * @param {string} personnel_id  - the _id of the given personnel whos notifications we want to fetch
   * @param {object} options - an object with the options
   * @param {string} options.action  - if 'add'/'update', will append/update the options.item notification message
   * @param {NotificationMsg} `options.item` - will append (or update) the given notification message to the collection instead of reloading the state(used in WebSocketContainer)
   * @param {boolean} `options.doReload`  - if true will refresh the collection by making a new call to the server
   * @param {boolean} `options.withoutSeries` - if true will not try to set the measurementSeries of the notifications
   */
  const loadPersonnelNotifications = (personnel_id: string, options?: any) => {
    let personnelNotifications = useCollectionStore.getState().personnelNotifications
    if (personnelNotifications.length > 0 && !options?.doReload) {
      if (
        options?.action === 'add' &&
        personnelNotifications.findIndex(n => n._id == options.item.notification._id) === -1 // item.notification is an object
      ) {
        let notification = { ...options.item.notification }
        options.item.notification = notification._id
        notification.messages = [{ ...options.item }] //this is a runtime property, it is not sored in the db
        if (options?.withoutSeries) {
          let updatedPersonnelNotifications = [...personnelNotifications]
          updatedPersonnelNotifications.push(notification)
          setPersonnelNotifications(updatedPersonnelNotifications)
          return Promise.resolve({ data: updatedPersonnelNotifications })
        } else {
          //add the measurement serie to the notification (ms runtime-only property)
          //without the .ms prop notifications will not open/pan to feature on map if clicked from the notification bell icon
          const notificationId = [notification.measurementSerie]
          measurementSeriesService
            .getMeasurementSeriesByIds(notificationId)
            .then(res => {
              let series = res.data
              //ms is a runtime property
              notification.ms = series?.find(s => s._id === notification?.measurementSerie)
              let updatedPersonnelNotifications = [...personnelNotifications]
              updatedPersonnelNotifications.push(notification)
              setPersonnelNotifications(updatedPersonnelNotifications)
              return Promise.resolve({ data: updatedPersonnelNotifications })
            })
            .catch(res => {
              console.log(`ERROR getMeasurementSeriesByIds: ${JSON.stringify(res.data, null, 2)}`)
              console.log(`fallback: setting Notifications without MeasurementSeries`)
              setPersonnelNotifications(personnelNotifications)
            })
        }
      } else if (
        options?.action === 'update' &&
        personnelNotifications.findIndex(n => n._id == options.item.notification) > -1 // item.notification is an _id
      ) {
        let updatedPersonnelNotifications = personnelNotifications.map(n => n._id.toString() === options.item.notification.toString() ? options.item : n)
        setPersonnelNotifications(updatedPersonnelNotifications)
        return Promise.resolve({ data: updatedPersonnelNotifications })
      } else {
        return Promise.resolve({ data: personnelNotifications })
      }
    } else {
      let filter = { webDoHide: { $in: [null, false] } }
      if (!options) {
        // set a default limit for personnel notifications
        options = { ...{ limit: 500 }, ...(options || {}) }
      }
      return notificationMsgService.getPersonnelNotificationMsgs(personnel_id, filter, options).then(
        res => {
          if (!res.data.error) {
            console.log(`loadPersonnelNotifications cnt ${res.data.length}`)
            let messages = res.data
            let notifications = messages.map(m => {
              let notification = { ...m.notification }
              notification.messages = [
                {
                  ...m,
                  notification: m.notification._id,
                },
              ] //this is a runtime property, it is not sored in the db
              return notification
            })
            if (options?.withoutSeries) {
              setPersonnelNotifications(notifications)
            } else {
              const seriesIdsSet = new Set()
              notifications.forEach(n => seriesIdsSet.add(n.measurementSerie))
              const seriesIds: any[] = Array.from(seriesIdsSet)
              return measurementSeriesService
                .getMeasurementSeriesByIds(seriesIds)
                .then(res => {
                  let series = res.data
                  //ms is also a runtime property it is needed for the notifications to be able to zoom/pan on the map when clicked from the Bell Icon/Notifications Calendar
                  const updatedNotifications = notifications.map(n => {
                    const ms = series.find(s => s._id === n.measurementSerie)
                    return { ...n, ms: ms }
                  })
                  setPersonnelNotifications(updatedNotifications)
                  return { data: updatedNotifications }
                })
                .catch(res => {
                  console.log(`ERROR getMeasurementSeriesByIds: ${JSON.stringify(res.data, null, 2)}`)
                  console.log(`fallback: setting Notifications without MeasurementSeries`)
                  setPersonnelNotifications(notifications)
                })
            }
            return notifications
          } else {
            console.log(`ERROR loadPersonnelNotifications: ${JSON.stringify(res.data, null, 2)}`)
            return { data: [] }
          }
        },
        err => {
          console.log(`ERROR loadPersonnelNotifications: ${JSON.stringify(err, null, 2)}`)
          return { data: [] }
        }
      )
    }
  }

  /** Fetches personnel notifications by combining filters for the Notification & NotificationMSGs collections.
   * ⚠️ sets the `filteredNotifications` at CollectionStore.
   * In order to fetch the (filtered)personnel Notifications a double query needs to be executed on both the Notifications and NotificationsMSG collections,
   * @param filter an object that will have the mongoose filter options for querying the Notification collection
   * @param options an object that will have the options.limit(how many documents to fetch, -1 equals to infinite) and the options.personnel_id
   */
  const getFilteredNotificationMsgsByPersonnel = async (filter?: any, options?: any) => {
      if (!filter) {
        filter = { webDoHide: { $in: [null, false] } }
      }
      if (!options) {
        // set a default limit for personnel notifications
        options = { ...{ limit: 500 }, ...(options || {}) }
      }
      return notificationMsgService.getFilteredNotificationMsgsByPersonnel(filter, options).then(
        res => {
          if (!res.data.error) {
            let messages = res.data
            let notifications = messages.map(m => {
              let notification = { ...m.notification }
              notification.messages = [
                {
                  ...m,
                  notification: m.notification._id,
                },
              ] //this is a runtime property, it is not sored in the db
              return notification
            })
            if (options?.withoutSeries) {
              setPersonnelNotifications(notifications)
            } else {
              const seriesIdsSet = new Set()
              notifications.forEach(n => seriesIdsSet.add(n.measurementSerie))
              const seriesIds: any[] = Array.from(seriesIdsSet)
              return measurementSeriesService
                .getMeasurementSeriesByIds(seriesIds)
                .then(res => {
                  let series = res.data
                  //ms is also a runtime property it is needed for the notifications to be able to zoom/pan on the map when clicked from the Bell Icon/Notifications Calendar
                  const updatedNotifications = notifications.map(n => {
                    const ms = series.find(s => s._id === n.measurementSerie)
                    return { ...n, ms: ms }
                  })
                    setFilteredNotifications(updatedNotifications)
                  return { data: updatedNotifications }
                })
                .catch(res => {
                  console.log(`ERROR getMeasurementSeriesByIds: ${JSON.stringify(res.data, null, 2)}`)
                  console.log(`fallback: setting Notifications without MeasurementSeries`)
                  setFilteredNotifications(notifications)
                  return { data: notifications }
                })
            }
          } else {
            console.log(`ERROR loadPersonnelNotifications: ${JSON.stringify(res.data, null, 2)}`)
            return []
          }
        },
        err => {
          console.log(`ERROR loadPersonnelNotifications: ${JSON.stringify(err, null, 2)}`)
          return []
        }
      )
  }

  const loadConsumerNotificationsForOperator = () => {
    let consumerNotifications = useCollectionStore.getState().consumerNotifications
    if (consumerNotifications.length > 0) {
      return Promise.resolve({ data: consumerNotifications })
    } else {
      let consumerNotificationTypes = Object.values(NotificationTypes)
        .filter(nt => !nt.isPersonnelType)
        .map(nt => nt.code)
      let filter = { notificationType: { $in: consumerNotificationTypes } }
      return notificationService.getNotifications(filter).then(
        res => {
          if (!res.data.error) {
            console.log(`loadNotifications cnt ${res.data.length}`)
            setConsumerNotifications(res.data)
            return res
          } else {
            console.log(`ERROR loadNotifications: ${JSON.stringify(res.data, null, 2)}`)
            return { data: [] }
          }
        },
        err => {
          console.log(`ERROR loadNotifications: ${JSON.stringify(err, null, 2)}`)
          return { data: [] }
        }
      )
    }
  }

  const actSaveNotification = (notification:Notification) => {
    let consumerNotifications = useCollectionStore.getState().consumerNotifications
    let consumers = useCollectionStore.getState().consumers
    let isNewNotification = !notification._id
    let messages
    if (isNewNotification) {
      notification.sender_user_id = profile?.user_id
      notification.tenantCode = profile?.selectedTenant!
      //@ts-ignore
      notification.sentDate = Date.now()
      //@ts-ignore
      messages = [...notification?.messages]
    } else {
      // notification.modifiedBy = profile.user_id
    }
    return notificationService.saveNotification(notification).then(
      res => {
        notification = res.data
        let updatedNotifications
        if (isNewNotification) {
          updatedNotifications = !!consumerNotifications ? [...consumerNotifications] : []
          updatedNotifications.push(notification)

          /* Create notification messages
           * Notice that we do not wait for the promises array to complete
           * (i.e. we do not return Promise.all)
           * We shall eventually update the notifications collection
           * when all the messages have been created (and sent via appropriate channels).*/
          let promises: any[] = []
          let createdmessages: any[] = []
          messages.forEach(m => {
            let message: any = { ...m }
            message.tenantCode = profile?.selectedTenant
            message.notification = notification._id
            let consumer: any
            if (consumers) {
              consumer = consumers.find(c => c._id === m.consumer)
            } else if (profile?.consumer && profile?.consumer._id === m.consumer) {
              consumer = profile?.consumer
            }
            let consumerLean = (({
              _id,
              email,
              mobile,
              firstname,
              lastname,
              smsNotifications,
              emailNotifications,
              users_user_id,
            }) => ({ _id, email, mobile, firstname, lastname, smsNotifications, emailNotifications, users_user_id }))(
              consumer
            )
            promises.push(
              notificationMsgService.saveNotificationMsg(message, notification, consumerLean).then(res => {
                createdmessages.push(res.data)
              })
            )
          })
          Promise.all(promises).then(res => {
            notification.messages = createdmessages
            let updatedNotifications2 = updatedNotifications.map(nt =>
              nt._id === notification._id ? notification : nt
            )
            setConsumerNotifications(updatedNotifications2)
          })
        } else {
          updatedNotifications = consumerNotifications
            ? consumerNotifications.map(nt => (nt._id === notification._id ? notification : nt))
            : []
        }
        setConsumerNotifications(updatedNotifications)
        return notification
      },
      err => {
        let errorMessage = buildErrorMessage(err, null, 'notifications')
        showAlert(t(errorMessage), 'E')
        console.error(`errorMessage ${errorMessage}`)
        return Promise.reject(errorMessage)
      }
    )
  }

  /* Notification Messages */

  const loadNotificationMsg = (filter?) => {
    let consumerNotifications = useCollectionStore.getState().consumerNotifications
    console.log(`loadNotificationMsg filter ${JSON.stringify(filter, null, 2)}`)
    return notificationMsgService.getNotificationMsgs(filter).then(
      res => {
        if (!res.data.error) {
          console.log(`loadNotificationMsg cnt ${res.data.length}`)
          if (res.data.length > 0) {
            let notificationMsg = res.data[0]
            const updatedNotifications = consumerNotifications.map(n => {
              if (n._id === notificationMsg.notification) {
                return { ...n, messages: res.data }
              } else {
                return n
              }
            })
            setConsumerNotifications(updatedNotifications)
          }
          return res
        } else {
          console.log(`ERROR loadNotificationMsg: ${JSON.stringify(res.data, null, 2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR loadNotificationMsg: ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  /**LEGACY Method, currently this method has no use,
   * instead of deleting the notificationMSG we use updateNotificationMsg to update it's webDoHide property */
  const actDeleteNotificationMsg = notificationMsg => {
    let consumerNotifications = useCollectionStore.getState().consumerNotifications
    return notificationMsgService.deleteNotificationMsg(notificationMsg).then(
      res => {
        console.log(res)
        if (!res.data.error) {
          console.log(`deleteNotificationMsg cnt ${res.data.length}`)
          console.log(res)
          const updatedNotifications = consumerNotifications.filter(n => n.messages[0]._id !== res.data._id)
          setConsumerNotifications(updatedNotifications)
        } else {
          console.log(`ERROR deleteNotificationMsg: ${JSON.stringify(res.data, null, 2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR deleteNotificationMsg: ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  /**Typical use cases are to update the:
   * webReadDate (when a user opens a notification)
   * webDoHide (when a user deletes a notification) */
  const updateNotificationMsg = (notificationMsg:NotificationMsg) => {
    let consumerNotifications = useCollectionStore.getState().consumerNotifications
    const updatedNotifications = notificationMsg.webDoHide
      ? consumerNotifications.filter(n => n._id !== notificationMsg.notification)
      : consumerNotifications.map(n => {
          if (n._id === notificationMsg.notification) {
            return { ...n, messages: n.messages.map(m => (m._id === notificationMsg._id ? notificationMsg : m)) }
          } else {
            return n
          }
        })
    setConsumerNotifications(updatedNotifications)
    return notificationMsgService.updateNotificationMsg(notificationMsg).then(
      res => {
        console.log(res)
        if (!res.data.error) {
          console.log(`updateNotificationMsg cnt ${res.data.length}`)
          console.log(res)
          return res
        } else {
          console.log(`ERROR updateNotificationMsg: ${JSON.stringify(res.data, null, 2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR updateNotificationMsg: ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  const updatePersonnelNotificationsMsg = (notificationMsg:NotificationMsg) => {
    let personnelNotifications = useCollectionStore.getState().personnelNotifications
    const updatedNotifications = notificationMsg.webDoHide
      ? personnelNotifications.filter(n => n._id !== notificationMsg.notification)
      : personnelNotifications.map(n => {
          if (n._id === notificationMsg.notification) {
            return { ...n, messages: n?.messages?.map(m => (m._id === notificationMsg._id ? notificationMsg : m)) }
          } else {
            return n
          }
        })
    setPersonnelNotifications(updatedNotifications)
    return notificationMsgService.updateNotificationMsg(notificationMsg).then(
      res => {
        console.log(res)
        if (!res.data.error) {
          console.log(`updateNotificationMsg cnt ${res.data.length}`)
          console.log(res)
          return res
        } else {
          console.log(`ERROR updateNotificationMsg: ${JSON.stringify(res.data, null, 2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR updateNotificationMsg: ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  const actDeletePersonnelNotificationMsg = (notificationMsg:NotificationMsg) => {
    let personnelNotifications = useCollectionStore.getState().personnelNotifications
    return notificationMsgService.deleteNotificationMsg(notificationMsg).then(
      res => {
        console.log(res)
        if (!res.data.error) {
          console.log(`deleteNotificationMsg cnt ${res.data.length}`)
          const updatedNotifications = personnelNotifications.filter(n => n?.messages![0]?._id !== res.data._id)
          setPersonnelNotifications(updatedNotifications)
        } else {
          console.log(`ERROR deleteNotificationMsg: ${JSON.stringify(res.data, null, 2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR deleteNotificationMsg: ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  const doUpdateNotificationWebReadDate = (notification:Notification) => {
    const notificationMsg = notification?.messages![0]
    if (!notificationMsg.webReadDate) {
      notificationMsg.webReadDate = new Date()
      updatePersonnelNotificationsMsg(notificationMsg)
    }
  }

  const doUpdateMultipleNotificationWebReadDate = (notifications: Notification[], webReadDate: string | Date) => {
    let personnelNotifications = useCollectionStore.getState().personnelNotifications
    const notificationMsgIds = notifications.map(n => n?.messages![0]?._id).filter(e => !!e)
    const notificationMsgsNotificationsIDS = notifications.map(n => n?.messages![0]?.notification)?.filter(id => !!id)
    //update the webReadDate for the selected notifications
    const updatedPersonelNotifications = personnelNotifications.map(pn => {
      if (notificationMsgsNotificationsIDS.includes(pn._id)) {
        return {
          ...pn,
          messages: pn?.messages?.map(m => {
            return notificationMsgIds.includes(m?._id) ? { ...m, webReadDate: webReadDate } : m
          }),
        }
      } else {
        return pn
      }
    })
    //Make an optimistic update for the client
    setPersonnelNotifications(updatedPersonelNotifications)

    return notificationMsgService.updateMultipleNotificationMsgs(notificationMsgIds, webReadDate).then(
      res => {
        console.log(res)
        if (!res.data.error) {
          return res
        } else {
          console.log(`ERROR updateNotificationMsg: ${JSON.stringify(res.data, null, 2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR updateNotificationMsg: ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  /** Loads notifications for ALL USERS (used in Bell Icon in vertical navigation -> Notification Log)
   * ⚠️ sets the `filteredNotifications` at CollectionStore */
  const loadAllUsersFilteredNotifications = async (filter?: any, options?: any) => {
    if (!filter) {
      let personnelNotificationTypes = Object.values(NotificationTypes)
        .filter(nt => nt.isPersonnelType)
        .map(nt => nt.code)
      filter = { notificationType: { $in: personnelNotificationTypes } }
    }
    return notificationService.getNotifications(filter, options).then(
      res => {
        if (!res.data.error) {
          const notificationsRes = res.data
          // enchancing notifications with measurement series (notification.ms)
          const notificationsIdsArrayTEMP = notificationsRes
            .filter(n => n.measurementSerie)
            .map(n => n.measurementSerie)
          const notificationsIdsSet = new Set(notificationsIdsArrayTEMP)
          const notificationsIds: any = Array.from(notificationsIdsSet)
          return measurementSeriesService
            .getMeasurementSeriesByIds(notificationsIds)
            .then(res => {
              let series = res.data
              //ms is also a runtime property
              const updatedNotifications = notificationsRes.map(n => {
                if (n.measurementSerie) {
                  const ms = series.find(s => s._id === n.measurementSerie)
                  return { ...n, ms: ms }
                } else {
                  return n
                }
              })
              setFilteredNotifications(updatedNotifications)
              return updatedNotifications
            })
            .catch(res => {
              console.log(`ERROR getMeasurementSeriesByIds: ${JSON.stringify(res.data, null, 2)}`)
              console.log(`fallback: setting Notifications without MeasurementSeries`)
              setFilteredNotifications(notificationsRes)
              return notificationsRes
            })
        } else {
          console.log(`ERROR loadAllUsersFilteredNotifications: ${JSON.stringify(res.data, null, 2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR loadAllUsersFilteredNotifications: ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  return {
    loadConsumerNotifications,
    loadPersonnelNotifications,
    getFilteredNotificationMsgsByPersonnel,
    loadAllUsersFilteredNotifications,
    loadConsumerNotificationsForOperator,
    actSaveNotification,
    loadNotificationMsg,
    updateNotificationMsg,
    updatePersonnelNotificationsMsg,
    actDeletePersonnelNotificationMsg,
    doUpdateNotificationWebReadDate,
    doUpdateMultipleNotificationWebReadDate,
  } as NotificationsManagerType
}

export default NotificationsManager
