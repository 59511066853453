import i18n from '../i18n'

import { buildErrorMessage } from '../utils/helpers'
import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'

import ConsumerService from '../services/ConsumerService'

const NIAM_API_URL = process.env.REACT_APP_NIMBL_API_URL
const WITH_SESSION = process.env.REACT_APP_WITH_SESSION === 'true'

const NiamService = (apiClient) => {
  const showAlert = useCommonStore.getState().showAlert
  const consumerService = ConsumerService()

  const getUserBy_id = (_id: string) => {
    let data = {
      operation: 'getUserBy_id',
      _id: _id,
    }
    let cachePromise = true
    return sendNiamRequest(data, cachePromise, 'user/')
  }
  const getUserByUser_id = (user_id: string) => {
    let data = {
      operation: 'getUserByUser_id',
      user_id: user_id,
    }
    let cachePromise = true
    return sendNiamRequest(data, cachePromise, 'user/')
    .then(
      res => {
        // console.log(`getUserByUser_id user ${JSON.stringify(res.data,null,2)}`)
        if (!res.data.error) {//this is in most cases a CosmosDB TooManyRequests issue. We need to handle it centrally
          let user = res.data
          if (user.tenants.length > 0) {
            user.selectedTenant = user.tenants[0]
          }
        } else {
          console.log(`ERROR getUserByUser_id res.data: ${JSON.stringify(res.data,null,2)}`)
        }
        return res
      }
    )
  }
  const getLoggedInUser = (auth0info: any) => {
    let data = {
      operation: 'getLoggedInUser',
      auth0info: auth0info,
    }
    let dataToReturn: any = null
    let cachePromise = true
    return sendNiamRequest(data, cachePromise, 'user/getLoggedInUser/')
    .then(
      res => {
        dataToReturn = res
        // console.log(`getLoggedInUser profile ${JSON.stringify(res.data,null,2)}`)
        let profile = res.data
        // if (profile.tenants.length > 0) {
        //   profile.selectedTenant = profile.tenants[0]
        // }
        if (!!WITH_SESSION) {
          console.log(`getLoggedInUser calling setSessionProfile. WITH_SESSION: ${WITH_SESSION}`)
          let data = {
            operation: 'setSessionProfile',
            profile: profile,
          }
          return sendRequest(data)
        } else {
          return res
        }
      }
    )
    .then(
      res => {
        // console.log(`getLoggedInUser setSessionProfile res.data ${JSON.stringify(res.data,null,2)}`)
        // console.log(`getLoggedInUser profile ${JSON.stringify(dataToReturn,null,2)}`)
        return dataToReturn
      }
    )
  }
  const updateUser = (user: any) => {
    let data = {
      operation: 'updateUser',
      user: user,
      productcode: 'wrm',
    }
    let cachePromise = false
    return sendNiamRequest(data, cachePromise, 'user/')
    .then(
      res => {
        let updatedUser = res.data
        useCollectionStore.getState().updateItem('users', updatedUser)
        return res
      }
    )
  }
  const sendVerificationEmail = (user: any) => {
    let data = {
      operation: 'sendVerificationEmail',
      user: user,
      productcode: 'wrm',
    }
    let cachePromise = false
    return sendNiamRequest(data, cachePromise, 'user/')
  }

  const getUsers = (tenant: string, filter?: any, doReload?: boolean) => {
    let users = useCollectionStore.getState()['users']
    if (!!users && !doReload) {
      return Promise.resolve({ data: users })
    } else {
      let tenantUsers: any[]
      let data: any = {
        operation: 'getTenantUsers',
        tenant: tenant,
      }
      if (!!filter) {
        data.filter = filter
      }
      let cachePromise = true
      return sendNiamRequest(data, cachePromise, 'user/')
      .then(//check if the user is deleted for this tenant
        res => {
          tenantUsers = res.data
          .filter(u => !u.status || u.status.findIndex(s => s.tenant === tenant && s.isDeleted) === -1)
          return consumerService.getConsumersWithUser()
        }
      )
      .then(
        res => {
          // console.log(`loadUsers consumers cnt ${res.data.length}`)
          // console.log(`loadUsers consumers ${JSON.stringify(res.data,null,2)}`)
          let consumers = res.data
          // tUsers = tUsers.map(u => {
          //   let con = consumers.find(c => c.users_user_id = u.user_id)
          consumers.forEach(con => {
            let user = tenantUsers.find(u => u.user_id === con.users_user_id)
            if (!!user) {
              con.hydrometers = []
              user.consumer = con
              // console.log(`loadUsers user ${user.auth0User.name} consumer ${user.consumer.lastname}`)
            }
          })
          useCollectionStore.getState().setItems('users', tenantUsers)
          // setUsers(tUsers)
          return { data: tenantUsers }
        },
        err => {
          console.log(`ERROR loadCollection ${'users'} ${JSON.stringify(err, null, 2)}`)
          // setUsers([])
          return { data: [] }
        }
      )
    }
  }
  const getRoles = () => {
    let cachePromise = true
    return getNiamEntities('role', undefined, cachePromise)
  }
  const getRights = () => {
    let cachePromise = true
    return getNiamEntities('right', undefined, cachePromise)
  }
  const getTenants = () => {
    let cachePromise = true
    return getNiamEntities('tenant', undefined, cachePromise)
  }
  const getLicenses = () => {
    let cachePromise = true
    return getNiamEntities('license', undefined, cachePromise)
  }
  /**
  * The following implementation of getLicenses
  * is a simple showcase of using some middleware-type of processing
  * of the fetched data before setting them in the state store.
  */
  /*
  const getLicenses = () => {
    return getNiamEntities('license', undefined, processLicenses)
  }
  const processLicenses = (licenses: any[]): any => {
    licenses.sort((r1, r2) => {
      let r1name = r1.label
      let r2name = r2.label
      return r1name < r2name ? -1 : (r1name > r2name ? 1 : 0)
    })
    // return licenses
    return Promise.resolve(licenses)
  }
  */

  const getNiamEntities = (entityName: string, entityPlural?: string, cachePromise?: boolean, processEntities?: any) => {
    const _entityPlural = entityPlural ?? `${entityName}s`
    // const entityCap = `${entityName.substring(0,1).toUpperCase()}${entityName.substring(1)}`
    const entityPluralCap = `${_entityPlural.substring(0,1).toUpperCase()}${_entityPlural.substring(1)}`
    const path = `${entityName}/`

    let entities: any[] = useCollectionStore.getState()[_entityPlural]
    if (!!entities) {
      return Promise.resolve({ data: entities })
    } else {
      let data = {
        operation: `get${entityPluralCap}`,
      }
      let promise = sendNiamRequest(data, cachePromise, path)

      /* call any custom processing logic */
      if (processEntities && typeof processEntities === 'function') {
        promise = promise.then(
          res => {
            if (!res.data.error) {
              return processEntities(res.data)
              .then (
                processedEntities => {
                  return { data: processedEntities }
                }
              )
            } else {
              return res
            }
          }
        )
      }

      return promise
      .then(
        res => {
          if (!res.data.error) {
            console.log(`loadCollection ${_entityPlural} cnt ${res.data.length}`)
            // console.log(`loadCollection ${key} ${JSON.stringify(res.data,null,2)}`)
            useCollectionStore.getState().setItems(_entityPlural, res.data)
            return res
          } else {
            console.log(`ERROR loadCollection ${_entityPlural} ${JSON.stringify(res.data,null,2)}`)
            return { data: [] }
          }
        },
        err => {
          console.log(`ERROR loadCollection ${_entityPlural} ${JSON.stringify(err, null, 2)}`)
          return { data: [] }
        }
      )
    }
  }

  const sendNiamRequest = (data: any, cachePromise?: boolean, path?: string) => {
    // console.log(`NiamService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, cachePromise, path, NIAM_API_URL, true)
  }

  const sendRequest = (data: any, cachePromise?: boolean, path?: string) => {
    // console.log(`NiamService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, cachePromise, path, null, true)
  }

  return({
    getLoggedInUser: getLoggedInUser,
    getUserByUser_id: getUserByUser_id,
    getUserBy_id: getUserBy_id,
    updateUser: updateUser,
    sendVerificationEmail: sendVerificationEmail,
    getUsers: getUsers,
    getRoles: getRoles,
    getRights: getRights,
    getTenants: getTenants,
    getLicenses: getLicenses,
  })

}

export default NiamService
