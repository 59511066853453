import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  Typography,
  Button,
  makeStyles,
  Tab,
  Tabs,
  Divider,
  Box,
  IconButton,
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'

import { useCommonStore } from '../../state/commonStore'
import { useCollectionStore } from '../../state/collectionStore'
import UserSettingsService from '../../services/UserSettingsService'
import IdentitySettings from './IdentitySettings'
import NotificationsSettings from './NotificationsSettings'
import LocaleSettings from './LocaleSettings'
import DateFormatSelector from './DateFormatSettings'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import Experimental from './Experimental' //TODO: DELETE THIS

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.25rem 1rem',
  },
  column: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0.25rem 1rem',
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 'normal',
    minWidth: 80,
    maxWidth: 120,
    color: theme.palette.tertiary.main,
  },
  tabLabelSelected: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tabPanelTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '0.75rem 0 0.5rem 0',
    alignSelf: 'flex-start',
  },
  checkboxContainerMain: {
    marginLeft: '1rem',
  },
  checkboxContainerSecondary: {
    marginLeft: '3rem',
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  btnCancel: {
    color: theme.palette.tertiary.main,
    width: '96px',
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'none',
    marginRight: '1rem',
  },
  buttonContainer: {
    margin: 'auto 1rem 1rem 0',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
  },
  divider: {
    border: 'none',
    height: '1px',
    margin: '0.5rem 0',
    width: '100%',
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}))

export default function UserSettings(props) {
  const profile = useCommonStore(state => state.profile)
  const showAlert = useCommonStore(state => state.showAlert)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const userSettings = useCollectionStore(state => state.userSettings)
  const userSettingsService = UserSettingsService()
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [activeTabCode, setActiveTabCode] = useState('generalSettings')
  const [coordinatesSystem, setCoordinatesSystem] = useState<string>(
    !!userSettings && userSettings[0]?.coordinatesSystem ? userSettings[0].coordinatesSystem : 'wgs84'
  )
  const [locale, setLocale] = useState(
    !!userSettings && userSettings[0]?.locale ? userSettings[0].locale : i18n.language
  )
  const [numberLocale, setNumberLocale] = useState(
    !!userSettings && userSettings[0]?.numberLocale ? userSettings[0]?.numberLocale : locale
  )
  const [selectedDateFormat, setSelectedDateFormat] = useState(
    !!userSettings && !!userSettings[0]?.dateFormat ? userSettings[0].dateFormat : (t('date.format') as string)
  )
  const [mdUserPrefs, setMdUserPrefs] = useState(
    !!userSettings && !!userSettings[0]?.mdUserPrefs ? userSettings[0].mdUserPrefs : false
  )

  if (!props.showUserSettings) return null

  const handleTabChange = (e, newValue: string) => {
    setActiveTabCode(newValue)
  }
  const handleCoordinateSystemChange = e => {
    if (e.target.name !== coordinatesSystem) {
      //change the WGS48 units to degrees, so that UI doesn't break
      setCoordinatesSystem(e.target.name)
    }
  }
  const handleChangeLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name !== locale) {
      setLocale(e.target.name)
    }
  }
  const handleChangeNumberLocale = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name !== numberLocale) {
      setNumberLocale(e.target.name)
    }
  }
  const handleDateFormatChange = (event: React.ChangeEvent<{ value: string }>) => {
    const newFormat = event.target.value
    setSelectedDateFormat(newFormat)
  }
  const handleChangeMdUserPrefs = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMdUserPrefs(e.target.checked)
  }

  const handleSaveUserSettings = () => {
    let settings: any
    if (userSettings && userSettings.length > 0) {
      settings = { ...userSettings[0] }
    } else {
      settings = {}
      settings.user_id = profile?.user_id
    }
    settings.coordinatesSystem = coordinatesSystem
    settings.locale = locale
    settings.numberLocale = numberLocale
    settings.dateFormat = selectedDateFormat
    settings.mdUserPrefs = mdUserPrefs
    props.handleClose()
    userSettingsService
      .saveUserSettings(settings)
      .then(res => {
        //if save is successfull i18n.changeLanguage(locale) and dateFormats will be triggered in a useEffect in <Main>
        return showAlert(t('userSettings.success'), 'S')
      })
      .catch(err => {
        console.log(err)
        return showAlert(t('error.message'), 'E')
      })
  }

  return (
    <>
      <Box className={classes.row}>
        <Typography variant='h6' component='h1'>
          {t('mvb.userSettings')}
        </Typography>
        <IconButton aria-label='close' onClick={props.handleClose}>
          <CloseRounded fontSize='inherit' />
        </IconButton>
      </Box>
      <Tabs
        value={activeTabCode}
        onChange={handleTabChange}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        aria-label={t('userSettings.general') as string}
      >
        <Tab
          label={t('userSettings.general')}
          value='generalSettings'
          className={
            'generalSettings' === activeTabCode ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel
          }
        />
        <Tab
          label={t('userSettings.identity')}
          value='identitySettings'
          className={
            'identitySettings' === activeTabCode ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel
          }
        />
        {accessGranted('RvwNtf') && (
          <Tab
            label={t('userSettings.notifications')}
            value='notificationsSettings'
            className={
              'notificationsSettings' === activeTabCode
                ? clsx(classes.tabLabel, classes.tabLabelSelected)
                : classes.tabLabel
            }
          />
        )}
        {/* //TODO: DELETE THIS */}
        {profile?.auth0User?.username === 'bentester' && (
          <Tab
            label={t('experimental')}
            value='experimental'
            className={
              'experimental' === activeTabCode
                ? clsx(classes.tabLabel, classes.tabLabelSelected)
                : classes.tabLabel
            }
          />
        )}
      </Tabs>
      <Divider />
      {activeTabCode === 'generalSettings' && (
        <>
          <Box className={classes.column}>
            <Typography align='left' className={classes.tabPanelTitle} component='h2'>
              {t('userSettings.coordinateSystem')}
            </Typography>
            {/* <h1>GENERAL OPTIOSN GO HERE */}
            <Box className={classes.checkboxContainerMain}>
              <CheckBoxNt
                checked={coordinatesSystem === 'egsa87'}
                onChange={handleCoordinateSystemChange}
                name='egsa87'
                label='ΕΓΣΑ 87 ( Χ, Υ)'
              />
            </Box>
            <Box className={classes.checkboxContainerMain}>
              <CheckBoxNt
                checked={coordinatesSystem === 'wgs84' || coordinatesSystem === 'wgs84DMS'}
                onChange={handleCoordinateSystemChange}
                name='wgs84'
                label={'units.wgs84'}
              />
            </Box>
            <Box className={classes.checkboxContainerSecondary}>
              <CheckBoxNt
                checked={coordinatesSystem === 'wgs84DMS'}
                disabled={!(coordinatesSystem === 'wgs84' || coordinatesSystem === 'wgs84DMS')}
                onChange={handleCoordinateSystemChange}
                name='wgs84DMS'
                label={'units.degrees'}
              />
            </Box>
            <Box className={classes.checkboxContainerSecondary}>
              <CheckBoxNt
                checked={coordinatesSystem === 'wgs84'}
                disabled={!(coordinatesSystem === 'wgs84' || coordinatesSystem === 'wgs84DMS')}
                onChange={handleCoordinateSystemChange}
                name='wgs84'
                label={'units.decimalDegrees'}
              />
            </Box>
          </Box>
          <LocaleSettings
            locale={locale}
            numberLocale={numberLocale}
            handleChangeLanguage={handleChangeLanguage}
            handleChangeNumberLocale={handleChangeNumberLocale}
          />
          <Box>
            <Box className={classes.column}>
              <DateFormatSelector
                selectedDateFormat={selectedDateFormat}
                handleDateFormatChange={handleDateFormatChange}
              />
            </Box>
          </Box>
          {accessGranted('VwNtwr1') &&
          <Box className={classes.column}>
            <Typography align='left' className={classes.tabPanelTitle} component='h2'>
              {t('userSettings.mdUserPrefs')}
            </Typography>
            <Box className={classes.checkboxContainerMain}>
              <CheckBoxNt checked={mdUserPrefs} onChange={handleChangeMdUserPrefs} label={t('btn.yes')!} />
            </Box>
          </Box>
          }
          <Box className={classes.buttonContainer}>
            <Divider className={classes.divider} />
            <Box>
              <Button className={classes.btnCancel} onClick={props.handleClose} variant='outlined'>
                {t('btn.cancel')}
              </Button>
              <Button className={classes.applyBtn} onClick={handleSaveUserSettings} autoFocus>
                {t('btn.update')}
              </Button>
            </Box>
          </Box>
        </>
      )}
      {activeTabCode === 'identitySettings' && (
        <IdentitySettings actUpdateUserUsernameOrPasswordOrEmail={props.actUpdateUserUsernameOrPasswordOrEmail} />
      )}
      {activeTabCode === 'notificationsSettings' && <NotificationsSettings handleClose={props.handleClose} />}
      {/* //TODO: DELETE THIS */}
      {activeTabCode === 'experimental' && (<Experimental/>)}
    </>
  )
}
