import { Simulation, SimulationNew } from "../models/epanet"
var _ = require('lodash');

const EpanetSimulationService = (apiClient) => {

  const pathSimu = 'epanetSimulation/'

  const runSimulation = (request) => {
    let data: any = request
    return postRequest(data, `${pathSimu}simulator`)
  }

  const getEnergySimulation = () => {
    let cachePromise = true
    return sendGetRequest(pathSimu, cachePromise)
  }

  const getEnergySimulationById = (id) => {
    let pathId = `${pathSimu}${id}`
    return sendGetRequest(pathId)
  }

  const getMeasurement = (measurement) => {
    let data: any = measurement
    let pathAdd = `${pathSimu}measurements`
    return postMeasurementRequest(data, pathAdd)
  }

  const saveSimulation = (data) => {
    let dataES = new SimulationNew();
    _.assign(dataES , _.pick(data, _.keys(dataES)));
    return postRequest(dataES, pathSimu)
  }

  const updateSimulation= (data) => {
    let dataES = new Simulation();
    _.assign(dataES , _.pick(data, _.keys(dataES)));
    return putRequest(dataES, pathSimu)
  }

  const deleteSimulation = (simu_id) => {
    return deleteRequest(`${pathSimu}${simu_id}`)
  }

  const saveEnergySimulationNote = (request) => {
    let data: any = request
    let pathAdd = `${pathSimu}note`
    return postAddNoteRequest(data, pathAdd)
  }

  const postAddNoteRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  const sendGetRequest = (path: string, cachePromise?: boolean) => {
    return apiClient.sendGetRequest(cachePromise, path, null, true, null)
  }

  const postMeasurementRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  const postRequest = (data, path) => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  const putRequest = (data, path) => {
    return apiClient.sendPutRequest(data, path, null, true)
  }

  const deleteRequest = (url) => {
    return apiClient.sendDeleteRequest(url, null, true)
  }

  return({
    runSimulation: runSimulation,
    // getSimulationTanks: getSimulationTanks,
    // getSimulationPumps: getSimulationPumps,
    // getSimulationJunctions: getSimulationJunctions,
    getEnergySimulation: getEnergySimulation,
    getEnergySimulationById: getEnergySimulationById,
    getMeasurement: getMeasurement,
    saveSimulation: saveSimulation,
    updateSimulation: updateSimulation,
    deleteSimulation: deleteSimulation,
    saveEnergySimulationNote: saveEnergySimulationNote
  })

}

export default EpanetSimulationService
