import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import EditProperty from './EditProperty'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        justifyContent: 'center',
    },
    li: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 8,
        paddingRight: 8,
    },
    lilab: {
        display: 'flex',
        minWidth: 150,
        maxWidth: 150,
        fontWeight: 700,
    },
    lival: {
        minWidth: 370,
        maxWidth: 370,
    },
    livallean: {
        minWidth: 90,
        maxWidth: 90,
    },
    livalfullname: {
        minWidth: 180,
        maxWidth: 180,
        marginRight: 10,
    },
    livalemail: {
        minWidth: 180,
        maxWidth: 180,
    },
    input: {
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: 14,
    },
    autoinputroot: {
        paddingTop: '6px !important',
        paddingBottom: '6px !important',
        paddingLeft: '14px !important',
        fontSize: 14,
    },
    autoinput: {
        paddingTop: '2px !important',
        paddingBottom: '2px !important',
    },
    inputlean: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: 14,
    },
    dlgpaper: {
        maxWidth: '80vw',
    },
    dlgcontroot: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderTop: `solid 1px ${theme.palette.tertiary.light}`,
        borderBottom: `solid 1px ${theme.palette.tertiary.light}`,
    },
    applyBtn: {
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 700,
        width: theme.spacing(12),
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    cancelBtn: {
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 700,
        width: theme.spacing(12),
    },
    uploadbtn: {
        overflow: 'hidden',
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        width: theme.spacing(15),
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    uploadinput: {
        cursor: 'pointer',
        position: 'absolute',
        top: -22,
        width: theme.spacing(15),
        height: 70,
        opacity: 0,
    },
    chip: {
        margin: 1,
    },
}))

const EditPropertiesDialog = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tfeature = props.feature
  const cloneFeature = (feature) => {
    let clone = Object.assign({}, feature)
    clone.properties = Object.assign({}, feature.properties)
    clone.geometry.coordinates = feature.geometry.coordinates.map(c => c)
    return clone
  }

  const [feature, setFeature] = useState(cloneFeature(tfeature))

  const buildDialogFieldsArray = () => {
    let dialogFields = [] as any
    if (!!feature && !!props.info && !!props.info.groups) {
      props.info.groups.forEach(g => {
        g.fields.forEach(f => {
          // console.log(`Info input to EditProperties component:${JSON.stringify(f, null, 2)}`)
          if (!f.hidden && !f.readonly) {
            dialogFields.push(f)
          }
        })
      })
    }
    return dialogFields
  }

  let dialogFields = buildDialogFieldsArray()
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dlgpaper }}
      onClose={(event, reason) => (reason === 'backdropClick') ? null : {}}
    >
      <DialogTitle >{t('ne.dlg.edit')}</DialogTitle>
      <DialogContent classes={{ root: classes.dlgcontroot }}>
        <List>
          {dialogFields.map(f =>
          <EditProperty
            key={f.code}
            info={f}
            feature={feature}
            editingProp={f.code}
            setFeature={setFeature}
          />)}
        </List>
      </DialogContent >
      <DialogActions>
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          onClick={() =>props.onClose(null)}
        >
          {t('btn.cancel')}
        </Button>
        <Button
          className={classes.applyBtn}
          onClick={() => props.onClose(feature)}
        >
          {t('btn.update')}
        </Button>
      </DialogActions>
    </Dialog >
  )
}
export default EditPropertiesDialog
