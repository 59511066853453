import * as React from 'react'
import { AppBar, Toolbar, Typography, IconButton,Badge } from '@mui/material/'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@mui/icons-material/Menu'
import WrmIcon from '../../WrmIcon'
import { useTranslation } from 'react-i18next'
import MobileNavigationDrawer from './CPMobileNavigationDrawer'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.tertiary.dark,
    height:'56px',
  },
}))

export default function CPMobileAppBar({
  accessGranted,
  verticalNavigate,
  setActiveList,
  activeList,
  logOut,
  activeContentKey,
  unreadNotificationsCnt
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const toggleDrawer = (open: boolean) => {
    setIsOpen(open)
  }

  return (
    <>
      <AppBar>
        <Toolbar className={classes.appBar}>
          <WrmIcon icon='tint' style={{ width: 32, height: 32 }} />
          <Typography
            variant='h6'
            component='div'
            align='left'
            sx={{ flexGrow: 1, paddingLeft: '0.75rem' }}>
            {t('cp.portal')}
          </Typography>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={() => toggleDrawer(true)}>
            <Badge
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              color="error"
              overlap='rectangular'
              variant="dot"
              invisible={!unreadNotificationsCnt}>
              <MenuIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <MobileNavigationDrawer
        accessGranted={accessGranted}
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        verticalNavigate={verticalNavigate}
        setActiveList={setActiveList}
        activeList={activeList}
        logOut={logOut}
        activeContentKey={activeContentKey}
      />
    </>
  )
}
