const InventoryItemService = (apiClient) => {

  const path = 'inventoryItem/'
  const pathReport = ''

  const getInventoryItems = (inventoryItemType?) => {
    let data: any = {}
    if (!!inventoryItemType) {
      data.inventoryItemType = inventoryItemType
    }
    let cachePromise = true
    return sendGetRequest(path, cachePromise, data)
  }

  const getInventoryItemsByCategoryType = (categoryType) => {
    let pathInventoryByCategory = `inventoryItem?inventoryItemType=${categoryType}`
    return sendGetInventoryByCategoryRequest(pathInventoryByCategory)
  }

  const saveInventoryItem = (inventoryItem) => {
    let data: any = inventoryItem
    return postRequest(data)
  }

  const deleteInventoryItem = (id) => {
    return deleteRequest(`${path}${id}`)
  }

  const updateInventoryItem = (inventoryItem) => {
    let data: any = inventoryItem
    return putRequest(data)
  }

  const getNotesByInventoryId = (inventoryItemId) => {
    let pathGetById = `${path}note?inventoryItem_id=${inventoryItemId}`
    return sendGetNotesRequest(pathGetById)
  }

  const saveInventoryNote = (inventoryItemNote) => {
    let data: any = inventoryItemNote
    let pathAdd = `${path}note`
    return postAddNoteRequest(data, pathAdd)
  }

  const editInventoryNote = (inventoryItemNote) => {
    let data: any = inventoryItemNote
    let pathEdit = `${path}note`
    return putAddNoteRequest(data, pathEdit)
  }

  const deleteInventoryNote = (inventoryItem_id, note_id) => {
    let pathRemove = `${path}${inventoryItem_id}/note/${note_id}`
    return deleteNoteRequest(pathRemove)
  }

  // service for operated hours start
  const saveOperatedHours = (operatedHoursItem) => {
    let data: any = operatedHoursItem
    let pathAdd = `${path}em_register`
    return postAddNoteRequest(data, pathAdd)
  }

  const editOperatedHours = (operatedHoursItem) => {
    let data: any = operatedHoursItem
    let pathEdit = `${path}em_register`
    return putAddNoteRequest(data, pathEdit)
  }

  const deleteOperatedHours = (inventoryItem_id, operatedHours_id) => {
    let pathRemove = `${path}${inventoryItem_id}/work_entry/${operatedHours_id}`
    return deleteNoteRequest(pathRemove)
  }
  // service for operated hours end

  const sendGetRequest = (path: string, cachePromise?: boolean, data?: any) => {
    return apiClient.sendGetRequest(cachePromise, path, null, true, data)
  }
  //add registers
  const saveRegisterItem = (register) => {
    // console.log('FUEL SAVE DATA ', register);
    let fullpath = `${path}em_register`
    return apiClient.sendRequest(register, false, fullpath, null, true)
  }

  //editRegister
  const editRegisterItem = (register) => {
    let data:any = {}
    if(register){
      data = {
        inventoryItem_id: register.inventoryItem_id,
        emRegister: {
          refDate: register.refDate,
          kilometers: register.kilometers,
          volume: register.volume,
          workhours:  register.workhours || null,
          cost: register.cost,
          _id: register._id,
          emRegisterType: register.emRegisterType,
        }
      }
    }
    // console.log('EDIT REGISTER DATA ', data);
    let fullpath = `${path}em_register`
    return apiClient.sendPutRequest(data, fullpath, null, true)
  }
  //deleteRegister
  const deleteRegisterItem = (inventoryItemId, registerId, type) => {
    let fullpath = `${path}/${inventoryItemId}/${type}/${registerId}`
    // console.log('DELETE REGISTER DATA ', fullpath);
    return apiClient.sendDeleteRequest(fullpath, null, true)
  }

  const getInventoryReports = (report, inventoryCategory, startDate?, endDate?, fuelType?) => {
    let path;
    if(report === 'permitted') {
      path = `${pathReport}vehicleTruckmachineReport?report=${report}&inventoryCategory=${inventoryCategory}`
    }
    if(report === 'actual') {
      path = `${pathReport}vehicleTruckmachineReport?report=${report}&inventoryCategory=${inventoryCategory}&startDate=${startDate}&endDate=${endDate}&fuelType=${fuelType}`
    }
    return sendGetReportsRequest(path)
  }

  const getMaintenanceEventsByID = (item_id) => {
    let subPath;
    subPath = `${path}${item_id}/events`
    return sendGetRequest(subPath)
  }

  const postRequest = (data) => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  const putRequest = (data) => {
    return apiClient.sendPutRequest(data, path, null, true)
  }

  const deleteRequest = (url) => {
    return apiClient.sendDeleteRequest(url, null, true)
  }

  const sendGetNotesRequest = (pathGetById) => {
    return sendGetRequest(pathGetById)
  }

  const sendGetInventoryByCategoryRequest = (path) => {
    return sendGetRequest(path)
  }

  const sendGetReportsRequest = (pathReport) => {
    return sendGetRequest(pathReport)
  }

  const postAddNoteRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  const putAddNoteRequest = (data, pathEdit) => {
    return apiClient.sendPutRequest(data, pathEdit, null, true)
  }

  const deleteNoteRequest = (pathRemove) => {
    return apiClient.sendDeleteRequest(pathRemove, null, true, null)
  }

  return({
    getInventoryItems: getInventoryItems,
    saveInventoryItem: saveInventoryItem,
    updateInventoryItem: updateInventoryItem,
    deleteInventoryItem: deleteInventoryItem,
    saveInventoryNote: saveInventoryNote,
    editInventoryNote: editInventoryNote,
    deleteInventoryNote: deleteInventoryNote,
    getNotesByInventoryId: getNotesByInventoryId,
    saveRegisterItem: saveRegisterItem,
    getInventoryReports: getInventoryReports,
    getInventoryItemsByCategoryType: getInventoryItemsByCategoryType,
    getMaintenanceEventsByID: getMaintenanceEventsByID,
    saveOperatedHours: saveOperatedHours,
    editOperatedHours: editOperatedHours,
    deleteOperatedHours: deleteOperatedHours,
    editRegisterItem: editRegisterItem,
    deleteRegisterItem: deleteRegisterItem
  })

}

export default InventoryItemService
