import React, { useState } from 'react'
import { useCollectionStore } from '../../state/collectionStore'
import { useCommonStore } from '../../state/commonStore'
import UserSettingsService from '../../services/UserSettingsService'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  Typography,
  Button,
  Box,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControlLabel,
} from '@material-ui/core'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import { USNotificationTypes, USNotificationFrequencies } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0.25rem 1rem',
  },
  tabPanelTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '1rem 0',
    alignSelf: 'flex-start',
  },
  li: {
    display: 'flex',
    flexFlow: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 32,
    width: '100%',
  },
  lilab: {
    textAlign: 'left',
    marginBottom: 16,
  },
  lival: {},
  livalcb: {
    minWidth: 250,
    maxWidth: 250,
    margin: 0,
  },
  livalcblab: {
    fontSize: 14,
  },
  livalcbchk: {
    padding: 8,
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
    textAlign: 'left',
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  identityButtonContainer: {
    marginLeft: 'auto',
  },
  infoBox: {
    marginLeft: 8,
    padding: 8,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  infoMsg: {
    marginLeft: 8,
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
  wrmIcon:{
    alignSelf:'flex-start',
    marginTop:'0.5rem'
  },
  btnCancel: {
    color: theme.palette.tertiary.main,
    width: '96px',
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'none',
    marginRight: '1rem',
  },
  buttonContainer: {
    margin: 'auto 1rem 1rem 0',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
  },
  divider: {
    border: 'none',
    height: '1px',
    margin: '0.5rem 0',
    width: '100%',
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}))

export default function NotificationsSettings(props) {
  const showAlert = useCommonStore(state => state.showAlert)
  const profile = useCommonStore(state => state.profile)
  const userSettingsService = UserSettingsService()
  const userSettings = useCollectionStore(state=>state.userSettings)
  const classes = useStyles()
  const { t } = useTranslation()

  const [frequency, setFrequency] = useState(
    (!!userSettings && userSettings[0]?.notificationFrequency)
      ? userSettings[0].notificationFrequency
      : ''
  )
  const [notificationTypes, setNotificationTypes] = useState<string[]>(
    (!!userSettings && userSettings[0]?.notificationTypes)
      ? userSettings[0].notificationTypes
      : []
  )

  const handleChangeFrequency = event => {
    let val = event.target.value
    setFrequency(val)
  }
  const handleChangeNotificationType = event => {
    let newTypes: string[] = [...notificationTypes]
    let chtype = event.target.name
    if (event.target.checked) {
      if (!newTypes.includes(chtype)) {
        newTypes.push(chtype)
      }
    } else {
      let x = newTypes.findIndex(v => v === chtype)
      if (x > -1) {
        newTypes.splice(x, 1)
      }
    }
    setNotificationTypes(newTypes)
  }

  const validateSettings = (): string[] => {
    let messages: string[] = []
    if (notificationTypes.length > 0 && !frequency) {
      messages.push(t('userSettings.notifications.error.noFrequency'))
    }
    return messages
  }
  const handleSaveUserSettings = () => {
    console.log(JSON.stringify(userSettings,null,2))
    let errmsgs: string[] = validateSettings()
    if (errmsgs.length > 0) {
      showAlert(errmsgs.join('\n'), 'E')
    } else {
      let settings: any
      if (!!userSettings && userSettings.length > 0) {
        settings = {...userSettings[0]}
      } else {
        settings = {}
        settings.user_id = profile?.user_id
      }
      settings.notificationTypes = notificationTypes
      settings.notificationFrequency = frequency

      console.log(JSON.stringify(settings,null,2))
      userSettingsService.saveUserSettings(settings)
      .then(
        res => {
          showAlert(t('userSettings.success'), 'S')
          props.handleClose()
        }
      )
      .catch(
        err => {
          let errMessage = `${t('error.message')}:\n`
          if (err?.response?.data?.error?.message) {
            errMessage += err?.response?.data?.error?.message
          } else {
            errMessage += err
          }
          console.log(errMessage)
          showAlert(errMessage, 'E')
        }
      )
    }
  }

  const mitems:any[] = Object.values(USNotificationFrequencies)
  .map(rt => (
    <MenuItem key={rt.code} value={rt.code}>{t(`${rt.transKey}.${rt.code}`)}</MenuItem>
  ))
  mitems.splice(0,0, (<MenuItem key='none' value=''>{t('options.choose')}</MenuItem>))

  return (
    <>
      <Box className={classes.column}>
        <Typography
          align='left'
          className={classes.tabPanelTitle}
          component='h2'>
          {t('userSettings.notifications.report')}
        </Typography>
      </Box>
      <Box className={classes.column}>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('userSettings.notifications.selectType')}</Box>
          {Object.values(USNotificationTypes).map(nt =>
          <FormControlLabel
            key={nt.code}
            name={nt.code}
            className={classes.livalcb}
            classes={{label: classes.livalcblab,}}
            control={
              <CheckBoxNt
                classes={{root: classes.livalcbchk,}}
                checked={notificationTypes.includes(nt.code)}
                onChange={handleChangeNotificationType}
              />
            }
            label={t(`${nt.transKey}.${nt.code}`)}
          />
          )}
        </Box>
      </Box>
      <Box className={classes.column}>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('userSettings.notifications.selectFrequency')}</Box>
          <Select
            variant='outlined'
            fullWidth
            displayEmpty
            className={classes.lival}
            classes={{root: classes.input,}}
            value={frequency}
            onChange={handleChangeFrequency}
          >
            {mitems}
          </Select>
        </Box>
      </Box>
      <Box className={classes.column}>
        <Box className={classes.li}>
          <Box className={classes.lilab}>
            {t('userSettings.notifications.emailLabel')}
          </Box>
          <TextField
            variant='outlined'
            disabled={true}
            type='text'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            value={profile?.auth0User?.email}
          />
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Divider className={classes.divider} />
        <Box>
          <Button
            className={classes.btnCancel}
            onClick={props.handleClose}
            variant='outlined'>
            {t('btn.cancel')}
          </Button>
          <Button
            className={classes.applyBtn}
            onClick={handleSaveUserSettings}
          >
            {t('btn.update')}
          </Button>
        </Box>
      </Box>
    </>
  )
}
