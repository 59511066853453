import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '8px',
    margin: '0 8px',
    height: 48,
    ...theme.typography.h3,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.lighter} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#fff',
      color: theme.palette.primary.dark,
    },
  },
});

const MenuItemNt = withStyles(styles)(MenuItem);

export default MenuItemNt;
