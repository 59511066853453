import moment from 'moment'

import { useCommonStore } from '../state/commonStore'
import { useIrriStatusStore } from '../state/irriStatusStore'
import { buildErrorMessage } from '../utils/helpers'
import TemplateService from './TemplateService'

const IrriCommandService = () => {

  const templateService: any = TemplateService('irriCommand')

  const apiClient = useCommonStore.getState().apiClient
  const path = 'irriCommand/'

  /**
  * Saves and also sends a command to an irrigation plc (via the backend).
  * The plc's connection specifics are retrieved by the backend,
  * based on the stationCode info contained in the irrigation command.
  * Internally this function calls the sendIrriCommand function.
  * @param entity - the irrigation command.
  */
  const saveIrriCommand = (entity: any) => {
    /* Note that refDatetime and timestamp are set on the backend */
    return sendIrriCommand(entity)
    .then(
      res => {
        entity = res
        return templateService.saveEntity(entity)
      }
    )
  }

  /**
  * Sends a command to an irrigation plc (via the backend).
  * The plc's connection specifics are retrieved by the backend,
  * based on the stationCode info contained in the irrigation command.
  * Normally this function should be called instead of the saveIrriCommand function
  * when we do not want the command to be logged
  * e.g. for commands sent automatically by the system in the background.
  * @param entity - the irrigation command.
  */
  const sendIrriCommand = (entity: any) => {
    let data: any = {
      operation: 'sendIrriCommand',
      irriCommand: entity,
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        return entity
      },
      err => {
        if (!entity.response) {
          let response = buildErrorMessage(err)
          entity.response = `Uncaught sendIrriCommand error: ${response}`
        }
        if (!entity.refDatetime) {
          entity.refDatetime = new Date()
          entity.timestamp = entity.refDatetime.valueOf()
        }
        return entity
      }
    )
  }

  const getEntities = (filter?: any, options?: any) => {
    const currentAgriculturalPeriod = useCommonStore.getState().currentAgriculturalPeriod
    /**Use Current year as fallback in case the currentAgriculturalPeriod is undefined */
    let cultivationPeriod: number = new Date().getFullYear()
    if (currentAgriculturalPeriod?.cultivationPeriod) {
      cultivationPeriod = currentAgriculturalPeriod?.cultivationPeriod
    }
    const startOfYear = moment().year(cultivationPeriod).startOf('year').toDate()
    const endOfYear = moment().year(cultivationPeriod).endOf('year').toDate()
    if (!filter) {
      filter = {
        'createdAt': {
          $gte: startOfYear,
          $lte: endOfYear,
        },
      }
    } else if (!filter['createdAt']) {
      filter['createdAt'] = {
        $gte: startOfYear,
        $lte: endOfYear,
      }
    }
    return templateService.getEntities(filter, options)
  }

  const sendRequest = (data: any) => {
    // console.log(`IrriProgService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getEntities: getEntities,
    saveEntity: saveIrriCommand,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
    sendIrriCommand: sendIrriCommand,
  })

}

export default IrriCommandService
