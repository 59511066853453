import TemplateService from "./TemplateService";

const AlarmService = () => {
  const templateService: any = TemplateService("alarm");

  return {
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  };
};

export default AlarmService
