import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import history from './utils/history'
import initFontAwesome from './utils/initFontAwesome'
import Main from './components/Main'
import theme from './theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { elGR, enUS } from '@mui/x-date-pickers/locales';
import { useCommonStore } from './state/commonStore'

function App() {
  initFontAwesome()
  const locale = useCommonStore(state => state.locale)

  return (
    <div className='App'>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={locale === 'el' ? 'gr' : 'en'}
              localeText={locale === 'el' 
                ? elGR.components.MuiLocalizationProvider.defaultProps.localeText
                : enUS.components.MuiLocalizationProvider.defaultProps.localeText}>
              <Route path='/' component={Main} />
            </LocalizationProvider>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
