import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'

import { useCommonStore } from '../state/commonStore'
import { useUiStore } from '../state/uiStore'
import WrmIcon from './WrmIcon'
import MobileAppBar from './consumer/cpMobileDevice/CPMobileAppBar'

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.primary.main,
  },
  li: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    color: theme.palette.tertiary.light,
  },
  liSelected: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    color: theme.palette.primary.main,
  },
  liSelectedSelected: {
    backgroundColor: 'unset !important',
  },
  liSelectedBorder: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  liIcon: {
    justifyContent: 'center',
    color: theme.palette.tertiary.light,
  },
  liIconSelected: {
    justifyContent: 'center',
    color: theme.palette.primary.main,
    minWidth: 54,
  },
  tooltip: {
    fontSize: 14,
    lineHeight: 2,
    fontWeight: 200,
  },
  badge:{
    color:' red',
    backgroundColor: '#fff',
    border: '2px solid red',
    left:' 4px',
  },
  badge2: {
    backgroundColor: '#FFFDFD',
    color: '#EC4941',
    border: '1px solid #E6004D',
    borderRadius: 8,
    padding: 0,
    height: 17,
    fontWeight: 'bold',
  },
  dot: {
    backgroundColor: '#EC4941',
  },
}))

const MainVertical = (props) => {
  const profile = useCommonStore(state => state.profile)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const isEditingGeometry = useUiStore(state => state.isEditingGeometry)
  const isDrawingGeometry = useUiStore(state => state.isDrawingGeometry)

  //Filter out notifications that should not appear based on user rights (used in badge unread notifications count)
  let filteredNotifications = props.unreadNotifications || []
  if (!accessGranted("vwqlty")) filteredNotifications = filteredNotifications.filter(n=>n.notificationType !== "alarmFired")
  if (!accessGranted("Vwrrgt9")) filteredNotifications = filteredNotifications.filter(n=>n.notificationType !== "invrtAlarmFired")
  if (!accessGranted("VwMntn")) filteredNotifications = filteredNotifications.filter(n=>n.notificationType !== "maintenancePlan")
  if (!accessGranted("Vwrrgt3")) filteredNotifications = filteredNotifications.filter(n=>n.notificationType !== "irriEventPersonnel")
  let unreadNotificationsCnt = !!filteredNotifications ? filteredNotifications.filter(n => n.messages && !n.messages[0].webReadDate).length : 0

  const { t } = useTranslation()
  const classes = useStyles()

  const VbItem = vbiprops => {
    let title = t(`mvb.${vbiprops.ckey}`)
    const isSelected = () => {
      if (!props.entitiesOverlayKey) {
        return props.contentKey === vbiprops.ckey
      } else if (
        props.contentKey === 'entities' &&
        props.entitiesOverlayKey === 'NOTIFICATIONS' &&
        vbiprops.ckey === 'NOTIFICATIONS'
      ) {
        return true
      } else {
        return false
      }
    }
    return (
    <Tooltip
      title={title}
      classes={{tooltip: classes.tooltip}}
      arrow interactive
      placement='right'
      enterDelay={1000}
      // leaveDelay={20}
      enterNextDelay={500}
    >
      <ListItem button key={vbiprops.ckey}
        id={`mvb_${vbiprops.ckey}`}
        className={isSelected() ? clsx(classes.liSelected, classes.liSelectedBorder) : classes.li}
        classes={{ selected: classes.liSelectedSelected, }}
        disabled={vbiprops.disabled}
        selected={props.contentKey === vbiprops.ckey}
        onClick={() => props.verticalNavigate(vbiprops.ckey)}
        onMouseEnter={vbiprops.onMouseEnter ?? undefined}
        onMouseLeave={vbiprops.onMouseLeave ?? undefined}
        // onMouseEnter={!!vbiprops.onMouseEnter ? (e) => vbiprops.onMouseEnter(e.currentTarget, vbiprops.ckey, true) : undefined}
        // onMouseLeave={!!vbiprops.onMouseLeave ? (e) => vbiprops.onMouseLeave(e.currentTarget, vbiprops.ckey, false) : undefined}
      >
        <ListItemIcon
          className={isSelected() ? classes.liIconSelected : classes.liIcon}
        >
        {vbiprops.withBadge ? (
          <Badge
            color='error'
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            // variant={vbiprops.count ? 'standard' : 'dot'}
            overlap='rectangular'
            variant='standard'
            badgeContent={vbiprops.count ? vbiprops.count : null}
            classes={{badge: vbiprops.count ? classes.badge2 : classes.dot}}
          >
            <WrmIcon
              icon={vbiprops.icon}
              style={{width: 32, height: 32, fill: 'currentColor'}}
            />
          </Badge>
        ) : (
          <WrmIcon
            icon={vbiprops.icon}
            style={{width: 32, height: 32, fill: 'currentColor'}}
          />
        )}
        </ListItemIcon>
      </ListItem>
    </Tooltip>
  )}

  let disableNavigation = isEditingGeometry || isDrawingGeometry
  if (props.isSmallScreen) {
    return (
      <>
        <MobileAppBar
          accessGranted={accessGranted}
          logOut={props.actAvatarAction}
          verticalNavigate={props.verticalNavigate}
          setActiveList={props.setActiveList}
          activeList={props.activeList}
          activeContentKey={props.contentKey}
          unreadNotificationsCnt={unreadNotificationsCnt}
        />
      </>
    )
  }
  return (
    <Fragment>
      <List
        style={{paddingTop: '0.625rem'}}
      >
        <ListItem key='tint'
          className={classes.li}
          style={{backgroundColor: 'unset'}}
        >
          <ListItemIcon
            className={classes.liIcon}
          >
            <WrmIcon
              icon='tint'
              style={{width: 32, height: 32}}
            />
          </ListItemIcon>
        </ListItem>
        {accessGranted('VwMnMp') &&
        <VbItem ckey='entities'
          disabled={disableNavigation || !accessGranted('VwMnMp')}
          icon='networkDesign'
        />}
        {accessGranted('VwMntn') &&
        <VbItem ckey='equipmentmaintenance'
          disabled={disableNavigation || !accessGranted('VwMntn')}
          icon='equipment-maintenance'
        />}
        {accessGranted('Vwnvnt') &&
        <VbItem ckey='inventorycategories'
          disabled={disableNavigation || !accessGranted('Vwnvnt')}
          icon='inventory-categories'
        />}
        {accessGranted('Vwctvt') &&
        <VbItem ckey='activities'
          disabled={disableNavigation || !accessGranted('Vwctvt')}
          icon='activities'
        />}
        {accessGranted('vwwtrb') &&
        <VbItem ckey='waterbalance'
          disabled={disableNavigation || !accessGranted('vwwtrb')}
          icon='water-balance'
        />}
        {accessGranted('vwqlty') &&
        <VbItem ckey='qualityControl'
          disabled={disableNavigation || !accessGranted('vwqlty')}
          icon='quality-control'
        />}
        {accessGranted('vwnrgy') &&
        <VbItem ckey='energyoptimization'
          disabled={disableNavigation || !accessGranted('vwnrgy')}
          icon='energy-optimization'
        />}
        {accessGranted('VwHydr') &&
        <VbItem ckey='hydraulicoptimization'
          disabled={disableNavigation || !accessGranted('VwHydr')}
          icon='hydraulic-optimization'
        />}
        {accessGranted('VwTlmt') &&
        <VbItem ckey='scada'
          disabled={disableNavigation || !accessGranted('VwTlmt')}
          icon='multiple-selection'
        />}
        {accessGranted('VwCtlg') &&
        <VbItem ckey='catalogs'
          disabled={disableNavigation || !accessGranted('VwCtlg')}
          icon='catalog-elements'
        />}
        {/* //TODO: add the correct rights */}
        {accessGranted('Vwrrgt7') &&
        <VbItem ckey='irriSchedule'
          disabled={disableNavigation || !accessGranted('Vwrrgt7')}
          icon='irriSchedule'
        />}
        {accessGranted('Vwrrgt3') &&
        <VbItem ckey='irrigation'
          disabled={disableNavigation || !accessGranted('Vwrrgt3')}
          icon='irrigain'
        />}
        {accessGranted('Vwrrgt5') &&
        <VbItem ckey='irriForm'
          disabled={disableNavigation || !accessGranted('Vwrrgt5')}
          icon='irriForm'
        />}
        {accessGranted('vwcnsm') &&
        <VbItem ckey='consumerPortal'
          disabled={disableNavigation || !accessGranted('vwcnsm')}
          icon='consumerPortal'
        />}
        {accessGranted('gnrccn') &&
        <VbItem ckey='cp.myAccount'
          disabled={disableNavigation || !accessGranted('gnrccn')}
          icon='myAccount'
        />}
        {accessGranted('VwMyCn') &&
        <VbItem ckey='cp.report'
          disabled={disableNavigation || !accessGranted('VwMyCn')}
          icon='reportAnIssue'
        />}
        {accessGranted('gnrccn') &&
        <VbItem ckey='cp.network'
          disabled={disableNavigation || !accessGranted('gnrccn')}
          icon='networkUpdates'
          withBadge={!!unreadNotificationsCnt}
          count={unreadNotificationsCnt}
        />}
      </List>
      <Divider className={classes.divider} style={{flexGrow: 1}} />
      <List
        style={{paddingBottom: '1.5rem'}}
      >
        {(accessGranted('vwntwr') || accessGranted('dtNtwr3')) &&
        <VbItem ckey='NOTIFICATIONS'
          disabled={disableNavigation || !(accessGranted('vwntwr') || accessGranted('dtNtwr3'))}
          icon='firedAlarms'
          withBadge={true || !!props.firedAlarmsCnt}
          count={unreadNotificationsCnt}
        />}
        {/* Popper Options */}
        {!accessGranted('gnrccn') && <Tooltip
          title={<span>{t(`mvb.settings`)}</span>}
          classes={{tooltip: classes.tooltip}}
          arrow
          placement='right'
          enterDelay={1000}
          // leaveDelay={20}
          enterNextDelay={500}
        >
          <ListItem button
            id='settings-menu'
            className={['hydoorSettings', 'tenantSettings', 'dataImport'].includes(props.contentKey) || (props.contentKey === 'entities' && props.entitiesOverlayKey === 'USER_SETTINGS_DIALOG') ? clsx(classes.liSelected, classes.liSelectedBorder) : classes.li}
            style={{backgroundColor: 'unset'}}
            onClick={props.actOpenOptionsMenu}
            disabled={disableNavigation || props.loading}
          >
            <ListItemIcon
              className={['hydoorSettings', 'tenantSettings', 'dataImport'].includes(props.contentKey) || (props.contentKey === 'entities' && props.entitiesOverlayKey === 'USER_SETTINGS_DIALOG') ? classes.liIconSelected : classes.liIcon}
            >
              <WrmIcon
                icon='configuration'
                style={{width: 32, height: 32}}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>}
        {/* /End Popper Options */}
        <Tooltip
          title={!!profile ? (!!profile.auth0User.username ? profile.auth0User.username : profile.auth0User.user_id) : '-'}
          classes={{tooltip: classes.tooltip}}
          arrow
          placement='right'
          enterDelay={1000}
          // leaveDelay={20}
          enterNextDelay={500}
        >
          <ListItem button key='profile'
            id='mvb_profile'
            className={props.contentKey === 'profile' ? classes.liSelected : classes.li}
            style={{backgroundColor: 'unset'}}
            selected={props.contentKey === 'profile'}
            onClick={props.actOpenAvatarMenu}
            disabled={disableNavigation || props.loading}
          >
            <ListItemIcon
              className={props.contentKey === 'profile' ? classes.liIconSelected : classes.liIcon}
            >
              <WrmIcon
                icon='profile'
                style={{width: 32, height: 32}}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
      </List>
    </Fragment>
  )
}

export default MainVertical
