import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, makeStyles, Box } from '@material-ui/core'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0.25rem 1rem',
  },
  tabPanelTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '0.5rem 0',
    alignSelf: 'flex-start',
  },
  checkboxContainerMain: {
    marginLeft: '1rem',
  },
  divider: {
    border: 'none',
    height: '1px',
    margin: '0.5rem 0',
    width: '100%',
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
}))

interface LocaleProps {
  locale: string
  numberLocale: string
  handleChangeLanguage: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeNumberLocale: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const LocaleSettings: React.FC<LocaleProps> = ({
  locale,
  numberLocale,
  handleChangeLanguage,
  handleChangeNumberLocale,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // const f = useFormat()

  return (
    <Box>
      <Box className={classes.column}>
        <Typography align='left' className={classes.tabPanelTitle} component='h2'>
          {t('userSettings.localeSettings')}
        </Typography>
        <Box className={classes.checkboxContainerMain}>
          <CheckBoxNt checked={locale === 'el'} onChange={handleChangeLanguage} name='el' label='Ελληνικά' />
        </Box>
        <Box className={classes.checkboxContainerMain}>
          <CheckBoxNt checked={locale === 'en'} onChange={handleChangeLanguage} name='en' label='English' />
        </Box>
      </Box>
      {/* below is the UI for US:1159 Use decimal separator based on language the user is using.  */}
      {/* <Box className={classes.column}>
        <Typography
          align='left'
          className={classes.tabPanelTitle}
          component='h2'>
          {t('userSettings.numberLocaleSettings')}
        </Typography>
        <Box className={classes.checkboxContainerMain}>
              <CheckBoxNt
                checked={numberLocale === 'en'}
                onChange={handleChangeNumberLocale}
                name='en'
                label={'userSettings.numberLocaleSettings.dot'}
              />
        </Box>
        <Box className={classes.checkboxContainerMain}>
              <CheckBoxNt
                checked={numberLocale === 'el'}
                onChange={handleChangeNumberLocale}
                name='el'
                label={'userSettings.numberLocaleSettings.comma'}
              />
        </Box>
      </Box> */}
    </Box>
  )
}

export default LocaleSettings
