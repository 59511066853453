import React, { useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, } from 'recharts'

import { TicksCalculationTable, GraphColorsPool } from '../../utils/constants'
import WrmIcon from '../WrmIcon'
import GraphCustomTooltip from './GraphCustomTooltip'

const NUMBER_OF_GRAPH_X_AXIS_INTERVALS = 5

const useStyles = makeStyles((theme) => ({
  dlgpaper: {
    maxWidth: '80vw',
  },
  dlgtitleroot: {
    padding: '8px 16px',
  },
  dlgcontroot: {
    padding: theme.spacing(1),
    borderTop: `solid 1px ${theme.palette.tertiary.light}`,
    borderBottom: `solid 1px ${theme.palette.tertiary.light}`,
  },
  closeBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  subtitleLiteral: {
    color: theme.palette.tertiary.main
  },
  subtitleData: {
    color: '#000000'
  },
  graphToolbar: {
    position: 'absolute',
    backgroundColor: '#FAFAFA',
    zIndex: 1000,
  },
  arrowBtn: {
    textTransform: 'none',
    fontSize: '1.375rem',
    lineHeight: 1.375,
    fontWeight: 400,
    minHeight: theme.spacing(4),
    minWidth: theme.spacing(4),
    padding: 0,
    marginRight: 4,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  graphBtn: {
    padding: 8,
    marginRight: 4,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  lineIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: 'white',
  },
  barIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const MeasurementsGraph = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  // const [graphTypeIndex, setGraphTypeIndex] = useState(props.valueFieldToDisplay.chartType === 'BAR_CHART' ? 1 : 0)
  // const graphCnt = props.series.length > 1 ? 4 : 2

  const [graphType, setGraphType] = useState(props.valueFieldToDisplay?.chartType === 'BAR_CHART' ? 1 : 0)
  const [graphKindIndex, setGraphKindIndex] = useState(0)
  let lineKinds = [
    'all-line',
  ]
  if (props.series.length > 1) {
    lineKinds.push('perMeasurementType-line')
  }
  const barKinds = lineKinds.map(k => k.replace('-line', '-bar'))
  const graphKinds = [...lineKinds, ...barKinds]
  const graphCnt = barKinds.length
  const handleGraphTypeClick = (event) => {
    setGraphType((graphType + 1) % 2)
  }
  const handleGraphKindClick = (event, val) => {
    let newix = graphKindIndex + val
    if (newix === -1) {
      newix = graphCnt - 1
    } else {
      newix = newix % graphCnt
    }
    setGraphKindIndex(newix)
  }

  const caclulateMinDatetime = () => {
    let min = moment().valueOf()
    props.series.forEach(s => { if (moment(s.measurements[0].refDatetime).valueOf() < min) { min = moment(s.measurements[0].refDatetime).valueOf() } })
    console.log(`caclulateMinDatetime ${moment(min)}`)
    return min
  }
  const caclulateMaxDatetime = () => {
    let max = 0
    props.series.forEach(s => { if (moment(s.measurements[s.measurements.length - 1].refDatetime).valueOf() > max) { max = moment(s.measurements[s.measurements.length - 1].refDatetime).valueOf() } })
    console.log(`caclulateMinDatetime ${moment(max)}`)
    return max
  }

  const minRefDatetime = caclulateMinDatetime()
  const maxRefDatetime = caclulateMaxDatetime()

  const calculateXAxisTicks = () => {
    TicksCalculationTable.sort((a, b) => (a.inMillisecs > b.inMillisecs) ? 1 : (a.inMillisecs === b.inMillisecs) ? ((a.inMillisecs > b.inMillisecs) ? 1 : -1) : -1)
    let points = props.series.reduce((mp, s) => Math.max(s.measurements.length, mp), 0)
    let numberOfTicks = points > 250 ? Math.floor(points / 50) + 1 : NUMBER_OF_GRAPH_X_AXIS_INTERVALS
    let range = maxRefDatetime - minRefDatetime
    if (range > 0) {
      let result = TicksCalculationTable[20]
      for (const item of TicksCalculationTable) {
        if (Math.round(range / item.inMillisecs) <= numberOfTicks) {
          //FOUND!!!
          result = item
          numberOfTicks = Math.round(range / item.inMillisecs)
          break;
        }
      }
      console.log(`Time scale is : ${JSON.stringify(result)}`)
      tickSize = result
    } else {
      tickSize = TicksCalculationTable.find(tt => tt.timeUnit === 'DAY' && tt.value === 1)
    }

    let mmin = moment(minRefDatetime)
    let tst = moment([mmin.year(), mmin.month(), mmin.date()])
    while (tst.isSameOrBefore(mmin)) {
      tst.add(tickSize.inMillisecs, 'milliseconds')
    }
    tst.subtract(tickSize.inMillisecs, 'milliseconds')
    let initialTick = tst.valueOf()

    let ticks = Array(numberOfTicks + 1)
    let i
    for (i = 0; i <= numberOfTicks; i++) {
      ticks[i] = initialTick + i * tickSize.inMillisecs
    }
    return ticks
  }
  const prepareData = (measurements) => {
    let result = !measurements ? [] :
      measurements
      // .filter(m => !!m.refDatetime && !!(!!props.valueFieldToDisplay.level && props.valueFieldToDisplay.level !== '.' ? m[props.valueFieldToDisplay.level][props.valueFieldToDisplay.fieldname] : m[props.valueFieldToDisplay.fieldname]))
      .filter(m => {
        if (!m.refDatetime) return false
        let vt = props.valueFieldToDisplay
        let valHost = !!vt.level && vt.level !== '.' ? m[vt.level] : m
        let val = valHost[vt.fieldname]
        return !!val || vt.datatype === 'Boolean' || (vt.datatype === 'Number' && val === 0)
      })
      .map(m => { 
        const value = props.graphValueFormatFunction 
          ? !!props.valueFieldToDisplay.level && props.valueFieldToDisplay.level !== '.' ? props.graphValueFormatFunction(m[props.valueFieldToDisplay.level][props.valueFieldToDisplay.fieldname]) : props.graphValueFormatFunction(m[props.valueFieldToDisplay.fieldname])
          : !!props.valueFieldToDisplay.level && props.valueFieldToDisplay.level !== '.' ? m[props.valueFieldToDisplay.level][props.valueFieldToDisplay.fieldname] : m[props.valueFieldToDisplay.fieldname]
        return {
        refDatetime: moment(m.refDatetime).valueOf(),
        value: value,
        dateFormat: props.dateFormat,
        fieldName: props.fieldName
      }})
    // console.log(`prepareData ${JSON.stringify(result)}`)
    return result
  }
  const prepareMultiSeriesData = (series) => {
    let q: any = {}
    series.forEach(s => {
      s.measurements
      // .filter(m => !!m.refDatetime && !!(!!props.valueFieldToDisplay.level && props.valueFieldToDisplay.level !== '.' ? m[props.valueFieldToDisplay.level][props.valueFieldToDisplay.fieldname] : m[props.valueFieldToDisplay.fieldname]))
      .filter(m => {
        if (!m.refDatetime) return false
        let vt = props.valueFieldToDisplay
        let valHost = !!vt.level && vt.level !== '.' ? m[vt.level] : m
        let val = valHost[vt.fieldname]
        return !!val || vt.datatype === 'Boolean' || (vt.datatype === 'Number' && val === 0)
      })
      .forEach(m => {
        let refDatetime = moment(m.refDatetime).valueOf()
        if (!q[refDatetime]) {
          q[refDatetime] = {
            refDatetime: refDatetime,
            dateFormat: props.dateFormat,
            fieldName: props.fieldName,
          }
        }
        const value = props.graphValueFormatFunction 
          ? !!props.valueFieldToDisplay.level && props.valueFieldToDisplay.level !== '.' 
            ? props.graphValueFormatFunction(m[props.valueFieldToDisplay.level][props.valueFieldToDisplay.fieldname]) 
            : props.graphValueFormatFunction(m[props.valueFieldToDisplay.fieldname])
          : !!props.valueFieldToDisplay.level && props.valueFieldToDisplay.level !== '.' 
            ? m[props.valueFieldToDisplay.level][props.valueFieldToDisplay.fieldname] 
            : m[props.valueFieldToDisplay.fieldname]
          q[refDatetime][s._id] = value
      })
    })
    // console.log(`prepareMultiSeriesData ${JSON.stringify(q,null,2)}`)
    /** In case the feature is a switch, a landparcel or a consumer
     * they can have measurements from multiple sources, (multiple series per switch - one serie per switch slot)
     * So the measurements before returning them, otherwise the x axis that shows the dates will be wrong
     * (see bug #2140 )
     */
    if (
      props.feature?.properties?.featureType === 'switch' ||
      props.feature?.properties?.featureType === 'landparcel' ||
      !!props.feature?.code 
    ) {
      return Object.values(q).sort((a: any, b: any) => a.refDatetime - b.refDatetime)
    }
    return Object.values(q)
  }

  // const isChartType = (chartType) =>{
  //   let theSelectedChartType = 'LINE_CHART'
  //   if (props.valueFieldToDisplay.chartType==='BAR_CHART') theSelectedChartType = 'BAR_CHART'
  //   console.log(`Chart type is : ${theSelectedChartType} `)
  //   return theSelectedChartType === chartType
  // }
  const dateFormatter = secs => {
    if (!!tickSize) {
      return moment(secs).format(tickSize.format)
    } else if (props.dateFormat === 'datetime.format') {
      return moment(secs).format(t('datetime.format')!)
    } else {
      return moment(secs).format(t(props.dateFormat)!)
    }
  }

  // let data = props.series.length === 1 ? prepareData(props.series[0].measurements) : prepareMultiSeriesData(props.series)
  // console.log(`data ${JSON.stringify(data,null,2)}`)
  let tickSize
  let ticks = calculateXAxisTicks()
  const MesLineChart = (props) => {
    let style: any = {marginTop: 10}
    let width
    if (ticks.length > 2 * NUMBER_OF_GRAPH_X_AXIS_INTERVALS) {
      width = ticks.length * 150
      // if (props.series.length > 1) {
      //   style.left = Math.max(0, (width / 2 - 850 / 2))
      // }
    } else {
      width = props.width ?? 800
    }
    return (
    <LineChart
      width={width}
      height={props.height ?? 600}
      style={style}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='refDatetime' type='number' allowDuplicatedCategory={false} tickFormatter={dateFormatter} scale='time' ticks={ticks} domain={[minRefDatetime, maxRefDatetime]} />
      <YAxis dataKey='value' />
      <Tooltip content={<GraphCustomTooltip />} />
      <Legend align='left' margin={{top: 0, left: 48, right: 0, bottom: 0}} />
      {props.series.map((s, i) => (
      <Line
        key={s._id}
        stroke={props.color ?? GraphColorsPool[i % GraphColorsPool.length]}
        dot={false}
        dataKey='value'
        name={!!props.inferredFeatures?.length 
          ? `${props.inferredFeatures.find(wo=>wo.properties.code === s.featureCode)?.properties?.label || s.featureCode}-${s.scadaDeviceCode}`
          : `${t('msr.fld.' + s.measurementType.code, s.measurementType.label)}-${!!props.feature?.properties?.label ? props.feature?.properties?.label : props.feature?.firstname}`}
        data={prepareData(!!s.measurements ? s.measurements : [])}
      />
      ))}
    </LineChart>
  )}
  const MesBarChart = (props) => {
    let data = props.series.length === 1 ? prepareData(props.series[0].measurements) : prepareMultiSeriesData(props.series)
    let style: any = {marginTop: 10}
    let width
    let barCnt = data.length * props.series.length
    if (barCnt > 100) {
      width = barCnt * 10
      // if (props.series.length > 1) {
      //   style.left = Math.max(0, (width / 2 - 850 / 2))
      // }
    } else {
      width = props.width ?? 800
    }
    return (
    <BarChart
      width={width}
      height={props.height ?? 600}
      data={data}
      style={style}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='refDatetime' tickFormatter={dateFormatter}/>
      <YAxis />
      <Tooltip content={<GraphCustomTooltip />} />
      <Legend align='left' margin={{top: 0, left: 48, right: 0, bottom: 0}} />
      {props.series.length === 1 &&
      <Bar
        fill={props.color ?? GraphColorsPool[0]}
        dataKey='value'
        name={!!props.inferredFeatures?.length 
          ? `${props.inferredFeatures.find(wo=>wo.properties.code === props.series[0]?.featureCode)?.properties?.label || props.series[0].featureCode}-${props.series[0].scadaDeviceCode}`
          : `${t('msr.fld.' + props.series[0].measurementType.code, props.series[0].measurementType.label)}`}
      />}
      {props.series.length > 1 && props.series.map((s,i) => {
      console.log(`s._id ${s._id} s.measurementType.code ${s.measurementType.code}`)
      return (
      <Bar
        key={s._id}
        fill={props.color ?? GraphColorsPool[i % GraphColorsPool.length]}
        dataKey={s._id}
        name={!!props.inferredFeatures?.length 
          ? `${props.inferredFeatures.find(wo=>wo.properties.code === s.featureCode)?.properties?.label || s.featureCode}-${s.scadaDeviceCode}`
          : `${t('msr.fld.' + s.measurementType.code, s.measurementType.label)}-${!!props.feature?.properties?.label ?props.feature?.properties?.label : props.feature?.firstname}`}
      />
      )})
      }
    </BarChart>
  )}

  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => (reason === 'backdropClick') ? null : props.handleCloseDialog(null)}
      classes={{ paper: classes.dlgpaper }}
    >
      <DialogTitle
        classes={{ root: classes.dlgtitleroot }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.dlgcontroot }}
      >
        <Box
          px={1}
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          minHeight={45}
          className={classes.graphToolbar}
        >
          {graphCnt > 1 &&
          <Button
            className={classes.arrowBtn}
            onClick={(e) => handleGraphKindClick(e, -1)}
          >
            &lt;
          </Button>}
          {graphCnt > 1 &&
          <Button
            className={classes.arrowBtn}
            onClick={(e) => handleGraphKindClick(e, 1)}
          >
            &gt;
          </Button>}
          {graphType === 0 &&
          <IconButton
            className={classes.graphBtn}
            onClick={handleGraphTypeClick}
          >
            <WrmIcon
              icon='graph'
              className={classes.lineIcon}
            />
          </IconButton>}
          {graphType === 1 &&
          <IconButton
            className={classes.arrowBtn}
            onClick={handleGraphTypeClick}
          >
            <WrmIcon
              icon='consumption'
              className={classes.barIcon}
            />
          </IconButton>}
          <Box>
            <span className={classes.subtitleLiteral}> {t(`net.measurements`) + ' '}</span>
            {!!props.inferredFeatures?.length
              ? props.inferredFeatures.map((feature,index)=>{
                return <span key={index}>{feature.properties.label}{index < props.inferredFeatures?.length -1 && ", "}</span>
              })
              : <><span className={classes.subtitleData}>{props.subtitle[0] + ' '}</span><br/></>}
            <span className={classes.subtitleLiteral}>{' ' + t(`com.fromDate`) + ' '}</span>
            <span className={classes.subtitleData}>{props.subtitle[1] + ' '}</span>
            <span className={classes.subtitleLiteral}>{t(`com.toDate`) + ' '}</span>
            <span className={classes.subtitleData}>{props.subtitle[2]}</span>
          </Box>
        </Box>
        <Box height={50} />
        {graphKinds[graphKindIndex + graphType * graphCnt] === 'all-line' &&
        <Box
          p={1}
          display='flex'
          flexDirection='row'
          alignItems='center'
          // justifyContent='center'
          width={850}
          flexWrap='wrap'
        >
          <MesLineChart
            width={800}
            height={500}
            series={props.series}
            feature={props.feature}
            inferredFeatures={props.inferredFeatures}
            // valueFieldToDisplay={props.valueFieldToDisplay}
          />
        </Box>}
        {graphKinds[graphKindIndex + graphType * graphCnt] === 'all-bar' &&
        <Box
          p={1}
          display='flex'
          flexDirection='row'
          alignItems='center'
          // justifyContent='center'
          width={850}
          flexWrap='wrap'
        >
          <MesBarChart
            width={800}
            height={500}
            series={props.series}
            feature={props.feature}
            inferredFeatures={props.inferredFeatures}
            // valueFieldToDisplay={props.valueFieldToDisplay}
          />
        </Box>}
        {graphKinds[graphKindIndex + graphType * graphCnt] === 'perMeasurementType-line' &&
        <Box
          p={1}
          display='flex'
          flexDirection='row'
          // width={props.series.length < 2 ? 'calc(25vw)' : (props.series.length === 3 ? 'calc(45vw)' : 'calc(65vw)')}
          width={props.series.length < 2 ? 350 : (props.series.length === 2 ? 650 : 950)}
          flexWrap='wrap'
        >
        {props.series.map((s, i) => (
          <MesLineChart
            key={s._id}
            width={300}
            height={200}
            color={GraphColorsPool[i % GraphColorsPool.length]}
            series={[s]}
            feature={props.feature}
            inferredFeatures={props.inferredFeatures}
            // valueFieldToDisplay={props.valueFieldToDisplay}
          />
        ))}
        </Box>}
        {graphKinds[graphKindIndex + graphType * graphCnt] === 'perMeasurementType-bar' &&
        <Box
          p={1}
          display='flex'
          flexDirection='row'
          // width={props.series.length < 2 ? 'calc(25vw)' : (props.series.length === 3 ? 'calc(45vw)' : 'calc(65vw)')}
          width={props.series.length < 2 ? 350 : (props.series.length === 2 ? 650 : 950)}
          flexWrap='wrap'
        >
        {props.series.map((s, i) => (
          <MesBarChart
            key={s._id}
            width={300}
            height={200}
            color={GraphColorsPool[i % GraphColorsPool.length]}
            series={[s]}
            feature={props.feature}
            inferredFeatures={props.inferredFeatures}
            // valueFieldToDisplay={props.valueFieldToDisplay}
          />
        ))}
        </Box>}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.closeBtn}
          onClick={props.handleCloseDialog}
        >
          {t('btn.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MeasurementsGraph
