import {create} from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  Aqueduct,
  Hydrant,
  Hydrometer,
  Junction,
  Pump,
  Pumpcluster,
  Reservoir,
  SamplingPoint,
  Station,
  Switch,
  Tank,
  Valve,
  Landparcel,
  Wszone,
  Pipe,
  GenericFeature,
} from '../utils/types/networkElementTypes'
import { FTC } from '../utils/constants'

interface networkElementStore {
  aqueducts: Aqueduct[] | undefined
  hydrants: Hydrant[] | undefined
  hydrometers: Hydrometer[] | undefined
  junctions: Junction[] | undefined
  landparcels: Landparcel[] | undefined
  pipes: Pipe[] | undefined
  pumps: Pump[] | undefined
  pumpclusters: Pumpcluster[] | undefined
  reservoirs: Reservoir[] | undefined
  samplingPoints: SamplingPoint[] | undefined
  stations: Station[] | undefined
  switches: Switch[] | undefined
  tanks: Tank[] | undefined
  valves: Valve[] | undefined
  wszones: Wszone[] | undefined
  FTC: typeof FTC
  doDeleteFreeGeometry: boolean
  setDoDeleteFreeGeometry: (val: boolean) => void
  getConnectedFeatures: (feature: GenericFeature, maxSize?: number) => any[]
  getIncomingPipes: (feature: GenericFeature) => any[]
  getOutgoingPipes: (feature: GenericFeature) => any[]
  setItems: (key: string, items: any) => void
  addItem: (key: string, item: any) => void
  addItems: (key: string, item: any) => void
  updateItem: (key: string, item: any) => void
  deleteItem: (key: string, item: any) => void
}

const useNetworkElementStore = create<networkElementStore>()(
  devtools(
    (set, get) => ({
      aqueducts: undefined,
      hydrants: undefined,
      hydrometers: undefined,
      junctions: undefined,
      landparcels: undefined,
      pipes: undefined,
      pumps: undefined,
      pumpclusters: undefined,
      reservoirs: undefined,
      samplingPoints: undefined,
      stations: undefined,
      switches: undefined,
      tanks: undefined,
      valves: undefined,
      wszones: undefined,
      FTC: FTC,
      doDeleteFreeGeometry: false,
      setDoDeleteFreeGeometry: val =>
        set(state => ({ doDeleteFreeGeometry: val })),
      setItems: (collectionKey, items) =>
        set(state => {
          let part = {}
          part[collectionKey] = items
          return part
        }),
      addItem: (collectionKey, feature) =>
        set(state => {
          let part: any = {}
          part[collectionKey] = state[collectionKey] ?? []
          part[collectionKey] = state[collectionKey].concat(feature)
          return part
        }),
      addItems: (collectionKey, features) =>
        set(state => {
          let part = {}
          part[collectionKey] = state[collectionKey] ?? []
          part[collectionKey] = part[collectionKey].concat(features)
          return part
        }),
      updateItem: (collectionKey, feature) =>
        set(state => {
          let part: any = {}
          part[collectionKey] = state[collectionKey] ?? []
          part[collectionKey] = part[collectionKey].map(it =>
            it._id === feature._id ? feature : it
          )
          return part
        }),
      deleteItem: (collectionKey, feature) =>
        set(state => {
          let part = {}
          part[collectionKey] = state[collectionKey] ?? []
          part[collectionKey] = part[collectionKey].filter(
            it => it._id !== feature._id
          )
          return part
        }),
      getConnectedFeatures: (feature: GenericFeature, maxSize?: number): any[] => {
        let featureType = feature.properties.featureType
        let connectedFeatures: any[] = []
        const FTCx = useNetworkElementStore.getState().FTC
        switch (featureType) {
          case 'wszone':
            Object.keys(FTCx).forEach(fcode => {
              if (!!maxSize && connectedFeatures.length > maxSize) return
              let ftc = FTCx[fcode]
              let cfs = get()[ftc.collection]?.filter(
                f => f.properties.wszoneCode === feature.properties.code
              )
              if (!cfs) cfs = []
              if (!!maxSize) {
                connectedFeatures = [
                  ...connectedFeatures,
                  ...cfs.slice(0, maxSize - connectedFeatures.length),
                ]
              } else {
                connectedFeatures = [...connectedFeatures, ...cfs]
              }
            })
            break
          case 'pipe':
            let ftc = FTCx[feature?.properties?.inNodeFeatureType]
            if (!!ftc) {
              let inf = get()[ftc.collection]?.find(
                f => f.properties.code === feature.properties.inNodeCode
              )
              if (!!inf) {
                connectedFeatures.push(inf)
              }
            }
            ftc = FTCx[feature.properties.outNodeFeatureType]
            if (!!ftc) {
              let outf = get()[ftc.collection]?.find(
                f => f.properties.code === feature.properties.outNodeCode
              )
              if (!!outf) {
                connectedFeatures.push(outf)
              }
            }
            break
          default:
            let incomingPipes = get().getIncomingPipes(feature)
            let outgoingPipes = get().getOutgoingPipes(feature)
            connectedFeatures = [...incomingPipes, ...outgoingPipes]
            break
        }
        return connectedFeatures
      },
      getIncomingPipes: (feature: GenericFeature) => {
        let incomingPipes = get().pipes?.filter(
          p => (p.properties.outNodeCode === feature.properties.code
              && p.properties.outNodeFeatureType === feature.properties.featureType)
        )
        return incomingPipes ?? []
      },
      getOutgoingPipes: feature => {
        let outgoingPipes = get().pipes?.filter(
          p =>
            p.properties.inNodeCode === feature.properties.code &&
            p.properties.inNodeFeatureType === feature.properties.featureType
        )
        return outgoingPipes ?? []
      },
    }),
    {
      name: 'networkElementStore',
      enabled: process.env.NODE_ENV === 'development' ? true : false,
    }
  )
)

export { useNetworkElementStore }

//this function was in <Main> moved it here just in case-
//if it becomes relevant it sould be added as a method in useNetworkElementStore
// const getConnectedByPipeFeatures = (feature, maxSize?) => {
//   let featureType = feature.properties.featureType
//   let connectedFeatures: any[] = []
//   switch (featureType) {
//     case 'wszone':
//       break
//     case 'pipe':
//       break
//     default:
//       let incomingPipes = getIncomingPipes(feature)
//       incomingPipes.forEach(pipe => {
//         if (!!maxSize && connectedFeatures.length > maxSize) return
//         let ftc = FTCx[pipe.properties.inNodeFeatureType]
//         if (!!ftc) {
//           let inf = ftc.state.features.find(
//             f => f.properties.code === pipe.properties.inNodeCode
//           )
//           if (!!inf) {
//             connectedFeatures.push(inf)
//           }
//         }
//       })
//       let outgoingPipes = getOutgoingPipes(feature)
//       outgoingPipes.forEach(pipe => {
//         if (!!maxSize && connectedFeatures.length > maxSize) return
//         let ftc = FTCx[pipe.properties.outNodeFeatureType]
//         if (!!ftc) {
//           let outf = ftc.state.features.find(
//             f => f.properties.code === pipe.properties.outNodeCode
//           )
//           if (!!outf) {
//             connectedFeatures.push(outf)
//           }
//         }
//       })
//       break
//   }
//   return connectedFeatures
// }
