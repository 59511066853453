import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const rootStyle = {
  padding: 6,
  backgroundColor: '#fff',
  border: '1px solid #ccc',
}
const rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
}

const GraphCustomTooltip = props => {
  //console.log('GraphCustomTooltip')
  const { t } = useTranslation()
  const { active, payload } = props
  if (active) {
    const firstData = payload && payload.length ? payload[0].payload : null
    // console.log(`GraphCustomTooltip payload ${JSON.stringify(payload,null,2)}`)
    let dateVal = ' -- '
    if (firstData) {
      if (firstData.refDatetime) {
        dateVal = moment(firstData.refDatetime).format(t(firstData.dateFormat)!)
      } else if (firstData.timeRef || firstData.timeRef === 0) {
        if (!!props.valueFormatter) {
          dateVal = props.valueFormatter(firstData.timeRef, 'timeRef')
        } else {
          dateVal = firstData.timeRef
        }
      }
    }
    return (
      <div className='area-chart-tooltip' style={rootStyle}>
        <div>
          {/*firstData ? moment(firstData.refDatetime).format(t(firstData.dateFormat)) : ' -- '*/}
          {!props.hideDateVal && dateVal}
        </div>
        {!!payload && payload.map((pl,i) => {
          let val = ' -- '
          if (!!pl.payload) {
            val = pl.payload[pl.dataKey]
            if (!!props.valueFormatter) {
              val = props.valueFormatter(val, pl.dataKey)
            }
          }
          return (
        <div key={i} style={rowStyle}>
          {`${pl.name}: `}
          <strong style={{color: pl.color}}>{val}</strong>
        </div>
        )})}
      </div>
    )
  }
  return null
}

export default GraphCustomTooltip
