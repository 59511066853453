import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Box,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  FormControl,
  IconButton,
} from '@material-ui/core'
import { CloseRounded, CheckRounded } from '@material-ui/icons'
import WrmIcon from '../WrmIcon'
import RadioBtnNt from '../reusableComponents/RadioBtnNt'

const useStyles = makeStyles(theme => ({
  popUpFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnRound: {
    margin: '0 0.5rem',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;',
  },
}))

export default function FlowDirectionPopUp(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  const [flowDirection, setFlowDirection] = useState<'normal' | 'reverse'>('normal')
  useEffect(()=>{
    const direction = props?.feature?.properties?.flowDirection
    if (direction) {
      setFlowDirection(props.feature.properties.flowDirection)
    }
  },[props?.feature?.properties])
  useEffect(() => {
    if (props.feature.properties.flowDirection && props.isOpen) {
      props.toggleLineArrows(props.feature, flowDirection)
    }
  }, [flowDirection,props.feature,props.isOpen])

  const handleChange = event => {
    setFlowDirection(event.target.value)
  }

  const handleSubmit = e => {
    /**Update flowDirection and reverse in/out Nodes */
    const featureClone = {...props.feature.properties}
    props.feature.properties.flowDirection = flowDirection
    if (featureClone?.flowDirection && (flowDirection !== featureClone.flowDirection)) {
      props.feature.properties.inNodeCode=   featureClone.outNodeCode || null
      props.feature.properties.inNodeFeatureType=   featureClone.outNodeFeatureType || null
      props.feature.properties.outNodeCode=  featureClone.inNodeCode || null
      props.feature.properties.outNodeFeatureType=  featureClone.inNodeFeatureType || null
    }
    props.hideLineArrows(props.feature)
    props.saveFlow(props.feature)
    props.setFlowPopupIsOpen(false)
  }

  const handleCancel = e => {
    props.hideLineArrows(props.feature)
    props.setFlowPopupIsOpen(false)
  }

  return (
    <Box>
      <Typography variant='subtitle2' style={{ margin: '0' }}>
        {t('map.createPipe.flowDirection')}
      </Typography>
      <Box>
        <FormControl component='fieldset'>
          <RadioGroup
            aria-label='flow'
            name='flow-direction'
            value={flowDirection}
            onChange={handleChange}>
            <FormControlLabel
              value='normal'
              control={<RadioBtnNt />}
              label={
                <>
                  <WrmIcon
                    icon='flow'
                    style={{
                      width: 24,
                      height: 24,
                      verticalAlign: 'middle',
                      margin: '0 1rem',
                    }}
                  />
                  {t('map.createPipe.oppositeA')}
                </>
              }
            />
            <FormControlLabel
              value='reverse'
              control={<RadioBtnNt />}
              label={
                <>
                  <WrmIcon
                    icon='flowReverse'
                    style={{
                      width: 24,
                      height: 24,
                      verticalAlign: 'middle',
                      margin: '0 1rem',
                    }}
                  />
                  {t('map.createPipe.towardsA')}
                </>
              }
            />
          </RadioGroup>
        </FormControl>

        <Box className={classes.popUpFooter}>
          <IconButton
            onClick={handleCancel}
            aria-label='cancel'
            size='small'
            className={classes.btnRound}>
            <CloseRounded fontSize='inherit' />
          </IconButton>
          <IconButton
            disabled={!props.feature}
            onClick={handleSubmit}
            aria-label='accept'
            size='small'
            className={classes.btnRound}>
            <CheckRounded fontSize='small' style={{ color: '#65DEDE' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
