import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'

import MdLabel from './MdLabel'
import MdValue from './MdValue'
import MdNotes from './MdNotes'
import MdAttachments from './MdAttachments'
import WrmIcon from '../WrmIcon'

const useStyles = makeStyles(theme => ({
  detail: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    padding: 16,
    marginRight: 4, //or else boxShadow effect is lost
    minWidth: 520,
    maxWidth: 520,
  },
  infoTab: {
    overflow: 'auto'
  },
  infoBar: {
  },
  infoList: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    // height: 'calc(100vh - 370px)',
    overflow: 'auto'
    // overflowX: 'auto'
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 'normal',
    minWidth: 90,
    maxWidth: 200,
    color: theme.palette.tertiary.main,
  },
  tabLabelSelected: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  entIcon: {
    width: 18,
    height: 18,
    color: theme.palette.primary.light,
  },
  trashBtn: {
    padding: '6px 8px',
  },
  blueBtn: {
    textTransform: 'none',
    minWidth: 100,
    width: theme.spacing(9),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    padding: '17px 0',
    borderTop: `1px solid ${theme.palette.custom.grey2}`,
  },
  liNoTop: {
    display: 'flex',
    padding: '18px 0',
  },
  liDense: {
    padding: '7px 0',
  },
  lilab: {
    minWidth: 170,
    maxWidth: 170,
  },
  lilabprimary: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: theme.palette.tertiary.dark,
  },
  lival: {
    minWidth: 250,
    maxWidth: 250,
    paddingLeft: 8,
    overflowWrap: 'break-word',
  },
  livalprimary: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: theme.palette.tertiary.dark,
  },
}))

const MdDetail = (props) => {
  const { t }: { t: any } = useTranslation()
  const classes = useStyles()

  const mcolumns = props.meta.columns

  //tabs
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const handleTabChange = (event, tabIndex) => {
    // console.log(`tabIndex ${tabIndex}`)
    setActiveTabIndex(tabIndex)
    if (!!props.customDetails && !!props.onCustomTabChange && props.customDetails.findIndex(cd => cd.code === props.meta.tabs[tabIndex]) > -1) {
      // props.onCustomTabChange(props.customDetails[tabIndex - 2].code)
      props.onCustomTabChange(props.customDetails.find(cd => cd.code === props.meta.tabs[tabIndex]).code)
    }
  }
  let tabsinfo = [
    {code: 'info', label: t('md.info')},
    {code: 'notes', label: !!props.selectedDataItem ? t('md.notesWithCounter', {val: props.selectedDataItem.notes?.length ?? 0}) : t('md.notes')}
  ]
  if (!!props.meta.tabs) {
    tabsinfo = props.meta.tabs.map(tb => {
      if (tb === 'info') return {code: 'info', label: t('md.info')}
      if (tb === 'notes') return {code: 'notes', label: !!props.selectedDataItem ? t('md.notesWithCounter', {val: props.selectedDataItem.notes?.length ?? 0}) : t('md.notes')}
      if (tb === 'attachments') return {code: 'attachments', label: !!props.selectedDataItem ? t('md.attachmentsWithCounter', {val: props.selectedDataItem.attachments?.length ?? 0}) : t('md.attachments')}
      let customtab = props.customDetails.find(d => d.code === tb)
      return {code: customtab.code, label: customtab.label}
    })
    if (props.meta.hideTabs && typeof props.meta.hideTabs === 'function') {
      tabsinfo = tabsinfo.filter(t => !props.meta.hideTabs(props.selectedDataItem, t.code))
    }
  }

  return (
    <Box
      className={classes.detail}
      style={!props.meta.detailsWidth ? undefined : {minWidth: props.meta.detailsWidth, maxWidth: props.meta.detailsWidth}}
      boxShadow={2}
    >
    {tabsinfo.length > 1 && 
      <Tabs
        value={activeTabIndex}
        indicatorColor='primary'
        textColor='inherit'
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleTabChange}
      >
      {tabsinfo.map((tb, i) => (
        <Tab
          key={tb.code}
          label={tb.label}
          className={i === activeTabIndex ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}
        />
      ))}
      </Tabs>
    }
    {tabsinfo[activeTabIndex].code === 'info' && !!props.selectedDataItem &&
      <Box p={2} mt={1}
        className={classes.infoTab}
        display='flex'
        flexGrow={1}
        flexDirection='column'
      >
        <Box p={2} mb={1}
          className={classes.infoBar}
          display='flex'
          alignItems='center'
        >
          <Box mr='auto' display='flex'>
            {props.idfld !== '_id' ? props.selectedDataItem[props.idfld] : props.selectedDataItem._id.substring(18)}
          </Box>
          {!!props.customInfoButtons && (
            <Box>
              {props.customInfoButtons}
            </Box>
          )}
          {!props.meta.editOnMaster && !(typeof props.meta.noDelete === 'function' ? props.meta.noDelete(props.selectedDataItem) : props.meta.noDelete) &&
          <Box ml={2} display='flex'>
            <IconButton
              className={classes.trashBtn}
              onClick={() => props.handleOpenConfirmDialog(
                'confirmDeleteDetail',
                !props.meta.thisEntity ? t('md.confirm.delete.item.msg') : `${t('md.confirm.delete.item.msg.typed')} ${t(props.meta.thisEntity)}${t('com.question.mark')}`,
                props.rowLabel
              )}
            >
              <WrmIcon icon='trash' />
            </IconButton>
          </Box>}
          {!props.meta.editOnMaster && !(typeof props.meta.noEdit === 'function' ? props.meta.noEdit(props.selectedDataItem) : props.meta.noEdit) &&
          <Box ml={2} display='flex'>
            <Button
              className={classes.blueBtn}
              onClick={props.handleEditDetail}
            >
              {t('btn.editDetails')}
            </Button>
          </Box>}
        </Box>
        <Box p={1} mt={1}
          className={classes.infoList}
        >
          <Box
            className={classes.list}
          >
            {!!props.selectedDataItem && mcolumns
            .filter(m => typeof m.hidden === 'function' ? !m.hidden(props.selectedDataItem, m) : !m.hidden)
            .map((m, i) => {
            let val = props.processRawValue(props.selectedDataItem[m.code], m)
            let label: string = t(m.label)
            if (m.unit) {
              label = `${label} (${t(m.unit)})`
            }
            let styledVal = val
            if (!!m.className && m.doStyleDetails) {
              if (typeof m.className === 'function') {
                styledVal = (<div className={m.className(props.selectedDataItem, m)}>{val}</div>)
              } else {
                styledVal = (<div className={m.className}>{val}</div>)
              }
            }
            return(
            <Box key={m.code} className={props.meta.denseDetails ? clsx((i === 0 ? classes.liNoTop : classes.li), classes.liDense) : i === 0 ? classes.liNoTop : classes.li}>
              <MdLabel
                labelText={label}
                tipText={m.tip ? t(`${m.label}.tip`) : undefined}
              />
              {m.isFeatureTypeField && m.showAsIcon && !!props.selectedDataItem[m.code] ? (
              <Tooltip title={val} enterDelay={1000} leaveDelay={10} placement='left'>
                <Box ml='auto' display='flex'>
                  <WrmIcon
                    icon={props.FTC[props.selectedDataItem[m.code]].icon}
                    className={classes.entIcon}
                  />
                </Box>
              </Tooltip>
              ) : (!!m.className && m.doStyleDetails ? (
              <MdValue value={styledVal} />
              ) : (
              <MdValue value={val} />
              ))} 
            </Box>
          )})}
          </Box>
        </Box>
      </Box>
      }
    {tabsinfo[activeTabIndex].code === 'notes' && !!props.selectedDataItem &&
      <MdNotes
        noEditNote={props.meta.noEditNote}
        users={props.users}
        showAlert={props.showAlert}
        selectedDataItem={props.selectedDataItem}
        actSaveDetailNote={props.actSaveDetailNote}
        actDeleteDetailNote={props.actDeleteDetailNote}
      />
    }
    {tabsinfo[activeTabIndex].code === 'attachments' && !!props.selectedDataItem &&
      <MdAttachments 
        users={props.users}
        showAlert={props.showAlert}
        noEditAttachment={props.meta.noEditAttachment}
        selectedDataItem={props.selectedDataItem}
        attachments={props.selectedDataItem?.attachments || []}
        actSaveDetailAttachment={props.actSaveDetailAttachment}
        actDeleteDetailAttachment={props.actDeleteDetailAttachment}
        attachmentAcceptedExtensions={props.meta?.attachmentAcceptedExtensions}
        maximumNumberOfAttachmentsPerElement={props.meta?.maximumNumberOfAttachmentsPerElement}
      />
    }
    {!!props.customDetails && !!props.selectedDataItem && props.customDetails.map((cd, i) => {
      return (tabsinfo[activeTabIndex].code === cd.code ? (
      <Box
        key={cd.code}
        mt={1}
        display='flex'
        justifyContent='center'
        flexGrow={1}
        height='calc(100vh - 200px)'
      >
        {cd.component}
      </Box>
      ) : null)
    })}
    </Box>
  )

}

export default MdDetail
