export interface Action {
    payload: any
    type: string
  }

export interface ReportType {
  code: string;
  transKey: string;
  label?: string;
}

export interface Irr_InverterReportInterface {
  avrgPowerConsumption: ReportType;
  avrgWaterSupply: ReportType;
  avrgStartStop: ReportType;
  avrgPressure: ReportType;
  avrgRPM: ReportType;
}

export type AlertSeverity = 'I' | 'W' | 'S' | 'E'

export enum MobileScreens {
  automationCenterMobile= 'automationCenterMobile',
  pumpManagementMobile= 'pumpManagementMobile',
  switchManagementMobile= 'switchManagementMobile',
  irrigationProgramMobile= 'irrigationProgramMobile',
  pumpSettingsMobile= 'pumpSettingsMobile',
  newNotificationsMobile= 'newNotificationsMobile',
  readNotificationsMobile= 'readNotificationsMobile',
  userSettingsMobile= 'userSettingsMobile',
}

export enum IRRI_PROG_DIALOG_TITLES {
  CONFIRM_DELETE_SLOT = 'propmt.confirm.delete',
  SAVE_CHANGES_TO_IRRIPROG = 'propmt.save.changes.to.irriProg',
  CONFIRM_CLONE_CYCLE = 'prompt.confirm.clone.cycle',
  CONFIRM_DELETE_CYCLE = 'prompt.confirm.delete.cycle',
  CONFIRM_DELETE_CURRENT_CYCLE = 'irriProgSlot.msg.deleteCycle.confirmation',
  CONFIRM_UPLOAD_CYCLE = 'prompt.confirm.upload.cycle',
  EDIT_SLOT = 'md.edit.title',
  NEW_SLOT = 'md.add.title',
  SELECT_CYCLE_START_DATE_FOR_NEW_IRRIPROG = 'irriProgSlot.editor.dialog.title',
  EMPTY = '',
}