import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  popperRoot: {
    maxHeight: '90vh',
    overflowY: 'auto',
    zIndex: 2000,
  },
  popperArrow: {
    position: 'absolute',
    fontSize: '0.5rem',
    marginLeft: '-0.5rem',

    bottom: '1.0rem',

    '&:before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      borderStyle: 'solid',
      borderWidth: '0.5rem 0.5rem 0.5rem 0',
      borderColor: 'transparent white transparent transparent',
    },

  },
}))

const Pop = (props) => {
  const ref = React.createRef<HTMLElement>()
  const classes = useStyles()

  return (!!props.anchorEl ?
    <Popper
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      placement={'right-end'}
      className={classes.popperRoot}
      modifiers={{
        arrow: {
          enabled: true,
          element: `#${props.id}`,
        },
      }}
    >
      <span id={props.id} ref={ref} className={classes.popperArrow} />
        <Paper>
          {props.children}
        </Paper>
    </Popper>
  : null)

}

export default Pop
