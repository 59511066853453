import BtnGenericNt from './BtnGenericNt'

interface BtnContainedProps {
  /** You can pass the translation string directly, since the title is passed into a t(title) internally in ButtonHDR  */
  title: string
  /** 'small' | 'medium' | 'large' */
  size?: 'small' | 'medium' | 'large'
  /** Applied the disabled status to the button */
  disabled?: boolean
  /** onClick function that fires when the button is pressed */
  onClick: (...args: any) => void
  /** A custom css style object that can be applied to the button */
  style?: React.CSSProperties
}

/**Renders a styled Contained Button (under the hood it's a ButtonGeneric with fewer props ) */
export function BtnContained(props: BtnContainedProps) {
  return <BtnGenericNt {...props} />
}

export default BtnContained
