import { useCommonStore } from "../state/commonStore"

const NotificationMsgService = (apiClient?) => {
  if (!apiClient) {
    apiClient = useCommonStore.getState().apiClient
  }
  const path = 'notificationMsg/'

  const getConsumerNotificationMsgs = (consumer_id, filter?) => {
    let data: any = {
      consumer_id: consumer_id,
      operation: 'getConsumerNotificationMsgs',
    }
    if (!!filter) {
      data.filter = filter
    }
    return sendRequest(data)
  }
  const getPersonnelNotificationMsgs = (personnel_id: string, filter?: any, options?: any) => {
    let data: any = {
      personnel_id: personnel_id,
      operation: 'getPersonnelNotificationMsgs',
    }
    if (!!filter) {
      data.filter = filter
    }
    if (!!options) {
      let backendOptions = {...options}
      delete backendOptions.cachePromise
      delete backendOptions.doReload
      // delete backendOptions.doNotStore
      if (Object.keys(backendOptions).length > 0) {
        data.options = backendOptions
      }
    }
    return sendRequest(data, !!options?.cachePromise)
  }

  /** This method performs a double query on the Notifications and NotificationsMSG collections,
   * @param filter - an object that will have the mongoose filter for querying the Notification collection
   * @param options - an object 
   * @param `options.limit` - limits (how many documents are fetched, -1 equals to infinite) if undefined defaults to 500 at the backend
   * @param `options.personnel_id` - the id of the personnel, it will be used at the NotificationsMSG query
   */
  const getFilteredNotificationMsgsByPersonnel = (filter?: any, options?: any) => {
    let data: any = {
      operation: 'getFilteredNotificationMsgsByPersonnel',
    }
    if (!!filter) {
      data.filter = filter
    }
    if (!!options) {
      let backendOptions = {...options}
      delete backendOptions.cachePromise
      delete backendOptions.doReload
      if (Object.keys(backendOptions).length > 0) {
        data.options = backendOptions
      }
    }
    return sendRequest(data, !!options?.cachePromise)
  }
  const getNotificationMsgs = (filter?) => {
    let data: any = {
      operation: 'getNotificationMsgs',
    }
    if (!!filter) {
      data.filter = filter
    }
    return sendRequest(data)
  }
  const saveNotificationMsg = (notificationMsg, notification, consumer) => {
    let data: any = {
      notificationMsg: notificationMsg,
      notification: notification,
      consumer: consumer,
    }
    let isNewNotificationMsg = !notificationMsg._id
    if (isNewNotificationMsg) {
      data.operation = 'saveNotificationMsg'
    } else {
      data.operation = 'updateNotificationMsg'
    }
    return sendRequest(data)
  }
  const deleteNotificationMsg = (notificationMsg) => {
    let data = {
      operation: 'deleteNotificationMsg',
      notificationMsg: notificationMsg,
    }
    return sendRequest(data)
  }

  const updateNotificationMsg = (notificationMsg) => {
    let data = {
      operation: 'updateNotificationMsg',
      notificationMsg: notificationMsg,
    }
    return sendRequest(data)
  }

  /** Marks multiple notificationMsgs as 'read' - Updates notificationMsg (webReadDate). */
  const updateMultipleNotificationMsgs = (notificationMsgIds:string[], webReadDate:string | Date, filter?)=>{
    let data: any = {
      operation: 'updateMultipleNotificationMsgs',
      notificationMsgIds: notificationMsgIds,
      webReadDate: webReadDate,
    }
    if (!!filter) {
      data.filter = filter
    }
    return sendRequest(data)
  }
  const sendRequest = (data: any, cachePromise?: boolean) => {
    return apiClient.sendRequest(data, cachePromise, path, null, true)
  }

  return({
    getConsumerNotificationMsgs,
    getPersonnelNotificationMsgs,
    getFilteredNotificationMsgsByPersonnel,
    getNotificationMsgs: getNotificationMsgs,
    saveNotificationMsg: saveNotificationMsg,
    deleteNotificationMsg: deleteNotificationMsg,
    updateNotificationMsg: updateNotificationMsg,
    updateMultipleNotificationMsgs: updateMultipleNotificationMsgs,
  })

}

export default NotificationMsgService
