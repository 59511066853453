import React, { useState } from 'react'
import { Box, makeStyles, createStyles, Theme, AppBar, Toolbar, IconButton, Badge, SvgIcon } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import WrmIcon from '../WrmIcon'
import TextNt from '../reusableComponents/TextNt'
import NavDrawerMobile from './NavDrawerMobile'
import { MobileScreens } from '../../utils/types/commonTypes'
import { useUiStore } from '../../state/uiStore'
import BackIcon from './svgIcons/BackIcon'
import BurgerIcon from './svgIcons/BurgerIcon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.tertiary.dark,
      height: 56,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      justifyItems: 'start',
    },
    navBar: {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      height: 40,
      display: 'grid',
      alignItems: 'center',
      color: theme.palette.common.white,
    },
    navBarWithIcon: {
      gridTemplateColumns: '50px auto 50px',
    },
    iconButton: {
      alignSelf: 'center',
      justifySelf: 'flex-start',
      height: 40,
      width: 40,
      marginLeft: 8,
      userSelect: 'none' /* Standard syntax */,
      '-webkit-user-select': 'none' /* WebKit (Safari, Chrome) */,
      '-moz-user-select': 'none' /* Mozilla Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
    },
    brandLogo: {
      justifySelf: 'center',
    },
  })
)

export default function AppBarMobile({ logOut, unreadNotificationsCnt }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const currentScreen = useUiStore(state => state.contentKeyLev2)
  const setCurrentScreen = useUiStore(state => state.setContentKeyLev2)
  const previousScreens = useUiStore(state => state.previousScreens)
  const setPreviousScreens = useUiStore(state => state.setPreviousScreens)
  const selectedEntity = useUiStore(state => state.selectedEntity)
  const filterNotificationsBySelectedElement = useUiStore(state => state.filterNotificationsBySelectedElement)

  /**Drawer props */
  const [navDrawerOpen, setNavDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) => {
    setNavDrawerOpen(open)
  }

  const handleBackButton = () => {
    setCurrentScreen(previousScreens.at(-1) || MobileScreens.automationCenterMobile)
    setPreviousScreens(previousScreens.slice(0, -1))
  }

  return (
    <>
      <AppBar>
        <Toolbar className={classes.appBar}>
          <Box>
            <IconButton size='medium' edge='start' color='primary' aria-label='menu' onClick={() => toggleDrawer(true)}>
              <Badge
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                color='error'
                overlap='rectangular'
                variant='dot'
                invisible={!unreadNotificationsCnt}
              >
                <BurgerIcon />
              </Badge>
            </IconButton>
          </Box>
          <IconButton
            size='medium'
            className={classes.brandLogo}
            color='primary'
            aria-label='menu'
            onClick={() => setCurrentScreen(MobileScreens.automationCenterMobile)}
          >
            <WrmIcon
              icon='tint'
              style={{
                width: 18,
                height: 26,
              }}
            />
          </IconButton>
          <Box></Box>
        </Toolbar>
        <Box
          className={
            currentScreen !== MobileScreens.automationCenterMobile
              ? clsx(classes.navBar, classes.navBarWithIcon)
              : classes.navBar
          }
        >
          {/* The back-IconButton should not be displayed at the AutomationCenterScreen  */}
          {currentScreen !== MobileScreens.automationCenterMobile && (
            <IconButton className={classes.iconButton} onClick={handleBackButton}>
              <BackIcon />
            </IconButton>
          )}
          <TextNt variant='h2'>
            {([MobileScreens.pumpManagementMobile, MobileScreens.pumpSettingsMobile, MobileScreens.switchManagementMobile, MobileScreens.irrigationProgramMobile].includes(
              currentScreen as MobileScreens
            ) ||
              (currentScreen === MobileScreens.newNotificationsMobile && filterNotificationsBySelectedElement)) &&
            !!selectedEntity
              ? t(currentScreen) + ` ${selectedEntity?.properties?.label || selectedEntity?.properties?.code}`
              : t(currentScreen)}
          </TextNt>
        </Box>
      </AppBar>

      <NavDrawerMobile isOpen={navDrawerOpen} toggleDrawer={toggleDrawer} logOut={logOut} />
    </>
  )
}
