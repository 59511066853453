import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SvgIcon from '@material-ui/core/SvgIcon'
import Button from '@material-ui/core/Button'
import {ArrowBackRounded as ArrowBackIcon } from '@material-ui/icons'

import { FTC } from '../utils/constants'
import { alphabeticalSorter } from '../utils/helpers'
import useFeatureCollection from '../hooks/useFeatureCollection'
import { useCommonStore } from '../state/commonStore'
import { useUiStore } from '../state/uiStore'
import WrmIcon from './WrmIcon'
import EntitiesFilterDialog from './entitiesfilterDialog/EntitiesfilterDialog'

const useStyles = makeStyles(theme => ({
  headerli: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  litext: {
    fontSize: '0.875rem',
    color: '#828282',
  },
  headedlitext: {
    fontSize: '.92rem',
    color: theme.palette.tertiary.main,
    fontWeight: 800,
  },
  secondlitext: {
    fontSize: '0.75rem',
  },
  entitiesIconInner: {
    fill: 'none',
    width: 21,
    height: 18,
    marginRight: '.8rem',
    marginLeft: '-2.75rem',
  },
  entitiesIconOuter: {
    fill: 'none',
    width: 34,
    height: 34,
    marginRight: '1.0rem',
  },
  searchField: {
    margin: '0.75rem',
  },
  faIcon: {
    fontSize: '1.0rem',
    marginRight: '0rem',
  },
  arrowIcon: {
    fontSize: '1.0rem',
    marginRight: '0.5rem',
  },
  searchIcon: {
    fontSize: '1.0rem',
    marginRight: '-0.375rem',
  },
  arrowBtn: {
    marginRight:'0.25rem'
  },
  searchInput: {
    padding: '0.50rem',
  },
  item: {
    padding: '0px 8px',
    marginTop: '4px',
    alignItems: 'stretch',
  },
  itemSelected: {
    backgroundColor: 'inherit',
  },
  itemBtn: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 8px 0px 3px',
  },
  itemBtnSelected: {
    background: 'rgba(179, 251, 251, 0.4)',/* 0.2 */
    borderRadius: '5px',
  },
  iconBtn: {
    borderRadius: '5px',
    padding: '0.25rem',
    marginRight: '0.25rem',
    minWidth: '1.375rem',
    color: '#BABABA',
  },
  entIcon: {
    color: theme.palette.primary.light,
    fill: 'currentColor',
    marginRight: theme.spacing(1.5),
  },
  featuresList: {
    overflowY: 'auto',
    marginBottom: theme.spacing(2.5),
  },
  filtersBtn: {
    textTransform: 'none',
    fontSize:'0.65rem',
    padding:'8px 15px',
  },
  hideHighlightedBtn: {
    textTransform: 'none',
    marginRight:'0.5rem',
    fontSize:'0.6rem',
    padding:'0 15px',
    width:'60%',
  },
  filterIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.primary.main
  },

  filterIconInactive:{
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  btnIconEye:{
    color:'#BABABA',
    backgroundColor:'#b3fbfb66',
  },
  customList:{
    paddingTop:'4px',
    paddingBottom:'4px'
  }
}))

const MAX_ENTITIES_LIST_SIZE = 50

const EntitiesPane = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const locale = useCommonStore(state => state.locale)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const networkElementCollection = useFeatureCollection()
  const entitiesOpen = useUiStore(state => state.entitiesOpen)
  const setEntitiesOpen = useUiStore(state => state.setEntitiesOpen)
  const selectedEntity = useUiStore(state => state.selectedEntity)
  const actSelectFeature = useUiStore(state => state.actSelectFeature)
  const networkElementsFilter = useUiStore(state => state.networkElementsFilter)
  const setFeatureFilter = useUiStore(state => state.setNetworkElementsFilter)
  const [filterDialogOpen,setFilterDialogOpen] = useState<boolean>(false)

  const [entitiesChosen, setEntitiesChosen] = useState('')

  const handleHideFeatures = ()=> {
    let currentHideState = networkElementsFilter.hideFeatures//use the same setting for both filters
    setFeatureFilter({...networkElementsFilter, hideFeatures: !currentHideState})
  }

  const EntitiesIcon = (esprops) => (
    <Fragment>
      <SvgIcon viewBox="0 0 34 34" classes={{root: classes.entitiesIconOuter,}}>
        <circle cx="17" cy="17" r="16.5" stroke="#BABABA"/>
      </SvgIcon>
      <SvgIcon viewBox="0 0 21 18" classes={{root: classes.entitiesIconInner,}}>
        <mask id="path-1-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0ZM2 7C0.895431 7 0 7.89543 0 9C0 10.1046 0.895431 11 2 11C3.10457 11 4 10.1046 4 9C4 7.89543 3.10457 7 2 7ZM0 16C0 14.8954 0.895431 14 2 14C3.10457 14 4 14.8954 4 16C4 17.1046 3.10457 18 2 18C0.895431 18 0 17.1046 0 16ZM9 0C7.89543 0 7 0.895431 7 2C7 3.10457 7.89543 4 9 4H19C20.1046 4 21 3.10457 21 2C21 0.895431 20.1046 0 19 0H9ZM7 9C7 7.89543 7.89543 7 9 7H19C20.1046 7 21 7.89543 21 9C21 10.1046 20.1046 11 19 11H9C7.89543 11 7 10.1046 7 9ZM9 14C7.89543 14 7 14.8954 7 16C7 17.1046 7.89543 18 9 18H19C20.1046 18 21 17.1046 21 16C21 14.8954 20.1046 14 19 14H9Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0ZM2 7C0.895431 7 0 7.89543 0 9C0 10.1046 0.895431 11 2 11C3.10457 11 4 10.1046 4 9C4 7.89543 3.10457 7 2 7ZM0 16C0 14.8954 0.895431 14 2 14C3.10457 14 4 14.8954 4 16C4 17.1046 3.10457 18 2 18C0.895431 18 0 17.1046 0 16ZM9 0C7.89543 0 7 0.895431 7 2C7 3.10457 7.89543 4 9 4H19C20.1046 4 21 3.10457 21 2C21 0.895431 20.1046 0 19 0H9ZM7 9C7 7.89543 7.89543 7 9 7H19C20.1046 7 21 7.89543 21 9C21 10.1046 20.1046 11 19 11H9C7.89543 11 7 10.1046 7 9ZM9 14C7.89543 14 7 14.8954 7 16C7 17.1046 7.89543 18 9 18H19C20.1046 18 21 17.1046 21 16C21 14.8954 20.1046 14 19 14H9Z" fill="#BEE8E8"/>
        <path d="M1 2C1 1.44772 1.44772 1 2 1V-1C0.343146 -1 -1 0.343146 -1 2H1ZM2 3C1.44772 3 1 2.55228 1 2H-1C-1 3.65685 0.343146 5 2 5V3ZM3 2C3 2.55228 2.55228 3 2 3V5C3.65685 5 5 3.65685 5 2H3ZM2 1C2.55228 1 3 1.44772 3 2H5C5 0.343146 3.65685 -1 2 -1V1ZM1 9C1 8.44772 1.44772 8 2 8V6C0.343146 6 -1 7.34315 -1 9H1ZM2 10C1.44772 10 1 9.55228 1 9H-1C-1 10.6569 0.343146 12 2 12V10ZM3 9C3 9.55228 2.55228 10 2 10V12C3.65685 12 5 10.6569 5 9H3ZM2 8C2.55228 8 3 8.44772 3 9H5C5 7.34315 3.65685 6 2 6V8ZM2 13C0.343146 13 -1 14.3431 -1 16H1C1 15.4477 1.44772 15 2 15V13ZM5 16C5 14.3431 3.65685 13 2 13V15C2.55228 15 3 15.4477 3 16H5ZM2 19C3.65685 19 5 17.6569 5 16H3C3 16.5523 2.55228 17 2 17V19ZM-1 16C-1 17.6569 0.343146 19 2 19V17C1.44771 17 1 16.5523 1 16H-1ZM8 2C8 1.44772 8.44772 1 9 1V-1C7.34315 -1 6 0.343146 6 2H8ZM9 3C8.44772 3 8 2.55228 8 2H6C6 3.65685 7.34315 5 9 5V3ZM19 3H9V5H19V3ZM20 2C20 2.55228 19.5523 3 19 3V5C20.6569 5 22 3.65685 22 2H20ZM19 1C19.5523 1 20 1.44771 20 2H22C22 0.343146 20.6569 -1 19 -1V1ZM9 1H19V-1H9V1ZM9 6C7.34315 6 6 7.34315 6 9H8C8 8.44772 8.44772 8 9 8V6ZM19 6H9V8H19V6ZM22 9C22 7.34315 20.6569 6 19 6V8C19.5523 8 20 8.44771 20 9H22ZM19 12C20.6569 12 22 10.6569 22 9H20C20 9.55228 19.5523 10 19 10V12ZM9 12H19V10H9V12ZM6 9C6 10.6569 7.34315 12 9 12V10C8.44772 10 8 9.55228 8 9H6ZM8 16C8 15.4477 8.44772 15 9 15V13C7.34315 13 6 14.3431 6 16H8ZM9 17C8.44771 17 8 16.5523 8 16H6C6 17.6569 7.34315 19 9 19V17ZM19 17H9V19H19V17ZM20 16C20 16.5523 19.5523 17 19 17V19C20.6569 19 22 17.6569 22 16H20ZM19 15C19.5523 15 20 15.4477 20 16H22C22 14.3431 20.6569 13 19 13V15ZM9 15H19V13H9V15Z" fill="#979797" mask="url(#path-1-inside-1)"/>
      </SvgIcon>
    </Fragment>
  )
  const FaIconBtn = (fibprops) => (
    <IconButton disabled={fibprops.disabled} onClick={fibprops.onClick} className={fibprops.selected ? clsx('itemBtnSelected', classes.itemBtnSelected, classes.iconBtn) : classes.iconBtn} >
      <Icon className={clsx(classes.faIcon, fibprops.icon)} />
    </IconButton>
  )

  const EntityListItem = (eliprops) => {
    let isSelected = !!selectedEntity && selectedEntity.properties.code === eliprops.entity.properties.code
    const handleFeatureClick = (event) => {
      if (!selectedEntity) {
        actSelectFeature(eliprops.entity)
        setEntitiesChosen(FTC[eliprops.entity.properties.featureType]?.collection)
      }
      else if (selectedEntity.properties.code !== eliprops.entity.properties.code) {
        actSelectFeature(eliprops.entity)
        setEntitiesChosen(FTC[eliprops.entity.properties.featureType]?.collection)
      }
    }
    return (
      <ListItem key={eliprops.entity.properties.code}
        button
        selected={isSelected}
        className={isSelected ? clsx('item', classes.item, classes.itemSelected) : clsx('item', classes.item)}
      >
        <div
          className={isSelected ? clsx(classes.itemBtn, classes.itemBtnSelected, 'MuiButtonBase-root') : clsx(classes.itemBtn, 'MuiButtonBase-root')}
          onClick={handleFeatureClick}
        >
          <ListItemText primary={eliprops.entity.properties.label} classes={{primary: classes.litext,}} />
        </div>
      </ListItem>
    )
  }

  const [entitySearch, setEntitySearch] = useState('')
  const handleEntitySearchChange = event => {
    let val = event.target.value
    setEntitySearch(val)
  }

  const filterFeatures = (feature) => {
    let searchString = entitySearch.toLowerCase()
    if (!searchString || searchString.length === 0) return true
    let b = (
      feature.properties.code.toLowerCase().indexOf(searchString) !== -1
      || feature.properties.label.toLowerCase().indexOf(searchString) !== -1
    )
    if (!b && feature.properties.featureType === 'hydrometer') {
      if (!!feature.properties.street) {
        b = feature.properties.street.toLowerCase().indexOf(searchString) !== -1
      }
      if (!b && !!feature.properties.billingCode) {
        b = feature.properties.billingCode.toLowerCase().indexOf(searchString) !== -1
      }
    }
    return b
  }
  const ftinfo = entitiesOpen && entitiesChosen ? Object.values(FTC).find(f => f.collection === entitiesChosen) : null
  let filteredFeatures = !!ftinfo && networkElementCollection[entitiesChosen] ? networkElementCollection[entitiesChosen] : []
  if (networkElementsFilter.isActive && filteredFeatures.length > 0) {
    filteredFeatures = filteredFeatures.filter(el=>networkElementsFilter.highlightedFeatures[entitiesChosen]?.includes(el.properties.code) )
  }
  if (filteredFeatures.length > 0 && !!entitySearch && entitySearch.length > 0 ) {
    filteredFeatures = filteredFeatures.filter(filterFeatures)
  }
  filteredFeatures.sort((a, b) => a.properties.label.localeCompare(b.properties.label, locale, {ignorePunctuation: true}))

  return (
    <Fragment>
      {entitiesOpen && !entitiesChosen && (
      <Fragment>
        <List style={{paddingBottom:'0'}}>
          <ListItem key='entities' className={classes.customList}>
            <IconButton size="small" onClick={() => setEntitiesOpen(false)} className={classes.arrowBtn} >
              <ArrowBackIcon/>
            </IconButton>
            <EntitiesIcon />
            <ListItemText
              primary={t('net.entity.plural')}
              classes={{primary: classes.headedlitext,}}
            />
          </ListItem>
          <ListItem className={classes.customList} >
            {networkElementsFilter.hideFeatures &&
            <Button
              variant='outlined'
              className={classes.hideHighlightedBtn}
              startIcon={<Icon className={clsx('fas fa-eye-slash',classes.btnIconEye)} style={{fontSize:'1rem'}} />}
              onClick={handleHideFeatures}
              disabled={!(networkElementsFilter.isActive)}
            >
              {t('btn.hideHighlighted')}
            </Button>
            }
           {!networkElementsFilter.hideFeatures &&
            <Button
              variant='outlined'
              className={classes.hideHighlightedBtn}
              startIcon={<Icon className={clsx('fas fa-eye', classes.btnIconEye)} style={{fontSize:'1rem'}}/>}
              onClick={handleHideFeatures}
              disabled={!(networkElementsFilter.isActive)}
            >
              {t('btn.hideHighlighted')}
            </Button>
            }
            <Button
              variant='outlined'
              className={classes.filtersBtn}
              startIcon={<WrmIcon icon='filter' className={networkElementsFilter.isActive ? classes.filterIcon :  classes.filterIconInactive }/>}
              onClick={()=>setFilterDialogOpen(true)}
            >
              {t('btn.filters')}
            </Button>
          </ListItem>
        </List>
        {false && <TextField
          id="outlined-search"
          placeholder={t('md.search.prompt')!}
          type="search"
          variant="outlined"
          className={classes.searchField}
          inputProps={{
            className: classes.searchInput,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className={clsx(classes.searchIcon, 'fas fa-search')} />
              </InputAdornment>
            )
          }}
        />}
        <Divider variant='middle' />
        <List dense={true} id = 'network-elements-menu'>
        {Object.values<any>(FTC)
          .filter(ft => !ft.accessRight || accessGranted(ft.accessRight))
          .sort((a, b) => alphabeticalSorter(t(`net.${a.code}.plural`), t(`net.${b.code}.plural`)))
          .map(e => (
          <ListItem button key={e.code}
            disabled={e.disabled}
          >
            <div
              className={clsx(classes.itemBtn, 'MuiButtonBase-root')}
              onClick={() => setEntitiesChosen(e.collection)}
            >
              <WrmIcon
                icon={e.icon}
                className={classes.entIcon}
              />
              {networkElementsFilter.isActive ? <ListItemText
                primary={t(`net.${e.code}.plural`)}
                secondary={`(${!!networkElementsFilter.highlightedFeatures[e.collection] ? networkElementsFilter.highlightedFeatures[e.collection].length : 0} ${t(`net.item.plural`)})`}
                classes={{
                  primary: classes.litext,
                  secondary: classes.secondlitext,
                }}
              /> : (
                <ListItemText
                primary={t(`net.${e.code}.plural`)}
                secondary={`(${!!networkElementCollection[e?.collection] ? networkElementCollection[e.collection]?.length : 0} ${t(`net.item.plural`)})`}
                classes={{
                  primary: classes.litext,
                  secondary: classes.secondlitext,
                }}
              />
              )}
            </div>
            {props.visibleLayers.includes(e.collection) && <FaIconBtn icon='fas fa-eye' selected={true} onClick={() => props.handleRemoveFeatureLayer(e.collection)} />}
            {!props.visibleLayers.includes(e.collection) && <FaIconBtn icon='fas fa-eye-slash' selected={true} onClick={() => props.handleAddFeatureLayer(e.collection)} />}
          </ListItem>
        ))}
        </List>
      </Fragment>
      )}
      {entitiesOpen && !!ftinfo && (
      <Fragment>
        <List>
          {!!ftinfo && (
          <ListItem
            key={entitiesChosen}
            className={classes.headerli}
          >
            <IconButton size="small" onClick={() => setEntitiesChosen('')} className={classes.arrowBtn} >
              <ArrowBackIcon />
            </IconButton>
            <WrmIcon
              icon={ftinfo.icon}
              className={classes.entIcon}
            />
            <ListItemText
              primary={t(`net.${ftinfo.code}.plural`)}
              secondary={`(${filteredFeatures.length <= MAX_ENTITIES_LIST_SIZE ? filteredFeatures.length : MAX_ENTITIES_LIST_SIZE + '/' + filteredFeatures.length} ${t('net.item.plural')})`}
              classes={{
                primary: classes.headedlitext,
                secondary: classes.secondlitext,
              }}
            />
            {props.visibleLayers.includes(entitiesChosen) && <FaIconBtn icon='fas fa-eye' selected={true} onClick={() => props.handleRemoveFeatureLayer(entitiesChosen)} />}
            {!props.visibleLayers.includes(entitiesChosen) && <FaIconBtn icon='fas fa-eye-slash' selected={true} onClick={() => props.handleAddFeatureLayer(entitiesChosen)} />}
          </ListItem>)}
        </List>
        <TextField
          id="outlined-search"
          placeholder={t('net.search.prompt')!}
          type="search"
          variant="outlined"
          value={entitySearch}
          onChange={handleEntitySearchChange}
          // onChange={debounced(200, handleEntitySearchChange)}
          className={classes.searchField}
          inputProps={{
            className: classes.searchInput,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className={clsx(classes.searchIcon, 'fas fa-search')} />
              </InputAdornment>
            )
          }}
        />
        <Divider variant='middle' />
        {!!networkElementCollection[entitiesChosen] && <List
          key={entitiesChosen}
          className={classes.featuresList}
        >
        {filteredFeatures
          .slice(0, MAX_ENTITIES_LIST_SIZE)
          .map(e => {
          return (
            <EntityListItem key={e.properties.code}
              entity={e}
            />
          )
        })}
        </List>}
      </Fragment>
      )}
      {filterDialogOpen &&
      <EntitiesFilterDialog
        open={filterDialogOpen}
        setOpen={setFilterDialogOpen}
      />}
    </Fragment>
  )
}

export default EntitiesPane
