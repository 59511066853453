import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import LabelNt from '../reusableComponents/LabelNt'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelContainer: {
      minWidth: 170,
      maxWidth: 170,
      minHeight: 20,// to match the heihgt of MdValue which uses typografy body2 that has a line height of 20px
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
    tooltip: {
      maxWidth: 220,
      maxHeight: 650,
      padding: '8px 14px 6px',
      borderRadius: '8px',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      whiteSpace: 'pre-wrap',
    },
    infoTooltip: {
      maxWidth: 300,
      maxHeight: 650,
      padding: '6px 12px 4px',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.custom.grey2,
    },
    arrow: {
      color: theme.palette.primary.main,
      fontSize: 18,
    },
    infoArrow: {
      color: theme.palette.primary.main,
      fontSize: 18,
    },
  })
)

interface MdLabelProps {
  /** Content of the label */
  labelText: string
  /** Content of the label tooltip */
  tipText?: string
  /** Content of the info icon tooltip */
  infoText?: string
  /** override label's mui box container className */
  labelClassName?: any,
  /** override tooltip's mui classes */
  tooltipClasses?: any,
  /** override info tooltip's mui classes */
  infoTooltipClasses?: any,
}

/** Renders a `label` that may optionally have a `tooltip` and/or an `info` icon with (another) `tooltip` */
export const MdLabel = ({
  labelText = '',
  tipText,
  infoText,
  labelClassName,
  tooltipClasses,
  infoTooltipClasses,
}: MdLabelProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <LabelNt
      labelText={labelText}
      tipText={tipText}
      infoText={infoText}
      tipPlacement='top'
      infoPlacement='right'
      infoTipPlacement='top'
      infoFontSize='small'
      labelClassName={labelClassName ? clsx (classes.labelContainer, labelClassName) : classes.labelContainer}
      tooltipClasses={tooltipClasses ? {...{ tooltip: classes.tooltip, arrow: classes.arrow }, ...tooltipClasses} : { tooltip: classes.tooltip, arrow: classes.arrow }}
      infoTooltipClasses={infoTooltipClasses ? {...{ tooltip: classes.infoTooltip, arrow: classes.infoArrow }, ...infoTooltipClasses} : { tooltip: classes.infoTooltip, arrow: classes.infoArrow }}
    />
  )
}

export default MdLabel
