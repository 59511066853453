import axios from 'axios'
import crypto from 'crypto-browserify' // see comments on config-overrides.js

axios.defaults.timeout = 1200000

const API_URL = process.env.REACT_APP_WRM_API_URL
const WITH_SESSION = process.env.REACT_APP_WITH_SESSION === 'true'

const proMap: any = {}
let hitCounter = 0

const ApiClient = (getTokenSilently: any) => {

    const sendRequest = (data: any, cachePromise?: boolean, path?: string, baseUrl?: string, withToken?: boolean) => {
      // console.log(`ApiClient sendRequest data ${JSON.stringify(data,null,2)}`)
      return sendCachableRequest('POST', data, cachePromise, path, baseUrl, withToken)
    }

    const sendPutRequest = (data: any, path?: string, baseUrl?: string, withToken?: boolean) => {
      // console.log(`ApiClient sendPutRequest data ${JSON.stringify(data,null,2)}`)
      let cachePromise = false //cachePromise not yet implemented as a sendPutRequest param
      return sendCachableRequest('PUT', data, cachePromise, path, baseUrl, withToken)
    }

    const sendGetRequest = (cachePromise?: boolean, path?: string, baseUrl?: string, withToken?: boolean, data?: any) => {
      // console.log(`ApiClient sendGetRequest url ${JSON.stringify(url,null,2)}`)
      return sendCachableRequest('GET', data, cachePromise, path, baseUrl, withToken)
    }

    const sendDeleteRequest = (path?: string, baseUrl?: string, withToken?: boolean) => {
      // console.log(`ApiClient sendDeleteRequest data ${JSON.stringify(data,null,2)}`)
      let cachePromise = false //cachePromise not yet implemented as a sendDeleteRequest param
      return sendCachableRequest('DELETE', null, cachePromise, path, baseUrl, withToken)
    }

    /*
    * Method that caches the promise returned by axios requsets.
    * Useful for long-lasting requests that may be asked multiple times within a sort period (possibly triggerd by GUI events)
    * If the request's promise exists in cache then the method returns that promise,
    * else it proceeds with making a new request (and cacheing its promise).
    * Upon resolution (or rejection) of the promise it is removed from the cache.
    */
    const sendCachableRequest = (method: string, data?: any, cachePromise?: boolean, path?: string, baseUrl?: string, withToken?: boolean) => {
      // console.log(`ApiClient sendRequest data ${JSON.stringify(data,null,2)}`)
      let url = `${!!baseUrl ? baseUrl : API_URL}${!!path ? path : ''}`

      let item: any
      let hashKey: string = ''
      let reqId: string
      if (cachePromise) {
        item = {url, data}
        hashKey = crypto.createHash('sha1').update(JSON.stringify(item)).digest('hex')//since collision possibility is very low we use sha1 which is slightly faster than sha256
        reqId = method === 'POST' ? `operation ${data.operation}` : `url ${url}`
        // console.log(`ApiClient sendRequest hashKey ${hashKey} operation ${data.operation}`)
        let cachedPromise = proMap[hashKey]
        if (!!cachedPromise) {
          hitCounter++
          console.log(`ApiClient sendCachableRequest hitCounter ${hitCounter} ${reqId} hashKey ${hashKey}`)
          return cachedPromise
        }
      }

      let promise: any = Promise.resolve('start')

      let options: any = {}
      if (!!WITH_SESSION) options.withCredentials = true
      if (!!withToken) {
        promise = getTokenSilently()
        .then(
          token => {
            options.headers = {Authorization: `Bearer ${token}`}
            // console.log(`sendRequest options ${JSON.stringify(options,null,2)}`)
            return token
          },
          tokenErr => {
            console.log(`sendCachableRequest ERROR tokenErr`)
            console.log(tokenErr)
            throw tokenErr
          }
        )
      }

      promise = promise.then(
        res => {
          if (method === 'GET') {
            return axios.get(url, options)
          } else if (method === 'PUT') {
            return axios.put(url, data, options)
          } else if (method === 'DELETE') {
            return axios.delete(url, options)
          } else {//default to POST
            return axios.post(url, data, options)
          }
        }
      )

      proMap[hashKey] = promise
      return promise
      .then(
        res => {
          //  console.log(`ApiClient sendCachableRequest DONE ${reqId}`)
          if (cachePromise) {
            delete proMap[hashKey]
          }
          return res
        },
        err => {
          console.error(`ApiClient sendCachableRequest error`)
          console.log(err)
          if (cachePromise) {
            console.log(`ApiClient sendCachableRequest ERROR ${reqId}`)
            //try resending the request
            if (err.config && err.response) {
              console.log(`ApiClient sendCachableRequest RESENDING ${reqId}`)
              let resendPromise = axios.request(err.config)
              .then(
                res => {
                  console.log(`ApiClient sendCachableRequest RESEND DONE ${reqId}`)
                  delete proMap[hashKey]
                  return res
                },
                err => {
                  console.error(`ApiClient sendCachableRequest RESEND error`)
                  console.log(err)
                  delete proMap[hashKey]
                  return Promise.reject(err)
                }
              )
              proMap[hashKey] = resendPromise
              return resendPromise
            } else {
              delete proMap[hashKey]
              return Promise.reject(err)
            }
          } else {
            return Promise.reject(err)
          }
        }
      )
    }

    return({
      sendRequest: sendRequest,
      sendPutRequest: sendPutRequest,
      sendGetRequest: sendGetRequest,
      sendDeleteRequest: sendDeleteRequest,
    })

}

export default ApiClient
