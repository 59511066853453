import { Irr_InverterReportInterface } from "./types/commonTypes"
import { NetworkElementsFilter } from "./types/mapStoreTypes"

const SupportedLocales = [
  'en',
  'el'
]

const IMNRStatuses = {
  'NEW': { code: 'im.nr.status.new', label: 'New', color: '#C0BB40', bcolor: '#FEFFDD' },
  'ONGOING': { code: 'im.nr.status.ongoing', label: "Ongoing", color: '#F46A62', bcolor: '#FFF1F0' },
  'PENDING': { code: 'im.nr.status.pending', label: 'Pending', color: '#979797', bcolor: '#F2F2F2' },
  'FINISHED': { code: 'im.nr.status.finished', label: 'Finished', color: '#35ADAD', bcolor: '#EDFFFF' },
  'CANCELED': { code: 'im.nr.status.canceled', label: 'Canceled', color: '#979797', bcolor: '#F2F2F2' },
  'NONRESOLVABLE': { code: 'im.nr.status.nonresolvable', label: 'Non resolvable', color: '#979797', bcolor: '#F2F2F2' },
}

const IMMPStatuses = {
  'NEW': { code: 'im.mp.status.new', label: 'New', color: '#F5992B', bcolor: '#DAFFFF' },
  'ONGOING': { code: 'im.mp.status.ongoing', label: "Ongoing", color: '#979797', bcolor: '#F2F2F2' },
  'UPCOMING': { code: 'im.mp.status.upcoming', label: 'Upcoming', color: '#C0BB40', bcolor: '#FEFFDD' },
  'FINISHED': { code: 'im.mp.status.finished', label: 'Finished', color: '#35ADAD', bcolor: '#EDFFFF' },
  'OVERDUE': { code: 'im.mp.status.overdue', label: 'Overdue', color: '#F46A62', bcolor: '#FFF1F0' },
  'UPTODATE': { code: 'im.mp.status.uptodate', label: 'Up to Date', color: '#35ADAD', bcolor: '#EDFFFF' },
}

const IMMEStatuses = {
  'NEW': { code: 'im.mp.status.new', label: 'New', color: '#F5992B', bcolor: '#DAFFFF' },
  'ONGOING': { code: 'im.mp.status.ongoing', label: "Ongoing", color: '#979797', bcolor: '#F2F2F2' },
  'UPCOMING': { code: 'im.mp.status.upcoming', label: 'Upcoming', color: '#C0BB40', bcolor: '#FEFFDD' },
  'FINISHED': { code: 'im.mp.status.finished', label: 'Finished', color: '#35ADAD', bcolor: '#EDFFFF' },
  'OVERDUE': { code: 'im.mp.status.overdue', label: 'Overdue', color: '#F46A62', bcolor: '#FFF1F0' },
}

const IMTStatuses = {
  'NEW': { code: 'im.nr.status.new', label: "New", color: '#F46A62', bcolor: '#FFF1F0' },
  'ONGOING': { code: 'im.nr.status.ongoing', label: "Ongoing", color: '#F46A62', bcolor: '#FFF1F0' },
  'PENDING': { code: 'im.nr.status.pending', label: 'Pending', color: '#979797', bcolor: '#F2F2F2' },
  'COMPLETED': { code: 'im.nr.status.completed', label: 'Finished', color: '#35ADAD', bcolor: '#EDFFFF' },
}

const IMNRCategories = {
  'hydrometerDamage': { code: 'im.nr.category.hydrometerDamage'},
  'waterLeakage': { code: 'im.nr.category.waterLeakage' },
  'brokenPipe': { code: 'im.nr.category.brokenPipe' },
  'waterSupply': { code: 'im.nr.category.waterSupply' },
  'other': { code: 'im.nr.category.other'},
}

const IMNRImportantRanks = {
  '1': { code: 'im.nr.rank.1', label: 'im.nr.rank.1label'},
  '2': { code: 'im.nr.rank.2', label: 'im.nr.rank.2label'},
  '3': { code: 'im.nr.rank.3', label: 'im.nr.rank.3label'},
  '4': { code: 'im.nr.rank.4', label: 'im.nr.rank.4label'},
  '5': { code: 'im.nr.rank.5', label: 'im.nr.rank.5label'},
}

const PersonnelSpecialties = {
  'other': { code: 'other', transKey: 'personnel.specialty' },
  'unskilled': { code: 'unskilled', transKey: 'personnel.specialty' },
  'director': { code: 'director', transKey: 'personnel.specialty' },
  'admin': { code: 'admin', transKey: 'personnel.specialty' },
  'electrical': { code: 'electrical', transKey: 'personnel.specialty' },
  'accountant': { code: 'accountant', transKey: 'personnel.specialty' },
  'member': { code: 'member', transKey: 'personnel.specialty' },
  'structural': { code: 'structural', transKey: 'personnel.specialty' },
  'mechanical': { code: 'mechanical', transKey: 'personnel.specialty' },
  'chairman': { code: 'chairman', transKey: 'personnel.specialty' },
  'maintenance': { code: 'maintenance', transKey: 'personnel.specialty' },
  'civil': { code: 'civil', transKey: 'personnel.specialty' },
  'plumber': { code: 'plumber', transKey: 'personnel.specialty' },
  'inspection': { code: 'inspection', transKey: 'personnel.specialty' },
  'track': { code: 'track', transKey: 'personnel.specialty' },
  'earthmoving': { code: 'earthmoving', transKey: 'personnel.specialty' },
  'chemical': { code: 'chemical', transKey: 'personnel.specialty' },
}

const MaintenanceStatus = {
  'unknown': {code: 'unknown', transKey: 'ivn.status'},
  'uptodate': {code: 'uptodate', transKey: 'ivn.status'},
  'upcoming': {code: 'upcoming', transKey: 'ivn.status'},
  'ongoing': {code: 'ongoing', transKey: 'ivn.status'},
  'overdue': {code: 'overdue', transKey: 'ivn.status'},
}

const InventoryItemType = {
  VEHICLE: 'vehicle',
  MACHINERY: 'machinery',
  EQUIPMENT: 'equipment',
  SPAREPART: 'spare_part',
}

const VehicleCategory = {
  'passenger': { code: 'passenger', transKey: 'vehicle.category' },
  '4X4': { code: '4X4', transKey: 'vehicle.category' },
  'jeep': { code: 'jeep', transKey: 'vehicle.category' },
  'fireTruck': { code: 'fireTruck', transKey: 'vehicle.category' },
  'miniVan': { code: 'miniVan', transKey: 'vehicle.category' },
  'bus': { code: 'bus', transKey: 'vehicle.category' },
  'truck': { code: 'truck', transKey: 'vehicle.category' },
  'waterTruck': { code: 'waterTruck', transKey: 'vehicle.category' },
  'tankTruck': { code: 'tankTruck', transKey: 'vehicle.category' },
  'tractor': { code: 'tractor', transKey: 'vehicle.category' },
  'multipurposeTruck': { code: 'multipurposeTruck', transKey: 'vehicle.category' },
  'trashTruck': { code: 'trashTruck', transKey: 'vehicle.category' },
  'recyclingTruck': { code: 'recyclingTruck', transKey: 'vehicle.category' },
}

const TruckMachineCategory = {
  'chapa': { code: 'chapa', transKey: 'truckMachine.category' },
  'loader': { code: 'loader', transKey: 'truckMachine.category' },
  'grader': { code: 'grader', transKey: 'truckMachine.category' },
  'multipurposeMachine': { code: 'multipurposeMachine', transKey: 'truckMachine.category' },
  'obstructive': { code: 'obstructive', transKey: 'truckMachine.category' },
  'fireTruckMagirus': { code: 'fireTruckMagirus', transKey: 'truckMachine.category' },
  'ditcher': { code: 'ditcher', transKey: 'truckMachine.category' },
  'leakDetector': { code: 'leakDetector', transKey: 'truckMachine.category' },
  'other': { code: 'other', transKey: 'truckMachine.category' },
}

const EquipSpareCategory = {
  'electrical': { code: 'electrical', transKey: 'equipSpare.category' },
  'communication': { code: 'communication', transKey: 'equipSpare.category' },
  'mechanical': { code: 'mechanical', transKey: 'equipSpare.category' },
  'waterSupply': { code: 'waterSupply', transKey: 'equipSpare.category' },
  'sewerage': { code: 'sewerage', transKey: 'equipSpare.category' },
  'other': { code: 'other', transKey: 'equipSpare.category' },
}

const EquipmentCategory = {
  'irriCtrl': { code: 'irriCtrl', transKey: 'equipment.category' },
  'pureInvrtCtrl': { code: 'pureInvrtCtrl', transKey: 'equipment.category' },
  'irriHybridCtrl': { code: 'irriHybridCtrl', transKey: 'equipment.category' },
  'plc': { code: 'plc', transKey: 'equipment.category' },
  'controller': { code: 'controller', transKey: 'equipment.category' },
  'inverter': { code: 'inverter', transKey: 'equipment.category' },
  'sofrStarter': { code: 'sofrStarter', transKey: 'equipment.category' },
  'flowMeter': { code: 'flowMeter', transKey: 'equipment.category' },
  'pressureMeter': { code: 'pressureMeter', transKey: 'equipment.category' },
  'moistureMeter': { code: 'moistureMeter', transKey: 'equipment.category' },
  'waterHeightMeter': { code: 'waterHeightMeter', transKey: 'equipment.category' },
  'waterLevelSensor': { code: 'waterLevelSensor', transKey: 'equipment.category' },
  'rainHeightMeter': { code: 'rainHeightMeter', transKey: 'equipment.category' },
  'weatherStation': { code: 'weatherStation', transKey: 'equipment.category' },
  'damGate': { code: 'damGate', transKey: 'equipment.category' },
  'battery': { code: 'battery', transKey: 'equipment.category' },
  'modem': { code: 'modem', transKey: 'equipment.category' },
  'other': { code: 'other', transKey: 'equipment.category' },
}
/*
PLC - PLC
Controller - Controller
Inverter - Ελεγκτής στροφών
Soft starter - Ομαλός εκκινητής 
Water meter - Παροχόμετρο
Pressure meter - Πιεσόμετρο
Hydrant meter - Υγρασιόμετρο
Weather Station - Μετεωρολογικός Σταθμός
Water level sensor - Αισθητήρας Στάθμης
Water height level senor - Αισθητήρας Ύψους Στάθμης
Dam gate - Θυρόφραγμα
Rain sensor - Αισθητήρας Βροχής
Battery - Μπαταρία
Safety valve - Δικλείδα ασφαλείας
Electrovalve - Ηλεκτροβάνα
LoRa device - LoRa συσκευή
Modem - Modem
 */
const InventoryItemCategory = {
  'passenger': { code: 'passenger', transKey: 'vehicle.category' },
  '4X4': { code: '4X4', transKey: 'vehicle.category' },
  'jeep': { code: 'jeep', transKey: 'vehicle.category' },
  'fireTruck': { code: 'fireTruck', transKey: 'vehicle.category' },
  'miniVan': { code: 'miniVan', transKey: 'vehicle.category' },
  'bus': { code: 'bus', transKey: 'vehicle.category' },
  'truck': { code: 'truck', transKey: 'vehicle.category' },
  'waterTruck': { code: 'waterTruck', transKey: 'vehicle.category' },
  'tankTruck': { code: 'tankTruck', transKey: 'vehicle.category' },
  'tractor': { code: 'tractor', transKey: 'vehicle.category' },
  'multipurposeTruck': { code: 'multipurposeTruck', transKey: 'vehicle.category' },
  'trashTruck': { code: 'trashTruck', transKey: 'vehicle.category' },
  'recyclingTruck': { code: 'recyclingTruck', transKey: 'vehicle.category' },
  'chapa': { code: 'chapa', transKey: 'truckMachine.category' },
  'loader': { code: 'loader', transKey: 'truckMachine.category' },
  'grader': { code: 'grader', transKey: 'truckMachine.category' },
  'multipurposeMachine': { code: 'multipurposeMachine', transKey: 'truckMachine.category' },
  'obstructive': { code: 'obstructive', transKey: 'truckMachine.category' },
  'fireTruckMagirus': { code: 'fireTruckMagirus', transKey: 'truckMachine.category' },
  'ditcher': { code: 'ditcher', transKey: 'truckMachine.category' },
  'leakDetector': { code: 'leakDetector', transKey: 'truckMachine.category' },
  'electrical': { code: 'electrical', transKey: 'equipSpare.category' },
  'communication': { code: 'communication', transKey: 'equipSpare.category' },
  'mechanical': { code: 'mechanical', transKey: 'equipSpare.category' },
  'waterSupply': { code: 'waterSupply', transKey: 'equipSpare.category' },
  'sewerage': { code: 'sewerage', transKey: 'equipSpare.category' },
  'other': { code: 'other', transKey: 'truckMachine.category' },
}

const Legislative = {
  '50percentMax': { code: '50percentMax', transKey: 'legislative' },
  '30percent': { code: '30percent', transKey: 'legislative' },
  '30percentMax': { code: '30percentMax', transKey: 'legislative' },
  'none': { code: 'none', transKey: 'legislative' },
}

const Fuel = {
  'unleaded': { code: 'unleaded', transKey: 'fuel' },
  'diesel': { code: 'diesel', transKey: 'fuel' },
}

const Department = {
  'technicalDepartment': { code: 'technicalDepartment', transKey: 'department' },
  'civilProtection': { code: 'civilProtection', transKey: 'department' },
  'humanResources': { code: 'humanResources', transKey: 'department' },
  'cleaning': { code: 'cleaning', transKey: 'department' },
  'roadConstruction': { code: 'roadConstruction', transKey: 'department' },
  'other': { code: 'other', transKey: 'department' },
}

const InventoryCategories = {
  'category': { code: 'category', transKey: 'ic.dlg' },
  'plates': { code: 'plates', transKey: 'ic.dlg' },
  'manufacturer': { code: 'manufacturer', transKey: 'ic.dlg' },
  'model': { code: 'model', transKey: 'ic.dlg' },
  'yom': { code: 'yom', transKey: 'ic.dlg' },
  'yoa': { code: 'yoa', transKey: 'ic.dlg' },
  'horsepower': { code: 'horsepower', transKey: 'ic.dlg' },
  'pc': { code: 'pc', transKey: 'ic.dlg' },
  'ft': { code: 'ft', transKey: 'ic.dlg' },
  'mma': { code: 'mma', transKey: 'ic.dlg' },
  'lo': { code: 'lo', transKey: 'ic.dlg' },
  'tk': { code: 'tk', transKey: 'ic.dlg' },
  'owned': { code: 'owned', transKey: 'ic.dlg' },
  'from': { code: 'from', transKey: 'ic.dlg' },
  'to': { code: 'to', transKey: 'ic.dlg' },
  'yes': { code: 'yes', transKey: 'ic.dlg' },
  'no': { code: 'no', transKey: 'ic.dlg' },
  'pol': { code: 'pol', transKey: 'ic.dlg' },
  'col': { code: 'col', transKey: 'ic.dlg' },
  'supplier': { code: 'supplier', transKey: 'ic.dlg' },
  'zoa': { code: 'zoa', transKey: 'ic.dlg' },
  'buv': { code: 'buv', transKey: 'ic.dlg' },
  'ms': { code: 'ms', transKey: 'ic.dlg' },
  'driver': { code: 'driver', transKey: 'ic.dlg' },
  'cancel': { code: 'cancel', transKey: 'ic.dlg' },
  'update': { code: 'update', transKey: 'ic.dlg' },
  'create': { code: 'create', transKey: 'ic.dlg' },
  'vne': { code: 'vne', transKey: 'ic.dlg' },
  'pmne': { code: 'pmne', transKey: 'ic.dlg' },
  'smo': { code: 'smo', transKey: 'ic.dlg' },
  'but': { code: 'but', transKey: 'ic.dlg' },
  'operator': { code: 'operator', transKey: 'ic.dlg' },
  'ene': { code: 'ene', transKey: 'ic.dlg' },
  'description': { code: 'description', transKey: 'ic.dlg' },
  'serialNo': { code: 'serialNo', transKey: 'ic.dlg' },
  'power': { code: 'power', transKey: 'ic.dlg' },
  'oh': { code: 'oh', transKey: 'ic.dlg' },
  'bue': { code: 'bue', transKey: 'ic.dlg' },
  'comment': { code: 'comment', transKey: 'ic.dlg' },
  'as': { code: 'as', transKey: 'ic.dlg' },
  'st': { code: 'st', transKey: 'ic.dlg' },
  'ltu': { code: 'ltu', transKey: 'ic.dlg' },
  'busp': { code: 'busp', transKey: 'ic.dlg' },
  'spne': { code: 'spne', transKey: 'ic.dlg' },
  'cfd': { code: 'cfd', transKey: 'ic.dlg' },
  'pht': { code: 'pht', transKey: 'ic.dlg' },
}

const Periodicity = {
  nonrec: { code: 'nonrec', label: 'Non recurrent' },
  annualy: { code: 'annualy', label: 'Annualy' },
  semiann: { code: 'semiann', label: 'Semi-annualy' },
  evr4mon: { code: 'evr4mon', label: 'Every 4 months' },
  evr3mon: { code: 'evr3mon', label: 'Every 3 months' },
  evr2mon: { code: 'evr2mon', label: 'Every 2 months' },
  monthly: { code: 'monthly', label: 'Monthly' },
  weekly: { code: 'weekly', label: 'Weekly' },
  daily: { code: 'daily', label: 'Daily' },
}

const EQPT = {
  pmpmtr: { code: 'pmpmtr', label: 'Pump Motor' },
  tnkflt: { code: 'tnkflt', label: 'Tank Floater' },
  aumbl: { code: 'aumbl', label: 'Automobile' },
  Other: { code: 'other', label: 'Other' },
}

const CIRTypes = {
  MyHydrometer: { code: 'MyHydrometer', transKey: 'cir.type' },
  OtherHydrometer: { code: 'OtherHydrometer', transKey: 'cir.type' },
  WaterLeakage: { code: 'WaterLeakage', transKey: 'cir.type' },
  BrokenPipe: { code: 'BrokenPipe', transKey: 'cir.type' },
  WaterSupply: { code: 'WaterSupply', transKey: 'cir.type' },
  Other: { code: 'Other', transKey: 'cir.type' },
}

const CIRStatuses = {
  // pending: {code: 'pending', label: 'Pending'},
  // opened: {code: 'opened', label: 'Opened'},
  // ongoing: {code: 'ongoing', label: 'Ongoing'},
  // finished: {code: 'finished', label: 'Finished'},
  // cancelled: {code: 'cancelled', label: 'Cancelled'},
  'ON HOLD': { code: 'cir.status.onHold', label: 'On Hold', color: '#979797', bcolor: '#F2F2F2' },
  'WONT FIX': { code: 'cir.status.wontFix', label: "Won't Fix", color: '#979797', bcolor: '#F2F2F2' },
  INVALID: { code: 'cir.status.invalid', label: 'Invalid', color: '#979797', bcolor: '#F2F2F2' },
  RESOLVED: { code: 'cir.status.resolved', label: 'Resolved', color: '#35ADAD', bcolor: '#EDFFFF' },
  'In Progress': { code: 'cir.status.inProgress', label: 'In Progress', color: '#F46A62', bcolor: '#FFF1F0' },
  NEW: { code: 'cir.status.new', label: 'New', color: '#C0BB40', bcolor: '#FEFFDD' },
}

const IVNStatuses = {
  'UNKNOWN': { code: 'ivn.status.unknown', label: '', color: 'transparent', bcolor: 'transparent' },
  'UPTODATE': { code: 'ivn.status.uptodate', label: "Up to Date", color: '#35ADAD', bcolor: '#EDFFFF' },
  'UPCOMING': { code: 'ivn.status.upcoming', label: 'Upcoming', color: '#C0BB40', bcolor: '#FEFFDD' },
  'ONGOING': { code: 'ivn.status.ongoing', label: 'Ongoing', color: '#979797', bcolor: '#F2F2F2' },
  'OVERDUE': { code: 'ivn.status.overdue', label: 'Overdue', color: '#F46A62', bcolor: '#FFF1F0' },
}

const NEConditions = {
  'active': { code: 'active', transKey: 'ne.condition' },
  'disabled': { code: 'disabled', transKey: 'ne.condition' },
  'damaged': { code: 'damaged', transKey: 'ne.condition' },
  'underRepair': { code: 'underRepair', transKey: 'ne.condition' },
  'new': { code: 'new', transKey: 'ne.condition' }
}

const WaterNetworks = {
  'waterSupply': { code: 'waterSupply', transKey: 'water.networks' },
  'agriculture': { code: 'agriculture', transKey: 'water.networks' },
  // 'livestock': { code: 'livestock', transKey: 'water.networks.agriculture' },
  'industrial': { code: 'industrial', transKey: 'water.networks' },
  'energy': { code: 'energy', transKey: 'water.networks' },
  'recreation': { code: 'recreation', transKey: 'water.networks' },
  'drainage': { code: 'drainage', transKey: 'water.networks' },
}

const WaterNetworksSubTypes = {
  'drinking': {  parentValue: 'waterSupply', code: 'drinking', transKey: 'water.networks.waterSupply' },
  'waterSupplyPublic': {  parentValue: 'waterSupply', code: 'waterSupplyPublic', transKey: 'water.networks.waterSupply' },
  'airConditioning': {  parentValue: 'waterSupply',  code: 'airConditioning', transKey: 'water.networks.waterSupply' },
  'construction': {  parentValue: 'waterSupply', code: 'construction', transKey: 'water.networks.waterSupply' },
  // Agriculture
  'irrigation': {  parentValue: 'agriculture', code: 'irrigation', transKey: 'water.networks.agriculture' },
  'livestock': {  parentValue: 'agriculture', code: 'livestock', transKey: 'water.networks.agriculture.agriculture' },
  'aquaculture': {  parentValue: 'agriculture', code: 'aquaculture', transKey: 'water.networks.agriculture' },
  'livingFish': {  parentValue: 'agriculture', code: 'livingFish', transKey: 'water.networks.agriculture' },
  'agriculturalIndustry': {  parentValue: 'agriculture', code: 'agriculturalIndustry', transKey: 'water.networks.agriculture' },
  //Industrial
  'integration': {  parentValue: 'industrial', code: 'integration', transKey: 'water.networks.industrial' },
  'indirectUse': {  parentValue: 'industrial', code: 'indirectUse', transKey: 'water.networks.industrial' },
  'bottling': {  parentValue: 'industrial', code: 'bottling', transKey: 'water.networks.industrial' },
  'cooling': {  parentValue: 'industrial', code: 'cooling', transKey: 'water.networks.industrial' },
  'fireSafety': {  parentValue: 'industrial', code: 'fireSafety', transKey: 'water.networks.industrial' },
  //energy
  'hydroelectric': {  parentValue: 'energy', code: 'hydroelectric', transKey: 'water.networks.energy' },
  'thermoelectric': {  parentValue: 'energy', code: 'thermoelectric', transKey: 'water.networks.energy' },
  //recreation
  'hotels': {  parentValue: 'recreation', code: 'hotels', transKey: 'water.networks.recreation' },
  'facilities': {  parentValue: 'recreation', code: 'facilities', transKey: 'water.networks.recreation' },
  'sports': {  parentValue: 'recreation', code: 'sports', transKey: 'water.networks.recreation' },
  //drainage
}

const HydrometerTypes = {
  'multipleBurstLiquidType': { code: 'multipleBurstLiquidType', transKey: 'hdrm.type' },
  'multipleBurstLiquidTypeOil': { code: 'multipleBurstLiquidTypeOil', transKey: 'hdrm.type' },
  'irrigationFlangesDryType': { code: 'irrigationFlangesDryType', transKey: 'hdrm.type' },
  'smartAMR': { code: 'smartAMR', transKey: 'hdrm.type' },
  'woltmanCompound': { code: 'woltmanCompound', transKey: 'hdrm.type' },
  'antiFrost': { code: 'antiFrost', transKey: 'hdrm.type' },
}

const HydrometerErrorFunctions = {
  constant: { code: 'constant', transKey: 'hdrm.errorFunction' },
  linearYearly: { code: 'linearYearly', transKey: 'hdrm.errorFunction' },
  linearOtherPeriod: { code: 'linearOtherPeriod', transKey: 'hdrm.errorFunction' },
  interval: { code: 'interval', transKey: 'hdrm.errorFunction' },
}

const PipeTypes = {
  'surfaced': { code: 'surfaced', transKey: 'pipe.type' },
  'underground': { code: 'underground', transKey: 'pipe.type' },
}

const PipeMaterials = {
  'copper': { code: 'copper', transKey: 'pipe.material', hazenWilliams: 140 },
  'steel': { code: 'steel', transKey: 'pipe.material', hazenWilliams: 100 },
  'castIron': { code: 'castIron', transKey: 'pipe.material', hazenWilliams: 130 },
  'U-PVC': { code: 'upvc', transKey: 'pipe.material', hazenWilliams: 150 },
  'HDPE': { code: 'hdpe', transKey: 'pipe.material', hazenWilliams: 100 },
  'PP': { code: 'PP', transKey: 'pipe.material', hazenWilliams: 100 },
  'PB': { code: 'pb', transKey: 'pipe.material', hazenWilliams: 100 },
  'VPE': { code: 'vpe', transKey: 'pipe.material', hazenWilliams: 100 },
  'C-PVC': { code: 'cpvc', transKey: 'pipe.material', hazenWilliams: 150 },
  'Concrete': { code: 'concrete', transKey: 'pipe.material', hazenWilliams: 140 },
  'Clay': { code: 'clay', transKey: 'pipe.material', hazenWilliams: 110 },
  'Asbestos': { code: 'asbestos', transKey: 'pipe.material', hazenWilliams: 140 },
}

const EpanetPumpStates = {
  '0': { code: '0', transKey: 'eo.epa.pump.state' },
  '2': { code: '2', transKey: 'eo.epa.pump.state' },
  '3': { code: '3', transKey: 'eo.epa.pump.state' },
  '5': { code: '5', transKey: 'eo.epa.pump.state' },
}

const EpanetControlOperators = {
  above: {code: 'above', epanetVal: 'ABOVE', transKey: 'eo.opa.controls.operator'},
  below: {code: 'below', epanetVal: 'BELOW', transKey: 'eo.opa.controls.operator'},
}

const EpanetControlTypes = {
  tank: {code: 'tank', epanetVal: 'IF NODE', transKey: 'eo.opa.controls.type'},
  junction: {code: 'junction', epanetVal: 'IF NODE', transKey: 'eo.opa.controls.type'},
  time: {code: 'time', epanetVal: 'AT TIME', transKey: 'eo.opa.controls.type'},
  clock: {code: 'clock', epanetVal: 'AT CLOCKTIME', transKey: 'eo.opa.controls.type'},
}

const EpanetControlTimeSufixes = {
  am: {code: 'am', epanetVal: 'AM', transKey: 'eo.opa.controls.timeSuffix'},
  pm: {code: 'pm', epanetVal: 'PM', transKey: 'eo.opa.controls.timeSuffix'},
}

const EpanetStatusTypes = {
  open: {code: 'open', epanetVal: 'OPEN', transKey: 'eo.opa.controls.statusType'},
  closed: {code: 'closed', epanetVal: 'CLOSED', transKey: 'eo.opa.controls.statusType'},
  pumpSpeed: {code: 'pumpSpeed', epanetVal: '', transKey: 'eo.opa.controls.statusType'},
  valveSetting: {code: 'valveSetting', epanetVal: '', transKey: 'eo.opa.controls.statusType'},
}

const PipeDiameterClasses = {
  'f15': { code: 'f15', transKey: 'pipe.diameter', diameter: 21.34 },
  'f20': { code: 'f20', transKey: 'pipe.diameter', diameter: 26.67 },
  'f25': { code: 'f25', transKey: 'pipe.diameter', diameter: 33.40 },
  'f35': { code: 'f35', transKey: 'pipe.diameter' },
  'f40': { code: 'f40', transKey: 'pipe.diameter', diameter: 48.26 },
  'f50': { code: 'f50', transKey: 'pipe.diameter', diameter: 60.33 },
  'f63': { code: 'f63', transKey: 'pipe.diameter' },
  'f75': { code: 'f75', transKey: 'pipe.diameter' },
  'f80': { code: 'f80', transKey: 'pipe.diameter', diameter: 88.90 },
  'f90': { code: 'f90', transKey: 'pipe.diameter', diameter: 101.60 },
  'f100': { code: 'f100', transKey: 'pipe.diameter', diameter: 114.30 },
  'f110': { code: 'f110', transKey: 'pipe.diameter' },
  'f125': { code: 'f125', transKey: 'pipe.diameter', diameter: 141.30 },
  'f140': { code: 'f140', transKey: 'pipe.diameter' },
  'f150': { code: 'f150', transKey: 'pipe.diameter', diameter: 168.28 },
  'f160': { code: 'f160', transKey: 'pipe.diameter' },
  'f180': { code: 'f180', transKey: 'pipe.diameter' },
  'f200': { code: 'f200', transKey: 'pipe.diameter', diameter: 219.08 },
  'f225': { code: 'f225', transKey: 'pipe.diameter' },
  'f250': { code: 'f250', transKey: 'pipe.diameter', diameter: 273.05 },
  'f280': { code: 'f280', transKey: 'pipe.diameter' },
  'f300': { code: 'f300', transKey: 'pipe.diameter', diameter: 323.85 },
  'f315': { code: 'f315', transKey: 'pipe.diameter' },
  'f355': { code: 'f355', transKey: 'pipe.diameter' },
  'f400': { code: 'f400', transKey: 'pipe.diameter', diameter: 406.40 },
  'f500': { code: 'f500', transKey: 'pipe.diameter', diameter: 508.00 },
  'f630': { code: 'f630', transKey: 'pipe.diameter' },
  'f1200': { code: 'f1200', transKey: 'pipe.diameter', diameter: 1219.20 },
  'other': { code: 'other', transKey: 'pipe.diameter' },
}

const ReservoirTypes = {
  'drilling': { code: 'drilling', transKey: 'reservoir.type' },
  'fountain': { code: 'fountain', transKey: 'reservoir.type' },
}

const StationTypes = {
  'TRANSPONDER': { code: 'transponder', transKey: 'station.type' },
  'PUMPING_STATION': { code: 'pumpingStation', transKey: 'station.type' },
  'DRILLING': { code: 'drilling', transKey: 'station.type' },
  'irrigationPoint': { code: 'irrigationPoint', transKey: 'station.type' },
}

const TankTypes = {
  'cylindrical': { code: 'cylindrical', transKey: 'tank.type' },
  'rectangular': { code: 'rectangular', transKey: 'tank.type' },
  'agricultural': { code: 'agricultural', transKey: 'tank.type' },
  'other': { code: 'other', transKey: 'tank.type' },
}

const TankMaterials = {
  'plastic': { code: 'plastic', transKey: 'tank.material' },
  'metalic': { code: 'metalic', transKey: 'tank.material' },
  'concrete': { code: 'concrete', transKey: 'tank.material' },
  'other': { code: 'other', transKey: 'tank.material' },
}

const WB_ReportTypes = [
  {code: 'tankBalance', transKey: 'wbreports.type'},//us-151
  {code: 'multifeature', transKey: 'wbreports.type'},//us-152
  {code: 'multiperiod', transKey: 'wbreports.type'},//us-153
  {code: 'zoneComparison', transKey: 'wbreports.type'},//us-154,156
  {code: 'zoneCalculation', transKey: 'wbreports.type'},//us-155
  {code: 'zoneLossesPct', transKey: 'wbreports.type'},//us-155
]

const QC_ReportTypes = [
  {code: 'indicatorThreshold', transKey: 'qcreports.type',label:"qcreports.type.indicatorThreshold"},// Υπέρβαση ορίων επιφυλακής... * Inicators thresholds
  {code: 'indicatorAverageValues', transKey: 'qcreports.type',label:"qcreports.type.indicatorAverageValues"},//Μέση τιμή μετρούμενων δεικτών       * Indicators measurements average values
  {code: 'indicatorsRateOfChange', transKey: 'qcreports.type',label:"qcreports.type.indicatorsRateOfChange"},//Ρυθμός μεταβολής στοιχείων ανά σημείο ελέγχου * Indicators Rate of change per sampling point
  {code: 'indicatorComparison', transKey: 'qcreports.type',label:"qcreports.type.indicatorComparison"},//Σύγκριση δείκτη σε δύο σημεία * Indicator Comparison between two sampling points
  {code: 'indicatorMeasuremntPerPeriod', transKey: 'qcreports.type',label:"qcreports.type.indicatorMeasuremntPerPeriod"},//Μετρήσεις δεικτών ανά περίοδο και...* Indicator measurements per period
]

/**LEGACY currently used in the <IrrReportsLEGACY> should be removed */
const Irr_ReportTypes = {
  hourmeterCnsm: {code: 'hourmeterCnsm', transKey: 'irrreports.type'},
  waterOutletCnsm: {code: 'waterOutletCnsm', transKey: 'irrreports.type'},
  cultivatorCnsm: {code: 'cultivatorCnsm', transKey: 'irrreports.type'},
  electricityCnsmByPeriod: {code: 'electricityCnsmByPeriod', transKey: 'irrreports.type'},
  electricityBillsTable: {code: 'electricityBillsTable', transKey: 'irrreports.type'},
}

const Irr_WaterOutletReportTypes = {
  irrigationAreaByWaterOutlet: {code: 'irrigationAreaByWaterOutlet', transKey: 'irrreports.type'},
  irrigationHoursPerCultivator: {code: 'irrigationHoursPerCultivator', transKey: 'irrreports.type'},
  waterOutletOperatingHours: {code: 'waterOutletOperatingHours', transKey: 'irrreports.type'},
  // electricityCnsmByPeriod: {code: 'electricityCnsmByPeriod', transKey: 'irrreports.type'},
  // electricityBillsTable: {code: 'electricityBillsTable', transKey: 'irrreports.type'},
}
enum Irr_WaterOutletReports_ENUM {
  /**Αρδευόμενη έκταση υδροληψίας */
  irrigationAreaByWaterOutlet = 'irrigationAreaByWaterOutlet',
  /**Ώρες άρδευσης ανά καλλιεργητή */
  irrigationHoursPerCultivator = 'irrigationHoursPerCultivator',
  /**Ώρες λειτουργίας υδροληψίας */
  waterOutletOperatingHours = 'waterOutletOperatingHours',
  /**Prompts user to select a report*/
  empty = '',
}
enum ActivitiesReport_ENUM {
  /**Εργασίες ανά διαδικασία */
  proceduresPerOperation = 'proceduresPerOperation',
  /**Εργασίες ανά κατηγορία */
  proceduresPerCategory = 'proceduresPerCategory',
  /**Ανοιχτές εργασίες */
  activeProcedures = 'activeProcedures',
  /**Prompts user to select a report*/
  empty = '',
}
const ActivitiesReportTypes = {
  proceduresPerOperation: {code: 'proceduresPerOperation', transKey: 'activity.report.type'},
  proceduresPerCategory: {code: 'proceduresPerCategory', transKey: 'activity.report.type'},
  activeProcedures: {code: 'activeProcedures', transKey: 'activity.report.type'},
}
const Irr_ApplicationReportTypes = {
  applStatus: { code: 'applStatus', transKey: 'irri.appl.reports' },
  perStatus: { code: 'perStatus', transKey: 'irri.appl.reports' },
  perCultCategory: { code: 'perCultCategory', transKey: 'irri.appl.reports' },
  perFrequency: { code: 'perFrequency', transKey: 'irri.appl.reports' },
  perRejectReason: { code: 'perRejectReason', transKey: 'irri.appl.reports' },
  perAgriPeriod: { code: 'perAgriPeriod', transKey: 'irri.appl.reports' },
}

const Irr_InverterReportTypes: Irr_InverterReportInterface = {
  avrgPowerConsumption: { code: 'avrgPowerConsumption', transKey: 'irri.inverter.reports' },
  avrgWaterSupply: { code: 'avrgWaterSupply', transKey: 'irri.inverter.reports' },
  avrgStartStop: { code: 'avrgStartStop', transKey: 'irri.inverter.reports' },
  avrgPressure: { code: 'avrgPressure', transKey: 'irri.inverter.reports' },
  avrgRPM: { code: 'avrgRPM', transKey: 'irri.inverter.reports' },
}
/** REPORT_TYPE_HEADERS are used in the <IrriInverterReportTable> to generate the headers of the table, 
 * and to populate the table cells with data
 * the header property is used as the label for the headers, 
 * the suffix (if present) is used by the useTranslation(t("")) function to add the correct formatting of the unit (suffix after value eg: 55 bars) displayed in the tableCells
 * type: is either string and number - used to generate the TOTAL */
const INVERTER_REPORT_TYPE_HEADERS = {
  avrgPowerConsumption: [
    { header: 'label', type: 'string' },
    { header: 'averagePowerConsumption', suffix: 'format.currency.euroPerHour', type: 'number' },
    { header: 'cumulativePowerConsumption', suffix: 'format.currency.euro', type: 'number' },
  ],
  avrgWaterSupply: [
    { header: 'label', type: 'string' },
    { header: 'averageWaterSupply', suffix: 'format.number.cubicMeters.perHour', type: 'number' },
    { header: 'cumulativeWaterSupply', suffix: 'format.number.cubicMeters', type: 'number' },
  ],
  avrgStartStop: [
    { header: 'label', type: 'string' },
    { header: 'averageStartStop', suffix: 'format.number.perHour', type: 'number'},
    { header: 'cumulativeStartStop', type: 'number' },
  ],
  avrgPressure: [
    { header: 'label', type: 'string' },
    { header: 'averagePressure', suffix: 'format.number.bar', type: 'number' },
  ],
  avrgRPM: [
    { header: 'label', type: 'string' },
    { header: 'averageRPM', suffix: 'format.number.rpm', type: 'number' },
  ],
}

const ValveTypes = {
  'prv': { code: 'prv', transKey: 'valve.type' },
  'psv': { code: 'psv', transKey: 'valve.type' },
  'pbv': { code: 'pbv', transKey: 'valve.type' },
  'fcv': { code: 'fcv', transKey: 'valve.type' },
  'tcv': { code: 'tcv', transKey: 'valve.type' },
  'gpv': { code: 'gpv', transKey: 'valve.type' },
  'chv': { code: 'chv', transKey: 'valve.type' },
  'shv': { code: 'shv', transKey: 'valve.type' },
  'other': { code: 'other', transKey: 'valve.type' },
}

const ValveUses = {
  'cleaning': { code: 'cleaning', transKey: 'valve.use' },
  'discharge': { code: 'discharge', transKey: 'valve.use' },
  'terminal': { code: 'terminal', transKey: 'valve.use' },
  'venting': { code: 'venting', transKey: 'valve.use' },
  'other': { code: 'other', transKey: 'valve.use' },
}

const ValveForms = {
  'drawer': { code: 'drawer', transKey: 'valve.form' },
  'knifegate': { code: 'knifegate', transKey: 'valve.form' },
  'swing': { code: 'swing', transKey: 'valve.form' },
  'ball': { code: 'ball', transKey: 'valve.form' },
  'butterfly': { code: 'butterfly', transKey: 'valve.form' },
  'other': { code: 'other', transKey: 'valve.form' },
}

const NotificationTypes = {
  'inventoryItem': { code: 'inventoryItem', transKey: 'emp.notification.type', isPersonnelType: true },
  'networkRepair': { code: 'networkRepair', transKey: 'emp.notification.type', isPersonnelType: true },
  'maintenancePlan': { code: 'maintenancePlan', transKey: 'emp.notification.type', isPersonnelType: true },
  'alarmFired': { code: 'alarmFired', transKey: 'emp.notification.type', isPersonnelType: true },
  'invrtAlarmFired': { code: 'invrtAlarmFired', transKey: 'emp.notification.type', isPersonnelType: true },
  'irriEventPersonnel': { code: 'irriEventPersonnel', transKey: 'emp.notification.type', isPersonnelType: true },
  'irriEventConsumer': { code: 'irriEventConsumer', transKey: 'cp.notification.type', isPersonnelType: false },
  'mailEvent': { code: 'mailEvent', transKey: 'cp.notification.type', isPersonnelType: false },
  'issueReport': { code: 'issueReport', transKey: 'cp.notification.type', isPersonnelType: false },
  'networkUpdate': { code: 'networkUpdate', transKey: 'cp.notification.type', isPersonnelType: false },
  'bill': { code: 'bill', transKey: 'cp.notification.type', isPersonnelType: false },
  'credentials': { code: 'credentials', transKey: 'cp.notification.type', isPersonnelType: false },
}

const USNotificationTypes = {
  'alarmFired': { code: 'alarmFired', transKey: 'userSettings.notifications.type' },
  'networkRepair': { code: 'networkRepair', transKey: 'userSettings.notifications.type' },
  'maintenancePlan': { code: 'maintenancePlan', transKey: 'userSettings.notifications.type' },
  'inventoryItem': { code: 'inventoryItem', transKey: 'userSettings.notifications.type' },
}

const USNotificationFrequencies = {
  'day': { code: 'day', transKey: 'userSettings.notifications.frequency' },
  'week': { code: 'week', transKey: 'userSettings.notifications.frequency' },
  'month': { code: 'month', transKey: 'userSettings.notifications.frequency' },
  'threeMonth': { code: 'threeMonth', transKey: 'userSettings.notifications.frequency' },
  // 'sixMonth': { code: 'sixMonth', transKey: 'userSettings.notifications.frequency' },
  // 'year': { code: 'year', transKey: 'userSettings.notifications.frequency' },
}

const IndicatorTypes = {
  'periodic': { code: 'periodic', transKey: 'indicator.type' },
  'other': { code: 'other', transKey: 'indicator.type' },
  'microbiological': { code: 'microbiological', transKey: 'indicator.type', tenants: ['dmgrty', 'dyhgmn'] },
  'physiochemical': { code: 'physiochemical', transKey: 'indicator.type', tenants: ['dmgrty', 'dyhgmn'] },
  'radioactive': { code: 'radioactive', transKey: 'indicator.type', tenants: ['dmgrty', 'dyhgmn'] },
  'measuredByScada': { code: 'measuredByScada', transKey: 'indicator.type', tenants: ['dmgrty', 'dyhgmn'] },
}

const TenantMetadata = {
  dmwrm : {
    mapCenter: { lat: 37.964097, lng: 23.500097 },
  },
  tnnOne : {
    mapCenter: { lat: 37.918777, lng: 23.090848 },
  },
  TNNAuto : {
    mapCenter: { lat: 37.918777, lng: 23.090848 },
  },
  'nm-tp' : {
    mapCenter: { lat: 37.964097, lng: 23.500097 },
  },
  dykrnt: {
    mapCenter: { lat: 37.930523, lng: 22.924519 },
  },
  dyhgmn: {
    mapCenter: { lat: 39.501591, lng: 20.278187 },
  },
  dmgrty: {
    mapCenter: { lat: 37.686809, lng: 21.989994 },
  },
  othrys: {
    mapCenter: { lat: 37.686809, lng: 21.989994 },
  },
  tchnr : {
    mapCenter: { lat: 37.964097, lng: 23.500097 },
  },
}

const SwitchCheckTypes = {
  analog: {code: 'analog', transKey: 'switch.switchType'},
  digital: {code: 'digital', transKey: 'switch.switchType'},
  // smartCard: {code: 'smartCard', transKey: 'switch.switchType'},
  // other: {code: 'other', transKey: 'switch.switchType'},
}

const CultivationIrrigationMethods = {
  canon: {code: 'canon', transKey: 'cultivation.irrigationMethod'},
  pipe: {code: 'pipe', transKey: 'cultivation.irrigationMethod'},
  sprinklers: {code: 'sprinklers', transKey: 'cultivation.irrigationMethod'},
  beck: {code: 'beck', transKey: 'cultivation.irrigationMethod'},
  ramp: {code: 'ramp', transKey: 'cultivation.irrigationMethod'},
}
const IRRIGATION_FREQUENCIES_6 = [1, 2, 3, 6]
const IRRIGATION_FREQUENCIES_8 = [1, 2, 4, 8]
const IRRIGATION_FREQUENCIES_12 = [2, 3, 4, 6, 12]
const IRRIGATION_FREQUENCIES_24 = [2, 3, 4, 6, 8, 12, 24]

const HOUR_VALUES = [
  { code: '0', label: '00:00' },
  { code: '1', label: '01:00' },
  { code: '2', label: '02:00' },
  { code: '3', label: '03:00' },
  { code: '4', label: '04:00' },
  { code: '5', label: '05:00' },
  { code: '6', label: '06:00' },
  { code: '7', label: '07:00' },
  { code: '8', label: '08:00' },
  { code: '9', label: '09:00' },
  { code: '10', label: '10:00' },
  { code: '11', label: '11:00' },
  { code: '12', label: '12:00' },
  { code: '13', label: '13:00' },
  { code: '14', label: '14:00' },
  { code: '15', label: '15:00' },
  { code: '16', label: '16:00' },
  { code: '17', label: '17:00' },
  { code: '18', label: '18:00' },
  { code: '19', label: '19:00' },
  { code: '20', label: '20:00' },
  { code: '21', label: '21:00' },
  { code: '22', label: '22:00' },
  { code: '23', label: '23:00' },
]

// const CultivationCategoryLEGACY = {
//   '11': {code: '11', transKey: 'cultivation.category'},
//   '12': {code: '12', transKey: 'cultivation.category'},
//   '98': {code: '98', transKey: 'cultivation.category'},
//   'other': {code: 'other', transKey: 'cultivation.category'},
// }
// const CultivationKindLEGACY = {
//   '11000001': {code: '11000001', parent: '11', transKey: 'cultivation.kind'},
//   '11000002': {code: '11000002', parent: '11', transKey: 'cultivation.kind'},
//   '11000099': {code: '11000099', parent: '11', transKey: 'cultivation.kind'},
//   '12000001': {code: '12000001', parent: '12', transKey: 'cultivation.kind'},
//   '12000002': {code: '12000002', parent: '12', transKey: 'cultivation.kind'},
//   '12000099': {code: '12000099', parent: '12', transKey: 'cultivation.kind'},
//   '98000001': {code: '98000001', parent: '98', transKey: 'cultivation.kind'},
//   '98000002': {code: '98000002', parent: '98', transKey: 'cultivation.kind'},
//   '98000003': {code: '98000003', parent: '98', transKey: 'cultivation.kind'},
//   '98000004': {code: '98000004', parent: '98', transKey: 'cultivation.kind'},
//   '98000005': {code: '98000005', parent: '98', transKey: 'cultivation.kind'},
//   '98000006': {code: '98000006', parent: '98', transKey: 'cultivation.kind'},
//   '98000099': {code: '98000099', parent: '98', transKey: 'cultivation.kind'},
//   'other': {code: 'other', parent: 'other', transKey: 'cultivation.kind'},
// }

/**Παρόλου που ξεκινήσαμε να βάζουμε τις κατηγορίες με την κωδικοποίηση του ΟΠΕΚΕΠΕ 
 * δεν έχει ιδιαίτερο νόημα καθώς κάποιες οι κατηγορίες που μας δόθηκαν από τους ΤΟΕΒ είναι 
 * συμπτυγμένες κατηγορίες του ΟΠΕΚΕΠΕ 
 * (πχ η κατ. Εδαφοκάλυψη καθώς και η κατ. Κτηνοτροφικά φυτά χλόωσης/ψυχανθή
 * έχουν τον ίδιο κωδικό -8- βάσει του ΟΠΕΚΕΠΕ) καθώς και η κατηγορία:
 * Κολοκυνθοειδή (Μποστανικά) && Υπαίθρια λαχανικά/Κηπευτικά έχουν τον ίδιο κωδικο: -38-*/
 /**CultivationCategoreis that have the singular property have only one option in the select drop down(CultivationKind)
 * and can be automatically selected if their parent was selected*/
const CultivationCategory = {
  'olives': {code: 'olives', transKey: 'cultivation.category', trees:true, singular: true}, //Ελαιώνες
  'cotton': {code: 'cotton', transKey: 'cultivation.category', singular: true}, //Βαμβάκι
  'corn': { code:'corn',transKey: 'cultivation.category', singular: true }, //Αραβόσιτος
  'citrus': { code:'citrus',transKey: 'cultivation.category',trees:true, singular: true }, //Εσπεριδοειδή
  'sugarBeets': { code:'sugarBeets',transKey: 'cultivation.category', singular: true }, //Ζαχαρότευτλα
  'forageGrassPlantsFaboideae': {code: 'forageGrassPlantsFaboideae', transKey: 'cultivation.category'}, //Κτηνοτροφικά φυτά χλόωσης/Ψυχανθή Forage grass plants Faboideae 
  'potato': {code: 'potato', transKey: 'cultivation.category', singular: true}, //Γεώμηλα
  'greens': {code: 'greens', transKey: 'cultivation.category'}, //Υπαίθρια λαχανικά/ Κηπευτικά
  'edafokalipsi': {code: 'edafokalipsi', transKey: 'cultivation.category'}, //Εδαφοκάλυψη
  'fruitTrees': {code: 'fruitTrees', transKey: 'cultivation.category',trees:true, singular: true}, //Δέντρα Οπωροφόρα
  'nurseryGarden': {code: 'nurseryGarden', transKey: 'cultivation.category', singular: true}, //Φυτώριο
  'melons': {code: 'melons', transKey: 'cultivation.category'}, //Κολοκυνθοειδή (Μποστανικά)
  // '98': {code: '98', transKey: 'cultivation.category'}, //"Κατ.Πηνειού"?~!?
  'other': {code: 'other', transKey: 'cultivation.category', singular: true},
}

const CultivationKind = {
  // '11000001': { code: '11000001', parent: '11', transKey: 'cultivation.kind' },
  'olives': { code: 'olives', parent: 'olives', transKey: 'cultivation.kind', singular: true },
  'corn': { code: 'corn', parent: 'corn', transKey: 'cultivation.kind', singular: true },
  'cotton': { code: 'cotton', parent: 'cotton', transKey: 'cultivation.kind', singular: true },
  'citrus': { code: 'citrus', parent: 'citrus', transKey: 'cultivation.kind', singular: true },
  'sugarBeets': { code: 'sugarBeets', parent: 'sugarBeets', transKey: 'cultivation.kind', singular: true },
  'alfalfa': { code: 'alfalfa', parent: 'forageGrassPlantsFaboideae', transKey: 'cultivation.kind' },
  'beans': { code: 'beans', parent: 'forageGrassPlantsFaboideae', transKey: 'cultivation.kind' },
  'potato': { code: 'potato', parent: 'potato', transKey: 'cultivation.kind', singular: true },
  'tomato': { code: 'tomato', parent: 'greens', transKey: 'cultivation.kind' },
  'horticultural': { code: 'horticultural', parent: 'greens', transKey: 'cultivation.kind' },
  'peas': { code: 'peas', parent: 'edafokalipsi', transKey: 'cultivation.kind' },
  'chickpeas': { code: 'chickpeas', parent: 'edafokalipsi', transKey: 'cultivation.kind' },
  'wheat': { code: 'wheat', parent: 'edafokalipsi', transKey: 'cultivation.kind' },
  'winterWheat': { code: 'winterWheat', parent: 'edafokalipsi', transKey: 'cultivation.kind' },
  'fruitTrees': { code: 'fruitTrees', parent: 'fruitTrees', transKey: 'cultivation.kind', singular: true },
  'nurseryGarden': { code: 'nurseryGarden', parent: 'nurseryGarden', transKey: 'cultivation.kind', singular: true },
  'watermelons': { code: 'watermelons', parent: 'melons', transKey: 'cultivation.kind' },
  'melons': { code: 'melons', parent: 'melons', transKey: 'cultivation.kind' },
  'other': { code: 'other', parent: 'other', transKey: 'cultivation.kind',singular: true },
}

const CultivationSoilTypes = {
  sandy: {
    code: 'sandy',
    transKey: 'cultivation.soilType',
  },
  loamy: {
    code: 'loamy',
    transKey: 'cultivation.soilType',
  },
}
const IrrigationSchedStatus = {
  'validated': { code: 'validated', transKey: 'irriSched.status'},
  'nonvalidated': { code: 'nonvalidated', transKey: 'irriSched.status'},
}
const IrrigationFormStatus = {
  'submitted': { code: 'submitted', transKey: 'irriForm.status'},
  'processing': { code: 'processing', transKey: 'irriForm.status'},
  'approved': { code: 'approved', transKey: 'irriForm.status'},
  'rejected': { code: 'rejected', transKey: 'irriForm.status'},
  'cancelled': { code: 'cancelled', transKey: 'irriForm.status'},
}
const IrrigationFormRejectionReasons = {
  'financialReasons': { code: 'financialReasons', transKey: 'irriForm.rejectedReason'},
  'overdueApplication': { code: 'overdueApplication', transKey: "irriForm.rejectedReason"},
  'insufficientWaterSupply': { code: 'insufficientWaterSupply', transKey: "irriForm.rejectedReason"},
}

const IrrigationCommandTypes = {
  CheckAndChangeBroker: {code: 'CheckAndChangeBroker', device: 'remoteDevice', transKey: 'irriCommand.commandType'},
  GetSettings : {code: 'GetSettings', device: 'remoteDevice', transKey: 'irriCommand.commandType'},
  GetState : {code: 'GetState', device: 'remoteDevice', transKey: 'irriCommand.commandType'},
  LockUser : {code: 'LockUser', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  RebootSystem : {code: 'RebootSystem', device: 'remoteDevice', transKey: 'irriCommand.commandType'},
  ResetReader: {code: 'ResetReader', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  SetSettings : {code: 'SetSettings', device: 'remoteDevice', transKey: 'irriCommand.commandType'},
  SetTime : {code: 'SetTime', device: 'remoteDevice', transKey: 'irriCommand.commandType'},
  SetTimetable : {code: 'SetTimetable', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  StartProgram : {code: 'StartProgram', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  StartUser : {code: 'StartUser', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  StartPump : {code: 'StartPump', device: 'pumpCtrl', transKey: 'irriCommand.commandType'},
  StopProgram : {code: 'StopProgram', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  StopPump : {code: 'StopPump', device: 'pumpCtrl', transKey: 'irriCommand.commandType'},
  StopUser : {code: 'StopUser', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  UnlockUser : {code: 'UnlockUser', device: 'irriPointCtrl', transKey: 'irriCommand.commandType'},
  UpdateFirmware : {code: 'UpdateFirmware', device: 'remoteDevice', transKey: 'irriCommand.commandType'},
  //invrtCtrl commands
  SetInverterAin1 : {code: 'SetInverterAin1', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
  SetInverterAin2 : {code: 'SetInverterAin2', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
  SetInverterFreq : {code: 'SetInverterFreq', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
  ResetInverter : {code: 'ResetInverter', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
  GetInverterState : {code: 'GetInverterState', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
  GetInverterSettings : {code: 'GetInverterSettings', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
  GetInverterParams : {code: 'GetInverterParams', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
  InitializeInverter : {code: 'InitializeInverter', device: 'invrtCtrl', transKey: 'invrtCommand.commandType'},
}

const IrrigationEventTypes = {
  earlyArrival : {code: 'earlyArrival', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 3, isCultivatorEvent: true},
  graceExpired : {code: 'graceExpired', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 1, isCultivatorEvent: true},
  graceStarted : {code: 'graceStarted', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 2, isCultivatorEvent: true},
  graceStopped : {code: 'graceStopped', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 3, isCultivatorEvent: true},
  identificationEvent : {code: 'identificationEvent', device: 'remoteDevice', transKey: 'irriEvent.eventType', disabled: true},
  networkUp : {code: 'networkUp', device: 'remoteDevice', transKey: 'irriEvent.eventType', disabled: true},
  networkDown : {code: 'networkDown', device: 'remoteDevice', transKey: 'irriEvent.eventType', disabled: true},
  programEnded : {code: 'programEnded', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 1},
  programStarted : {code: 'programStarted', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 1},
  programStopped : {code: 'programStopped', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 1},
  publicIpChanged : {code: 'publicIpChanged', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', disabled: true},
  pumpStarted : {code: 'pumpStarted', device: 'pumpCtrl', transKey: 'irriEvent.eventType', severity: 1},
  pumpStartedOVR : {code: 'pumpStartedOVR', device: 'pumpCtrl', transKey: 'irriEvent.eventType', severity: 1},
  pumpStopped : {code: 'pumpStopped', device: 'pumpCtrl', transKey: 'irriEvent.eventType', severity: 1},
  pumpStoppedOVR : {code: 'pumpStoppedOVR', device: 'pumpCtrl', transKey: 'irriEvent.eventType', severity: 1},
  requestReload : {code: 'requestReload', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 1},
  settingsChanged : {code: 'settingsChanged', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 2},
  slotChanged : {code: 'slotChanged', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 3, isCultivatorEvent: true},
  systemReboot : {code: 'systemReboot', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 2},
  timetableChanged : {code: 'timetableChanged', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 2},
  userLocked : {code: 'userLocked', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 2, isCultivatorEvent: true},
  userRejected : {code: 'userRejected', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 3, isCultivatorEvent: true},
  userStarted : {code: 'userStarted', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 3, isCultivatorEvent: true},
  userStartedOVR : {code: 'userStartedOVR', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 2, isCultivatorEvent: true},
  userStopped : {code: 'userStopped', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 3, isCultivatorEvent: true},
  userStoppedOVR : {code: 'userStoppedOVR', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 3, isCultivatorEvent: true},
  userUnlocked : {code: 'userUnlocked', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 2, isCultivatorEvent: true},
  /*error event types*/
  motorOffBypass : {code: 'motorOffBypass', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  motorOnBypass : {code: 'motorOnBypass', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  motorRelayError : {code: 'motorRelayError', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  motorThermalError : {code: 'motorThermalError', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  emergencySwError : {code: 'emergencySwError', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  powerDown : {code: 'powerDown', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  readerError : {code: 'readerError', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 1},
  safetyError : {code: 'safetyError', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  /*resolved error event types*/
  motorOffOk : {code: 'motorOffOk', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  motorOnOk : {code: 'motorOnOk', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  motorRelayOk : {code: 'motorRelayOk', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  motorThermalOk : {code: 'motorThermalOk', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  emergencySwOk : {code: 'emergencySwOk', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  powerUp : {code: 'powerUp', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  readerOk : {code: 'readerOk', device: 'irriPointCtrl', transKey: 'irriEvent.eventType', severity: 1},
  safetyOk : {code: 'safetyOk', device: 'remoteDevice', transKey: 'irriEvent.eventType', severity: 1},
  /*invrtCtrl event types*/
  inverterReport: {code: 'inverterReport', device: 'invrtCtrl', transKey: 'invrtEvent.eventType', severity: 3},
  inverterEvent: {code: 'inverterEvent', device: 'invrtCtrl', transKey: 'invrtEvent.eventType', severity: 3},
}

/**
 * DeviceModules are logical groupings of device functionality.
 * e.g. a invrtCtrl comprises all the functionality required
 * in order to control remotely a pump inverter.
 */
const DeviceModules = {
  remoteDevice: {
    code: 'remoteDevice',
    transKey: 'device.module',
    eventTypes: Object.values(IrrigationEventTypes).filter((e: any) => e.device === 'remoteDevice').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    commandTypes: Object.values(IrrigationCommandTypes).filter((e: any) => e.device === 'remoteDevice').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    settings: {
      name: {code: 'name', assetField: 'label', type: 'string'},
      deviceId: {code: 'deviceId', assetField: 'macAddress', type: 'string'},
      mqttBroker: {code: 'mqttBroker', assetField: 'mqttBroker', type: 'string'},
      mqttUser: {code: 'mqttUser', assetField: 'mqttBroker', type: 'string'},
      mqttPass: {code: 'mqttPass', assetField: 'mqttBroker', type: 'string'},
      mqttCommands: {code: 'mqttCommands', assetField: 'mqttTopicDomain', type: 'string'},
      mqttEvents: {code: 'mqttEvents', assetField: 'mqttTopicDomain', type: 'string'},
      mqttResponses: {code: 'mqttResponses', assetField: 'mqttTopicDomain', type: 'string'},
      serverUrl: {code: 'serverUrl', assetField: 'postsToUrl', type: 'string'},
      softVers: {code: 'softVers', assetField: 'softwareVer', type: 'string'},
      ssid: {code: 'ssid', assetField: 'ssid', type: 'string'},
      ssidPass: {code: 'ssidPass', assetField: 'ssidPass', type: 'string', writeonly: true},
      bssid: {code: 'bssid', assetField: 'bssid', type: 'string', readonly: true},
      pumpControl: {code: 'pumpControl', assetField: 'category', type: 'number'},
      cardReader: {code: 'cardReader', assetField: 'cardReader', type: 'number'},
      loraTransceiver: {code: 'loraTransceiver', assetField: 'loraTransceiver', type: 'number'},
      lcdEnable: {code: 'lcdEnable', assetField: 'lcdEnable', type: 'boolean'},
      input5Enable: {code: 'input5Enable', assetField: 'input5Enable', type: 'boolean'},
    },
    stateProperties: {},
  },
  pumpCtrl: {
    code: 'pumpCtrl',
    transKey: 'device.module',
    eventTypes: Object.values(IrrigationEventTypes).filter((e: any) => e.device === 'pumpCtrl').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    commandTypes: Object.values(IrrigationCommandTypes).filter((e: any) => e.device === 'pumpCtrl').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    settings: {
      inverterType: {code: 'inverterType', assetField: 'inverterType', type: 'number'},
      pumpOutIsPulse: {code: 'pumpOutIsPulse', assetField: 'pumpOutIsPulse', type: 'boolean'},
      bEnMRERest: {code: 'bEnMRERest', assetField: 'bEnMRERest', type: 'boolean'},
    },
    stateProperties: {},
  },
  irriPointCtrl: {
    code: 'irriPointCtrl',
    transKey: 'device.module',
    eventTypes: Object.values(IrrigationEventTypes).filter((e: any) => e.device === 'irriPointCtrl').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    commandTypes: Object.values(IrrigationCommandTypes).filter((e: any) => e.device === 'irriPointCtrl').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    featureType: 'switch',
    settings: {
      iGracePeriod: {code: 'iGracePeriod', assetField: 'gracePeriod', type: 'number'},
      iEarlyArrivalPeriod: {code: 'iEarlyArrivalPeriod', assetField: 'earlyArrivalPeriod', type: 'number'},
      iLateLeavePeriod: {code: 'iLateLeavePeriod', assetField: 'lateLeavePeriod', type: 'number'},
      usersToSwOff: {code: 'usersToSwOff', assetField: 'usersToSwOff', type: 'number'},
    },
    stateProperties: {},
  },
  invrtCtrl: {
    code: 'invrtCtrl',
    transKey: 'device.module',
    eventTypes: Object.values(IrrigationEventTypes).filter((e: any) => e.device === 'invrtCtrl').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    commandTypes: Object.values(IrrigationCommandTypes).filter((e: any) => e.device === 'invrtCtrl').reduce((acc: any, e: any) => {acc[e.code] = e; return acc}, {}),
    featureType: 'pump',
    settings: {
      invMaxAin1: {code: 'invMaxAin1', assetField: 'invMaxAin1', type: 'number'},
      invMinAin1: {code: 'invMinAin1', assetField: 'invMinAin1', type: 'number'},
      invPhysAin1: {code: 'invPhysAin1', assetField: 'invPhysAin1', type: 'number'},
      invSensAin1: {code: 'invSensAin1', assetField: 'invSensAin1', type: 'number'},
      invMaxAin2: {code: 'invMaxAin2', assetField: 'invMaxAin2', type: 'number'},
      invMinAin2: {code: 'invMinAin2', assetField: 'invMinAin2', type: 'number'},
      invPhysAin2: {code: 'invPhysAin2', assetField: 'invPhysAin2', type: 'number'},
      invSensAin2: {code: 'invSensAin2', assetField: 'invSensAin2', type: 'number'},
      invMaxFreq: {code: 'invMaxFreq', assetField: 'invMaxFreq', type: 'number'},
      invMinFreq: {code: 'invMinFreq', assetField: 'invMinFreq', type: 'number'},
      invMotNomSpeed: {code: 'invMotNomSpeed', assetField: 'invMotNomSpeed', type: 'number'},
    },
    stateProperties: {},
  },
}
// console.log(`DeviceModules ${JSON.stringify(DeviceModules,null,2)}`)

/**
 * DeviceTypes describe actual devices and are concrete groupings of logical functionality (device modules).
 * e.g. an irriCtrl is an actual device that incorporates the functionality of 
 * both an irriPointCtrl and a pumpInvrtCtrl.
 */
const DeviceCategories = {
  irriCtrl: {
    code: 'irriCtrl',
    transKey: 'device.category',
    modules: [DeviceModules.remoteDevice, DeviceModules.pumpCtrl, DeviceModules.irriPointCtrl]
    // modules: ['remoteDevice', 'pumpCtrl, 'irriPointCtrl']
  },
  pureInvrtCtrl: {
    code: 'pureInvrtCtrl',
    transKey: 'device.category',
    modules: [DeviceModules.remoteDevice, DeviceModules.pumpCtrl, DeviceModules.invrtCtrl]
    // modules: ['remoteDevice', 'pumpCtrl, 'invrtCtrl']
  },
  irriHybridCtrl: {
    code: 'irriHybridCtrl',
    transKey: 'device.category',
    modules: [DeviceModules.remoteDevice, DeviceModules.pumpCtrl, DeviceModules.irriPointCtrl, DeviceModules.invrtCtrl]
    // modules: ['remoteDevice', 'pumpCtrl, 'irriPointCtrl', 'invrtCtrl']
  },
}
// console.log(`DeviceCategories ${JSON.stringify(DeviceCategories,null,2)}`)
const InverterTypes = {
  abb_acs_180: {
    code: 'abb_acs_180',
    pumpControl: 1,
    errors: {
      '0x0000': {code: '0x0000', transKey: 'inverterType.abb_acs_180.errors', isOK: true}, 
      '0x2310': {code: '0x2310', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x2330': {code: '0x2330', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x2340': {code: '0x2340', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x2381': {code: '0x2381', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x3130': {code: '0x3130', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x3181': {code: '0x3181', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x3210': {code: '0x3210', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x3220': {code: '0x3220', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x3381': {code: '0x3381', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x4110': {code: '0x4110', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x4210': {code: '0x4210', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x4290': {code: '0x4290', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x42F1': {code: '0x42F1', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x4310': {code: '0x4310', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x4380': {code: '0x4380', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x4981': {code: '0x4981', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x4982': {code: '0x4982', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x5080': {code: '0x5080', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x7121': {code: '0x7121', transKey: 'inverterType.abb_acs_180.errors'}, 
      '0x7122': {code: '0x7122', transKey: 'inverterType.abb_acs_180.errors'}, 
      other: {code: 'other', transKey: 'inverterType.abb_acs_180.errors'},
    },
    warnings: {
      '0x0000': {code: '0x0000', transKey: 'inverterType.abb_acs_180.warnings', isOK: true}, 
      other: {code: 'other', transKey: 'inverterType.abb_acs_180.warnings'},
    },
  },
  abb_acq_580: {
    code: 'abb_acq_580',
    pumpControl: 2,
    errors: {
      '0x0000': {code: '0x0000', transKey: 'inverterType.abb_acq_580.errors', isOK: true}, 
      '0x2310': {code: '0x2310', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x2330': {code: '0x2330', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x2340': {code: '0x2340', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x2381': {code: '0x2381', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x3130': {code: '0x3130', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x3181': {code: '0x3181', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x3210': {code: '0x3210', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x3220': {code: '0x3220', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x3381': {code: '0x3381', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x4110': {code: '0x4110', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x4210': {code: '0x4210', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x4290': {code: '0x4290', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x42F1': {code: '0x42F1', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x4310': {code: '0x4310', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x4380': {code: '0x4380', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x4981': {code: '0x4981', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x4982': {code: '0x4982', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x5080': {code: '0x5080', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x7121': {code: '0x7121', transKey: 'inverterType.abb_acq_580.errors'}, 
      '0x7122': {code: '0x7122', transKey: 'inverterType.abb_acq_580.errors'}, 
      other: {code: 'other', transKey: 'inverterType.abb_acq_580.errors'},
    },
    warnings: {
      '0x0000': {code: '0x0000', transKey: 'inverterType.abb_acq_580.warnings', isOK: true}, 
      other: {code: 'other', transKey: 'inverterType.abb_acq_580.warnings'},
    },
  },
  delta_el: {
    code: 'delta_el',
    pumpControl: 3,
    errors: {
      '0x0000': {code: '0x0000', transKey: 'inverterType.delta_el.errors', isOK: true},
      '14': {code: '14', transKey: 'inverterType.delta_el.errors'},
      '16': {code: '16', transKey: 'inverterType.delta_el.errors'},
      '17': {code: '17', transKey: 'inverterType.delta_el.errors'},
      '18': {code: '18', transKey: 'inverterType.delta_el.errors'},
      '19': {code: '19', transKey: 'inverterType.delta_el.errors'},
      '20': {code: '20', transKey: 'inverterType.delta_el.errors'},
      '21': {code: '21', transKey: 'inverterType.delta_el.errors'},
      '22': {code: '22', transKey: 'inverterType.delta_el.errors'},
      '23': {code: '23', transKey: 'inverterType.delta_el.errors'},
      '24': {code: '24', transKey: 'inverterType.delta_el.errors'},
      '25': {code: '25', transKey: 'inverterType.delta_el.errors'},
      '26': {code: '26', transKey: 'inverterType.delta_el.errors'},
      '27': {code: '27', transKey: 'inverterType.delta_el.errors'},
      '28': {code: '28', transKey: 'inverterType.delta_el.errors'},
      '32': {code: '32', transKey: 'inverterType.delta_el.errors'},
      '34': {code: '34', transKey: 'inverterType.delta_el.errors'},
      other: {code: 'other', transKey: 'inverterType.abb_delta_el.errors'},
    },
    warnings: {
      '0x0000': {code: '0x0000', transKey: 'inverterType.delta_el.warnings', isOK: true}, 
      other: {code: 'other', transKey: 'inverterType.delta_el.warnings'},
    },
  },
  delta_cp2000: {
    code: 'delta_cp2000',
    pumpControl: 4,
    errors: {
      '0x0000': {code: '0x0000', transKey: 'inverterType.delta_cp2000.errors', isOK: true},
      // '0': {code: '0', transKey: 'inverterType.delta_cp2000.errors', isOK: true}, 
      '1': {code: '1', transKey: 'inverterType.delta_cp2000.errors'},
      '2': {code: '2', transKey: 'inverterType.delta_cp2000.errors'},
      '3': {code: '3', transKey: 'inverterType.delta_cp2000.errors'},
      '4': {code: '4', transKey: 'inverterType.delta_cp2000.errors'},
      '5': {code: '5', transKey: 'inverterType.delta_cp2000.errors'},
      '6': {code: '6', transKey: 'inverterType.delta_cp2000.errors'},
      '7': {code: '7', transKey: 'inverterType.delta_cp2000.errors'},
      '8': {code: '8', transKey: 'inverterType.delta_cp2000.errors'},
      '9': {code: '9', transKey: 'inverterType.delta_cp2000.errors'},
      '10': {code: '10', transKey: 'inverterType.delta_cp2000.errors'},
      '11': {code: '11', transKey: 'inverterType.delta_cp2000.errors'},
      '12': {code: '12', transKey: 'inverterType.delta_cp2000.errors'},
      '13': {code: '13', transKey: 'inverterType.delta_cp2000.errors'},
      '14': {code: '14', transKey: 'inverterType.delta_cp2000.errors'},
      '15': {code: '15', transKey: 'inverterType.delta_cp2000.errors'},
      '16': {code: '16', transKey: 'inverterType.delta_cp2000.errors'},
      '17': {code: '17', transKey: 'inverterType.delta_cp2000.errors'},
      '18': {code: '18', transKey: 'inverterType.delta_cp2000.errors'},
      '19': {code: '19', transKey: 'inverterType.delta_cp2000.errors'},
      '21': {code: '21', transKey: 'inverterType.delta_cp2000.errors'},
      '22': {code: '22', transKey: 'inverterType.delta_cp2000.errors'},
      '23': {code: '23', transKey: 'inverterType.delta_cp2000.errors'},
      '24': {code: '24', transKey: 'inverterType.delta_cp2000.errors'},
      '26': {code: '26', transKey: 'inverterType.delta_cp2000.errors'},
      '27': {code: '27', transKey: 'inverterType.delta_cp2000.errors'},
      '28': {code: '28', transKey: 'inverterType.delta_cp2000.errors'},
      '30': {code: '30', transKey: 'inverterType.delta_cp2000.errors'},
      '31': {code: '31', transKey: 'inverterType.delta_cp2000.errors'},
      '33': {code: '33', transKey: 'inverterType.delta_cp2000.errors'},
      '34': {code: '34', transKey: 'inverterType.delta_cp2000.errors'},
      '35': {code: '35', transKey: 'inverterType.delta_cp2000.errors'},
      '36': {code: '36', transKey: 'inverterType.delta_cp2000.errors'},
      '37': {code: '37', transKey: 'inverterType.delta_cp2000.errors'},
      '38': {code: '38', transKey: 'inverterType.delta_cp2000.errors'},
      '39': {code: '39', transKey: 'inverterType.delta_cp2000.errors'},
      '40': {code: '40', transKey: 'inverterType.delta_cp2000.errors'},
      '41': {code: '41', transKey: 'inverterType.delta_cp2000.errors'},
      '48': {code: '48', transKey: 'inverterType.delta_cp2000.errors'},
      '49': {code: '49', transKey: 'inverterType.delta_cp2000.errors'},
      '50': {code: '50', transKey: 'inverterType.delta_cp2000.errors'},
      '51': {code: '51', transKey: 'inverterType.delta_cp2000.errors'},
      '52': {code: '52', transKey: 'inverterType.delta_cp2000.errors'},
      '54': {code: '54', transKey: 'inverterType.delta_cp2000.errors'},
      '55': {code: '55', transKey: 'inverterType.delta_cp2000.errors'},
      '56': {code: '56', transKey: 'inverterType.delta_cp2000.errors'},
      '57': {code: '57', transKey: 'inverterType.delta_cp2000.errors'},
      '58': {code: '58', transKey: 'inverterType.delta_cp2000.errors'},
      '59': {code: '59', transKey: 'inverterType.delta_cp2000.errors'},
      '60': {code: '60', transKey: 'inverterType.delta_cp2000.errors'},
      '61': {code: '61', transKey: 'inverterType.delta_cp2000.errors'},
      '62': {code: '62', transKey: 'inverterType.delta_cp2000.errors'},
      '63': {code: '63', transKey: 'inverterType.delta_cp2000.errors'},
      '64': {code: '64', transKey: 'inverterType.delta_cp2000.errors'},
      '73': {code: '73', transKey: 'inverterType.delta_cp2000.errors'},
      '74': {code: '74', transKey: 'inverterType.delta_cp2000.errors'},
      '79': {code: '79', transKey: 'inverterType.delta_cp2000.errors'},
      '80': {code: '80', transKey: 'inverterType.delta_cp2000.errors'},
      '81': {code: '81', transKey: 'inverterType.delta_cp2000.errors'},
      '82': {code: '82', transKey: 'inverterType.delta_cp2000.errors'},
      '83': {code: '83', transKey: 'inverterType.delta_cp2000.errors'},
      '84': {code: '4', transKey: 'inverterType.delta_cp2000.errors'},
      '101': {code: '101', transKey: 'inverterType.delta_cp2000.errors'},
      '102': {code: '102', transKey: 'inverterType.delta_cp2000.errors'},
      '103': {code: '103', transKey: 'inverterType.delta_cp2000.errors'},
      '104': {code: '104', transKey: 'inverterType.delta_cp2000.errors'},
      '105': {code: '105', transKey: 'inverterType.delta_cp2000.errors'},
      '106': {code: '106', transKey: 'inverterType.delta_cp2000.errors'},
      '107': {code: '107', transKey: 'inverterType.delta_cp2000.errors'},
      other: {code: 'other', transKey: 'inverterType.delta_cp2000.errors'},
    },
    warnings: {
      '0': {code: '0', transKey: 'inverterType.delta_cp2000.warnings', isOK: true}, 
      other: {code: 'other', transKey: 'inverterType.delta_cp2000.warnings'},
    },
  },
  other: {
    code: 'other',
    errors: {
      '100': {code: '100', transKey: 'inverterType.other.errors'},
      '200': {code: '200', transKey: 'inverterType.other.errors'},
      other: {code: 'other', transKey: 'inverterType.other.errors'},
    },
    warnings: {
      other: {code: 'other', transKey: 'inverterType.other.warnings'},
    },
  },
  unknown: {
    code: 'unknown',
    errors: {
      other: {code: 'other', transKey: 'inverterType.unknown.errors'},
    },
    warnings: {
      other: {code: 'other', transKey: 'inverterType.unknown.warnings'},
    },
  },
  noInverter: {
    code: 'noInverter',
    pumpControl: 0,
    errors: {
    },
    warnings: {
    },
  },
}

const MqttBrokers = {
  hivemq_dko: {
    code: 'hivemq_dko',
    label: 'HiveMQ-A',
    host: 'a7b09663d7864936aad8d42973a809f1.s2.eu.hivemq.cloud',
    username: 'dkMqtt1',
    password: 'X^C@w7Yn^#6Hh!d',
  },
  hivemq_yko: {
    code: 'hivemq_yko',
    label: 'HiveMQ-B',
    host: '4c397cacd4834c16952119ac8b52dca2.s2.eu.hivemq.cloud',
    username: 'irriclient',
    password: 'rriclienti',
  },
  emqx_yko: {
    code: 'emqx_yko',
    label: 'EMQX-A',
    host: 'j21eaa1b.ala.us-east-1.emqxsl.com',
    username: 'irriclient',
    password: 'rriclienti',
  },
  myqtthub_yko: {
    code: 'myqtthub_yko',
    label: 'MYQTTHUB-A',
    host: 'node02.myqtthub.com',
    username: 'irrictrl0Le6z',
    password: '63442aae5b96aa',
  },
}

const FTC = {
  aqueduct: { code: 'aqueduct', collection: 'aqueducts', label: 'Aqueduct', icon: 'tint', prefix: 'AQ', geometryType: 'Point', isGroup: true, color: '#1e88e5' },
  hydrant: { code: 'hydrant', collection: 'hydrants', label: 'Hydrant', prefix: 'FH', icon: 'hydrant', geometryType: 'Point', color: '#42a5f5' },
  hydrometer: { code: 'hydrometer', collection: 'hydrometers', label: 'Hydrometer', prefix: 'HD', icon: 'hydrometer', geometryType: 'Point', color: '#42a5f5', minZoom: 19 },
  junction: { code: 'junction', collection: 'junctions', label: 'Junction', prefix: 'JN', icon: 'junction', geometryType: 'Point', color: '#42a5f5', minZoom: 18 },
  landparcel: { code: 'landparcel', collection: 'landparcels', label: 'Landparcel', prefix: 'LP', icon: 'landparcel', geometryType: 'Polygon', color: '#42a5f5', accessRight: 'Vwgrln' },
  pipe: { code: 'pipe', collection: 'pipes', label: 'Pipe', icon: 'pipe', prefix: 'PP', geometryType: 'LineString', color: '#2962ff', minZoom: 15 },
  pump: { code: 'pump', collection: 'pumps', label: 'Pump', icon: 'pump', prefix: 'PM', geometryType: 'Point', color: '#1e88e5' },
  pumpcluster: { code: 'pumpcluster', collection: 'pumpclusters', label: 'Pumpcluster', icon: 'tint', prefix: 'PG', geometryType: 'Point', isGroup: true, color: '#1e88e5' },
  reservoir: { code: 'reservoir', collection: 'reservoirs', label: 'Reservoir', prefix: 'SR', icon: 'reservoir', geometryType: 'Point', color: '#42a5f5' },
  samplingPoint: { code: 'samplingPoint', collection: 'samplingPoints', label: 'Sampling Point', prefix: 'SP', icon: 'samplingPoint', geometryType: 'Point', color: '#42a5f5' },
  station: { code: 'station', collection: 'stations', label: 'Local Station', prefix: 'ST', icon: 'station', geometryType: 'Point', isGroup: true, color: '#42a5f5' },
  switch: { code: 'switch', collection: 'switches', label: 'Switch', icon: 'switch', prefix: 'SW', geometryType: 'Point', color: '#1e88e5', accessRight: 'Vwrrgt1' },
  tank: { code: 'tank', collection: 'tanks', label: 'Tank', prefix: 'TN', icon: 'tank', geometryType: 'Point', color: '#1565c0' },
  valve: { code: 'valve', collection: 'valves', label: 'Valve', prefix: 'VL', icon: 'valve', geometryType: 'Point', color: '#42a5f5', minZoom: 18 },
  wszone: { code: 'wszone', collection: 'wszones', label: 'Water Supply Zone', prefix: 'WS', icon: 'wszone', geometryType: 'Polygon', isGroup: true, color: '#2979ff' },
}

const FeatureInnerSvg = {
  hydrant: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="hydrant_map_icon">
      <path d="M7.5 16.5C7.5 16.2239 7.72386 16 8 16H10C10.2761 16 10.5 16.2239 10.5 16.5V18.3571C10.5 18.6333 10.2761 18.8571 10 18.8571H8C7.72386 18.8571 7.5 18.6333 7.5 18.3571V16.5Z" fill="white" stroke="#979797"/>
      <path d="M19.5 16.5C19.5 16.2239 19.7239 16 20 16H22C22.2761 16 22.5 16.2239 22.5 16.5V18.3571C22.5 18.6333 22.2761 18.8571 22 18.8571H20C19.7239 18.8571 19.5 18.6333 19.5 18.3571V16.5Z" fill="white" stroke="#979797"/>
      <rect x="10.5" y="11.5" width="9" height="13" fill="#9EE1E1" stroke="#979797"/>
      <path d="M8.5 25C8.5 24.7239 8.72386 24.5 9 24.5H21C21.2761 24.5 21.5 24.7239 21.5 25V27C21.5 27.2761 21.2761 27.5 21 27.5H9C8.72386 27.5 8.5 27.2761 8.5 27V25Z" fill="white" stroke="#979797"/>
      <rect x="13.5" y="2.5" width="3" height="2.85714" rx="0.5" fill="white" stroke="#979797"/>
      <path d="M10.1977 8.57141C10.4437 6.59826 12.1269 5.07141 14.1667 5.07141H15.8334C17.8732 5.07141 19.5564 6.59826 19.8025 8.57141H10.1977Z" fill="#9EE1E1" stroke="#979797"/>
      <path d="M17.1667 17.75C17.1667 18.8771 16.214 19.8214 15 19.8214C13.7861 19.8214 12.8334 18.8771 12.8334 17.75C12.8334 16.623 13.7861 15.6786 15 15.6786C16.214 15.6786 17.1667 16.623 17.1667 17.75Z" fill="white" stroke="#979797"/>
      <path d="M8.5 9C8.5 8.72386 8.72386 8.5 9 8.5H21C21.2761 8.5 21.5 8.72386 21.5 9V11C21.5 11.2761 21.2761 11.5 21 11.5H9C8.72386 11.5 8.5 11.2761 8.5 11V9Z" fill="white" stroke="#979797"/>
    </svg>`,
  hydrometer: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="hydrometer_map_icon">
      <mask id="path-1-inside-1" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 25C21.0751 25 26 20.0751 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 20.0751 8.92487 25 15 25ZM15 20C18.3137 20 21 17.3137 21 14C21 10.6863 18.3137 8 15 8C11.6863 8 9 10.6863 9 14C9 17.3137 11.6863 20 15 20Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 25C21.0751 25 26 20.0751 26 14C26 7.92487 21.0751 3 15 3C8.92487 3 4 7.92487 4 14C4 20.0751 8.92487 25 15 25ZM15 20C18.3137 20 21 17.3137 21 14C21 10.6863 18.3137 8 15 8C11.6863 8 9 10.6863 9 14C9 17.3137 11.6863 20 15 20Z" fill="#9EE1E1" stroke="#979797"/>
      <path d="M17.9022 9.75949L17.9022 9.75949L13.425 14.5291C13.2045 14.7641 13.2605 15.1432 13.5396 15.3044L15.2811 16.3099C15.5602 16.471 15.9166 16.3299 16.0098 16.0215L17.9022 9.75949Z" fill="#9EE1E1" stroke="#979797"/>
      <path d="M8 22V27H22V22" stroke="#979797" fill="none"/>
    </svg>`,
  junction: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="junction_map_icon">
      <mask id="path-1-inside-1" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21 6.06087V4.5873L13.3267 4.58731V6.06088L14.2689 6.06088V10.5241C14.2689 11.4489 13.5493 12.1986 12.6616 12.1986H8.47357V11.5199H7V18.8619H8.47357V18.2268L12.2689 18.2268C13.3735 18.2268 14.2689 19.1223 14.2689 20.2268V25.2222H20.055L20.055 10.5241V6.06087L21 6.06087Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21 6.06087V4.5873L13.3267 4.58731V6.06088L14.2689 6.06088V10.5241C14.2689 11.4489 13.5493 12.1986 12.6616 12.1986H8.47357V11.5199H7V18.8619H8.47357V18.2268L12.2689 18.2268C13.3735 18.2268 14.2689 19.1223 14.2689 20.2268V25.2222H20.055L20.055 10.5241V6.06087L21 6.06087Z" fill="#9EE1E1" stroke="#979797"/>
    </svg>`,
  landparcel: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="landparcel_map_icon">
      <path d="M0.5 6.12679C0.5 5.49174 0.8999 4.92551 1.49839 4.71315L11.7536 1.0742C12.4778 0.817216 13.2791 1.14912 13.6095 1.84294L16.766 8.47156C16.9361 8.82872 16.9582 9.23861 16.8275 9.61199L14.5683 16.067C14.3577 16.6686 13.7899 17.0714 13.1525 17.0714H2C1.17157 17.0714 0.5 16.3999 0.5 15.5714V6.12679Z" fill="" stroke="#979797"/>
      <path d="M8.57142 12.8571H4.28571V12H6.74999C6.43713 10.7957 5.48999 9.85286 4.28571 9.54C4.55999 9.47143 4.84713 9.42857 5.14285 9.42857C7.03713 9.42857 8.57142 10.9629 8.57142 12.8571ZM12.8571 9.54C12.5828 9.47143 12.2957 9.42857 12 9.42857C10.7443 9.42857 9.65142 10.1057 9.05142 11.1129C9.17571 11.3957 9.27856 11.6914 9.33856 12C9.39428 12.2786 9.42428 12.5657 9.42428 12.8571H10.2814H12.8528V12H10.3886C10.7057 10.7957 11.6528 9.85286 12.8571 9.54ZM10.1314 9.00857C10.4657 8.11286 11.0871 7.36286 11.8843 6.86571C10.0457 6.92571 8.57142 8.43 8.57142 10.2857C8.57142 10.29 8.57142 10.2943 8.57142 10.2943C8.97856 9.75 9.51428 9.30857 10.1314 9.00857ZM8.32285 8.07857C7.96285 7.14 7.23428 6.38143 6.29999 6C6.91713 6.79714 7.28571 7.79143 7.28571 8.87571C7.28571 8.96571 7.27285 9.05143 7.26856 9.13714C7.45285 9.24 7.62428 9.36 7.79142 9.48857C7.88142 8.98286 8.06999 8.50714 8.32285 8.07857Z" fill="#979797"/>
    </svg>
  `,
  pipe: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="pipe_map_icon">
      <mask id="path-1-inside-1" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27197 4.28566H6.54963V5.50503H11.3699C15.0584 5.50503 18.0486 8.37509 18.0486 11.9155V15.2601H12.8218V11.9155C12.8218 11.1458 12.1717 10.5219 11.3699 10.5219H6.54963V11.6019H5.27197V10.5219V5.50503V4.28566ZM24.4369 21.2175H19.6166C18.8148 21.2175 18.1648 20.5936 18.1648 19.8239V17.699H19.3263L19.3263 16.4796H18.1648V16.4793H12.938V16.4796H11.6603V17.699H12.938V19.8239C12.938 23.3643 15.9281 26.2344 19.6166 26.2344H24.4369V27.454H25.7146V26.2344V21.2175V20.1377H24.4369V21.2175Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27197 4.28566H6.54963V5.50503H11.3699C15.0584 5.50503 18.0486 8.37509 18.0486 11.9155V15.2601H12.8218V11.9155C12.8218 11.1458 12.1717 10.5219 11.3699 10.5219H6.54963V11.6019H5.27197V10.5219V5.50503V4.28566ZM24.4369 21.2175H19.6166C18.8148 21.2175 18.1648 20.5936 18.1648 19.8239V17.699H19.3263L19.3263 16.4796H18.1648V16.4793H12.938V16.4796H11.6603V17.699H12.938V19.8239C12.938 23.3643 15.9281 26.2344 19.6166 26.2344H24.4369V27.454H25.7146V26.2344V21.2175V20.1377H24.4369V21.2175Z" fill="#9EE1E1" stroke="#979797"/>
    </svg>`,
  pump: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="pump_map_icon">
      <rect x="6.5" y="7.5" width="17" height="15" rx="0.5" fill="#9EE1E1" stroke="#979797"/>
      <rect x="11.5" y="4.5" width="7" height="3" rx="0.5" fill="none" stroke="#979797"/>
      <path d="M10 12L20 12" stroke="#979797" stroke-linecap="round"/>
      <path d="M10 15L20 15" stroke="#979797" stroke-linecap="round"/>
      <path d="M10 18L20 18" stroke="#979797" stroke-linecap="round"/>
      <rect x="6.5" y="22.5" width="4" height="3" rx="0.5" fill="none" stroke="#979797"/>
      <rect x="19.5" y="22.5" width="4" height="3" rx="0.5" fill="none" stroke="#979797"/>
      <path d="M4.5 8C4.5 7.72386 4.72386 7.5 5 7.5H6C6.27614 7.5 6.5 7.72386 6.5 8V22C6.5 22.2761 6.27614 22.5 6 22.5H5C4.72386 22.5 4.5 22.2761 4.5 22V8Z" fill="none" stroke="#979797"/>
      <path d="M2.5 11C2.5 10.7239 2.72386 10.5 3 10.5H4C4.27614 10.5 4.5 10.7239 4.5 11V20C4.5 20.2761 4.27614 20.5 4 20.5H3C2.72386 20.5 2.5 20.2761 2.5 20V11Z" fill="#9EE1E1" stroke="#979797"/>
      <rect x="23.5" y="7.5" width="2" height="15" rx="0.5" fill="none" stroke="#979797"/>
      <rect x="25.5" y="10.5" width="2" height="10" rx="0.5" fill="#9EE1E1" stroke="#979797"/>
    </svg>
  `,
  reservoir: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="reservoir_map_icon">
      <mask id="path-1-inside-1" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6334 2.27903L10 5.67949L6.60531 8.98232C6.21832 9.35885 6 9.87585 6 10.4158V23C6 24.1046 6.89543 25 8 25H22C23.1046 25 24 24.1046 24 23V10.4158C24 9.87585 23.7817 9.35885 23.3947 8.98232L20 5.67949L16.3666 2.27903C15.5977 1.55935 14.4023 1.55935 13.6334 2.27903ZM17.1214 18.2958C17.1923 18.239 17.2588 18.1802 17.3209 18.1191C18.1238 17.3348 18.2304 16.2185 17.557 15.3391L16.4164 13.8493L15.8018 13.047L15.0001 12L12.9786 14.6397L12.443 15.3391C12.1453 15.7279 12 16.1631 12 16.5953C12 17.2168 12.3 17.8321 12.8786 18.2958C13.4646 18.7651 14.2324 19 15.0001 19C15.7679 19 16.5356 18.7651 17.1214 18.2958Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6334 2.27903L10 5.67949L6.60531 8.98232C6.21832 9.35885 6 9.87585 6 10.4158V23C6 24.1046 6.89543 25 8 25H22C23.1046 25 24 24.1046 24 23V10.4158C24 9.87585 23.7817 9.35885 23.3947 8.98232L20 5.67949L16.3666 2.27903C15.5977 1.55935 14.4023 1.55935 13.6334 2.27903ZM17.1214 18.2958C17.1923 18.239 17.2588 18.1802 17.3209 18.1191C18.1238 17.3348 18.2304 16.2185 17.557 15.3391L16.4164 13.8493L15.8018 13.047L15.0001 12L12.9786 14.6397L12.443 15.3391C12.1453 15.7279 12 16.1631 12 16.5953C12 17.2168 12.3 17.8321 12.8786 18.2958C13.4646 18.7651 14.2324 19 15.0001 19C15.7679 19 16.5356 18.7651 17.1214 18.2958Z" fill="#9EE1E1" stroke="#979797"/>
    </svg>
  `,
  samplingPoint: `
    <svg viewBox="-3 0 33 30" x="14" y="-3" width="22" name="samplingPoint_map_icon">
      <path d="M10.6079 16.9405L18.4062 7.22647L22.831 10.7787L15.0327 20.4927C14.5492 21.0951 13.7965 21.416 13.0271 21.3477C12.5486 21.3053 12.0805 21.5048 11.7798 21.8794L11.0968 22.7302C10.6137 23.332 9.7987 23.4476 9.27157 23.0244C8.80638 22.6509 8.74745 21.9234 9.18633 21.3767L9.95725 20.4163C10.258 20.0417 10.3516 19.5416 10.2067 19.0836C9.97375 18.3471 10.1243 17.5429 10.6079 16.9405Z" fill="none" stroke="#979797"/>
      <path d="M19.7288 5.5792L20.7492 4.30818C21.7301 3.08628 23.5158 2.89093 24.7377 3.87185C25.9596 4.85277 26.1549 6.63851 25.174 7.8604L24.1537 9.13143L19.7288 5.5792Z" fill="#9EE1E1" stroke="#979797"/>
      <rect x="18.0641" y="4.09882" width="10.1239" height="3.3862" rx="1.5" transform="rotate(38.757 18.0641 4.09882)" fill="#9EE1E1" stroke="#979797"/>
      <path d="M6.05243 25.7961L6.05367 25.7946C6.58225 25.1751 6.65044 24.2993 6.20935 23.608C6.20935 23.608 6.20935 23.608 6.20935 23.608L5.25889 22.1184L5.25884 22.1183L4.74672 21.316L4.74667 21.3159L4.50009 20.9295L3.237 22.9087L3.23698 22.9087L2.79067 23.608C2.79065 23.608 2.79064 23.608 2.79062 23.6081C2.59453 23.9154 2.5 24.2571 2.5 24.5953C2.5 25.0818 2.69526 25.5666 3.07866 25.9353L6.05243 25.7961ZM6.05243 25.7961C6.01244 25.8433 5.96877 25.8897 5.92128 25.9354M6.05243 25.7961L5.92128 25.9354M5.92128 25.9354C5.53191 26.3098 5.01869 26.5 4.50011 26.5M5.92128 25.9354L4.50011 26.5M4.50011 26.5C3.98159 26.5 3.46835 26.3098 3.07873 25.9354L4.50011 26.5Z" fill="#9EE1E1" stroke="#979797"/>
    </svg>
  `,
  station: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="station_map_icon">
      <path d="M25 25.5H20.3889L20.3889 13.0555H22.9445H24C24.8285 13.0555 25.5 13.7271 25.5 14.5555V25C25.5 25.2761 25.2762 25.5 25 25.5Z" fill="#9EE1E1" stroke="#979797"/>
      <mask id="path-2-inside-1" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4C4.89543 4 4 4.89543 4 6.00001L4.00005 25C4.00005 25.5523 4.44776 26 5.00005 26H21.1111V6C21.1111 4.89543 20.2157 4 19.1111 4H12.5556H6ZM15.846 21.3684H10.5811V24.8421H15.846V21.3684ZM8.88888 14.421H12.8376V16.7368H8.88888V14.421ZM12.8376 8.63152H8.88888V10.9473H12.8376V8.63152Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4C4.89543 4 4 4.89543 4 6.00001L4.00005 25C4.00005 25.5523 4.44776 26 5.00005 26H21.1111V6C21.1111 4.89543 20.2157 4 19.1111 4H12.5556H6ZM15.846 21.3684H10.5811V24.8421H15.846V21.3684ZM8.88888 14.421H12.8376V16.7368H8.88888V14.421ZM12.8376 8.63152H8.88888V10.9473H12.8376V8.63152Z" fill="#9EE1E1" stroke="#979797"/>
      <path d="M4 6.00001L5 6L4 6.00001ZM4.00005 25L5.00005 25L4.00005 25ZM21.1111 26V27H22.1111V26H21.1111ZM10.5811 21.3684V20.3684H9.58108V21.3684H10.5811ZM15.846 21.3684H16.846V20.3684H15.846V21.3684ZM10.5811 24.8421H9.58108V25.8421H10.5811V24.8421ZM15.846 24.8421V25.8421H16.846V24.8421H15.846ZM12.8376 14.421H13.8376V13.421H12.8376V14.421ZM8.88888 14.421V13.421H7.88888V14.421H8.88888ZM12.8376 16.7368V17.7368H13.8376V16.7368H12.8376ZM8.88888 16.7368H7.88888V17.7368H8.88888V16.7368ZM8.88888 8.63152V7.63152H7.88888V8.63152H8.88888ZM12.8376 8.63152H13.8376V7.63152H12.8376V8.63152ZM8.88888 10.9473H7.88888V11.9473H8.88888V10.9473ZM12.8376 10.9473V11.9473H13.8376V10.9473H12.8376ZM5 6C5 5.44772 5.44771 5 6 5V3C4.34314 3 3 4.34315 3 6.00001L5 6ZM5.00005 25L5 6L3 6.00001L3.00005 25L5.00005 25ZM5.00005 25L5.00005 25L3.00005 25C3.00005 26.1046 3.89548 27 5.00005 27V25ZM21.1111 25H5.00005V27H21.1111V25ZM20.1111 6V26H22.1111V6H20.1111ZM19.1111 5C19.6634 5 20.1111 5.44771 20.1111 6H22.1111C22.1111 4.34315 20.768 3 19.1111 3V5ZM12.5556 5H19.1111V3H12.5556V5ZM6 5H12.5556V3H6V5ZM10.5811 22.3684H15.846V20.3684H10.5811V22.3684ZM11.5811 24.8421V21.3684H9.58108V24.8421H11.5811ZM15.846 23.8421H10.5811V25.8421H15.846V23.8421ZM14.846 21.3684V24.8421H16.846V21.3684H14.846ZM12.8376 13.421H8.88888V15.421H12.8376V13.421ZM13.8376 16.7368V14.421H11.8376V16.7368H13.8376ZM8.88888 17.7368H12.8376V15.7368H8.88888V17.7368ZM7.88888 14.421V16.7368H9.88888V14.421H7.88888ZM8.88888 9.63152H12.8376V7.63152H8.88888V9.63152ZM9.88888 10.9473V8.63152H7.88888V10.9473H9.88888ZM12.8376 9.94731H8.88888V11.9473H12.8376V9.94731ZM11.8376 8.63152V10.9473H13.8376V8.63152H11.8376Z" fill="#979797" mask="url(#path-2-inside-1)"/>
    </svg>
  `,
  switch: `
    <svg viewBox="-2 -2 27 27" x="14" y="-3" width="22" name="switch_map_icon" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9455 3.2727V2.8727H18.5455H5.45455H5.05455V3.2727V20.7272V21.1272H5.45455H18.5455H18.9455V20.7272V3.2727ZM9.81819 9.41815H9.41819V9.81815V10.5091H6.94546V4.76361H17.0546V10.5091H14.5818V9.81815V9.41815H14.1818H9.81819ZM5.45455 1.49088H18.5455C19.5245 1.49088 20.3273 2.29361 20.3273 3.2727V20.7272C20.3273 21.7063 19.5245 22.5091 18.5455 22.5091H5.45455C4.47547 22.5091 3.67274 21.7063 3.67274 20.7272V3.2727C3.67274 2.29361 4.47547 1.49088 5.45455 1.49088ZM17.0546 13.4909V19.2363H6.94546V13.4909H17.0546Z" fill="#9EE1E1" stroke="#979797" stroke-width="0.8"/>
    </svg>
  `,
  switchOLD: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="switchOLD_map_icon">
      <path d="M22.5 5V25H7.5V5H22.5ZM22.5 2.5H7.5C6.125 2.5 5 3.625 5 5V25C5 26.375 6.125 27.5 7.5 27.5H22.5C23.875 27.5 25 26.375 25 25V5C25 3.625 23.875 2.5 22.5 2.5ZM8.75 23.75H21.25V16.25H8.75V23.75ZM12.5 12.5H17.5V13.75H21.25V6.25H8.75V13.75H12.5V12.5Z" fill="#9EE1E1" stroke="#979797"/>
    </svg>
  `,
  tank: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="tank_map_icon">
      <path d="M9.29368 14.4309L6 15.2222V23C6 24.1046 6.89543 25 8 25H22C23.1046 25 24 24.1046 24 23V15.2222L20.7063 14.4309C19.5499 14.153 18.3321 14.3024 17.2771 14.8516C15.8499 15.5946 14.1501 15.5946 12.7229 14.8516C11.6679 14.3024 10.4501 14.153 9.29368 14.4309Z" fill="#9EE1E1"/>
      <path d="M3.75 15L7.93203 14.1779C9.34208 13.9007 10.8044 14.1056 12.084 14.7596V14.7596C13.9153 15.6956 16.0847 15.6956 17.916 14.7596V14.7596C19.1956 14.1056 20.6579 13.9007 22.068 14.1779L26.25 15" stroke="#979797" stroke-linecap="round"/>
      <rect x="5.57355" y="5.5" width="18.8529" height="19" rx="1.5" fill="none" stroke="#979797"/>
    </svg>
  `,
  valve: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="valve_map_icon">
      <rect x="8.22186" y="2.76318" width="13.5564" height="24.4736" rx="1.5" stroke="#979797" fill="none"/>
      <mask id="path-2-inside-1" fill="white">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26 14.8842C26 20.9593 21.0751 25.8842 15 25.8842C8.92492 25.8842 4.00006 20.9593 4.00006 14.8842C4.00006 8.80909 8.92492 3.88423 15 3.88423C21.0751 3.88423 26 8.80909 26 14.8842ZM20.1141 19.5698C18.8583 20.9743 17.0324 21.8583 15 21.8583C12.9917 21.8583 11.1851 20.9951 9.93077 19.6195L13.8218 17.373C14.1834 17.5281 14.5817 17.614 15.0001 17.614C15.4472 17.614 15.8713 17.516 16.2523 17.3401L20.1141 19.5698ZM21.6996 16.4737L17.9763 14.3241C17.8869 13.4226 17.3972 12.6388 16.6874 12.1531V8.35083C19.6592 9.10268 21.8583 11.7945 21.8583 15C21.8583 15.5059 21.8035 15.999 21.6996 16.4737ZM13.3129 8.35075C10.341 9.10249 8.14172 11.7944 8.14172 15C8.14172 15.5297 8.20177 16.0454 8.31545 16.5406L12.017 14.4035C12.0848 13.4687 12.5823 12.6529 13.3129 12.1529V8.35075Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26 14.8842C26 20.9593 21.0751 25.8842 15 25.8842C8.92492 25.8842 4.00006 20.9593 4.00006 14.8842C4.00006 8.80909 8.92492 3.88423 15 3.88423C21.0751 3.88423 26 8.80909 26 14.8842ZM20.1141 19.5698C18.8583 20.9743 17.0324 21.8583 15 21.8583C12.9917 21.8583 11.1851 20.9951 9.93077 19.6195L13.8218 17.373C14.1834 17.5281 14.5817 17.614 15.0001 17.614C15.4472 17.614 15.8713 17.516 16.2523 17.3401L20.1141 19.5698ZM21.6996 16.4737L17.9763 14.3241C17.8869 13.4226 17.3972 12.6388 16.6874 12.1531V8.35083C19.6592 9.10268 21.8583 11.7945 21.8583 15C21.8583 15.5059 21.8035 15.999 21.6996 16.4737ZM13.3129 8.35075C10.341 9.10249 8.14172 11.7944 8.14172 15C8.14172 15.5297 8.20177 16.0454 8.31545 16.5406L12.017 14.4035C12.0848 13.4687 12.5823 12.6529 13.3129 12.1529V8.35075Z" fill="#9EE1E1" stroke="#979797"/>
    </svg>
  `,
  wszone: `
    <svg viewBox="0 0 30 30" x="14" y="-3" width="22" name="wszone_map_icon">
      <path d="M7.27683 11.8225C7.40024 11.3609 7.73617 10.9857 8.18138 10.8123L19.7135 6.31921C20.5748 5.98367 21.5316 6.49931 21.7249 7.40314L24.9195 22.3386C25.136 23.351 24.2861 24.2732 23.2594 24.1399L6.32871 21.9411C5.42935 21.8243 4.83855 20.9423 5.07279 20.0661L7.27683 11.8225Z" fill="#9EE1E1" stroke="#979797"/>
    </svg>
  `,
}
type HighlightedFeatureType = {
  [key: string]: string[];
};
/**Used in networkElementsFilter */
const HIGHLIGHTED_FEATURES:HighlightedFeatureType = {
  hydrants: [],
  hydrometers: [],
  junctions: [],
  landparcels: [],
  pipes: [],
  pumps: [],
  reservoirs: [],
  samplingPoints: [],
  stations: [],
  switches: [],
  tanks: [],
  valves: [],
  wszones: [],
}
/**The initial state of the networkElementsFilter (used by search by coordinates/attributes) */
const DEFAULT_NETWORK_ELEMENTS_FILTER:NetworkElementsFilter = {
  isActive: false,
  hideFeatures: false,
  highlightedFeatures: HIGHLIGHTED_FEATURES,
  locationFilter: {
    isActive: false,
    center: [],
    highlightedFeatures: HIGHLIGHTED_FEATURES,
    coordinatesSystem: '',
    longitude: '',
    latitude: '',
    lngDegrees: '',
    latDegrees: '',
    radius: '',
    wgsUnits: '',
  },
  attributesFilter: {
    highlightedFeatures: HIGHLIGHTED_FEATURES,
    isActive: false,
    generic: {},
    aqueduct: {},
    hydrant: {},
    hydrometer: {},
    junction: {},
    landparcel: {},
    pipe: {},
    pump: {},
    pumpcluster: {},
    reservoir: {},
    samplingPoint: {},
    station: {},
    switch: {},
    tank: {},
    valve: {},
    wszone: {},
  },
}

const FEATURE_PROPERTIES = {
  aqueduct: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'station', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        docked: true, nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'station', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
        ]
      },
    ]
  },
  hydrant: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'hydrant', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks, filterableGeneric: true },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes, filterableGeneric: true },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'], filterableGeneric: true },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        nested: 'properties', code: 'dimensions', label: 'ne.characteristics', collapsible: true, icon: 'hydrant', fields: [
          { code: 'volumePerHour', label: 'fhd.volumePerHour', type: 'number', format: '9.999', unit: 'units.volumePerHour.m3/h', filterable: true },
          { code: 'pressure', label: 'fhd.pressure', type: 'number', format: '9.999', unit: 'units.pressure.bar', filterable: true },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'hydrant', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber', filterableGeneric: true },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions, filterableGeneric: true },
          { code: 'hydrantType', label: 'fhd.hydrantType', type: 'string', filterable: true },
        ]
      },
    ]
  },
  hydrometer: {
    groups: [
      {
        docked: true, code: 'basic', label: "ne.identity", collapsible: false, icon: 'hydrometer', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true, filterable: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true,nested: 'properties', code: 'billingCode', label: 'ne.identity.billingCode', type: 'string', filterable: true },
          { nested: 'properties', code: 'barCode', label: 'ne.identity.barCode', type: 'string' },
          { nested: 'properties', code: 'oldCode', label: 'ne.identity.oldCode', type: 'string' },
          { nested: 'properties', code: 'hydrometerNo', label: 'ne.identity.hydrometerNo', type: 'string' },
          { nested: 'properties', code: 'path', label: 'ne.identity.path', type: 'string' },
          { nested: 'properties', code: 'pathItem', label: 'ne.identity.pathItem', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'properties', code: 'address', label: 'hdrm.address.address', collapsible: true, icon: 'hydrometer', fields: [
          { code: 'street', label: 'hdrm.address.street', type: 'string', filterable: true },
          { code: 'streetNo', label: 'hdrm.address.streetNo', type: 'string', filterable: true },
          { code: 'postalCode', label: 'hdrm.address.postalCode', type: 'string', filterable: true },
          { code: 'region', label: 'hdrm.address.region', type: 'string', filterable: true },
          { code: 'city', label: 'hdrm.address.city', type: 'string', filterable: true },
          // { code: 'country', label: 'hdrm.address.country', type: 'string'},
          { code: 'address', label: 'hdrm.address.addresstmp', readonly: true, type: 'string'},
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'hydrometer', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { code: 'hydrometerType', label: 'hdrm.hydrometerType', type: 'selectionList', values: HydrometerTypes},
          { code: 'errorFunction', label: 'hdrm.errorFunction', type: 'selectionList', values: HydrometerErrorFunctions},
          { code: 'errorFunctionParams', label: 'hdrm.errorFunctionParams', type: 'string' },
          { code: 'isBilled', label: 'hdrm.isBilled', type: 'boolean', filterable: true },
        ]
      },
    ]
  },
  junction: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'junction', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true, filterable: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'junction', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { code: 'junctionType', label: 'junction.type', type: 'string', filterable: true },
        ]
      }
    ]
  },
  landparcel: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'landparcel', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true, filterable: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
          { docked: true, code: 'cultivationsCount', label: 'landparcel.cultivationsCount', readonly: true, type: 'computed', computation: 'computeCultivationsCount' },
        ]
      },
      {
        docked: true, nested: 'properties', code: 'dimensions', label: 'ne.dimensions', collapsible: true, icon: 'landparcel', fields: [
          { docked: true, code: 'area', label: 'landparcel.area', type: 'number', format: '9.99', unit: 'units.area.m2x1K' },
          { docked: true, code: 'perimeter', label: 'landparcel.perimeter', type: 'number', format: '9.999', unit: 'units.length.m' },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'landparcel', fields: [
          { code: 'cultivationPeriod', label: 'landparcel.cultivationPeriod', type: 'posnumber', filterable: true },
          { code: 'ownerCodes', label: 'landparcel.ownerCodes', readonly: true, type: 'computed', computation: 'computeLandparcelOwners' },
        ]
      }
    ]
  },
  pipe: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'pipe', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'properties', code: 'dimensions', label: 'ne.dimensions', collapsible: true, icon: 'pipe', fields: [
          { code: 'length', label: 'pipe.length', type: 'number', format: '9.999', unit: 'units.length.m' },
          { code: 'diameterInner', label: 'pipe.diameterInner', type: 'number', format: '9.9', unit: 'units.length.mm', filterable: true },
          { code: 'diameterOuter', label: 'pipe.diameterOuter', type: 'number', format: '9.9', unit: 'units.length.mm' },
          { code: 'diameterClass', label: 'pipe.diameterClass', type: 'selectionList', values: PipeDiameterClasses },
        ]
      },
      {
        nested: 'properties', code: 'inNode', label: 'pipe.node.in', collapsible: true, icon: 'pipe', hidden: false, fields: [
          { code: 'inNodeCode', label: 'ne.identity.code', readonly: true,
              type: 'groupedDynamicSelectionList', groupedBy: false },
          { code: 'inNodeFeatureType', label: 'ne.identity.featureType',
              type: 'groupedDynamicSelectionList', groupedBy: true,
              aggregates: 'inNodeCode', aggregateLabel: 'pipe.node.in', selectionListGroups: [
                { type: 'hydrant', values: 'hydrants' },
                { type: 'junction', values: 'junctions' },
                { type: 'pump', values: 'pumps' },
                { type: 'reservoir', values: 'reservoirs' },
                { type: 'switch', values: 'switches' },
                { type: 'tank', values: 'tanks' },
                { type: 'valve', values: 'valves' },
              ]
          },
        ]
      },
      {
        nested: 'properties', code: 'outNode', label: 'pipe.node.out', collapsible: true, icon: 'pipe', hidden: false, fields: [
          { code: 'outNodeCode', label: 'ne.identity.code', readonly: true,
              type: 'groupedDynamicSelectionList', groupedBy: false },
          { code: 'outNodeFeatureType', label: 'ne.identity.featureType',
              type: 'groupedDynamicSelectionList', groupedBy: true,
              aggregates: 'outNodeCode', aggregateLabel: 'pipe.node.out', selectionListGroups: [
                { type: 'hydrant', values: 'hydrants' },
                { type: 'junction', values: 'junctions' },
                { type: 'pump', values: 'pumps' },
                { type: 'reservoir', values: 'reservoirs' },
                { type: 'switch', values: 'switches' },
                { type: 'tank', values: 'tanks' },
                { type: 'valve', values: 'valves' },
              ]
          },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'pipe', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { code: 'pipeType', label: 'pipe.pipeType', type: 'selectionList', values: PipeTypes, filterable: true },
          { code: 'material', label: 'pipe.material', type: 'selectionList', values: PipeMaterials, filterable: true },
          { code: 'roughnessFactor', label: 'pipe.roughnessFactor', type: 'number', format: '9999.' },
          // { code: 'isExternal', label: 'pipe.isExternal', type: 'boolean' },
          { code: 'maxPressure', label: 'pipe.maxPressure', type: 'number', format: '9.999', unit: 'units.pressure.bar' },
        ]
      },
    ]
  },
  pump: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'pump', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { nested: 'properties', code: 'stationCode', label: 'ne.identity.stationCode', type: 'dynamicSelectionList', featureTypes: ['stations'], filterable: true },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        nested: 'properties', code: 'dimensions', label: 'ne.characteristics', collapsible: true, icon: 'pump', fields: [
          { code: 'volumePerHour', label: 'pump.volumePerHour', type: 'number', format: '9.999', unit: 'units.volumePerHour.m3/h', filterable: true },
          { code: 'power', label: 'pump.power', type: 'number', format: '9.999', unit: 'units.power.kw', filterable: true },
          { code: 'pressure', label: 'pump.pressure', type: 'number', format: '9.999', unit: 'units.pressure.bar' },
          { code: 'performDistr', label: 'pump.performDistr', type: 'string' },
          { code: 'performance', label: 'pump.performance', type: 'string' },
          { code: 'pumpMotorEfficiency', label: 'pump.pumpMotorEfficiency', type: 'string' },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'pump', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { code: 'pumpType', label: 'pump.pumpType', type: 'string', filterable: true },
          { code: 'motorType', label: 'pump.motorType', type: 'string', filterable: true },
          { code: 'softStarter', label: 'pump.softStarter', type: 'boolean' },
          { code: 'inverter', label: 'pump.inverter', type: 'boolean' },
          { code: 'agingFactor', label: 'pump.agingFactor', type: 'string' },
        ]
      },
    ]
  },
  pumpcluster: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'station', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        docked: true, nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'station', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
        ]
      },
    ]
  },
  reservoir: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'reservoir', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { nested: 'properties', code: 'stationCode', label: 'ne.identity.stationCode', type: 'dynamicSelectionList', featureTypes: ['stations'], filterable: true },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      // {
      //   nested: 'properties', code: 'dimensions', label: 'ne.characteristics', collapsible: true, icon: 'reservoir', fields: [
      //     // { code: 'volumePerHour', label: 'reservoir.volumePerHour', type: 'number', format: '9.999', unit: 'units.volumePerHour.m3/h' },
      //     // { code: 'power', label: 'reservoir.power', type: 'number', format: '9.999', unit: 'units.power.kw' },
      //   ]
      // },
      {
        docked: true, nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'reservoir', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { docked: true, code: 'reservoirType', label: 'reservoir.reservoirType', type: 'selectionList', values: ReservoirTypes, filterable: true },
          { code: 'depth', label: 'reservoir.depth', type: 'number', format: '9.9', unit: 'units.length.m', filterable: true },
          // { code: 'motorType', label: 'reservoir.motorType', type: 'string' },
          // { code: 'softStarter', label: 'reservoir.softStarter', type: 'boolean' },
          // { code: 'inverter', label: 'reservoir.inverter', type: 'boolean' },
        ]
      },
    ]
  },
  samplingPoint: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'samplingPoint', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
        },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: false, icon: 'samplingPoint', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { code: 'samplingPointType', label: 'samplingPoint.samplingPointType', type: 'string', filterable: true },
          { code: 'description', label: 'samplingPoint.description', type: 'string' },
          { code: 'attachedElementCode', label: 'samplingPoint.attachedElementCode', readonly: true, filterable: true,
              type: 'groupedDynamicSelectionList', groupedBy: false },
          { code: 'attachedElementFeatureType', label: 'samplingPoint.attachedElementFeatureType',
              type: 'groupedDynamicSelectionList', groupedBy: true,
              aggregates: 'attachedElementCode', aggregateLabel: 'samplingPoint.attachedElementCode', selectionListGroups: [
                { type: 'pipe', values: 'pipes', label: 'pipes' },
                { type: 'junction', values: 'junction', label: 'stations' },
                { type: 'reservoir', values: 'reservoirs', label: 'reservoirs' },
                { type: 'tank', values: 'tanks', label: 'tanks' },
              ]
          },
        ]
      },
    ]
  },
  station: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'station', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { nested: 'properties', code: 'billingCode', label: 'ne.identity.stationBillingCode', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        docked: true, nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'station', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { docked: true, code: 'stationType', label: 'station.stationType', type: 'selectionList', values: StationTypes, filterable: true },
        ]
      },
    ]
  },
  switch: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'switch', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { docked: true, nested: 'properties', code: 'stationCode', label: 'ne.identity.stationCode', type: 'dynamicSelectionList', featureTypes: ['stations'] },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format', },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        docked: true, nested: 'properties', code: 'dimensions', label: 'ne.characteristics', collapsible: true, icon: 'switch', fields: [
          { docked: true, code: 'switchType', label: 'switch.switchType', type: 'selectionList', values: SwitchCheckTypes, filterable: true },
          { code: 'boxCount', label: 'switch.boxCount', type: 'posnumber', format: '99.' },
          { code: 'keyCount', label: 'switch.keyCount', type: 'posnumber', format: '99.' },
          { code: 'minKeys', label: 'switch.minKeys', type: 'posnumber', format: '99.', filterable: true },
          { docked: true, code: 'scadaCodesCount', label: 'switch.scadaCodesCount', readonly: true, type: 'computed', computation: 'computeScadaCodesCount' },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'switch', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
        ]
      },
    ]
  },
  tank: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'tank', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { nested: 'properties', code: 'stationCode', label: 'ne.identity.stationCode', type: 'dynamicSelectionList', featureTypes: ['stations'], filterable: true },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        nested: 'properties', code: 'dimensions', label: 'ne.dimensions', collapsible: true, icon: 'tank', fields: [
          { code: 'tankType', label: 'tank.tankType', type: 'selectionList', values: TankTypes, filterable: true },
          { code: 'numberOfChambers', label: 'tank.numberOfChambers', type: 'number', format: '9.' },
          // { code: 'contourShape', label: 'tank.contourShape', type: 'string' },
          // { code: 'meanContourArea', label: 'tank.meanContourArea', type: 'string' },
          // { code: 'contourDiameter', label: 'tank.contourDiameter', type: 'string' },
          { code: 'tankWidth', label: 'tank.tankWidth', type: 'number', format: '9.999', unit: 'units.length.m' },
          { code: 'tankLength', label: 'tank.tankLength', type: 'number', format: '9.999', unit: 'units.length.m' },
          { code: 'tankHeight', label: 'tank.tankHeight', type: 'number', format: '9.999', unit: 'units.length.m' },
          { code: 'minHeight', label: 'tank.minHeight', type: 'number', format: '9.999', unit: 'units.length.m' },
          { code: 'maxHeight', label: 'tank.maxHeight', type: 'number', format: '9.999', unit: 'units.length.m' },
          { code: 'volume', label: 'tank.volume', type: 'number', format: '9.999', unit: 'units.volume.m3', filterable: true },
          // { code: 'netVolume', label: 'tank.netVolume', type: 'number', format: '9.999', unit: 'units.volume.m3' },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'tank', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { code: 'material', label: 'tank.material', type: 'selectionList', values: TankMaterials, filterable: true },
          // { code: 'tankColor', label: 'tank.tankColor', type: 'string' },
        ]
      },
    ]
  },
  valve: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'valve', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { docked: true, nested: 'properties', code: 'wszoneCode', label: 'ne.identity.wszoneCode', type: 'dynamicSelectionList', featureTypes: ['wszones'] },
          { nested: 'properties', code: 'stationCode', label: 'ne.identity.stationCode', type: 'dynamicSelectionList', featureTypes: ['stations'], filterable: true },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'tint', fields: [
          { code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', unit: 'units.length.m', readonly: true },
        ]
      },
      {
        nested: 'properties', code: 'built', label: 'ne.built', collapsible: true, icon: 'valve', fields: [
          { code: 'yearBuilt', label: 'ne.yearBuilt', type: 'posnumber' },
          { code: 'condition', label: 'ne.condition', type: 'selectionList', values: NEConditions },
          { code: 'electric', label: 'valve.electric', type: 'boolean' },
          { code: 'valveType', label: 'valve.valveType', type: 'selectionList', values: ValveTypes, filterable: true },
          { code: 'valveUse', label: 'valve.valveUse', type: 'selectionList', values: ValveUses, filterable: true },
          { code: 'valveForm', label: 'valve.valveForm', type: 'selectionList', values: ValveForms, filterable: true },
          // { code: 'pressure', label: 'valve.pressure', type: 'number', format: '9.999', unit: 'units.pressure.bar' },
          { code: 'pressure', label: 'valve.pressure', type: 'number', format: '9.99' },//use this for valveType-dependent valve setting (not always pressure)
          // { code: 'statusTimeSeries', label: 'valve.statusTimeSeries', type: 'string' },
          { code: 'diameter', label: 'valve.diameter', type: 'number', format: '9.9', unit: 'units.length.mm' },
          // { code: 'operatingPoint', label: 'valve.operatingPoint', type: 'string' },
        ]
      },
    ]
  },
  wszone: {
    groups: [
      {
        docked: true, code: 'basic', label: 'ne.identity', collapsible: false, icon: 'wszone', fields: [
          { docked: true, nested: 'properties', code: 'code', label: 'ne.identity.code', type: 'string', readonly: true, filterable:true },
          { nested: 'properties', code: 'label', label: 'ne.identity.name', type: 'string' },
          { docked: true, nested: 'properties', code: 'network', label: 'ne.identity.network', type: 'selectionList', values: WaterNetworks },
          { nested: 'properties', code: 'networkSubType', label: 'ne.identity.network.subType', type: 'selectionList', parentListCode: 'network', values: WaterNetworksSubTypes },
          { code: 'createdAt', label: 'ne.identity.created', type: 'date', readonly: true, format: 'date.format' },
          { code: 'updatedAt', label: 'ne.identity.updated', type: 'date', readonly: true, format: 'date.format' },
        ]
      },
      {
        docked: true, nested: 'properties', code: 'dimensions', label: 'ne.dimensions', collapsible: true, icon: 'wszone', fields: [
          { docked: true, code: 'area', label: 'wszone.area', type: 'number', format: '9.99', unit: 'units.area.m2x1K' },
          { docked: true, code: 'perimeter', label: 'wszone.perimeter', type: 'number', format: '9.999', unit: 'units.length.m' },
        ]
      },
    ]
  }
}

const DrawnLayerProperties = {
  Point: {
    groups: [
      {
        docked: true, nested: 'geometry.coordinates', code: 'position', label: 'ne.geometry.position', collapsible: true, icon: 'marker', fields: [
          { docked: true, code: '0', label: 'ne.geometry.longtitude', type: 'number', format: '9.9999999', readonly: true },
          { docked: true, code: '1', label: 'ne.geometry.latitude', type: 'number', format: '9.9999999', readonly: true },
          { docked: true, code: '2', label: 'ne.geometry.elevation', type: 'number', format: '9.9', readonly: true },
        ]
      },
    ]
  },
  LineString: {
    groups: [
      {
        docked: true, code: 'dimensions', label: 'ne.dimensions', collapsible: true, icon: 'ruler', fields: [
          { docked: true, code: 'transient_length', label: 'measurements.length', type: 'number', format: '9.999' },
        ]
      },
    ]
  },
  Polygon: {
    groups: [
      {
        docked: true, code: 'dimensions', label: 'ne.dimensions', collapsible: true, icon: 'rulerArea', fields: [
          { docked: true, code: 'transient_length', label: 'measurements.length.perimeter', type: 'number', format: '9.999' },
          { docked: true, code: 'transient_area', label: 'measurements.area', type: 'number', format: '9.99' },
        ]
      },
    ]
  },
}

const BaseMapLayers = [
  {
    name: 'cyberjapandata',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png?nocache',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 8,
  },
/*
  {
    name: 'maptiler.Tiles.Outdoor Beta',
    url: 'https://api.maptiler.com/tiles/outdoor/{z}/{x}/{y}.pbf?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 14,
  },
*/
  {
    name: 'maptiler.Tiles.Satellite',
    url: 'https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 20,
    // checked: true,
  },
/*
  {
    name: 'maptiler.Tiles.RGB Terain',
    url: 'https://api.maptiler.com/tiles/terrain-rgb/{z}/{x}/{y}.png?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 10,
    // checked: true,
  },
  {
    name: 'maptiler.Tiles.Hillshades',
    url: 'https://api.maptiler.com/tiles/hillshades/{z}/{x}/{y}.png?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 12,
    // checked: true,
  },
  {
    name: 'maptiler.Maps.Satellite.Hybrid',
    url: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 22,
    // checked: true,
  },
  {
    name: 'maptiler.Maps.Topo',
    url: 'https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 22,
  },
  {
    name: 'maptiler.Maps.Topographique',
    url: 'https://api.maptiler.com/maps/topographique/{z}/{x}/{y}.png?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 22,
  },
  {
    name: 'maptiler.Maps.Streets',
    url: 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=0xoiqhNcGXhu648v7i8c',
    attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    maxZoom: 22,
  },
*/
  {
    name: 'OpenStreetMap.Mapnik',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 19,
  },
  {
    name: 'OpenStreetMap.BlackAndWhite',
    url: 'https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 18,
  },
  {
    name: 'OpenStreetMap.OpenTopoMap',
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    maxZoom: 17,
  },
  {
    name: 'Thunderforest.Landscape',
    url: `https://tile.thunderforest.com/landscape/{z}/{x}/{y}{r}.png?apikey=c6e1cf8551974a8786bf9a7c65b3d1e8`,
    attribution: '&copy; <a href="http://thunderforest.com">Thunderforest</a> contributors',
    maxZoom: 22,
    checked: true,
  },
  {
    name: 'Thunderforest.Transport',
    url: `https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=c6e1cf8551974a8786bf9a7c65b3d1e8`,
    attribution: '&copy; <a href="http://thunderforest.com">Thunderforest</a> contributors',
    maxZoom: 22,
  },
  {
    name: 'Thunderforest.OpenCycleMap',
    url: `https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=c6e1cf8551974a8786bf9a7c65b3d1e8`,
    attribution: '&copy; <a href="http://thunderforest.com">Thunderforest</a> contributors',
    maxZoom: 22,
  },
  {
    name: 'Thunderforest.Outdoors',
    url: `https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=c6e1cf8551974a8786bf9a7c65b3d1e8`,
    attribution: '&copy; <a href="http://thunderforest.com">Thunderforest</a> contributors',
    maxZoom: 22,
  },
  {
    name: 'Eλληνικό Κτηματολόγιο',
    url: `http://gis.ktimanet.gr/wms/wmsopen/wmsserver.aspx`,
    attribution:'&copy; <a href="https://www.ktimatologio.gr/" target="_blank">Ελληνικό Κτηματολόγιο</a>',
    maxZoom: 25,
    wms:true
  },
  // {
  //   name: 'Δήμος Γορτυνίας',
  //   url: `http://services.opendatagortynia.gr/er/wms`,
  //   attribution:'&copy; <a href="http://gis.opendatagortynia.gr/" target="_blank">Δήμος Γορτυνίας</a>',
  //   maxZoom: 25,
  //   wms:true
  // },
]

const LAYERS_THAT_DONT_SNAP_TO_PIPES = [
  "hydrometers",
  "pipes",
  "stations",
  // "switches",
  "wszones",
  "hydrometers",
  "samplingPoints",
  'landparcel',
  // single
  "hydrometer",
  "pipe",
  "station",
  // "switch",
  "wszone",
  "hydrometer",
  "samplingPoint",
  'landparcels',
]

const DEFAULT_NO_OF_DAYS_MEASUREMENTS_FILTER = 30
const MAX_GRAPH_DATA_LIMIT = 500

const LEGEND_COLORS = {
  waterSupply: "#80F2E6",
  agriculture: "#FFFFA8",
  industrial: "#CC4DF2",
  energy: "#E6004D",
  recreation: "#FFE6FF",
  drainage: "#A64D00",
  default: '#7089E3',
}

const SELECTED_COLOR = '#171717' //black for selected features
const DEFAULT_COLOR = '#7089E3'  //blue for elements with no network property
const DRAWN_LAYER_COLOR = 'green' //freehand-temporary-auxiliary features
const LOCATION_HIGHLIGHT_COLOR = '#ffb74d' //used to highlight features if selectByLocation is active & hover
const ALARM_COLOR = '#ff11009c'

const CHART_COLORS = [
  '#ff0000',
  '#80ff00',
  '#140f00',
  '#ff0080',
  '#ffff00',
  '#ff8000',
  '#ff00ff',
  '#0080ff',
  '#00ff00',
  '#0000ff',
  '#00ff80',
  '#8000ff',
]

//TODO change the access rights in electricityBillsIrrigation (now it is enabled by Vwrrgt- view Irrigation reports)
const IMPORT_FILES = [
  {code : 'networkElements', translation : 'importFiles.networkElements', translationShort : 'importFiles.networkElements.short',extensions:['.geojson','.shp','.kml','.zip'],rights:"mprtNt"},
  {code : 'invoice', translation : 'importFiles.invoice', translationShort : 'importFiles.invoice.short', extensions:['.csv'],rights:"mprtnv", tenants:['tnnOne','dmwrm','dmgrty']},
  // {code : 'amr', translation : 'importFiles.amr', translationShort : 'importFiles.amr.short',extensions:['txt','csv'],rights:"mprtMR"},
  // {code : 'personnel', translation : 'importFiles.personnel', translationShort : 'importFiles.personnel.short',extensions:['txt','csv']},
  {code : 'electricityBills', translation : 'importFiles.electricityBills', translationShort : 'importFiles.electricityBills.short',extensions:['.txt'],rights:'mprtlc'},
  {code : 'electricityBillsIrrigation', translation : 'importFiles.electricityBillsIrrigation', translationShort : 'importFiles.electricityBillsIrrigation.short', extensions:['.xlsx'],rights:'mprtrr'},
]
const IMPORT_FILES_INVOICE_HEADERS_MAP = {
	// GENREASON_XK	:	'billReason'	,
	FNCDATE_XK	:	'billDate'	,
	EXPDATE_XK	:	'billExpirationDate'	,
	// FIYID	:	'year'	,
	// PERIOD	:	'period'	,//optional?
	LOGAR	:	'billCode'	,
	// AA	:	'billSerialNumber'	,
	// YDICODE	:	'routeCode'	,
	// AADIA	:	'routeSerialNumber'	,
	// KARTH	:	'invoiceCode'	,
  /**In the CSV file from DEYA Gortynias YKACODE is Κωδ. Καταναλωτή-'consumerCode'
    *In order to match the records to the hydrometers in our app map it with hydrometerCode **/
	YKACODE	:	'hydrometerCode'	,//originally this was 'consumerCode'	,
	// ARYDRMTR	:	'hydrometerCode'	,//since the YKACODE is used as the hydrometerCode this is redundant
	TRA_CODE	:	'consumerCode',// originally was  Κωδ. Συναλλασσόμενου - 'consumerTansactionCode'	,//optional?
	// TRANAME	:	'fullName'	,
	// TRAFNAME	:	'fatherName'	,
	// TRAADDRESS	:	'address'	,
	// TRACITY	:	'city'	,
	PRVMTR	:	'lastMeasurementCubicMeters'	,
	CURMTR	:	'currentMeasurementCubicMeters'	,
	XREOMTR	:	'consumptionCubicMeters'	,
	// AMOUNT	:	'amountValue'	,
	// AMOUNTVAT	:	'vat'	,
	// AMOUNTTOTALROUND	:	'rounding'	,
	// AMOUNTPAY	:	'amountPayable'	,
	TOTAL_AMOUNTPAY	:	'amountPayableFinal'	,
  invoicingPeriodFrom: 'invoicingPeriodFrom',//GORTYNIA ONLY maps from (FIYID)
  invoicingPeriodUpTo: 'invoicingPeriodUpTo',//GORTYNIA ONLY maps from (FIYID)
	// AMOUNTCLCGRD	:	'amountPaidOrDeducted'	, //optional?
	// YLOID	:	'billCodeFromDB'	,
}
const IMPORT_FILES_IRRIGATION_ELECTRICITY_HEADERS_MAP = {
  "ΕΤΟΣ" : 'etos',
  "ΜΗΝΑΣ": 'minas',
  "ΚΩΔΙΚΟΣ_ΠΟΛΛΑΠΛΟΥ": 'kodikosPollaplou',
  "ΟΝΟΜΑ_ΠΟΛΛΑΠΛΟΥ":'onomaPollaplou',
  "ΑΡ.ΠΑΡΟΧΗΣ":'arithmosParohis',
  "ΟΝΟΜΑ_ΠΕΛΑΤΗ": 'onomaPelati',
  "ΟΝΟΜΑ_ΟΔΟΥ_(Παροχης)": 'onomaOdouParohis',
  "ΑΡΙΘΜ.ΟΔΟΥ_(Παροχής)": 'arithmosOdouParohis',
  "ΠΟΛΗ_(Παροχής)": 'poliParohis',
  "ΑΡΙΘΜΟΣ_ΠΑΡΑΣΤΑΤΙΚΟΥ": 'arithmosParastatikou',
  "ΗΜΕΡΟΜ._ΕΚΔΟΣΗΣ__ΛΟΓ/ΜΟΥ": 'emerominiaEkdosisLogariasmou',
  "ΤΙΜΟΛΟΓΙΟ": 'timologio',
  "ΗΜΕΡ.ΤΕΛΕΥΤ._ΚΑΤΑΜΕΤΡΗΣΗΣ": 'emerominiaTelefteasKatametrisis',
  "ΗΜΕΡ.ΠΡΟΗΓ._ΚΑΤΑΜΕΤΡΗΣΗΣ": 'emerominiaProigoumenisKatametrisis',
  "ΠΑΡΟΥΣΑ_ΕΝΔΕΙΞΗ": 'parousaEndiksi',
  "ΠΡΟΗΓΟΥΜΕΝΗ_ΕΝΔΕΙΞΗ": 'proegoumeniEndiksi',
  "ΣΥΝΤ._ΩΧΒ": 'sindelestisKWH',
  "ΚΑΤΑΝΑΛΩΣΗ_ΕΝΕΡΓΕΙΑΣ_(ΩΧΒ)": 'katanalosiKWH',
  "ΣΥΝΟΛΟ_ΧΡΕΩΣΗΣ_ΠΡΟΜΗΘΕΙΑΣ_ΡΕΥΜΑΤΟΣ": 'sinoloPromithiasRevmatos',
  "ΣΥΝΟΛΟ__ΡΥΘΜΙΖΟΜΕΝΩΝ_ΧΡΕΩΣΕΩΝ": 'sinoloRithmizomenonHreoseon',
  "ΣΥΝΟΛΟ_ΔΙΑΦΟΡΩΝ_ΧΡΕΩΣΕΩΝ_/_ΠΙΣΤΩΣΕΩΝ": 'sinoloDiaforonHreoseonPistoseon',
  "ΣΥΝΟΛΟ_ΛΟΙΠΩΝ_ΕΚΤΑΚΤΩΝ_ΧΡΕΩΣΕΩΝ": 'sinoloLiponEktaktonHreoseon',
  "ΣΥΝΟΛΟ_ΦΠΑ": 'sinoloFPA',
  "ΣΥΝΟΛΟ_ΔΗΜΟΥ": 'sinoloDimou',
  "ΣΥΝΟΛΟ_ΕΡΤ": 'sinoloErt',
  "ΣΥΝΟΛΟ_ΛΟΓΑΡΙΑΣΜΟΥ": 'sinoloLogariasmou',
  "ΣΥΝΟΛΟ_ΤΡΕΧΟΝΤΑ_ΜΗΝΑ": 'sinoloTrehondaMina',
  "ΤΥΠΟΣ_ΛΟΓΑΡΙΑΣΜΟΥ": 'typosLogariasmou',
}
const ELECTRICITY_HEADERS_POWER = [
  { start: 1,   end: 4,   name: 'etos', type: 'string', },
  { start: 5,   end: 7,   name: 'minas', type: 'string', },
  { start: 9,   end: 15,  name: 'kodikosPollaplou', type: 'string', skipValidation: true, },
  { start: 17,  end: 36,  name: 'onomaPollaplou', type: 'string', },
  { start: 64,  end: 73,  name: 'arithmosParohis', type: 'string', },
  { start: 75,  end: 79,  name: 'timologio', type: 'string', },
  { start: 81,  end: 100, name: 'onomaPelati', type: 'string', },
  { start: 102, end: 124, name: 'onomaOdou', type: 'string', },
  { start: 126, end: 128, name: 'arithmosOdou', type: 'string', skipValidation:true, },
  { start: 130, end: 149, name: 'poli', type: 'string', },
  { start: 162, end: 169, name: 'arithmosMetri', type: 'string', },
  { start: 182, end: 187, name: 'sindelestisXB', type: 'number', },
  { start: 189, end: 196, name: 'sinimitono', type: 'number', },
  { start: 198, end: 225, name: 'sholiaSinimitonou', previousColumnEnd: 196, type: 'string', previousType: 'number', },
  { start: 227, end: 231, name: 'egatestimeniIshis', previousColumnStart: 198, type: 'number', },
  { start: 233, end: 240, name: 'katametrithisaZitisi', type: 'number', },
  { start: 242, end: 249, name: 'hrosteaZitisi', type: 'number', },
  { start: 251, end: 255, name: 'simfonimeniIshis', type: 'number', },
  { start: 257, end: 264, name: 'zitisiEhmis', previousColumnEnd: 255, type: 'number', },
  { start: 266, end: 273, name: 'zitisiImeras', type: 'number', },
  { start: 275, end: 282, name: 'zitisiNihtas', type: 'number', },
  { start: 284, end: 289, name: 'sintelestisProsarmogis', type: 'number', },
  { start: 291, end: 296, name: 'sintelestisHrisimopiisis', type: 'number', },
]
const ELECTRICITY_HEADERS_BASIC = [
  { start: 1,   end: 4,   name: 'etos', type: 'string', },
  { start: 6,   end: 7,   name: 'minas', type: 'string', },
  { start: 9,   end: 15,  name: 'kodikosPollaplou', type: 'string', skipValidation: true, },
  { start: 17,  end: 36,  name: 'onomaPollaplou', type: 'string', },
  { start: 85,  end: 95,  name: 'arithmosParohis', type: 'string', },
  { start: 123, end: 147, name: 'onomaPelati', type: 'string', },
  { start: 149, end: 172, name: 'onomaOdouParohis', type: 'string', }, // skipValidation: true, },
  { start: 174, end: 181, name: 'arithmosOdouParohis', type: 'string', skipValidation: true, },
  { start: 183, end: 197, name: 'poliParohis', type: 'string', },
  { start: 226, end: 233, name: 'emerominiaEkdosisLogariasmou', type: 'date', },
  { start: 235, end: 239, name: 'timologio', type: 'string', },
  { start: 293, end: 300, name: 'emerominiaTelefteasKatametrisis', type: 'date', },
  { start: 302, end: 309, name: 'emerominiaProigoumenisKatametrisis', type: 'date', },
  { start: 315, end: 321, name: 'parousaEndiksi', type: 'number', },
  { start: 323, end: 329, name: 'proegoumeniEndiksi', type: 'number', },
  { start: 331, end: 335, name: 'sindelestisKWH', type: 'number', },
  { start: 337, end: 345, name: 'katanalosiKWH', type: 'number', },
  { start: 557, end: 569, name: 'sinoloPromithiasRevmatos', type: 'number', },
  { start: 683, end: 695, name: 'sinoloRithmizomenonHreoseon', type: 'number', },
  { start: 963, end: 975, name: 'sinoloDiaforonHreoseonPistoseon', type: 'number', },
  { start: 1047, end: 1059, name: 'sinoloLiponEktaktonHreoseon', type: 'number', },
  { start: 1203, end: 1215, name: 'sinoloFPA', type: 'number', },
  { start: 1347, end: 1359, name: 'sinoloDimou', type: 'number', },
  { start: 1389, end: 1401, name: 'sinoloErt', type: 'number', },
  { start: 1403, end: 1415, name: 'sinoloLogariasmou', type: 'number', },
  { start: 1417, end: 1429, name: 'sinoloTrehondaMina', type: 'number', },
  { start: 1431, end: 1435, name: 'typosLogariasmou', type: 'string', skipValidation:true, },
]
const DATA_IMPORT_HEADER_VALUES = {
  invoice: [
    // { key: 'billReason', required: true },
    { key: 'billDate', required: true, type: 'date' },
    { key: 'billExpirationDate', required: true, type: 'date' },
    // { key: 'year', required: true },
    // { key: 'period', required: true, type: 'string' }, //make it optional?
    { key: 'billCode', required: true, type: 'string' },
    // { key: 'billSerialNumber', required: true },
    // { key: 'routeCode', required: true },
    // { key: 'routeSerialNumber', required: true },
    // { key: 'invoiceCode', required: true },
    { key: 'consumerCode', required: true, type: 'string' },
    { key: 'hydrometerCode', required: true, type: 'string' },
    // { key: 'consumerTansactionCode', required: true, type: 'string' }, //make it optional?
    // { key: 'fullName', required: true },
    // { key: 'fatherName', required: true },
    // { key: 'address', required: true },
    // { key: 'city', required: true },
    { key: 'lastMeasurementCubicMeters', required: true, type: 'number' },
    { key: 'currentMeasurementCubicMeters', required: true, type: 'number' },
    { key: 'consumptionCubicMeters', required: true, type: 'number' },
    // { key: 'amountValue', required: true },
    // { key: 'vat', required: true },
    // { key: 'rounding', required: true },
    // { key: 'amountPayable', required: true },
    { key: 'amountPayableFinal', required: true, type: 'number' },
    { key: 'invoicingPeriodFrom', required: true, type: 'date' },
    { key: 'invoicingPeriodUpTo', required: true, type: 'date' },
    { key: 'ebillCode', required: false, type: 'string' },
    // { key: 'amountPaidOrDeducted', required: true, type: 'number' },//make it optional?
    // { key: 'billCodeFromDB', required: true },
  ],
  amr: [
    { key: 'amrTest1', required: true },
    { key: 'amrTest2', required: false },
  ],
  electricityBillsIrrigation: [
    { key: 'etos', required: true, type: 'number' },
    { key: 'minas', required: true, type: 'number' },
    { key: 'kodikosPollaplou', required: true, type: 'string' },
    { key: 'onomaPollaplou', required: true, type: 'string' },
    { key: 'arithmosParohis', required: true, type: 'string' },
    { key: 'onomaPelati', required: true, type: 'string' },
    { key: 'onomaOdouParohis', required: true, type: 'string' },
    { key: 'arithmosOdouParohis', required: false, type: 'string' },
    { key: 'poliParohis', required: true, type: 'string' },
    { key: 'arithmosParastatikou', required: true, type: 'string' },
    { key: 'emerominiaEkdosisLogariasmou', required: true,type: 'date' },
    { key: 'timologio', required: true, type: 'string' },
    { key: 'emerominiaTelefteasKatametrisis', required: true, type: 'date'},
    { key: 'emerominiaProigoumenisKatametrisis', required: true, type: 'date' },
    { key: 'parousaEndiksi', required: true, type: 'string' },
    { key: 'proegoumeniEndiksi', required: true, type: 'string' },
    { key: 'sindelestisKWH', required: true, type: 'number' },
    { key: 'katanalosiKWH', required: true, type: 'number' },
    { key: 'sinoloPromithiasRevmatos', required: true, type: 'number' },
    { key: 'sinoloRithmizomenonHreoseon', required: true, type: 'number' },
    { key: 'sinoloDiaforonHreoseonPistoseon', required: true, type: 'number' },
    { key: 'sinoloLiponEktaktonHreoseon', required: true, type: 'number' },
    { key: 'sinoloFPA', required: true, type: 'number' },
    { key: 'sinoloDimou', required: true, type: 'number' },
    { key: 'sinoloErt', required: true, type: 'number' },
    { key: 'sinoloLogariasmou', required: true, type: 'number' },
    { key: 'sinoloTrehondaMina', required: true },
    { key: 'typosLogariasmou', required: true, type: 'string' },
  ],
  electricityBills: [
    // {key:'electricityCompanyTest1', required:true},
    { key: 'year', required: true },
    { key: 'ac_month', required: true },
    { key: 'codeMultiple', required: true },
    { key: 'nameMultiple1', required: true },
    { key: 'nameMultiple2', required: true },
    { key: 'periphery', required: true },
    { key: 'office', required: true },
    { key: 'billingNumber', required: true },
    { key: 'invoice', required: true },
    { key: 'customerName', required: true },
    { key: 'streetName', required: true },
    { key: 'streetNum', required: true },
    { key: 'city', required: true },
    { key: 'lastBillDate', required: true, type: 'date' },
    { key: 'meterNumber', required: true },
    { key: 'invoiceType', required: true },
    { key: 'xbCoefficient', required: true },
    { key: 'cosine', required: true },
    { key: 'cosineNotes', required: true },
    { key: 'installedPower', required: true },
    { key: 'measuredDemand', required: true },
    { key: 'demandDemand', required: true },
    { key: 'agreedPower', required: true },
    { key: 'peakDemand', required: true },
    { key: 'dayDemand', required: true },
    { key: 'nightDemand', required: true },
    { key: 'adaptationCoefficient', required: true },
    { key: 'usabilityCoefficient', required: true },
  ],
}

const IRRI_FORM_ACCEPTED_EXTENSIONS = '.pdf, .doc, .docx, .jpg, .jpeg, .png'

const AGRICULTURAL_PERIOD_STATUS = {
  'completed': { code: 'completed', transKey: 'agriculturalPeriod.status' },
  'active': { code: 'active', transKey: 'agriculturalPeriod.status' },
  'pending': { code: 'pending', transKey: 'agriculturalPeriod.status' },
}
enum GraphStates  {
  preparing= 'preparing',
  building= 'building',
  ready= 'ready',
}

const GraphColorsPool = [
  '#53B4B4', '#3f51b5', '#f44336', '#ffc107',
  '#009688', '#e91e63', '#4caf50', '#ff9800',
  '#03a9f4', '#8bc34a', '#9c27b0', '#ff5722',
]
const GraphColorsPool_2 = [
  '#42a5f5', '#5c6bc0', '#26c6da',
  '#66bb6a', '#9ccc65', '#76ff03',
  '#ef5350', '#ff5722', '#f06292',
  '#3d5afe', '#00e5ff', '#ffeb3b',
  '#26a69a', '#69f0ae', '#795548',
  '#e040fb', '#ffab40', '#c6ff00',

  '#53B4B4', '#3f51b5', '#f44336', '#ffc107',
  '#009688', '#e91e63', '#4caf50', '#ff9800',
  '#03a9f4', '#8bc34a', '#9c27b0', '#3d5722',
]
const TicksCalculationTable = [
  { timeUnit: 'SEC', value: 1, inMillisecs: 1000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 2, inMillisecs: 2000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 5, inMillisecs: 5000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 10, inMillisecs: 10000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 15, inMillisecs: 15000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 30, inMillisecs: 30000, format: 'HH:mm:ss', },
  { timeUnit: 'MIN', value: 1, inMillisecs: 60000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 2, inMillisecs: 120000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 5, inMillisecs: 300000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 10, inMillisecs: 600000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 15, inMillisecs: 900000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 30, inMillisecs: 1800000, format: 'HH:mm', },
  { timeUnit: 'HOUR', value: 1, inMillisecs: 3600000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 2, inMillisecs: 7200000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 3, inMillisecs: 10800000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 6, inMillisecs: 21600000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 12, inMillisecs: 43200000, format: 'DD MMM HH:mm', },
  { timeUnit: 'DAY', value: 1, inMillisecs: 86400000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 2, inMillisecs: 172800000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 5, inMillisecs: 432000000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 7, inMillisecs: 604800000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 14, inMillisecs: 1209600000, format: 'DD MMM', },
  { timeUnit: 'MONTH', value: 1, inMillisecs: 2635200000, format: 'DD MMM YYYY', },
  { timeUnit: 'MONTH', value: 2, inMillisecs: 5270400000, format: 'DD MMM YYYY', },
  { timeUnit: 'MONTH', value: 3, inMillisecs: 7905600000, format: 'DD MMM YYYY', },
  { timeUnit: 'MONTH', value: 6, inMillisecs: 15811200000, format: 'DD MMM YYYY', },
  { timeUnit: 'YEAR', value: 1, inMillisecs: 31622400000, format: 'DD MMM YYYY', },
  { timeUnit: 'YEAR', value: 2, inMillisecs: 63244800000, format: 'DD MMM YYYY', },
  { timeUnit: 'YEAR', value: 5, inMillisecs: 158112000000, format: 'DD MMM YYYY', },
]
const TicksCalculationTable_2 = [
  { timeUnit: 'SEC', value: 1, inMillisecs: 1000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 2, inMillisecs: 2000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 5, inMillisecs: 5000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 10, inMillisecs: 10000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 15, inMillisecs: 15000, format: 'HH:mm:ss', },
  { timeUnit: 'SEC', value: 30, inMillisecs: 30000, format: 'HH:mm:ss', },
  { timeUnit: 'MIN', value: 1, inMillisecs: 60000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 2, inMillisecs: 120000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 5, inMillisecs: 300000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 10, inMillisecs: 600000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 15, inMillisecs: 900000, format: 'HH:mm', },
  { timeUnit: 'MIN', value: 30, inMillisecs: 1800000, format: 'HH:mm', },
  { timeUnit: 'HOUR', value: 1, inMillisecs: 3600000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 2, inMillisecs: 7200000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 3, inMillisecs: 10800000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 6, inMillisecs: 21600000, format: 'DD MMM HH:mm', },
  { timeUnit: 'HOUR', value: 12, inMillisecs: 43200000, format: 'DD MMM HH:mm', },
  { timeUnit: 'DAY', value: 1, inMillisecs: 86400000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 2, inMillisecs: 172800000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 5, inMillisecs: 432000000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 7, inMillisecs: 604800000, format: 'DD MMM', },
  { timeUnit: 'DAY', value: 14, inMillisecs: 1209600000, format: 'DD MMM', },
  { timeUnit: 'MONTH', value: 1, inMillisecs: 2635200000, format: 'MMM YY', },
  { timeUnit: 'MONTH', value: 2, inMillisecs: 5270400000, format: 'MMM YY', },
  { timeUnit: 'MONTH', value: 3, inMillisecs: 7905600000, format: 'MMM YY', },
  { timeUnit: 'MONTH', value: 6, inMillisecs: 15811200000, format: 'MMM YY', },
  { timeUnit: 'YEAR', value: 1, inMillisecs: 31622400000, format: 'YYYY', },
  { timeUnit: 'YEAR', value: 2, inMillisecs: 63244800000, format: 'YYYY', },
  { timeUnit: 'YEAR', value: 5, inMillisecs: 158112000000, format: 'YYYY', },
]

const SUPPORTED_DATE_FORMATS = [
  'DD MMM YYYY',
  'MMM DD YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'DD-MM-YYYY',
  'MM-DD-YYYY',
  'YYYY-MM-DD',
  'YYYY/MM/DD',
]

/** Collections that should trigger the web-socket updates only if 
 * the client's currentAgriculturalPeriod.cultivationPeriod (eg 2023) is the same as the updated item's cultivation period
 * 'irriEvents' & 'irriCommands' don't have the 'cultivationPeriod' property so we use the 'createdAt' instead*/
const AGRICULTURAL_PERIOD_DEPENDANT_COLLECTIONS = [
  'cultivations',
  'irriCommands',
  'irriEvents',
  'irriForms',
  'irriProgs',
  'irriScheds',
]

const ProcedureCategories = {
  'maintenance': { code: 'maintenance', transKey: 'procedure.category' },
  'damage': { code: 'damage', transKey: 'procedure.category' },
  'roadConstruction': { code: 'roadConstruction', transKey: 'procedure.category' },
  'qualityControl': { code: 'qualityControl', transKey: 'procedure.category' },
  'emergency': { code: 'emergency', transKey: 'procedure.category' },
}

const ImportanceRanks = {
  '1': { code: '1', transKey: 'com.importance.rank' },
  '2': { code: '2', transKey: 'com.importance.rank' },
  '3': { code: '3', transKey: 'com.importance.rank' },
  '4': { code: '4', transKey: 'com.importance.rank' },
  '5': { code: '5', transKey: 'com.importance.rank' },
}

const ActivityStatuses = {
  'new': { code: 'new', transKey: 'activity.status'},
  'ongoing': { code: 'ongoing', transKey: 'activity.status'},
  'pending': { code: 'pending', transKey: 'activity.status'},
  'finished': { code: 'finished', transKey: 'activity.status'},
  'cancelled': { code: 'cancelled', transKey: 'activity.status'},
  'nonResolvable': { code: 'nonResolvable', transKey: 'activity.status'},
}

const DeviceLinkTypes = {
  'controls': { code: 'controls', transKey: 'deviceLink.linkType' },
  // 'reads': { code: 'reads', transKey: 'deviceLink.linkType' },
  // 'other': { code: 'other', transKey: 'deviceLink.linkType' },
}

const DeviceSlaveLinkTypes = {
  'controls_sl': { code: 'controls_sl', transKey: 'deviceLink.slave.linkType' },
  // 'reads_sl': { code: 'reads_sl', transKey: 'deviceLink.slave.linkType' },
  // 'other_sl': { code: 'other_sl', transKey: 'deviceLink.slave.linkType' },
}

const DeviceCommProtocols = {
  'wifi': { code: 'wifi', transKey: 'device.comm.protocol' },
  '4g': { code: '4g', transKey: 'device.comm.protocol' },
  'ethernet': { code: 'ethernet', transKey: 'device.comm.protocol' },
  'lora': { code: 'lora', transKey: 'device.comm.protocol' },
  'rs485': { code: 'rs485', transKey: 'device.comm.protocol' },
  'i2c': { code: 'i2c', transKey: 'device.comm.protocol' },
  'other': { code: 'other', transKey: 'device.comm.protocol' },
}

const GroupItemTypes = {
  'groups': { code: 'groups', transKey: 'groupItem.type' },
  'isgrouped': { code: 'isgrouped', transKey: 'groupItem.type' },
}

const MseriesLinkTypes = {
  'input': { code: 'input', transKey: 'mseriesLink.linkType' },
  'output': { code: 'output', transKey: 'mseriesLink.linkType' },
  'throughput': { code: 'throughput', transKey: 'mseriesLink.linkType' },
  // 'phase': { code: 'phase', transKey: 'mseriesLink.linkType' },
  'phaseA': { code: 'phaseA', transKey: 'mseriesLink.linkType' },
  'phaseB': { code: 'phaseB', transKey: 'mseriesLink.linkType' },
  'phaseC': { code: 'phaseC', transKey: 'mseriesLink.linkType' },
  'other': { code: 'other', transKey: 'mseriesLink.linkType' },
}

const MseriesTypes = {
  'flow': { code: 'flow', transKey: 'mseries.mstype' },
  'volume': { code: 'volume', transKey: 'mseries.mstype' },
  'pressure': { code: 'pressure', transKey: 'mseries.mstype' },
  'height': { code: 'height', transKey: 'mseries.mstype' },
  'status': { code: 'status', transKey: 'mseries.mstype' },
  'voltage': { code: 'voltage', transKey: 'mseries.mstype' },
  'current': { code: 'current', transKey: 'mseries.mstype' },
  'energy': { code: 'energy', transKey: 'mseries.mstype' },
  'power': { code: 'power', transKey: 'mseries.mstype' },
  'efficiency': { code: 'efficiency', transKey: 'mseries.mstype' },
  'frequency': { code: 'frequency', transKey: 'mseries.mstype' },
  'chlorination': { code: 'chlorination', transKey: 'mseries.mstype' },
  'hours': { code: 'hours', transKey: 'mseries.mstype' },
  'amount': { code: 'amount', transKey: 'mseries.mstype' },
  'other': { code: 'other', transKey: 'mseries.mstype' },
}

const CardReaders = {
  0: { code: 0, transKey: 'device.cardReader' },
  1: { code: 1, transKey: 'device.cardReader' },
}

const LoraTransceivers = {
  0: { code: 0, transKey: 'device.loraTransceiver' },
  1: { code: 1, transKey: 'device.loraTransceiver' },
  2: { code: 2, transKey: 'device.loraTransceiver' },
  3: { code: 3, transKey: 'device.loraTransceiver' },
}

export {
  ActivitiesReport_ENUM,
  ActivitiesReportTypes,
  ActivityStatuses,
  AGRICULTURAL_PERIOD_DEPENDANT_COLLECTIONS,
  AGRICULTURAL_PERIOD_STATUS,
  ALARM_COLOR,
  BaseMapLayers,
  CardReaders,
  CHART_COLORS,
  CIRStatuses,
  CIRTypes,
  CultivationCategory,
  CultivationIrrigationMethods,
  CultivationKind,
  CultivationSoilTypes,
  DATA_IMPORT_HEADER_VALUES,
  DEFAULT_COLOR,
  DEFAULT_NO_OF_DAYS_MEASUREMENTS_FILTER,
  Department,
  DeviceCategories,
  DeviceCommProtocols,
  DeviceLinkTypes,
  DeviceModules,
  DeviceSlaveLinkTypes,
  DRAWN_LAYER_COLOR,
  DrawnLayerProperties,
  ELECTRICITY_HEADERS_BASIC,
  ELECTRICITY_HEADERS_POWER,
  EpanetControlOperators,
  EpanetControlTimeSufixes,
  EpanetControlTypes,
  EpanetPumpStates,
  EpanetStatusTypes,
  EQPT,
  EquipmentCategory,
  EquipSpareCategory,
  FeatureInnerSvg,
  DEFAULT_NETWORK_ELEMENTS_FILTER,
  FEATURE_PROPERTIES,
  FTC,
  Fuel,
  GraphColorsPool,
  GraphColorsPool_2,
  GraphStates,
  GroupItemTypes,
  HOUR_VALUES,
  HIGHLIGHTED_FEATURES,
  IMMEStatuses,
  IMMPStatuses,
  IMNRCategories,
  IMNRImportantRanks,
  IMNRStatuses,
  ImportanceRanks,
  IMPORT_FILES_INVOICE_HEADERS_MAP,
  IMPORT_FILES_IRRIGATION_ELECTRICITY_HEADERS_MAP,
  IMPORT_FILES,
  IMTStatuses,
  IndicatorTypes,
  InventoryCategories,
  InventoryItemCategory,
  InventoryItemType,
  InverterTypes,
  INVERTER_REPORT_TYPE_HEADERS,
  Irr_ApplicationReportTypes,
  IRRI_FORM_ACCEPTED_EXTENSIONS,
  Irr_InverterReportTypes,
  IrrigationCommandTypes,
  IrrigationEventTypes,
  IrrigationFormStatus,
  IrrigationFormRejectionReasons,
  IrrigationSchedStatus,
  IRRIGATION_FREQUENCIES_6,
  IRRIGATION_FREQUENCIES_8,
  IRRIGATION_FREQUENCIES_12,
  IRRIGATION_FREQUENCIES_24,
  Irr_ReportTypes,
  Irr_WaterOutletReportTypes,
  Irr_WaterOutletReports_ENUM,
  IVNStatuses,
  LAYERS_THAT_DONT_SNAP_TO_PIPES,
  LEGEND_COLORS,
  Legislative,
  LOCATION_HIGHLIGHT_COLOR,
  LoraTransceivers,
  MaintenanceStatus,
  MAX_GRAPH_DATA_LIMIT,
  MseriesLinkTypes,
  MseriesTypes,
  MqttBrokers,
  NEConditions,
  NotificationTypes,
  Periodicity,
  PersonnelSpecialties,
  PipeDiameterClasses,
  PipeMaterials,
  QC_ReportTypes,
  SELECTED_COLOR,
  SupportedLocales,
  SUPPORTED_DATE_FORMATS,
  SwitchCheckTypes,
  ProcedureCategories,
  TenantMetadata,
  TicksCalculationTable,
  TicksCalculationTable_2,
  TruckMachineCategory,
  USNotificationFrequencies,
  USNotificationTypes,
  ValveTypes,
  VehicleCategory,
  WaterNetworks,
  WaterNetworksSubTypes,
  WB_ReportTypes,
}
