import TemplateService from './TemplateService'
import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import { buildErrorMessage } from '../utils/helpers'
import { Profile } from '../utils/types/collectionStoreTypes'

const NIAM_API_URL = process.env.REACT_APP_NIMBL_API_URL

const ConsumerService = () => {
  const templateService = TemplateService('consumer')
  const apiClient = useCommonStore.getState().apiClient

  /** Importing NiamService into ConsumerService locked the app in an infinite loop
   * so the sendNiamRequest & updateUser were copied here from NiamService
   */
  const sendNiamRequest = (data, path) => {
    // console.log(`ConsumerService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, false, path, NIAM_API_URL, true)
  }
  const updateUser = (user: any) => {
    let data = {
      operation: 'updateUser',
      user: user,
      productcode: 'wrm',
    }
    return sendNiamRequest(data, 'user/').then(res => {
      let updatedUser = res.data
      useCollectionStore.getState().updateItem('users', updatedUser)
      return res
    })
  }

  const users = useCollectionStore.getState().users
  const profile = useCommonStore.getState().profile
  const setProfile = useCommonStore.getState().setProfile
  const setCollectionItems = useCollectionStore.getState().setItems

  const getConsumersWithUser = () => {
    let filter = {
      users_user_id: {$nin: [null, ""]},
    }
    const options = {
      cachePromise: true,
      doReload: true,
      doNotStore: true, //we do not want this filtered list of consumers as the consumers' state data
    }
    return templateService.getEntities(filter, options)
  }
  const getConsumerByUser_id = user_id => {
    let consumer
    let filter = {
      users_user_id: user_id,
    }
    return templateService.getEntities(filter).then(res => {
      // console.log(`getConsumerByUser_id user ${JSON.stringify(res.data,null,2)}`)
      if (!res.data.error) {
        if (res.data.length > 0) {
          consumer = res.data[0]
        }
      } else {
        console.log(
          `ERROR getConsumerByUser_id res.data: ${JSON.stringify(
            res.data,
            null,
            2
          )}`
        )
      }
      return consumer
    })
  }
  const getConsumerByVatNo = vatNo => {
    let consumer
    let filter = {
      vatNo: vatNo,
    }
    return templateService.getEntities(filter).then(res => {
      // console.log(`getConsumerByVatNo user ${JSON.stringify(res.data,null,2)}`)
      if (!res.data.error) {
        if (res.data.length > 0) {
          consumer = res.data[0]
        }
      } else {
        console.log(
          `ERROR getConsumerByVatNo res.data: ${JSON.stringify(
            res.data,
            null,
            2
          )}`
        )
      }
      return consumer
    })
  }

  const saveConsumer = (consumer, doNotSelect?, updatedUser?) => {
    let promise: any
    if (!updatedUser) {
      promise = Promise.resolve('ok')
    } else {
      promise = updateUser(updatedUser) //updateUser(updatedUser)
      // promise = niamService.updateUser(updatedUser)
    }
    return promise
      .then(res => {
        return templateService.saveEntity(consumer, doNotSelect) //collectionManager.actSaveCollectionItem('consumers', consumer, doNotSelect)
      })
      .then(
        res => {
          console.log(`saveConsumer res ${JSON.stringify(res, null, 2)}`)
          let consumer = res
          let updatedusers = [...users!]
          updatedusers = updatedusers.map(u => {
            if (u.user_id === consumer.users_user_id) {
              if (!!u.consumer && !!u.consumer.hydrometers) {
                consumer.hydrometers = u.consumer.hydrometers
              }
              u.consumer = consumer
              if (updatedUser) {
                u.auth0User = updatedUser.auth0User
              }
            }
            return u
          })

          setCollectionItems('users', updatedusers)
          if (profile?.user_id === consumer.users_user_id) {
            if (!!profile?.consumer && !!profile.consumer.hydrometers) {
              consumer.hydrometers = profile.consumer.hydrometers
            }
            profile!.consumer = consumer
            if (updatedUser) {
              profile!.auth0User = updatedUser.auth0User
            }
            setProfile({ ...(profile as Profile) })
          }
          return res
        },
        err => {
          console.error(`error ${JSON.stringify(err, null, 2)}`)
          // console.error(`error.toJSON():${error.toJSON()}`);
          let errorMessage = buildErrorMessage(err)
          console.error(`errorMessage ${errorMessage}`)
          // showAlert(t(errorMessage) , 'E')
          return Promise.reject(err)
        }
      )
  }

  return {
    getConsumersWithUser: getConsumersWithUser,
    getConsumerByUser_id: getConsumerByUser_id,
    getConsumerByVatNo: getConsumerByVatNo,
    /** same as consumerService.saveEntity but doesn't handle user's collection update
     * use if the consumer is not a registered app user */
    saveConsumerWithoutUpdatingUsers: templateService.saveEntity,
    getEntities: templateService.getEntities,
    saveEntity: saveConsumer,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  }
}

export default ConsumerService
