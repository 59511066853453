import i18n from '../i18n'
import { buildErrorMessage } from '../utils/helpers'


const CollectionManager = (collectionsMeta: any, profile: any, showAlert) => {

  const actCollection = (key: string, data?: any) => {
    // console.log(`actCollection ${key}`)
    let meta = collectionsMeta[key]
    if (!meta) {
      console.log(`!meta ${!meta}`)
      return Promise.resolve('wait')
    }
    return loadCollection(key, data).then(
      res => {
        return res
      },
      err => {
        console.log(`ERROR actCollection ${key} err ${JSON.stringify(!!err.data ? err.data : err,null,2)}`)
        return err
      }
    )
  }

  const loadCollection = (key: string, data?: any, doReload?: boolean) => {
    // console.log(`loadCollection ${key}`)
    let meta = collectionsMeta[key]
    if (!meta) return Promise.resolve('wait')
    if (!!meta.state && !doReload) {
      return Promise.resolve({ data: meta.state })
    } else {
      // console.log(`loadCollection ${key} do job`)
      if (!meta.fetcher) meta.fetcher = 'getEntities'
      if (typeof meta.service[meta.fetcher] !== 'function') {
        console.log(`ERROR loadCollection ${key} meta.service[${meta.fetcher}] is not a function`)
        return Promise.resolve({ data: [] })
      } else {
        return meta.service[meta.fetcher](data).then(
          res => {
            if (!res.data.error) {
              console.log(`loadCollection ${key} cnt ${res.data.length}`)
              // console.log(`loadCollection ${key} ${JSON.stringify(res.data,null,2)}`)
              meta.stateSetter(res.data)
              meta.state = res.data
              return res
            } else {
              console.log(`ERROR loadCollection ${key} ${JSON.stringify(res.data,null,2)}`)
              return { data: [] }
            }
          },
          err => {
            console.log(`ERROR loadCollection ${key} ${JSON.stringify(err, null, 2)}`)
            return { data: [] }
          }
        )
      }
    }
  }

  const actSaveCollectionItem = (key, item, doNotSelect?) => {
    console.log(`actSaveCollectionItem ${key}`)
    let meta = collectionsMeta[key]
    if (!meta) return
    let isNewItem = !item._id
    if (isNewItem) {
      // console.log(`actSaveCollectionItem profile ${JSON.stringify(profile,null,2)}`)
      item.addedBy = profile.user_id
      item.tenantCode = profile.selectedTenant
    } else {
      item.modifiedBy = profile.user_id
    }
    // console.log(`actSaveCollectionItem ${key} ${JSON.stringify(item, null, 2)}`)
    if (!meta.saver) meta.saver = 'saveEntity'
    if (typeof meta.service[meta.saver] !== 'function') {
      let errorMessage = `ERROR actSaveCollectionItem ${key} meta.service[${meta.saver}] is not a function`
      // showAlert(t(errorMessage), 'E')
      console.error(`errorMessage ${errorMessage}`)
      return Promise.reject(errorMessage)
    } else {
      return meta.service[meta.saver](item).then(
        res => {
          // console.log(`actSaveCollectionItem ${key} res.data ${JSON.stringify(res.data, null, 2)}`)
          item = res.data
          let updatedCollection
          if (isNewItem) {
            updatedCollection = !!meta.state ? [...meta.state] : []
            updatedCollection.push(item)
          } else {
            updatedCollection = !!meta.state
              ? meta.state.map(it => (it._id === item._id ? item : it))
              : []
          }
          if (!doNotSelect) {
            // meta.service[meta.selector](item)
          }
          meta.stateSetter(updatedCollection)
          meta.state = updatedCollection
          return item
        },
        err => {
          // console.error(`error ${JSON.stringify(err, null, 2)}`)
          console.log(err)
          let errorMessage = buildErrorMessage(err, null, key)
          showAlert(i18n.t(errorMessage), 'E')
          console.error(`errorMessage ${errorMessage}`)
          return Promise.reject(err)
        }
      )
    }
  }

  const actDeleteCollectionItem = (key, item, note?) => {
    console.log(`actDeleteCollectionItem ${key}`)
    let meta = collectionsMeta[key]
    if (!meta) return

    let doNotSelect = true

    item.modifiedBy = profile.user_id

    console.log(`actDeleteCollectionItem ${key} ${JSON.stringify(item, null, 2)}`)

    if (!meta.deleter) meta.deleter = 'deleteEntity'
    if (typeof meta.service[meta.deleter] !== 'function') {
      let errorMessage = `ERROR actDeleteCollectionItem ${key} meta.service[${meta.deleter}] is not a function`
      showAlert(i18n.t(errorMessage), 'E')
      console.error(`errorMessage ${errorMessage}`)
      return Promise.reject(errorMessage)
    } else {
      let promise: any = Promise.resolve({ data: item })
      if (!!note) {
        if (!meta.noteSaver) meta.noteSaver = 'saveEntityNote'
        if (typeof meta.service[meta.noteSaver] !== 'function') {
          let errorMessage = `ERROR actDeleteCollectionItem ${key} meta.service[${meta.noteSaver}] is not a function`
          showAlert(i18n.t(errorMessage), 'E')
          console.error(`errorMessage ${errorMessage}`)
          return Promise.reject(errorMessage)
        } else {
          promise = promise.then(res => {
            console.log(`actDeleteCollectionItem ${key} saving note: note ${note}`)
            note.addedBy = profile.user_id
            note.text = `${i18n.t('md.confirm.delete.item.noteReasonPrefix')}: ${
              note.text
            }`
            return meta.service[meta.noteSaver](item, note, doNotSelect)
          })
        }
      }
      return promise
        .then(res => {
          console.log(`actDeleteCollectionItem ${key} after saving note: res.data ${JSON.stringify(res.data,null,2)}`)
          item = res.data
          return meta.service[meta.deleter](item)
        })
        .then(res => {
          console.log(`actDeleteCollectionItem ${key} res.data ${JSON.stringify(res.data,null,2)}`)
          item = res.data
          let updatedCollection
          updatedCollection = !!meta.state
            ? meta.state.filter(it => it._id !== item._id)
            : []
          // meta.service[meta.selector](null)
          meta.stateSetter(updatedCollection)
          meta.state = updatedCollection
        }
        , err => {
          let errorMessage = buildErrorMessage(err, null, key)
          showAlert(i18n.t(errorMessage) , 'E')
          console.error(`errorMessage ${errorMessage}`)
          return Promise.reject(err)
        }
      )
    }
  }

  const actSaveCollectionItemNote = (key, item, note, doNotSelect?) => {
    console.log(`actSaveCollectionItemNote ${key} note ${JSON.stringify(note,null,2)}`)
    let meta = collectionsMeta[key]
    if (!meta) return
    let isNewNote = !note._id
    if (isNewNote) {
      note.addedBy = profile.user_id
    } else {
      note.modifiedBy = profile.user_id
      note.updatedAt = Date.now()
    }
    if (!meta.noteSaver) meta.noteSaver = 'saveEntityNote'
    if (typeof meta.service[meta.noteSaver] !== 'function') {
      let errorMessage = `ERROR actSaveCollectionItemNote ${key} meta.service[${meta.noteSaver}] is not a function`
      showAlert(i18n.t(errorMessage), 'E')
      console.error(`errorMessage ${errorMessage}`)
      return Promise.reject(errorMessage)
    } else {
      return meta.service[meta.noteSaver](item, note).then(
        res => {
          // console.log(`actSaveCollectionItemNote ${key} res.data ${JSON.stringify(res.data,null,2)}`)
          item = res.data
          let updatedCollection = meta.state.map(it =>
            it._id === item._id ? item : it
          )
          if (!doNotSelect) {
            // meta.service[meta.selector](item)
          }
          meta.stateSetter(updatedCollection)
          meta.state = updatedCollection
          return item
        },
        err => {
          console.error(
            `actSaveCollectionItemNote err ${JSON.stringify(err, null, 2)}`
          )
          // console.error(`err.toJSON():${err.toJSON()}`);
          let errorMessage = buildErrorMessage(err)
          showAlert(i18n.t(errorMessage), 'E')
          return Promise.reject(err)
        }
      )
    }
  }

  const actDeleteCollectionItemNote = (key, item, note) => {
    console.log(`actDeleteCollectionItemNote ${key} note ${JSON.stringify(note,null,2)}`)
    let meta = collectionsMeta[key]
    if (!meta) return
    if (!meta.noteDeleter) meta.noteDeleter = 'deleteEntityNote'
    if (typeof meta.service[meta.noteDeleter] !== 'function') {
      let errorMessage = `ERROR actDeleteCollectionItemNote ${key} meta.service[${meta.noteDeleter}] is not a function`
      showAlert(i18n.t(errorMessage), 'E')
      console.error(`errorMessage ${errorMessage}`)
      return Promise.reject(errorMessage)
    } else {
      return meta.service[meta.noteDeleter](item, note).then(
        res => {
          // console.log(`actDeleteCollectionItemNote ${key} res.data ${JSON.stringify(res.data,null,2)}`)
          item = res.data
          let updatedCollection = meta.state.map(it =>
            it._id === item._id ? item : it
          )
          meta.stateSetter(updatedCollection)
          meta.state = updatedCollection
          return item
        },
        err => {
          console.error(`err ${JSON.stringify(err, null, 2)}`)
          // console.error(`error.toJSON():${error.toJSON()}`);
          let errorMessage = buildErrorMessage(err)
          showAlert(i18n.t(errorMessage), 'E')
          return Promise.reject(err)
        }
      )
    }
  }

  return({
    actCollection: actCollection,
    loadCollection: loadCollection,
    actSaveCollectionItem: actSaveCollectionItem,
    actDeleteCollectionItem: actDeleteCollectionItem,
    actSaveCollectionItemNote: actSaveCollectionItemNote,
    actDeleteCollectionItemNote: actDeleteCollectionItemNote,
  })

}

export default CollectionManager
