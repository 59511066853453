import { SvgIcon } from '@material-ui/core'

const IrrigationIcon = props => (
  <SvgIcon
    style={props.style}
    width='16'
    height='19'
    viewBox='0 0 16 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 5.15601H2.9376C5.61065 5.15601 7.77454 7.3199 7.77454 9.99295H5.83694C3.16389 9.99295 1 7.82905 1 5.15601Z'
      stroke='#3CADAD'
      fill='#ffffff'
      strokeMiterlimit='333.33'
    />
    <path
      d='M9.9376 10.3369H8C8 7.66389 10.1639 5.5 12.8369 5.5H14.7745C14.7745 8.17305 12.6106 10.3369 9.9376 10.3369Z'
      stroke='#3CADAD'
      fill='#ffffff'
      strokeMiterlimit='333.33'
    />
    <path d='M7.77441 16.9725V9.99292' stroke='#3CADAD' strokeMiterlimit='333.33' />
    <path
      d='M9.47826 2.40154L7.98913 0.5L6.5 2.40154C6.20652 2.82625 6 3.30888 6 3.79151C6 4.00386 6.04348 4.20656 6.13043 4.40927C6.21739 4.61197 6.3587 4.79537 6.54348 4.95946C6.94565 5.3166 7.47826 5.5 8 5.5C8.53261 5.5 9.05435 5.3166 9.45652 4.95946C9.6413 4.79537 9.78261 4.60232 9.86957 4.40927C9.95652 4.20656 10 4.00386 10 3.79151C10 3.30888 9.79348 2.82625 9.5 2.4112L9.47826 2.40154ZM9.40217 4.24517C9.33696 4.38996 9.23913 4.5251 9.09783 4.65058C8.79348 4.92085 8.3913 5.05598 7.98913 5.05598C7.58696 5.05598 7.18478 4.92085 6.88044 4.65058C6.73913 4.5251 6.6413 4.38996 6.57609 4.24517C6.51087 4.10039 6.48913 3.94595 6.48913 3.79151C6.48913 3.41506 6.65217 3.00965 6.91304 2.64286L7.98913 1.26255L9.06522 2.64286C9.32609 3 9.48913 3.41506 9.48913 3.79151C9.48913 3.9556 9.45652 4.10039 9.40217 4.24517Z'
      fill='#3CADAD'
    />
    <path
      d='M7.79098 2.5C7.37141 2.80071 6.72458 3.61033 7.12667 4.16549C7.5812 4.79005 8.21054 4.41994 7.93083 4.0267C7.37141 3.26335 7.79098 2.5 7.79098 2.5Z'
      fill='#3CADAD'
    />
    <path
      d='M7.77457 18.5C10.0515 18.5 11.8973 18.2309 11.8973 17.8989C11.8973 17.567 10.0515 17.2979 7.77457 17.2979C5.49766 17.2979 3.65186 17.567 3.65186 17.8989C3.65186 18.2309 5.49766 18.5 7.77457 18.5Z'
      stroke='#3CADAD'
      fill='#ffffff'
      strokeMiterlimit='333.33'
    />
  </SvgIcon>
)

export default IrrigationIcon
