import { useEffect, useState, useMemo } from 'react'
import * as _ from 'lodash'

import { useNetworkElementStore } from '../../state/networkElementStore'
import { useUiStore } from '../../state/uiStore'
import { useCollectionStore } from '../../state/collectionStore'
import { useCommonStore } from '../../state/commonStore'
import MeasurementsManager from '../../services/MeasurementsManager'
import CultivationService from '../../services/CultivationService'
import useLandParcelMeasurements from '../../hooks/useLandParcelMeasurements'
import { GenericFeature, Switch } from '../../utils/types/networkElementTypes'
import IrrigationMeasurementsMd from '../irrigation/measurements/IrrigationMeasurementsMd'
import HydoorMeasurementsMd from './HydoorMeasurementsMd'

/* EXTRA_VALUES that will be merged with existing measurement types and passed as props to the <IrrigationMeasurementsMd/>*/
const EXTRA_VALUES = {
  CULTIVATOR: {
    level: 'extraValues',
    fieldname: 'cultivator',
    datatype: 'String',
    label: 'cultivator',
    description: "Cultivator's name",
    groupfieldname: 'cultivator',
  },
  WATER_OUTLET: {
    level: 'extraValues',
    fieldname: 'waterOutlet',
    datatype: 'String',
    label: 'WaterOutlet',
    description: "Cultivator's name",
    groupfieldname: 'waterOutlet',
  },
  CULTIVATION_LABEL: {
    level: 'extraValues',
    fieldname: 'cultivationLabel',
    datatype: 'String',
    label: 'CultivationLabel',
    description: "Cultivation's name",
    groupfieldname: 'cultivationLabel',
  },
}
const getRenderedFeature = (parentComponent: string, featureType: string) => {
  switch (parentComponent) {
    case 'CultivationCatalog':
      return 'cultivation'
    case 'SwitchesCatalog':
      return 'switch'
    case 'SwitchSlotCatalog':
      return 'switchSlot'
    case 'LandparcelsCatalog':
      return 'landparcel'
    case 'ConsumersCatalog':
      return 'consumer'
    case 'FeaturesCatalog':
      return featureType
    case 'Main':
      return featureType
    default:
      return ''
  }
}

const MeasurementsMd = props => {
  const measurementsManager = MeasurementsManager()
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)
  const switchSlots = useCollectionStore(state => state.switchSlots)
  const consumers = useCollectionStore(state => state.consumers)
  const cultivations = useCollectionStore(state => state.cultivations)
  const waterOutlets = useNetworkElementStore(state => state.switches)
  const selectedEntity = useUiStore(state => state.selectedEntity)
  const { _cultivations, _switches, _hourMeters } = useLandParcelMeasurements(selectedEntity?.properties?.code || '')
  const [consumerWaterOutlets, setConsumerWaterOutlets] = useState<Switch[] | []>([])
  const [consumerSlotsCodes, setConsumerSlotsCodes] = useState<string[]>([])
  const cultivationService = CultivationService()

  const inferredFeatures = props.inferredFeatures || _switches
  let filteredMeasuermentTypes = props.measurementTypes

  const getRenderedFeatureLabel = (parentEntityType: String, series: any[]) => {
    switch (parentEntityType) {
      case 'switch':
        return props.feature.properties.label
      case 'landparcel':
        return props.feature.properties.label
      case 'consumer':
        let cultivatorName = `${props.feature.lastname} ${props.feature.firstname}`
        return cultivatorName
      case 'switchSlot':
        let switchSlotCode = ''
        if (series && series.length > 0) {
          switchSlotCode = series[0].scadaDeviceCode
        }
        return switchSlotCode
      case 'cultivation':
        let cultivationName = ''
        if (series && series.length > 0 && cultivations) {
          let switchSlotCode = series[0].scadaDeviceCode
          if (switchSlotCode) {
            let cultivation = cultivations.find(cl => cl.hourmeterCode === switchSlotCode)
            if (cultivation) {
              cultivationName = cultivation.label
              if (consumers) {
                let consumer = consumers.find(cn => cn.code === cultivation?.cultivatorCode)
                if (consumer) {
                  cultivationName = `${cultivationName} - ${consumer.lastname} ${consumer.firstname}`
                }
              }
            }
          }
        }
        return cultivationName

      default:
        return parentEntityType
    }
  }

  useEffect(() => {
    /* Depending from which part of the HYDOOR APP the user opens the measurements (Catalogs, Map, etc)
     * some of the nessesary elements(cultivations) may have not been fetched from db */
    if (!cultivations) {
      let agriculturalPeriodFilter = {
        cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod || -1
      }
      cultivationService.getEntities(agriculturalPeriodFilter)
    }
  }, [props.parent])

  useEffect(() => {
    /* Fetch the measurements based on selected feature
     * Case#1: props.feature is a feature (network element)
     * hourmeter/switchSlot and cultivation fall into Case#1 category since they "fake" beeing a switch/waterOutlet
     * Case#2: props.feature is a consumer
     * If the feature doen't have the property "properties" then it isn't a network element feature
     * Consumers have the code property directly inside them while network elements have the code nested inside the properties */
    if (!!props.feature && props.feature?.properties && !props.feature.series) {
      let getMeasurements = measurementsManager.getFeatureMeasurements.bind(this, props.feature)
      if (!!selectedEntity && selectedEntity?.properties?.featureType === 'landparcel') {
        getMeasurements = measurementsManager.getFeatureMeasurementsForMultipleFeatures.bind(
          this,
          _switches,
          false,
          false,
          selectedEntity,
          _hourMeters
        )
      }
      if (!!inferredFeatures?.length) {
        getMeasurements = measurementsManager.getFeatureMeasurementsForMultipleFeatures.bind(
          this,
          inferredFeatures,
          false,
          false,
          props.feature,
          _hourMeters
        )
      }
      getMeasurements().then(
        res => {},
        err => {
          console.log(`Error loading series`)
          console.log(`..attempting to load measurements of feature ${props.feature?.properties?.code} once more`)
          getMeasurements().then(
            res => {
              console.log(`..second loading succeded.`)
            },
            err => {
              console.log(`..second loading failed.`)
            }
          )
        }
      )
    } else if (!!props.feature && !!props.feature.code) {
      //CASE#2: CONSUMER
      // const ownedSwitchSlotsLEGACY = !!switchSlots
      // ? switchSlots.filter(sl => sl.consumerCode === props.feature.code)
      // : []
      const ownedSwitchSlots = cultivations?.filter(c=>c.cultivatorCode === props.feature.code)?.map(cc=>{
        return switchSlots?.find(sw=> sw.code === cc.hourmeterCode)
      })?.filter(swtchSlot=>!!swtchSlot) || []
      const infferedWaterOutletsCodes = ownedSwitchSlots.map(s => s?.switchCode) || []
      const _consumerWaterOutlets =
        waterOutlets?.filter(wo => infferedWaterOutletsCodes.includes(wo.properties.code)) || []
      setConsumerWaterOutlets(_consumerWaterOutlets)
      setConsumerSlotsCodes(ownedSwitchSlots.map(slot => slot?.code!))
      //TODO: REAFACTOR REFACTOR REFACTOR:
      /** Holy cow this is horrible 
       * 1) returning a function inside useEffect -> the function becomes a cleanup function
       *  probably that's why initially when the first consumer is selected and the measurements tab is opened, no measuerements appear
       * 2) check the arguments of getFeatureMeasurementsForMultipleFeatures, something is fishy 
       *   */
      measurementsManager.getFeatureMeasurementsForMultipleFeatures(_consumerWaterOutlets as GenericFeature[], false, undefined, props.feature)
      // return props.getFeatureMeasurementsForMultipleFeatures(_consumerWaterOutlets as GenericFeature[], false, false, false, props.feature)
    }
  }, [props.feature?.code, props.feature?.properties?.code])

  const filteredSeriesMemo: any[] = useMemo(() => {
    let filteredSeries: any[] = props.series || []
    if (!(filteredSeries.length > 0)) {
      return filteredSeries
    }

    /* Since Landparcel & Consumer are aggregations of various wateroutlet elements
    * series that are unrelated to the selected feature must be filtered out
    * before passing the series to the <IrrigationMeasuermentMd/> */
    if (props.featureType === 'landparcel' && !!props.series && !!inferredFeatures) {
      const connectedSwitchSlotsCodes = _hourMeters?.map(el => el?.code) || []
      filteredSeries = props.series.filter(s => connectedSwitchSlotsCodes?.includes(s?.scadaDeviceCode))
    }
    if (props?.featureType === 'consumer' && !!props.series) {
      filteredSeries = props.series.filter(s => consumerSlotsCodes?.includes(s?.scadaDeviceCode))
    }
    if (props.parent === 'CultivationCatalog' && !!props.selectedCultivation) {
      //Warning the cultivations are "masked" as "switches" so props.featureType==="switch"
      filteredSeries = filteredSeries.filter(s => s.scadaDeviceCode === props.selectedCultivation.hourmeterCode)
    }

    const seriesCodes = filteredSeries?.map(s => s.measurementType?.code)
    const selectedNetworkElementOrFeature = getRenderedFeature(props.parent, props.featureType)
    /* Enchance the measurements by adding new interpolated values (that don't exist on the measurement Types)
    * from the EXTRA_VALUES constant (adds cultivators to cultivations, etc)
    *** the property cultivationCode is removed in all cases and replaced with EXTRA_VALUS.CULTIVATION_LABEL (if needed) */
    switch (selectedNetworkElementOrFeature) {
      case 'cultivation': {
        /** cultivation , inject water outlet and cultivator's name */
        const cultivator =
          consumers?.find(c => c.code === props.selectedCultivation?.cultivatorCode) ||
          props.selectedCultivation?.cultivatorCode
        const cultivatorsName =
          !!cultivator && typeof cultivator === 'string' ? cultivator : `${cultivator?.lastname} ${cultivator?.firstname}`
        filteredSeries = filteredSeries.map(serie => {
          const updatedmeasurements = serie?.measurements?.map(m => {
            const waterOutlet = waterOutlets?.find(
              wo => wo.properties.code === props.selectedCultivation?.irrigationPointCode
            )
            // if (!!m.extraValues?.cultivationCode) {
            //   m.extraValues.cultivator = cultivatorsName
            // }
            if (!!m.extraValues) {
              m.extraValues.cultivator = cultivatorsName
              m.extraValues.waterOutlet = waterOutlet?.properties?.label || props.selectedCultivation?.irrigationPointCode
            }
            return m
          })
          serie.measurements = updatedmeasurements
          return serie
        })

        filteredMeasuermentTypes = props.measurementTypes.map(el => {
          if (el.code === 'plcHours' || seriesCodes.includes(el.code)) {
            let enchancedMsType = _.cloneDeep(el)
            enchancedMsType.valueTypes = enchancedMsType.valueTypes.filter(vt => !(vt.fieldname === 'cultivationCode'))
            enchancedMsType.valueTypes.push(EXTRA_VALUES.CULTIVATOR)
            enchancedMsType.valueTypes.push(EXTRA_VALUES.WATER_OUTLET)
            return enchancedMsType
          } else {
            return el
          }
        })
        break
      }
      case 'switch': {
        /* case 'WaterOutlet', inject cultivator's name and cultivationLabel*/
        filteredSeries = filteredSeries.map(serie => {
          const updatedmeasurements = serie?.measurements?.map(m => {
            const currentCultivation = cultivations?.find(c => c.code === m.extraValues?.cultivationCode)
            const cultivator = consumers?.find(c => c.code === currentCultivation?.cultivatorCode) || ''
            if (!!m.extraValues) {
              m.extraValues.cultivationLabel = currentCultivation?.label || m.extraValues?.cultivationCode
              m.extraValues.cultivator = !!cultivator ? `${cultivator.lastname} ${cultivator.firstname}` : ''
            }
            return m
          })
          serie.measurements = updatedmeasurements
          return serie
        })

        filteredMeasuermentTypes = props.measurementTypes.map(el => {
          if (el.code === 'plcHours' || seriesCodes.includes(el.code)) {
            let enchancedMsType = _.cloneDeep(el)
            /* remove the cultivationCode, use EXTRA_VALUS.CULTIVATION_LABEL instead */
            enchancedMsType.valueTypes = enchancedMsType.valueTypes.filter(vt => !(vt.fieldname === 'cultivationCode'))
            enchancedMsType.valueTypes.push(EXTRA_VALUES.CULTIVATOR)
            enchancedMsType.valueTypes.push(EXTRA_VALUES.CULTIVATION_LABEL)
            return enchancedMsType
          } else {
            return el
          }
        })
        break
      }
      case 'switchSlot': {
        /* case 'switchSlot/Hourmeter', inject cultivatioLabel*/
        filteredSeries = filteredSeries.map(serie => {
          const updatedmeasurements = serie?.measurements?.map(m => {
            const currentCultivation = cultivations?.find(c => c.code === m.extraValues?.cultivationCode)
            if (!!m.extraValues) {
              m.extraValues.cultivationLabel = currentCultivation?.label || m.extraValues?.cultivationCode
            }
            return m
          })
          serie.measurements = updatedmeasurements
          return serie
        })

        filteredMeasuermentTypes = props.measurementTypes.map(el => {
          if (el.code === 'plcHours' || seriesCodes.includes(el.code)) {
            let enchancedMsType = _.cloneDeep(el)
            /* remove the cultivationCode, use EXTRA_VALUS.CULTIVATION_LABEL instead */
            enchancedMsType.valueTypes = enchancedMsType.valueTypes.filter(vt => !(vt.fieldname === 'cultivationCode'))
            enchancedMsType.valueTypes.push(EXTRA_VALUES.CULTIVATION_LABEL)
            return enchancedMsType
          } else {
            return el
          }
        })
        break
      }
      case 'landparcel': {
        /* case 'landparcel', inject cultivator's name, waterOutlet and cultivationLabel */
        filteredSeries = filteredSeries.map(serie => {
          const updatedmeasurements = serie?.measurements?.map(m => {
            const currentCultivation = cultivations?.find(c => c.code === m.extraValues?.cultivationCode)
            const cultivator = consumers?.find(c => c.code === currentCultivation?.cultivatorCode) || ''
            const waterOutlet = waterOutlets?.find(wo => wo.properties.code === currentCultivation?.irrigationPointCode)
            if (!!m.extraValues) {
              m.extraValues.waterOutlet = waterOutlet?.properties.label || currentCultivation?.irrigationPointCode
              m.extraValues.cultivationLabel = currentCultivation?.label || m.extraValues?.cultivationCode
              m.extraValues.cultivator = !!cultivator ? `${cultivator.lastname} ${cultivator.firstname}` : ''
            }
            return m
          })
          serie.measurements = updatedmeasurements
          return serie
        })

        filteredMeasuermentTypes = props.measurementTypes.map(el => {
          if (el.code === 'plcHours' || seriesCodes.includes(el.code)) {
            let enchancedMsType = _.cloneDeep(el)
            /* remove the cultivationCode, use EXTRA_VALUS.CULTIVATION_LABEL instead */
            enchancedMsType.valueTypes = enchancedMsType.valueTypes.filter(vt => !(vt.fieldname === 'cultivationCode'))
            enchancedMsType.valueTypes.push(EXTRA_VALUES.CULTIVATOR)
            enchancedMsType.valueTypes.push(EXTRA_VALUES.CULTIVATION_LABEL)
            enchancedMsType.valueTypes.push(EXTRA_VALUES.WATER_OUTLET)
            return enchancedMsType
          } else {
            return el
          }
        })
        break
      }
      case 'consumer': {
        /* case 'consumer', inject cultivator's name, waterOutlet and cultivationLabel */
        filteredSeries = filteredSeries.map(serie => {
          const updatedmeasurements = serie?.measurements?.map(m => {
            const currentCultivation = cultivations?.find(c => c.code === m.extraValues?.cultivationCode)
            const waterOutlet = consumerWaterOutlets?.find(
              (wo: Switch) => wo.properties.code === currentCultivation?.irrigationPointCode
            )
            if (!!m.extraValues) {
              m.extraValues.waterOutlet = waterOutlet?.properties.label || currentCultivation?.irrigationPointCode
              m.extraValues.cultivationLabel = currentCultivation?.label || m.extraValues?.cultivationCode
            }
            return m
          })
          serie.measurements = updatedmeasurements
          return serie
        })

        filteredMeasuermentTypes = props.measurementTypes.map(el => {
          if (el.code === 'plcHours' || seriesCodes.includes(el.code)) {
            let enchancedMsType = _.cloneDeep(el)
            enchancedMsType.valueTypes = enchancedMsType.valueTypes.filter(vt => !(vt.fieldname === 'cultivationCode'))
            enchancedMsType.valueTypes.push(EXTRA_VALUES.CULTIVATION_LABEL)
            enchancedMsType.valueTypes.push(EXTRA_VALUES.WATER_OUTLET)
            return enchancedMsType
          } else {
            return el
          }
        })
        break
      }
      default:
        break
    }

    return filteredSeries

  }, [props.series])

  /** The only cases for irrigation are these 3:
   * case 'switch':
   * case 'landparcel':
   * case 'consumer':
   */
  let parentEntityType = getRenderedFeature(props.parent, props.featureType)
  let parentEntityLabel = getRenderedFeatureLabel(parentEntityType, filteredSeriesMemo)
  let tinferredFeatures: any
  switch (props.featureType) {
    case 'landparcel':
      tinferredFeatures = _switches
      break;
    case 'consumer':
      tinferredFeatures = consumerWaterOutlets
      break;
  }

  return ['switch', 'landparcel', 'consumer'].includes(props.featureType) ? (
    <IrrigationMeasurementsMd
      {...props}
      parentEntityType={parentEntityType}
      parentEntityLabel={parentEntityLabel}
      series={filteredSeriesMemo}
      measurementTypes={filteredMeasuermentTypes}
      inferredFeatures={tinferredFeatures}
    />
  ) : (
    <HydoorMeasurementsMd {...props} />    
  )
}

export default MeasurementsMd
