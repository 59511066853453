import React, { useState, useMemo, useRef, Suspense, useEffect } from 'react'
import { useCommonStore } from '../../state/commonStore'
import moment from 'moment'
import { cloneDeep, debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'

import WrmIcon from '../WrmIcon'
import { FTC } from '../../utils/constants'
import MdDetail from './MdDetail'
import MdMaster from './MdMaster'
import MdEditingDialog from './MdEditingDialog'
import MdFilterDialog from './MdFilterDialog'

//Sorting
function descendingComparator(a, b, orderBy, colmeta, processValue) {
  // console.log(`descendingComparator a ${a[orderBy]} b ${b[orderBy]} orderBy ${orderBy}`)
  let aval
  let bval
  if (colmeta.type === 'number' || colmeta.type === 'posnumber') {
    aval = a[orderBy]
    bval = b[orderBy]
  } else if (colmeta.type === 'date' || colmeta.type === 'datetime') {
    // aval = a[orderBy] ? moment(a[orderBy]).valueOf() : a[orderBy]
    // bval = b[orderBy] ? moment(b[orderBy]).valueOf() : b[orderBy] 
    aval = a.sortItem[orderBy]
    bval = b.sortItem[orderBy]
  } else if (!!colmeta.lookup) {
    aval = a.searchItem[orderBy]
    bval = b.searchItem[orderBy]
  } else {
    aval = processValue(a[orderBy], colmeta)
    bval = processValue(b[orderBy], colmeta)
    if (typeof aval === 'string') aval = aval.toLowerCase()
    if (typeof bval === 'string') bval = bval.toLowerCase()
  }
  let result
  if (!aval && !(aval === 0 || aval === false)) {
    result = 1
  } else if (!bval && !(bval === 0 || bval === false)) {
    result = -1
  } else if (bval < aval) {
  // if (bval < aval) {
    result = -1
  } else if (bval > aval) {
    result = 1
  } else {
    result = 0
  }
  // console.log(`descendingComparator ${typeof aval}\t${aval}\t${result === 1 ? '<' : (result === -1 ? '>' : '=')}\t ${typeof bval}\t${bval}`)
  return result
}
function getComparator(order, orderBy, colmeta, processValue) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, colmeta, processValue)
    : (a, b) => -descendingComparator(a, b, orderBy, colmeta, processValue)
}
function stableSort(array, comparator) {
  // console.log(`stableSort array.length ${array.length}`)
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    // console.log(`stableSort order ${order}`)
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles(theme => ({
  mdroot: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto',
  },
  itemid: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.tertiary.dark,
  },
  noBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: theme.spacing(9),
  },
}))

const MasterDetail = (props) => {
  const showAlert = useCommonStore(state => state.showAlert)
  const { t }: { t: any } = useTranslation()
  const classes = useStyles()

  const data = props.data
  const mcolumns = props.meta.columns
  const idColMeta = mcolumns.findIndex(m => m.isId) > -1 ? mcolumns.find(m => m.isId) : null
  const idfld = idColMeta ? idColMeta.code : '_id'

  let rowLabel = ''
  if (props.selectedDataItem) {
    if (props.meta.rowLabel && typeof props.meta.rowLabel === 'function' && props.meta.rowLabelFields && props.meta.rowLabelFields.length > 0) {
      let processedValues: any[] = props.meta.rowLabelFields
      .map(f => mcolumns.find(c => c.code === f))
      .map(m => processRawValue(props.selectedDataItem[m.code], m))
      rowLabel = props.meta.rowLabel(processedValues)
    } else if (idColMeta) {
      rowLabel =  idColMeta.type === 'date' ? moment(props.selectedDataItem[idfld]).format(t('date.format')) : (idColMeta.type === 'datetime' ? moment(props.selectedDataItem[idfld]).format(t('datetime.format')) : props.selectedDataItem[idfld])
    } else {
      rowLabel = props.selectedDataItem._id.substring(18)
    }
  }

  useEffect(() => {
    if (props.cachedState) {
      if (props.cachedState.orderState) _setOrderState(props.cachedState.orderState)
      if (props.cachedState.page) _setPage(props.cachedState.page)
      if (props.cachedState.rowsPerPage) _setRowsPerPage(props.cachedState.rowsPerPage)
      if (props.cachedState.itemFilter) _setItemFilter(props.cachedState.itemFilter)
      if (props.cachedState.itemSearchLiteral) _setItemSearchLiteral(props.cachedState.itemSearchLiteral)
    }
  }, [])
  const setOrderState = (on: any) => {
    setPartialCachedState('orderState', on)
    _setOrderState(on)
  }
  const setPartialCachedState = (field: string, val: any) => {
    if (props.cachedState && props.setCachedState) {
      let newcachedState: any = {name: props.cachedState.name}
      newcachedState[field] = val
      props.setCachedState(newcachedState)
    }
  }
  
  //sorting
  const [orderState, _setOrderState] = React.useState({
    order: !!props.meta.defaultSortOrder ? props.meta.defaultSortOrder : 'asc',
    orderBy: !!props.meta.defaultSortBy ? props.meta.defaultSortBy : '_id',
    orderColMeta: {},
  })
  const handleRequestSort = (event, property, colmeta) => {
    const isAsc = orderState.orderBy === property && orderState.order === 'asc'
    setOrderState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
      orderColMeta: colmeta,
    })
    setJustFilteredOrSorted(true)
  }
  /**
   * Pre-process an item's searchable raw data to speed-up search-string filtering.
   */
  const preprocessDataItem = (item) => {
    /* speed searching */
    const searchItem: any = {}
    mcolumns
    .filter(m => m.searchable || !!m.lookup) // searchItem is also used when sorting columns with lookup
    .forEach(m => {
      if (!!m.lookup) {
        searchItem[m.code] = processRawValue(item[m.code], m)?.toString()?.toLowerCase()
      } else {
        if (m.type === 'string') {
          searchItem[m.code] = !!item[m.code] ? (!!item[m.code].toLowerCase ? item[m.code].toLowerCase() : item[m.code].toString()) : ''
        } else if (m.type === 'number' || m.type === 'posnumber') {
          searchItem[m.code] = (!!item[m.code] || item[m.code] === 0) ? item[m.code].toString() : ''
        } else if (m.type === 'date') {
          searchItem[m.code] = !!item[m.code] ? moment(item[m.code]).format(t('date.format')).toLowerCase() : ''
        } else if (m.type === 'datetime') {
          searchItem[m.code] = !!item[m.code] ? moment(item[m.code]).format(t('datetime.format')).toLowerCase() : ''
        }
      }
    })
    /* speed up date/datetime sorting */
    const sortItem: any = {}
    mcolumns.filter(m => m.type === 'date' || m.type === 'datetime').forEach(m => {
      sortItem[m.code] = !!item[m.code] ? moment(item[m.code]).valueOf() : item[m.code]
    })
    const preprocessedItem = {...item, ...{searchItem: searchItem, sortItem: sortItem}}
    return preprocessedItem
  }
  /**
   * Pre-process all raw data to speed-up search-string filtering.
   */
  const [isLoading, setLoading] = useState(false)
  // const [preprocessedData, setPreprocessedData] = useState<any>([])
  // const debLoading = debounce(() => setLoading(true), 1000)
  // useEffect(() => {
  //   if (data.length > 2000) {
  //     debLoading()
  //     // setLoading(true)
  //   }
  // }, [data])
  // useEffect(() => {
  //   // if (isLoading) {
  //   //   setTimeout(() => {
  //   //     let now = moment()
  //   //     let preproData = data.map(d => preprocessDataItem(d))
  //   //     console.log(`preprocessedData length ${data.length} duration ${moment().diff(now, 'millisecond')}`)
  //   //     setPreprocessedData(preproData)
  //   //     setLoading(false)
  //   //   }, 10)
  //   // } else if (data.length <= 2000) {
  //       let now = moment()
  //       let preproData = data.map(d => preprocessDataItem(d))
  //       console.log(`preprocessedData length ${data.length} duration ${moment().diff(now, 'millisecond')}`)
  //       setPreprocessedData(preproData)
  //   // }
  // // }, [isLoading, data])
  // }, [data])
  const preprocessedData: any = useMemo(() => {
    let now = moment()
    let preproData = data.map(d => preprocessDataItem(d))
    // console.log(`preprocessedData length ${data.length} duration ${moment().diff(now, 'millisecond')}`)
    return preproData || []
  }, [data])
  /**
   * Preform sorting on data.
   * To speed up rendering when search-filtering is active, this method,
   * apart form sorting the entire props.data dataset,
   * will also sort the searchedDataMemo dataset.
   */
  const sortedDataMemo = useMemo(() => {
    // let now = moment()
    let sortedData = stableSort(preprocessedData, getComparator(orderState.order, orderState.orderBy, orderState.orderColMeta, processRawValue))
    // console.log(`sortedData length ${preprocessedData.length} duration ${moment().diff(now, 'millisecond')}`)
    return sortedData
  }, [orderState, preprocessedData])

  function getLookupFld(rec, key) {
    if (key.indexOf('.') < 0) {
      return rec[key]
    } else {
      let keys = key.split('.')
      let val = { ...rec }
      keys.forEach(k => {
        val = val[k]
      })
      return val
    }
  }
  function processRawValue(raw, met, exportType?: string) {
    let val = raw
    if (met.type === 'date') {
      if (!!val) {
        if (!!met.format) {
          val = moment(val).format(met.format)
        } else {
          val = moment(val).format(t('date.format'))
        }
      }
    } else if (met.type === 'datetime') {
      if (!!val) {
        if (!!met.format) {
          val = moment(val).format(met.format)
        } else {
          val = moment(val).format(t('datetime.format'))
        }
      }
    } else if (!met.lookup && (met.type === 'number' || met.type === 'posnumber')) {
      let fval = parseFloat(val)
      if (exportType === 'xlsx') {
        val = fval
      } else {
        if (!isNaN(fval)) {
          if (!!met.format) {
            let options: any = {}
            let fractionDigits = met.format.length - met.format.indexOf('.') - 1
            options = {
              minimumFractionDigits: fractionDigits,
              maximumFractionDigits: fractionDigits,
            }
            val = new Intl.NumberFormat('el', options).format(fval)
          } else {
            val = new Intl.NumberFormat('el').format(fval)
          }
          if (met.append) {
            val = `${val}${met.append}`
          }
          if (met.prepend) {
            val = `${met.prepend}${val}`
          }
        }
      }
    } else if (met.type === 'link') {
      if (!!val) {
        let href = val
        val = (
          <Link target="_blank" href={href}>
            <WrmIcon icon='imageIcon' style={{ width: 17, height: 19, verticalAlign: 'middle' }} />
          </Link>
        )
        // val = moment(val).format(t('datetime.format'))
      }
    } else if (typeof (met.type) === 'function') {
      /** If type of met.type is a function it means that it's a
       * custom formatting function
       */
      val = met.type(val)
    }
    if (!!met.lookup) {
      let look
      met.lookup.forEach(lookup => {
        if (!!look) {
          return
        }
        if (!!props.lookups[lookup]) {
          let lookupField = met.lookupField || 'code'
          let lookupFetch = met.lookupFetch || ['label']
          if (lookupFetch.length > 0) {
            if (!met.isArray) {
              look = props.lookups[lookup].find(l => getLookupFld(l, lookupField) === val)
              if (!!look) {
                if (lookupFetch.length === 1) {
                  val = getLookupFld(look, lookupFetch[0])
                } else if (!met.lookupTemplate) {
                  val = lookupFetch.map(f => getLookupFld(look, f)).join(' ')
                } else {
                  val = met.lookupTemplate
                  lookupFetch.forEach((f, i) => {
                    val = val.replace(`f${i + 1}`, getLookupFld(look, f))
                  })
                }
              }
            } else {
              if (val?.length > 0) {
                val = val.map(iv => {
                  let ival = iv
                  look = props.lookups[lookup].find(l => getLookupFld(l, lookupField) === iv)
                  if (!!look) {
                    if (lookupFetch.length === 1) {
                      ival = getLookupFld(look, lookupFetch[0])
                    } else if (!met.lookupTemplate) {
                      ival = lookupFetch.map(f => getLookupFld(look, f)).join(' ')
                    } else {
                      ival = met.lookupTemplate
                      lookupFetch.forEach((f, i) => {
                        ival = ival.replace(`f${i + 1}`, getLookupFld(look, f))
                      })
                    }
                  }
                  return ival
                }).join(', ')
              } else {
                val = ''
              }
            }
          }
        }
      })
    }
    return val
  }

  //filtering by search
  const setItemSearchLiteral = (isl: string) => {
    setPartialCachedState('itemSearchLiteral', isl)
    _setItemSearchLiteral(isl)
  }
  const [itemSearchLiteral, _setItemSearchLiteral] = React.useState('')
  const handleItemSearchLiteralChange = event => {
    let val = event.target.value
    setItemSearchLiteral(val)
    setJustFilteredOrSorted(true)
  
    if (!!props.selectedDataItem && !filterDataItemBySearch(preprocessDataItem(props.selectedDataItem).searchItem, val)) {
      props.setSelectedDataItem(null)
    }
  }
  /**
   * Checks if the given item passes the search-string criteria
   * @param item - the given item
   * @param altSearchString - the search-string criteria
   * @returns a boolean variable indicating if the item is vissible or not
   */
  const filterDataItemBySearch = (item, altSearchString?) => {
    let searchString = !!altSearchString ? altSearchString.toLowerCase() : itemSearchLiteral.toLowerCase()
    if (searchString.length === 0) return true
    let showRow = mcolumns.filter(m => m.searchable).some(m => {
      let val = item[m.code]
      val = val?.toString()
      let show = !!val && val.indexOf(searchString) !== -1
      return show
    })
    return showRow
  }
  /**
   * Store search results whenever the serach literal changes.
   * NOTE: We do not need an extra state variable for this so we use a memo.
   */
  const searchedDataMemo = useMemo(() => {
    let searchedData = sortedDataMemo?.filter(d => filterDataItemBySearch(d.searchItem, itemSearchLiteral))
    return searchedData
  }, [itemSearchLiteral, sortedDataMemo])

  //filtering by complex filter
  const setItemFilter = (itf: any) => {
    setPartialCachedState('itemFilter', itf)
    _setItemFilter(itf)
  }
  const prepareFilter = () => {
    let filter = {}
    mcolumns.filter(m => m.filterable).forEach(m => {
      if (m.multiselect) {
        filter[m.code] = !!m.filter ? m.filter : []
      } else if (m.type === 'date' || m.type === 'datetime') {
        // filter[m.code] = moment().format(t('date.format'))
        filter[`${m.code}_from`] = ''
        filter[`${m.code}_to`] = ''
      // } else if (m.type === 'boolean') {
      //   filter[m.code] = false
      } else {
        filter[m.code] = ''
      }
    })
    if (props.meta.rowsLimit || props.meta.rowsLimit === 0) {
      filter['rowsLimit'] = props.meta.rowsLimit
    }
    return filter
    // setItemFilter(filter)
  }
  const [itemFilter, _setItemFilter] = useState<any | null>(() => prepareFilter())
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)
  const handleCloseFilterDialog = (reply, filter?) => {
    if (typeof reply !== 'string') reply = 'no'
    //console.log(`handleCloseDetailDialog reply parsed: ${reply}`)
    // console.log(`handleCloseDetailDialog filter: ${JSON.stringify(filter,null,2)}`)
    if (reply === 'yes') {
      setFilterDialogOpen(false)
      setItemFilter(filter)
      if (props.meta.rowsLimit && filter.rowsLimit && typeof props.meta.fetchMoreRows === 'function' ) {
        const rowsLimit = parseInt(filter.rowsLimit)
        if (rowsLimit > props.meta.rowsLimit && rowsLimit > data.length) {
          props.meta.fetchMoreRows(rowsLimit)
        }
      }
  
      //if selected item has become out-of-scope then deselect it
      if (!!props.selectedDataItem && !filterDataByFilter(props.selectedDataItem, filter)) {
        props.setSelectedDataItem(null)
      }

      setJustFilteredOrSorted(true)
    } else {
      setFilterDialogOpen(false)
    }
  }
  const handleFiltersBtnClick = () => {
    if (!itemFilter) {
      let filter = prepareFilter()
      setItemFilter(filter)
    }
    setFilterDialogOpen(true)
  }
  const filterDataByFilter = (item, altItemFilter?) => {
    let show = true
    let theFilter = !!altItemFilter ? altItemFilter : itemFilter
    mcolumns.filter(m => m.filterable).forEach(m => {
      if (!show) return
      let fval = theFilter[m.code]
      if (m.type === 'boolean') {
        // console.log(`m.code ${m.code} item[m.code] ${item[m.code]} fval ${fval} item[m.code] === fval ${item[m.code] === fval}`)
        if (fval == null || fval === '') return
        show = item[m.code] === fval
      } else if (!!m.lookup) {
        if (!m.multiselect) {
          if (!fval && !(fval === 0 || fval === false)) return
          // console.log(`m.code ${m.code} item[m.code] ${item[m.code]} fval ${fval}`)
          if (m.isArray) {
            show = !!item[m.code] && item[m.code].some(el => fval === el)
          } else {
            show = (!!item[m.code] || item[m.code] === 0 || item[m.code] === false) && item[m.code] === fval
          }
        } else if (m.multiselect) {
          if (!fval || fval.length === 0) return
          if (m.isArray) {
            show = !!item[m.code] && item[m.code].some(el => fval.includes(el))
          } else {
            show = !!item[m.code] && fval.includes(item[m.code])
          }
        }
        // } else if (!!m.lookup && m.multiselect && !m.isAutocomplete) {
        //   if (!fval || fval.length === 0) return
        //   show = !!item[m.code] && fval.findIndex(v => v === item[m.code]) > -1
        // } else if (!!m.lookup && m.multiselect && m.isAutocomplete) {
        //   /** MULTISELECT +  AUTOCOMPLETE */
        //   if (!fval || fval.length === 0) return
        //   show = !!item[m.code] && item[m.code].some(el=>fval.includes(el))

      } else {
        if (m.type === 'date') {
          let fval_from = theFilter[`${m.code}_from`]
          let fval_to = theFilter[`${m.code}_to`]
          // console.log(`m.code ${m.code} item[m.code] ${item[m.code]} fval_from ${fval_from} fval_to ${fval_to}`)
          if (!(!!fval_from || !!fval_to)) return
          show = !!item[m.code]
          if (show) {
            let val = moment(item[m.code])
            if (!val.isValid()) {
              /* Creating the moment using the 'datetime.format' misses the timezone information. Use it as last resort */
              val = moment(item[m.code], t('date.format') as string)
            }
            if (!!fval_from) {
              let mval_from = moment(fval_from)
              if (!mval_from.isValid()) {
                /* Creating the moment using the 'datetime.format' misses the timezone information. Use it as last resort */
                mval_from = moment(fval_from, t('date.format') as string)
              }
              show = mval_from.isSameOrBefore(val)
            }
            if (show && !!fval_to) {
              let mval_to = moment(fval_to)
              if (!mval_to.isValid()) {
                /* Creating the moment using the 'datetime.format' misses the timezone information. Use it as last resort */
                mval_to = moment(fval_to, t('date.format') as string)
              }
              show = mval_to.isSameOrAfter(val)
            }
          }
        } else if (m.type === 'datetime') {
          let fval_from = theFilter[`${m.code}_from`]
          let fval_to = theFilter[`${m.code}_to`]
          // console.log(`m.code ${m.code} item[m.code] ${item[m.code]} fval_from ${fval_from} fval_to ${fval_to}`)
          if (!(!!fval_from || !!fval_to)) return
          show = !!item[m.code]
          if (show) {
            let val = moment(item[m.code])
            if (!val.isValid()) {
              /* Creating the moment using the 'datetime.format' misses the timezone information. Use it as last resort */
              val = moment(item[m.code], t('datetime.format') as string)
            }
            if (!!fval_from) {
              let mval_from = moment(fval_from)
              if (!mval_from.isValid()) {
                /* Creating the moment using the 'datetime.format' misses the timezone information. Use it as last resort */
                mval_from = moment(fval_from, t('datetime.format') as string)
              }
              show = mval_from.isSameOrBefore(val)
            }
            if (show && !!fval_to) {
              let mval_to = moment(fval_to)
              if (!mval_to.isValid()) {
                /* Creating the moment using the 'datetime.format' misses the timezone information. Use it as last resort */
                mval_to = moment(fval_to, t('datetime.format') as string)
              }
              show = mval_to.isSameOrAfter(val)
            }
          }
        } else if (m.type === 'number' || m.type === 'posnumber') {
          if (!fval) return
          // console.log(`m.code ${m.code} item[m.code] ${item[m.code]} fval ${fval} item[m.code] === fval ${item[m.code] === fval}`)
          show = (!!item[m.code] || item[m.code] === 0) && parseFloat(item[m.code]) === parseFloat(fval)
        } else if (m.type === 'string') {
          if (!fval) return
          // console.log(item[m.code])
          show = !!item[m.code] && item[m.code].toLowerCase().indexOf(fval.toLowerCase()) !== -1
        } else {
        }
      }

    })
    return show
  }
  const shouldApplyFilter = () => {
    const checkFilter = {...itemFilter}
    if (props.meta.rowsLimit || props.meta.rowsLimit === 0) {
      if (!checkFilter.rowsLimit || parseInt(checkFilter.rowsLimit) !== props.meta.rowsLimit) {
        return true
      }
      delete checkFilter.rowsLimit
    }
    let b = Object.values(checkFilter).some(fval => Array.isArray(fval) ? fval.length > 0 : !!fval || fval === 0 || fval === false)
    if (!b) {
      b = mcolumns.filter(m => m.type === 'boolean').some(m => checkFilter[m.code] === false)
    }
    return b
  }

  //editing
  const [editingDataItem, setEditingDataItem] = useState<any | null>(null)
  const [detailDialogOpen, setDetailDialogOpen] = useState(false)
  /*savingEdits is used to prevent multiple save requests && to indicate that the save request is in progress(spinner)*/
  const [isSavingEdits, setIsSavingEdits] = useState<boolean>(false)
  const handleCloseDetailDialog = (reply, editedItem?) => {
    if (typeof reply !== 'string') reply = 'no'
    //console.log(`handleCloseDetailDialog reply parsed: ${reply}`)
    if (reply === 'yes') {
      if (!validateDetail(editedItem)) {
        return
      }
      setIsSavingEdits(true)
      // console.log(`editedItem ${JSON.stringify(editedItem,null,2)}`)
      props.actSaveDetail(editedItem)
      .then(
        res => {
          // console.log(`props.actSaveDetail res: ${JSON.stringify(res,null,2)}`)
          setDetailDialogOpen(false)
          setEditingDataItem(null)
          props.setSelectedDataItem(res)
          setIsSavingEdits(false)
        }
        ,err => {
          if (!!err.response && !!err.response.data && !!err.response.data.error) {
            console.error(`props.actSaveDetail error ${JSON.stringify(err.response.data.error,null,2)}`)
            setIsSavingEdits(false)
          } else {
            console.log(`props.actSaveDetail err ${err}`)
            setIsSavingEdits(false)
          }
          // console.log(`props.actSaveDetail err: ${JSON.stringify(err,null,2)}`)
          // showAlert(err.toString(), 'E')
        }
      )
    } else {
      setEditingDataItem(null)
      setDetailDialogOpen(false)
      setIsSavingEdits(false)
    }
  }
  const validateDetail = (editedItem) => {
    // console.log(`validateDetail ${JSON.stringify(editedItem,null,2)}`)
    let valid = true
    let messages: string[] = []
    //do some default validation based on metadata, e.g. required consumerFields

    //check completion of required fields
    let fldLabels: any[] = []
    mcolumns
    .filter(colmeta => {
      let editable
      if (typeof colmeta.editable === 'function') {
        editable = colmeta.editable(editedItem, colmeta.code, props.selectedDataItem)
      } else {
        editable = colmeta.editable
      }
      return editable === true || editable === 'always'
        || (!!editedItem._id && editable === 'onEdit')
        || (!editedItem._id && editable === 'onAdd')
    })
    .forEach(m => {
      let b = !m.required || (
        (m.isArray && editedItem[m.code]?.length > 0)
        || (m.type === 'boolean')
        || ((m.type === 'number' || m.type === 'posnumber') && (!!editedItem[m.code] || editedItem[m.code] === 0))
        || (m.type === 'date' && !!editedItem[m.code])
        || (m.type === 'datetime' && !!editedItem[m.code])
        || (m.type === 'string' && !!editedItem[m.code] && !!editedItem[m.code].trim())
      )
      if (!b) {
        valid = false
        fldLabels.push(t(m.label))
      }
    })
    if (fldLabels.length > 0) {
      messages.push(`${t('md.validation.error.required')}\n\t${fldLabels.join(', ')}`)
    }

    //validate positive number fields
    fldLabels = []
    mcolumns.filter(m => m.type === 'posnumber').forEach(m => {
      let b = (!editedItem[m.code] || parseFloat(editedItem[m.code]) >= 0)
      if (!b) {
        valid = false
        fldLabels.push(t(m.label))
      }
    })
    // if (!valid) {
    if (fldLabels.length > 0) {
      // showAlert(`${t('md.validation.error.posnumber')}\n\t${fldLabels.join(', ')}`, 'E')
      messages.push(`${t('md.validation.error.posnumber')}\n\t${fldLabels.join(', ')}`)
    }

    //validate regexOnValidate fields
    mcolumns.filter(m => !!m.regexOnValidate).forEach(m => {
      let invalidChars = editedItem[m.code].match(m.regexOnValidate)
      if (!!invalidChars) {
        let msg = `${t('md.validation.error.invalidChars.found')} '${t(m.label)}':\n`
        msg = msg + `\t'${invalidChars.join("', '")}' \n`
        msg = msg + `\t${t('md.validation.error.invalidChars.validAre')}:\n`
        if (!!m.validCharsMsg) {
          msg = msg + `\t${m.validCharsMsg}`
        } else {
          msg = msg + `\t${m.regexOnValidate}`
        }
        valid = false
        messages.push(msg)
      }
    })

    //do custom validation if a validateEdit function is provided by the client
    if (!!props.meta.validateEdit) {
      let customMessage = props.meta.validateEdit(editedItem, props.selectedDataItem)
      if (!!customMessage) {
        valid = false
        messages.push(customMessage)
      }
    }

    //finally if validation errors were found show an alert message stating the errrors found
    if (!valid) {
      showAlert(`${messages.join('\n\n')}`, 'E')
    }
    return valid
  }
  const handleAddDetail = () => {
    console.log(`handleAddDetail`)
    let dataItem = {}
    //....
    prepareEditingItem(dataItem)
  }
  const handleEditDetail = () => {
    console.log(`handleEditDetail`)
    if (!!props.selectedDataItem) {
      // let dataItem = {...props.selectedDataItem}
      let dataItem = cloneDeep(props.selectedDataItem)
      prepareEditingItem(dataItem)
    }
  }
  const prepareEditingItem = (dataItem) => {
    console.log(`prepareEditingItem`)
    mcolumns.forEach(m => {
      if (!dataItem[m.code]) {
        if (m.isArray) {
          dataItem[m.code] = []
        } else if (m.type === 'date') {
          // dataItem[m.code] = moment().format(t('date.format'))
        } else if (m.type === 'datetime') {
          // dataItem[m.code] = moment().format(t('datetime.format'))
        } else if (m.type === 'boolean') {
          dataItem[m.code] = false
        } else if (m.type === 'number' || m.type === 'posnumber') {
          if (dataItem[m.code] !== 0) {
            dataItem[m.code] = ''
          }
        } else {
          dataItem[m.code] = ''
        }
      }
    })
    setEditingDataItem(dataItem)
    setDetailDialogOpen(true)
  }

  //deleting
  const [confirmDialogId, setConfirmDialogId] = React.useState('')
  const [confirmDialogTitle, setConfirmDialogTitle] = React.useState('... Ahh ... I forgot what I wanted to ask!')
  const [confirmDialogContent, setConfirmDialogContent] = React.useState('')
  const [confirmDialogNote, setConfirmDialogNote] = useState<null|any>()
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
  const handleOpenConfirmDialog = (dlgId, title, content?) => {
    if (!props.meta.noDeletionReasonNote) {
      setConfirmDialogNote({text: ''})
    }
    setConfirmDialogId(dlgId)
    setConfirmDialogTitle(title)
    if (!!content) setConfirmDialogContent(content)
    setOpenConfirmDialog(true)
  }
  const handleCloseConfirmDialog = (reply) => {
    if (typeof reply !== 'string') reply = 'no'
    if (reply === 'yes') {
      if (!props.meta.noDeletionReasonNote && !validateConfirmDialogNote(confirmDialogNote)) {
        return
      }
      switch (confirmDialogId) {
        case 'confirmDeleteDetail':
          props.actDeleteDetail(props.selectedDataItem, {...confirmDialogNote})
          .then(
            res => {
              // console.log(`props.actDeleteDetail res: ${JSON.stringify(res,null,2)}`)
              props.setSelectedDataItem(null)
            }
            ,err => {
              if (!!err.response && !!err.response.data && !!err.response.data.error) {
                console.error(`props.actDeleteDetail error ${JSON.stringify(err.response.data.error,null,2)}`)
              } else {
                console.log(`props.actDeleteDetail err ${err}`)
              }
            }
          )
          // props.setSelectedDataItem(null)
          break;
        default:
      }
      setOpenConfirmDialog(false)
      setConfirmDialogContent('')
      setConfirmDialogNote(null)
    } else {
      setOpenConfirmDialog(false)
      setConfirmDialogContent('')
      setConfirmDialogNote(null)
    }
  }
  const handleDialogNoteChange = event => {
    let val = event.target.value
    let note: any = Object.assign({}, confirmDialogNote)
    note.text = val
    setConfirmDialogNote(note)
  }
  const validateConfirmDialogNote = (note) => {
    let valid = true
    note.text = note.text.trim()
    if (!note.text) {
      showAlert(t('md.confirm.delete.item.noteNotEmpty'), 'W')
      valid = false
    }
    return valid
  }

  /* exporting functionality */
  const menuOptions = [
    {code: 'xlsx', label: 'md.export.excel'},
    {code: 'csv', label: 'md.export.csv'},
    {code: 'pdf', label: 'md.export.pdf'},
  ]
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  const handleMenuBtnClick = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }
  /**
   * Prepare a header object based on column metadata that will be used when exporting the component's data.
   */
  const prepareExportHeader = (): any => {
    let exportHeader: any = props.meta.columns
    .filter(cm => !cm.hidden)
    .map(cm => ({
      title: t(cm.label),
      width: 40,
    }))
    return exportHeader
  }
  /**
   * Prepare the component's data for export.
   * This function applies the same processing (i.e. formatting, fetching lookup values, filtering, sorting)
   * that is applied to the displayed page data (before rendering),
   * but to the entire set of data.
   */
  const prepareExportData = (exportType?: string): any => {
    let sourceData: any[] = [...sortedDataMemo]
    if (itemSearchLiteral.length > 0) {
      sourceData = [...searchedDataMemo]
    }
    if (!!itemFilter && shouldApplyFilter()) {//todo: make more elaborate check (e.g. for boolean fields)
      sourceData = sourceData.filter(d => filterDataByFilter(d))
    }

    let exportData: any = sourceData.map(rrow => {
      let xrow: any = {}
      props.meta.columns
      .filter(cm => !cm.hidden)
      .forEach(cm => {
        //prepare data
        let val = processRawValue(rrow[cm.code], cm, exportType)
        xrow[cm.code] = val
      })
      return xrow
    })
    return exportData
  }

  //paging
  const setRowsPerPage = (rpp: number) => {
    setPartialCachedState('rowsPerPage', rpp)
    _setRowsPerPage(rpp)
  }
  const pageSize = props.meta.pageSize ? props.meta.pageSize : 10
  const [justFilteredOrSorted, setJustFilteredOrSorted] = useState(true)
  const setPage = (p: number) => {
    setPartialCachedState('page', p)
    _setPage(p)
  }
  const [page, _setPage] = useState(0)
  const [rowsPerPage, _setRowsPerPage] = useState(pageSize)
  // const emptyRows = !data ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setJustFilteredOrSorted(false)
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setJustFilteredOrSorted(true)
    setPage(0)
  }


  let pageData: any[] = [...sortedDataMemo]
  let pageDataCount = 0
  let dpage = page
  if (!!data) {

    if (itemSearchLiteral.length > 0) {
      pageData = [...searchedDataMemo]
    }
    if (!!itemFilter && shouldApplyFilter()) {//todo: make more elaborate check (e.g. for boolean fields)
      pageData = pageData.filter(d => filterDataByFilter(d))
    }
    pageDataCount = pageData.length

    //readjust page number (dpage) after sorting and filtering
    if (rowsPerPage > 0) {
      if (!!props.selectedDataItem) {
        let idx = pageData.findIndex(d => d[idfld] === props.selectedDataItem[idfld])
        if (idx > -1) {
          if (justFilteredOrSorted) {
            dpage = Math.floor(idx / rowsPerPage)
          }
        } else {
          dpage = 0
        }
      } else {
        if (page * rowsPerPage > pageData.length) {
          dpage = 0
        }
      }
      if (dpage !== page) {
        /* TODO: The next line causes a react warning:
         * "Warning: Cannot update a component (`IrriEventCatalog`) while rendering a different component (`MasterDetail`).
         *  To locate the bad setState() call inside `MasterDetail`" */
        setPage(dpage)
      }

      pageData = pageData.slice(dpage * rowsPerPage, dpage * rowsPerPage + rowsPerPage)
    }
  }

  return (
    <Box
      p={props.meta.compact ? 0 : 1}
      width={props.meta.hideDetails ? 'unset' : 'calc(100vw - 90px)'}
      className={classes.mdroot}
    >
      {isLoading &&
      <div>Processing...</div>
      }
      {!isLoading &&
      <MdMaster
        meta={props.meta}
        data={props.data}
        selectedDataItem={props.selectedDataItem}
        setSelectedDataItem={props.setSelectedDataItem}
        processRawValue={processRawValue}
        rowLabel={rowLabel}
        idfld={idfld}
        FTC={FTC}
        iconlookups={props.iconlookups}

        // crud
        handleEditDetail={handleEditDetail}
        handleAddDetail={handleAddDetail}
        handleOpenConfirmDialog={handleOpenConfirmDialog}
        customEditing={props.customEditing}

        // menu actions
        menuOptions={menuOptions}
        menuAnchorEl={menuAnchorEl}
        setMenuAnchorEl={setMenuAnchorEl}
        handleMenuBtnClick={handleMenuBtnClick}
        prepareExportHeader={prepareExportHeader}
        prepareExportData={prepareExportData}

        // sorting
        order={orderState.order}
        orderBy={orderState.orderBy}
        handleRequestSort={handleRequestSort}

        // filter/search
        itemFilter={itemFilter}
        handleFiltersBtnClick={handleFiltersBtnClick}
        itemSearchLiteral={itemSearchLiteral}
        handleItemSearchLiteralChange={handleItemSearchLiteralChange}
        shouldApplyFilter={shouldApplyFilter}

        // paging
        pageData={pageData}
        pageDataCount={pageDataCount}
        dpage={dpage}
        // emptyRows={emptyRows}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}

        // checking
        checkedItems={props.checkedItems}
        setCheckedItems={props.setCheckedItems}
        doCheckFeature={props.doCheckFeature}

        // custom ui
        customMasterButtons={props.customMasterButtons}
        customMasterBar={props.customMasterBar}
      />
      }
      {!isLoading && !props.meta.hideDetails && 
      <MdDetail
        meta={props.meta}
        selectedDataItem={props.selectedDataItem}
        processRawValue={processRawValue}
        users={props.users}
        rowLabel={rowLabel}
        idfld={idfld}
        FTC={FTC}
        showAlert={showAlert}

        // crud
        handleEditDetail={handleEditDetail}
        handleOpenConfirmDialog={handleOpenConfirmDialog}
        actSaveDetailNote={props.actSaveDetailNote}
        actDeleteDetailNote={props.actDeleteDetailNote}

        // attachements
        actSaveDetailAttachment={props.actSaveDetailAttachment}
        actDeleteDetailAttachment={props.actDeleteDetailAttachment}

        // custom ui
        customDetails={props.customDetails}
        onCustomTabChange={props.onCustomTabChange}
        customInfoButtons={props.customInfoButtons}
      />
      }
      {!!editingDataItem  && !props.customEditing &&
      <MdEditingDialog
        open={detailDialogOpen}
        lookups={props.lookups}
        selectedDataItem={props.selectedDataItem}
        editingDataItem={editingDataItem}
        getLookupFld={getLookupFld}
        meta={props.meta}
        handleCloseDetailDialog={handleCloseDetailDialog}
        isSavingEdits={isSavingEdits}
      />
      }
      {!!itemFilter && filterDialogOpen &&
      <MdFilterDialog
        open={filterDialogOpen}
        lookups={props.lookups}
        itemFilter={itemFilter}
        getLookupFld={getLookupFld}
        meta={props.meta}
        handleCloseFilterDialog={handleCloseFilterDialog}
      />
      }
      <Dialog
        id="confirmDialog"
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>{confirmDialogTitle}</DialogTitle>
        <DialogContent>
          <Box
            display='flex'
            flexDirection='column'
          >
            {!!confirmDialogContent && <Box
              display='flex'
              className={classes.itemid}
            >
              {confirmDialogContent}
            </Box>}
            {!!confirmDialogNote && <Box mt={2} mb={1}
              display='flex'
            >
              <TextField
                label={t('md.confirm.delete.item.notePrompt')}
                fullWidth
                multiline
                minRows={3}
                value={confirmDialogNote.text}
                onChange={handleDialogNoteChange}
              />
            </Box>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant='outlined'
            className={classes.noBtn}
            onClick={() => handleCloseConfirmDialog('no')}
          >
            {t('btn.no')}
          </Button>
          <Button
            variant='outlined'
            className={classes.noBtn}
            color="primary"
            onClick={() => handleCloseConfirmDialog('yes')}
          >
            {t('btn.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )

}

export default MasterDetail
