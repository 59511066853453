import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

//vertical bar
import { ReactComponent as Tint } from "../assets/icons/Tint.svg"
import { ReactComponent as Activities } from "../assets/icons/Activities.svg"
import { ReactComponent as CatalogElements } from "../assets/icons/catalog-elements.svg"
import { ReactComponent as Configuration } from "../assets/icons/Configuration.svg"
import { ReactComponent as ConsumerPortal } from "../assets/icons/ConsumerPortal.svg"
import { ReactComponent as EnergyOptimization } from "../assets/icons/EnergyOptimization.svg"
import { ReactComponent as HydraulicOptimization } from "../assets/icons/HydraulicOptimization.svg"
import { ReactComponent as FiredAlarms } from "../assets/icons/firedAlarms.svg"
import { ReactComponent as InventoryCategories } from "../assets/icons/inventory-categories.svg"
import { ReactComponent as IrriSchedule } from "../assets/icons/irriSchedule.svg"
import { ReactComponent as Irrigain } from "../assets/icons/Irrigain.svg"
import { ReactComponent as IrriForm } from "../assets/icons/irriForm.svg"
import { ReactComponent as MultipleSelection } from "../assets/icons/multiple-selection.svg"
import { ReactComponent as MyAccount } from "../assets/icons/MyAccount.svg"
import { ReactComponent as MyAccountMobile } from "../assets/icons/MyAccountMobile.svg"
import { ReactComponent as NetworkDesign } from "../assets/icons/NetworkDesign.svg"
import { ReactComponent as NetworkUpdates } from "../assets/icons/NetworkUpdates.svg"
import { ReactComponent as NetworkUpdatesMobile } from "../assets/icons/NetworkUpdatesMobile.svg"
import { ReactComponent as Profile } from "../assets/icons/Profile.svg"
import { ReactComponent as QualityControl } from "../assets/icons/quality-control.svg"
import { ReactComponent as ReportAnIssue } from "../assets/icons/ReportAnIssue.svg"
import { ReactComponent as WaterBalance } from "../assets/icons/water-balance.svg"
//network features
import { ReactComponent as Hydrant } from "../assets/icons/hydrant.svg"
import { ReactComponent as Hydrometer } from "../assets/icons/hydrometer.svg"
import { ReactComponent as Junction } from "../assets/icons/junction.svg"
import { ReactComponent as Landparcel } from "../assets/icons/landparcel.svg"
import { ReactComponent as Pipe } from "../assets/icons/pipe.svg"
import { ReactComponent as Pump } from "../assets/icons/pump.svg"
import { ReactComponent as Reservoir } from "../assets/icons/reservoir.svg"
import { ReactComponent as SamplingPoint } from "../assets/icons/sampling-point.svg"
import { ReactComponent as Station } from "../assets/icons/station.svg"
import { ReactComponent as Switch } from "../assets/icons/Switch.svg"
import { ReactComponent as Tank } from "../assets/icons/tank.svg"
import { ReactComponent as Valve } from "../assets/icons/valve.svg"
import { ReactComponent as Wszone } from "../assets/icons/wszone.svg"
//buttons, etc
import { ReactComponent as MeasurementTools } from "../assets/icons/measurementTools.svg"
import { ReactComponent as Map } from "../assets/icons/map.svg"
import { ReactComponent as MapTools } from "../assets/icons/mapTools.svg"
import { ReactComponent as Marker } from "../assets/icons/marker.svg"
import { ReactComponent as MarkerMd } from "../assets/icons/markerMd.svg"
import { ReactComponent as Ruler } from "../assets/icons/ruler.svg"
import { ReactComponent as RulerArea } from "../assets/icons/rulerArea.svg"
import { ReactComponent as RulerPipe } from "../assets/icons/rulerPipe.svg"
import { ReactComponent as Attach } from "../assets/icons/attach.svg"
import { ReactComponent as Send } from "../assets/icons/send.svg"
import { ReactComponent as Filter } from "../assets/icons/filter.svg"
import { ReactComponent as Xicon } from "../assets/icons/x-icon.svg"
import { ReactComponent as Pencil } from "../assets/icons/pencil.svg"
import { ReactComponent as Trash } from "../assets/icons/trash.svg"
import { ReactComponent as Dock } from "../assets/icons/dock.svg"
import { ReactComponent as Undock } from "../assets/icons/undock.svg"
import { ReactComponent as ArrowLeft } from "../assets/icons/arrowLeft.svg"
import { ReactComponent as ImageIcon } from "../assets/icons/ImageIcon.svg"
import { ReactComponent as DocIcon } from "../assets/icons/DocIcon.svg"
import { ReactComponent as EquipmentMaintenance } from "../assets/icons/equipment-maintenance.svg"
import { ReactComponent as Check } from "../assets/icons/fa-check.svg"
import { ReactComponent as Edit } from "../assets/icons/edit.svg"
import { ReactComponent as Consumption } from "../assets/icons/consumption.svg"
import { ReactComponent as SubmitReading } from "../assets/icons/submitReading.svg"
import { ReactComponent as ViewDetails } from "../assets/icons/viewDetails.svg"
import { ReactComponent as Graph } from "../assets/icons/graph.svg"
import { ReactComponent as Graph2 } from "../assets/icons/graph2.svg"
import { ReactComponent as Meter } from "../assets/icons/meter.svg"
import { ReactComponent as Target } from "../assets/icons/target.svg"
import { ReactComponent as Download } from "../assets/icons/download.svg"
import { ReactComponent as Print } from "../assets/icons/print.svg"
import { ReactComponent as Report } from "../assets/icons/report.svg"
import { ReactComponent as Bill } from "../assets/icons/bill-32.svg"
import { ReactComponent as IssueReport } from "../assets/icons/issueReport.svg"
import { ReactComponent as Legend} from '../assets/icons/legend.svg'
import { ReactComponent as List} from '../assets/icons/list.svg'
import { ReactComponent as Up} from '../assets/icons/up.svg'
import { ReactComponent as Down} from '../assets/icons/down.svg'
import { ReactComponent as Delete} from '../assets/icons/delete.svg'
import { ReactComponent as Ongoing} from '../assets/icons/ongoing.svg'
import { ReactComponent as Done} from '../assets/icons/done.svg'
import { ReactComponent as Flow} from '../assets/icons/flow.svg'
import { ReactComponent as FlowReverse} from '../assets/icons/flowReverse.svg'
import { ReactComponent as Add} from '../assets/icons/add.svg'
import { ReactComponent as Remove} from '../assets/icons/remove.svg'
import { ReactComponent as Info} from '../assets/icons/info.svg'
import { ReactComponent as DeleteOutline} from '../assets/icons/deleteoutline.svg'
import { ReactComponent as PumpActive} from '../assets/icons/pumpactive.svg'
import { ReactComponent as PumpInactive} from '../assets/icons/pumpinactive.svg'
import { ReactComponent as Warning} from '../assets/icons/warning.svg'
import { ReactComponent as CheckCircle} from '../assets/icons/CheckCircle.svg'
import { ReactComponent as WarningRounded} from '../assets/icons/WarningRounded.svg'
import { ReactComponent as AttachmentIcon} from '../assets/icons/attachments.svg'

// import { isPropertySignature } from 'typescript'

/**
  Due to some bug the .MuiSvgIcon-root css class of SvgIcon is, sometimes, not applied,
  e.g. on the map expand the measuring tools toolbar
  then click on a tank marker
  then without closing the popup click on a samplingPoint marker and
  notice how the size of the toolbar buttons increases
  (evenmore, the ruler icon button gets even larger than the other two and looses its icon).
  Then click buck on the tank marker and the sizes return to normal !?
  In order to mitigate this bug we explicitly add a 'copy' of the css cless
  using the makeStyles/useStyles mechanism,
  so instead of adding 'MuiSvgIcon-root' we add classes.muiSvgIconRoot
  to the returned component
**/
const useStyles = makeStyles(theme => ({
  muiSvgIconRoot: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
  },
}))

const WrmIcon = (props) => {
  // console.log(`props ${JSON.stringify(props,null,2)}`)
  //props.push({id:props.icon + '_icon'})
  const {icon, ...rest} = props
  const classes = useStyles()

  // rest.className = !!rest.className ? clsx('MuiSvgIcon-root', rest.className) : 'MuiSvgIcon-root'
  rest.className = !!rest.className ? clsx(classes.muiSvgIconRoot, rest.className) : classes.muiSvgIconRoot
  // console.log(`props ${JSON.stringify(props,null,2)}`)
  rest.id = icon+'_icon'+ Math.random()
  rest.name = icon+'_icon'
  switch (icon) {
    //vertical bar
    case "tint": return <Tint {...rest} />
    case "activities": return <Activities {...rest} />
    case "catalog-elements": return <CatalogElements {...rest} />
    case "configuration": return <Configuration {...rest} />
    case "consumerPortal": return <ConsumerPortal {...rest} />
    case "energy-optimization": return <EnergyOptimization {...rest} />
    case "hydraulic-optimization": return <HydraulicOptimization {...rest} />
    case "equipment-maintenance": return <EquipmentMaintenance {...rest} />
    case "firedAlarms": return <FiredAlarms {...rest} />
    case "inventory-categories": return <InventoryCategories {...rest} />
    case "irrigain": return <Irrigain {...rest} />
    case "irriForm": return <IrriForm {...rest} />
    case "irriSchedule": return <IrriSchedule {...rest} />
    case "multiple-selection": return <MultipleSelection {...rest} />
    case "myAccount": return <MyAccount {...rest} />
    case "myAccountMobile": return <MyAccountMobile {...rest} />
    case "networkDesign": return <NetworkDesign {...rest} />
    case "networkUpdates": return <NetworkUpdates {...rest} />
    case "networkUpdatesMobile": return <NetworkUpdatesMobile {...rest} />
    case "profile": return <Profile {...rest} />
    case "quality-control": return <QualityControl {...rest} />
    case "reportAnIssue": return <ReportAnIssue {...rest} />
    case "water-balance": return <WaterBalance {...rest} />
    //network featurws
    case "hydrant": return <Hydrant {...rest} />
    case "hydrometer": return <Hydrometer {...rest} />
    case "junction": return <Junction {...rest} />
    case "landparcel": return <Landparcel {...rest} />
    case "pipe": return <Pipe {...rest} />
    case "pump": return <Pump {...rest} />
    case "reservoir": return <Reservoir {...rest} />
    case "samplingPoint": return <SamplingPoint {...rest} />
    case "station": return <Station {...rest} />
    case "switch": return <Switch {...rest} />
    case "tank": return <Tank {...rest} />
    case "valve": return <Valve {...rest} />
    case "wszone": return <Wszone {...rest} />

    //buttons, etc
    case "arrowLeft": return <ArrowLeft {...rest} />
    case "attach": return <Attach {...rest} />
    case "attachment": return <AttachmentIcon {...rest} />
    case "bill": return <Bill {...rest} />
    case "check": return <Check {...rest} />
    case "consumption": return <Consumption {...rest} />
    case "docIcon": return <DocIcon {...rest} />
    case "dock": return <Dock {...rest} />
    case "download": return <Download {...rest} />
    case "edit": return <Edit {...rest} />
    case "filter": return <Filter {...rest} />
    case "flow": return <Flow {...rest} />
    case "flowReverse": return <FlowReverse {...rest} />
    case "graph": return <Graph {...rest} />
    case "graph2": return <Graph2 {...rest} />
    case "imageIcon": return <ImageIcon {...rest} />
    case "issueReport": return <IssueReport {...rest} />
    case "legend": return <Legend {...rest} />
    case "list": return <List {...rest} />
    case "measurementTools": return <MeasurementTools {...rest} />
    case "map": return <Map {...rest} />
    case "mapTools": return <MapTools {...rest} />
    case "marker": return <Marker {...rest} />
    case "markerMd": return <MarkerMd {...rest} />
    case "meter": return <Meter {...rest} />
    case "pencil": return <Pencil {...rest} />
    case "print": return <Print {...rest} />
    case "report": return <Report {...rest} />
    case "ruler": return <Ruler {...rest} />
    case "rulerArea": return <RulerArea {...rest} />
    case "rulerPipe": return <RulerPipe {...rest} />
    case "send": return <Send {...rest} />
    case "submitReading": return <SubmitReading {...rest} />
    case "target": return <Target {...rest} />
    case "trash": return <Trash {...rest} />
    case "undock": return <Undock {...rest} />
    case "viewDetails": return <ViewDetails {...rest} />
    case "x-icon": return <Xicon {...rest} />
    case "up": return <Up {...rest} />
    case "down": return <Down {...rest} />
    case "delete": return <Delete {...rest} />
    case "done": return <Done {...rest} />
    case "ongoing": return <Ongoing {...rest} />
    case "add": return <Add {...rest} />
    case "remove": return <Remove {...rest} />
    case "info": return <Info {...rest} />
    case "deleteOutline": return <DeleteOutline {...rest} />
    case "pumpActive": return <PumpActive {...rest} />
    case "pumpInactive": return <PumpInactive {...rest} />
    case "warning": return <Warning {...rest} />
    case "warningRounded": return <WarningRounded {...rest} />
    case "checkCircle": return <CheckCircle {...rest} />

    default: return <Tint {...rest} />
  }
}

export default WrmIcon
