import React, { useState, useEffect, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useAuth0 } from '../react-auth0-spa'

import MeasurementsManager from '../services/MeasurementsManager'
import NotificationsManager from '../services/NotificationsManager'
import CollectionManager from '../services/CollectionManager'
import { featureType } from '../services/FeatureService'

import { buildErrorMessage } from '../utils/helpers'
import { FTC } from '../utils/constants'
import SimpleErrorBoundary from './error/SimpleErrorBoundary'
import SimpleErrorBoundaryContent from './error/SimpleErrorBoundaryContent'
import { useCollectionStore } from '../state/collectionStore'
import { useNetworkElementStore } from '../state/networkElementStore'
import { useCommonStore } from '../state/commonStore'
import { useUiStore } from '../state/uiStore'

import MainVertical from './MainVertical'
import Profile from './Profile'
import LMap from './LMap'
import EntitiesPane from './EntitiesPane'
import FeatureDetails from './network/FeatureDetails'
import Pop from './Pop'
import Toaster from './Toaster'

import NotificationPopUp from './personnelNotifications/NotificationPopUp'
import NotificationsList from './personnelNotifications/NotificationsList'

import UserSettings from './userSettings/UserSettings'
import WebSocketsContainer from './webSockets/WebSocketsContainer'

import { Role, Notification, Profile as UserProfile } from '../utils/types/collectionStoreTypes'
import { AlertSeverity } from '../utils/types/commonTypes'
import useFeatureCollection from '../hooks/useFeatureCollection'
import useLocaleHandler from '../hooks/useLocaleHandler'
import useServices from '../hooks/useServices'
import useNavigation from '../hooks/useNavigation'
import AppBarMobile from './mobile/AppBarMobile'
import SkeletonMainMobile from './mobile/SkeletonMainMobile'
const MainMobile = React.lazy(() => import('./mobile/MainMobile'))

const Maintenance = React.lazy(() => import('./inventoryMaintenance/Maintenance'))
const Inventory = React.lazy(() => import('./inventoryCategories/Inventory'))
const ActivitiesProcedures = React.lazy(() => import('./activities/ActivitiesProcedures'))
const WaterBalance = React.lazy(() => import('./waterBalance/WaterBalance'))
const QualityControl = React.lazy(() => import('./qualityControl/QualityControl'))
const EnergyOptimazation = React.lazy(() => import('./energyOptimazation/EnergyOptimazation'))
const HydraulicOptimazation = React.lazy(() => import('./energyOptimazation/HydraulicOptimazation'))
const MultiFeatureStepper = React.lazy(() => import('./scada/MultiFeatureStepper'))
const Catalogs = React.lazy(() => import('./feature/Catalogs'))
const Irrigation = React.lazy(() => import('./irrigation/Irrigation'))
const IrrigationApplications = React.lazy(() => import('./irrigation/IrrigationApplications'))
const IrrigationSchedule = React.lazy(()=>import('./irrigation/irriSchedule/IrrigationSchedule'))
const CPConsumerMain = React.lazy(() => import('./consumer/CPConsumerMain'))
const ConsumerPortal = React.lazy(() => import('./consumer/ConsumerPortal'))
const DataImportMain = React.lazy(() => import('./dataImport/DataImportMain'))
const TenantSettings = React.lazy(() => import('./tenantSettings/TenantSettings'))
const HydoorSettings = React.lazy(() => import('./hydoorSettings/HydoorSettings'))

const drawerWidth = 58
const drawerEntitiesWidth = 246
const drawerEntityPropertiesWidth = 500
const useStyles = makeStyles(theme => ({
  rootMain: {
    display: 'flex',
    flexGrow: 1,
  },
  secondaryDark: {
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.primary.main,
  },
  menuLink: {
    width: '100%',
  },
  hide: {
    display: 'none',
  },
  main: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(2),
  },
  contentMobile:{
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0 0 0',
      maxWidth: '100vw',
    },
  },
  drawer: {
    minWidth: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.25)',
    borderRightWidth: 0,
    zIndex: 1232,
    [theme.breakpoints.down('xs')]: {
      display:'none',
    },
  },
  drawerCommon: {
    borderRightWidth: 0,
  },
  drawerClose: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerEntitiesOpen: {
    width: drawerEntitiesWidth,
    marginLeft: 8,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerEntitiesOpenPaper: {
    zIndex: 1216,
    width: drawerEntitiesWidth,
    left: drawerWidth + 8,
    background: '#F6F6F6',
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.1)',
  },
  drawerEntityPropertiesOpen: {
    width: drawerEntityPropertiesWidth,
    marginLeft: 6,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerEntityPropertiesOpenPaper: {
    zIndex: 1208,
    width: drawerEntityPropertiesWidth,
    left: drawerWidth + 8,
    background: '#F6F6F6',
    boxShadow: '4px 0px 6px rgba(0, 0, 0, 0.1)',
  },
}))

const Main = () => {
  const { loading, user, isAuthenticated, loginWithRedirect, logout, getTokenSilently, } = useAuth0()
  const classes = useStyles()
  const screenMaxWidth600 = useMediaQuery('(max-width:600px)')

  useLocaleHandler()
  const { t } = useTranslation()

  /* init services */
  const services = useServices(getTokenSilently)
  const { verticalNavigate } = useNavigation()

  /**Measurement Manager */
  // const measurementsManager = MeasurementsManager()
  // const getFeatureMeasurements = measurementsManager.getFeatureMeasurements
  // const loadConsumptions = measurementsManager.loadConsumptions

  /* State */
  const profile = useCommonStore(state => state.profile)
  const setProfile = useCommonStore(state => state.setProfile)
  const setShowAlert = useCommonStore(state => state.setShowAlert)
  const setAccessGranted = useCommonStore(state => state.setAccessGranted)
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)

  const roles = useCollectionStore(state => state.roles)
  const rights = useCollectionStore(state => state.rights)
  const licenses = useCollectionStore(state => state.licenses)
  const tenants = useCollectionStore(state => state.tenants)
  const alarms = useCollectionStore(state => state.alarms)
  const userSettings = useCollectionStore(state =>state.userSettings)
  const tenantSettings = useCollectionStore(state => state.tenantSettings)
  const agriculturalPeriods = useCollectionStore(state => state.agriculturalPeriods)
  const [licensedRightCodes, setLicensedRightCodes] = useState<any|null>(null)

  const personnels = useCollectionStore(state => state.personnels)
  const [inventoryItems, setInventoryItems] = useState<any | null>(null)

  const setCollectionItems = useCollectionStore(state => state.setItems)

  const [issueReports, setIssueReports] = useState<any | null>(null)

  const measurementTypes = useCollectionStore(state => state.measurementTypes)
  const setMeasurementTypes = (mtypes) => {
    setCollectionItems('measurementTypes', mtypes)
  }
  // Notifications
  const notificationsManager = NotificationsManager()
  const consumerNotifications = useCollectionStore(state => state.consumerNotifications)
  const personnelNotifications = useCollectionStore(state => state.personnelNotifications)

  // Alarms
  const [selectedFeatureWithActiveAlarms,setSelectedFeatureWithActiveAlarms]=useState<{feature:{},notification:{}} | null>(null)
  const [flowPopupIsOpen, setFlowPopupIsOpen] = useState<boolean>(false)
  // Features
  const pumps = useNetworkElementStore(state => state.pumps)
  const stations = useNetworkElementStore(state => state.stations)
  const switches = useNetworkElementStore(state => state.switches)
  const tanks = useNetworkElementStore(state => state.tanks)

  const featureCollection = useFeatureCollection()
  
  const [visibleLayers, setVisibleLayers] = useState(Object.values(FTC).map(ftc => ftc.collection))

  /* UiStore */
  const entitiesOpen = useUiStore(state => state.entitiesOpen)
  const setEntitiesOpen = useUiStore(state => state.setEntitiesOpen)
  const entitiesOverlayOpen = useUiStore(state => state.entitiesOverlayOpen)
  const setEntitiesOverlayOpen = useUiStore(state => state.setEntitiesOverlayOpen)
  const entitiesOverlayKey = useUiStore(state => state.entitiesOverlayKey)
  const setEntitiesOverlayKey = useUiStore(state => state.setEntitiesOverlayKey)
  const contentKey = useUiStore(state => state.contentKey)
  const setContentKey = useUiStore(state => state.setContentKey)
  const selectedIssueItem = useUiStore(state => state.selectedIssueItem)
  const isAddNetworkFromCons = useUiStore(state => state.isAddNetworkFromCons)
  const setIsAddNetworkFromCons = useUiStore(state => state.setIsAddNetworkFromCons)
  const selectedEntity = useUiStore(state => state.selectedEntity)
  const setSelectedEntity = useUiStore(state => state.setSelectedEntity)
  const setActSelectFeature = useUiStore(state => state.setActSelectFeature)
  const featureDetailsTab = useUiStore(state => state.featureDetailsTab)
  const setFeatureDetailsTab = useUiStore(state => state.setFeatureDetailsTab)
  const isFeatureDetailsDocked = useUiStore(state => state.isFeatureDetailsDocked)
  const isEditingGeometry = useUiStore(state => state.isEditingGeometry)
  const editingFeature = useUiStore(state => state.editingFeature)
  const isDrawingGeometry = useUiStore(state => state.isDrawingGeometry)
  const drawingFeature = useUiStore(state => state.drawingFeature)
  const drawing_id = useUiStore(state => state.drawing_id)

  const [target_id, setTarget_id] = useState(0)

  const handleCloseEntitiesOverlayDialog=()=>{
    setEntitiesOverlayOpen(false)
    setEntitiesOpen(true)
    setEntitiesOverlayKey('')
  }

  const [activeList, setActiveList] = useState('cp.myAccount.acdtl') //changes tabs in CPConsumerMain/CPProfile
  const handleSetActiveList = (key) => {
    setActiveList(key)
  }

  let showDockedProperties = (!!selectedEntity || isEditingGeometry || isDrawingGeometry) && isFeatureDetailsDocked
  let showProperties = contentKey === 'entities' && entitiesOpen && showDockedProperties
  let showEntities = contentKey === 'entities'  && entitiesOpen && !showDockedProperties

  const [drawerMainOpen, setDrawerMainOpen] = useState(false)
  const [tenantMenuAnchor, setTenantMenuAnchor] = useState(null)
  const [avatarMenuAnchor, setAvatarMenuAnchor] = useState(null)
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('all is well')
  const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('I')
  /**When displaying multiple consecutive Snackbars from a parent rendering a single <Snackbar/>,
   * add the key prop to ensure independent treatment of each message.
   * otherwise, the message may update-in-place and features such as autoHideDuration may be canceled. */
  const [alertKey, setAlertKey] = useState(0);

  /* init profile */
  let tProfile: undefined | any = undefined
  let tTenants: undefined | any = undefined
  // let tMeasurementTypes: undefined | any = undefined

  useEffect(() => {
    if (licenses && tenants && profile && profile.selectedTenant && !profile.deducedRights) {
      let theProfile = {...profile}
      /** check if the logged user is a newly created consumer
       * and set the selected tenant based on the tenant url,
       * (this will run only upon a successfull sign up of a new consumer)
       */
      if (theProfile.tenants.length === 1 && theProfile.tenants[0] === 'nm-tp') {
          let url = window.location.href
          let allTenants = tenants
          let tenant: any = allTenants.filter(t => t.urlPart).find(t => t?.urlPart?.split(',').some(p => url.indexOf(p) > -1))
          const selectedTenant = tenant ? tenant.code : 'dmwrm'
          theProfile.selectedTenant = selectedTenant
      }
      prepareLicensedRightCodes(licenses, tenants, theProfile)
    }
  }, [licenses, tenants, profile])

  const prepareLicensedRightCodes = (_licenses, _tenants, _profile) => {
    if (!(_licenses && _tenants && _profile && _profile.selectedTenant && !_profile.deducedRights)) return []
    const selectedTenant: any = _tenants.find(t => t.code === _profile.selectedTenant)
    let tLicensedRightCodes: any[] = []
    _licenses
    .filter(l => selectedTenant?.licenses?.includes(l.code))
    .forEach(l => {
      l.rights.forEach(r => {
        if (!tLicensedRightCodes.includes(r)) {
          tLicensedRightCodes.push(r)
        }
      })
    })
    setLicensedRightCodes(tLicensedRightCodes)
    return tLicensedRightCodes
  }

  const loadMeasurementTypes = (refresh?) => {
    if (measurementTypes && !refresh) {
      return Promise.resolve({ data: measurementTypes })
    } else {
      return services.measurementTypesService.getMeasurementTypes().then(
        res => {
          console.log(`loadMeasurementTypes cnt ${res.data.length}`)
          let tMeasurementTypes = [...res.data]
          setMeasurementTypes(tMeasurementTypes)
          return { data: tMeasurementTypes }
        },
        err => {
          console.log(`ERROR loadMeasurementTypes ${JSON.stringify(err, null, 2)}`)
          return { data: [] }
        }
      )
    }
  }
  const loadLoggedInUser = (auth0User: any) => {
    if (profile) {
      tProfile = profile
      return Promise.resolve({ data: profile })
    } else {
      return services.niamService.getLoggedInUser(auth0User)
      .then(
        res => {
          console.log(`niamService.getLoggedInUser res.data ${res.data.auth0User.name}`)
          tProfile = res.data
          setProfile(res.data)
          return res
        }
        , err => {
          console.log(`ERROR niamService.loadLoggedInUser err ${JSON.stringify(err, null, 2)}`)
          return { data: [] }
        }
      )
    }
  }
  const deduceUserRights = (user: any, _roles: any[] | Role[], _rights: any[], _tenants: any[], selectedTenant: string, _licensedRightCodes: string[]) => {
    let processedRoles: any[] = []
    let deducedRights: any[] = []
    let isSuper = user.roles.findIndex(r => r.role === 'super') > -1 || user.accessRights.findIndex(a => a.right === 'genSup') > -1
    let selectedProduct = _tenants.find(t => t.code === selectedTenant).product
    user.deducedRights = deducedRights
    user.accessRights
    .filter(a => a.tenant === selectedTenant || (!a.tenant && isSuper ) )
    .filter(a => {
      let right = _rights.find(r => r.code === a.right)
      if (!right) return false//in case (that by coding mistake) a deleted right has not been un-assigned from this user
      return !right.productRight || (selectedProduct === right.product && _licensedRightCodes.includes(right.code))
    })
    .forEach(a => {
      let dr = {sources: ['self'], right: a.right, tenant: a.tenant}
      deducedRights.push(dr)
    })
    user.roles
    .filter(r => r.tenant === selectedTenant || (!r.tenant && isSuper ) )
    .filter(r => !r.productRole || selectedProduct === r.product )
    .forEach(r => {
      if (!processedRoles.includes(r.role)) {
        let childRole = _roles.find(c => c.code === r.role && (!c.customRole || c.tenant === selectedTenant))
        if (!!childRole) {//in case (that by coding mistake) a deleted role has not been un-assigned from this role
          deduceRoleRights(childRole, _roles, _rights, _tenants, _licensedRightCodes, deducedRights, processedRoles, selectedTenant)
        }
      }
    })
    return user
  }
  const deduceRoleRights = (role: any, _roles: any[] | Role[], _rights: any[], _tenants: any[], _licensedRightCodes: string[], deducedRights?, processedRoles?, selectedTenant?: string) => {
    let isRoot = !deducedRights
    if (isRoot) {
      processedRoles = []
      deducedRights = []
      selectedTenant = profile?.selectedTenant
      role.deducedRights = deducedRights
    }
    let selectedProduct = _tenants.find(t => t.code === selectedTenant).product
    role.rights
      .filter(rc => _rights.findIndex(r => r.code === rc) > -1)//in case (that by coding mistake) a deleted right has not been un-assigned from this role
      .map(rc => _rights.find(r => r.code === rc))
      .filter(r => !r.productRight || (selectedProduct === r.product && _licensedRightCodes.includes(r.code)))
      .forEach(r => {
        let dr = deducedRights.find(d => d.right === r.code)
        if (dr) {
          dr.sources.push(role.code)
        } else {
          dr = {sources: [role.code], right: r.code, tenant: role.tenant}
          deducedRights.push(dr)
        }
      })
    processedRoles.push(role.code)
    role.roles
    .filter(rc => _roles.findIndex(r => r.code === rc) > -1)//in case (that by coding mistake) a deleted role has not been un-assigned from this role
    .map(rc => _roles.find(r => r.code === rc))
    .filter(r => !r.productRole || selectedProduct === r.product )
    .forEach(r => {
      if (!processedRoles.includes(r.code)) {
        let childRole = r
        deduceRoleRights(childRole, _roles, _rights, _tenants, _licensedRightCodes, deducedRights, processedRoles, selectedTenant)
      }
    })
  }
  const checkIfUserIsDeleted = user => {
    console.log(`checkIfUserIsDeleted`)
    let isDeleted = false
    if (!!user.status) {
      isDeleted = user.tenants.findIndex(t => user.status.findIndex(s => s.tenant === t && s.isDeleted) === -1) === -1
    }
    return isDeleted
  }
  const getFirstEnabledTenant = user => {
    let tenant
    if (!user.status) {
      tenant = user.tenants[0]
    } else {
      tenant = user.tenants.find(t => user.status.findIndex(s => s.tenant === t && (s.isDisabled || s.isDeleted)) === -1)
    }
    return tenant
  }
  const dologin = () => {
    console.log(`dologin`)
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({})
    }
    fn()
  }
  const dologout = () => {
    console.log(`dologout`)
    let returnUrl = window.location.href
    console.log(`OLD returnTo ${process.env.REACT_APP_WRM_CLI_URL}`)
    console.log(`NEW returnTo ${returnUrl}`)
    logout({ returnTo: returnUrl })
    // logout({ returnTo: process.env.REACT_APP_WRM_CLI_URL })
  }

  /**
  * Fires when Auth0 has finished loading and
  * either prompts to the login page, if no user was loaded,
  * or proceeds with loading the user profile
  * as well as any unrelated-to-profile information (e.g. tenants, roles, etc.)
  */
  useEffect(() => {
    if (!loading && !user) {
      dologin()
    } else if (!loading && user && !tenants) {
      console.log(`initProfile job`)

      // setApiClient(services.apiClient)
      setShowAlert(showAlert)
      // setActSelectFeature(actSelectFeature)

      /* all these should be loaded before proceeding to load the profile */
      Promise.all([
        services.niamService.getTenants(),
        services.tenantSettingsService.getEntities(),
        services.niamService.getRoles(),
        services.niamService.getRights(),
        services.niamService.getLicenses(),
        loadMeasurementTypes(),
      ])
      .then(
        res => {
          loadLoggedInUser(user)
        }
      )

    }
  }, [loading, user])

  /**
  * Fires at first when the initial profile is loaded via loadLoggedInUser
  * and then, with subsequent firings,
  * progressively builds the complete profile
  * by setting the profile's selectedTenant and the profile's deducedRights,
  * It also loads other, profile-related (e.g. features, personnels, alarms etc.)
  * and tenant-related (e.g. hydrometers, issueReports, notifications, etc.)
  * data collections.
  */
  useEffect(() => {
    if (!profile) return
    if (!profile.selectedTenant) {
      let isDeleted = checkIfUserIsDeleted(profile)
      if (isDeleted) {
        let msg = `${t('msg.alert.userIsDeleted.user')} "${profile.auth0User.name}" ${t('msg.alert.userIsDeleted.isDeleted')}!\n${t('msg.alert.transferToLogin.in')} 5" ${t('msg.alert.transferToLogin.redirect')}.`
        showAlert(msg, 'E')
        setTimeout(() => {
          dologout()
        }, 5000)
        console.log(`ERROR initProfile: user deleted`)
        return
      }
      let selTenant = getFirstEnabledTenant(profile)
      if (!selTenant) {
        let msg = `${t('msg.alert.userIsDisabled.user')} "${profile.auth0User.name}" ${t('msg.alert.userIsDisabled.isDisabled')}!\n${t('msg.alert.transferToLogin.in')} 5" ${t('msg.alert.transferToLogin.redirect')}.`
        showAlert(msg, 'E')
        setTimeout(() => {
          dologout()
        }, 5000)
        console.log(`ERROR initProfile: user disabled`)
        return
      } else {
        let tProfile = JSON.parse(JSON.stringify(profile))
        tProfile.selectedTenant = selTenant
        setProfile(tProfile)
      }
    } else if (!profile.deducedRights) {
      if (licenses && tenants && roles && rights && licensedRightCodes) {
        // let tLicensedRightCodes: any = prepareLicensedRightCodes(licenses, tenants, profile)
        let tProfile = JSON.parse(JSON.stringify(profile))
        deduceUserRights(tProfile, roles, rights, tenants, tProfile.selectedTenant, licensedRightCodes)
        setProfile(tProfile)
        setActSelectFeature(actSelectFeature)
      }
    } else {//profile is ready

      let profilePromise: any = Promise.resolve('start')
      /* select 'first page' according to rights */
      let ck = ''
      if (profile.tenants.length === 1 && profile.tenants[0] === 'nm-tp') {
        ck = 'cp.myAccount'
        profilePromise = registerConsumer()
      } else if (accessGranted('gnrccn')) {
        ck = 'cp.myAccount'
      } else if (accessGranted('Vwrrgn') && screenMaxWidth600) {
        /**The logged user have access to `view main map` and `View Irrigation Inverters` and is on mobile */
        ck = 'inverterMobile'
        profilePromise = verticalNavigate(ck + '/automationCenterMobile', false)
      } else if (accessGranted('VwMnMp')) {
        ck = 'entities'
        setEntitiesOpen(true)
      } else {
        if (accessGranted('vwcnsm')) {
          ck = 'consumerPortal'
        } else if (accessGranted('VwMntn')) {
          ck = 'equipmentmaintenance'
          profilePromise = verticalNavigate(ck, false)
        } else if (accessGranted('Vwnvnt')) {
          ck = 'inventorycategories'
          profilePromise = verticalNavigate(ck, false)
        } else if (accessGranted('Vwctvt')) {
          ck = 'activities'
          profilePromise = verticalNavigate(ck, false)
        } else if (accessGranted('vwwtrb')) {
          ck = 'waterbalance'
          profilePromise = verticalNavigate(ck, false)
        }  else if (accessGranted('vwnrgy')) {
          ck = 'energyoptimization'
          profilePromise = verticalNavigate(ck, false)
        }  else if (accessGranted('VwHydr')) {
          ck = 'hydraulicoptimization'
          profilePromise = verticalNavigate(ck, false)
        } else if (accessGranted('vwqlty')) {
          ck = 'qualityControl'
          profilePromise = verticalNavigate(ck, false)
        } else if (accessGranted('vwmsrm')) {
          ck = 'scada'
          profilePromise = verticalNavigate(ck, false)
        }
      }

      /* load additional for consumers */
      if (accessGranted('gnrccn') && !profile.consumer) {
        profilePromise
        .then(
          res => {
            return loadMyConsumer(profile)
          }
        )
        .then(
          res => {
            let promises = [
              services.niamService.getUsers(profile.selectedTenant),
              loadIssueReports(profile.selectedTenant, profile.user_id),
              loadMyHydrometers(profile),
              loadFeatures('wszone'),
              notificationsManager.loadConsumerNotifications(profile?.consumer?._id as string),
              services.userSettingsService.getUserSettings(profile.user_id),
            ]
            return Promise.all(promises)
          }
        )
        .then(
          res => {
            /** While loadMyHydrometers sets the zustatnd state (setProfile) before returning the results
             * At this stage the loadMyHydrometers promise is resolved but the state is not yet updated by zustand
             * this will happen in the next re-render,
             * one way of solving this could be by adding a useEffect with [profile.consumer.hydrometers] as dependancy
             * that will fire loadConsumption when the hydrometers are loaded,
             * alternatively we can consruct the profile from the previous state and the loadMyHydrometers Response
             * the loaded hydrometers are stored into loadMyHydrometersRes and appended into a clone of the profile
             *  */
            const loadMyHydrometersRes = res[2]
            let currentProfile = _.cloneDeep(profile)
            if (!currentProfile?.consumer?.hydrometers || currentProfile.consumer.hydrometers.length === 0) {
              //@ts-ignore
              currentProfile.consumer.hydrometers = loadMyHydrometersRes.hydrometers
            }
            return MeasurementsManager().loadConsumptions(currentProfile, measurementTypes)
          },
          err => {
            console.log(`ERROR initProfile err ${err}`)
            return 'profile error'
          }
        )
      /* load additional for operators according to rights */
      } else if (!accessGranted('gnrccn')) {
        let promises:Promise<any>[] = []
        let irriPromise: any = Promise.resolve('start')
        irriPromise.then(res=>{
          let result = 'noIrrigation'
          /**If the user has access to irrigation then the agricultural periods must be fetched 
           * before fetching the landParcels.
           * Then the FeatureService will add the appropriate agricultural period filter. */
          if (accessGranted('Vwrrgt3')) {
            result = services.agriculturalPeriodService.getEntities() 
          }
          return result
        }).then(res => {
          promises = Object.keys(FTC).map(ft => loadFeatures(ft as featureType))

          promises.push(services.niamService.getUsers(profile.selectedTenant))
          promises.push(services.userSettingsService.getUserSettings(profile.user_id))

          /** Load ALARMS if the user has access to view alarms
           * or if the user has access to editing(deleting) network features
           * the latter is used in order to block the user from deleting a sampling point that is used in an alarm
           */
          if (accessGranted('AVwlrm') || accessGranted('vwntwr') || accessGranted('dtNtwr3') ) {
            promises.push(services.alarmService.getEntities())
            promises.push(services.personnelService.getEntities())
          }
          if (accessGranted('vwcnsm')) {
            promises.push(loadIssueReports(profile.selectedTenant))
          }

          profilePromise
          .then(
            res => {
              return Promise.all(promises)
            }
          )
        })
      }

      profilePromise
      .then(
        res => {
          console.log(`initProfile done`)
          setContentKey(ck)
        },
        err => {
          console.log(`ERROR initProfile err ${err}`)
          setContentKey(ck)
        }
      )
      // We have to set accessGranted after profile building is complete
      // TODO check wat happens with new (signed-up consumers)
      setAccessGranted(accessGranted) 
    }
  }, [profile, licenses, licensedRightCodes, tenants, roles, rights])

  useEffect(() => {
    if ((accessGranted('AVwlrm') || accessGranted('vwntwr') || accessGranted('dtNtwr3'))
        && alarms && personnels)
    {
          const currentUsersPersonnel = personnels.find(p => p.users_user_id === profile?.user_id)
          if (currentUsersPersonnel) {
            notificationsManager.loadPersonnelNotifications(currentUsersPersonnel._id, {cachePromise: true})
          }
    }
  }, [personnels, alarms])

  useEffect(() => {
    if ( (userSettings && userSettings.length > 0 ) || (tenantSettings && tenantSettings.length > 0) ) {
      setCollectionItems('appSettings',getAppSettings())
    }
  }, [userSettings, tenantSettings])


  /* Features */
  const actSelectFeature = (feature, leafstateFeatureDetailsTab?) => {
    // console.log(`actSelectFeature feature ${JSON.stringify(feature,null,2)}`)
    // console.log(`actSelectFeature feature ${!!feature ? feature.properties.label : feature}`)
    if (!!feature) {
      // console.log(`leafstateFeatureDetailsTab ${leafstateFeatureDetailsTab} featureDetailsTab ${featureDetailsTab} !feature.measurements ${!feature.measurements}`)
      if (!!feature.series) {
        MeasurementsManager().setMeasurementSeries(feature.series)
      } else {
        //don't db-fetch measurements if just browsing through other tabs
        //don't fetch measurements if the selected Feature is a landParcel
        if (
          (featureDetailsTab === 'measurements' ||
          leafstateFeatureDetailsTab === 'measurements' ||
          featureDetailsTab === 'meters' ||
          leafstateFeatureDetailsTab === 'meters' )
          && feature.properties.featureType !== "landparcel"
        ) {
          console.log(`actSelectFeature feature ${!!feature ? feature.properties.label : feature} no series calling getFeatureMeasurements`)
          MeasurementsManager().getFeatureMeasurements(feature)
        }
      }
    }
    setSelectedEntity(feature)
  }
  const loadFeatures = (featureType: featureType, doReload?: boolean) => {
    return services.featureService.getFeatures(featureType, null ,doReload)
  }

  const handleAddFeatureLayer = collection => {
    console.log(`handleAddFeatureLayer ${collection}`)
    let vl = visibleLayers.map(l => l)
    vl.push(collection)
    setVisibleLayers(vl)
  }
  const handleRemoveFeatureLayer = collection => {
    console.log(`handleRemoveFeatureLayer ${collection}`)
    let vl = visibleLayers.filter(l => l !== collection)
    setVisibleLayers(vl)
  }

  /* Alarms */
  const handleCloseAlarmPopUp = (notification:Notification) => {
    setSelectedFeatureWithActiveAlarms(null)
    if (notification.messages) {
      notificationsManager.doUpdateNotificationWebReadDate(notification)
    }
  }

  const handleOpenAlarmPopUp = (notification, feature) => {
    /** If triggered from the Map - notification will be null but we'll get the clicked feature
     *  If triggered from NotificationList - feature will be null but we'll get the clicked notification
     * Probably should refactor this based on notificationEventType
     */
    if (!feature && notification.measurementSerie) {
      feature = featureCollection[FTC[notification.ms.featureType].collection].filter(
        f => f.properties.code === notification.ms.featureCode
      )[0]
    }
    //Generic Irrigation notification including inverters/Pumps (get the feature from notification.featureType/featureCode)
    if (!feature && notification.featureCode && notification.featureType) {
      feature = featureCollection[FTC[notification.featureType]?.collection]?.find(
        f => f.properties?.code === notification.featureCode
      )
    } else if (!feature && notification.switchCode) {
      //Legacy Irrigation notifications (not inverters, waterOutlets etc)
      feature = switches?.find(sw => sw.properties.code === notification.switchCode)
    }
    if (!notification) {
      //get the last(latest) undread urgent alarmNotification that corresponds to that feature
      notification = personnelNotifications
        ? personnelNotifications
            .slice(0)
            .reverse()
            .filter(n => (
                ['alarmFired', 'invrtAlarmFired', 'irriEventPersonnel'].includes(n.notificationType)
                && n.severity === 1
                && !n?.messages![0]?.webReadDate
                && (n.ms?.featureCode === feature.properties.code || n.switchCode === feature.properties.code)
            ))[0]
        : null
    }
    if (!notification) {
      let errorMessage = t('alarms.notification.error.null')
      showAlert(errorMessage, 'E')
      console.log(`${errorMessage}`)
      return
    }
    setSelectedFeatureWithActiveAlarms({ feature, notification })
  }

  /* Profile */
  const actProfile = () => {
    console.log(`actProfile`)
    setEntitiesOpen(false)
    return services.niamService.getTenants()
      .then(res => {
        return Promise.all([
          services.niamService.getRoles(),
          services.niamService.getRights(),
          services.niamService.getLicenses(),
          loadLoggedInUser(profile),
        ])
      })
      .then(
        res => {
          setContentKey('profile')
        },
        err => {
          console.log(`ERROR actProfile err.data ${JSON.stringify(err.data, null, 2)}`)
          console.log(`ERROR actProfile err ${JSON.stringify(err, null, 2)}`)
          setContentKey('profile')
        }
      )
  }

  /* Common Collectios Functionality */
  const collectionsMeta = {
    electricityBills:{
      // state: electricityBills,
      // stateSetter: setElectricityBills,
      service: services.electricityBillsService,
      fetcher: 'getElectricityBills',
      saver: 'saveElectricityBills',
      deleter: 'deleteElectricityBillByID',
    },
    inventoryitems: {
      state: inventoryItems,
      stateSetter: setInventoryItems,
      service: services.inventoryItemService,
      fetcher: 'getInventoryItems',
      saver: 'saveInventoryItem',
      registerSaver: 'saveRegisterItem'
    },
    invoices:{
      // state: invoice,
      // stateSetter: setInvoice,
      service: services.invoiceService,
      fetcher: 'getInvoices',
      saver: 'saveInvoices',
      deleter: 'deleteInvoiceByID',
    },
    maintenance: {
      service: services.maintenanceService
    },
  }
  const collectionManager = CollectionManager(collectionsMeta, profile, showAlert)

  /* InventoryItems */
  const actInventoryItems = (inventoryItemType?) => {
    return collectionManager.actCollection('inventoryitems', inventoryItemType)
  }
  const actSaveInventoryItem = (inventory, doNotSelect?) => {
    return collectionManager.actSaveCollectionItem('inventoryitems', inventory, doNotSelect)
  }

  /* Indicators */
  const actSaveIndicator = (indicator) => {
    return (
      services.indicatorService.saveEntity(indicator)
        //reload measurement types
        .then(res => {
          let refresh = true
          loadMeasurementTypes(refresh)
          return res
        })
    )
  }
  const actDeleteIndicator = (indicator, note?) => {
    return (
      services.indicatorService.deleteEntity(indicator, note)
        //reload measurement types
        .then(res => {
          let refresh = true
          loadMeasurementTypes(refresh)
        })
    )
  }

  /* SwitchSlots */
  const actDeleteSwitchSlot = (switchSlot, note?) => {
    /** Before Deleting a switchSlot entry check if it is tied to an alarm */
    const allarmsTiedToSwitchSlot = alarms?.filter(al=>
      al.features.some(f=>f.featureCode===switchSlot.switchCode))

    const alarmNames = allarmsTiedToSwitchSlot?.map(el=>el.name).join(", ")
    if (!!alarmNames) {
      showAlert(t("net.features.confirm.delete.feature.tied.to.alarm") + alarmNames, 'W')
      return Promise.resolve({ switchSlot })
    }
    return services.switchSlotService.deleteEntity(switchSlot, note)
  }

  /** ImortFiles fileImportSeries ### */
  const actFileImportSeries = (filter) => {
    return services.fileImportSeriesService.getFileImportSeries(filter)
  }
  const actGetCollectionByUploadedFileID =(fileImportSerie,filter)=>{
    return services.fileImportSeriesService.getCollectionByUploadedFileID(fileImportSerie)
  }
  const actDeleteFileImportSeriesByUploadedFileID = (fileImportSerie) => {
    return services.fileImportSeriesService.deleteFileImportSeriesByUploadedFileID(fileImportSerie)
      .then(res=>{
        if (fileImportSerie.fileType === 'networkElements') {
          /** Reload the collection so the map gets/app gets updated **/
          return loadFeatures(fileImportSerie.fileSubType, true)
        }
        return res
      })
  }

  /** ImportFiles Invoice */
  const actInvoice = (filter?) => {
    return collectionManager.actCollection('invoices',filter)
  }
  const actSaveInvoices = (invoices,fileMeta,doNotCreateFileImportSerie?) => {
    const key ='invoices'
    let meta = collectionsMeta[key]
    if (!meta) return
    return meta.service[meta.saver](invoices,fileMeta,doNotCreateFileImportSerie).then(
      res => {
        // console.log(`actSaveCollectionItem ${key} res.data ${JSON.stringify(res.data, null, 2)}`)
        console.log('actSaveInvoices')
        return res.data
      },
      err => {
        // console.error(`error ${JSON.stringify(err, null, 2)}`)
        console.log(err)
        console.log(key)
        let errorMessage = buildErrorMessage(err, null, key)
        showAlert(t(errorMessage), 'E')
        console.error(`errorMessage ${errorMessage}`)
        return Promise.reject(err)
      }
    )
  }
  const actDeleteInvoiceByID = (_id) => {
    return collectionManager.actDeleteCollectionItem('invoices', _id)
  }
  const actDeleteInvoicesByUploadedFileID = (uploadedFileID) => {
    return services.invoiceService.deleteInvoicesByUploadedFileID(uploadedFileID).then(res=>console.log(res))
  }

  /** ImportFiles electricityBills */
  const actElectricityBills = (filter?,isIrrigation?:boolean) => {
      return services.electricityBillsService.getElectricityBills(filter,isIrrigation).then(
      res => {
        console.log(res)
        return res
      },
      err => {
        console.log(`ERROR actElectricityBills ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }
  const actSaveElectricityBills = (bills,fileMeta) => {
    /** ### For the bills the state is of the shape {power:[...],basic:[...]}
     * modifying actSaveCollectionMultipleItems seemed kinda hacky so moved the logic directly here*/
      const key = 'electricityBills'
      let meta = collectionsMeta[key]
      if (!meta) return

      return meta.service[meta.saver](bills,fileMeta).then(
        res => {
          let items = res.data
          return items
        },
        err => {
          console.log(err)
          let errorMessage = buildErrorMessage(err, null, key)
          showAlert(t(errorMessage), 'E')
          console.error(`errorMessage ${errorMessage}`)
          return Promise.reject(err)
        }
      )
  }
  const actDeleteElectricityBillByID = (_id) => {
    return collectionManager.actDeleteCollectionItem('electricityBills', _id)
  }
  const actDeleteElectricityBillsByUploadedFileID = (uploadedFileID,fileMeta) => {
    return services.electricityBillsService.deleteElectricityBillsByUploadedFileID(uploadedFileID,fileMeta).then(res=>console.log(res))
  }

  /* Consumers */
  const loadMyConsumer = userProfile => {
    if (!!userProfile.consumer) {
      return Promise.resolve({ data: userProfile.consumer })
    } else {
      return services.consumerService
        .getConsumerByUser_id(userProfile.user_id)
        .then(res => {
          let consumer = res
          if (!!consumer) {
            userProfile.consumer = consumer
            setProfile(_.cloneDeep(userProfile))
            return userProfile.consumer
          } else {
            console.log(`ERROR loadMyConsumer: No consumer found for user ${userProfile.user_id}`)
            userProfile.consumer = {}
            setProfile(_.cloneDeep(userProfile))
            return userProfile.consumer
          }
        })
    }
  }
  const loadMyHydrometers = _userProfile => {
    /** Clone of the _userProfile, so that the state is not directly mutated */
    let userProfile = _.cloneDeep(_userProfile)
    let promise
    if (!!userProfile.consumer && !!userProfile.consumer.hydrometers) {
      return Promise.resolve({ data: userProfile.consumer.hydrometers })
    } else if (!userProfile.consumer) {
      promise = loadMyConsumer(userProfile)
    } else {
      promise = Promise.resolve(userProfile.consumer)
    }
    promise.then(res => {
      let consumer = res
      if (!consumer._id) {
        userProfile.consumer.hydrometers = []
        setProfile(_.cloneDeep(userProfile))
        return { data: userProfile.consumer.hydrometers }
      } else {
        let filter = {
          'properties.consumer_id': consumer._id,
        }
        return services.featureService.getFeatures('hydrometer', filter).then(
          res => {
            if (!res.data.error) {
              userProfile.consumer.hydrometers = res.data
            } else {
              console.log(`ERROR loadMyHydrometers: ${JSON.stringify(res.data, null, 2)}`)
              userProfile.consumer.hydrometers = []
            }
            setProfile(_.cloneDeep(userProfile))
            return { data: userProfile.consumer.hydrometers }
          },
          err => {
            console.log(`ERROR loadMyHydrometers: ${JSON.stringify(err, null, 2)}`)
            userProfile.consumer.hydrometers = []
            setProfile(_.cloneDeep(userProfile))
            return { data: userProfile.consumer.hydrometers }
          }
        )
      }
    })
    return promise
  }

  const cloneUser = user => {
    let clone = Object.assign({}, user)
    clone.tenants = user.tenants.map(c => c)
    clone.roles = user.roles.map(c => c)
    clone.accessRights = user.accessRights.map(c => c)
    clone.auth0User = Object.assign({}, user.auth0User)
    clone.auth0User.identities = user.auth0User.identities.map(c =>
      Object.assign({}, c)
    )
    return clone
  }
  const registerConsumer = (tenantCode?) => {
    let theProfile = profile ? profile : tProfile
    if (theProfile.tenants.length === 1 && theProfile.tenants[0] === 'nm-tp') {
      if (!tenantCode) {
        let url = window.location.href
        let allTenants = tenants ?? tTenants
        let tenant: any = allTenants.filter(t => t.urlPart).find(t => t.urlPart.split(',').some(p => url.indexOf(p) > -1))
        tenantCode = tenant ? tenant.code : 'dmwrm'
      }

      let user = cloneUser(theProfile)
      user.tenants = [tenantCode]

      let allTenants = tenants ?? tTenants
      let tenant: any = allTenants.find(t => t.code === tenantCode)
      if (tenant?.licenses?.includes(['Irrigation', 'Irrigation-Applications', 'Irrigation-Inverters', 'Irrigation-Scheduling'])) {
        user.roles = [{ role: 'Cltvtr', tenant: tenantCode }]
      } else {
        user.roles = [{ role: 'cnsmrw', tenant: tenantCode }]
      }

      deduceUserRights(user, roles!, rights!, tenants!, tenantCode, licensedRightCodes)
      return services.niamService.updateUser(user)
      .then(
        res => {
          user = res.data
          user.selectedTenant = tenantCode
          console.log(`registerConsumer user ${JSON.stringify(user, null, 2)}`)
          deduceUserRights(user, roles!, rights!, tenants!, user.selectedTenant, licensedRightCodes)

          let consumer = {
            email: user.auth0User.email,
            users_user_id: user.user_id,
            isVerifiedUser: false,
            tenantCode: tenantCode,
          }
          return services.consumerService.saveConsumerWithoutUpdatingUsers(consumer)
      })
      .then(
        res => {
          console.log(`registerConsumer res ${JSON.stringify(res.data,null,2)}`)
          let consumer = res//res.data
          consumer.hydrometers = []
          user.consumer = consumer

          tProfile = user
          setProfile(user)
          setContentKey('cp.myAccount')
          return 'ok'
        },
        err => {
          console.log(err)
          setContentKey('cp.myAccount')
          return 'ok'
        }
      )
    } else {
      setContentKey('cp.myAccount')
      return 'ok'
    }
  }
  /**
  * Updates the user's username, or password, or email.
  * NOTE: username, password or email can be updated only one at a time,
  * accordingly, data may be one of:
  * {username: username: string},
  * {password: password: string, oldPassword: oldPassword: string}, or
  * {email: email: string}
  */
  const actUpdateUserUsernameOrPasswordOrEmail = (data: any, callback?:()=>void) => {
    let tmpProfile = {...profile}
    tmpProfile.auth0User = {...profile?.auth0User!}
    if (data.username) {
      tmpProfile.auth0User.username = data.username
    } else if (data.password) {
      //@ts-ignore
      tmpProfile.auth0User.password = data.password
      //@ts-ignore
      tmpProfile.auth0User.oldPassword = data.oldPassword
    } else if (data.email) {
      tmpProfile.auth0User.email = data.email

      /* check if a personnel is linked with this user
      * and if yes then update that personnel's email */
      let linkedPersonnel = personnels?.find(p => p.users_user_id === tmpProfile.user_id)
      if (linkedPersonnel) {
        linkedPersonnel.email = data.email
        services.personnelService.saveEntity(linkedPersonnel)
      }

    }
    return services.niamService.updateUser(tmpProfile)
    .then(
      res => {
        let updProfile = tmpProfile
        updProfile.auth0User = res.data.auth0User
        setProfile(_.cloneDeep(updProfile) as UserProfile)
        /**At inverter mobile we use the callback to trigger the navigation (UiStore.setContentKey2) */
        if (!!callback) {
          callback()
          return updProfile
        }
        //close the settings dialog if the logged user is not a consumer (consumer has no dialog)
        if (!accessGranted("gnrccn")) {
          // actUserSettings()
          verticalNavigate('USER_SETTINGS_DIALOG')
        }
        return updProfile
      }
    )
  }

  /* IssueReports */
  const loadIssueReports = (tenant, user_id?) => {
    if (issueReports) {
      return Promise.resolve({ data: issueReports })
    } else {
      return services.issueReportService.getIssueReports(tenant, user_id).then(
        res => {
          setIssueReports(res.data)
          return res
        }
      )
    }
  }

  /* Profile */
  const actOpenAvatarMenu = event => {
    // console.log(`actOpenAvatarMenu ${event.currentTarget}`)
    setAvatarMenuAnchor(event.currentTarget)
  }
  const actAvatarAction = action => {
    // console.log(`actAvatarAction`)
    if (action === 'login') {
      dologin()
    } else if (action === 'logout') {
      dologout()
    } else if (action === 'viewprofile') {
      actProfile()
    }
    setTenantMenuAnchor(null) //first close tenants menu (or else it will have an invalid anchor)
    setAvatarMenuAnchor(null)
  }
  const actOpenTenantMenu = event => {
    // console.log(`actOpenTenantMenu ${event.currentTarget}`)
    if (!!profile && profile.tenants?.filter(t => !profile?.status || profile.status?.findIndex(s => s.tenant === t && s.isDisabled) === -1).length < 2) {
      return
    }
    setTenantMenuAnchor(event.currentTarget)
  }
  const actSwitchTenant = code => {
    // console.log(`actSwitchTenant`)
    if (code) {
      /* @todo - switch-tenant functionality
      * we must also empty all tenant-related state
      * and empty the backend cache from the auth0User info */
      /*
      let tProfile = JSON.parse(JSON.stringify(profile))
      tProfile.selectedTenant = code
      tProfile.deducedRights = null
      setProfile(tProfile)
      */
    }
    // console.log(`actSwitchTenant ${code}`)
    setTenantMenuAnchor(null)
  }

  function showAlert(message, severity?) {
    setAlertOpen(true)
    setAlertMessage(message)
    setAlertKey(new Date().getTime())
    if (severity) setAlertSeverity(severity)
    console.log(`showAlert message ${message} severity ${severity}`)
  }

  const actOpenOptionsMenu = event => {
    //Ancors to the Gear Icon in vertical menu for the <Pop>
    setSettingsMenuAnchor(event.currentTarget)
  }
  const actOptionsAction = action => {
    console.log('actOptionsAction + action')
    console.log(action)
    if (action) {
      verticalNavigate(action)
    }
    setSettingsMenuAnchor(null)
  }
  const getAppSettings = () => {
    /** For now all it does is to return the userSettings but...
     * In case in the tenant gets a tenantSettings option
     * or if each tenant gets a default tenantSettings option
     * then this function should be refactored to reflect this
     * since some tenant options should be overriden by user options
     * but others could be applied universally regardless of user preferences */
    let settings = {
      coordinatesSystem: 'wgs84',
    }
    if (userSettings && userSettings.length > 0 ) {
      settings = {...settings, ...userSettings[0]}
    }
    if (tenantSettings && tenantSettings.length > 0) {
      settings = {...settings, ...tenantSettings[0]}
    }
    return settings
  }

  const accessGranted = right => {
    // console.log(`accessGranted`)
    return accessGrantedForEntity(right, profile)
  }
  const accessGrantedForEntity = (right, entity) => {
    // console.log(`accessGrantedForEntity`)
    if (!entity || !profile?.deducedRights) return false
    if (!entity.selectedTenant) return false
    if (!entity.deducedRights) {
      // if (entity.user_id) deduceUserRights(entity, roles ? roles : tRoles, (profile ? profile : tProfile).selectedTenant, licensedRightCodes)
      // else deduceRoleRights(entity, roles ? roles : tRoles, licensedRightCodes)
      if (entity.user_id) deduceUserRights(entity, roles!, rights!, tenants!, profile?.selectedTenant, licensedRightCodes)
      else deduceRoleRights(entity, roles!, rights!, tenants!, licensedRightCodes)
    }
    let granted = entity.deducedRights.findIndex(d => d.right === right) > -1
    return granted
  }

  return (
    <div className={classes.rootMain}>
      {!loading && !!profile &&
      <WebSocketsContainer loadFeatures={loadFeatures} />}
      <Toaster
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        message={alertMessage}
        severity={alertSeverity}
        alertKey={alertKey}
      />
      {!loading && user && profile?.deducedRights && (
        <Pop id='tenPop' anchorEl={tenantMenuAnchor}>
          <ClickAwayListener onClickAway={() => actSwitchTenant(null)}>
            <MenuList>
              {profile.tenants
                .filter(t => !profile.status || profile.status.findIndex(s => s.tenant === t && s.isDisabled) === -1)
                .map(pt => (
                  <MenuItem key={pt} onClick={() => actSwitchTenant(pt)}>
                    {tenants?.find(t => t.code === pt)?.label}
                  </MenuItem>
                ))}
            </MenuList>
          </ClickAwayListener>
        </Pop>
      )}
      {!loading && (
        <Pop id='profPop' anchorEl={avatarMenuAnchor}>
          <ClickAwayListener onClickAway={() => actAvatarAction(null)}>
            <MenuList>
              {(accessGranted('manUsr') || accessGranted('mngRoles')) && (
                  <MenuItem
                    key='mvmi_manageUsers'
                    id='mvmi_manageUsers'
                    onClick={() => actAvatarAction(null)}
                    onMouseOver={() => actSwitchTenant(null)}>
                    <Link
                      // href="http://localhost:3002"
                      href={process.env.REACT_APP_NIMBL_CLI_URL}
                      target='_blank'
                      rel='noopener'
                      underline='none'
                      color='inherit'
                      className={classes.menuLink}>
                      {t('manageUsers')}
                    </Link>
                  </MenuItem>
                )}
              {profile?.deducedRights && (
                <MenuItem
                  key='mvmi_tenants'
                  id='mvmi_tenants'
                  onMouseOver={actOpenTenantMenu}
                  disabled={profile.tenants.filter(t => !profile.status || profile.status.findIndex(s => s.tenant === t && s.isDisabled) === -1).length < 2}
                >
                  {tenants?.find(t => t.code === profile.selectedTenant)?.label}
                </MenuItem>
              )}
              {accessGranted('genSup') && (
                <MenuItem
                  key='mvmi_viewProfile'
                  id='mvmi_viewProfile'
                  onClick={() => actAvatarAction('viewprofile')}
                  onMouseOver={() => actSwitchTenant(null)}>
                  {t('viewProfile')}
                </MenuItem>
              )}
              {profile && (
                <MenuItem
                  key='mvmi_username'
                  id='mvmi_username'
                  disabled={true}>
                  {`${profile.auth0User.name} (${profile.auth0User.username})`}
                </MenuItem>
              )}
              {user && (
                <MenuItem
                  key='mvmi_signOut'
                  id='mvmi_signOut'
                  onClick={() => actAvatarAction('logout')}
                  onMouseOver={() => actSwitchTenant(null)}>
                  {t('signOut')}
                </MenuItem>
              )}
              {!user && (
                <MenuItem
                  key='mvmi_signIn'
                  id='mvmi_signIn'
                  onClick={() => actAvatarAction('login')}
                  onMouseOver={() => actSwitchTenant(null)}>
                  {t('signIn')}
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </Pop>
      )}
      {/* Settings Menu PopOver */}
      {!loading && (
        <Pop id="settings-menu" anchorEl={settingsMenuAnchor}>
          <ClickAwayListener onClickAway={() => actOptionsAction(null)}>
            <MenuList>
            {(accessGranted('mprtMR') || accessGranted('mprtlc') || accessGranted('mprtnv') || accessGranted('mprtNt')) && (
              <MenuItem
                id='dataImport'
                onClick={()=> actOptionsAction('dataImport')}
              >
                {t('importFiles')}
              </MenuItem>
            )}
            {profile && (
              <MenuItem
                id='userSettings'
                // onMouseOver={actOpenTenantMenu}
                onClick={()=> actOptionsAction('USER_SETTINGS_DIALOG')}
                disabled={!profile}
              >
                {t("mvb.userSettings")}
              </MenuItem>
            )}
            {profile && (
              accessGranted('Vwrrgt6') || accessGranted('dtrrgt5') // Irrigation Controllers
              || accessGranted('VwTnnt') || accessGranted('dtTnnt') // Tenant Settings master-detail
              || accessGranted('Vwgrcl') || accessGranted('dtgrcl') // Agricultural Periods
              || accessGranted('VwNtwr1') // Experimental Features
            ) && (
              <MenuItem
                id='tenantSettings'
                onClick={()=> actOptionsAction('tenantSettings')}
              >
                {t('mvb.tenantSettings')}
              </MenuItem>
            )}
            {profile && accessGranted('Mngrrg') && (
              <MenuItem
                id='hydoorSettings'
                onClick={()=> actOptionsAction('hydoorSettings')}
              >
                {t('mvb.hydoorSettings')}
              </MenuItem>
            )}
            </MenuList>
          </ClickAwayListener>
        </Pop>
      )}
      {/* end settings menu popover */}
      {(!accessGranted('gnrccn') || (accessGranted('gnrccn') && !screenMaxWidth600)) &&
      <Drawer
        id='MainDrawer'
        variant='permanent'
        className={classes.drawer}
        classes={{
          paper: clsx(classes.drawer, classes.secondaryDark),
        }}>
        <MainVertical
          loading={loading}
          user={user}
          contentKey={contentKey}
          entitiesOverlayKey={entitiesOverlayKey}
          verticalNavigate={verticalNavigate}
          actOpenAvatarMenu={actOpenAvatarMenu}
          actOpenOptionsMenu={actOpenOptionsMenu}
          unreadNotifications={accessGranted('gnrccn') ? consumerNotifications : personnelNotifications}
          activeContentKey={contentKey}
        />
      </Drawer>}
      {screenMaxWidth600 && accessGranted('gnrccn') &&
      <MainVertical
          loading={loading}
          user={user}
          contentKey={contentKey}
          verticalNavigate={verticalNavigate}
          actOpenAvatarMenu={actOpenAvatarMenu}
          unreadNotifications={accessGranted('gnrccn') ? consumerNotifications : personnelNotifications}
          isSmallScreen={screenMaxWidth600}
          activeList={activeList}
          setActiveList={handleSetActiveList}
          actAvatarAction={actAvatarAction}
      />
      }
      <Drawer
        id='entitiesOverlay'
        variant='persistent'
        open={entitiesOverlayOpen}
        className={clsx(classes.drawerCommon, {
          [classes.drawerEntityPropertiesOpen]: entitiesOverlayOpen,
          [classes.drawerClose]: !entitiesOverlayOpen,
        })}
        classes={{
          paper: clsx(classes.drawerCommon, {
            [classes.drawerEntityPropertiesOpenPaper]: entitiesOverlayOpen,
          }),
        }}>
          {/* contentKey === 'firedalarms' && alarm */}
        {contentKey === 'entities' && entitiesOverlayKey === 'NOTIFICATIONS' &&
        <NotificationsList
          notifications={personnelNotifications ? personnelNotifications : [] }
          actInventoryItems={actInventoryItems}
          inventoryItems={inventoryItems}
          handleClose={handleCloseEntitiesOverlayDialog}
          handleOpenAlarmPopUp={handleOpenAlarmPopUp}
          />}
        {contentKey === 'entities' && entitiesOverlayKey === 'USER_SETTINGS_DIALOG' &&
        <UserSettings
          showUserSettings={entitiesOverlayKey === 'USER_SETTINGS_DIALOG'}
          handleClose={handleCloseEntitiesOverlayDialog}
          actUpdateUserUsernameOrPasswordOrEmail={actUpdateUserUsernameOrPasswordOrEmail}
        />}
      </Drawer>
      {(accessGranted('vwntwr') || accessGranted('dtNtwr3')) &&
      <Drawer
        id='entities'
        variant='persistent'
        open={showEntities}
        onClose={() => setEntitiesOpen(false)}
        className={clsx(classes.drawerCommon, 'EntitiesPane', {
          [classes.drawerEntitiesOpen]: showEntities,
          [classes.drawerClose]: !showEntities,
        })}
        classes={{
          paper: clsx(classes.drawerCommon, {
            [classes.drawerEntitiesOpenPaper]: showEntities,
          }),
        }}>
        <EntitiesPane
          visibleLayers={visibleLayers}
          handleAddFeatureLayer={handleAddFeatureLayer}
          handleRemoveFeatureLayer={handleRemoveFeatureLayer}
        />
      </Drawer>}
      <Drawer
        id='entityProperties'
        variant='persistent'
        open={showProperties}
        onClose={() => setFeatureDetailsTab(null)}
        className={clsx(classes.drawerCommon, {
          [classes.drawerEntityPropertiesOpen]: showProperties,
          [classes.drawerClose]: !showProperties,
        })}
        classes={{
          paper: clsx(classes.drawerCommon, {
            [classes.drawerEntityPropertiesOpenPaper]: showProperties,
          }),
        }}>
        {showProperties && (
          <FeatureDetails
            key={isDrawingGeometry ? (!!drawingFeature ? drawingFeature._id : drawing_id)
              : (isEditingGeometry ? `${editingFeature.properties.code}_${editingFeature.updatedAt}` : `${selectedEntity?.properties.code}_${selectedEntity?.updatedAt}`)}
            parent='Main'
            // readonly={!accessGranted('dtntwr')}
            noEdit={!accessGranted('dtntwr')}
            noDelete={!accessGranted('DltNtw')}
            canEditNotes={accessGranted('dtNtwr2')}
            hideMeasurements={!accessGranted('vwmsrm')}
            // readonlyMeasurements={!accessGranted('dtmsrm')}
            noEditMeasurements={!accessGranted('dtmsrm')}
            noDeleteMeasurements={!accessGranted('DltMsr')}
            hideTelemeters={!accessGranted('VwTlmt1')}
            noEditTelemeters={!accessGranted('dtTlmt')}
            // hideGeometry={!accessGranted('VwNtwr2')}
            noEditGeometry={!accessGranted('dtNtwr3') && (!(isDrawingGeometry && accessGranted('VwMsrm')))}
            target_id={target_id}
            setTarget_id={setTarget_id}
            flowPopupIsOpen={flowPopupIsOpen}
            setFlowPopupIsOpen={setFlowPopupIsOpen}
          />
        )}
      </Drawer>

      <main className={classes.main}>

        {!!selectedFeatureWithActiveAlarms?.notification && !selectedFeatureWithActiveAlarms?.feature && (
          <NotificationPopUp
            handleClosePopUp={handleCloseAlarmPopUp}
            selectedFeatureWithActiveAlarms={selectedFeatureWithActiveAlarms}
            inventoryItems={inventoryItems || []}
            maintenanceService={services.maintenanceService}
            repairs={[]} //testing
            // repairTypes={[]} possibly it  will be needed this for the notificationsPopup
          />
        )}
        {(contentKey === 'entities') && (
          <LMap
            setEntitiesOpen={setEntitiesOpen}
            noEdit={!accessGranted('dtntwr')}
            noDelete={!accessGranted('DltNtw')}
            flowPopupIsOpen={flowPopupIsOpen}
            setFlowPopupIsOpen={setFlowPopupIsOpen}
            handleOpenAlarmPopUp={handleOpenAlarmPopUp}
            handleCloseAlarmPopUp={handleCloseAlarmPopUp}
            maintenanceService={services.maintenanceService} //Currently it's reduntant,will be handly when maintenance notifications will be tied to features
            inventoryItems={inventoryItems}//Currently it's reduntant,will be handly when maintenance notifications will be tied to features
            urgentAlarmNotifications={personnelNotifications ? personnelNotifications.filter(n=>n.severity===1 && (['alarmFired', 'invrtAlarmFired', 'irriEventPersonnel'].includes(n.notificationType)) && !n.messages![0].webReadDate): []}
            selectedFeatureWithActiveAlarms={selectedFeatureWithActiveAlarms}
            setSelectedFeatureWithActiveAlarms={setSelectedFeatureWithActiveAlarms}
            entitiesOpen={entitiesOpen}//its needed to correct the offset of panMapToLatLng()
            contentKey={contentKey}
            visibleLayers={visibleLayers}
            handleAddFeatureLayer={handleAddFeatureLayer}
            target_id={target_id}
            setTarget_id={setTarget_id}
          />
        )}
        {contentKey === 'equipmentmaintenance' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <Maintenance
              maintenanceService={services.maintenanceService}
              inventoryItemService={services.inventoryItemService}
              buildErrorMessage={buildErrorMessage}
              isAddNetworkFromCons={isAddNetworkFromCons}
              selectedIssueItem={selectedIssueItem}
              setIsAddNetworkFromCons={setIsAddNetworkFromCons}
              entitiesOpen={true}
              visibleLayers={visibleLayers}
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'inventorycategories' && accessGranted('Vwnvnt') && (
        <div className={classes.content}>
          <SimpleErrorBoundary accessGranted={accessGranted}>
            <Suspense fallback={<div>Loading...</div>}>
              <Inventory
                activeContentKey={contentKey}
                inventoryItems={inventoryItems}
                inventoryItemService={services.inventoryItemService}
                maintenanceService={services.maintenanceService}
                actSaveInventoryItem={actSaveInventoryItem}
                buildErrorMessage={buildErrorMessage}
              />
            </Suspense>
          </SimpleErrorBoundary>
        </div>
        )}
        {contentKey === 'activities' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <ActivitiesProcedures maintenanceService={services.maintenanceService} />
          </Suspense>
        </div>
        )}
        {contentKey === 'waterbalance' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <WaterBalance
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'qualityControl' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <QualityControl
              actSaveIndicator={actSaveIndicator}
              actDeleteIndicator={actDeleteIndicator}
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'energyoptimization' && accessGranted('vwnrgy') && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <EnergyOptimazation
              buildErrorMessage={buildErrorMessage}
              energyOptimazationService={services.energyOptimazationService}
              actElectricityBills={actElectricityBills}
              getMeasurements={services.measurementService.getMeasurements}
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'hydraulicoptimization' && accessGranted('VwHydr') && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <HydraulicOptimazation
              buildErrorMessage={buildErrorMessage}
              epanetSimulationService={services.epanetSimulationService}
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'dataImport' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <DataImportMain
              buildErrorMessage={buildErrorMessage}
              energyOptimazationService={services.energyOptimazationService}
              tanks={tanks}
              pumps={pumps}
              stations={stations}
              actSaveInvoices={actSaveInvoices}
              actInvoice={actInvoice}
              actDeleteInvoiceByID={actDeleteInvoiceByID}
              actDeleteInvoicesByUploadedFileID={actDeleteInvoicesByUploadedFileID}
              actSaveElectricityBills={actSaveElectricityBills}
              actFileImportSeries={actFileImportSeries}
              actGetCollectionByUploadedFileID={actGetCollectionByUploadedFileID}
              actDeleteFileImportSeriesByUploadedFileID={actDeleteFileImportSeriesByUploadedFileID}
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'scada' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <MultiFeatureStepper
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'catalogs' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <Catalogs
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'irrigation' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <Irrigation
              actElectricityBills={actElectricityBills}
              // actIrrigation={actIrrigation}
              actIrrigation={() => verticalNavigate('irrigation', false)}
              actDeleteSwitchSlot={actDeleteSwitchSlot}
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'irriForm' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <IrrigationApplications
            />
          </Suspense>
        </div>
        )}
        {/* irriSchedule */}
        {contentKey === 'irriSchedule' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <IrrigationSchedule
              notifications={personnelNotifications?.filter(el=>el?.notificationType==="irriEventPersonnel") || []}
              actElectricityBills={actElectricityBills}
              actDeleteSwitchSlot={actDeleteSwitchSlot}
            />
          </Suspense>
        </div>
        )}
        {['cp.myAccount', 'cp.report', 'cp.network'].includes(contentKey) &&
          accessGranted('gnrccn') && (
          <div className={classes.contentMobile}>
            <SimpleErrorBoundary accessGranted={accessGranted}>
              <Suspense fallback={<div>Loading...</div>}>
                <CPConsumerMain
                  activeContentKey={contentKey}

                  issueReports={issueReports}
                  setIssueReports={setIssueReports}
                  issueReportService={services.issueReportService}

                  sendVerificationEmail={services.niamService.sendVerificationEmail}
                  verticalNavigate={verticalNavigate}
                  activeList={activeList}
                  setActiveList={handleSetActiveList}
                  actUpdateUserUsernameOrPasswordOrEmail={actUpdateUserUsernameOrPasswordOrEmail}
                />
              </Suspense>
            </SimpleErrorBoundary>
          </div>
          )}
        {contentKey === 'consumerPortal' && accessGranted('vwcnsm') && (
          <div className={classes.content}>
            <SimpleErrorBoundary accessGranted={accessGranted}>
              <Suspense fallback={<div>Loading...</div>}>
                <ConsumerPortal
                  activeContentKey={contentKey}
                  actUpdateUserUsernameOrPasswordOrEmail={actUpdateUserUsernameOrPasswordOrEmail}
                  sendVerificationEmail={services.niamService.sendVerificationEmail}
                  issueReports={issueReports}
                  setIssueReports={setIssueReports}
                  issueReportService={services.issueReportService}
                  activeList={activeList}
                  setActiveList={handleSetActiveList}
                  verticalNavigate={verticalNavigate}
                />
              </Suspense>
            </SimpleErrorBoundary>
          </div>
        )}
        {contentKey === 'consumerRegister' && (
          <div className={classes.content}>
            <Suspense fallback={<div>Loading...</div>}>
              <CPConsumerMain
                registerConsumer={registerConsumer}
                activeContentKey={contentKey}
                verticalNavigate={verticalNavigate}
              />
            </Suspense>
          </div>
        )}
        {contentKey === 'tenantSettings' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <TenantSettings
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'hydoorSettings' && (
        <div className={classes.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <HydoorSettings
            />
          </Suspense>
        </div>
        )}
        {contentKey === 'settings' && (
          <div className={classes.content}>
            <SimpleErrorBoundaryContent
              error="TypeError: Cannot read property 'toUpperCase' of undefined"
              errorInfo={
                '\n    in ErroneousGreeting (at IssueChat.tsx:272)\n    in div (created by Styled(MuiBox))\n    in Styled(MuiBox) (at IssueChat.tsx:264)\n    in IssueChat (at IssueDetails.tsx:126)\n    in div (created by Styled(MuiBox))\n    in Styled(MuiBox) (at IssueDetails.tsx:91)\n    in EquipmentDetails (at CPConsumerMain.tsx:618)\n    in div (created by ForwardRef(Paper))\n    in ForwardRef(Paper) (created by WithStyles(ForwardRef(Paper)))\n    in WithStyles(ForwardRef(Paper)) (at CPConsumerMain.tsx:617)\n    in div (created by Styled(MuiBox))\n    in Styled(MuiBox) (at CPConsumerMain.tsx:555)\n    in div (created by Styled(MuiBox))\n    in Styled(MuiBox) (at CPConsumerMain.tsx:501)\n    in CPConsumerMain (at Main.tsx:1353)\n    in SimpleErrorBoundary (at Main.tsx:1350)\n    in div (at Main.tsx:1349)\n    in main (at Main.tsx:1265)\n    in div (at Main.tsx:1085)\n    in Main (created by Context.Consumer)\n    in Route (at App.tsx:60)\n    in Switch (at App.tsx:59)\n    in Router (at App.tsx:58)\n    in ThemeProvider (at App.tsx:57)\n    in div (at App.tsx:55)\n    in App (at src/index.tsx:50)\n    in Auth0Provider (at src/index.tsx:43)'
              }
              handleReturnClick={() => setContentKey('consumerPortal')}
              accessGranted={accessGranted}
            />
          </div>
        )}
        {contentKey === 'profile' && (
          <div className={classes.content}>
            <Profile />
          </div>
        )}
        {contentKey === 'inverterMobile' && (
          <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
            <AppBarMobile unreadNotificationsCnt={0} logOut={actAvatarAction}/>
            <Suspense fallback={<SkeletonMainMobile/>}>
              {!pumps && <SkeletonMainMobile/>}
              {pumps && <MainMobile actUpdateUserUsernameOrPasswordOrEmail={actUpdateUserUsernameOrPasswordOrEmail}/>}
            </Suspense>
          </div>
        )}
      </main>
    </div>
  )
}

export default Main
