import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

import { useNetworkElementStore } from '../../state/networkElementStore'
import { useCollectionStore } from '../../state/collectionStore'
import { useCommonStore } from '../../state/commonStore'
import { useUiStore } from '../../state/uiStore'
import MeasurementsManager from '../../services/MeasurementsManager'
import FeatureService from '../../services/FeatureService'
import useLandParcelMeasurements from '../../hooks/useLandParcelMeasurements'
import Properties from './Properties'
import Geometry from './Geometry'
import MeasurementsMd from './MeasurementsMd'
import Notes from './Notes'
import WrmIcon from '../WrmIcon'
import { FTC } from '../../utils/constants'
import SCADADeviceCodes from './SCADADeviceCodes'

const useStyles = makeStyles(theme => ({
  fdroot: {
    overflowY: 'hidden',
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.tertiary.dark,
  },
  featureIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: 30,
    height: 30,
    fill: 'currentColor',
  },
  actionBtn: {
    padding: '0px 0px 0px 0px',
  },
  actionIcon: {
    color: '#828282',
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
  },
  trashIcon: {
    width: 14,
    height: 18,
  },
  undockIcon: {
    width: 24,
    height: 24,
    color: '#F6F6F6',
  },
  xIcon: {
    width: 14,
    height: 14,
    fill: 'currentColor',
  },
  tabsRoot: {
    minHeight: theme.spacing(4.5),
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.palette.tertiary.dark,
    minWidth: 90,
    maxWidth: 200,
    minHeight: 'inherit',
  },
  tabLabelSelected: {
    color: theme.palette.primary.dark,
  },
  noBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: theme.spacing(9),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  connectedLabel: {
    fontStyle: 'italic',
    fontSize: '0.75rem',
  },
  tooltip: {
    fontSize: 14,
  },
}))

const FeatureDetails = (props) => {
  const showAlert = useCommonStore(state => state.showAlert)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const measurementSeries = useCollectionStore(state => state.measurementSeries)
  const measurementTypes = useCollectionStore(state => state.measurementTypes) || []
  const featureService = FeatureService()
  const getConnectedFeatures = useNetworkElementStore(state => state.getConnectedFeatures)
  const selectedEntity = useUiStore(state => state.selectedEntity)
  const actSelectFeature = useUiStore(state => state.actSelectFeature)
  const {_cultivations,_switches, _hourMeters} = useLandParcelMeasurements(selectedEntity?.properties?.code || "")//(selectedEntity?.properties?.code || "")
  const measurementsManager = MeasurementsManager()
  const featureDetailsTab = useUiStore(state => state.featureDetailsTab)
  const setFeatureDetailsTab = useUiStore(state => state.setFeatureDetailsTab)
  const isFeatureDetailsDocked = useUiStore(state => state.isFeatureDetailsDocked)
  const setIsFeatureDetailsDocked = useUiStore(state => state.setIsFeatureDetailsDocked)
  const isEditingGeometry = useUiStore(state => state.isEditingGeometry)
  const editingFeature = useUiStore(state => state.editingFeature)
  const isDrawingGeometry = useUiStore(state => state.isDrawingGeometry)
  const drawingFeature = useUiStore(state => state.drawingFeature)
  const setDoSaveGeometry = useUiStore(state => state.setDoSaveGeometry)
  const setDoCancelGeometry = useUiStore(state => state.setDoCancelGeometry)
  const setDoCancelDrawing = useUiStore(state => state.setDoCancelDrawing)
  const actEditingGeometry = useUiStore(state => state.actEditingGeometry)

  useEffect(() => {
    if (!!selectedEntity) {
      console.log(`useEffect theFeature ${!!selectedEntity ? selectedEntity.properties.label : selectedEntity}`)
      let hideMeasurements = props.hideMeasurements
      if (selectedEntity.properties.featureType === "landparcel") {
        /** Land Parcels don't have measurements or series but they should display the measurements from the connected switches */
        //TODO: add the missing condition below
        hideMeasurements = props.hideMeasurements //|| measurementTypes.every(mt => mt.featureType !== theFeature.properties.featureType)
      } else {
        hideMeasurements = props.hideMeasurements || measurementTypes.every(mt => mt.featureType !== selectedEntity.properties.featureType)
      }
      // let hideMeasurements = props.hideMeasurements || measurementTypes.every(mt => mt.featureType !== theFeature.properties.featureType)
      if (hideMeasurements && (featureDetailsTab === 'measurements' || featureDetailsTab === 'meters')) {
        setFeatureDetailsTab('properties')
      }
    }
  }, [selectedEntity])

  const { t } = useTranslation()
  const classes = useStyles()

  const theFeature = isEditingGeometry ? editingFeature : (isDrawingGeometry ? drawingFeature : selectedEntity)

  const isFreeDrawnFeature = !!theFeature && !theFeature.properties.featureType
  const tabsInfo = [
    { code: 'properties', label: t('net.properties') },
  ]
  if (accessGranted('VwNtwr2')) {
    tabsInfo.push({ code: 'geometry', label: t('net.geometry') })
  }
  let hideMeasurements = props.hideMeasurements
  if (!hideMeasurements && !!theFeature) {
    if (theFeature.properties.featureType === "landparcel") {
      hideMeasurements = measurementTypes.every(mt => mt.featureType !== 'switch')
    } else {
      hideMeasurements = measurementTypes.every(mt => mt.featureType !== theFeature.properties.featureType)
    }
  }

  if (!hideMeasurements) {
    tabsInfo.push({ code: 'measurements', label: t('net.measurements') })
  }

  let hideTelemeters = props.hideTelemeters
  if (!hideTelemeters && !!theFeature) {
    hideTelemeters = measurementTypes.every(mt => mt.featureType !== theFeature.properties.featureType)
  }
  tabsInfo.push({ code: 'notes', label: !!theFeature ? t('md.notesWithCounter', {val: theFeature.notes?.length ?? 0}) : t('md.notes')})
  if (!hideTelemeters) {
    tabsInfo.push({ code: 'meters', label: t('net.meters') })
  }
  if (isDrawingGeometry) {
    tabsInfo.splice(0)
    tabsInfo.push({ code: 'geometry', label: t('net.geometry') })
  } else if (isFreeDrawnFeature) {
    tabsInfo.splice(1)
    tabsInfo.push({ code: 'geometry', label: t('net.geometry') })
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [msgDialog, setMsgDialog] = useState('... Ahh ... I forgot what I wanted to ask!')
  const [idDialog, setIdDialog] = useState('')
  const [textInputDialog, setTextInputDialog] = useState('')

  const saveMeasurement = (measurement, measurementTypeId?) => {
    return measurementsManager.actSaveMeasurement(measurement, theFeature, measurementTypeId)
  }
  const deleteMeasurement = (measurement) => {
    return measurementsManager.actDeleteMeasurement(measurement, theFeature)
  }

  const setGroupOfMeasurementsFilter = (updatedFilter) => {
    return measurementsManager.actSetGroupOfMeasurementsFilter(theFeature, updatedFilter)
  }

  const checkIfMeasurementsLoaded = (featureSeries) => {
    return featureSeries.findIndex(s => !s.measurements || s.measurements.length === 0) === -1
  }
  const handleTabChange = (event, tabIndex) => {

    let tabCode = tabsInfo[tabIndex].code

    if (tabCode === 'measurements' && !!theFeature && (!theFeature.series || !checkIfMeasurementsLoaded(theFeature.series) ) ) {
      setFeatureDetailsTab(tabCode)
      let getMeasurements = measurementsManager.getFeatureMeasurements.bind(this,theFeature) 
      if (!!selectedEntity && selectedEntity.properties.featureType === 'landparcel') {
        getMeasurements = measurementsManager.getFeatureMeasurementsForMultipleFeatures.bind(this,_switches,false,false,selectedEntity,_hourMeters)
      }
      if (!!props.inferredFeatures) {
        getMeasurements = measurementsManager.getFeatureMeasurementsForMultipleFeatures.bind(this,props.inferredFeatures,false,false,theFeature, _hourMeters)
      }
      getMeasurements()
      .then(
        res => {
        }, err =>{
          console.log(`Error loading series ${featureDetailsTab}`)
          console.log(`..attempting to load measurements of feature ${theFeature?.properties?.code} once more`)
          getMeasurements()
          .then(
            res => {
              console.log(`..second loading succeded.`)
            }, err =>{
              console.log(`..second loading failed.`)
            }
          )
        }
      )
    } else if (tabCode === 'meters' && !!theFeature && !theFeature.series) {
      setFeatureDetailsTab(tabCode)
      measurementsManager.getFeatureSeries(theFeature)
      .then(
        res => {
        }, err =>{
          console.log(`Error loading series ${featureDetailsTab}`)
        }
      )
    } else {
      setFeatureDetailsTab(tabCode)
    }
  }
  const handleDockClick = () => {
    console.log(`handleDockClick isFeatureDetailsDocked ${isFeatureDetailsDocked}`)
    setIsFeatureDetailsDocked(true)
    setFeatureDetailsTab('properties')
  }
  const handleUndockClick = () => {
    console.log(`handleUndockClick isFeatureDetailsDocked ${isFeatureDetailsDocked}`)
    setIsFeatureDetailsDocked(false)
    setFeatureDetailsTab('properties')
  }
  const handleTargetClick = () => {
    console.log(`handleTargetClick props.target_id ${props.target_id}`)
    props.setTarget_id(props.target_id + 1)
  }
  const handleCloseClick = () => {
    console.log(`handleCloseClick`)
    setIsFeatureDetailsDocked(false)
    setFeatureDetailsTab('properties')
    if (!!props.onClose) props.onClose()
  }

  const handleOpenDialog = (dlgId, question) => {
    setIdDialog(dlgId)
    setMsgDialog(question)
    setOpenDialog(true)
  }
  const handleCloseDialog = (reply) => {
    if (typeof reply !== 'string') reply = 'no'
    if (reply === 'yes') {
      switch (idDialog) {
        case 'confirmDeleteFeature':
          if (!isFreeDrawnFeature && !validateTextInput()) {
            return
          }
          let note = { text: textInputDialog }
          featureService.deleteFeature(theFeature, note)
          actSelectFeature(null)
          break;
        default:
      }
      setOpenDialog(false)
    } else {
      setOpenDialog(false)
    }
  }
  const validateTextInput = () => {
    let valid = true
    let deleteNote = !!textInputDialog ? textInputDialog.trim() : ''
    if (!deleteNote) {
      showAlert(t('net.features.confirm.delete.feature.note.notEmpty'), 'W')
      valid = false
    }
    return valid
  }
  const handleNoteTextChange = event => {
    let val = event.target.value
    setTextInputDialog(val)
  }

  const actEditGeometry = () => {
    actEditingGeometry(true)
  }
  const actApplyGeometry = () => {
    setDoSaveGeometry(true)
  }
  const actCancelGeometry = () => {
    setDoCancelGeometry(true)
  }
  const actCancelDrawing = () => {
    setDoCancelDrawing(true)
  }


  const ftinfo: any = !!theFeature ? FTC[theFeature.properties.featureType] : null
  let connectedFeatures:any = []
  if (openDialog) {
    connectedFeatures = getConnectedFeatures(theFeature)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      p={isFeatureDetailsDocked ? 1 : 0}
      className={classes.fdroot}
    >
      <Box
        display='flex'
        px={1} py={2}
        alignItems='center'
      >
        {false && isFeatureDetailsDocked &&
        <IconButton
          className={classes.actionBtn}
        >
          <WrmIcon
            icon='arrowLeft'
            className={classes.actionIcon}
            style={{ width: 14, height: 14 }}
          />
        </IconButton>}
        <WrmIcon
          icon={!!ftinfo ? ftinfo.icon : 'tint'}
          className={classes.featureIcon}
        />
        <Box
          display='flex'
          px={1}
          flexGrow={1}
          className={classes.title}
        >
          {!!theFeature ? theFeature.properties.label : ''}
        </Box>
        {!(props.noDelete || isEditingGeometry || isDrawingGeometry) &&
        <Tooltip title={`${t(`btn.delete`)}`} classes={{tooltip: classes.tooltip}} arrow placement='right' enterDelay={1000} enterNextDelay={500} >
          <IconButton
            className={classes.actionBtn}
            onClick={() => handleOpenDialog('confirmDeleteFeature', isFreeDrawnFeature ? t('net.features.confirm.delete.freeDraw') : t('net.features.confirm.delete.feature'))}
          >
            <WrmIcon
              icon='trash'
              className={clsx(classes.actionIcon, classes.trashIcon)}
            />
          </IconButton>
        </Tooltip>}
        {!(props.hideDockButtons || isEditingGeometry || isDrawingGeometry) &&
        <Tooltip title={`${t(`btn.target`)}`} classes={{tooltip: classes.tooltip}} arrow placement='right' enterDelay={1000} enterNextDelay={500} >
          <IconButton
            disabled={isEditingGeometry || isDrawingGeometry}
            className={classes.actionBtn}
            onClick={handleTargetClick}
          >
            <WrmIcon
              icon='target'
              className={clsx(classes.actionIcon, classes.undockIcon)}
              style={{ width: 18, height: 18 }}
            />
          </IconButton>
        </Tooltip>}
        {!(props.hideDockButtons || isEditingGeometry || isDrawingGeometry) && isFeatureDetailsDocked &&
        <Tooltip title={`${t(`btn.undock`)}`} classes={{tooltip: classes.tooltip}} arrow placement='right' enterDelay={1000} enterNextDelay={500} >
          <IconButton
            disabled={isEditingGeometry || isDrawingGeometry}
            className={classes.actionBtn}
            onClick={handleUndockClick}
          >
            <WrmIcon
              icon='undock'
              className={clsx(classes.actionIcon, classes.undockIcon)}
              style={{ width: 24, height: 24, color: '#F6F6F6' }}
            />
          </IconButton>
        </Tooltip>}
        {!props.hideDockButtons && !isFeatureDetailsDocked &&
        <Tooltip title={`${t(`btn.dock`)}`} classes={{tooltip: classes.tooltip}} arrow placement='right' enterDelay={1000} enterNextDelay={500} >
          <IconButton
            disabled={isEditingGeometry || isDrawingGeometry}
            className={classes.actionBtn}
            onClick={handleDockClick}
          >
            <WrmIcon
              icon='dock'
              className={clsx(classes.actionIcon, classes.undockIcon)}
              style={{ width: 24, height: 24, color: '#F6F6F6' }}
            />
          </IconButton>
        </Tooltip>}
        {!(props.hideDockButtons || isEditingGeometry || isDrawingGeometry) &&
        <Tooltip title={`${t(`btn.close`)}`} classes={{tooltip: classes.tooltip}} arrow placement='right' enterDelay={1000} enterNextDelay={500} >
          <IconButton
            disabled={isEditingGeometry || isDrawingGeometry}
            className={classes.actionBtn}
            onClick={handleCloseClick}
          >
            <WrmIcon
              icon='x-icon'
              className={clsx(classes.actionIcon, classes.xIcon)}
              style={{ width: 14, height: 14 }}
            />
          </IconButton>
        </Tooltip>}
      </Box>
      {isFeatureDetailsDocked &&
      <Tabs
        value={tabsInfo.findIndex(t => t.code === featureDetailsTab) > -1 ? tabsInfo.findIndex(t => t.code === featureDetailsTab) : 0}
        indicatorColor="primary"
        className={classes.tabsRoot}
        onChange={handleTabChange}
      >
        {tabsInfo.map((t: any) => (
          <Tab
            key={t.code}
            disabled={!!t.disabled || isEditingGeometry || isDrawingGeometry}
            label={t.label}
            className={t.code === featureDetailsTab ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}
          />
        ))}
      </Tabs>}
      <Divider variant='middle' />
      {featureDetailsTab === 'properties' &&
        <Properties
          readonly={props.noEdit}
          feature={theFeature}
          setEditing={props.setEditing}
          actEditGeometry={actEditGeometry}
          handleDockClick={handleDockClick}
        />}
      {featureDetailsTab === 'geometry' &&
        <Geometry
          feature={theFeature}
          readonly={true}
          hideButtons={props.noEditGeometry}
          // showFlowButton={props.parent === "Main"}
          onApply={actApplyGeometry}
          onCancel={actCancelGeometry}
          onCancelDrawing={actCancelDrawing}
          onEdit={actEditGeometry}
          flowPopupIsOpen={props.flowPopupIsOpen}
          setFlowPopupIsOpen={props.setFlowPopupIsOpen}
        />}
      {featureDetailsTab === 'measurements' &&
        <MeasurementsMd
          parent={props.parent}
          noEdit={props.noEditMeasurements}
          noDelete={props.noDeleteMeasurements}
          featureType={theFeature.properties.featureType}
          feature={theFeature}
          inferredFeatures={_switches}
          series={measurementSeries}
          saveMeasurement={saveMeasurement}
          deleteMeasurement={deleteMeasurement}
          measurementTypes={measurementTypes}
          setGroupOfMeasurementsFilter = {setGroupOfMeasurementsFilter}
        />}
      {featureDetailsTab === 'notes' &&
        <Notes
          canEditNotes={props.canEditNotes}
          feature={theFeature}
        />}
      {featureDetailsTab === 'meters' &&
        <SCADADeviceCodes
          noEditTelemeters={props.noEditTelemeters}
          measurementTypes={measurementTypes}
          feature={theFeature}
          series={measurementSeries}
        />}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{msgDialog}</DialogTitle>
        {!!theFeature && <DialogContent>
          <Box
            display='flex'
            flexDirection='column'
          >
            <Box
              display='flex'
              className={classes.title}
            >
              {theFeature.properties.label}
            </Box>
            {connectedFeatures.length > 0 &&
              <Box mt={2} mb={1}
                display='flex'
                flexDirection='column'
              >
                <Box py={0.5}
                  key='connectedLabel'
                  display='flex'
                // flexDirection='column'
                >
                  {t('net.features.confirm.delete.feature.connected.label')}
                </Box>
                <Box pb={1}
                  key='connectedInfo'
                  display='flex'
                  className={classes.connectedLabel}
                >
                  {t('net.features.confirm.delete.feature.connected.info')}
                </Box>
                {getConnectedFeatures(theFeature).map(f => (
                  <Box px={1} py={0.5}
                    key={f.properties._id}
                    display='flex'
                  >
                    {f.properties.label}
                  </Box>))}
              </Box>}
            {!!props.measurements && props.measurements.length > 0 && <Box mt={2} mb={1}
              display='flex'
              flexDirection='column'
            >
              <Box py={0.5}
                key='measurementsLabel'
                display='flex'
              >
                {`${t('net.features.confirm.delete.feature.measurements.label')} ${props.measurements.length}`}
              </Box>
              <Box pb={1}
                key='measurementsInfo'
                display='flex'
                className={classes.connectedLabel}
              >
                {t('net.features.confirm.delete.feature.measurements.info')}
              </Box>
            </Box>}
            {!isFreeDrawnFeature &&
            <Box mt={2} mb={1}
              display='flex'
            >
              <TextField
                label={t('net.features.confirm.delete.feature.note.prompt')}
                fullWidth
                multiline
                minRows={3}
                value={textInputDialog}
                onChange={handleNoteTextChange}
              />
            </Box>}
          </Box>
        </DialogContent>}
        <DialogActions>
          <Button
            autoFocus
            variant='outlined'
            className={classes.noBtn}
            onClick={() => handleCloseDialog('no')}
          >
            {t('btn.no')}
          </Button>
          <Button
            variant='outlined'
            className={classes.noBtn}
            color="primary"
            onClick={() => handleCloseDialog('yes')}
          >
            {t('btn.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )

}

export default FeatureDetails
