import React, { useReducer, useState } from 'react'
import { useCommonStore } from '../../state/commonStore'
import { useUiStore } from '../../state/uiStore'
import { useCollectionStore } from '../../state/collectionStore'
import FeatureService from '../../services/FeatureService'
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Divider,
  FormControlLabel,
  TextField,
} from '@material-ui/core/'
import { useTranslation } from 'react-i18next'
import { GenericFeature } from '../../utils/types/networkElementTypes'
import { Typography } from '@mui/material'
import { UserSettings } from '../../utils/types/collectionStoreTypes'
import { Action } from '../../utils/types/commonTypes'
import { PointCoordinates } from '../../utils/types/mapStoreTypes'
import ButtonHDR from '../presentationalComponents/ButtonHDR'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import useReproject from '../../utils/useReproject'
import { isFloat } from '../../utils/helpers'
import * as _ from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fbfbfb',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: '2fr 2fr 2fr',
      alignItems: 'center',
      rowGap: '1rem' /* vertical gap */,
      columnGap: '0,5rem' /* horizontal gap */,
    },
    checkBoxWrapper: {
      margin: '0',
      textAlign: 'left',
      fontSize: '10px',
    },
    formControlLabel: {
      fontSize: '15px',
    },
    coordinateSystemLabel: {
      marginRight: '1rem !important',
      alignSelf: 'center',
    },
    fullWidthRow: {
      display: 'flex',
      gridColumn: '1 / -1',
    },
    twoThirdsRow: {
      display: 'flex',
      gridColumn: '1 / 3',
    },
    dmsInputContainer: {
      gridColumn: '1 / 3',
      display: 'grid',
      gridTemplateColumns: '1fr 4fr 4fr 6fr',
      columnGap: '0.5rem',
    },
  })
)

/**'wgs84' | 'wgs84DMS' | 'egsa87' from UserSettings */
type CoordinateSystem = 'wgs84' | 'egsa87'
interface CoordinatesEditorProps {
  feature: GenericFeature
  isEdittingCoordinates: boolean
  setIsEditingCoordinates: React.Dispatch<React.SetStateAction<boolean>>
  // loading: boolean
  // setLoading: React.Dispatch<React.SetStateAction<boolean>>
  // handleSaveFeature: (updatedFeature: GenericFeature) => void
}

interface CoordinatesInputsState {
  coordinatesSystem: CoordinateSystem
  egsaX: string
  egsaY: string
  latitude: string
  longitude: string
  latDegrees: string
  latMinutes: string
  latSeconds: string
  longDegrees: string
  longMinutes: string
  longSeconds: string
  wgsUnits: 'decimalDegrees' | 'dms'
}

const initialState: CoordinatesInputsState = {
  coordinatesSystem: 'wgs84',
  egsaX: '',
  egsaY: '',
  latitude: '',
  longitude: '',
  latDegrees: '',
  latMinutes: '',
  latSeconds: '',
  longDegrees: '',
  longMinutes: '',
  longSeconds: '',
  wgsUnits: 'decimalDegrees',
}
/**Sets the coordinateSystem of the initial state based on UserSettings */
const getInitialInputState = (settings: UserSettings): CoordinatesInputsState => {
  const system = settings?.coordinatesSystem || ''
  switch (system) {
    case 'wgs84':
      return { ...initialState, coordinatesSystem: 'wgs84', wgsUnits: 'decimalDegrees' }
    case 'wgs84DMS':
      return { ...initialState, coordinatesSystem: 'wgs84', wgsUnits: 'dms' }
    case 'egsa87':
      return { ...initialState, coordinatesSystem: 'egsa87' }
    default:
      return { ...initialState }
  }
}

function inputStateReducer(state: CoordinatesInputsState, action: Action): CoordinatesInputsState {
  const payload = action.payload
  switch (action.type) {
    case 'coordinatesSystem':
      return { ...state, coordinatesSystem: payload }
    case 'latitude':
      return { ...state, latitude: payload }
    case 'longitude':
      return { ...state, longitude: payload }
    case 'egsaX':
      return { ...state, egsaX: payload }
    case 'egsaY':
      return { ...state, egsaY: payload }
    case 'latDegrees':
      return { ...state, latDegrees: payload }
    case 'latMinutes':
      return { ...state, latMinutes: payload }
    case 'latSeconds':
      return { ...state, latSeconds: payload }
    case 'longDegrees':
      return { ...state, longDegrees: payload }
    case 'longMinutes':
      return { ...state, longMinutes: payload }
    case 'longSeconds':
      return { ...state, longSeconds: payload }
    case 'wgsUnits':
      return { ...state, wgsUnits: payload }
    default:
      return { ...state }
  }
}

const CoordinatesEditor = ({
  feature,
  isEdittingCoordinates,
  setIsEditingCoordinates,
  // loading,
  // setLoading,
  // handleSaveFeature,
}: CoordinatesEditorProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const featureService = FeatureService()
  const R = useReproject()
  const showAlert = useCommonStore(state => state.showAlert)
  const userSettings = useCollectionStore(state => state.userSettings)!
  const mainMap = useUiStore(state => state.mainMap)
  const flyTo = useUiStore(state => state.flyTo)
  const setCircleMarkers = useUiStore(state => state.setCircleMarkers)
  const [inputState, dispatchInputStateChange] = useReducer(inputStateReducer, getInitialInputState(userSettings[0]))
  const [loading, setLoading] = useState(false)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const action = { type: name, payload: value }

    dispatchInputStateChange(action)
  }

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target
    const action = { type: name, payload: value }

    dispatchInputStateChange(action)
  }

  const handlePreviewClick = () => {
    validateCoordinatesForWarnings()
    let coords: any = []
    switch (inputState.coordinatesSystem) {
      case 'egsa87':
        coords = R([+inputState.egsaX, +inputState.egsaY], 'EPSG:2100', 'EPSG:4326') as PointCoordinates
        break
      case 'wgs84':
        if (inputState.wgsUnits === 'decimalDegrees') {
          coords = [+inputState.longitude, +inputState.latitude] as PointCoordinates
        } else if (inputState.wgsUnits === 'dms') {
          const lat = +inputState.latDegrees + +inputState.latMinutes / 60 + +inputState.latSeconds / 3600
          const lng = +inputState.longDegrees + +inputState.longMinutes / 60 + +inputState.longSeconds / 3600
          coords = [lng, lat]
        }
        break
      default:
        return
    }
    if (!!coords && coords?.length) {
      setCircleMarkers([{ center: coords }])
      let zoomLevel = !!mainMap ? mainMap.getZoom() : 15
      zoomLevel = zoomLevel < 15 || !zoomLevel ? 15 : zoomLevel
      flyTo(coords, zoomLevel)
    }
  }

  const handleSaveBtnClick = () => {
    if (!validateCoordinatesForWarnings('E')) {
      return
    }
    setLoading(true)
    const updatedFeature: GenericFeature = _.cloneDeep(feature)
    switch (inputState.coordinatesSystem) {
      case 'egsa87':
        let coords = R([+inputState.egsaX, +inputState.egsaY], 'EPSG:2100', 'EPSG:4326') as PointCoordinates
        updatedFeature.geometry.coordinates[0] = coords[0]
        updatedFeature.geometry.coordinates[1] = coords[1]
        break
      case 'wgs84':
        if (inputState.wgsUnits === 'decimalDegrees') {
          updatedFeature.geometry.coordinates[0] = +inputState.longitude
          updatedFeature.geometry.coordinates[1] = +inputState.latitude
        } else if (inputState.wgsUnits === 'dms') {
          const lat = +inputState.latDegrees + +inputState.latMinutes / 60 + +inputState.latSeconds / 3600
          const lng = +inputState.longDegrees + +inputState.longMinutes / 60 + +inputState.longSeconds / 3600
          updatedFeature.geometry.coordinates[0] = lng
          updatedFeature.geometry.coordinates[1] = lat
        }
        break
      default:
        return
    }

    // handleSaveFeature(updatedFeature)
    setCircleMarkers([])
    featureService
      .saveFeature(updatedFeature)
      .then(res => {
        flyTo(updatedFeature.geometry.coordinates as PointCoordinates)
        showAlert(t('toaster.message.save.edtits.success'), 'S')
      })
      .catch(err => {
        showAlert(err, 'E')
      })
      .finally(() => {
        // setLoading(false)
        setCircleMarkers([])
        // setIsEditingCoordinates(false)
      })
  }

  const inputCoordinatesAreValid = (): boolean => {
    switch (inputState.coordinatesSystem) {
      case 'egsa87':
        if (isFloat(inputState.egsaX) && isFloat(inputState.egsaY)) {
          return true
        }
        return false
      case 'wgs84':
        if (inputState.wgsUnits === 'decimalDegrees') {
          if (isFloat(inputState.latitude) && isFloat(inputState.longitude)) {
            return true
          }
        } else if (inputState.wgsUnits === 'dms') {
          if (
            isFloat(inputState.latDegrees) &&
            isFloat(inputState.latMinutes) &&
            isFloat(inputState.latSeconds) &&
            isFloat(inputState.longDegrees) &&
            isFloat(inputState.longMinutes) &&
            isFloat(inputState.longSeconds)
          ) {
            return true
          }
        }
        return false
      default:
        return false
    }
  }

  const validateCoordinatesForWarnings = (severity: 'E' | 'W' | 'S' | 'I' = 'W') => {
    let valid = true
    let msgs: string[] = []
    if (inputState.coordinatesSystem === 'wgs84' && inputState.wgsUnits === 'dms') {
      //longitude D/M/S
      if (+inputState.longDegrees > 180 || +inputState.longDegrees < -180 || inputState.longDegrees === '') {
        msgs.push(t('units.dmsLng.d.error'))
        valid = false
      }
      if (+inputState.longMinutes < 0 || +inputState.longMinutes > 59.9999 || inputState.longMinutes === '') {
        msgs.push(t('units.dmsLng.m.error'))
        valid = false
      }
      if (+inputState.longSeconds < 0 || +inputState.longSeconds > 59.9999 || inputState.longSeconds === '') {
        msgs.push(t('units.dmsLng.s.error'))
        valid = false
      }
      //latitude D/M/S
      if (+inputState.latDegrees > 90 || +inputState.latDegrees < -90 || inputState.latDegrees === '') {
        msgs.push(t('units.dmsLat.d.error'))
        valid = false
      }
      if (+inputState.latMinutes < 0 || +inputState.latMinutes > 59.9999 || inputState.latMinutes === '') {
        msgs.push(t('units.dmsLat.m.error'))
        valid = false
      }
      if (+inputState.latSeconds < 0 || +inputState.latSeconds > 59.9999 || inputState.latSeconds === '') {
        msgs.push(t('units.dmsLat.s.error'))
        valid = false
      }
    }
    //wgs84 Decimal Degrees
    else if (inputState.coordinatesSystem === 'wgs84' && inputState.wgsUnits === 'decimalDegrees') {
      if (+inputState.longitude > 180 || +inputState.longitude < -180 || inputState.longitude === '') {
        msgs.push(t('units.dmsLng.d.error'))
        valid = false
      }
      if (+inputState.latitude > 90 || +inputState.latitude < -90 || inputState.latitude === '') {
        msgs.push(t('units.dmsLat.d.error'))
        valid = false
      }
    }
    //ΕΓΣΑ87
    else if (inputState.coordinatesSystem === 'egsa87') {
      if (inputState.egsaX.trim().split('.')[0].length !== 6 || inputState.egsaX === '') {
        msgs.push(t('units.egsa87.lng.error'))
        valid = false
      }
      if (inputState.egsaY.trim().split('.')[0].length !== 7 || inputState.egsaY === '') {
        msgs.push(t('units.egsa87.lat.error'))
        valid = false
      }
    }
    if (msgs.length > 0) {
      showAlert(msgs.join('\n'), severity)
    }
    // console.log(valid)
    return valid
  }

  return (
    <Box className={classes.root}>
      <Box mb={1} mt={1}>
        <Typography textAlign={'left'}>{t('btn.editCoordinates')}</Typography>
        <Divider style={{ marginTop: '1rem' }} />
      </Box>
      <Box className={classes.gridContainer}>
        <Typography>{t('userSettings.coordinateSystem')}</Typography>
        <FormControlLabel
          className={classes.checkBoxWrapper}
          classes={{
            label: classes.formControlLabel,
          }}
          control={
            <CheckBoxNt
              checked={inputState.coordinatesSystem === 'egsa87'}
              onChange={e => handleCheckboxChange(e)}
              value='egsa87'
              name='coordinatesSystem'
            />
          }
          label={t('units.egsa87')}
        />
        <FormControlLabel
          className={classes.checkBoxWrapper}
          classes={{
            label: classes.formControlLabel,
          }}
          control={
            <CheckBoxNt
              checked={inputState.coordinatesSystem === 'wgs84'}
              onChange={e => handleCheckboxChange(e)}
              value='wgs84'
              name='coordinatesSystem'
            />
          }
          label={t('units.wgs84')}
        />
        {/* EGSA87 */}
        {inputState.coordinatesSystem === 'egsa87' && (
          <>
            <Box className={classes.fullWidthRow}>
              <Typography align='right' className={classes.coordinateSystemLabel}>
                {t('units.egsa87.lng')}
              </Typography>
              <TextField
                id='egsaX'
                variant='outlined'
                type='number'
                required
                fullWidth
                size='small'
                placeholder={'χχχχχχ.χχχ'}
                name='egsaX'
                value={inputState.egsaX}
                onChange={e => handleTextFieldChange(e)}
              />
            </Box>

            <Box className={classes.fullWidthRow}>
              <Typography align='right' className={classes.coordinateSystemLabel}>
                {t('units.egsa87.lat')}
              </Typography>
              <TextField
                id='egsaY'
                variant='outlined'
                type='number'
                required
                fullWidth
                size='small'
                placeholder={'yyyyyyy.yyy'}
                value={inputState.egsaY}
                name='egsaY'
                onChange={e => handleTextFieldChange(e)}
              />
            </Box>
          </>
        )}
        {/* WGS84 */}
        {inputState.coordinatesSystem === 'wgs84' && (
          <>
            {inputState.wgsUnits === 'decimalDegrees' && (
              <Box className={classes.twoThirdsRow}>
                <Typography align='right' className={classes.coordinateSystemLabel}>
                  {t('units.wgs84.lng')}
                </Typography>
                <TextField
                  id='longitude'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={'λλ.λλλλλλ'}
                  value={inputState.longitude}
                  name='longitude'
                  onChange={e => handleTextFieldChange(e)}
                />
              </Box>
            )}
            {inputState.wgsUnits === 'dms' && (
              <Box className={classes.dmsInputContainer}>
                <Typography align='left' className={classes.coordinateSystemLabel}>
                  {t('units.wgs84.lng')}
                </Typography>
                <TextField
                  id='longDegrees'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={'°'}
                  value={inputState.longDegrees}
                  name='longDegrees'
                  onChange={e => handleTextFieldChange(e)}
                />
                <TextField
                  id='longMinutes'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={"'"}
                  value={inputState.longMinutes}
                  name='longMinutes'
                  onChange={e => handleTextFieldChange(e)}
                />
                <TextField
                  id='longSeconds'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={"''"}
                  value={inputState.longSeconds}
                  name='longSeconds'
                  onChange={e => handleTextFieldChange(e)}
                />
              </Box>
            )}
            <FormControlLabel
              className={classes.checkBoxWrapper}
              control={
                <CheckBoxNt
                  checked={inputState.wgsUnits === 'dms'}
                  onChange={e => handleCheckboxChange(e)}
                  value='dms'
                  name='wgsUnits'
                />
              }
              label={t('units.degrees')}
            />
            {inputState.wgsUnits === 'decimalDegrees' && (
              <Box className={classes.twoThirdsRow}>
                <Typography align='right' className={classes.coordinateSystemLabel}>
                  {t('units.wgs84.lat')}
                </Typography>
                <TextField
                  id='latitude'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={'φφ.φφφφφφ'}
                  value={inputState.latitude}
                  name='latitude'
                  onChange={e => handleTextFieldChange(e)}
                />
              </Box>
            )}
            {inputState.wgsUnits === 'dms' && (
              <Box className={classes.dmsInputContainer}>
                <Typography align='left' className={classes.coordinateSystemLabel}>
                  {t('units.wgs84.lat')}
                </Typography>
                <TextField
                  id='latDegrees'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={'°'}
                  value={inputState.latDegrees}
                  name='latDegrees'
                  onChange={e => handleTextFieldChange(e)}
                />
                <TextField
                  id='latMinutes'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={"'"}
                  value={inputState.latMinutes}
                  name='latMinutes'
                  onChange={e => handleTextFieldChange(e)}
                />
                <TextField
                  id='latSeconds'
                  variant='outlined'
                  type='number'
                  required
                  fullWidth
                  size='small'
                  placeholder={"''"}
                  value={inputState.latSeconds}
                  name='latSeconds'
                  onChange={e => handleTextFieldChange(e)}
                />
              </Box>
            )}
            <FormControlLabel
              className={classes.checkBoxWrapper}
              control={
                <CheckBoxNt
                  checked={inputState.wgsUnits === 'decimalDegrees'}
                  onChange={e => handleCheckboxChange(e)}
                  value='decimalDegrees'
                  name='wgsUnits'
                />
              }
              label={t('units.decimalDegrees')}
            />
          </>
        )}
      </Box>
      {/* footer */}
      <Divider style={{ marginTop: '1rem' }} />
      <Box display={'flex'}>
        <ButtonHDR
          disabled={!inputCoordinatesAreValid()}
          variant='outlined'
          style={{ color: inputCoordinatesAreValid() ? '#828282' : '#979797', marginRight: 'auto' }}
          color='inherit'
          title='btn.preview'
          onClick={handlePreviewClick}
        />
        <ButtonHDR
          variant='outlined'
          style={{ color: '#828282' }}
          color='default'
          title='btn.cancel'
          onClick={() => {
            setIsEditingCoordinates(false)
            setCircleMarkers([])
          }}
        />
        <ButtonHDR
          loading={loading}
          disabled={!inputCoordinatesAreValid()}
          title='btn.apply'
          onClick={()=>handleSaveBtnClick()}
        />
      </Box>
    </Box>
  )
}

export default CoordinatesEditor
