import i18n from '../i18n'
import { buildErrorMessage } from '../utils/helpers'
import { alphabeticalSorter } from '../utils/helpers'
import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import { useNetworkElementStore } from '../state/networkElementStore'
import { useUiStore } from '../state/uiStore'
import MeasurementSeriesService from './MeasurementSeriesService'

export type featureType =
  | 'hydrant'
  | 'hydrometer'
  | 'junction'
  | 'landparcel'
  | 'pipe'
  | 'pump'
  | 'reservoir'
  | 'samplingPoint'
  | 'station'
  | 'switch'
  | 'tank'
  | 'valve'
  | 'wszone'

const FeatureService_FEATURE = (apiClient?) => {
  if (!apiClient) {
    apiClient = useCommonStore.getState().apiClient
  }
  const path = 'feature/'

  const profile = useCommonStore.getState().profile
  const showAlert = useCommonStore.getState().showAlert
  const setSelectedEntity = useUiStore.getState().setSelectedEntity
  
  const prepEntityKeys = (entityName: featureType, _entityPlural?: string) => {
    const FTC = useNetworkElementStore.getState().FTC
    const entityPlural = _entityPlural ?? FTC[entityName].collection
    const entityCap = `${entityName
      .substring(0, 1)
      .toUpperCase()}${entityName.substring(1)}`
    const entityPluralCap = `${entityPlural
      .substring(0, 1)
      .toUpperCase()}${entityPlural.substring(1)}`
    return { entityPlural: entityPlural, entityCap, entityPluralCap }
  }

  const _getFeatures = (featureType, filter?) => {
    let data: any = {
      operation: 'getFeatures',
      featureType: featureType,
    }

    /**
     * Special handling for landparcels:
     * we want to load only those of the carrent agricultural period
     */
    if (featureType === 'landparcel') {
      const currentAgriculturalPeriod = useCommonStore.getState().currentAgriculturalPeriod
      if (!filter) {
        filter = { 'properties.cultivationPeriod': currentAgriculturalPeriod?.cultivationPeriod || -1 }
      } else if (!filter['properties.cultivationPeriod']) {
        /* The above else if statement presumes that if a filter on agricultural period is already set
         * then it will be in the form of a direct property of a properties.cultivationPeriod property of the filter object.
         * This presumption currently holds and will probably continue to do so.
         * Should that ever change, we will deal with it then.
         */
        filter['properties.cultivationPeriod'] = currentAgriculturalPeriod?.cultivationPeriod || -1
      }
    }

    if (!!filter) {
      data.filter = filter
    }
    let cachePromise = true
    return sendRequest(data, cachePromise).then(res => {
      if (!res.data.error && !!res.data) {
        res.data.sort((a, b) =>
          alphabeticalSorter(a.properties.label, b.properties.label)
        )
      }
      return res
    })
  }

  const getGlobalFeatures = (featureType: featureType, filter?: any) => {
    if (!filter) {
      filter = {
        'properties.tenantCode': {$in: ['nm-tp', null].concat(profile?.tenants || [])},
      }
    } else if (!filter.tenantCode) {
      filter['properties.tenantCode'] = {$in: ['nm-tp', null].concat(profile?.tenants || [])}
    }
    let data: any = {
      operation: 'getGlobalFeatures',
      featureType: featureType,
      filter: filter
    }
    return sendRequest(data)
    .then(
      res => {
        if (!res.data.error) {
          console.log(`getGlobalFeatures cnt ${res.data.length}`)
          // console.log(`loadCollection ${_entityPlural} ${JSON.stringify(res.data,null,2)}`)
          return res
        } else {
          console.log(`ERROR getGlobalFeatures ${JSON.stringify(res.data,null,2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR getGlobalFeatures ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  const getFeatures = (featureType: featureType, filter?: any, doReload?: boolean) => {
    const { entityPlural } = prepEntityKeys(featureType)
    let entities: any[] = useNetworkElementStore.getState()[entityPlural]
    if (!!entities && !doReload) {
      return Promise.resolve({ data: entities })
    } else {
      return _getFeatures(featureType, filter).then(
        res => {
          if (!res.data?.error && !!res.data) {
            useNetworkElementStore.getState().setItems(entityPlural, res.data)
            return res
          } else {
            console.log(
              `ERROR getFeatures ${entityPlural} ${JSON.stringify(
                res.data,
                null,
                2
              )}`
            )
            return { data: [] }
          }
        },
        err => {
          console.log(
            `ERROR getFeatures ${entityPlural} ${JSON.stringify(err, null, 2)}`
          )
          return { data: [] }
        }
      )
    }
  }

  const getWSZonesByLocation = location => {
    let data = {
      operation: 'getFeaturesByLocation',
      featureType: 'wszone',
      location: location, //pointSchema
    }
    return sendRequest(data)
  }

  const _saveFeatureNoteRequest = (feature, note) => {
    let data: any = {
      feature_id: feature._id,
      featureType: feature.properties.featureType,
      note: note,
    }
    let isNewNote = !note._id
    if (isNewNote) {
      note.addedBy = profile?.user_id
      data.operation = 'addFeatureNote'
    } else {
      data.operation = 'updateFeatureNote'
      note.modifiedBy = profile?.user_id
      note.updatedAt = Date.now()
    }
    return sendRequest(data)
  }

  const saveFeatureNote = (feature: any, note: any, doNotSelect?: boolean) => {
    const { entityPlural } = prepEntityKeys(feature.properties.featureType)

    return _saveFeatureNoteRequest(feature, note).then(
      res => {
        const updatedFeature = res.data
        useNetworkElementStore
          .getState()
          .updateItem(entityPlural, updatedFeature)
        if (!doNotSelect)
          setSelectedEntity(updatedFeature)
        return updatedFeature
      },
      err => {
        return errorHandler(err, entityPlural)
      }
    )
  }

  const _deleteFeatureNoteRequest = (feature, note) => {
    let data: any = {
      operation: 'deleteFeatureNote',
      feature_id: feature._id,
      featureType: feature.properties.featureType,
      note: note,
    }
    return sendRequest(data)
  }
  const deleteFeatureNote = (feature: any, note: any) => {
    const { entityPlural } = prepEntityKeys(feature.properties.featureType)

    return _deleteFeatureNoteRequest(feature, note).then(
      res => {
        // console.log(`deleteFeatureNote ${feature.properties.code} res.data ${JSON.stringify(res.data,null,2)}`)
        const updatedFeature = res.data
        useNetworkElementStore
          .getState()
          .updateItem(entityPlural, updatedFeature)
        setSelectedEntity(updatedFeature)
        return updatedFeature
      },
      err => {
        return errorHandler(err, entityPlural)
      }
    )
  }

  const _saveFeatureRequest = feature => {
    let data: any = {
      feature: feature,
    }
    let isNewFeature = !feature._id
    if (isNewFeature) {
      let code = feature.properties.label
        .toLowerCase()
        .trim()
        .replace(/ /g, '')
        .replace(/a/g, '')
        .replace(/e/g, '')
        .replace(/i/g, '')
        .replace(/o/g, '')
        .replace(/u/g, '')
        .substring(0, 7)
      feature.properties.code = code
      feature.properties.tenantCode = profile?.selectedTenant
      data.operation = 'saveFeature'
    } else {
      data.operation = 'updateFeature'
    }
    return sendRequest(data)
  }

  const saveFeature = (feature: any, doNotSelect?: boolean) => {
    const { entityPlural } = prepEntityKeys(feature.properties.featureType)
    let isNewFeature = !feature._id

    if (feature.properties.featureType === 'landparcel') {
      const currentAgriculturalPeriod = useCommonStore.getState().currentAgriculturalPeriod
      feature.properties.cultivationPeriod = currentAgriculturalPeriod?.cultivationPeriod
    }

    /** It is possible that 'saveEntity' could be called by a component
     * before the entities collection has been loaded,
     * therefore we call 'getFeatures()' first **/
    return getFeatures(feature.properties.featureType)
      .then(res => {
        return _saveFeatureRequest(feature)
      })
      .then(
        res => {
          const updatedFeature = res.data
          if (isNewFeature) {
            useNetworkElementStore
              .getState()
              .addItem(entityPlural, updatedFeature)
          } else {
            useNetworkElementStore
              .getState()
              .updateItem(entityPlural, updatedFeature)
          }
          if (!doNotSelect)
            setSelectedEntity(updatedFeature)
          return updatedFeature
        },
        err => {
          return errorHandler(err, entityPlural)
        }
      )
  }

  const _updateManyFeaturesRequest = (featureType, filter, updateOb) => {
    let data = {
      operation: 'updateManyFeatures',
      featureType: featureType,
      filter: filter,
      updateOb: updateOb,
    }
    return sendRequest(data)
  }

  const updateManyFeatures = (featureType, filter, updateOb) => {
    console.log(`updateManyFeatures featureType ${featureType}`)
    return _updateManyFeaturesRequest(featureType, filter, updateOb).then(
      res => {
        // console.log(`featureService.updateManyFeatures res ${JSON.stringify(res.data,null,2)}`)
        console.log(res)
        let doReload = true

        /** It is possible that 'updateManyFeature' could be called by a component
         * before the entities collection has been loaded,
         * therefore we call 'getFeatures()' first **/
        return getFeatures(featureType, null, doReload)
      },
      err => {
        console.log(err)
        const { entityPlural } = prepEntityKeys(featureType)
        errorHandler(err, entityPlural)
      }
    )
  }

  const _saveMultipleFeaturesRequest = (
    features,
    fileMeta,
    featureType,
    doNotCreateFileImportSerie?
  ) => {
    let data: any = {
      features: features,
      fileMeta,
      operation: 'saveMultipleFeatures',
      doNotCreateFileImportSerie: doNotCreateFileImportSerie,
      featureType: featureType,
    }
    return sendRequest(data)
  }

  const saveMultipleFeatures = (
    features,
    fileMeta,
    featureType,
    doNotCreateFileImportSerie
  ) => {
    const { entityPlural } = prepEntityKeys(featureType)

    return _saveMultipleFeaturesRequest(
      features,
      fileMeta,
      featureType,
      doNotCreateFileImportSerie
    ).then(
      res => {
        // console.log(`actSaveFeatures res ${JSON.stringify(res.data, null, 2)}`)
        useNetworkElementStore.getState().addItems(entityPlural, res.data)
        return res.data
      },
      err => {
        errorHandler(err, entityPlural)
      }
    )
  }

  const _deleteFeatureRequest = feature => {
    let data = {
      operation: 'deleteFeature',
      feature: feature,
    }
    return sendRequest(data)
  }
  const deleteFeature = (feature, note?) => {
    console.log(`actDeleteFeature feature ${feature.properties.label}`)
    if (!feature.properties.featureType) {
      const setDoDeleteFreeGeometry =
        useNetworkElementStore.getState().setDoDeleteFreeGeometry
      setDoDeleteFreeGeometry(true)
      return
    }

    const alarms = useCollectionStore.getState().alarms
    const cultivations = useCollectionStore.getState().cultivations

    if (!!note && (!note.text || note.text.trim() === '')) {
      showAlert(
        i18n.t('net.features.confirm.delete.feature.note.notEmpty'),
        'W'
      )
      return
    }
    /** Before Deleting a feature check if it is tied to an alarm */
    const allarmsTiedToFeature = alarms?.filter(al =>
      al.features.some(el => el.featureCode === feature.properties.code)
    )
    const alarmNames = allarmsTiedToFeature?.map(el => el.name).join(', ')
    if (!!alarmNames) {
      showAlert(
        i18n.t('net.features.confirm.delete.feature.tied.to.alarm') +
          alarmNames,
        'W'
      )
      return
    }

    let featureType: featureType = feature.properties.featureType

    let doNotSelect = true
    switch (featureType) {
      /** Do not delete a landparcel if there are cultivations linked to it */
      case 'landparcel':
        if (
          cultivations?.some(c => c.landparcelCode === feature.properties.code)
        ) {
          showAlert(
            i18n.t('net.features.confirm.delete.feature.tied.to.cultivations'),
            'W'
          )
          return
        }
        break
      case 'wszone':
        const getConnectedFeatures =
          useNetworkElementStore.getState().getConnectedFeatures
        getConnectedFeatures(feature).forEach(f => {
          f.properties.wszoneCode = null
          saveFeature(f, doNotSelect)
        })
        break
      case 'pipe':
        break
      default:
        useNetworkElementStore
          .getState()
          .getIncomingPipes(feature)
          .forEach(f => {
            f.properties.outNodeCode = null
            f.properties.outNodeFeatureType = null
            saveFeature(f, doNotSelect)
          })
        useNetworkElementStore
          .getState()
          .getOutgoingPipes(feature)
          .forEach(f => {
            f.properties.inNodeCode = null
            f.properties.inNodeFeatureType = null
            saveFeature(f, doNotSelect)
          })
        break
    }

    let ftc = useNetworkElementStore.getState().FTC[featureType]
    const measurementSeriesService = MeasurementSeriesService(apiClient)

    return measurementSeriesService //delete the measurement series of the deleted feature as well
      .getMeasurementSeriesByFeature(
        feature.properties.featureType,
        feature.properties.code
      )
      .then(res => {
        let series = res.data
        let promises = series.map(s =>
          measurementSeriesService.deleteMeasurementSeries(s)
        )
        return Promise.all(promises)
      })
      .then(res => {
        return saveFeatureNote(feature, note, doNotSelect)
      })
      .then(res => {
        // feature = res.data
        feature = res
        return _deleteFeatureRequest(feature)
      })
      .then(
        res => {
          setSelectedEntity(null)
          useNetworkElementStore.getState().updateItem(ftc.collection, feature)
          return res
        },
        err => {
          const { entityPlural } = prepEntityKeys(featureType)
          errorHandler(err, entityPlural)
        }
      )
  }

  const sendRequest = (data: any, cachePromise?: boolean) => {
    // console.log(`FeatureService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, cachePromise, path, null, true)
  }
  const errorHandler = (err: any, entityPlural) => {
    let errorMessage = buildErrorMessage(err, null, entityPlural)
    showAlert(i18n.t(errorMessage), 'E')
    console.error(`errorMessage ${errorMessage}`)
    return Promise.reject(err)
  }

  return {
    getFeatures: getFeatures,
    getGlobalFeatures: getGlobalFeatures,
    saveFeature: saveFeature,
    deleteFeature: deleteFeature,
    getWSZonesByLocation: getWSZonesByLocation,
    saveFeatureNote: saveFeatureNote,
    deleteFeatureNote: deleteFeatureNote,
    updateManyFeatures: updateManyFeatures,
    saveMultipleFeatures: saveMultipleFeatures,
  }
}

export default FeatureService_FEATURE
