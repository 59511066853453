import TemplateService from './TemplateService'

const UserSettingsService = () => {
  const templateService = TemplateService('userSettings','userSettings')

  const getUserSettings = (user_id: string) => {
    let filter:any = {}
    if (!!user_id) {
      filter.user_id = user_id
      return templateService.getEntities(filter)
    } else {
      throw new Error('user_id is required')
    }
  }

  const saveUserSettings = userSettings => {
    let data: any = {
      userSettings: userSettings,
    }
    let isNewItem = !userSettings._id
    if (isNewItem) {
      data.operation = 'saveUserSettings'
    } else {
      data.operation = 'updateUserSettings'
    }
    return templateService.saveEntity(userSettings)
  }

  const deleteUserSettings = userSettings => {
    let data = {
      operation: 'deleteUserSettings',
      userSettings: userSettings,
    }
    return templateService.deleteEntity(data)
  }

  return {
    getUserSettings: getUserSettings,
    saveUserSettings: saveUserSettings,
    deleteUserSettings: deleteUserSettings,
  }
}

export default UserSettingsService
