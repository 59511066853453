import { Simulation, SimulationNew } from "../models/energy"
import { alphabeticalSorter } from '../utils/helpers'
var _ = require('lodash');

const EnergyOptimazationService = (apiClient) => {

  const pathReport = 'energyMonitoring/'

  const getEnergyMonitoring = (request) => {
    let data: any = request
    return postRequest(data, pathReport)
  }

  const pathSimu = 'energySimulation/'

  const runSimulation = (request) => {
    let data: any = request
    return postRequest(data, `${pathSimu}simulator`)
  }

  const getSimulationTanks = () => {
    let pathTank = `${pathSimu}tanks`
    let cachePromise = true
    return sendGetRequest(pathTank, cachePromise)
    .then(
      res => {
        // console.log(`getSimulationTanks sorting`)
        res.data.sort((a, b) => alphabeticalSorter(a.properties.label, b.properties.label))
        return res
      }
    )
  }

  const getSimulationPumps = () => {
    let pathPump = `${pathSimu}pumps`
    let cachePromise = true
    return sendGetRequest(pathPump, cachePromise)
    .then(
      res => {
        // console.log(`getSimulationPumps sorting`)
        res.data.sort((a, b) => alphabeticalSorter(a.properties.label, b.properties.label))
        return res
      }
    )
  }

  const getEnergySimulation = () => {
    let cachePromise = true
    return sendGetRequest(pathSimu, cachePromise)
  }

  const getEnergySimulationById = (id) => {
    let pathId = `${pathSimu}${id}`
    return sendGetRequest(pathId)
  }

  const getMeasurement = (measurement) => {
    let data: any = measurement
    let pathAdd = `${pathSimu}measurements`
    return postMeasurementRequest(data, pathAdd)
  }

  const saveSimulation = (data) => {
    let dataES = new SimulationNew();
    _.assign(dataES , _.pick(data, _.keys(dataES)));
    return postRequest(dataES, pathSimu)
  }

  const updateSimulation= (data) => {
    let dataES = new Simulation();
    _.assign(dataES , _.pick(data, _.keys(dataES)));
    return putRequest(dataES, pathSimu)
  }

  const deleteSimulation = (simu_id) => {
    return deleteRequest(`${pathSimu}${simu_id}`)
  }

  const saveEnergySimulationNote = (request) => {
    let data: any = request
    let pathAdd = `${pathSimu}note`
    return postAddNoteRequest(data, pathAdd)
  }

  const postAddNoteRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  const sendGetRequest = (path: string, cachePromise?: boolean) => {
    return apiClient.sendGetRequest(cachePromise, path, null, true, null)
  }

  const postMeasurementRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  const postRequest = (data, path) => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  const putRequest = (data, path) => {
    return apiClient.sendPutRequest(data, path, null, true)
  }

  const deleteRequest = (url) => {
    return apiClient.sendDeleteRequest(url, null, true)
  }

  return({
    getEnergyMonitoring: getEnergyMonitoring,
    runSimulation: runSimulation,
    getSimulationTanks: getSimulationTanks,
    getSimulationPumps: getSimulationPumps,
    getEnergySimulation: getEnergySimulation,
    getEnergySimulationById: getEnergySimulationById,
    getMeasurement: getMeasurement,
    saveSimulation: saveSimulation,
    updateSimulation: updateSimulation,
    deleteSimulation: deleteSimulation,
    saveEnergySimulationNote: saveEnergySimulationNote
  })

}

export default EnergyOptimazationService
