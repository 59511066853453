const MeasurementService = (apiClient) => {

  const path = 'measurement/'

  const getAggregatedMeasurements = (seriesId, mfilter, timeUnit?) => {
    console.log(`MeasurementService getAggregatedMeasurements seriesId ${seriesId} mfilter ${JSON.stringify(mfilter,null,2)}`)
    if (!timeUnit) {
      timeUnit = 'day'
    }
    let data: any = {
      operation: 'getAggregatedMeasurements',
      mfilter: mfilter,
      seriesId: seriesId,
      timeUnit: timeUnit,
    }
    return sendRequest(data)
  }
  /** similarly to getAggregatedMeasurements gets aggregated measurements for QCreports */
  const getReportData = (seriesIds, mfilter,reportType, timeUnit?) => {
    console.log(`MeasurementService getReportData seriesId ${seriesIds} mfilter ${JSON.stringify(mfilter,null,2)}`)
    if (!timeUnit) {
      timeUnit = 'day'
    }
    let data: any = {
      operation: 'getReportData',
      mfilter,
      seriesIds,
      reportType,
      timeUnit,
    }
    return sendRequest(data)
  }

  const getMeasurementsByFeature = (featureType, featureCode) => {
    let filter = {
      featureType: featureType,
      featureCode: featureCode,
    }
    return getMeasurements(filter)
  }
  const getMeasurementsByType = (measurementTypeId) => {
    let filter = {
      measurementType: measurementTypeId,
    }
    return getMeasurements(filter)
  }
  const getMultiFeatureMeasurementsByMeasurementType = (measurementTypeId: string, featureCodes: string[], mfilter: any, sortedBy?: string) => {
    let sfilter: any = {
      measurementType: measurementTypeId,
      featureCode: { $in: featureCodes }
    }
    let data: any = {
      operation: 'getMultiFeatureMeasurements',
    }
    if (!!sfilter) {
      data.sfilter = sfilter
    }
    if (!!sortedBy) {
      data.sortedBy = sortedBy
    } else {
      data.sortedBy = 'series refDatetime'
    }
    if (!!mfilter) {
      data.mfilter = mfilter
    }
    return sendRequest(data)
  }
  const getMeasurementsByFeatureAndType = (featureType, featureCode, measurementTypeId, sortedBy) => {
    let filter = {
      featureType: featureType,
      featureCode: featureCode,
      measurementType: measurementTypeId
    }
    return getMeasurements(filter, sortedBy)
  }
  const getMeasurementsByIndicator = (indicatorCode) => {
    let filter = {
      indicatorCode: indicatorCode,
    }
    return getMeasurements(filter)
  }
  /**
   * @param sfilter: series filter eg: {_id: seriesId}
   * @param sortedBy: sortedBy property, defaults to `refDatetime` 
   * @param mfilter: the filter for measurements eg {
          refDatetime: {
            $gte: moment(someDate),
            $lte: moment(someOtherDate),
          }
   * @param firstAndLastOnly: boolean, if true will bring only the first and last measurements  */
  const getMeasurements = (sfilter?, sortedBy?, mfilter?, firstAndLastOnly?) => {
    //console.log(`MeasurementService getMeasurements sfilter:${JSON.stringify(sfilter)} mfilter:${JSON.stringify(mfilter)}`)
    let data: any = {
      operation: 'getMeasurements',
    }
    if (!!sfilter) {
      data.sfilter = sfilter
    }
    if (!!sortedBy) {
      data.sortedBy = sortedBy
    } else {
      data.sortedBy = 'refDatetime'
    }
    if (!!mfilter) {
      data.mfilter = mfilter
    }
    if (!!firstAndLastOnly) {
      data.firstAndLastOnly = firstAndLastOnly
    }
    return sendRequest(data)
  }
  
  const saveMeasurement = (measurement) => {
    let data: any = {
      measurement: measurement,
    }
    let isNewMeasurement = !measurement._id
    let mfilter:any = {
      'series': measurement.series,
      'refDatetime': measurement.refDatetime,
      'isDeleted' : { $in: [null, false]}
    }
    data.filter = mfilter
    if (isNewMeasurement) {
      data.operation = 'saveMeasurement'
    } else {
      data.operation = 'updateMeasurement'
    }
    return sendRequest(data)
  }

  const submitHydrometerConsumption = (measurement) => {
    let data: any = {
      measurement: measurement,
    }
    let mfilter:any = {
      'series': measurement.series,
      'refDatetime': measurement.refDatetime,
      'isDeleted' : { $in: [null, false]}
    }
    data.filter = mfilter
    data.operation = 'saveMeasurement'
    console.log(`submitHydrometerConsumption filter is:${JSON.stringify(mfilter)}`)
    return sendRequest(data)
  }

  const deleteMeasurement = (measurement) => {
    let data = {
      operation: 'deleteMeasurement',
      measurement: measurement,
    }
    return sendRequest(data)
  }

  const sendRequest = (data) => {
    // console.log(`MeasurementService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getAggregatedMeasurements: getAggregatedMeasurements,
    getReportData: getReportData,
    getMultiFeatureMeasurementsByMeasurementType: getMultiFeatureMeasurementsByMeasurementType,
    getMeasurementsByFeature: getMeasurementsByFeature,
    getMeasurementsByType: getMeasurementsByType,
    getMeasurementsByFeatureAndType: getMeasurementsByFeatureAndType,
    getMeasurementsByIndicator: getMeasurementsByIndicator,
    getMeasurements: getMeasurements,
    saveMeasurement: saveMeasurement,
    submitHydrometerConsumption:submitHydrometerConsumption,
    deleteMeasurement: deleteMeasurement,
  })

}

export default MeasurementService
