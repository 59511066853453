import { useEffect } from 'react'

import MeasurementsManager from '../services/MeasurementsManager'
import NotificationsManager from '../services/NotificationsManager'
import CollectionManager from '../services/CollectionManager'
import ApiClient from '../services/ApiClient'
import ActivityService from '../services/ActivityService'
import AgriculturalPeriodService from '../services/AgriculturalPeriodService'
import AlarmService from '../services/AlarmService'
import AssetService from '../services/AssetService'
import ConsumerService from '../services/ConsumerService'
import CultivationService from '../services/CultivationService'
import DeviceLinkService from '../services/DeviceLinkService'
import ElectricityBillsService from '../services/ElectricityBillsService'
import EnergyOptimazationService from '../services/EnergyOptimazationService'
import EpanetSimulationService from '../services/EpanetSimulationService'
import FeatureService,{ featureType } from '../services/FeatureService'
import FileImportSeriesService from '../services/FileImportSeriesService'
import GroupItemService from '../services/GroupItemService'
import IndicatorService from '../services/IndicatorService'
import InventoryItemService from '../services/InventoryItemService'
import InvoiceService from '../services/InvoicesService'
import IrriCommandService from '../services/IrriCommandService'
import IrriEventService from '../services/IrriEventService'
import IrriFormService from '../services/IrriFormService'
import IrriProgService from '../services/IrriProgService'
import IrriSchedService from '../services/IrriSchedService'
import IssueReportService from '../services/IssueReportService'
import MaintenanceService from '../services/MaintenanceService'
import MeasurementSeriesService from '../services/MeasurementSeriesService'
import MeasurementService from '../services/MeasurementService'
import MeasurementTypesService from '../services/MeasurementTypesService'
import MseriesLinkService from '../services/MseriesLinkService'
import MseriesService from '../services/MseriesService'
import MsrmService from '../services/MsrmService'
import NiamService from '../services/NiamService'
import NotificationService from '../services/NotificationService'
import PersonnelService from '../services/PersonnelService'
import PopulationService from '../services/PopulationService'
import SwitchSlotService from '../services/SwitchSlotService'
import ProcedureService from '../services/ProcedureService'
import TenantSettingsService from '../services/TenantSettingsService'
import UserSettingsService from '../services/UserSettingsService'
import WaterLossService from '../services/WaterLossService'

import { useCommonStore } from '../state/commonStore'

const useServices = (getTokenSilently?) => {
  // console.log(`useServices`)
  const setApiClient = useCommonStore(state => state.setApiClient)
  /* init services */
  // setApiClient(apiClient)
  let apiClient = useCommonStore.getState().apiClient
  useEffect(() => {
    if (getTokenSilently) {
      console.log(`getTokenSilently`)
      apiClient = ApiClient(getTokenSilently)
      setApiClient(apiClient)
    }
  }, [getTokenSilently])//, loading, user])

  const services = {
    apiClient: apiClient,
    activityService: ActivityService(),
    agriculturalPeriodService: AgriculturalPeriodService(),
    alarmService: AlarmService(),
    assetService: AssetService(),
    consumerService: ConsumerService(),
    cultivationService: CultivationService(),
    deviceLinkService: DeviceLinkService(),
    electricityBillsService: ElectricityBillsService(apiClient),
    energyOptimazationService: EnergyOptimazationService(apiClient),
    epanetSimulationService: EpanetSimulationService(apiClient),
    groupItemService: GroupItemService(),
    featureService: FeatureService(),
    fileImportSeriesService: FileImportSeriesService(apiClient),
    indicatorService: IndicatorService(),
    inventoryItemService: InventoryItemService(apiClient),
    invoiceService: InvoiceService(apiClient),
    irriCommandService: IrriCommandService(),
    irriEventService: IrriEventService(),
    irriFormService: IrriFormService(),
    irriProgService: IrriProgService(),
    irriSchedService: IrriSchedService(),
    issueReportService: IssueReportService(apiClient),
    maintenanceService: MaintenanceService(apiClient),
    measurementSeriesService: MeasurementSeriesService(apiClient),
    measurementService: MeasurementService(apiClient),
    measurementTypesService: MeasurementTypesService(apiClient),
    mseriesLinkService: MseriesLinkService(),
    mseriesService: MseriesService(),
    msrmService: MsrmService(),
    niamService: NiamService(apiClient),
    notificationService: NotificationService(),
    personnelService: PersonnelService(),
    populationService: PopulationService(),
    switchSlotService: SwitchSlotService(),
    procedureService: ProcedureService(),
    tenantSettingsService: TenantSettingsService(apiClient),
    userSettingsService: UserSettingsService(),
    waterLossService: WaterLossService(),
  } 

  return services
}

export default useServices