import domtoimage from 'dom-to-image'
import FileSaver from 'file-saver'
import ReactDOM from 'react-dom'

import { IconButton, makeStyles, Theme, createStyles } from '@material-ui/core'
import { GetApp as Download } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      '@media print': {
        display: 'none',
      },
    },
    icon: {
      color: 'black',
    },
  })
)

export interface DownloadImageButtonHDRProps {
  /**Defines the size of the IconButton and it's click area, defaults to medium */
  size?: 'medium' | 'small' | undefined
  /**Size of the Icon, defaults to 'medium'  */
  fontSize?: 'inherit' | 'medium' | 'default' | 'large' | 'small' | undefined
  /**Styles applied to the IconButton, default style has just one rule: '@media print': { display: 'none', },
   * that hides the button during printing, in case you provide your own css class here you'll have to
   * to add '@media print' rule to that class, default style and custom styles are not merged */
  buttonCSSclass?: any
  /**Styles passed to the Print Icon Component, defaults to icon: { color: 'black' }, */
  iconCSSClass?: any
  /**In case we are mapping Charts or any other Components we need to provide the (order)index of each ref inside the elementsRefs, defaults to `0` */
  order?: number
  /**An array of refs to the elements that we want to download as images,
   * eg: let chartRefs: any[] = [];
   * < div ref={chart => chartRefs.push(chart)} /> < SomeComponentWeWantToDownload /> < /div > */
  elementsRefs: any[]
  /** If some elements need to be removed from the downloaded image, just pass an array of classes
   * in the hideClass prop, elements bearing that classes will be removed from the downloaded image (their visibility will be temporary set to hidden)
   * defaults to ['recharts-brush-bar'] (removes the < Brush > slider from the charts) */
  hideClasses?: string[]
  /**FileName for the downloaded file, defaults to `chart${Date.now()}.png` */
  fileName?: string
}

/**Downloads part of the DOM as a png image
 * In case we are printing a single Comomponent all it needs elementsRefs prop (an array of refs of the DOM)
 * In case we are mapping elements (eg Reports with multiple charts) apart from elementsRefs it also needs the order (index of the ref in the array) */
const DownloadImageButtonHDR = ({
  size = 'medium',
  fontSize = 'medium',
  buttonCSSclass = undefined,
  iconCSSClass = undefined,
  order = 0,
  elementsRefs,
  hideClasses = ['recharts-brush-bar'],
  fileName = '',
}: DownloadImageButtonHDRProps) => {
  const classes = useStyles()

  const handleSaveGraphClick = (event, order) => {
    // console.log(`handleSaveGraphClick order ${order}`)
    let chartWrapper: any = ReactDOM.findDOMNode(elementsRefs[order])

    let selectedElements: Element[] = []
    // Populate the selectedElements
    hideClasses.forEach(className => {
      const elementsWithHiddenClasses = chartWrapper.querySelectorAll(`.${className}`)
      selectedElements = selectedElements.concat([...elementsWithHiddenClasses])
    })
    //hide the selectedElements
    selectedElements.forEach((element: any) => {
      element.style.visibility = 'hidden'
    })

    if (!!chartWrapper) {
      domtoimage
        .toBlob(chartWrapper)
        .then(blobres => {
          FileSaver.saveAs(blobres, !!fileName ? fileName : `chart${Date.now()}.png`)
        })
        .finally(res => {
          //Unhide the selectedElements after the download
          selectedElements.forEach((element: any) => {
            element.style.visibility = 'visible'
          })
        })
    }
  }

  return (
    <IconButton
      aria-label='Print'
      className={buttonCSSclass ? buttonCSSclass : classes.iconButton}
      size={size}
      onClick={event => handleSaveGraphClick(event, order)}
    >
      <Download fontSize={fontSize} className={iconCSSClass ? iconCSSClass : classes.icon} />
    </IconButton>
  )
}

export default DownloadImageButtonHDR
