import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Print, GetApp as Download, TableChart } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FileSaver from 'file-saver'
import domtoimage from 'dom-to-image'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
  },
  titleTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  graphBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialogActions: {
    flexFlow: 'column',
  },
  blueBtn: {
    textTransform: 'none',
    minWidth: 100,
    width: theme.spacing(9),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    margin: '0.5rem  0.5rem  0.5rem auto !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tableGraphIcon: {
    color: 'white',
  },
  tableHeader: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  printWrapper: {
    paddingRight: '1rem',
  },
  tableContainer: {
    overflowX: 'scroll',
    paddingRight:'2rem'
  },
}))

export default function NotificationsTableDialog({
  filteredAlarmNotificationsMSGS,
  samplingPoints,
  startDate,
  endDate,
  filteredSamplingPoints,
  filterIsActive,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const [tableHeadersNew, setTableHeadersNew] = useState<any>(null)
  const [tableDataNew, setTableDataNew] = useState<any>(null)

  useEffect(() => {
    let datesClasses: any = [] //
    let datesArray: any = [] //tableheaders
    const start = moment(startDate)
    const end = moment(endDate)
    let samplingPointsArr: any[] = []
    let samplingPointsObj = {}

    if (
      start.isValid() &&
      end.isValid() &&
      filteredAlarmNotificationsMSGS &&
      filterIsActive &&
      open
    ) {
      const duration = moment.duration(end.diff(start))
      const days = duration.asDays()
      let dtformat = 'DD-MMM-YY'

      /** GET LABELS */
      if (days <= 14) {
        let i = moment(start)
        while (i < end) {
          datesClasses.push({
            name: i.format(dtformat),
            upperLimit: moment(i).endOf('day'),
          })
          datesArray.push(i.format(dtformat))
          i.set('day', i.get('day') + 1)
          i.startOf('day')
        }
      } else if (days <= 98) {
        let i = moment(start)
        while (i < end) {
          datesClasses.push({
            name: i.format(dtformat),
            upperLimit: moment(i).endOf('day'),
          })
          datesArray.push(i.format(dtformat))
          i.set('day', i.get('day') + 7)
          i.startOf('day')
          //@ts-ignore
          if (
            i >= end &&
            !datesClasses.some(el => el.name === end.format(dtformat))
          ) {
            datesClasses.push({
              //@ts-ignore
              name: end.format(dtformat),
              //@ts-ignore
              upperLimit: moment(end).endOf('day'),
            })
            datesArray.push(end.format(dtformat))
          }
        }
      } else {
        let i = moment(start)
        dtformat = 'MMM YY'
        //both i and end are moment date objects, comparisson runs on dates
        while (i < end) {
          datesClasses.push({
            name: i.format(dtformat),
            upperLimit: moment(i).endOf('month'),
          })
          datesArray.push(i.format(dtformat))
          i.set('month', i.get('month') + 1)
          i.startOf('month')
        }
      }

      const datesTemplate = {}
      datesArray.forEach(el => {
        datesTemplate[el] = 0
      })

      filteredSamplingPoints.forEach(el => {
        const SP = samplingPoints.find(sp => sp.properties.code === el)
        samplingPointsArr.push({
          //@ts-ignore
          code: SP.properties.code,
          //@ts-ignore
          label: SP.properties.label,
          ...datesTemplate,
        })
        samplingPointsObj[el] = {
          code: SP.properties.code,
          label: SP.properties.label,
          ...datesTemplate,
        }
      })

      filteredAlarmNotificationsMSGS?.forEach(el => {
        const elDate = moment(el.sentDate)
        let targetDate
        for (let i = 0; i < datesClasses.length; i++) {
          if (elDate <= datesClasses[i].upperLimit) {
            targetDate = datesClasses[i].name
            break
          }
        }
        samplingPointsObj[el.ms.featureCode][targetDate]++
      })
    }
    const finalDataNew = Object.keys(samplingPointsObj).map(
      el => samplingPointsObj[el]
    )
    setTableHeadersNew(datesArray)
    setTableDataNew(finalDataNew)
  }, [startDate, endDate, filteredAlarmNotificationsMSGS, filterIsActive,open])

  const tableRef = useRef(null)
  const tableRefPrint = useRef(null)

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const handleSaveGraphClick = event => {
    // https://github.com/tsayen/dom-to-image/issues/183
    let listWrapper: any = tableRef.current
    if (!!listWrapper) {
      domtoimage
        .toBlob(listWrapper, {
          width: listWrapper.scrollWidth,
          height: listWrapper.scrollHeight,
        })
        .then(blobres => {
          FileSaver.saveAs(blobres, `alarms${Date.now()}.png`)
        })
    }
  }

  const handlePrintTableReport = event => {
    let printoutNode: any = tableRefPrint.current
    let newWin = window.open('')
    const title = `<div style={{display:}}>
    <h4>${t('alarms.calendar.filter.from')} ${getStartDate()} ${t(
      'alarms.calendar.filter.to'
    )} ${getEndDate()} ${t('alarms.charts.totalAlarms')} ${
      filteredAlarmNotificationsMSGS.length
    }</h4></div>`
    //@ts-ignore
    newWin.document.write(title + printoutNode.outerHTML)
    //@ts-ignore
    newWin.document.title = `report${Date.now()}.png`
    //@ts-ignore
    newWin.focus()
    //@ts-ignore
    newWin.print()
    //@ts-ignore
    newWin.close()
  }

  const getStartDate = () => {
    if (startDate) {
      return moment(startDate).format('DD-MMMM-YYYY')
    } else if (endDate) {
      const aYearBeforeEndDate = moment(
        moment(endDate).set('year', endDate.get('year') - 1)
      )
      return aYearBeforeEndDate.format('DD-MMMM-YYYY')
    } else {
      const aYearBeforeToday = moment().set('year', moment().get('year') - 1)
      return aYearBeforeToday.format('DD-MMMM-YYYY')
    }
  }
  const getEndDate = () => {
    if (endDate) {
      return moment(endDate).format('DD-MMMM-YYYY')
    } else if (startDate) {
      let aYearAfterStartDate = moment(
        moment(startDate).set('year', startDate.get('year') + 1)
      )
      if (aYearAfterStartDate > moment()) aYearAfterStartDate = moment()
      return aYearAfterStartDate.format('DD-MMMM-YYYY')
    } else {
      const today = moment()
      return today.format('DD-MMMM-YYYY')
    }
  }

  return (
    <div>
      <Button
        className={classes.graphBtn}
        variant='contained'
        color='primary'
        startIcon={<TableChart className={classes.tableGraphIcon} />}
        onClick={handleOpenDialog}
        disabled={
          !tableDataNew ||
          !filteredAlarmNotificationsMSGS ||
          filteredAlarmNotificationsMSGS?.length === 0
        }>
        {t('btn.table')}
      </Button>
      {open && (
        <Dialog
          maxWidth='lg'
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <div className={classes.printWrapper} ref={tableRef}>
            <DialogTitle id='alert-dialog-title'>
              <Box className={classes.titleContainer}>
                <Box className={classes.titleTop}>
                  <Typography>{`${t(
                    'alarms.charts.startDate'
                  )} ${getStartDate()}`}</Typography>
                  <Typography>{`${t('alarms.charts.totalAlarms')} ${
                    filteredAlarmNotificationsMSGS.length
                  }`}</Typography>
                </Box>
                <Box className={classes.titleBottom}>
                  <Typography>{`${t(
                    'alarms.charts.endDate'
                  )} ${getEndDate()}`}</Typography>
                  <Box >
                    <IconButton
                      aria-label='save'
                      onClick={handleSaveGraphClick}>
                      <Download />
                    </IconButton>
                    <IconButton
                      aria-label='print'
                      onClick={handlePrintTableReport}>
                      <Print />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              {tableHeadersNew && tableDataNew && (
                //Mui-styles are not applied during printing, use inline styles instead
                <div className={classes.tableContainer}  ref={tableRefPrint}>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeader}
                          align='left'
                          style={{
                            padding: '5px',
                            textAlign: 'left',
                            border: '1px solid black',
                          }}>
                          {t('net.samplingPoint')}
                        </TableCell>
                        {tableHeadersNew.map(header => (
                          <TableCell
                            key={header}
                            className={classes.tableHeader}
                            style={{
                              padding: '5px',
                              textAlign: 'center',
                              border: '1px solid black',
                            }}
                          >
                            {header.toUpperCase()}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableDataNew.map((sp, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align='center'
                            style={{
                              padding: '5px',
                              textAlign: 'left',
                              border: '1px solid black',
                            }}>
                            {sp.label}
                          </TableCell>
                          {tableHeadersNew.map(header => (
                            <TableCell
                              key={header}
                              align='center'
                              style={{
                                padding: '5px',
                                textAlign: 'center',
                                border: '1px solid black',
                              }}>
                              {sp[header]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </DialogContent>
          </div>
          <DialogActions className={classes.dialogActions}>
            <Divider style={{ width: '100%' }} />
            <Button className={classes.blueBtn} onClick={handleCloseDialog} autoFocus>
              {t('btn.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
