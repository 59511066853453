import { makeStyles, createStyles, Theme, FormControlLabel, Checkbox, CheckboxProps } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryDisabled: {
      color: `${theme.palette.primary.disabled} !important`,
    },
    secondaryDisabled: {
      color: `${theme.palette.secondary.disabled} !important`,
    },
  })
)

export interface CheckBoxNtProps extends CheckboxProps {
  /** ❗Custom prop: If label is provided as a prop the component will render a <FormControlLabel/> with a <CheckBox/> */
  label?: string
  /** ❗Custom prop: Determines the FormControlLabel's placement, defaults to 'end', will be ignored if no `label` prop is provided */
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top'
}

/**
 * Renders a material-ui < Checkbox /> with adjusted styles
 *
 * props:
 *
 * `label`: If label is provided as a prop the component will render a <FormControlLabel/> with a <CheckBox/>
 *
 * `labelPlacement`: Determines the FormControlLabel's placement, defaults to 'end', will be ignored if no `label` prop is provided.
 *
 * 👉 also accepts all material-ui default < Checkbox /> props (`checked`, `onChange`, etc)
 *
 * ❗Apart from `labelPlacement` & `label` no other < FormControlLabel /> props are spread,
 * so in case you need to use FormControlLabel with additional properties/styles, you should import it from material-ui
 * and use CheckBoxNt inside FormControlLabel's control prop
 */

export default function CheckBoxNt(props: CheckBoxNtProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const color = props.color ? props.color : 'primary'
  const labelPlacement = props.labelPlacement ? props.labelPlacement : 'end'

  if (!!props.label) {
    return (
      <FormControlLabel
        disabled={props.disabled}
        control={
          <Checkbox
            {...props}
            color={color}
            classes={{ disabled: props.disabled && props.checked ? classes[`${color}Disabled`] : '' }}
          />
        }
        label={t(props.label)}
        labelPlacement={labelPlacement}
      />
    )
  } else {
    return (
      <Checkbox
        {...props}
        color={color}
        classes={{ disabled: props.disabled && props.checked ? classes[`${color}Disabled`] : '' }}
      />
    )
  }
}
