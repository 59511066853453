import React, { useState, useEffect } from 'react'
// import { useCollectionStore } from '../../state/collectionStore'
// import { useCommonStore } from '../../state/commonStore'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  IconButton,
  Card,
  CardActionArea,
  Tooltip,
  OutlinedInput,
  CircularProgress,
  Divider,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import WrmIcon from '../WrmIcon'
import { useTranslation } from 'react-i18next'
import { Attachment } from '../../utils/types/collectionStoreTypes'
import MdAttachmentBtn from './MdAttachmentBtn'
import MdConfirmationDialog from './MdConfirmationDialog'
import moment from 'moment'
import { greekToEnglish } from '../../utils/helpers'
import { User } from '../../utils/types/collectionStoreTypes'
const API_URL = process.env.REACT_APP_WRM_API_URL

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentsRoot: {
      display: 'flex',
      flexFlow: 'column',
    },
    uploadBtnContainerOuter: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 0',
      justifyContent: 'space-between',
    },
    uploadBtnContainerInner: {
      marginLeft: 'auto',
    },
    attachmentRow: {
      display: 'grid',
      gridTemplateColumns: '2fr 7fr 1fr',
      marginBottom: '0.5rem',
      width: '100%',
    },
    card: {
      backgroundColor: '#EDEDED',
      height: '110px',
      width: '90px',
      margin: '5px 10px 5px 5px',
    },
    cardarea: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    cardfilename: {
      backgroundColor: '#FFFFFF',
      fontSize: '0.75rem',
      width: '100%',
      height: 34,
    },
    cardicon: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    attachmentsContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexFlow: 'column',
      maxHeight: 'calc(100vh - 280px)',
      overflowY: 'auto',
      position: 'relative',
    },
    cardActionContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    cardTextContainer: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    dialogPaperRoot: {
      minWidth: '500px',
      maxWidth: '600px',
    },
    renameDialogInputContainer: {
      display: 'flex',
      flexFlow: 'column',
      margin: '1rem 1rem 0.5rem 1rem',
    },
    inputTypographyBody: {
      padding: '0 0.25rem',
    },
    input: {
      padding: '0.5rem 0.75rem',
    },
    outlinedInput: {
      width: '300px',
      marginLeft: '2rem',
    },
    outlinedInputMultiline: {
      width: '300px',
      marginLeft: '2rem',
      padding: '0 !important',
    },
    inputContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexFlow: 'column',
    },
    inputContainerInner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: '0.5rem',
    },
    dialogTitleRoot: {
      textAlign: 'left',
      whiteSpace: 'pre-line',
      paddingBottom: 0,
      paddingLeft: '1rem',
    },
    dialogContent: {
      padding: 0,
    },
    dialogActions: {
      paddingTop: 0,
    },
    spinner: {
      width: '100px !important',
      height: '100px !important',
      position: 'absolute',
      right: '50%',
      top: '50%',
    },
  })
)

const ALL_ACCEPTED_EXTENSIONS = '.pdf, .doc, .docx, .txt, .jpg, .jpeg, .png, .zip'
const BYTES_IN_KILOBYTES = 1024

interface MdAttachmentsProps {
  selectedDataItem: any
  attachments?: any
  /** defaults to all acceppted extensions: -> '.pdf, .doc, .docx, .txt, .jpg, .jpeg, .png, .zip' */
  attachmentAcceptedExtensions?: string
  actSaveDetailAttachment: (entity: any, attachment: any) => any
  actDeleteDetailAttachment: (entity: any, attachment: any) => any
  maximumNumberOfAttachmentsPerElement: number
  showAlert: (message: string, severity?: 'E' | 'W' | 'S' | 'I' | undefined) => void
  users: User[]
  noEditAttachment?: boolean
}

const MdAttachments: React.FC<MdAttachmentsProps> = ({
  selectedDataItem,
  attachments,
  attachmentAcceptedExtensions = ALL_ACCEPTED_EXTENSIONS,
  actSaveDetailAttachment,
  actDeleteDetailAttachment,
  maximumNumberOfAttachmentsPerElement = -1,
  showAlert,
  users,
  noEditAttachment = false,
}) => {
  // const users = useCollectionStore(state => state.users)
  // const showAlert = useCommonStore(state => state.showAlert)
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editAttachmentDialogOpen, setEditAttachmentDialogOpen] = useState(false)
  const [fileNameVal, setFileNameVal] = useState('')
  const [descriptionVal, setDescriptionVal] = useState('')
  const [currentAttachment, setCurrentAttachment] = useState<Attachment | null>(null)
  /** newAttachments are attachments that the user has just loaded from the browser (not yet uploaded to db) */
  const [newAttachments, setNewAttachments] = useState<any[]>([])

  const handleOpenConfirmDeleteAttachmentDialog = (attachment: Attachment) => {
    setCurrentAttachment(attachment)
    setDeleteDialogOpen(true)
  }
  const handleCloseConfirmDeleteAttachmentDialog = () => {
    setCurrentAttachment(null)
    setDeleteDialogOpen(false)
  }

  const handleOpenEditAttachmentDialog = (attachment: Attachment) => {
    setCurrentAttachment(attachment)
    setFileNameVal(greekToEnglish(attachment.filename) || '')
    setDescriptionVal((attachment?.description as string) || '')
    setEditAttachmentDialogOpen(true)
  }

  const handleCloseEditAttachmentDialog = () => {
    setCurrentAttachment(null)
    setEditAttachmentDialogOpen(false)
    setFileNameVal('')
    setDescriptionVal('')
    setNewAttachments([])
  }

  const handleDeleteAttachment = (attachment: Attachment) => {
    setLoading(true)
    actDeleteDetailAttachment(selectedDataItem, attachment)
      .then(res => {
        showAlert(t('irriForm.attachments.delete.success'))
      })
      .catch(err => {
        showAlert(t('irriForm.attachments.delete.error', { val: err?.message }))
      })
      .finally(res => {
        setCurrentAttachment(null)
        setLoading(false)
        setDeleteDialogOpen(false)
      })
  }
  /**Will be triggered both by saving the edits of an existing attachment, and when the user uploads a new attachment */
  const handleSaveAttachmentEdits = (attachment: Attachment, newFileName: string, newDescription: string) => {
    //save new attachment edits:
    if (!!newAttachments.length) {
      return handleSaveNewAttachment()
    }
    //save an existing attachment edits:
    const newFileNameWithExtension = newFileName?.endsWith(`.${attachment.type}`)
      ? newFileName
      : `${newFileName}.${attachment.type}`
    // if (attachments.findIndex(a => greekToEnglish(a.filename) === greekToEnglish(newFileNameWithExtension)) !== -1) {
    //   return showAlert(t('irriForm.attachments.duplicate.error', { val: newFileName.trim() }), 'W')
    // }
    setLoading(true)
    if (!!newFileName.trim()) {
      attachment.filename = newFileNameWithExtension
    }
    attachment.description = newDescription.trim()

    return actSaveDetailAttachment(selectedDataItem, attachment)
      .then(res => {
        showAlert(t('irriForm.attachments.save.success'), 'S')
      })
      .catch(err => {
        showAlert(t('irriForm.attachments.save.error', { val: err?.message }), 'E')
      })
      .finally(res => {
        setLoading(false)
        setCurrentAttachment(null)
        setFileNameVal('')
        setDescriptionVal('')
        setEditAttachmentDialogOpen(false)
      })
  }

  const handleSaveNewAttachment = () => {
    const attachment = newAttachments[0]
    attachment.ownerCode = selectedDataItem.code
    attachment.description = descriptionVal
    const newFileNameWithExtension = fileNameVal?.trim()?.endsWith(`.${attachment.type}`)
      ? fileNameVal
      : `${fileNameVal}.${attachment.type}`
    if (!!fileNameVal.trim() && newFileNameWithExtension !== attachment.filename) {
      attachment.filename = newFileNameWithExtension.trim()
    }
    // if (attachments.findIndex(a => greekToEnglish(a.filename) === greekToEnglish(newFileNameWithExtension)) !== -1) {
    //   return showAlert(t('irriForm.attachments.duplicate.error', { val: attachment.filename }), 'W')
    // }
    setLoading(true)
    actSaveDetailAttachment(selectedDataItem, newAttachments[0])
      .then(res => {
        showAlert(t('irriForm.attachments.save.success'), 'S')
      })
      .catch(err => {
        showAlert(t('irriForm.attachments.save.error', { val: err?.message }), 'E')
      })
      .finally(() => {
        setFileNameVal('')
        setDescriptionVal('')
        setNewAttachments([])
        setLoading(false)
        setEditAttachmentDialogOpen(false)
      })
  }

  const getUserName = (user_id: string) => {
    const creator = users?.find(el => el.user_id === user_id)
    if (!creator?.auth0User || !user_id) {
      return '-'
    } else {
      return creator.auth0User.given_name + creator.auth0User.family_name
    }
  }

  /**When the user selects a file to upload, open the editAttachmentDialog */
  useEffect(() => {
    if (!!newAttachments.length) {
      setFileNameVal(greekToEnglish(newAttachments[0].filename))
      setDescriptionVal('')
      setEditAttachmentDialogOpen(true)
    }
  }, [newAttachments])

  return (
    <Box className={classes.attachmentsRoot}>
      <Box className={classes.uploadBtnContainerOuter}>
        {maximumNumberOfAttachmentsPerElement >= 0 ? (
          <Tooltip
            title={
              t('irriForm.attachments.maximumAttachments', {
                current: selectedDataItem?.attachments?.length || 0,
                max: maximumNumberOfAttachmentsPerElement,
              }) as string
            }
            placement='top'>
            <Box className={classes.uploadBtnContainerInner}>
              <MdAttachmentBtn
                showAlert={showAlert}
                attachments={newAttachments}
                setAttachments={setNewAttachments}
                acceptedFileExtensions={attachmentAcceptedExtensions}
                disabled={
                  noEditAttachment ||
                  (maximumNumberOfAttachmentsPerElement > -1 &&
                    selectedDataItem?.attachments?.length >= maximumNumberOfAttachmentsPerElement)
                }
              />
            </Box>
          </Tooltip>
        ) : (
          <Box className={classes.uploadBtnContainerInner}>
            <MdAttachmentBtn
              showAlert={showAlert}
              attachments={newAttachments}
              setAttachments={setNewAttachments}
              acceptedFileExtensions={attachmentAcceptedExtensions}
              disabled={
                noEditAttachment ||
                (maximumNumberOfAttachmentsPerElement > -1 &&
                  selectedDataItem?.attachments?.length >= maximumNumberOfAttachmentsPerElement)
              }
            />
          </Box>
        )}
      </Box>
      <Box className={classes.attachmentsContainer}>
        {loading && <CircularProgress className={classes.spinner} />}
        {attachments?.length > 0 &&
          attachments.map((a: Attachment) => (
            <Box key={a._id} className={classes.attachmentRow}>
              <Tooltip title={a.filename} placement='top'>
                <Card className={classes.card}>
                  <CardActionArea
                    href={`${API_URL}attachments/${a.attachment}`}
                    target='_blank'
                    className={classes.cardarea}>
                    <Box display='flex' alignItems='center' justifyContent='center' className={classes.cardicon}>
                      <WrmIcon
                        style={{ fontSize: '2.2rem' }}
                        icon={['jpg', 'jpeg', 'png'].includes(a.type.toLowerCase()) ? 'imageIcon' : 'docIcon'}
                      />
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      py={0.5}
                      className={classes.cardfilename}>
                      {a.filename.length > 14 ? a.filename.substring(0, 12) + '..' : a.filename}
                    </Box>
                  </CardActionArea>
                </Card>
              </Tooltip>
              <Box className={classes.cardTextContainer}>
                <Typography align='left' variant='body2'>
                  {t('irriForm.attachments.description', { val: a.description })}
                </Typography>
                <Typography align='left' variant='body2'>
                  {t('irriForm.attachments.size', { val: !!a.size ? Math.round(a.size / BYTES_IN_KILOBYTES) : '??' })}
                </Typography>
                <Typography align='left' variant='body2'>
                  {t('irriForm.attachments.createdBy', { val: getUserName(a?.addedBy as string) })}
                </Typography>
                <Typography align='left' variant='body2'>
                  {t('irriForm.attachments.createdAt', { val: moment(a.createdAt).format(t('date.format')!) })}
                </Typography>
                {a.modifiedBy && (
                  <>
                    <Typography align='left' variant='body2'>
                      {t('irriForm.attachments.modifiedBy', { val: getUserName(a?.modifiedBy as string) })}
                    </Typography>
                    <Typography align='left' variant='body2'>
                      {t('irriForm.attachments.updatedAt', { val: moment(a.updatedAt).format(t('date.format')!) })}
                    </Typography>
                  </>
                )}
              </Box>
              <Box className={classes.cardActionContainer}>
                <IconButton disabled={noEditAttachment || loading} aria-label='edit' onClick={() => handleOpenEditAttachmentDialog(a)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  disabled={noEditAttachment || loading}
                  aria-label='delete'
                  onClick={() => handleOpenConfirmDeleteAttachmentDialog(a)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
      </Box>
      {/* Delete attachmentDialog*/}
      {!!currentAttachment && (
        <MdConfirmationDialog
          isOpen={deleteDialogOpen}
          setDialogOpen={setDeleteDialogOpen}
          yesBtnClickCallback={handleDeleteAttachment.bind(this, currentAttachment)}
          noBtnClickCallback={handleCloseConfirmDeleteAttachmentDialog}
          title={t('irriForm.attachments.delete.dialog.title')}
          itemName={t('irriForm.attachments.delete.dialog.content', { val: currentAttachment?.filename })}
          customClasses={{ dialogPaperRoot: classes.dialogPaperRoot }}
          showAlert={showAlert}
          btnYesColor='primary'
          btnYesVariant='outlined'
        />
      )}
      {/* Edit attachmentDialog - will be triggered when adding a new attachment/or when editing an existing attachment*/}
      {(!!currentAttachment || !!newAttachments.length) && (
        <MdConfirmationDialog
          isOpen={editAttachmentDialogOpen}
          setDialogOpen={setEditAttachmentDialogOpen}
          yesBtnClickCallback={() =>
            handleSaveAttachmentEdits(currentAttachment as Attachment, fileNameVal, descriptionVal)
          }
          noBtnClickCallback={handleCloseEditAttachmentDialog}
          title={t('irriForm.attachments.edit.dialog.title')}
          itemName={''}
          customClasses={{
            dialogPaperRoot: classes.dialogPaperRoot,
            dialogTitleRoot: classes.dialogTitleRoot,
            dialogContent: classes.dialogContent,
            dialogActions: classes.dialogActions,
          }}
          btnYesText={!!newAttachments.length ? 'btn.save' : 'btn.update'}
          btnNoText='btn.cancel'
          btnYesVariant='outlined'
          submitBtnDisabled={!fileNameVal?.trim() || loading}
          showAlert={showAlert}
          btnYesColor='primary'>
          <Divider />
          <Box className={classes.renameDialogInputContainer}>
            <Box className={classes.inputContainer}>
              <Box className={classes.inputContainerInner}>
                <Typography className={classes.inputTypographyBody} variant='body1'>
                  {t('irriForm.attachments.edit.rename')}*
                </Typography>
                <OutlinedInput
                  classes={{ input: classes.input }}
                  className={classes.outlinedInput}
                  value={fileNameVal}
                  onChange={e => setFileNameVal(greekToEnglish(e.target.value))}
                />
              </Box>
              <Box className={classes.inputContainerInner}>
                <Typography className={classes.inputTypographyBody} variant='body1'>
                  {t('irriForm.attachments.edit.dialog.discription')}
                </Typography>
                <OutlinedInput
                  multiline
                  classes={{ input: classes.input }}
                  className={classes.outlinedInputMultiline}
                  value={descriptionVal}
                  onChange={e => setDescriptionVal(e.target.value)}
                />
              </Box>
              <Alert severity='info'>{t('irriForm.attachments.filename.restriction')}</Alert>
            </Box>
          </Box>
          <Divider />
        </MdConfirmationDialog>
      )}
    </Box>
  )
}

export default MdAttachments
