import { makeStyles, createStyles, Theme, Radio, RadioProps } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.custom.grey2,
      userSelect: 'none' /* Standard syntax */,
      '-webkit-user-select': 'none' /* WebKit (Safari, Chrome) */,
      '-moz-user-select': 'none' /* Mozilla Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
    },
    primaryDisabledChecked: {
      color: `${theme.palette.primary.disabled} !important`,
    },
    primaryDisabled: {
      color: `${theme.palette.tertiary.disabled} !important`,
    },
    secondaryDisabledChecked: {
      color: `${theme.palette.secondary.disabled} !important`,
    },
    secondaryDisabled: {
      color: `${theme.palette.tertiary.disabled} !important`,
    },
  })
)

export interface RadioBtnNtProps extends RadioProps {
  color?: 'primary' | 'secondary'
}

/**
 * Renders a material-ui `<Radio/>` with adjusted styles
 *
 * All props are the default props for the material-ui `<Radio/>`
 */

export function RadioBtnNt(props: RadioBtnNtProps) {
  const classes = useStyles()

  const color = props.color ? props.color : 'primary'

  return (
    <Radio
      {...props}
      color={color}
      classes={{
        root: classes.root,
        disabled: !!props.checked ? classes[`${color}DisabledChecked`] : classes[`${color}Disabled`],
      }}
    />
  )
}

export default RadioBtnNt
