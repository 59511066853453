import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Select, MenuItem, Typography, FormControl, makeStyles, Theme, createStyles, Box } from '@material-ui/core'
import { SUPPORTED_DATE_FORMATS } from '../../utils/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateFormatRoot: {
      width: '100%',
    },
    selectInput: {
      paddingTop: 6,
      paddingBottom: 6,
      fontSize: 14,
      textAlign: 'left',
    },
    formControl: {
      alignSelf: 'center',
    },
    title: {
      textAlign: 'left',
      fontWeight: 'bold',
      margin: '0.75rem 0 0.5rem 0',
      alignSelf: 'flex-start',
    },
    selectContainer: {
      display: 'flex',
      marginLeft: '1rem',
      alignItems: 'center',
    },
  })
)

interface DateFormatSettingsProps {
  handleDateFormatChange: (
    event: React.ChangeEvent<{
      value: string
    }>
  ) => void
  selectedDateFormat: string
}

const DateFormatSettings = ({ handleDateFormatChange, selectedDateFormat }: DateFormatSettingsProps) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.dateFormatRoot}>
      <Typography align='left' className={classes.title} component='h2'>
        {t('userSettings.dateFormat')}:
      </Typography>
      <Box className={classes.selectContainer}>
        <FormControl className={classes.formControl}>
          <Select
            variant='outlined'
            displayEmpty
            classes={{ root: classes.selectInput }}
            value={selectedDateFormat}
            onChange={handleDateFormatChange}
          >
            {SUPPORTED_DATE_FORMATS.map(dateFormat => (
              <MenuItem value={dateFormat} key={dateFormat}>
                {moment().format(dateFormat)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default DateFormatSettings
