import { forwardRef, RefObject } from 'react'
import { IconButton, makeStyles, Theme, createStyles } from '@material-ui/core'
import { Print } from '@material-ui/icons'
import { useReactToPrint, IReactToPrintProps } from 'react-to-print'
import PrintIconNt from '../mobile/svgIcons/PrintIconNt'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      '@media print': {
        display: 'none',
      },
    },
    icon: {
      color: 'black',
    },
    pringIconButton: {
      boxShadow: '0px 2px 4px 0px #00000040',
      backgroundColor: theme.palette.custom.grey4,
      height: 32,
      width: 32,
    },
  })
)

/**⚠️ In case we want to set the state (remember that setState is asynchronous) before printing
 * we have two ways to achieve this:
 * A) the recomended way with printOptions and an extra ref for the promise returned from the onBeforeGetContent
 * B) the `hacky` way using prePrintCallback & delay props
 * The example below should be inside the component that will handle the printing (the parent of < PrintButtonHDR >)
 */

/** A) the recomended way: */
// const printRef = useRef<HTMLDivElement>(null)
// const [isPrinting, setIsPrinting] = useState(false)
// const promiseResolveRef: React.MutableRefObject<any> = useRef(null)

// useEffect(() => {
//   if (isPrinting && promiseResolveRef.current) {
//     // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
//     promiseResolveRef?.current()
//   }
// }, [isPrinting])

// const printOptions:IReactToPrintProps = {
//   content:() => printRef.current,
//   //onBeforeGetContent must return a promise
//   onBeforeGetContent: () => {
//     return new Promise(resolve => {
//       promiseResolveRef.current = resolve
//       setIsPrinting(true)
//     })
//   },
//   onAfterPrint:()=>{
//     promiseResolveRef.current = null
//     setIsPrinting(false)
//   }
// }

// return (
// <>
//     <PrintButtonHDR printOptions={printOptions} />
//     <div ref={printRef}>
//         <SomeComponentThatWillBePrinted/>
//         {!isPrinting && <SomeComponentThatWeWantToHideDuringPrinitg/>}
//     </div>
// </>
// )

/** B) the hacky way prePrintCallback & delay: */
// const printRef = useRef<HTMLDivElement>(null)
// const [isPrinting, setIsPrinting] = useState(false)

// //`prePrintCallback` with `delay` achieves the same thing as printOptions.onBeforeGetContent
// const prePrintCallback = ()=> setIsPrinting(true)
// const printOptions:IReactToPrintProps = {
//   content:() => printRef.current,
//   onAfterPrint:()=>{
//     setIsPrinting(false)
//   }
// }

// return (
// <>
//     <PrintButtonHDR printOptions={printOptions} prePrintCallback={prePrintCallback} delay={50}/>
//     <div ref={printRef}>
//         <SomeComponentThatWillBePrinted/>
//         {!isPrinting && <SomeComponentThatWeWantToHideDuringPrinitg/>}
//     </div>
// </>
// )

export interface PrintButtonHDRProps {
  /**Defines the size of the IconButton and it's click area, defaults to medium */
  size?: 'medium' | 'small' | undefined
  /**Size of the Icon, defaults to 'medium'  */
  fontSize?: 'inherit' | 'medium' | 'default' | 'large' | 'small' | undefined
  /**Styles applied to the IconButton, default style has just one rule: '@media print': { display: 'none', },
   * that hides the button during printing, in case you provide your own css class here you'll have to
   * to add '@media print' rule to that class, default style and custom styles are not merged */
  buttonCSSclass?: any
  /**Styles passed to the Print Icon Component, defaults to icon: { color: 'black' }, */
  iconCSSClass?: any
  /**Options passed to the useReactToPrint, defaults to undefined
   * ⚠️ In case the printOptions are used the ref SHOULD NOT be passed as a prop on < PrintButtonHDR >,
   * instead the ref MUST be passed directly in the options in the following way:
   * eg: const printOptions:IReactToPrintProps = { content:() => printRef.current, //other options... }
   * < PrintButtonHDR printOptions={printOptions} />   */
  printOptions?: IReactToPrintProps
  /**Callback that will be fired just before the printing window opens, used in edge cases where you have to mutate some state
   * or use some other sideeffect before the printing occures,
   * ⚠️ IT SHOULD BE NOTED that the react-to-print has a similar build in functionallity printOptions.onBeforeGetContent
   * printOptions.onBeforeGetContent should not be used with prePrintCallback
   * (they both achieve the same functionality, pick either one, but not both!)   */
  prePrintCallback?: () => void
  /**Adds delay (milliseconds) before triggering the handlePrint() function, Recall that the react-to-print will print anyting on the screen
   * so in case we have animations (eg charts, finish rendering but have 1-2seconds of animation for the bars/pies to load)
   * then we need the delay the printing, alternatively we can use prePrintCallback with delay, for setting some state in the parent component before the print window opens*/
  delay?: number
}

/**PringButtonHDR requires the print container (the component along with it's children that will be printed)
 * to be a < div > element (Box,Paper and other MUI components won't work)
 * In case you have a MUI component that you want to print just wrap it in a < div ref={myPrintRef} > < SomeComponent /> < /div >
 * and create a ref inside the components body using the useRef hook eg: const myPrintRef = useRef< HTMLDivElement >(null)
 * finally pass the ref on the PrintButtonHDR eg: < PrintButtonHDR ref={myPrintRef} />
 * In case you need to use printOptions,  there is no need to attach the ref on the < div > 
 * since you need to pass it in the content option that is mandatory and it should always be a function that returns the ref.current
 * eg:  const printOptions:IReactToPrintProps = {
    content:() => myPrintRef.current,
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        setIsPrinting(true)
      })
    },
    onAfterPrint:()=>{
      setIsPrinting(false)
    },
    //...otherPrintOptions
} */
const PrintButtonHDR = forwardRef(
  (
    {
      size = 'medium',
      buttonCSSclass = undefined,
      iconCSSClass = undefined,
      printOptions = undefined,
      prePrintCallback,
      delay = 0,
    }: PrintButtonHDRProps,
    ref: RefObject<HTMLDivElement> | null //React.ForwardedRef<HTMLDivElement>
  ) => {
    const classes = useStyles()
    const startPrint = () => {
      if (!!prePrintCallback) {
        prePrintCallback()
        /**Use timeout so the state/sideEffects/animations can update before the printing window opens */
        setTimeout(() => {
          handlePrint()
        }, delay)
      } else {
        /**Use timeout so the state/sideEffects/animations can update before the printing window opens */
        setTimeout(() => {
          handlePrint()
        }, delay)
      }
    }

    const handlePrint = useReactToPrint(
      printOptions
        ? printOptions
        : {
            content: () => ref?.current || null,
          }
    )

    return (
      <IconButton
        aria-label='Print'
        className={buttonCSSclass ? buttonCSSclass : classes.pringIconButton}
        size={size}
        onClick={startPrint}
      >
        {/* <Print fontSize={fontSize} className={iconCSSClass ? iconCSSClass : classes.icon} /> */}
        <PrintIconNt style={{heigh:18,width:18}} className={iconCSSClass ? iconCSSClass : classes.icon} />
      </IconButton>
    )
  }
)

export default PrintButtonHDR
