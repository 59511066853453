/**
 * Reference:
 * Code is taken from the mapbox/geojson-area project
 */
const wgs84 = require('wgs84')

// module.exports.geometryArea = geometryArea
// module.exports.ring = ringArea

function geometryArea(_) {
  let area = 0
  switch (_.type) {
    case 'Polygon':
      return polygonArea(_.coordinates)
    case 'MultiPolygon':
      for (let i = 0; i < _.coordinates.length; i++) {
        area += polygonArea(_.coordinates[i])
      }
      break
    case 'Point':
    case 'MultiPoint':
    case 'LineString':
    case 'MultiLineString':
      area = 0
      break
    case 'GeometryCollection':
      for (let i = 0; i < _.geometries.length; i++) {
        area += geometryArea(_.geometries[i])
      }
      break
    default:
      area = 0
  }
  return area
}

function polygonArea(coords) {
  var area = 0
  if (coords && coords.length > 0) {
    area += Math.abs(ringArea(coords[0]))
    for (let i = 1; i < coords.length; i++) {
      area -= Math.abs(ringArea(coords[i]))
    }
  }
  return area
}

/**
 * Calculate the approximate area of the polygon were it projected onto
 *     the earth.  Note that this area will be positive if ring is oriented
 *     clockwise, otherwise it will be negative.
 *
 * Reference:
 * Robert. G. Chamberlain and William H. Duquette, "Some Algorithms for
 *     Polygons on a Sphere", JPL Publication 07-03, Jet Propulsion
 *     Laboratory, Pasadena, CA, June 2007 http://trs-new.jpl.nasa.gov/dspace/handle/2014/40409
 *
 * Returns:
 * (float) The approximate signed geodesic area of the polygon in square meters.
 */
function ringArea(coords) {
  var p1, p2, p3, lowerIndex, middleIndex, upperIndex, i,
  area = 0,
  coordsLength = coords.length

  if (coordsLength > 2) {
    for (i = 0; i < coordsLength; i++) {
      if (i === coordsLength - 2) {// i = N-2
        lowerIndex = coordsLength - 2
        middleIndex = coordsLength -1
        upperIndex = 0
      } else if (i === coordsLength - 1) {// i = N-1
        lowerIndex = coordsLength - 1
        middleIndex = 0
        upperIndex = 1
      } else { // i = 0 to N-3
        lowerIndex = i
        middleIndex = i+1
        upperIndex = i+2
      }
      p1 = coords[lowerIndex]
      p2 = coords[middleIndex]
      p3 = coords[upperIndex]
      area += ( rad(p3[0]) - rad(p1[0]) ) * Math.sin( rad(p2[1]))
    }
    area = area * wgs84.RADIUS * wgs84.RADIUS / 2
  }
  return area
}

function rad(_) {
  return _ * Math.PI / 180
}
/** transforms a pair of coordinates from decimal degrees to Degrees/Minutes/Seconds 
 *  used only for display purposes */
function toDMS(dd: number[], decimalPrecision?: number) {
  if (typeof decimalPrecision !== 'number') {
    decimalPrecision = 4
  }
  //latitude
  var deg0 = Math.floor(dd[0])
  var min0 = Math.floor((dd[0]-deg0)*60)
  var sec0 = ((((dd[0]-deg0)*60)-min0)*60).toFixed(decimalPrecision)
  //longitude
  var deg1 = Math.floor(dd[1])
  var min1 = Math.floor((dd[1]-deg1)*60)
  var sec1 = ((((dd[1]-deg1)*60)-min1)*60).toFixed(decimalPrecision)
  if (dd[2]) return [deg0+"°"+min0+"'"+sec0+'"',deg1+"°"+min1+"'"+sec1+'"',dd[2]]
  return [deg0+"°"+min0+"'"+sec0+'"',deg1+"°"+min1+"'"+sec1+'"']
}

export default {
  geometryArea,
  ringArea,
  toDMS
}
