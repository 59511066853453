import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/el'

import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
// import { useNetworkElementStore } from '../state/networkElementStore'
// import { useUiStore } from '../state/uiStore'

const useLocaleHandler = () => {

  // const userSettings = useCollectionStore(state =>state.userSettings)
  // const tenantSettings = useCollectionStore(state => state.tenantSettings)
  const appSettings = useCollectionStore(state => state.appSettings)

  const { t, i18n } = useTranslation()
  const locale = useCommonStore(state => state.locale)
  const setLocale = useCommonStore(state => state.setLocale)
  if (!locale) {
    let i18nextLng: 'el' | 'en' | undefined = localStorage.getItem('i18nextLng') === 'el' ? 'el' : 'en'
    let loc = moment.locale(i18nextLng ?? 'el')
    console.log(`moment locale set to ${loc}`)
    setLocale(i18nextLng)
  }

  /**Change locale and dateFormat based on appSettings.locale and appSettings.dateFormat */
  useEffect(()=>{
    if (!!appSettings.locale) {
      const currentLocale = i18n.language
      const userSettinsLocale = appSettings.locale
      if (currentLocale !== userSettinsLocale) {
        i18n.changeLanguage(userSettinsLocale)
        let loc = moment.locale(userSettinsLocale)
        console.log(`moment locale set to ${loc}`)
        setLocale(userSettinsLocale)
      }
    }
    if (!!appSettings.dateFormat) {
      const currentLocale = appSettings.locale || i18n.language
      const userDateFormat = appSettings.dateFormat
      const userDateFormatPickers = appSettings.dateFormat.replaceAll('MMM','MM')
      /**Displays the empty label placeholder characters in the picker, doesn't affect actual dates */
      const formatByLocale =(dFormat:string, timeSuffix="") => {
        let result = dFormat.toLowerCase()
        let resultSuffix = timeSuffix
        if (currentLocale === 'el') {
          result = result.replaceAll('d','η')
          result = result.replaceAll('m','μ')
          result = result.replaceAll('y','ε')
          resultSuffix = resultSuffix.replaceAll('H','ω')
          resultSuffix = resultSuffix.replaceAll('h','ω')
          resultSuffix = resultSuffix.replaceAll('m','λ')
          resultSuffix = resultSuffix.replaceAll('s','δ')
        }
        return result + resultSuffix
      }
      const removeYearFromDate = (dFormat:string, timeSuffix="")=> {
        let result = dFormat.replaceAll('Y','')
        if (result.startsWith('/') || result.startsWith('-') || result.startsWith(' ')) {
          result = result.slice(1)
        }
        if (result.endsWith('/') || result.endsWith('-') || result.endsWith(' ')) {
          result = result.slice(0,-1)
        }
        return result + timeSuffix
      }
      i18n.addResource(currentLocale, 'translation', 'date.format', userDateFormat)
      i18n.addResource(currentLocale, 'translation', 'date.format.pickers', userDateFormatPickers)
      i18n.addResource(currentLocale, 'translation', 'date.format.short', userDateFormat.replaceAll('YYYY','YY'))
      i18n.addResource(currentLocale, 'translation', 'datetime.format', userDateFormat + ' HH:mm:ss')
      i18n.addResource(currentLocale, 'translation', 'datetime.format.pickers', userDateFormatPickers + ' HH:mm:ss')
      i18n.addResource(currentLocale, 'translation', 'datetime.format.noSecs', userDateFormatPickers + ' HH:mm')
      i18n.addResource(currentLocale, 'translation', 'datetime.format.noYear.noSecs', removeYearFromDate(userDateFormat, ' HH:mm'))
      i18n.addResource(currentLocale, 'translation', 'datetime.format.noYear', removeYearFromDate(userDateFormat, ' HH:mm:ss'))
      i18n.addResource(currentLocale, 'translation', 'date.format.emptyLabel', formatByLocale(userDateFormatPickers))
      i18n.addResource(currentLocale, 'translation', 'date.format.pickers.emptyLabel', formatByLocale(userDateFormatPickers))
      i18n.addResource(currentLocale, 'translation', 'datetime.format.emptyLabel', formatByLocale(userDateFormatPickers, ' HH:mm:ss'))
      i18n.addResource(currentLocale, 'translation', 'datetime.format.pickers.emptyLabel', formatByLocale(userDateFormatPickers, ' HH:mm:ss'))
      i18n.addResource(currentLocale, 'translation', 'datetime.format.noSecs.emptyLabel', formatByLocale(userDateFormatPickers, ' hh:mm'))
    }
  }, [appSettings?.locale, appSettings?.dateFormat])
  
}

export default useLocaleHandler