import {create} from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  AgriculturalPeriod,
  Alarm,
  Personnel,
  User,
  Role,
  Right,
  License,
  Tenant,
  Cultivation,
  IrriProg,
  IrriSched,
  IrriEvent,
  IrriForm,
  Consumer,
  UserSettings,
  TenantSettings,
  AppSettings,
  SwitchSlot,
  Notification,
  Activity,
  Procedure,
  MaintenancePlan,
  NetworkRepair,
  RepairType,
  // IrriSoft,
} from '../utils/types/collectionStoreTypes'

interface CollectionStore {
  activities: Activity[] | undefined
  agriculturalPeriods: AgriculturalPeriod[] | undefined
  /**The Alarms that trigger the notifications when certain conditions are met (eg Quality Control Indicator Alarms) */
  alarms: Alarm[] | undefined
  /**This is a temporary collection, currently it is used to display the notifications in the Notifications Log (Bell Icon from Vertical Navigation -> Log tab)
   * each time the user selects a different filter a new request is made to the server and the collection is populated with new values based on the filter */
  filteredNotifications: Notification[]
  appSettings: AppSettings
  assets: any[] | undefined
  consumers: Consumer[] | undefined
  /**Consumers/Cultivator's Notifications, this notifications are loaded when the Consumer Portal is opened
   * in case the User is a Consumer consumerNotifications will be the notifications that the given consumer has recieved
   * in case the User is an Operator and opens the Consumer Portal, then consumerNotifications will be all notifications that have been sent to the Consumers */
  consumerNotifications: any[],
  cultivations: Cultivation[] | undefined
  deviceLinks: any[] | undefined
  groupItems: any[] | undefined
  indicators: any[] | undefined
  irriCommands: any[] | undefined
  irriEvents: IrriEvent[] | undefined
  irriForms: IrriForm[] | undefined
  irriProgs: IrriProg[] | undefined
  irriScheds: IrriSched[] | undefined
  // irriSofts: IrriSoft[] | undefined
  licenses: License[] | undefined
  /** maintenancePlans are added manually to the store!!! */
  maintenancePlans: MaintenancePlan[] | undefined
  measurementSeries: any[] | undefined
  measurementTypes: any[] | undefined
  mseriesLinks: any[] | undefined
  mseries: any[] | undefined
  /** networkRepairs are added manually to the store!!! */
  networkRepairs: NetworkRepair[] | undefined
  personnels: Personnel[] | undefined
  /**Notifications for personnel (operators) */
  personnelNotifications: Notification[]
  populations: any[] | undefined
  /**Repair Types and Damages are added manually to the store!!!*/
  repairTypes: RepairType[] | undefined
  rights: Right[] | undefined
  roles: Role[] | undefined
  switchSlots: SwitchSlot[] | undefined
  procedures: Procedure[] | undefined
  tenants: Tenant[] | undefined
  tenantSettings: TenantSettings[] | undefined
  users: User[] | undefined
  userSettings: UserSettings[] | undefined
  waterLosses: any[] | undefined
  setItems: (key: string, items: any) => void
  addItem: (key: string, item: any) => void
  addItems: (key: string, items: any) => void
  updateItem: (key: string, item: any) => void
  deleteItem: (key: string, item: any) => void
}

const useCollectionStore = create<CollectionStore>()(
  devtools(set => ({
  activities: undefined,
  agriculturalPeriods: undefined,
  alarms: undefined,
  filteredNotifications:[],
  appSettings: {coordinatesSystem: 'wgs84'},
  assets: undefined,
  consumers: undefined,
  consumerNotifications: [],
  cultivations: undefined,
  deviceLinks: undefined,
  groupItems: undefined,
  indicators: undefined,
  irriCommands: undefined,
  irriEvents: undefined,
  irriForms: undefined,
  irriProgs: undefined,
  irriScheds: undefined,
  // irriSofts: undefined,
  licenses: undefined,
  maintenancePlans:undefined,
  measurementSeries: undefined,
  measurementTypes: undefined,
  mseriesLinks: undefined,
  mseries: undefined,
  networkRepairs: undefined,
  personnels: undefined,
  personnelNotifications: [],
  populations: undefined,
  repairTypes: undefined,
  rights: undefined,
  roles: undefined,
  switchSlots: undefined,
  procedures: undefined,
  tenants: undefined,
  tenantSettings: undefined,
  users: undefined,
  userSettings: undefined,
  waterLosses: undefined,
  setItems: (key, items) =>
    set(state => {
      let part = {}
      part[key] = items
      return part
    }),
  addItem: (key, item) =>
    set(state => {
      let part = {}
      part[key] = state[key] ?? []
      part[key] = part[key].concat(item)
      return part
    }),
  addItems: (key, items) =>
    set(state => {
      let part = {}
      part[key] = state[key] ?? []
      part[key] = part[key].concat(items)
      return part
    }),
  updateItem: (key, item) =>
    set(state => {
      let part = {}
      part[key] = state[key] ?? []
      part[key] = part[key].map(it => (it._id === item._id ? item : it))
      return part
    }),
  deleteItem: (key, item) =>
    set(state => {
      let part = {}
      part[key] = state[key] ?? []
      part[key] = part[key].filter(it => it._id !== item._id)
      return part
    }),
}),{name: "collectionStore", enabled: process.env.NODE_ENV === 'development' ? true : false})
)

export { useCollectionStore }
