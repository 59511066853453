import { Theme, createTheme } from '@material-ui/core'
import {
  Palette,
  PaletteColor,
  PaletteColorOptions,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles/createPalette'
import { useCommonStore } from './state/commonStore'
import { elGR } from './utils/material_el_v4'

interface ExtendedPaletteColorOptions extends SimplePaletteColorOptions {
  darker?: string
}

interface ExtendedPaletteOptions extends PaletteOptions {
  primary: ExtendedPaletteColorOptions
  secondary: ExtendedPaletteColorOptions
  // text: Partial<TypeText>
  // error: PaletteColorOptions
  // warning: PaletteColorOptions
  // info: ExtendedPaletteColorOptions
  // success: ExtendedPaletteColorOptions
  // And your custom palette options if you defined them, e.g:
  // brand: ExtendedPaletteColorOptions
}

declare module '@material-ui/core/styles/createPalette' {
  interface ExtendedPaletteOptions {
    custom: {
      grey1: string
      grey2: string
      grey3: string
      grey4: string
      grey5: string
      grey3Disabled: string
      transparent: string
    }
    tertiary: {
      main: string
      dark: string
      light: string
      disabled: string
    }
  }
  interface Palette {
    custom: {
      grey1: string
      grey2: string
      grey3: string
      grey4: string
      grey5: string
      grey3Disabled: string
      transparent: string
    }
    tertiary: {
      /**Legacy secondary.main '#979797'*/
      main: string
      /**Legacy secondary.dark '#313131'*/
      dark: string
      /**Legacy secondary.light '#DEDEDE'*/
      light: string
      /**Used for disabled outlined buttons '#99999966' (Legacy secondary.main with 40% opacity) */
      disabled: string
    }
  }
  interface PaletteColor {
    /** #DAFFFF (primary) */
    lighter?: string
    /**'#F0FFFF' (primary) */
    lightest?: string
    /**primary/secondary.main with 40% opacity */
    disabled?: string
  }
}

export const themeOptions = {
  overrides: {
    MuiListItem: {
      root: {
        '&$selected': {
          // backgroundColor: palette.primary.selected,
        },
      },
    },
  },
  palette: {
    custom: {
      grey1: '#707070',
      grey2: '#999999',
      grey3: '#e1e1e1',
      grey4: '#F6F6F6',
      grey5: '#FBFBFB',
      grey3Disabled: '#e1e1e166',
      transparent: '#00000000',
    },
    primary: {
      main: '#5DCFCF',
      light: '#65DEDE',
      dark: '#3CADAD',
      contrastText: '#000000',
      lighter: '#DAFFFF',
      lightest: '#F0FFFF',
      disabled: '#5dcfcf66',
    },
    secondary: {
      main: '#965DCF', //<-tetradic https://www.canva.com/colors/color-wheel/
      dark: '#694190',
      light: '#ab7dd8',
      disabled: '#965dcf66', //<-tetradic
      contrastText: '#ffffff',
    },
    tertiary: {
      main: '#979797',
      dark: '#313131',
      light: '#DEDEDE',
      disabled: '#99999966',
    },
    success: {
      main: '#5DCFCF',
    },
    info: {
      main: '#3CADAD',
    },
    error: {
      main: '#EC4941',
      /**error.disabled is an exception instead of 40% opacity on the main color it applies 20% */
      disabled: '#ec494133',
    },
    warning: {
      main: '#F5992b',
      disabled: '#F5992b66',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem', //20px;
      lineHeight: 1.167,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1rem', //16px,
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem', //14px,
      lineHeight: 1.167,
      letterSpacing: 0,
    },
    // Body1: {
    /**Body1 is the same as default MUI theme */
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 400,
    //   fontSize: '1rem', //16px,
    //   lineHeight: 1.5,
    //   letterSpacing: '0.00938em',
    // },
    // Body2: {
    /**Body2 is the same as default MUI theme */
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 400,
    //   fontSize: '0.875rem', //14px,
    //   lineHeight: 1.43,
    //   letterSpacing: '0.01071em',
    // },
    // caption: {
    /**The Caption is the same as the default MUI theme */
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 400,
    //   fontSize: '0.75rem', //12px,
    //   lineHeight: 1.66,
    //   letterSpacing: '0.03333em',
    // },
  },
}

let locale = useCommonStore.getState().locale as string
if (!locale) {
  locale = localStorage.getItem('i18nextLng') === 'el' ? 'el' : 'en'
}

let customTheme: Theme
if (locale === 'el') {
  customTheme = createTheme(themeOptions, elGR)
} else {
  customTheme = createTheme(themeOptions)
}

export default customTheme
