import React, { useState } from 'react'
import MeasurementsManager from '../../services/MeasurementsManager'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import WrmIcon from '../WrmIcon'
import SetSeriesScadaCode from './SetSeriesScadaCode'
import { useCommonStore } from '../../state/commonStore'

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'hidden',
    width: '100%',
  },
  propsRoot: {
    overflowY: 'auto',
  },
  editBtn: {
    padding: '6px 8px',
  },
  editIcon: {
    width: 20,
    height: 20,
  },
  bottomDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const SCADADeviceCodes = (props) => {
  const measurementsManager = MeasurementsManager()
  const classes = useStyles()
  const { t } = useTranslation()

  const locale = useCommonStore(state => state.locale)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [selectedSeriesIndex, setSelectedSeriesIndex] = useState(-1)
  const [selectedMeasurementType, setSelectedMeasurementType] = useState(null)


  const handleOnEditClick = (i, mt) => {
    setSelectedSeriesIndex(i)
    setSelectedMeasurementType(mt)
    setOpenEditDialog(true)
  }

  const handleCloseEditDialog = (series) => {
    if (!!series) {
      measurementsManager.actSaveSeries(series).then(
        res => {
          setOpenEditDialog(false)
        }
        , error => {
          console.log(`handleCloseDialog error : ${JSON.stringify(error)}`)
        }
      )
    } else {
      setOpenEditDialog(false)
    }
  }

  const getScadaCodeByMSType = (msType)=>{
    let s = props.series ? props.series.find(s=>(!!s.measurementType._id?s.measurementType._id===msType._id:s.measurementType===msType._id)) : null
    return !!s&&!!s.scadaDeviceCode?s.scadaDeviceCode:t(`msr.series.scadacode.undefined`)
  }

  const getSeriesIndexByMSType = (msType)=>{
    let sIndex = props.series ? props.series.findIndex(s=>(!!s.measurementType._id?s.measurementType._id===msType._id:s.measurementType===msType._id)) : -1
    //console.log(`getSeriesIndexByMSType : ${sIndex} for msType ${JSON.stringify(msType)}`)
    return sIndex
  }

  const buildNewSeries = (msType)=>{
    let newSeries={
      featureCode: props.feature.properties.code,
      featureType: props.feature.properties.featureType,
      measurementType: msType,
      scadaDeviceCode:''
    }
    return newSeries
  }

  const Item = iprops => {
    return (
      <ListItem
        className='item'
      >
        <ListItemText
          className='txt lab'
          primary={iprops.name}
        />
        <ListItemText
          className='txt val'
          primary={iprops.value}
        />
        {!!iprops.editable &&
        <IconButton
          className={classes.editBtn}
          onClick={()=>{handleOnEditClick(iprops.seriesIndex, iprops.msType)}}
        >
          <WrmIcon icon='edit' className={classes.editIcon} />
        </IconButton>}
      </ListItem>

    )
  }
  return (

    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      className={clsx(classes.root, 'tankPropList')}
    >
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
        className={classes.propsRoot}
      >
        {props.measurementTypes
          .filter(mt => {
            let b = mt.featureType === props.feature.properties.featureType
            if (b && props.feature.properties.featureType === 'switch') {
              let mtypePrefix = props.feature.properties.switchType === 'digital' ? 'plcHour' : 'switchHour'
              let mtNo = parseInt(mt.code.substring(mtypePrefix.length))
              b = props.feature.properties.keyCount && props.feature.properties.keyCount >= mtNo
            }
            return b
          })
          .map(mt => {
            mt.translatedLabel = t(`msr.fld.${mt.featureType === 'switch' ? 'irrigation.' : ''}${mt.code}`, mt.label)
            return mt
          })
          .sort((a, b) => a.translatedLabel.localeCompare(b.translatedLabel, locale, {ignorePunctuation: true}))
          .map(mt => (
        <List
          className='tankProperties' key={mt._id}
        >
          <ListItem className='item headerItem'>
            <WrmIcon
              icon={'meter'}
            />
            <ListItemText
              className='txt'
              primary={mt.translatedLabel}
            />
          </ListItem>
          <Divider variant='middle' />
          <Item
            name={t(`msr.series.scadacode`)}
            value={getScadaCodeByMSType(mt)}
            seriesIndex={getSeriesIndexByMSType(mt)}
            editable={!props.noEditTelemeters && mt.featureType !== 'switch'}
            msType={mt}
          />
        </List>
        ))}
      </Box>

      <Divider
        variant='middle'
        className={classes.bottomDivider}
      />

      {!!openEditDialog && <SetSeriesScadaCode
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        selectedSeries={selectedSeriesIndex !== -1 ? props.series[selectedSeriesIndex] : buildNewSeries(selectedMeasurementType)}
      />}

    </Box>
  )

}
export default SCADADeviceCodes
