import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Box,
  Button,
} from '@material-ui/core/'
import turfCirclePolygon from '@turf/circle'
import booleanIntersects from '@turf/boolean-intersects'

import { useUiStore } from '../../state/uiStore'
import { useCommonStore } from '../../state/commonStore'
import useReproject from '../../utils/useReproject'
import useFeatureCollection from '../../hooks/useFeatureCollection'
import SearchByLocation from './SearchByLocation'
import SearchByFeatureProps from './SearchByFeatureProps'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import {
  NetworkElementsFilter,
  LocationFilter,
  AttributesFilter,
  HighlightedFeatures,
} from '../../utils/types/mapStoreTypes'
import { FTC, FEATURE_PROPERTIES, HIGHLIGHTED_FEATURES, DEFAULT_NETWORK_ELEMENTS_FILTER } from '../../utils/constants'
import { GenericFeature } from '../../utils/types/networkElementTypes'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '50vw',
  },
  dialogTitle: {
    fontSize: '1rem',
    fontWeight: 'normal',
    margin: '0 auto',
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 'normal',
    minWidth: 90,
    maxWidth: 250,
    color: theme.palette.tertiary.main,
  },
  checkboxContainerMain: {
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnClearFilter: {
    width: theme.spacing(12),
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'none',
    color: theme.palette.tertiary.main,
    maxHeight: '2.2rem',
  },
  buttonContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
  },
  divider: {
    border: 'none',
    height: '1px',
    margin: '8px 0',
    width: '100%',
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  btnCancel: {
    width: theme.spacing(12),
    marginLeft: 8,
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'none',
    color: theme.palette.tertiary.main,
  },
  btnApply: {
    width: theme.spacing(12),
    marginLeft: 8,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

export default function EntitiesFilterDialog(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const R = useReproject()
  const networkElementsFilter = useUiStore(state => state.networkElementsFilter)
  const setFeatureFilter = useUiStore(state => state.setNetworkElementsFilter)
  const showAlert = useCommonStore(state => state.showAlert)
  const [locationFilterChecked, setLocationFilterChecked] = useState(
    !networkElementsFilter.locationFilter.isActive && !networkElementsFilter.attributesFilter.isActive
      ? true
      : networkElementsFilter.locationFilter.isActive
  )
  const [attributeFilterChecked, setAttributeFilterChecked] = useState(networkElementsFilter.attributesFilter.isActive)
  const featureCollections = useFeatureCollection()

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = e.target.name
    if (name === 'locationSearch') {
      if (!attributeFilterChecked && !checked) return //prevent the user from unchecking both filters
      setLocationFilterChecked(checked)
    } else if (name === 'attributesSearch') {
      if (!locationFilterChecked && !checked) return //prevent the user from unchecking both filters
      setAttributeFilterChecked(checked)
    }
  }

  const handleCloseDialog = () => {
    props.setOpen(false)
  }

  /*Search By Location state/methods */
  const [coordinatesSystem, setCoordinatesSystem] = useState<string>(
    networkElementsFilter.locationFilter.coordinatesSystem || 'wgs84'
  )
  const [longitude, setLongitude] = useState<string>(networkElementsFilter.locationFilter.longitude || '')
  const [latitude, setLatitude] = useState<string>(networkElementsFilter.locationFilter.latitude || '')
  const [lngDegrees, setLngDegrees] = useState<any>(
    networkElementsFilter.locationFilter.lngDegrees || { d: '', m: '', s: '' }
  )
  const [latDegrees, setLatDegrees] = useState<any>(
    networkElementsFilter.locationFilter.latDegrees || { d: '', m: '', s: '' }
  )
  const [radius, setRadius] = useState<string>(networkElementsFilter.locationFilter.radius || '')
  const [wgsUnits, setWgsUnits] = useState<string>(networkElementsFilter.locationFilter.wgsUnits || 'decimalDegrees')

  const validateCoordinates = () => {
    let valid = true
    let msgs: string[] = []
    if (coordinatesSystem === 'wgs84' && wgsUnits === 'dms') {
      console.log(`coordinatesSystem === 'wgs84' && wgsUnits === 'dms'`)
      //longitude D/M/S
      if (+lngDegrees.d > 180 || +lngDegrees.d < -180 || lngDegrees.d === '') {
        msgs.push(t('units.dmsLng.d.error'))
        valid = false
      }
      if (+lngDegrees.m < 0 || +lngDegrees.m > 59.9999 || lngDegrees.m === '') {
        msgs.push(t('units.dmsLng.m.error'))
        valid = false
      }
      if (+lngDegrees.s < 0 || +lngDegrees.s > 59.9999 || lngDegrees.s === '') {
        msgs.push(t('units.dmsLng.s.error'))
        valid = false
      }
      //latitude D/M/S
      if (+latDegrees.d > 90 || +latDegrees.d < -90 || latDegrees.d === '') {
        msgs.push(t('units.dmsLat.d.error'))
        valid = false
      }
      if (+latDegrees.m < 0 || +latDegrees.m > 59.9999 || latDegrees.m === '') {
        msgs.push(t('units.dmsLat.m.error'))
        valid = false
      }
      if (+latDegrees.s < 0 || +latDegrees.s > 59.9999 || latDegrees.s === '') {
        msgs.push(t('units.dmsLat.s.error'))
        valid = false
      }
    }
    //wgs84 Decimal Degrees
    else if (coordinatesSystem === 'wgs84' && wgsUnits === 'decimalDegrees') {
      console.log(`coordinatesSystem === 'wgs84' && wgsUnits === 'decimalDegrees'`)
      if (+longitude > 180 || +longitude < -180 || longitude === '') {
        msgs.push(t('units.dmsLng.d.error'))
        valid = false
      }
      if (+latitude > 90 || +latitude < -90 || latitude === '') {
        msgs.push(t('units.dmsLat.d.error'))
        valid = false
      }
    }
    //ΕΓΣΑ87
    else if (coordinatesSystem === 'egsa87') {
      console.log(coordinatesSystem === 'egsa87')
      if (longitude.split('.')[0].length !== 6 || longitude === '') {
        msgs.push(t('units.egsa87.lng.error'))
        valid = false
      }
      if (latitude.split('.')[0].length !== 7 || latitude === '') {
        msgs.push(t('units.egsa87.lat.error'))
        valid = false
      }
    }
    if (!radius || +radius < 1) {
      console.log(`!radius || +radius < 1 || +radius > 5000`)
      msgs.push(t('units.radius.error'))
      valid = false
    }
    if (msgs.length > 0) {
      showAlert(msgs.join('\n'), 'E')
    }
    console.log(valid)
    return valid
  }

  const handleCoordinateSystemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name !== coordinatesSystem) {
      //change the WGS48 units to degrees, so that UI doesn't break
      setWgsUnits('decimalDegrees')
      setLatitude('')
      setLongitude('')
      setCoordinatesSystem(e.target.name)
    } else {
      setCoordinatesSystem(e.target.name)
    }
  }
  const handleWgs84UnititsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name !== wgsUnits) {
      setLatitude('')
      setLongitude('')
      setLngDegrees({ d: '', m: '', s: '' })
      setLatDegrees({ d: '', m: '', s: '' })
    }
    setWgsUnits(e.target.name)
  }

  /** Filters out all the elements that are outside of the given circle (center,radius)
   * @param filter (optional) if provided, instead of perfoming a booleanIntersects with every feature in the collection,
   * will do it only for the features in the filter. The filter should be a HighlightedFeatures type (see mapStoreTypes.ts)
   */
  const setHighlightedFeaturesFromCircle = (
    lng: string | number,
    lat: string | number,
    radius: string | number,
    filter?: HighlightedFeatures | undefined
  ) => {
    //create circle Polygon
    const center = [+lng, +lat]
    const circleRadius = +radius / 1000 //meters to km, the default unit of turf circle
    const options = { steps: 360 } //increases the polygons compexity (more vertices) but imporves spatial accuracy
    const turfCircle = turfCirclePolygon(center, circleRadius, options)
    let filteredValues: any = {}
    //use booleanIntersects to highlight features that intersect with the circle
    Object.values(FTC).forEach(ftc => {
      let fc = ftc.collection
      // const shouldUseFilter = !filter ? false : true
      filteredValues[fc] = []
      const theFeatureCollection = !filter
        ? featureCollections[fc]
        : featureCollections[fc].filter(el => filter[fc].includes(el.properties.code))
      theFeatureCollection?.forEach((f: GenericFeature) => {
        if (booleanIntersects(turfCircle, f.geometry)) {
          filteredValues[fc].push(f.properties.code)
        }
      })
    })
    return filteredValues
  }

  const generateLocationFilter = (filter: undefined | HighlightedFeatures = undefined) => {
    //If all location fields are empty reset form and remove filters
    if (
      (wgsUnits !== 'dms' && !longitude && !latitude && !radius) ||
      (coordinatesSystem === 'wgs84' &&
        wgsUnits === 'dms' &&
        !lngDegrees.d &&
        !lngDegrees.m &&
        !lngDegrees.s &&
        !latDegrees.d &&
        !latDegrees.m &&
        !latDegrees.s &&
        !radius)
    ) {
      handleClearLocationFilter()
      const updatedFeatureFilter = {
        ...networkElementsFilter,
        //if the attributesFilter isActive then the filter will remain 'active' otherwise isActive will be set to false
        isActive: networkElementsFilter.attributesFilter.isActive,
        locationFilter: { ...DEFAULT_NETWORK_ELEMENTS_FILTER.locationFilter },
      }
      console.log(`⤴️🆙updatedFeatureFilter`)
      console.log(updatedFeatureFilter)
      setFeatureFilter(updatedFeatureFilter)
      handleCloseDialog()
      return
    }
    //check if location filter is valid
    if (!validateCoordinates()) return

    let lat
    let lng
    switch (coordinatesSystem) {
      case 'egsa87':
        if (!longitude || !latitude) return
        const transformedCoords = R([+longitude, +latitude], 'EPSG:2100', 'EPSG:4326')
        lng = transformedCoords[0]
        lat = transformedCoords[1]
        break
      case 'wgs84':
        if (wgsUnits === 'decimalDegrees') {
          lat = latitude
          lng = longitude
        } else {
          lat = +latDegrees.d + +latDegrees.m / 60 + +latDegrees.s / 3600
          lng = +lngDegrees.d + +lngDegrees.m / 60 + +lngDegrees.s / 3600
          setLatitude(lat.toString())
          setLongitude(lng.toString())
        }
        break
      default:
        break
    }

    let data: LocationFilter = {
      center: [lat, lng],
      radius,
      isActive: true,
      highlightedFeatures: setHighlightedFeaturesFromCircle(lng, lat, radius, filter),
      coordinatesSystem,
      longitude,
      latitude,
      lngDegrees,
      latDegrees,
      wgsUnits,
    }

    return data
  }

  const handleClearLocationFilter = () => {
    setLatitude('')
    setLongitude('')
    setRadius('')
    setLngDegrees({ d: '', m: '', s: '' })
    setLatDegrees({ d: '', m: '', s: '' })
  }

  /**Search by Attributes state/methods */
  const [featuresFilterLocal, setFeaturesFilterLocal] = useState<any>()
  const [metadata, setMetadata] = useState<any>()
  const [lookups, setLookups] = useState<any>()

  const handleFeatureFilterChange = (featureType: string, featureTypeFilter: any) => {
    let filter = { ...featuresFilterLocal }
    filter[featureType] = { ...featureTypeFilter }
    filter[featureType].checked = featuresFilterLocal[featureType].checked
    filter[featureType].expanded = featuresFilterLocal[featureType].expanded
    setFeaturesFilterLocal(filter)
  }

  const handleCheckOrExpandedChange = (featureType: string, field: string, value: any) => {
    let filter = { ...featuresFilterLocal }
    filter[featureType][field] = value
    setFeaturesFilterLocal(filter)
  }

  const generateAttributeFilter = () => {
    let filter = JSON.parse(JSON.stringify(featuresFilterLocal))
    filter.isActive = Object.keys(metadata)
      .filter(k => k !== 'generic')
      .some(k => filter[k].checked)
    applyFilter(filter, metadata)
    /**Add the missing highlightedFeatures (empty arrays) to the attributesFilter */
    // const filterWithHighlighted = {highlightedFeatures: FeatureFilterDefault.highlightedFeatures, ... }
    const highlightedFeatures = {
      ...DEFAULT_NETWORK_ELEMENTS_FILTER.attributesFilter.highlightedFeatures,
      ...filter.highlightedFeatures,
    }
    const resultTEST = { ...filter, highlightedFeatures }
    return { ...filter, highlightedFeatures } as AttributesFilter
  }

  const handleClearAttributeFilter = () => {
    let clearedFilter = JSON.parse(
      JSON.stringify({
        ...DEFAULT_NETWORK_ELEMENTS_FILTER.attributesFilter,
        hideFeatures: networkElementsFilter.hideFeatures,
      })
    )
    setFeaturesFilterLocal(enhanceFilter(clearedFilter, metadata))
  }
  useEffect(() => {
    let tlookups: any = { BooleanValues: BooleanValues }
    let tmetadata: any = enhanceMetadata(gatherMetadata(), tlookups)
    let tfilter: any = enhanceFilter(JSON.parse(JSON.stringify(networkElementsFilter.attributesFilter)), tmetadata)
    setMetadata(tmetadata)
    setFeaturesFilterLocal(tfilter)
    setLookups(tlookups)
  }, [])

  const BooleanValues = [
    { code: true, label: t('btn.yes') },
    { code: false, label: t('btn.no') },
  ]
  const gatherMetadata = () => {
    const FEATURE_PROPERTIES_X = JSON.parse(JSON.stringify(FEATURE_PROPERTIES))
    let metadata: any = {
      generic: FEATURE_PROPERTIES_X['hydrant'].groups.reduce(
        (flds, g: any) => flds.concat(g.fields.filter(fld => fld.filterableGeneric)),
        []
      ),
    }
    Object.keys(FTC)
      .sort((fta, ftb) => {
        let a = t(`net.${fta}`)
        let b = t(`net.${ftb}`)
        return a > b ? 1 : a < b ? -1 : 0
      })
      .forEach(ft => {
        metadata[ft] = FEATURE_PROPERTIES_X[ft].groups.reduce(
          (flds, g) => flds.concat(g.fields.filter(fld => fld.filterable)),
          []
        )
      })
    return metadata
  }
  const enhanceMetadata = (metadata, lookups) => {
    console.log(`enhanceMetadata`)
    // Object.values(metadata).forEach((ft_flds: any[]) => {
    Object.entries(metadata).forEach((entry: any[]) => {
      let ft_key: string = entry[0]
      let ft_flds: any[] = entry[1]
      ft_flds.forEach((mt: any) => {
        if (mt.type === 'boolean') {
          mt.lookup = ['BooleanValues']
        } else if (mt.type === 'selectionList') {
          mt.multiselect = true
          mt.isAutocomplete = true
          let lookupName = `${ft_key}.${mt.code}Lookup`
          mt.lookup = [lookupName]
          if (!lookups[lookupName]) {
            lookups[lookupName] = Object.values(mt.values).map((c: any) => {
              let li = { code: c.code, label: t(`${c.transKey}.${c.code}`) }
              return li
            })
          }
        } else if (mt.type === 'dynamicSelectionList') {
          mt.multiselect = true
          mt.isAutocomplete = true
          mt.lookupField = 'properties.code'
          mt.lookupFetch = ['properties.label']
          mt.featureTypeField = 'lala'
          mt.lookup = []
          mt.featureTypes.forEach(ft => {
            let lookupName = `${ft}Lookup`
            mt.lookup.push(lookupName)
            if (!lookups[lookupName]) {
              lookups[lookupName] = featureCollections[ft] ? featureCollections[ft] : null
            }
          })
        } else if (mt.type === 'groupedDynamicSelectionList' && !mt.groupedBy) {
          mt.multiselect = true
          mt.isAutocomplete = true
          mt.lookupField = 'properties.code'
          mt.lookupFetch = ['properties.label']
          mt.featureTypeField = 'lala'
          mt.lookup = []

          let groupByMeta = FEATURE_PROPERTIES[ft_key].groups.reduce(
            (flds, g: any) => flds.concat(g.fields.filter(fld => fld.aggregates === mt.code)),
            []
          )
          if (groupByMeta.length > 0) {
            groupByMeta = groupByMeta[0]
            groupByMeta.selectionListGroups.forEach(fg => {
              let lookupName = `${fg.values}Lookup`
              mt.lookup.push(lookupName)
              if (!lookups[lookupName]) {
                lookups[lookupName] = featureCollections[fg.values] ? featureCollections[fg.values] : null
              }
            })
          }
        }
      })
    })
    return metadata
  }
  const enhanceFilter = (filter, metadata) => {
    console.log(`enhanceFilter`)
    if (!filter.isEnhanced) {
      Object.keys(metadata).forEach(feature_key => {
        let ftFilter = filter[feature_key]
        metadata[feature_key].forEach((m: any) => {
          if (m.multiselect) {
            ftFilter[m.code] = !!m.filter ? m.filter : []
          } else if (['date', 'datetime', 'number', 'posnumber'].includes(m.type)) {
            ftFilter[`${m.code}_from`] = ''
            ftFilter[`${m.code}_to`] = ''
          } else {
            ftFilter[m.code] = ''
          }
        })
      })
      filter.isEnhanced = true
    }
    return filter
  }

  const applyFilter = (filter, metadata) => {
    let filteredValues = {}
    Object.values(FTC)
      .filter(ftc => filter[ftc.code].checked)
      .forEach(ftc => {
        let fc = ftc.collection
        filteredValues[fc] = []
        let ftFilter = filter[ftc.code]
        // if (ftFilter.checked) {
        filteredValues[fc] = featureCollections[fc]
          .filter(f => {
            let fok = true
            fok = fok && applyFilterToFeature(f, filter['generic'], metadata['generic'])
            fok = fok && applyFilterToFeature(f, ftFilter, metadata[ftc.code])
            return fok
            /*
          let feok = Object.entries(ftFilter)
          .filter(e => !!e[1] && !['checked','expanded'].includes(e[0]))
          .every(e => {
            let bf = true
            if (e[0].endsWith('_from')) {
              let p = e[0].lastIndexOf('_from')
              let fld = e[0].substring(0, p)
              let meta = metadata[ftc.code].find(m => m.code === fld)
              let val: any = e[1]
              if (['number','posnumber'].includes(meta.type)) {
                val = parseFloat(val)
              } else if (['date','datetime'].includes(meta.type)) {
                val = new Date(val)//TODO better do it with moment
              }
              bf = f.properties[fld] >= val
            } else if (e[0].endsWith('_to')) {
              let p = e[0].lastIndexOf('_to')
              let fld = e[0].substring(0, p)
              let meta = metadata[ftc.code].find(m => m.code === fld)
              let val: any = e[1]
              if (['number','posnumber'].includes(meta.type)) {
                val = parseFloat(val)
              } else if (['date','datetime'].includes(meta.type)) {
                val = new Date(val)//TODO better do it with moment
              }
              bf = f.properties[fld] <= val
            } else {
              let fld = e[0]
              let meta = metadata[ftc.code].find(m => m.code === fld)
              let val: any = e[1]
              if (meta.multiselect) {
                let ok = val.length === 0 || val.includes(f.properties[fld])
                if (ok) {
                  console.log(`${ftc.code} ${f.properties.code} ${fld} ${f.properties[fld]} is included in [${val.join()}]`)
                }
                bf = val.length === 0 || val.includes(f.properties[fld])
              } else {
                bf = f.properties[fld] && f.properties[fld].toLowerCase().indexOf(val.toLowerCase()) > -1
              }
            }
            return bf
          })
          return feok
          */
          })
          .map(f => {
            return f.properties.code
          })
        // }
      })
    filter.highlightedFeatures = filteredValues
  }
  /*
    pump:
    expanded: true
    motorType: "0"
    power_from: ""
    power_to: ""
    pumpType: "lala"
    stationCode: ['ST21111514']
    volumePerHour_from: "1"
    volumePerHour_to: "10"
  */
  const applyFilterToFeature = (feature: any, ftFilter: any, ftMetadata: any): boolean => {
    let fok: boolean = Object.entries(ftFilter)
      .filter(e => !!e[1] && !['checked', 'expanded'].includes(e[0]))
      .every(e => {
        let bf = true
        if (e[0].endsWith('_from')) {
          let p = e[0].lastIndexOf('_from')
          let fld = e[0].substring(0, p)
          let meta = ftMetadata.find(m => m.code === fld)
          let val: any = e[1]
          if (['number', 'posnumber'].includes(meta.type)) {
            val = parseFloat(val)
          } else if (['date', 'datetime'].includes(meta.type)) {
            val = new Date(val) //TODO better do it with moment
          }
          bf = feature.properties[fld] >= val
        } else if (e[0].endsWith('_to')) {
          let p = e[0].lastIndexOf('_to')
          let fld = e[0].substring(0, p)
          let meta = ftMetadata.find(m => m.code === fld)
          let val: any = e[1]
          if (['number', 'posnumber'].includes(meta.type)) {
            val = parseFloat(val)
          } else if (['date', 'datetime'].includes(meta.type)) {
            val = new Date(val) //TODO better do it with moment
          }
          bf = feature.properties[fld] <= val
        } else {
          let fld = e[0]
          let meta = ftMetadata.find(m => m.code === fld)
          let val: any = e[1]
          if (meta.multiselect) {
            bf = val.length === 0 || val.includes(feature.properties[fld])
            /** In case the user has selected a zone from the Generic Tab
             * When looping through the features if the featureType is 'wszone' and fld === 'wszoneCode'
             * use 'code' instead of 'wszoneCode' since the property 'wszoneCode' doesn't exist in wszones */
            if (!bf && fld === 'wszoneCode') {
              bf = feature.properties.featureType === 'wszone' && val.includes(feature.properties.code)
            }
            if (bf) {
              // console.log(`${feature.properties.featureType} ${feature.properties.code} ${fld} ${feature.properties[fld]} is included in [${val.join()}]`)
            }
          } else {
            bf = feature.properties[fld] && feature.properties[fld].toLowerCase().indexOf(val.toLowerCase()) > -1
          }
        }
        return bf
      })
    return fok
  }

  const handleApplyFilters = () => {
    if (attributeFilterChecked && locationFilterChecked) {
      //#case1: both filters are checked-> set the both filters
      /**Since the attribute filter is less costly in terms of calculations
       * the attribute filter will be calculated first
       * the location filter will be applied only to the features that are filtered out by the attribute filter
       * if the location filter returns undefined we ignore it
       */
      const newAttributesFilter = generateAttributeFilter()
      const newLocationFilter = generateLocationFilter(
        newAttributesFilter.isActive
          ? {
              ...HIGHLIGHTED_FEATURES,
              ...newAttributesFilter.highlightedFeatures,
            }
          : undefined
      )
      const updatedFeatureFilter: NetworkElementsFilter = {
        ...networkElementsFilter,
        locationFilter: newLocationFilter?.isActive
          ? newLocationFilter
          : DEFAULT_NETWORK_ELEMENTS_FILTER.locationFilter,
        attributesFilter: newAttributesFilter?.isActive
          ? newAttributesFilter
          : DEFAULT_NETWORK_ELEMENTS_FILTER.attributesFilter,
        hideFeatures: networkElementsFilter.hideFeatures,
        isActive: newLocationFilter?.isActive || newAttributesFilter?.isActive,
        highlightedFeatures: newLocationFilter?.highlightedFeatures || newAttributesFilter.highlightedFeatures,
      }
      setFeatureFilter(updatedFeatureFilter)
      handleCloseDialog()
    } else if (locationFilterChecked) {
      //case#2: Only location filter is checked-> set the location filter
      const newLocationFilter = generateLocationFilter()
      if (newLocationFilter) {
        setFeatureFilter({
          ...networkElementsFilter,
          locationFilter: newLocationFilter,
          attributesFilter: DEFAULT_NETWORK_ELEMENTS_FILTER.attributesFilter,
          highlightedFeatures: newLocationFilter.highlightedFeatures,
          isActive: true,
        })
        handleCloseDialog()
      }
    } else if (attributeFilterChecked) {
      //case#3: Only attributes filter is checked-> set the attribute filter
      const newAttributesFilter = generateAttributeFilter()
      const updatedFeatureFilter = {
        ...networkElementsFilter,
        attributesFilter: newAttributesFilter,
        highlightedFeatures: newAttributesFilter.highlightedFeatures,
        locationFilter: DEFAULT_NETWORK_ELEMENTS_FILTER.locationFilter,
        isActive: newAttributesFilter.isActive,
      }
      setFeatureFilter(updatedFeatureFilter)
      handleCloseDialog()
    }
  }

  return (
    <Dialog
      open={props.open}
      classes={{ paperScrollPaper: classes.root }}
      onClose={(event, reason) => (reason === 'backdropClick' ? null : handleCloseDialog)}
    >
      <DialogTitle disableTypography id='filter-dialog-title'>
        <h2 className={classes.dialogTitle}>{t('md.filters.title')}</h2>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <Box className={classes.checkboxContainerMain}>
            <CheckBoxNt checked={locationFilterChecked} onChange={handleCheckBoxChange} name='locationSearch' label={'btn.locationSearch'}/>
            <Button
              className={classes.btnClearFilter}
              disabled={!locationFilterChecked}
              onClick={handleClearLocationFilter}
              variant='outlined'
            >
              {t('btn.clear')}
            </Button>
          </Box>

          <Box className={classes.checkboxContainerMain}>
            <CheckBoxNt checked={attributeFilterChecked} onChange={handleCheckBoxChange} name='attributesSearch' label={'btn.attributesSearch'}/>
            <Button
              className={classes.btnClearFilter}
              onClick={handleClearAttributeFilter}
              disabled={!attributeFilterChecked}
              variant='outlined'
            >
              {t('btn.clear')}
            </Button>
          </Box>
        </Box>
        <Divider />
        {locationFilterChecked && (
          <SearchByLocation
            coordinatesSystem={coordinatesSystem}
            longitude={longitude}
            latitude={latitude}
            lngDegrees={lngDegrees}
            latDegrees={latDegrees}
            radius={radius}
            wgsUnits={wgsUnits}
            setLongitude={setLongitude}
            setLatitude={setLatitude}
            setLngDegrees={setLngDegrees}
            setLatDegrees={setLatDegrees}
            setRadius={setRadius}
            handleCoordinateSystemChange={handleCoordinateSystemChange}
            handleWgs84UnititsChange={handleWgs84UnititsChange}
          />
        )}
        {attributeFilterChecked && (
          <SearchByFeatureProps
            handleCheckOrExpandedChange={handleCheckOrExpandedChange}
            handleFeatureFilterChange={handleFeatureFilterChange}
            featuresFilterLocal={featuresFilterLocal}
            metadata={metadata}
            lookups={lookups}
          />
        )}
        <Box className={classes.buttonContainer}>
          <Divider className={classes.divider} />
          <Box display='flex' width='100%' justifyContent='flex-end'>
            <Button className={classes.btnCancel} onClick={handleCloseDialog} variant='outlined'>
              {t('btn.cancel')}
            </Button>
            <Button className={classes.btnApply} onClick={handleApplyFilters}>
              {t('btn.apply')}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
