import i18n from 'i18next'
import moment from 'moment'
import * as _ from 'lodash'

import { useCommonStore } from '../state/commonStore'
import { useCollectionStore } from '../state/collectionStore'
import { useNetworkElementStore } from '../state/networkElementStore'
import { useUiStore } from '../state/uiStore'
import { buildErrorMessage } from '../utils/helpers'
import { DEFAULT_NO_OF_DAYS_MEASUREMENTS_FILTER } from '../utils/constants'
import { GenericFeature, Switch } from '../utils/types/networkElementTypes'
import { Consumer } from '../utils/types/collectionStoreTypes'
import { FTC } from '../utils/constants'
import MeasurementService from './MeasurementService'
import MeasurementSeriesService from './MeasurementSeriesService'
import MsrmService from './MsrmService'
import MseriesService from './MseriesService'

/* All  the commented out methods in MeasurementsManager are used internally,
if the need to expose them to some other component arrives just uncomment the method 
in MeasurementsManagerType interface and in the return of the MeasurementsManager  */
interface MeasurementsManagerType {
  actDeleteMeasurement: (measurement: any, entity: any) => any
  getFeatureSeries: (feature: GenericFeature | Switch, needsRefresh?: boolean, initialFilter?: any) => any
  getFeatureMeasurements: (feature: GenericFeature, needsRefresh?: boolean, initialFilter?: any) => any
  getFeatureMeasurementsForMultipleFeatures: (
    features: GenericFeature[],
    needsRefresh?: boolean,
    initialFilter?: any,
    parentFeature?: GenericFeature,
    consumer?: Consumer
  ) => Promise<string>
  getMultiFeatureMeasurementsByMeasurementType: (
    measurementTypeId: string,
    featureCodes: string[],
    mfilter: any,
    sortedBy?: string
  ) => any
  getSeriesReport: (seriesIds: Array<string>, mfilter: any, reportType: any, timeUnit?: any) => any
  getSeriesAggregatedMeasurements: (seriesId: any, mfilter: any, timeUnit?: any) => any
  actSaveSeries: (theUpdatedSeries: any, feature?: any) => any
  actSaveMeasurement: (measurement: any, entity: any, measurementTypeId?: any) => Promise<any>
  actSubmitHydrometerConsumption: (measurement: any, hydrometer: any, entity?: any) => Promise<any>
  actSetMeasurementsFilter: (feature: any, seriesId: any, filter: any) => any
  actSetGroupOfMeasurementsFilter: (feature: any, updatedFilter: any) => Promise<string>
  actSetMeasurementsFilterToFeatures: (featureCodes: any, updatedFilter: any) => Promise<string>
  loadConsumptions: (_userProfile: any, mtypes: any) => void
  getDefaultFilter: (options?: any) => {
    refDatetime: {
      $gte: Date
      $lte: Date
    }
  }
  setMeasurementSeries: (series: any) => void
  // checkSeriesPromiseExecutor(selectedEntity: any, measurementTypeId: any, measurementSeriesId: any): (resolve: (value: unknown) => void, reject: (reason?: any) => void) => void
  // getConsumption: (feature: any, mtypes: any) => any
  // getConsumptionSeries: (feature: any, mtypes: any) => any
  // validateMeasurement: (m: any) => boolean
  // getSeriesMeasurements: (
  //   feature: any,
  //   seriesId: any,
  //   mfilter?: any,
  //   needsRefresh?: boolean,
  //   firstAndLastOnly?: any
  // ) => any
  getFeatureMsrms: (feature: GenericFeature, needsRefresh?: boolean, initialFilter?: any) => any
  getMseriesMsrms: (mseriesCode: string, filter?: any, needsRefresh?: boolean, mseri?: any) => any
}

/**A collection of helper methods dealing with measurement methods */
const MeasurementsManager = () => {
  const t = i18n.t
  const apiClient = useCommonStore.getState().apiClient
  const showAlert = useCommonStore.getState().showAlert
  const profile = useCommonStore.getState().profile
  const setProfile = useCommonStore.getState().setProfile
  const currentAgriculturalPeriod = useCommonStore.getState().currentAgriculturalPeriod
  const measurementSeriesService = MeasurementSeriesService(apiClient)
  const measurementService = MeasurementService(apiClient)
  const measurementSeries = useCollectionStore.getState().measurementSeries
  const measurementTypes = useCollectionStore.getState().measurementTypes
//   const mseries = useCollectionStore.getState().mseries || []
// // console.log(`MeasurementsManager mseries length ${mseries.length}`)
//   const mseriesLinks = useCollectionStore.getState().mseriesLinks || []
// // console.log(`MeasurementsManager mseriesLinks length ${mseriesLinks.length}`)
  const setCollectionItems = useCollectionStore.getState().setItems
  const updateCollectionItem = useCollectionStore.getState().updateItem
  
  const selectedEntity = useUiStore.getState().selectedEntity
  const setSelectedEntity = useUiStore.getState().setSelectedEntity
  const updateNetworkElement = useNetworkElementStore.getState().updateItem
  const setLoadingMultipleSeries = useUiStore.getState().setLoadingMultipleSeries

  const mseriesService = MseriesService()
  const msrmService = MsrmService()

  const setMeasurementSeries = series => {
    setCollectionItems('measurementSeries', series)
  }

  const validateMeasurement = m => {
    let result = true
    if (!!measurementSeries) {
      let theMsSeries = measurementSeries.find(ms => ms._id === m.series)
      if (!!theMsSeries) {
        let theMt = measurementTypes?.find(mt => mt.code === theMsSeries.measurementType.code)
        if (!!theMt) {
          theMt.valueTypes.forEach(vt => {
            let fieldValue = !vt.level || vt.level === '.' ? m[vt.fieldname] : m[vt.level][vt.fieldname]
            if (vt.datatype === 'Date' && !!fieldValue) {
              fieldValue = moment(fieldValue).startOf('day').format(t(`date.format`)!)
            }
            if (!vt.level || vt.level === '.' || !!vt.isRequired) {
              if (!fieldValue && !(vt.datatype === 'Boolean' || (vt.datatype === 'Number' && fieldValue === 0))) {
                showAlert(`${t('msr.mstable.required.value.missing')}: '${t(`msr.fld.${vt.fieldname}`)}'`, 'E')
                result = false
              }
            }
            if (result) {
              if (vt.datatype === 'Number') {
                if (vt.min != null && !!fieldValue && parseInt(fieldValue) < parseInt(vt.min)) {
                  showAlert(
                    `${t('msr.mstable.value.outOfRange.min')} (${t(`msr.fld.${vt.fieldname}`)} - ${vt.min})`,
                    'E'
                  )
                  result = false
                }
                if (result && !!fieldValue && !!vt.max && parseInt(fieldValue) > parseInt(vt.max)) {
                  showAlert(
                    `${t('msr.mstable.value.outOfRange.max')} (${t(`msr.fld.${vt.fieldname}`)} - ${vt.max})`,
                    'E'
                  )
                  result = false
                }
              }
              if (result && vt.fieldname === 'refDatetime' && !!fieldValue && moment(fieldValue).isAfter(moment())) {
                showAlert(t('msr.mstable.refDatetime.future.date'), 'E')
                result = false
              }
            }
          })
        } else {
          showAlert('Cannot validate. Something went really wrong .... Please Sign in again.', 'E')
          result = false
        }
      } else {
        showAlert(t('msr.mstable.series.missing'), 'E')
        result = false
      }
    } else {
      showAlert(t('msr.mstable.series.missing'), 'E')
      result = false
    }
    return result
  }

  const actDeleteMeasurement = (measurement, entity) => {
    return measurementService.deleteMeasurement(measurement).then(
      res => {
        if (!!entity) {
          let index = entity.series.findIndex(s => s._id === measurement.series)
          if (index !== -1) {
            let updatedmeasurements = !!entity.series[index].measurements ? [...entity.series[index].measurements] : []
            updatedmeasurements = updatedmeasurements.filter(m => m._id !== measurement._id)
            entity.series[index].measurements = updatedmeasurements
            let updatedSeries = [...entity.series]
            showAlert(t('com.dialog.success.msg'), 'S')
            setMeasurementSeries(updatedSeries)
          } else {
            showAlert(t('com.dialog.error.msg'), 'E')
          }
        }
        return res
      },
      error => {
        console.error(`err ${JSON.stringify(error, null, 2)}`)
        let errorMessage = buildErrorMessage(error)
        showAlert(t(errorMessage), 'E')
        return Promise.reject(error)
      }
    )
  }

  /** TODO: if the mseri has no filter look for optional (new) mseri property daysBack: number
   * if fetching mseries for a feature e.g. with getFeatureMseries also look (by priority) for optional (new) mseriesLink property daysBack: number */
  const getMseriesMsrms = (mseriesCode: string, filter?: any, needsRefresh?: boolean, mseri?: any) => {
    if (!mseri) {
      const mseries = useCollectionStore.getState().mseries || []
      console.log(`getMseriesMsrms mseries length ${mseries.length}`)
      // const mseriesLinks = useCollectionStore.getState().mseriesLinks || []
      // console.log(`MeasurementsManager mseriesLinks length ${mseriesLinks.length}`)
      mseri = mseries?.find(s => s.code === mseriesCode)
      if (!mseri) {
        return Promise.reject(`ERROR getMseriesMrms : Could not find series with code ${mseriesCode}`)
      }
    }

    let promise: any = Promise.resolve('getMseriesMrms start')
    if (!mseri.filter) {
      needsRefresh = true
    } else { // mseri has filter
      if (filter) {
        //check if the filter has changed
        if (
            moment(mseri.filter.refDatetime?.$gte) === moment(filter.refDatetime?.$gte)
            && moment(mseri.filter.refDatetime?.$lte) === moment(filter.refDatetime?.$lte)
        ) {
          //check if measurements for this series exist
          if (!needsRefresh && !!mseri.measurements) {
            promise = Promise.resolve(mseri.measurements || [])
          } else {
            //if they do not exist get them from db
            needsRefresh = true
          }
        } else {
          //if filter is not the same, get them form db
          needsRefresh = true
        }
      }
    }

    if (needsRefresh) {

      if (!filter && !mseri.filter) {
        let filterOptions: any
        if (mseri.daysBack) {
          filterOptions = { days: mseri.daysBack }
        }
        filter = getDefaultFilter(filterOptions)
      } else {
        filter = filter || mseri.filter || {}
      }
      filter.mseriesCode = mseriesCode
console.log(`getMseriesMsrms filter ${JSON.stringify(filter,null,2)}`)
      // let seriesfilter = {
      //   _id: mseri._id,
      // }
      // return measurementService.getMeasurements(seriesfilter, null, mfilter).then(
      promise = msrmService.getEntities(filter)
      .then(
        res => {
          let updatedMseri = {...mseri}
          updatedMseri.measurements = res.data
          updatedMseri.filter = filter
          updateCollectionItem('mseries', updatedMseri)
console.log(`getMseriesMsrms Mseries ${mseri.code} msrms length ${res.data.length}`)
          return res.data
        },
        err => {
          console.log(`ERROR getMseriesMsrms err.data ${JSON.stringify(err.data, null, 2)}`)
          // return { error: err }
          return Promise.reject(err)
        }
      )
    // } else {
    //   return Promise.resolve(mseri && mseri.measurements ? mseri.measurements : [])
    }
    return promise
  }
  /** TODO: if the mseri has no filter look for optional (new) mseri property daysBack: number
   * if fetching mseries for a feature e.g. with getFeatureMseries also look (by priority) for optional (new) mseriesLink property daysBack: number */
  const getFeatureMseries = (feature: GenericFeature, needsRefresh?: boolean, initialFilter?: any) => {
    if (!feature) {
      // setMeasurementSeries(null)
      return Promise.reject('no feature provided!')
    }
    const mseries = useCollectionStore.getState().mseries || []
    console.log(`getFeatureMseries mseries length ${mseries.length}`)
    const mseriesLinks = useCollectionStore.getState().mseriesLinks || []
    console.log(`getFeatureMseries mseriesLinks length ${mseriesLinks.length}`)
    const featureMseriesLinks: any[] = mseriesLinks.filter(ml => ml.clientCode === feature.properties.code)
    let featureMseries: any[] = mseries.filter(ms => featureMseriesLinks.findIndex(msl => msl.mseriesCode === ms.code) > -1)
console.log(`getFeatureMseries featureMseries.length ${featureMseries.length}`)

    featureMseries = featureMseries.map(ms => {
      if (!ms.filter || needsRefresh) {
        let filter = initialFilter //if no initialFilter is provided, better check each series for being scada-enabled
        if (!filter) {
          //get default filter after fetching series, so that getDefaultFilter can check for scada-enabled series
          if (ms.daysBack) {
            filter = getDefaultFilter({ days: ms.daysBack })
          } else if (feature.properties.featureType !== 'switch') {
            filter = getDefaultFilter({ isScadaEnabled: !!ms.scadaDeviceCode, featureType: feature.properties.featureType })
          } else {
            filter = getDefaultFilter({ featureType: feature.properties.featureType })
          }
        }
        filter.mseriesCode = ms.code
console.log(`getFeatureMseries filter ${JSON.stringify(filter,null,2)}`)
        let updatedMseri = {...ms}
        updatedMseri.filter = filter
        updateCollectionItem('mseries', updatedMseri)
        return updatedMseri
      }
      return ms
    })
    return Promise.resolve(featureMseries)
  }
  /** TODO: if the mseri has no filter look for optional (new) mseri property daysBack: number
   * if fetching mseries for a feature e.g. with getFeatureMseries also look (by priority) for optional (new) mseriesLink property daysBack: number */
  const getFeatureMsrms = async(feature: GenericFeature, needsRefresh?: boolean, initialFilter?: any) => {
    if (!feature) {
      setMeasurementSeries(null)
      return Promise.resolve('ok')
    }

    let promises: any[] = []
    const featureMseries = await getFeatureMseries(feature, needsRefresh, initialFilter)
console.log(`getFeatureMsrms featureMseries.length ${featureMseries.length}`)
    featureMseries.forEach(ms => {
      promises.push(getMseriesMsrms(ms.code, ms.filter, needsRefresh, ms))
    })
    return Promise.all(promises)
  }

  const getFeatureMeasurements = (feature: GenericFeature, needsRefresh?: boolean, initialFilter?: any) => {
    if (!feature) {
      setMeasurementSeries(null)
      return Promise.resolve('ok')
    }
    let promises: any[] = []

    return getFeatureSeries(feature, needsRefresh, initialFilter).then(series => {
      feature.series?.forEach((s, i) => {
        let pr = getSeriesMeasurements(feature, s?._id, s?.mfilter).then(
          res => {
            // /**Hell of a bad practice, we mutate the state outside setState... check task 1490 for more details about it */
            //@ts-ignore
            feature.series[i].measurements = res
            // if (feature.properties._id === selectedEntity?.properties?._id) {
            //   setSelectedEntity({ ...feature })
            // }
            return res
          },
          err => {
            //@ts-ignore
            feature.series[i].measurements = []
          }
        )
        promises.push(pr)
      })
      return Promise.all(promises).then(sm => {
        setMeasurementSeries(feature.series || [])
        /**Hell of a bad practice, we mutate the state outside setState... check task 1490 for more details about it */
        //@ts-ignore
        if (feature.properties._id === selectedEntity?.properties?._id) {
          setSelectedEntity({ ...feature })
        }
        return Promise.resolve('getFeatureMeasurements ok')
      })
    })
  }

  /** Fetches measurements (and series) for multiple features at once
   * each individual feature.series && feature.series.measurements will be updated (on a collection level)
   * the global measurementSeries state will be set with all measurementSeries of all the features passed to getFeatureMeasurementsForMultipleFeatures()
   * if an optional feature(network element) is passed as argument then the series will be appended to it   */
  const getFeatureMeasurementsForMultipleFeatures = async (
    features: GenericFeature[],
    needsRefresh?: boolean,
    initialFilter?: any,
    parentFeature?: GenericFeature,
    consumer?: Consumer
  ) => {
    if (features.length === 0) {
      setMeasurementSeries(null)
      return Promise.resolve('ok')
    }
    let promises: any[] = []
    setLoadingMultipleSeries(true)
    const featuresClone = _.cloneDeep(features)
    for (const feature of featuresClone) {
      await getFeatureSeries(feature, needsRefresh, initialFilter)
      if (!!feature?.series?.length && Array.isArray(feature.series)) {
        for (let i = 0; i < feature.series.length; i++) {
          const serie = feature.series[i]
  
          let pr = getSeriesMeasurements(feature, serie._id, serie.mfilter).then(
            res => {
              feature.series![i].measurements = res
              if (feature.properties._id === selectedEntity?.properties?._id) {
                setSelectedEntity({ ...feature })
              }
              return res
            },
            err => {
              feature.series![i].measurements = []
            }
          )
          promises.push(pr)
        }
      }
    }
    return Promise.all(promises).then(sm => {
      for (const featureClone of featuresClone) {
        updateNetworkElement(FTC[featureClone?.properties?.featureType].collection, featureClone)
      }
      const reducer = (acc, curVal) => acc.concat(curVal.series)
      const parentFeatureClone = _.cloneDeep(parentFeature)
      const allSeries = featuresClone.reduce(reducer, [])
      if (!!parentFeatureClone) {
        parentFeatureClone.series = allSeries
        parentFeatureClone.seriesLoaded = true
        if (parentFeatureClone?.properties?.featureType) {
          updateNetworkElement(FTC[parentFeatureClone?.properties?.featureType].collection, parentFeatureClone)
        }
      }
      setMeasurementSeries(allSeries)
      if (
        !!parentFeatureClone &&
        !!selectedEntity &&
        parentFeatureClone?.properties?._id === selectedEntity?.properties?._id
      ) {
        setSelectedEntity({ ...parentFeatureClone })
      }
      setLoadingMultipleSeries(false)
      return Promise.resolve('getFeatureMeasurementsForMultipleFeatures ok')
    })
  }

  /** MultiFeature measurements are computed data (used for reports and charts) that will not be memory-stored within the feature's series   **/
  const getMultiFeatureMeasurementsByMeasurementType = (
    measurementTypeId: string,
    featureCodes: string[],
    mfilter: any,
    sortedBy?: string
  ) => {
    return measurementService
      .getMultiFeatureMeasurementsByMeasurementType(measurementTypeId, featureCodes, mfilter, sortedBy)
      .then(
        res => {
          console.log(`getMultiFeatureMeasurementsByMeasurementType res.data.length ${res.data?.length}`)
          return res.data
        },
        err => {
          console.log(
            `ERROR getMultiFeatureMeasurementsByMeasurementType err.data ${JSON.stringify(err.data, null, 2)}`
          )
          return { error: err }
        }
      )
  }

  /** getSeriesReport gets aggregated measurements (used for Quality Control reports) are computed data that will not be memory-stored within the feature's series */
  const getSeriesReport = (seriesIds: Array<string>, mfilter, reportType, timeUnit?) => {
    if (!!seriesIds && !!mfilter) {
      return measurementService.getReportData(seriesIds, mfilter, reportType, timeUnit).then(
        res => {
          return res.data
        },
        err => {
          console.log(`ERROR getSeriesReport err.data ${JSON.stringify(err.data, null, 2)}`)
          return { error: err }
        }
      )
    } else {
      console.log(`ERROR getSeriesReport no ${!seriesIds ? 'seriesId' : 'mfilter'} provided.`)
      return Promise.resolve([])
    }
  }

  /** Aggregated measurements are computed data that will not be memory-stored within the feature's series */
  const getSeriesAggregatedMeasurements = (seriesId, mfilter, timeUnit?) => {
    if (!!seriesId && !!mfilter) {
      return measurementService.getAggregatedMeasurements(seriesId, mfilter, timeUnit).then(
        res => {
          console.log(`getSeriesAggregatedMeasurements res.data.length ${res.data?.length}`)
          return res.data
        },
        err => {
          console.log(`ERROR getSeriesAggregatedMeasurements err.data ${JSON.stringify(err.data, null, 2)}`)
          return { error: err }
        }
      )
    } else {
      console.log(`ERROR getSeriesAggregatedMeasurements no ${!seriesId ? 'seriesId' : 'mfilter'} provided.`)
      return Promise.resolve([])
    }
  }

  const getSeriesMeasurements = (
    feature: GenericFeature,
    seriesId: string,
    mfilter?: any,
    needsRefresh?: boolean,
    firstAndLastOnly?: any
  ) => {
    const serie = feature.series?.find(s => s._id === seriesId)
    //check if measurements for this series exist
    //if they do not exist get them from db
    //if they do exist check if the filter has changed
    //if filter is not the same, get them form db
    if (serie) {
      if (
        !!mfilter &&
        !!serie.mfilter &&
        serie.mfilter?.refDatetime?.$gte === mfilter?.refDatetime?.$gte &&
        serie.mfilter?.refDatetime?.$lte === mfilter?.refDatetime?.$lte
      ) {
        if (!needsRefresh && !!serie.measurements) {
          return Promise.resolve(serie.measurements)
        } else {
          needsRefresh = true
        }
      } else {
        needsRefresh = true
      }
    }
    if (needsRefresh) {
      let sfilter = {
        _id: seriesId,
      }
      return measurementService.getMeasurements(sfilter, null, mfilter, firstAndLastOnly).then(
        res => {
          serie.measurements = res.data
          serie.mfilter = mfilter
          return res.data
        },
        err => {
          console.log(`ERROR getMeasurements err.data ${JSON.stringify(err.data, null, 2)}`)
          return { error: err }
        }
      )
    } else {
      return Promise.resolve(serie && serie.measurements ? serie.measurements : [])
    }
  }

  const getFeatureSeries = (feature: GenericFeature, needsRefresh?: boolean, initialFilter?: any) => {
    if (!feature) {
      setMeasurementSeries(null)
      return Promise.resolve('no feature provided!')
    }
    if (!feature.series || (feature.series.length === 0 && !feature.seriesLoaded) || needsRefresh) {
      return measurementSeriesService
        .getMeasurementSeriesByFeature(feature.properties.featureType, feature.properties.code)
        .then(
          res => {
            feature.series = res.data
            feature.series?.forEach(s => {
              let sfilter = initialFilter //if no initialFilter is provided, better check each series for being scada-enabled
              if (!sfilter) {
                //get default filter after fetching series, so that getDefaultFilter can check for scada-enabled series
                if (s.featureType !== 'switch') {
                  sfilter = getDefaultFilter({ isScadaEnabled: !!s.scadaDeviceCode, featureType: s.featureType })
                } else {
                  sfilter = getDefaultFilter({ featureType: s.featureType })
                }
              }
              s.mfilter = sfilter
            })
            setMeasurementSeries(JSON.parse(JSON.stringify(feature.series)))
            feature.seriesLoaded = true
            return feature.series
          },
          err => {
            console.log(`ERROR getMeasurementSeriesByFeature err ${JSON.stringify(err, null, 2)}`)
            setMeasurementSeries([])
            return { error: err }
          }
        )
    } else {
      setMeasurementSeries(!!feature.series ? [...feature.series] : [])
      return Promise.resolve(feature.series)
    }
  }

  const actSaveSeries = (theUpdatedSeries, feature?) => {
    let isNewSeries = !theUpdatedSeries._id
    if (isNewSeries) {
      theUpdatedSeries.tenantCode = profile?.selectedTenant
      return measurementSeriesService.createMeasurementSeries(theUpdatedSeries).then(
        res => {
          console.log(`actSaveSeries res.data ${JSON.stringify(res.data, null, 2)}`)
          if (!!feature) {
            let updatedSeries = [...feature.series]
            updatedSeries.push(res.data)
            feature.series = updatedSeries
          } else {
            let updatedSeries = [...(measurementSeries || [])]
            updatedSeries.push(res.data)
            const tempSelectedEntity = _.cloneDeep(selectedEntity)
            tempSelectedEntity!.series = updatedSeries
            setSelectedEntity(tempSelectedEntity)
            setMeasurementSeries(updatedSeries)
          }
          res.data.mfilter = getDefaultFilter({
            isScadaEnabled: !!theUpdatedSeries.scadaDeviceCode,
            featureType: theUpdatedSeries.featureType,
          })
          return res.data
        },
        err => {
          console.error(`error ${JSON.stringify(err, null, 2)}`)
          let errorMessage = buildErrorMessage(err)
          showAlert(t(errorMessage), 'E')
          return Promise.reject(err)
        }
      )
    } else {
      return measurementSeriesService.updateMeasurementSeries(theUpdatedSeries).then(
        res => {
          console.log(`actSaveSeries res.data ${JSON.stringify(res.data, null, 2)}`)
          if (!!feature) {
            let updatedSeries = [...feature.series]
            let serieToUpdate = updatedSeries.find(s => s._id === res.data._id)
            if (serieToUpdate) {
              serieToUpdate.scadaDeviceCode = res.data.scadaDeviceCode
              feature.series = updatedSeries
              return res.data
            } else {
              return Promise.reject('Could not refresh cache')
            }
          } else {
            let updatedSeries = [...(measurementSeries || [])]
            let serieToUpdate = updatedSeries.find(s => s._id === res.data._id)
            if (serieToUpdate) {
              serieToUpdate.scadaDeviceCode = res.data.scadaDeviceCode
              const tempSelectedEntity = _.cloneDeep(selectedEntity)
              tempSelectedEntity!.series = updatedSeries
              setSelectedEntity(tempSelectedEntity)
              setMeasurementSeries(updatedSeries)
              return res.data
            } else {
              return Promise.reject('Could not refresh cache')
            }
          }
        },
        err => {
          console.error(`error ${JSON.stringify(err, null, 2)}`)
          let errorMessage = buildErrorMessage(err)
          showAlert(t(errorMessage), 'E')
          return Promise.reject(err)
        }
      )
    }
  }

  const actSaveMeasurement = (measurement, entity, measurementTypeId?) => {
    if (!measurement.series && !measurementTypeId) {
      return Promise.reject('No series or measurement type provided')
    } else {
      let seriesPromise = new Promise(checkSeriesPromiseExecutor(entity, measurementTypeId, measurement.series))
      return seriesPromise.then(
        seriesId => {
          if (!measurement.series) {
            measurement.series = seriesId
          }
          let isNewMeasurement = !measurement._id
          if (isNewMeasurement) {
            measurement.addedBy = profile?.user_id
          } else {
            measurement.modifiedBy = profile?.user_id
          }
          if (validateMeasurement(measurement)) {
            return measurementService.saveMeasurement(measurement).then(
              res => {
                measurement = res.data
                if (!!entity) {
                  let index = entity.series.findIndex(s => s._id === measurement.series)
                  if (index !== -1) {
                    let updatedmeasurements = !!entity.series[index].measurements
                      ? [...entity.series[index].measurements]
                      : []
                    if (isNewMeasurement) {
                      updatedmeasurements.push(measurement)
                    } else {
                      updatedmeasurements = entity.series[index].measurements.map(e =>
                        e._id === measurement._id ? measurement : e
                      )
                    }
                    entity.series[index].measurements = updatedmeasurements
                    let mfilter = entity.series[index].mfilter
                    return getSeriesMeasurements(
                      entity,
                      entity.series[index]._id,
                      entity.series[index].mfilter,
                      true
                    ).then(res => {
                      let updatedSeries = [...entity.series]
                      updatedSeries[index].measurements = res
                      updatedSeries[index].mfilter = mfilter
                      setMeasurementSeries(updatedSeries)
                      return measurement
                    })
                  } else {
                    return getFeatureMeasurements(entity)
                  }
                }
                return measurement
              },
              error => {
                console.error(`err ${JSON.stringify(error, null, 2)}`)
                let errorMessage = buildErrorMessage(error)
                showAlert(t(errorMessage), 'E')
                return Promise.reject(error)
              }
            )
          } else {
            return Promise.reject('Measurement valdation failed.')
          }
        },
        error => {
          console.error(`err ${JSON.stringify(error, null, 2)}`)
          let errorMessage = buildErrorMessage(error)
          showAlert(t(errorMessage), 'E')
          return Promise.reject(error)
        }
      )
    }
  }

  function checkSeriesPromiseExecutor(
    selectedEntity: any,
    measurementTypeId: any,
    measurementSeriesId: any
  ): (resolve: (value: unknown) => void, reject: (reason?: any) => void) => void {
    return (resolve, reject) => {
      if (!selectedEntity) {
        let errmsg = `Series Check Error : No selectedEntity was provided`
        console.log(errmsg)
        reject(errmsg)
      } else if (!!measurementSeriesId) {
        return resolve(measurementSeriesId)
      } else {
        return measurementSeriesService
          .getMeasurementSeriesByFeatureAndMsType(
            selectedEntity.properties.featureType,
            selectedEntity.properties.code,
            measurementTypeId
          )
          .then(
            res => {
              if (res.data?.length > 0) {
                console.log(`Measurement Series is : ${JSON.stringify(res.data[0])}`)
                resolve(res.data[0]._id)
              } else {
                console.log('No Measurements Series found. Create One ')
                let newMS = {
                  featureCode: selectedEntity.properties.code,
                  featureType: selectedEntity.properties.featureType,
                  measurementType: measurementTypeId,
                }
                let createPromise = measurementSeriesService.createMeasurementSeries(newMS)
                createPromise.then(
                  res => {
                    console.log(`Measurement Series Added new: ${JSON.stringify(res.data, null, 2)}`)
                    res.data.mfilter = getDefaultFilter({
                      isScadaEnabled: !!res.data.scadaDeviceCode,
                      featureType: res.data.featureType,
                    })
                    if (!!measurementSeries) {
                      measurementSeries.push(res.data)
                      setMeasurementSeries(measurementSeries)
                      selectedEntity.series = measurementSeries
                    }
                    resolve(res.data._id)
                  },
                  err => {
                    console.log(`Create Measurement Series Error: ${JSON.stringify(err)}`)
                    reject(err)
                  }
                )
              }
            },
            err => {
              console.log(`Series Check Error : ${JSON.stringify(err)}`)
              reject(err)
            }
          )
      }
    }
  }

  const actSetMeasurementsFilter = (feature, seriesId, filter) => {
    console.log(
      `actSetMeasurementsFilter feature ${!!feature ? feature.properties.label : feature} filter: ${JSON.stringify(
        filter
      )}`
    )
    return getSeriesMeasurements(feature, seriesId, filter).then(res => {
      let updatedSeries = [...feature.series]
      setMeasurementSeries(updatedSeries)
      return updatedSeries
    })
  }

  const actSubmitHydrometerConsumption = (measurement, hydrometer, entity?) => {
    let errorMessage = 'An error occured.'
    console.log(`submitHydrometerConsumption`)
    measurement.addedBy = profile?.user_id //measurement.tenantCode = profile.selectedTenant
    let consumption = null
    if (!!hydrometer) {
      consumption = hydrometer?.consumptions?.find(c => moment(c['refDatetime']).isSame(measurement['refDatetime']))
    }
    if (!!consumption) {
      errorMessage = 'Consumption for this date already exists'
      showAlert(t('msg.dialog.validation.consumptionForSameDateExists'), 'E')
      return Promise.reject(errorMessage)
    } else {
      let consumptionMT = measurementTypes?.find(mt => mt.code === 'hydrometerConsumption')
      let seriesPromise = new Promise(checkSeriesPromiseExecutor(hydrometer, consumptionMT._id, measurement.series))
      return seriesPromise.then(
        seriesId => {
          if (!measurement.series) {
            measurement.series = seriesId
          }
          return measurementService.submitHydrometerConsumption(measurement).then(
            res => {
              if (!!entity) {
                const index = entity.series.findIndex(s => s._id === measurement.series)
                let updatedmeasurements = !!entity.series[index].measurements
                  ? [...entity.series[index].measurements]
                  : []
                updatedmeasurements.push(measurement)
                entity.series[index].measurements = updatedmeasurements
                let updatedSeries = [...entity.series]
                setMeasurementSeries(updatedSeries)
              }
              return measurement
            },
            error => {
              console.error(`ERROR FROM API ${JSON.stringify(error, null, 2)}`)
              errorMessage = buildErrorMessage(error, errorMessage)
              showAlert(t(errorMessage), 'E')
              return Promise.reject(error)
            }
          )
        },
        error => {
          console.error(`ERROR FROM API ${JSON.stringify(error, null, 2)}`)
          errorMessage = buildErrorMessage(error, errorMessage)
          showAlert(t(errorMessage), 'E')
          return Promise.reject(error)
        }
      )
    }
  }

  const actSetGroupOfMeasurementsFilter = (feature, updatedFilter) => {
    console.log(
      `actSetGroupOfMeasurementsFilter feature ${
        !!feature ? feature.properties?.label : feature
      } updatedFilter: ${JSON.stringify(updatedFilter)}`)
    let promises: any[] = []
    let commonFilter: any = {}
    if (!!updatedFilter.refDatetimeFrom && !!updatedFilter.refDatetimeTo) {
      commonFilter = {
        refDatetime: {
          $gte: updatedFilter.refDatetimeFrom,
          $lte: updatedFilter.refDatetimeTo,
        },
      }
    } else if (!!updatedFilter.refDatetimeFrom) {
      commonFilter = { refDatetime: { $gte: updatedFilter.refDatetimeFrom } }
    } else if (!!updatedFilter.refDatetimeTo) {
      commonFilter = { refDatetime: { $lte: updatedFilter.refDatetimeTo } }
    }
    updatedFilter.series.forEach(sf => {
      let series = feature.series.find(s => s._id === sf._id)
      if (series) {
        let mfilter = { ...commonFilter }
        if (!sf.selected) {
          if (!series.seriesHidden) {
            series.seriesHidden = true
            series.measurements = []
          }
          series.mfilter = mfilter
        } else {
          let needsRefreshBecauseSelected = series.seriesHidden
          series.seriesHidden = false
          let pr = getSeriesMeasurements(feature, sf._id, mfilter, needsRefreshBecauseSelected).then(
            res => {
              return res
            },
            err => {
              let errseries = feature.series.find(s => s._id === sf._id)
              if (errseries) {
                errseries.measurements = []
                errseries.mfilter = null //so that it can be refreshed next time
              }
            }
          )
          promises.push(pr)
        }
      }
    })
    return Promise.all(promises).then(sm => {
      let updatedSeries = [...feature.series]
      setMeasurementSeries(updatedSeries)
      return Promise.resolve('actSetGroupOfMeasurementsFilter ok')
    })
  }

  const actSetMeasurementsFilterToFeatures = (featureCodes, updatedFilter) => {
    console.log(
      `actSetMeasurementsFilterToFeatures feature ${!!featureCodes ? featureCodes : ''} updatedFilter: ${JSON.stringify(
        updatedFilter
      )}`
    )
    let promises: any[] = []
    let commonFilter: any = {}
    if (!!updatedFilter.refDatetimeFrom && !!updatedFilter.refDatetimeTo) {
      commonFilter = {
        refDatetime: {
          $gte: updatedFilter.refDatetimeFrom,
          $lte: updatedFilter.refDatetimeTo,
        },
      }
    } else if (!!updatedFilter.refDatetimeFrom) {
      commonFilter = { refDatetime: { $gte: updatedFilter.refDatetimeFrom } }
    } else if (!!updatedFilter.refDatetimeTo) {
      commonFilter = { refDatetime: { $lte: updatedFilter.refDatetimeTo } }
    }

    const NETWORK_ELEMENTS = useNetworkElementStore.getState()
    const featureCollection = {}
    Object.values(FTC).forEach(ftc => {
      featureCollection[ftc.collection] = NETWORK_ELEMENTS[ftc.collection]
    })

    let features = featureCodes.map(fcode => {
      let feature
      Object.values(featureCollection).some((featureCollection: any) => {
        feature = featureCollection.find(elem => elem?.properties?.code === fcode)
        return !!feature
      })
      return feature
    })
    features.forEach(feature => {
      let mfilter = { ...commonFilter }
      feature.series.forEach(sf => {
        let pr = getSeriesMeasurements(feature, sf._id, mfilter).then(
          res => {
            return res
          },
          err => {
            let errseries = feature.series.find(s => s._id === sf._id)
            if (errseries) {
              errseries.measurements = []
              errseries.mfilter = null //so that it can be refreshed next time
            }
          }
        )
        promises.push(pr)
      })
    })
    return Promise.all(promises).then(sm => {
      return Promise.resolve('actSetMeasurementsFilterToFeatures ok')
    })
  }

  const getConsumption = (feature: GenericFeature, mtypes) => {
    let hydrometerConsumption = mtypes.find(mt => mt.code === 'hydrometerConsumption')
    if (!feature) return []
    return measurementService
      .getMeasurementsByFeatureAndType(
        feature.properties.featureType,
        feature.properties.code,
        hydrometerConsumption._id,
        'refDatetime'
      )
      .then(
        res => {
          return res.data
        },
        err => {
          console.log(`ERROR getMeasurements err.data ${JSON.stringify(err.data, null, 2)}`)
          Promise.reject(err)
        }
      )
  }

  const getConsumptionSeries = (feature, mtypes) => {
    let hydrometerConsumption = mtypes.find(mt => mt.code === 'hydrometerConsumption')
    if (!feature) return []
    return measurementSeriesService
      .getMeasurementSeriesByFeatureAndMsType(
        feature.properties.featureType,
        feature.properties.code,
        hydrometerConsumption._id
      )
      .then(
        res => {
          return res.data
        },
        err => {
          console.log(`ERROR getConsumptionSeries err.data ${JSON.stringify(err.data, null, 2)}`)
          Promise.reject(err)
        }
      )
  }

  /* yko: at some point we should re-implement this 'consumption' logic using the common measurement methods */
  const loadConsumptions = (_userProfile, mtypes) => {
    /** Clone of the userProfile, so that the state is not directly mutated */
    let userProfile = _.cloneDeep(_userProfile)
    if (!!userProfile.consumer.hydrometers) {
      console.log(`Load Consumptions for ${userProfile.consumer.hydrometers.length} hydrometers`)
      userProfile.consumer.hydrometers.forEach((h: any) => {
        getConsumption(h, mtypes).then(
          res => {
            if (Array.isArray(res)) {
              let previousValue = 0
              res.forEach((m, i) => {
                if (i === 0) {
                  m['consumption'] = ''
                } else {
                  m['consumption'] = m['value'] - previousValue
                }
                previousValue = m['value']
              })
              h['consumptions'] = [...res]
              //console.log(`hydrometer with code ${h.properties.code} consumption are ${JSON.stringify( h['consumptions'], null, 2)}`)
              // console.log(`hydrometer with code ${h.properties.code} consumptions count ${h['consumptions'].length}`)
            }
          },
          err => {
            console.log(`Error retrieving consumptions of hydrometer with code ${h.properties.code}`)
          }
        )
        getConsumptionSeries(h, mtypes).then(
          res => {
            if (!!res) {
              h['series'] = { ...res }
            } else {
              h['series'] = {}
            }
            //console.log(`hydrometer with code ${h.properties.code} consumption are ${JSON.stringify( h['consumptions'], null, 2)}`)
            // console.log(`hydrometer with code ${h.properties.code} consumption series ${JSON.stringify(h['series'])}`)
          },
          err => {
            console.log(`Error retrieving consumption series of hydrometer with code ${h.properties.code}`)
          }
        )
      })
      setProfile(userProfile)
    }
  }

  const getDefaultFilter = (options?) => {
    let daysCount = DEFAULT_NO_OF_DAYS_MEASUREMENTS_FILTER
    if (options) {
      if (options.days) {
        daysCount = options.days
      } else if (options.isScadaEnabled) {
        daysCount = 1
      } else if (options.feature) {
        if (options.feature.series) {
          if (options.feature.series.some(s => s.scadaDeviceCode)) {
            daysCount = 1
          }
        } else if (options.feature.properties.featureType === 'hydrometer') {
          daysCount = 365 * 3
        }
      } else if (options.featureType) {
        if (options.featureType === 'hydrometer') {
          daysCount = 365 * 3
        } else if (options.featureType === 'switch') {
          // let mlast = moment() //
          // let mfirst = mlast.clone().subtract(8, 'month')
          // daysCount = mlast.diff(mfirst, 'days')
          let start = moment(currentAgriculturalPeriod?.startDate)
          let end = moment(currentAgriculturalPeriod?.endDate)
          let defaultFilter = {
            refDatetime: {
              $gte: start,
              $lte: end,
            },
          }
          return defaultFilter
        }
      }
    }
    let filterPeriodStart = moment()
      .add(-1 * daysCount, 'day')
      .startOf('day')
      .toDate()
    let today = moment().endOf('day').toDate()
    let defaultFilter = {
      refDatetime: {
        $gte: filterPeriodStart,
        $lte: today,
      },
    }
    return defaultFilter
  }

  return {
    actDeleteMeasurement,
    getFeatureSeries,
    getFeatureMeasurements,
    getFeatureMeasurementsForMultipleFeatures,
    getMultiFeatureMeasurementsByMeasurementType,
    getSeriesReport,
    getSeriesAggregatedMeasurements,
    actSaveSeries,
    actSaveMeasurement,
    actSetMeasurementsFilter,
    actSetGroupOfMeasurementsFilter,
    actSetMeasurementsFilterToFeatures,
    loadConsumptions,
    actSubmitHydrometerConsumption,
    getDefaultFilter,
    setMeasurementSeries,
    // checkSeriesPromiseExecutor,
    // getConsumption,
    // getConsumptionSeries,
    // validateMeasurement,
    // getSeriesMeasurements,
    getFeatureMsrms,
    getMseriesMsrms,
  } as MeasurementsManagerType
}

export default MeasurementsManager
