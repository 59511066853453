import React, { useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Tooltip from '@material-ui/core/Tooltip'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import WrmIcon from '../WrmIcon'
const MdActionsMenu = React.lazy(()=>import('./MdActionsMenu'))

//Paging
const useTPAStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))
function TablePaginationActions(props) {
  const classes = useTPAStyles()
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }
  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }
  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }
  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}
/*
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}
*/

//enhanced table header
const useETHStyles = makeStyles(theme => ({
  thcell: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxWidth: '300px',
    // whiteSpace: 'nowrap',
    whiteSpace: 'pre-wrap',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  entIcon: {
    width: 18,
    height: 18,
    color: theme.palette.primary.light,
  },
}))
function EnhancedTableHead(props) {
  const classes = useETHStyles()
  const { t } = useTranslation()
  const { order, orderBy, onRequestSort, meta, rowCount, checkedCount, onCheckAll } = props
  const createSortHandler = (property, colmeta) => (event) => {
    onRequestSort(event, property, colmeta)
  }

  return (
    <TableHead>
      <TableRow>
        {meta.checkboxed && <TableCell
          padding='checkbox'
          component="th" scope="row"
          key='check'
          className={classes.thcell}
        >
          {!meta.checkAllHidden && <CheckBoxNt
            indeterminate={checkedCount > 0 && checkedCount !== rowCount}
            checked={checkedCount === rowCount}
            onClick={onCheckAll}
          />}
        </TableCell>}
        {meta.columns.filter(m => !m.hidden && !m.noColumn).map((m) => {
          let label: string = t(m.label)
          if (m.unit) {
            label = `${label} (${t(m.unit)})`
          }
          return (
          <TableCell
            key={m.code}
            align={m.align === 'left' ? 'left' : (m.align === 'right' ? 'right' : 'center')}
            // padding={m.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === m.code ? order : false}
            className={classes.thcell}
          >
            <TableSortLabel
              active={orderBy === m.code}
              direction={orderBy === m.code ? order : 'asc'}
              onClick={createSortHandler(m.code, m)}
            >
            {m.isFeatureTypeField && m.showAsIcon ? (
              <Tooltip title={t(label).toString()} enterDelay={1000} leaveDelay={10} placement='right'>
              <Box display='flex' alignItems='center' justifyContent='center' ml={3}>
                <WrmIcon
                  icon='tint'
                  className={classes.entIcon}
                />
              </Box>
              </Tooltip>
            ) : t(label)}
            </TableSortLabel>
          </TableCell>
        )})}
      </TableRow>
    </TableHead>
  )
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
  checkedCount: PropTypes.number.isRequired,
  onCheckAll: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  master: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginRight: 16,
    width: '100%',
    minWidth: 600,
  },
  masterCompact: {
    padding: 0,
    marginRight: 0,
    minWidth: 'unset',
    boxShadow: 'unset'
  },
  masterNoDetails: {
    maxWidth: 'unset',
  },
  title: {
    fontSize: '16px !important',
    fontWeight: 'bold',
    color: theme.palette.tertiary.dark,
  },
  masterBar: {
  },
  masterTable: {
    // overflowX: 'auto'
  },
  masterSearchInput: {
    padding: theme.spacing(1),
  },
  masterSearchInputIcon: {
    fontSize: 14,
    marginRight: -6,
  },
  entIcon: {
    width: 18,
    height: 18,
    color: theme.palette.primary.light,
  },
  trashBtn: {
    padding: '6px 8px',
  },
  trashIcon: {
    width: 14,
    height: 18,
  },
  pencilBtn: {
    padding: '6px 8px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  pencilIcon: {
    width: 16,
    height: 16,
  },
  blueBtn: {
    textTransform: 'none',
    minWidth: 100,
    width: theme.spacing(9),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  filtersBtn: {
    textTransform: 'none',
  },
  filterIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  filterIconActive: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  tableContainer: {
    // maxHeight: '75vh',
    // overflowY: 'auto',
  },
  tableRoot : {
    // height: 'fit-content'
  },
  // table: {
  //   // minWidth: 500,
  //   alignSelf: 'flex-start'
  // },
  selectedRow: {
    // backgroundColor: '#DFFDFD4D !important',
    backgroundColor: '#DFFDFDAA !important',
  },
  tcell: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxWidth: '300px',
    height: '29px',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  tcellSelected: {
    // color: theme.palette.primary.main
  },
  tcellLong: {
    minWidth: '300px',
    // maxWidth: '300px',
  },
  tcellcbchk: {
    padding: 2,
    // paddingTop: 2,
    // paddingBottom: 2,
    // paddingLeft: 8,
    // paddingRight: 8,
  },
  paginationSpacer: {
    flex: 'unset',
  },
  paginationCaption: {
    whiteSpace: 'pre',
  },
  tooltip: {
    fontSize: 14,
    lineHeight: 2,
    fontWeight: 200,
  },
  tcellTooltip: {
    fontSize: 14,
    fontWeight: 200,
    // whiteSpace: 'pre',
    whiteSpace: 'pre-line',
  },
  actionBtn: {
    padding: '0px 0px 0px 0px',
  },
  actionIcon: {
    color: '#828282',
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    width: 14,
    height: 18,
  },
}))

const MdMaster = (props) => {
  const { t }: { t: any } = useTranslation()
  const classes = useStyles()

  const data = props.data
  const mcolumns = props.meta.columns

  const handleRowClick = (event, id) => {
    let selectedItem = data.find(d => d[props.idfld] === id)
    // setTmpSelectedDataItem(selectedDataItem)
    if (!!props.selectedDataItem && selectedItem[props.idfld] === props.selectedDataItem[props.idfld]) {
      props.setSelectedDataItem(null)
    } else {
      // console.log(`selectedItem ${JSON.stringify(selectedItem,null,2)}`)
      props.setSelectedDataItem(selectedItem)
    }
  }
  // const [checkedItems, setCheckedItems] = React.useState<any[]>([])
  const handleRowCheck = (event, id) => {
    event.stopPropagation()
    let clone = [...props.checkedItems]
    if (event.target.checked) {
      clone.push(id)
    } else {
      let x = clone.findIndex(v => v === id)
      clone.splice(x, 1)
    }
    let doProceed = true
    if (!!props.doCheckFeature) {//custom action on check
      doProceed = props.doCheckFeature(event.target.checked, id)
    }
    if (doProceed) {
      props.setCheckedItems(clone)
    }
  }
  const handleCheckAll = (event) => {
    let clone:any = []
    if (event.target.checked) {
      clone = data.map(d => d[props.idfld])
    }
    let doProceed = true
    if (!!props.doCheckFeature) {//custom action on check
      doProceed = props.doCheckFeature(event.target.checked, 'checkAll')
    }
    if (doProceed) {
      props.setCheckedItems(clone)
    }
  }

  /**
   * Returns a string label indicating which rows are displayed in the current page.
   * Because we want the page action buttons (i.e. goto first/previous/next/last page),
   * we use a function of the passed parameters ('from', 'to' and 'count')
   * to pad the returned label with an appropriate number of empty spaces.
   * Note that this works in combination with the use of a custom css class ('paginationCaption'),
   * which preserves whitespaces.
   * @param {from, to, count}
   * @param from - order of first record of page
   * @param to - order of last record of page
   * @param count - count of all records
   * @returns the label 
   */
  const get_labelDisplayedRows=({from, to, count}) => {
    // let totlen = from.toString().length + to.toString().length + count.toString().length + t('pagination.rows.displayed.of').toString() + 3
    let padlen = 2 * count.toString().length - (from.toString().length + to.toString().length)
    let labelText = `${from}-${to} ${t('pagination.rows.displayed.of')} ${count}`
    labelText = labelText.padStart(labelText.length + padlen*2, ' ')
    return labelText
  }


  let classNames: any[] = [classes.master]
  if (props.meta.compact) classNames.push(classes.masterCompact)
  if (props.meta.hideDetails) classNames.push(classes.masterNoDetails)
  let className: any = classNames.length === 1 ? classNames[0] : clsx(classNames)
  return (
    <Box
      className={className}
      boxShadow={props.meta.compact ? 0 : 2}
    >
    {!props.meta.noTitleBar &&
      <Box
        className={classes.masterBar}
        p={props.meta.compact ? 1 : 2}
        mb={props.meta.compact ? 0.5 : 1}
        display='flex'
        alignItems='center'
      >
      {!!props.meta.title &&
        <Box mr={2} display='flex'
          className={classes.title}
        >
          {t(props.meta.title)}
        </Box>
      }
      {!(typeof props.meta.noAdd === 'function' ? props.meta.noAdd(props.selectedDataItem) : props.meta.noAdd) &&
        <Box mr={props.meta.compact ? 1 : 2} display='flex'>
        {/* CUSTOM EDITING */}
        {!!props.customEditing ? props.customEditing : (
          <Button
            className={classes.blueBtn}
            onClick={props.handleAddDetail}
          >
            {t('btn.add')}
          </Button>
        )}
        </Box>
      }
      {!!props.selectedDataItem && (props.meta.hideDetails || props.meta.editOnMaster) && !(typeof props.meta.noEdit === 'function' ? props.meta.noEdit(props.selectedDataItem) : props.meta.noEdit) &&
        <Box ml={props.meta.compact ? 1 : 2} display='flex'>
          <Tooltip title={`${t('btn.editDetails')}`} classes={{tooltip: classes.tooltip}} arrow placement='bottom' enterDelay={1000} enterNextDelay={500} >
            <IconButton
              className={classes.pencilBtn}
              onClick={props.handleEditDetail}
            >
              <WrmIcon icon='pencil' className={classes.pencilIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      }
      {!!props.selectedDataItem && (props.meta.hideDetails || props.meta.editOnMaster) && !(typeof props.meta.noDelete === 'function' ? props.meta.noDelete(props.selectedDataItem) : props.meta.noDelete) &&
        <Box ml={props.meta.compact ? 1 : 2} display='flex'>
          <Tooltip title={`${t('btn.delete')}`} classes={{tooltip: classes.tooltip}} arrow placement='bottom' enterDelay={1000} enterNextDelay={500} >
            <IconButton
              className={classes.trashBtn}
              onClick={() => props.handleOpenConfirmDialog(
                'confirmDeleteDetail',
                !props.meta.thisEntity ? t('md.confirm.delete.item.msg') : `${t('md.confirm.delete.item.msg.typed')} ${t(props.meta.thisEntity)}${t('com.question.mark')}`,
                props.rowLabel
              )}
            >
              <WrmIcon icon='trash' className={classes.trashIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      }
      {!!props.customMasterButtons && props.customMasterButtons.map((b, i) => (
        <Box key={`cb${i}`} ml={props.meta.compact ? 1 : 2} display='flex'>
          {b}
        </Box>
      ))}
        <Box mr='auto' />
      {!props.meta.noActionsMenu &&
        <div>
          <IconButton
            className={classes.actionBtn}
            // disabled={!selectedCycle || isWaiting || isEditing}
            onClick={props.handleMenuBtnClick}
          >
            <Icon
              className={clsx(classes.actionIcon, 'fas fa-ellipsis-v')}
            />
          </IconButton>
          <Suspense key="print" fallback={<div>Loading...</div>}>
            <MdActionsMenu
              meta={props.meta}
              menuOptions={props.menuOptions}
              menuAnchorEl={props.menuAnchorEl}
              setMenuAnchorEl={props.setMenuAnchorEl}
              prepareExportHeader={props.prepareExportHeader}
              prepareExportData={props.prepareExportData}
            />
          </Suspense>
        </div>
      }
      {!props.meta.noFilter &&
        <Box ml={props.meta.compact ? 1 : 2} display='flex'>
          <Button
            variant='outlined'
            className={classes.filtersBtn}
            startIcon={<WrmIcon className={!!props.itemFilter && props.shouldApplyFilter() ? classes.filterIconActive : classes.filterIcon} icon='filter' />}
            onClick={props.handleFiltersBtnClick}
          >
            {t('btn.filters')}
          </Button>
        </Box>
      }
        <Box ml={props.meta.compact ? 1 : 2} display='flex'>
          <TextField
            placeholder={t('md.search.prompt')}
            type='search'
            variant='outlined'
            value={props.itemSearchLiteral}
            onChange={props.handleItemSearchLiteralChange}
            inputProps={{
              className: classes.masterSearchInput,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Icon className={clsx(classes.masterSearchInputIcon, 'fas fa-search')} />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
    }
    {!!props.customMasterBar && (
      props.customMasterBar
    )}
      <Box
        p={props.meta.compact ? 0 : 2}
        mb={props.meta.compact ? 0.5 : 1}
        className={classes.masterTable}
        display='flex'
        flexGrow={1}
        height = {props.meta.compact ? '' : 'calc(100vh - 320px)'}
      >
      {!!props.pageData && 
      <TableContainer
        component={Box}
        id='tableContainer'
        className={classes.tableContainer}
      >
        <Table
          stickyHeader
          // className={classes.table}
          classes={{root: classes.tableRoot}}
          size='medium'
        >
          <EnhancedTableHead
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={props.handleRequestSort}
            meta={props.meta}
            rowCount={data.length}
            checkedCount={props.meta.checkboxed ? props.checkedItems.length : 0}
            onCheckAll={handleCheckAll}
          />
          <TableBody>
          {props.pageData.map(d => {
            let id = d[props.idfld]
            let isSelected = !!props.selectedDataItem && props.selectedDataItem[props.idfld] === id

            /* table row css customization */
            let rowClassName = ''
            if (props.meta.rowClassName) {
              if (typeof props.meta.rowClassName === 'function') {
                rowClassName = props.meta.rowClassName(d, props.meta)
              } else {
                rowClassName = props.meta.rowClassName
              }
            }

            return (
            <TableRow
              key={id}
              className={rowClassName}
              classes={{selected: classes.selectedRow}}
              selected={isSelected}
              onClick={(event) => handleRowClick(event, id)}
            >
            {props.meta.checkboxed &&
              <TableCell
                padding='checkbox'
                component="th" scope="row"
                key='check'
                className={isSelected ? clsx(classes.tcellSelected, classes.tcell) : clsx(classes.tcell)}
              >
                <CheckBoxNt
                  className={classes.tcellcbchk}
                  checked={props.checkedItems.includes(id)}
                  onClick={(event) => handleRowCheck(event, id)}
                />
              </TableCell>}
            {mcolumns.filter(m => !m.hidden && !m.noColumn).map(m => {
              //prepare data
              let val = props.processRawValue(d[m.code], m)
              let styledVal = val
              if (!!m.className) {
                if (typeof m.className === 'function') {
                  styledVal = (<div className={m.className(d, m)}>{val}</div>)
                } else {
                  styledVal = (<div className={m.className}>{val}</div>)
                }
              }
              let align: any = 'center'
              if (!m.lookup) {
                if (m.align === 'left') {
                  align = 'left'
                } else if (m.align === 'right') {
                  align = 'right'
                } else if (m.align === 'center') {
                  align = 'center'
                } else if ('string' === m.type) {
                  align = 'left'
                } else if (['number', 'posnumber'].includes(m.type)) {
                  align = 'right'
                }
              }
              return (
              <TableCell component="th" scope="row"
                align={align}
                key={m.code}
                className={isSelected ? clsx(classes.tcellSelected, classes.tcell, (m.isLong ? classes.tcellLong : '')) : clsx(classes.tcell, (m.isLong ? classes.tcellLong : ''))}
              >
              {m.isFeatureTypeField && m.showAsIcon && !!d[m.code] ? (
                <Tooltip title={val} enterDelay={1000} leaveDelay={10} placement='right'>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <WrmIcon
                      icon={props.FTC[d[m.code]].icon}
                      className={classes.entIcon}
                    />
                  </Box>
                </Tooltip>
              ) : (m.tooltip && props.meta.hideDetails ? (
                <Tooltip title={val} classes={{tooltip: classes.tcellTooltip}} enterDelay={1000} leaveDelay={10} placement='bottom'>
                  <div>{styledVal}</div>
                </Tooltip>
              ) : (m.iconlookup ? (
                <Box display='flex' alignItems='center' justifyContent='center'>
                  {props.iconlookups[m.iconlookup].find(lv => lv.code === val)?.icon}
                </Box>
              ) : styledVal
              ))}
              </TableCell>
            )})}
            </TableRow>
          )})}
          {/* {props.emptyRows > 0 && (
            <TableRow style={{ height: 29 * props.emptyRows }}>
              <TableCell colSpan={mcolumns.length} />
            </TableRow>
          )} */}
          </TableBody>
        </Table>
      </TableContainer>}
      </Box>
    {!props.meta.hidePaging && !!props.pageData && (
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              size='small'
              classes={{spacer: classes.paginationSpacer, caption: classes.paginationCaption}}
              // rowsPerPageOptions={[5, 10, 20, { label: t('all-female'), value: -1 }]}
              rowsPerPageOptions={[10, 15, 20, 25, 50]}
              // colSpan={4}
              labelRowsPerPage={props.meta.compact ? t('pagination.rows.per.page.short') : t('pagination.rows.per.page')}
              // labelDisplayedRows={({from, to, count}) => `${from}-${to} ${t('pagination.rows.displayed.of')} ${count}`}
              labelDisplayedRows={get_labelDisplayedRows}
              count={props.pageDataCount}
              rowsPerPage={props.rowsPerPage}
              page={props.dpage}
              SelectProps={{
                inputProps: { 'aria-label':'xxxxxxx' },
                native: true,
              }}
              onPageChange={props.handleChangePage}
              onRowsPerPageChange={props.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )}
    </Box>
  )

}

export default MdMaster
