import {create} from 'zustand'

const defaultStationState: any = {
  stationCode: null,
  lastUpdatedAt: null,
  automationActive: null,
  programActive: null,
  pump: {
    status: -1,
    reason: '',
    refDatetime: null,
    earlyArrival: 0,
    grace: 0,
    graceStartedAt: null,
    graceExpiredAt: null,
    lastStartedAt: null,
    lastStoppedAt: null,
    slot: null,
    slotStartTimestamp: null,
    lastSlotEndTimestamp: null,
    slotUsers: null,
    startedSlotUsers: null,
    enoughSlotUsers: null,
    outputFlags: null,
    inputFlags: null,
    errorFlags: null,
    iMotRestartAttempts: null,
  },
  users: {
  }
}
const defaultStationInverterState: any = {
  stationCode: null,
  lastUpdatedAt: null,
  invStatus: -1,
  invEnergyKwh: -1,
  invPowerW: -1,
  invCurrent: -1,
  invFrequency: -1,
  invAI1: -1,
  invAI2: -1,
  Error: null,
  Alarm: null,
  isRunning: null,
  isTripped: null,
  isRemote: null,
  isOffline: null,
  hasAlarm: null,
  selectedControl: -1,
  invLastStartTime: -1,
  invLastStartPowerAvgW: -1,
  invLastStartEnergyWh: -1,
  invTargetFrequency: -1,
  invTargetAin1: -1,
  invTargetAin2: -1,
}

interface IrriStatusStore {
  stations: any
  stationInverters: any
  setStations: (stations: any) => void
  setStationState: (plcState: any) => void
  updateStationStateOnEvents: (events: any[]) => void
  setStationInverters: (stationInverters: any) => void
  setStationInverterState: (invrtState: any) => void
}

const useIrriStatusStore = create<IrriStatusStore>((set) => ({

  stations: {},
  stationInverters: {},

  setStations: (stations) => set(state => ({stations: stations})),

  setStationState: (plcState: any) => set(state => {
    let stationCode = plcState.stationCode
    let newState: any = JSON.parse(JSON.stringify(state.stations))
    if (!newState[stationCode]) {
      let newStationState: any = {...defaultStationState, pump: {...defaultStationState.pump}, users: {...defaultStationState.users}}
      newStationState.stationCode = stationCode
      newState[stationCode] = newStationState
    }
    let stationState: any = newState[stationCode]

    stationState.lastUpdatedAt = new Date()
    stationState.automationActive = plcState.automationActive
    stationState.programActive = plcState.programActive

    stationState.pump.status = plcState.pump
    stationState.pump.grace = plcState.grace ? 1 : 0
    stationState.pump.slot = plcState.slot
    stationState.pump.slotStartTimestamp = plcState.startTimestamp
    stationState.pump.lastSlotEndTimestamp = plcState.lastEndTimestamp
    stationState.pump.slotUsers = plcState.slotUsers
    stationState.pump.startedSlotUsers = plcState.loggedInSlotUsers
    stationState.pump.enoughSlotUsers = plcState.getEnough
    stationState.pump.outputFlags = plcState.outputs
    stationState.pump.inputFlags = plcState.inputs
    stationState.pump.errorFlags = plcState.hwErrors
    stationState.pump.iMotRestartAttempts = plcState.iMotRestartAttempts

    plcState?.users?.forEach(u => {
      if (!stationState.users[u.userCode]) {
        stationState.users[u.userCode] = { userCode: u.userCode }
      }
      let userState = stationState.users[u.userCode]
      if (u.state === 'userSTATUS_LoggedIn') {
        userState.status = 1
      } else if (u.state === 'userSTATUS_LoggedOut') {
        userState.status = 0
      } else if (u.state === 'userSTATUS_Locked') {
        userState.status = 2
      } else {
        userState.status = -1
      }
    })
    Object.values(stationState.users)
    .forEach((u: any) => {
      if (plcState?.users.findIndex(plcu => plcu.userCode === u.userCode) === -1) {
        u.status = 0
      }
    })

    newState[stationCode] = stationState//do we need this line?
    return {stations: newState}
  }),

  updateStationStateOnEvents: (events: any[]) => set(state => {
    if (events.length > 0) {
      let stationCode = events[0].stationCode
      let newState: any = JSON.parse(JSON.stringify(state.stations))
      if (!newState[stationCode]) {
        let newStationState: any = {...defaultStationState, pump: {...defaultStationState.pump}, users: {...defaultStationState.users}}
        newStationState.stationCode = stationCode
        newState[stationCode] = newStationState
      }
      let stationState: any = newState[stationCode]//do we need to JSON.parse(JSON.stringify( again?
      let changed = false

      events.forEach(ev => {
        //update irrigation state
        switch (ev.event) {

          //(experimental)
          //In case of power shortage the station will be unreachable,
          //therefore we should recreate, at least its power-related error state from the 'powerDown' event.
          //We assume the power has been restored with either a 'powerUp' or a 'systemReboot' event.
          //In the case of the 'powerUp' or 'systemReboot' events, 
          //we could do nothing, since we will get the full state with a 'GetState' command that foolows such events,
          //but we choose to use them as well (otherwise a station would appear 'red' untill the 'GetState' response arrives).
          case 'powerDown':
            stationState.pump.errorFlags = 8
            changed = true
            break;
          case 'powerUp':
            if (stationState.pump.errorFlags === 8) {//if !== 8 then assume this is a result of 'GetState' response (more valid) hence do not override
              stationState.pump.errorFlags = 0
              changed = true
            }
            break;
          case 'systemReboot':
            if (stationState.pump.errorFlags === 8) {//if !== 8 then assume this is a result of 'GetState' response (more valid) hence do not override
              stationState.pump.errorFlags = 0
              changed = true
            }
            break;

          case 'programStarted':
            stationState.automationActive = true
            changed = true
            break;
          case 'programStopped':
            stationState.automationActive = false
            changed = true
            break;
          case 'timetableChanged':
            stationState.pump.earlyArrival = 0
            stationState.lastEndTimestamp = ev.slotNo
            changed = true
            break;
          case 'slotChanged':
            stationState.pump.refDatetime = ev.refDatetime
            stationState.pump.slot = ev.slotNo
            stationState.pump.slotStartTimestamp = null
            stationState.pump.slotUsers = null
            stationState.pump.startedSlotUsers = null
            stationState.pump.enoughSlotUsers = null
            stationState.pump.earlyArrival = 0
            changed = true
            break;
          case 'earlyArrival':
            stationState.pump.earlyArrival = 1
            changed = true
            break;
          case 'graceStarted':
            stationState.pump.grace = 1
            stationState.pump.graceStartedAt = ev.refDatetime
            stationState.pump.graceExpiredAt = null
            stationState.pump.enoughSlotUsers = false
            changed = true
            break;
          case 'graceStopped':
            stationState.pump.grace = 0
            stationState.pump.graceStartedAt = null
            stationState.pump.startedSlotUsers = stationState.pump.slotUsers
            stationState.pump.enoughSlotUsers = true
            changed = true
            break;
          case 'graceExpired':
            stationState.pump.grace = 0
            // stationState.pump.graceStartedAt = null
            stationState.pump.graceExpiredAt = ev.refDatetime
            stationState.pump.reason = 'graceExpired'
            changed = true
            break;
          case 'pumpStarted':
            stationState.pump.status = 1
            stationState.pump.reason = 'PLC'
            stationState.pump.refDatetime = ev.refDatetime
            stationState.pump.lastStartedAt = ev.refDatetime
            changed = true
            break;
          case 'pumpStartedOVR':
            if (!stationState.pump) {
              stationState.pump = {}
            }
            stationState.pump.status = 1
            stationState.pump.reason = 'OVR'
            stationState.pump.refDatetime = ev.refDatetime
            stationState.pump.lastStartedAt = ev.refDatetime
            changed = true
            break;
          case 'pumpStopped':
            if (!stationState.pump) {
              stationState.pump = {}
            }
            stationState.pump.status = 0
            // stationState.pump.reason = 'PLC'//let other events update the reason
            stationState.pump.refDatetime = ev.refDatetime
            stationState.pump.lastStoppedAt = ev.refDatetime
            changed = true
            break;
          case 'pumpStoppedOVR':
            if (!stationState.pump) {
              stationState.pump = {}
            }
            stationState.pump.status = 0
            stationState.pump.reason = 'OVR'
            stationState.pump.refDatetime = ev.refDatetime
            stationState.pump.lastStoppedAt = ev.refDatetime
            changed = true
            break;
          case 'userStarted':
            if (!stationState.users[ev.userCode]) {
              stationState.users[ev.userCode] = { userCode: ev.userCode }
            }
            stationState.users[ev.userCode].status = 1
            stationState.users[ev.userCode].reason = 'RF'
            stationState.users[ev.userCode].refDatetime = ev.refDatetime
            stationState.users[ev.userCode].lastStartedAt = ev.refDatetime
            changed = true
            break;
          case 'userStartedOVR':
            if (!stationState.users[ev.userCode]) {
              stationState.users[ev.userCode] = { userCode: ev.userCode }
            }
            stationState.users[ev.userCode].status = 1
            stationState.users[ev.userCode].reason = 'OVR'
            stationState.users[ev.userCode].refDatetime = ev.refDatetime
            stationState.users[ev.userCode].lastStartedAt = ev.refDatetime
            changed = true
            break;
          case 'userStopped':
            if (!stationState.users[ev.userCode]) {
              stationState.users[ev.userCode] = { userCode: ev.userCode }
            }
            stationState.users[ev.userCode].status = 0
            stationState.users[ev.userCode].reason = 'RF'
            stationState.users[ev.userCode].refDatetime = ev.refDatetime
            stationState.users[ev.userCode].lastStoppedAt = ev.refDatetime
            changed = true
            break;
          case 'userStoppedOVR':
            if (!stationState.users[ev.userCode]) {
              stationState.users[ev.userCode] = { userCode: ev.userCode }
            }
            stationState.users[ev.userCode].status = 0
            stationState.users[ev.userCode].reason = 'OVR'
            stationState.users[ev.userCode].refDatetime = ev.refDatetime
            stationState.users[ev.userCode].lastStoppedAt = ev.refDatetime
            changed = true
            break;
          case 'userLocked':
            if (!stationState.users[ev.userCode]) {
              stationState.users[ev.userCode] = { userCode: ev.userCode }
            }
            stationState.users[ev.userCode].status = 2
            stationState.users[ev.userCode].reason = 'RF'
            stationState.users[ev.userCode].refDatetime = ev.refDatetime
            stationState.users[ev.userCode].lockedAt = ev.refDatetime
            changed = true
            break;
          case 'userUnlocked':
            if (!stationState.users[ev.userCode]) {
              stationState.users[ev.userCode] = { userCode: ev.userCode }
            }
            stationState.users[ev.userCode].status = 0
            stationState.users[ev.userCode].reason = 'OVR'
            stationState.users[ev.userCode].refDatetime = ev.refDatetime
            stationState.users[ev.userCode].lockedAt = null
            changed = true
            break;
          default:
            break;
        }
      })
      /* Store timestamp of state update.
       * Better use controller' time here (i.e. refDatetime) instead of hydoor time (i.e. createdAt). */
      if (changed) {
        stationState.lastUpdatedAt = new Date(events[events.length - 1].refDatetime) 
      } else {
        if (events[events.length - 1].refDatetime) {//
          stationState.lastUpdatedAt = new Date(events[events.length - 1].refDatetime)
        }
        console.log(`updateStationStateOnEvents stationState Unchanged`)
      }
      return {stations: newState}
    } else {
      return state
    }
  }),

  setStationInverters: (stationInverters) => set(state => ({stationInverters: stationInverters})),

  setStationInverterState: (invrtState: any) => set(state => {
    const stationCode = invrtState.stationCode
    const allStates: any = JSON.parse(JSON.stringify(state.stationInverters))
    let stationState: any = allStates[invrtState.stationCode]
    if (!stationState) {
      stationState = JSON.parse(JSON.stringify(defaultStationInverterState))
      stationState.stationCode = stationCode
      allStates[stationCode] = stationState
    }
    // stationState.lastUpdatedAt = refDate
    stationState = {...stationState, ...invrtState}
    allStates[stationCode] = stationState //we need this line because in the previous line we created a new stationState object by using the spread operator
    return {stationInverters: allStates}
  }),
}))

export { useIrriStatusStore }
