import { library, dom, IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faAward,
    faBars,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCodeBranch,
    faCog,
    faDatabase,
    faDrawPolygon,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEye,
    faEyeSlash,
    // faFaucet,
    faFilter,
    faFlask,
    faGasPump,
    faGlobeEurope,
    // faHandHoldingWater,
    faHatCowboy,
    faInbox,
    faListOl,
    faListUl,
    faLock,
    faLockOpen,
    faMapMarked,
    faMapMarkerAlt,
    faMinus,
    faPen,
    faPlus,
    faSave,
    faScrewdriver,
    faSearch,
    faShower,
    faSignInAlt,
    faSwimmingPool,
    faSyncAlt,
    faTachometerAlt,
    faTemperatureHigh,
    faTimes,
    faTint,
    faTools,
    faTrash,
    faUndo,
    faUserCircle,
    faUsers,
    faUsersCog,
    faUserSecret,
    faUserTie,
    faWater,
} from '@fortawesome/free-solid-svg-icons'
import {
    faGoogle,
    faMicrosoft,
} from '@fortawesome/free-brands-svg-icons'

// const faGoogleN: IconDefinition = <IconDefinition>faGoogle;
function initFontAwesome() {
  // console.log('initFontAwesome')
  library.add(
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faAward,
    faBars,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCodeBranch,
    faCog,
    faDatabase,
    faDrawPolygon,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEye,
    faEyeSlash,
    // faFaucet,
    faFilter,
    faFlask,
    faGasPump,
    faGlobeEurope,
    // faHandHoldingWater,
    faHatCowboy,
    faInbox,
    faListOl,
    faListUl,
    faLock,
    faLockOpen,
    faMapMarked,
    faMapMarkerAlt,
    faMinus,
    faPen,
    faPlus,
    faSave,
    faScrewdriver,
    faSearch,
    faShower,
    faSignInAlt,
    faSwimmingPool,
    faSyncAlt,
    faTachometerAlt,
    faTemperatureHigh,
    faTimes,
    faTint,
    faTools,
    faTrash,
    faUndo,
    faUserCircle,
    faUsers,
    faUsersCog,
    faUserSecret,
    faUserTie,
    faWater,
    // faGoogle,
    // faGoogleN,
    // <IconDefinition>faGoogle,
    // <IconDefinition>faMicrosoft,
    faGoogle as IconDefinition,
    faMicrosoft as IconDefinition,
  )
  dom.watch()
}

export default initFontAwesome
