import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import { useCollectionStore } from '../../state/collectionStore'
import { useUiStore } from '../../state/uiStore'
import useCoords from '../../utils/useCoords'
import WrmIcon from '../WrmIcon'
import PipeNodes from './PipeNodes'
import CoordinatesEditor from './CoordinatesEditor'
import MasterDetail from '../masterdetail/MasterDetail'

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'auto',
  },
  area: {
    color: theme.palette.tertiary.main,
  },
  editBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#828282',
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
    marginRight: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    marginRight: '1rem',
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
  },
  clearBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
    marginRight: 'auto',
  },
  dimsBox: {
    backgroundColor: '#FBFBFB',
    border: `1px solid ${theme.palette.tertiary.light}`,
    borderRadius: 2,
    marginTop: theme.spacing(1),
  },
  bottomDivider: {
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
  },
  flowContainer:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    marginTop:'0.25rem',
    // paddingBottom:'0.5rem'
  },
  btnFlowEdit:{
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#828282',
    marginLeft:'auto',
    width: theme.spacing(14),
  }
}))

const Geometry = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const XYZ = useCoords()

  const appSettings = useCollectionStore(state=>state.appSettings)
  const [isEdittingCoordinates, setIsEditingCoordinates] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any | null>(null)
  // const [edited, setEdited] = useState(false)
  const [editingPipeNodes, setEditingPipeNodes] = useState(false)
  const [meta, setMeta] = useState<any | null>(null)
  const [data, setData] = useState<any[] | null>([])
  const isEditingGeometry = useUiStore(state => state.isEditingGeometry)
  const isDrawingGeometry = useUiStore(state => state.isDrawingGeometry)

  const metadata: any = {
    pageSize: props.pageSize ?? 1,
    noTitleBar: true,
    hidePaging: true,
    hideDetails: true,
    defaultSortBy: 'label',
    defaultSortOrder: 'asc',
    compact: true,
    columns: [
      {code: 'label', label: 'ne.geometry.point', type: 'string',
        editable: false, searchable: true, filterable: true,
        isId: true,
      },
      {code: 'lng', label: 'ne.geometry.longtitude.short',
        type: 'number', format: '99.9999999',
        editable: false, searchable: true, filterable: true,
      },
      {code: 'lat', label: 'ne.geometry.latitude.short',
        type: 'number', format: '99.9999999',
        editable: false, searchable: true, filterable: true,
      },
      {code: 'alt', label: 'ne.geometry.altitude',
        type: 'number', format: '9999.9', unit: 'units.length.m',
        editable: false, searchable: true, filterable: true,
      },
    ],
  }
  const prepareData = () => {
    let tdata = parseFeatureCoords()
    if (metadata.pageSize < tdata.length) {
      metadata.pageSize = tdata.length
      setMeta(metadata)
    }
    setData(tdata)
  }
  const prepareMeta = () => {
    if (appSettings.coordinatesSystem === 'egsa87') {
      metadata.columns[1].label = 'ne.geometry.longtitudeX.short'
      metadata.columns[1].format = '999999.999'
      metadata.columns[2].label = 'ne.geometry.latitudeY.short'
      metadata.columns[2].format = '999999.999'
    } else if (appSettings.coordinatesSystem === 'wgs84DMS') {
      metadata.columns[1].type = 'string'
      metadata.columns[2].type = 'string'
    }
    setMeta(metadata)
  }

  useEffect(() => {
    prepareMeta()
  }, [appSettings.coordinatesSystem])
  useEffect(() => {
    prepareData()
  }, [props.feature, appSettings.coordinatesSystem])

  const getPointLabel = (order) => {
    const pointLetters = t('net.pointLetters')
    let modulator = pointLetters.length
    return `${order < modulator ? '' : pointLetters[Math.floor(order / modulator) - 1]}${pointLetters[order % modulator]}`
  }
  const parseFeatureCoords = () => {
    if (!props.feature) return []
    let tcoords: any[] = []
    let geo = props.feature.geometry
    let coordinatesSystem = appSettings.coordinatesSystem

    if (geo.type === 'Point') {
      geo = { ...geo, coordinates: XYZ(coordinatesSystem, geo.coordinates) }
      let c = {
        label: getPointLabel(0),
        lng: geo.coordinates[0],
        lat: geo.coordinates[1],
        alt: null,
      }
      if (geo.coordinates.length === 3) c.alt = geo.coordinates[2]
      tcoords.push(c)
    } else if (geo.type === 'LineString') {
      geo = {
        ...geo,
        coordinates: geo.coordinates.map(xy => XYZ(coordinatesSystem, xy)),
      }
      tcoords = geo.coordinates.map((p, i) => {
        let c = { label: getPointLabel(i), lng: p[0], lat: p[1], alt: null }
        if (p.length === 3) c.alt = p[2]
        return c
      })
    } else if (geo.type === 'Polygon') {
      geo = {
        ...geo,
        coordinates: [geo.coordinates[0].map(xy => XYZ(coordinatesSystem, xy))],
      }
      geo.coordinates[0].forEach((p, i) => {
        let c = { label: getPointLabel(i), lng: p[0], lat: p[1], alt: null }
        if (p.length === 3) c.alt = p[2]
        tcoords.push(c)
      })
      tcoords.pop()
    }
    return tcoords
  }
  const buildFeature = () => {
    if (!props.feature) return []
    let tcoords = parseFeatureCoords()
    console.log(`buildFeature coords ${JSON.stringify(tcoords,null,2)}`)
    let feature = Object.assign({}, props.feature)
    console.log(`buildFeature feature bef ${JSON.stringify(feature,null,2)}`)
    let geo = props.feature.geometry
    if (geo.type === 'Point') {
      feature.geometry.coordinates = [parseFloat(tcoords[0].lng), parseFloat(tcoords[0].lat)]
      if (!!tcoords[0].alt) feature.geometry.coordinates.push(parseFloat(tcoords[0].alt))
    } else if (geo.type === 'LineString') {
      feature.geometry.coordinates = tcoords.map(c => {
        let p = [parseFloat(c.lng), parseFloat(c.lat)]
        if (!!c.alt) p.push(parseFloat(c.alt))
        return p
      })
    } else if (geo.type === 'Polygon') {
      feature.geometry.coordinates = []
      feature.geometry.coordinates.push(tcoords.map(c => {
        let p = [parseFloat(c.lng), parseFloat(c.lat)]
        if (!!c.alt) p.push(parseFloat(c.alt))
        return p
      }))
      feature.geometry.coordinates[0].push(feature.geometry.coordinates[0][0])
    }
    console.log(`buildFeature feature aft ${JSON.stringify(feature,null,2)}`)
    return feature
  }

  const handleCancelDrawing = () => {
    if (!!props.onCancelDrawing) props.onCancelDrawing()
  }
  const handleCancelFeature = () => {
    // setEdited(false)
    if (!!props.onCancel) props.onCancel()
  }
  const handleOnApplyClick = () => {
    if (props.readonly) {
      if (!!props.onApply) props.onApply()
      if (props.feature.properties.featureType === 'pipe') props.setFlowPopupIsOpen(true)
    } else {
      let feature = buildFeature()
      if (!!props.onApply) props.onApply(feature)
    }
    // setEdited(false)
  }
  const handleOnEditClick = () => {
    // setEdited(true)
    if (!!props.onEdit) props.onEdit()
  }
  const handleEditPipeNodesClick = () => {
    setEditingPipeNodes(true)
    // if (!!props.onEdit) props.onEdit()
  }
  const handleApplyPipeNodesClick = () => {
    setEditingPipeNodes(false)
  }
  const handleCancelPipeNodesClick = () => {
    setEditingPipeNodes(false)
  }
  const canCancel = () => {
    if (!props.feature) return false
    let geo = props.feature.geometry
    let can = geo.coordinates && geo.coordinates.length > 0
    return can
  }
  const canApply = () => {
    if (!props.feature) return false
    let can = false
    let geo = props.feature.geometry
    if (geo.type === 'Point') {
      can = !!geo.coordinates
    } else if (geo.type === 'LineString') {
      can = geo.coordinates.length > 1
    } else if (geo.type === 'Polygon') {
      can = geo.coordinates[0].length > 3//polygons contain the first point twice!
    }
    return can
  }

  const isEditing = isEditingGeometry || isDrawingGeometry
  const isFreeDrawnFeature = !!props.feature && !props.feature?.properties?.featureType

  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      className={clsx(classes.root, 'MTableContainer WrmGeometry WrmMeasurements')}
    >
      {!!meta &&
      <Box
        display='flex'
      >
        <MasterDetail
          key={data?.length} //so that it re-renders when editing
          data={data}
          meta={meta}
          selectedDataItem={selectedRow}
          setSelectedDataItem={setSelectedRow}
        />
      </Box>
      }
      <Box
        display='flex'
        flexDirection='column'
        className={classes.dimsBox}
      >
        {!!props.feature && !!props.feature.transient_length &&
        <Box className={classes.area} display='flex' justifyContent='space-between' py={1} px={4}>
            <Box>{props.feature.geometry.type === 'Polygon' ? t('measurements.length.perimeter') : t('measurements.length')} ({t('units.length.m')})</Box>
            <Box>{props.feature.transient_length.toLocaleString('el', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Box>
        </Box>}
        {!!props.feature && !!props.feature.transient_area && <Box className={classes.area} display='flex' justifyContent='space-between' py={1} px={4}>
          <Box>{t('measurements.area')} ({t('units.area.m2x1K')})</Box>
          <Box>{props.feature.transient_area.toLocaleString('el', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Box>
        </Box>}
        {!!props.feature && !!props.feature.transient_elevationDiff && <Box className={classes.area} display='flex' justifyContent='space-between' py={1} px={4}>
          <Box>{t('measurements.length.elevationDifference')} ({t('units.length.m')})</Box>
          <Box>{props.feature.transient_elevationDiff.toLocaleString('el', {minimumFractionDigits: 1, maximumFractionDigits: 1})}</Box>
        </Box>}
      </Box>
      {isEdittingCoordinates && !isEditing && !isEditingGeometry && 
        <Box>
          <CoordinatesEditor 
            feature={props.feature} 
            isEdittingCoordinates={isEdittingCoordinates}
            setIsEditingCoordinates={setIsEditingCoordinates}
          />
        </Box>
      }
      {/* Edit flow button */}
      {props?.feature?.properties?.featureType === 'pipe' &&
      <Box py={1} px={1.5}
        display='flex'
        flexDirection='column'
        className={classes.dimsBox}
      >
        <Typography align='left' >{ t('pipe.flowDirection')}</Typography>
        <Box
          className={classes.flowContainer}
        >
          <WrmIcon icon='flow' style={{ width: 24, height: 24, verticalAlign: 'middle',margin:'0 1rem' }} />
          <Typography style={{color:'#979797'}}>
            {(() => {
              let labels = [getPointLabel(0), getPointLabel(props.feature.geometry.coordinates.length - 1)]
              if (props?.feature?.properties?.flowDirection === 'reverse') labels.reverse()
              return `${t('ne.geometry.pipeFlow.from')} ${labels[0]} ${t('ne.geometry.pipeFlow.to')} ${labels[1]}`
            })()}
          </Typography>
          <Button
            variant='outlined'
            className={classes.btnFlowEdit}
            disabled={isEditing || props.hideButtons || editingPipeNodes}
            onClick={(e)=>props.setFlowPopupIsOpen(true)}
          >
            {t('btn.edit')}
          </Button>
        </Box>
      </Box>}
      {props?.feature?.properties?.featureType === 'pipe' &&
      <PipeNodes
        pipe={props.feature}
        featureTypes={['hydrant', 'junction', 'pump', 'reservoir', 'switch', 'tank', 'valve']}
        onEdit={handleEditPipeNodesClick}
        onApply={handleApplyPipeNodesClick}
        onCancel={handleCancelPipeNodesClick}
        readonly={isEditing || props.hideButtons || props.flowPopupIsOpen}
      />}
      {(isEditing || !isFreeDrawnFeature) &&
      <Divider
        variant='middle'
        className={classes.bottomDivider}
      />}
      {!props.hideButtons &&
      <Box
        display='flex'
        justifyContent= {props?.feature?.geometry?.type  === 'Point' && !isEditingGeometry ? 'space-around' : 'flex-end'}
      > 
        {props?.feature?.geometry?.type === 'Point' && !isEditingGeometry && !isDrawingGeometry && !isFreeDrawnFeature &&
          <Button
            variant='outlined'
            disabled={isEdittingCoordinates}
            className={classes.editBtn}
            onClick={()=>setIsEditingCoordinates(true)}
          >
          {t('btn.editCoordinates')}
          </Button>
        }
        {!isEditing && !isFreeDrawnFeature &&
        <Button
          variant='outlined'
          disabled={props.flowPopupIsOpen || editingPipeNodes || isEdittingCoordinates }
          className={classes.editBtn}
          onClick={handleOnEditClick}
        >
          {t('btn.editLocationOnMap')}
        </Button>}

        {isDrawingGeometry && canCancel() &&
        <Button
          variant='outlined'
          className={classes.clearBtn}
          onClick={handleCancelFeature}
        >
          {t('btn.clear')}
        </Button>}

        {isEditingGeometry && canCancel() &&
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          onClick={handleCancelFeature}
        >
          {t('btn.cancel')}
        </Button>}
        {isDrawingGeometry &&
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          onClick={handleCancelDrawing}
        >
          {t('btn.cancel')}
        </Button>}

        {isEditing && canApply() &&
        <Button
          className={classes.applyBtn}
          onClick={handleOnApplyClick}
        >
          {t('btn.apply')}
        </Button>}

      </Box>}
    </Box>
  )

}

export default Geometry
