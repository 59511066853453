import { MaintenanceEvent, MaintenancePlan, MaintenancePlanUnscheduled } from "../models/maintenancePlan"
var _ = require('lodash');

const MaintenanceService = (apiClient) => {

  const pathTasks = 'workFlow/'
  const pathDamage = 'repairType/'
  const pathNetworkRepairs = 'networkrepairs/'
  const pathMaintenanceReport = 'networkRepairsAndMaintenanceReport/'
  const pathMaintenancePlans = 'maintenanceplans/'

  const getMaintenanceDataItemsByCode = (activeTabCode) => {
    let subPath;
    if(activeTabCode === 'damagesandtasks'){
      subPath = `${pathTasks}${activeTabCode}`
    } else if(activeTabCode === 'networkrepairs'){
      subPath = `${activeTabCode}`
    } else if(activeTabCode === 'maintenanceplans'){
      subPath = `${activeTabCode}`
    } else {
      subPath = pathTasks
    }
    let cachePromise = true
    return sendGetRequest(subPath, cachePromise)
  }

  const getMaintenanceItemByID = (activeTabCode, dataItem) => {
    let subPath;
    if(activeTabCode === 'damagesandtasks'){
      if(dataItem?.category === 'damage'){
        subPath = `${pathDamage}${dataItem?._id}`
      } else {
        subPath = `${pathTasks}${dataItem?._id}`
      }
    } else if(activeTabCode === 'networkrepairs'){
      subPath = `${pathNetworkRepairs}${dataItem?._id}`
    } else if(activeTabCode === 'maintenanceplans'){
      subPath = `${pathMaintenancePlans}${dataItem?._id}`
    }
    return !!dataItem && sendGetRequest(subPath)
  }

  const getMaintenanceEventsByID = (plan_id) => {
    let subPath;
    subPath = `${pathMaintenancePlans}${plan_id}/events`
    return sendGetRequest(subPath)
  }

  const getMaintenanceEventByID = (activeTabCode, dataItem) => {
    let subPath;
    if(activeTabCode === 'maintenanceplans'){
      subPath = `${pathMaintenancePlans}events/${dataItem?._id}`
    }
    return !!dataItem && sendGetRequest(subPath)
  }

  const saveDamageAndTaskItem = (item) => {
    let data: any = {...item}
    delete data['category']
    let pathAdd = '';
    if(item['category'] === 'taskSequence') {
      pathAdd = pathTasks
    } else if(item['category'] === 'damage') {
      pathAdd = pathDamage
    }
    return postRequest(data, pathAdd)
  }

  const updateDamageAndTaskItem = (item) => {
    let data: any = {...item}
    delete data['category']
    let pathAdd = '';
    if(item['category'] === 'taskSequence') {
      pathAdd = pathTasks
    } else if(item['category'] === 'damage') {
      pathAdd = pathDamage
    }
    return putRequest(data, pathAdd)
  }

  const deleteDataItem = (id, category, activeTabCode) => {
    let subPath = '';
    if(activeTabCode === 'damagesandtasks'){
      if(category === 'damage'){
        subPath = `${pathDamage}/${id}`
      } else {
        subPath = `${pathTasks}/${id}`
      }
    }
    return deleteRequest(subPath)
  }

  const saveDamageAndTaskNote = (damageAndTaskNote, category) => {
    let data: any = damageAndTaskNote
    let pathAdd = '';
    if(category === 'taskSequence') {
      pathAdd = `${pathTasks}note`
    } else if(category === 'damage') {
      pathAdd = `${pathDamage}note`
    }
    return postAddNoteRequest(data, pathAdd)
  }

  const editDamageAndTaskNote = (damageAndTaskNote, category) => {
    let data: any = damageAndTaskNote
    let pathEdit = '';
    if(category === 'taskSequence') {
      pathEdit = `${pathTasks}note`
    } else if(category === 'damage') {
      pathEdit = `${pathDamage}note`
    }
    return putAddNoteRequest(data, pathEdit)
  }

  const deleteDamageAndTaskNote = (damageAndTask_id, note_id, category) => {
    let pathRemove = ''
    if(category === 'taskSequence') {
      pathRemove = `${pathTasks}${damageAndTask_id}/note/${note_id}`
    } else if(category === 'damage') {
      pathRemove = `${pathDamage}${damageAndTask_id}/note/${note_id}`
    }
    return deleteNoteRequest(pathRemove)
  }

  // network repair notes start
  const saveNetworkRepairNote = (networkRepairItemNote) => {
    let data: any = networkRepairItemNote
    let pathAdd = `${pathNetworkRepairs}note`
    return postAddNetworkRepairNoteRequest(data, pathAdd)
  }

  const editNetworkRepairNote = (networkRepairItemNote) => {
    let data: any = networkRepairItemNote
    let pathEdit = `${pathNetworkRepairs}note`
    return putAddNetworkRepairNoteRequest(data, pathEdit)
  }

  const deleteNetworkRepairNote = (networkRepairItem_id, note_id) => {
    let pathRemove = `${pathNetworkRepairs}${networkRepairItem_id}/note/${note_id}`
    return deleteNetworkRepairNoteRequest(pathRemove)
  }
  // network repair notes end

  // maintenance plan notes start
  const saveMaintenancePlanNote = (maintenancePlanItemNote) => {
    let data: any = maintenancePlanItemNote
    let pathAdd = `${pathMaintenancePlans}note`
    return postAddNetworkRepairNoteRequest(data, pathAdd)
  }

  const editMaintenancePlanNote = (maintenancePlanItemNote) => {
    let data: any = maintenancePlanItemNote
    let pathEdit = `${pathMaintenancePlans}note`
    return putAddNetworkRepairNoteRequest(data, pathEdit)
  }

  const deleteMaintenancePlanNote = (maintenancePlanItem_id, note_id) => {
    let pathRemove = `${pathMaintenancePlans}${maintenancePlanItem_id}/note/${note_id}`
    return deleteNetworkRepairNoteRequest(pathRemove)
  }
  // maintenance plan notes end

  const getMaintenanceEventTasks = (dataItemId) => {
    let subPath = `${pathMaintenancePlans}events/${dataItemId}/tasks`
    return sendGetRequest(subPath)
  }

  const updateMaintenancePlan = (item: any) => {
    let data;
    if(item?.scheduleType === "unscheduled"){
      data = new MaintenancePlanUnscheduled();
    } else {
      data = new MaintenancePlan();
    }
    _.assign(data , _.pick(item, _.keys(data)));
    let subPath = `${pathMaintenancePlans}`
    return putRequest(data, subPath)
  }

  const updateMaintenanceEvent = (item) => {
    let data = new MaintenanceEvent()
    _.assign(data , _.pick(item, _.keys(data)));
    let subPath = `${pathMaintenancePlans}events`
    return putRequest(data, subPath)
  }

  const updateMaintenanceEventTasks = (dataItem, taskData) => {
    let data: any = {
      _id: dataItem?._id,
      maintenanceTasks: taskData
    }
    let subPath = `${pathMaintenancePlans}events/tasks`
    // console.log(data, subPath)
    return !!dataItem && putRequest(data, subPath)
  }

  // network repair task start
  const getNetworkRepairTasks = (dataItemId) => {
    let subPath = `${pathNetworkRepairs}tasks/${dataItemId}`
    return sendGetRequest(subPath)
  }

  const editNetworkRepairTasks = (dataItemId, taskData) => {
    let data: any = taskData
    let pathEdit = `${pathNetworkRepairs}tasks/${dataItemId}`
    return putRequest(data, pathEdit)
  }

  const updateNetworkRepairTasks = (dataItem, taskData) => {
    let data: any = {
      _id: dataItem?._id,
      code: dataItem.code,
      repairTasks: taskData
    }
    return !!dataItem && putRequest(data, pathNetworkRepairs)
  }

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] == null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const updateNetworkRepair = (item) => {
    let data: any = {
      _id: item?._id,
      code: item.code,
      status: item.status,
      category: item.category,
      wszoneCode: item.wszoneCode,
      partsUsed: item.partsUsed,
      address: item.address,
      importance: item.importance,
      responsible_id: item.responsible_id,
      cost: item.cost,
      comment: item.comment,
      attachments: item.attachments
    }
    return !!item && putRequest(data, pathNetworkRepairs)
  }

  const saveNetworkRepair = (networkRepairAdd) => {
    let data: any = clean(networkRepairAdd)
    let pathAdd = `${pathNetworkRepairs}`
    return postNetworkRepairRequest(data, pathAdd)
  }

  // get repair type
  const getRepairTypes = () => {
    let subPath = pathDamage;
    let cachePromise = true
    return sendGetRequest(subPath, cachePromise)
  }

  // get tasks
  const getTaskSequence = () => {
    let subPath = pathTasks;
    let cachePromise = true
    return sendGetRequest(subPath, cachePromise)
  }

  // network repair task end

  const postAddNoteRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  const putAddNoteRequest = (data, pathEdit) => {
    return apiClient.sendPutRequest(data, pathEdit, null, true)
  }

  const deleteNoteRequest = (pathRemove) => {
    return apiClient.sendDeleteRequest(pathRemove, null, true, null)
  }

  const sendGetRequest = (path: string, cachePromise?: boolean) => {
    return apiClient.sendGetRequest(cachePromise, path, null, true)
  }

  const deleteRequest = (url) => {
    return apiClient.sendDeleteRequest(url, null, true)
  }

  const postRequest = (data, path) => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  const putRequest = (data, path) => {
    return apiClient.sendPutRequest(data, path, null, true)
  }

  const deleteMaintenancePlan= (id) => {
    let  subPath = `${pathMaintenancePlans}/${id}`;
    return deleteRequest(subPath)
  }

  const deleteMaintenanceEvent= (id) => {
    let subPath = `${pathMaintenancePlans}events/${id}`
    return deleteRequest(subPath)
  }

  // network repair notes
  const postAddNetworkRepairNoteRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  const putAddNetworkRepairNoteRequest = (data, pathEdit) => {
    return apiClient.sendPutRequest(data, pathEdit, null, true)
  }

  const deleteNetworkRepairNoteRequest = (pathRemove) => {
    return apiClient.sendDeleteRequest(pathRemove, null, true, null)
  }
  // network repair notes end

  // add maintenance report
  const postMaintenanceReport = (reportRequest) => {
    return apiClient.sendRequest(reportRequest, false, pathMaintenanceReport, null, true)
  }

   // add network repair
   const postNetworkRepairRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }

  // add maintenance plan
  const saveMaintenancePlan = (maintenancePlanAdd) => {
    let data: any = maintenancePlanAdd
    let pathAdd = `${pathMaintenancePlans}`
    return postMaintenancePlanRequest(data, pathAdd)
  }

  // add maintenance plan
  const postMaintenancePlanRequest = (data, pathAdd) => {
    return apiClient.sendRequest(data, false, pathAdd, null, true)
  }


  return({
    getMaintenanceDataItemsByCode: getMaintenanceDataItemsByCode,
    getMaintenanceItemByID: getMaintenanceItemByID,
    saveDamageAndTaskNote: saveDamageAndTaskNote,
    editDamageAndTaskNote:editDamageAndTaskNote,
    deleteDamageAndTaskNote: deleteDamageAndTaskNote,
    deleteDataItem: deleteDataItem,
    saveDamageAndTaskItem: saveDamageAndTaskItem,
    updateDamageAndTaskItem: updateDamageAndTaskItem,
    saveNetworkRepairNote: saveNetworkRepairNote,
    editNetworkRepairNote: editNetworkRepairNote,
    deleteNetworkRepairNote: deleteNetworkRepairNote,
    getNetworkRepairTasks: getNetworkRepairTasks,
    editNetworkRepairTasks: editNetworkRepairTasks,
    updateNetworkRepairTasks: updateNetworkRepairTasks,
    updateNetworkRepair:updateNetworkRepair,
    saveNetworkRepair: saveNetworkRepair,
    getRepairTypes: getRepairTypes,
    postMaintenanceReport: postMaintenanceReport,
    saveMaintenancePlanNote: saveMaintenancePlanNote,
    editMaintenancePlanNote: editMaintenancePlanNote,
    deleteMaintenancePlanNote: deleteMaintenancePlanNote,
    saveMaintenancePlan: saveMaintenancePlan,
    getTaskSequence: getTaskSequence,
    getMaintenanceEventsByID: getMaintenanceEventsByID,
    getMaintenanceEventByID: getMaintenanceEventByID,
    getMaintenanceEventTasks: getMaintenanceEventTasks,
    updateMaintenanceEventTasks: updateMaintenanceEventTasks,
    deleteMaintenancePlan: deleteMaintenancePlan,
    deleteMaintenanceEvent: deleteMaintenanceEvent,
    updateMaintenancePlan: updateMaintenancePlan,
    updateMaintenanceEvent: updateMaintenanceEvent
  })

}

export default MaintenanceService
