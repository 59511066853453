import React, { useState, useEffect } from 'react'
import { useCommonStore } from '../../state/commonStore'
import useFeatureCollection from '../../hooks/useFeatureCollection'
import FeatureService from '../../services/FeatureService'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import { FTC } from '../../utils/constants'
import { NetworkElementCollection } from '../../utils/types/networkElementTypes'
const useStyles = makeStyles(theme => ({
  pnRoot: {
    backgroundColor: '#FBFBFB',
    border: `1px solid ${theme.palette.tertiary.light}`,
    borderRadius: 2,
  },
  autoRoot: {
    minWidth: 350,
    maxWidth: 350,
    textAlign: 'left',
  },
  autoInputRoot: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
  },
  autoInput: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    fontSize: 14,
  },
  autoPopper: {
    whiteSpace: 'pre-wrap',
  },
  autoList: {
    fontSize: 14,
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(14),
    marginLeft: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(14),
    marginLeft: 'auto',
    color: theme.palette.tertiary.main,
  },
  nsRoot: {
    // display: 'flex',
    // flexDirection: 'column',
  },
  typoShort: {
    width: theme.spacing(7),
    color: theme.palette.tertiary.main,
  },
  typoLong: {
    color: theme.palette.tertiary.main,
  },
  typoDark: {
    color: theme.palette.tertiary.dark,
  },
}))

const PipeNodes = props => {
  const showAlert = useCommonStore(state => state.showAlert)
  const featureService = FeatureService()
  const { t } = useTranslation()
  const classes = useStyles()
  const networkElementCollection = useFeatureCollection()
  const chooseNodeOption = {code: '', label: t('options.choose'), group: ''}
  const nodeIds = {startNode: 'in', endNode: 'out', none: 'none'}

  const [editingNodeId, setEditingNodeId] = useState(nodeIds.none)
  const [nodeOptions, setNodeOptions] = useState<any[]>([])
  // const [endSelection, setEndSelection] = useState(chooseNodeOption)

  const NodeSelector = nsprops => {

    const [nodeSelection, setNodeSelection] = useState(nsprops.selectedOption)
    const [moveNode, setMoveNode] = useState(true)

    const handleNodeChange = (event, newVal) => {
      if (!newVal) {
        newVal = chooseNodeOption
      }
      setNodeSelection(newVal)
      console.log(`handleNodeChange ${JSON.stringify(newVal,null,2)}`)
    }

    const handleMoveNodeChange = (doChange: boolean) => {
      if (doChange) {
        setMoveNode(!moveNode)
      }
    }

    return (
      <Box
        py={2}
        display='flex'
        flexDirection='column'
        className={classes.nsRoot}
      >
        <Box
          display='flex'
          alignItems='center'
          height={42}
        >
          <Typography
            align='left'
            className={classes.typoShort}
          >
            {t(`pipe.edit.node.${editingNodeId}`)+':'}
          </Typography>
          <Autocomplete
            className={classes.autoRoot}
            classes={{
              inputRoot: classes.autoInputRoot,
              input: classes.autoInput,
              popper: classes.autoPopper,
              listbox: classes.autoList,
            }}
            // fullWidth
            // multiple
            // disableCloseOnSelect
            clearOnEscape
            options={nsprops.options}
            groupBy={(option) => t(`net.${option.group}.plural`)}
            noOptionsText={t('md.autocomplete.noOptions')}
            getOptionSelected={(o, v) => o.code === v.code}
            getOptionDisabled={o => !o.code}
            value={nodeSelection}
            onChange={handleNodeChange}
            getOptionLabel={(option:any) => option.label}
            renderInput={(params) => (<TextField {...params} variant='outlined' />)}
          />
        </Box>
        <Box pl={6}
          display='flex'
          alignItems='center'
          height={42}
        >
          <CheckBoxNt
            checked={moveNode}
            onChange={event => handleMoveNodeChange(event.target.checked && !moveNode)}
          />
          <Typography
            align='left'
            className={classes.typoLong}
          >
            {t(`pipe.edit.node.${editingNodeId}.move`)}
          </Typography>
        </Box>
        <Box pl={6}
          display='flex'
          alignItems='center'
          height={42}
        >
          <CheckBoxNt
            checked={!moveNode}
            onChange={event => handleMoveNodeChange(event.target.checked && moveNode)}
          />
          <Typography
            align='left'
            className={classes.typoLong}
          >
            {t(`pipe.edit.node.${editingNodeId}.add`)}
          </Typography>
        </Box>
        <Box
          pr={2}
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          height={42}
        >
          <Button
            variant='outlined'
            className={classes.cancelBtn}
            onClick={() => nsprops.onCancel()}
          >
            {t('btn.cancel')}
          </Button>
          <Button
            disabled={props.isSavingEdits}
            className={classes.applyBtn}
            onClick={() => nsprops.onApply(nodeSelection, moveNode)}
          >
            {t('btn.apply')}
          </Button>
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    if (!!networkElementCollection && !!props.featureTypes) {
      let options = buildNodeOptions(networkElementCollection, props.featureTypes)
      setNodeOptions(options)
    }
  }, [networkElementCollection, props.featureTypes])

  const buildNodeOptions = (featureCollections: NetworkElementCollection, featureTypes: string[]): any[] => {
    let options: any[] = [chooseNodeOption]
    if (!!featureCollections && !!featureTypes) {
      featureTypes.forEach(ft => {
        let ftInfo = FTC[ft]
        if (!!ftInfo && !!featureCollections[ftInfo.collection]) {
          let features = featureCollections[ftInfo.collection]
          features.forEach(f => {
            let o: any = {
              code: f.properties.code,
              label: f.properties.label,
              group: f.properties.featureType,//(===ft)
            }
            options.push(o)
          })
        }
      })
    }
    return options
  }

  const handleEditClick = nodeId => {
    setEditingNodeId(nodeId)
    // todo: notify Geometry
    props.onEdit()
  }
  const handleCancelClick = () => {
    setEditingNodeId(nodeIds.none)
    // todo: notify Geometry
    props.onCancel()
  }
  const handleApplyClick = (selectedOption: any, moveNode: any) => {
    setEditingNodeId(nodeIds.none)
    props.onApply()
    saveModifiedPipe(selectedOption, moveNode)
  }

  const saveModifiedPipe = (selectedOption: any, moveNode: any) => {
    if (!selectedOption || !selectedOption) {
      throw('You must select a node!')
    }
    let modpipe = JSON.parse(JSON.stringify(props.pipe))

    let nodeCodePropName = 'inNodeCode'
    let nodeTypePropName = 'inNodeFeatureType'
    if (editingNodeId === nodeIds.endNode) {
      nodeCodePropName = 'outNodeCode'
      nodeTypePropName = 'outNodeFeatureType'
    }

    if (selectedOption.code === modpipe.properties[nodeCodePropName]) {
      throw(`You must select a node other than ${selectedOption.code} !`)
    }
    modpipe.properties[nodeCodePropName] = selectedOption.code
    modpipe.properties[nodeTypePropName] = selectedOption.group

    let ftInfo = FTC[selectedOption.group]
    if (!(ftInfo || networkElementCollection[ftInfo.collection])) {
      throw(`Could not find feature collection ${ftInfo.collection} !`)
    }

    let newNode = networkElementCollection[ftInfo.collection].find(f => f.properties.code === selectedOption.code)
    if (!newNode) {
      throw(`Could not find feature ${selectedOption.code} !`)
    }

    let newNodeCoords = newNode.geometry.coordinates

    if (//handle the first coordinate
      (editingNodeId === nodeIds.startNode && modpipe.properties?.flowDirection !== 'reverse')
      || (editingNodeId === nodeIds.endNode && modpipe.properties?.flowDirection === 'reverse')
    ) {
      if (moveNode) {
        modpipe.geometry.coordinates[0] = newNodeCoords
      } else {
        modpipe.geometry.coordinates.splice(0, 0, newNodeCoords)
      }
    } else {//handle the last coordinate
      if (moveNode) {
        modpipe.geometry.coordinates[modpipe.geometry.coordinates.length - 1] = newNodeCoords
      } else {
        modpipe.geometry.coordinates.push(newNodeCoords)
      }
    }

    // console.log(`before pipe ${JSON.stringify(props.pipe,null,2)}`)
    // console.log(`after pipe ${JSON.stringify(modpipe,null,2)}`)
    return featureService.saveFeature(modpipe)
    .then(
      res => {
        // showAlert('Success', 'S')
      },
      err => {
        showAlert(`${t('eo.epa.error.header')} ${err}`, 'E')
      }
    )
  }

  let option = nodeOptions.find(o => o.code === props.pipe?.properties.inNodeCode)
  let selectedStartOption = !!option ? option : chooseNodeOption
  option = nodeOptions.find(o => o.code === props.pipe?.properties.outNodeCode)
  let selectedEndOption = !!option ? option : chooseNodeOption

  return (
    <Box py={1} px={1.5} mt={1}
      display='flex'
      flexDirection='column'
      className={classes.pnRoot}
    >
      <Box
        display='flex'
        alignItems='center'
        height={42}
      >
        <Typography
          align='left'
          className={classes.typoDark}
        >
          {t('pipe.edit.node.header')}
        </Typography>
      </Box>
      {editingNodeId === nodeIds.startNode ? (
      <NodeSelector
        selectedOption={selectedStartOption}
        options={nodeOptions}
        editingNodeId={editingNodeId}
        onCancel={handleCancelClick}
        onApply={handleApplyClick}
      />
      ) : (
      <Box
        display='flex'
        alignItems='center'
        height={42}
      >
        <Typography
          align='left'
          className={classes.typoShort}
        >
          {t(`pipe.edit.node.in`)+':'}
        </Typography>
        <Typography
          align='left'
          className={classes.typoLong}
        >
          {!!selectedStartOption.code ? selectedStartOption.label : ''}
        </Typography>
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          disabled={props.readonly || editingNodeId === nodeIds.endNode}
          onClick={() => handleEditClick(nodeIds.startNode)}
        >
          {t('btn.edit')}
        </Button>
      </Box>)}
      {editingNodeId === nodeIds.endNode ? (
      <NodeSelector
        selectedOption={selectedEndOption}
        options={nodeOptions}
        editingNodeId={editingNodeId}
        onCancel={handleCancelClick}
        onApply={handleApplyClick}
      />
      ) : (
      <Box
        display='flex'
        alignItems='center'
        height={42}
      >
        <Typography
          align='left'
          className={classes.typoShort}
        >
          {t(`pipe.edit.node.out`)+':'}
        </Typography>
        <Typography
          align='left'
          className={classes.typoLong}
        >
          {!!selectedEndOption.code ? selectedEndOption.label : ''}
        </Typography>
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          disabled={props.readonly || editingNodeId === nodeIds.startNode}
          onClick={() => handleEditClick(nodeIds.endNode)}
        >
          {t('btn.edit')}
        </Button>
      </Box>)}
    </Box>
  )
}

export default PipeNodes
