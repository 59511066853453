import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import { useCollectionStore } from '../../../state/collectionStore'
import { useCommonStore } from '../../../state/commonStore'
import { useUiStore } from '../../../state/uiStore'
import { GenericFeature } from '../../../utils/types/networkElementTypes'
import { formatSecondsToHoursMinuts, formatSecondsToHoursToFixed } from '../../../utils/helpers'
import MeasurementsManager from '../../../services/MeasurementsManager'
import MasterDetail from '../../masterdetail/MasterDetail'
import WrmIcon from '../../WrmIcon'
import MeasurementsFilter from '../../network/MeasurementsFilter'
import IrrigationMeasurementsGraph from './IrrigationMeasurementsGraph'

const API_URL = process.env.REACT_APP_WRM_API_URL
const MAX_GRAPH_DATA_LIMIT = 5000

const useStyles = makeStyles(theme => ({
  msrroot: {
  },
  tabsRoot: {
    minHeight: 'inherit',
  },
  tabLabel: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.palette.tertiary.dark,
    minWidth: 90,
    maxWidth: 200,
    minHeight: 'inherit',
  },
  tabLabelSelected: {
    color: theme.palette.primary.dark,
  },
  filtersBtn: {
    textTransform: 'none',
    minWidth: 105,
    fontSize: '0.875rem',
    fontWeight: 'normal',
  },
  filterIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  filterIconActive: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  graphBtn: {
    textTransform: 'none',
    minWidth: 105,
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  graphIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  graphIconActive: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  circular: {
    color: theme.palette.primary.main,
  },
  circularRed: {
    color: 'red',
  },
  irriRoot:{
    maxHeight:'80vh',
    maxWidth: '33vw'
  },
  dlgpaper: {
    maxWidth: '80vw',
  },
  dlgtitleroot: {
    padding: '8px 16px',
    // padding: theme.spacing(1),
  },
  dlgcontroot: {
    padding: theme.spacing(1),
    borderTop: `solid 1px ${theme.palette.tertiary.light}`,
    borderBottom: `solid 1px ${theme.palette.tertiary.light}`,
  },
  closeBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

const reduceInferredFeaturesToMtGroup = (a:string[],c:GenericFeature)=>{
  let mtGroup:string = ""
  switch (c.properties?.switchType) {
    case 'digital':
      mtGroup = 'plcHours'
      break
    case 'analog':
      mtGroup = 'switchHours'
      break
    case 'smartCard':
      //TODO: add when smart Card is implemented
      break
    default:
      break
  }
  return a.includes(mtGroup) ? a : a.concat(mtGroup) 
}

const IrrigationMeasurementsMd = (props) => {
  const showAlert = useCommonStore(state => state.showAlert)
  const currentAgriculturalPeriod = useCommonStore.getState().currentAgriculturalPeriod
  const loadingMultipleSeries = useUiStore(state => state.loadingMultipleSeries)
  const measurementsManager = MeasurementsManager()

  useEffect(()=>{
    if (loadingMultipleSeries) {
      setLoadingMeasurements(true)
    }
  }, [loadingMultipleSeries])
  useEffect(() => {
    if (!!props.series && !!props.feature) {
      if (!props.series.every(s => s.measurements) && props?.featureType !== 'landparcel' && props?.featureType !== 'consumer') {
        setLoadingMeasurements(true)
      } else if ((props.feature?.properties?.featureType === 'landparcel' || props.featureType === 'consumer') && loadingMultipleSeries) {
        setLoadingMeasurements(true)
      } else {
        if (loadingMeasurements) setLoadingMeasurements(false)
        if (props.series.length === 0 || (props.series.length > 0 && props.feature?.properties?.code === props.series[0]?.featureCode)) {
          if (!!selectedMeasurement && (props.series.length === 0 || (props.series.length > 0 && props.series.findIndex(s => s._id === selectedMeasurement.series) === -1))) {
            setSelectedMeasurement(null)
          }
          prepareTabsData(props.series)
        }
        /**LandParcel implementation */
        if (!!props.inferredFeatures && props?.featureType === 'landparcel' ) {
          const switchCodes = props.inferredFeatures.map((el:GenericFeature) => el.properties.code)
          if (props.series.length === 0 || (props.series.length > 0 && switchCodes.includes(props.series[0].featureCode))) {
            if (!!selectedMeasurement && (props.series.length === 0 || (props.series.length > 0 && props.series.findIndex(s => s._id === selectedMeasurement.series) === -1))) {
              setSelectedMeasurement(null)
            }
            prepareTabsData(props.series)
          }
        }
        /**Consumer Implementation */
        if (!!props.inferredFeatures && props?.featureType === 'consumer') {
          const switchCodes = props.inferredFeatures.map((el:GenericFeature) => el.properties.code)
          if (props.series.length === 0 || (props.series.length > 0 && switchCodes.includes(props.series[0].featureCode))) {
            if (!!selectedMeasurement && (props.series.length === 0 || (props.series.length > 0 && props.series.findIndex(s => s._id === selectedMeasurement.series) === -1))) {
              setSelectedMeasurement(null)
            }
            prepareTabsData(props.series)
          }
        }
      }
    }
  }, [props.series, loadingMultipleSeries])
  useEffect(() => {
  /* If the selected feature changes from a feature with multiple measurements (both digital && analog - two or more tabs) 
   * to a feature that has less measurements (eg only digital or only analog) 
   * reset the active tab (Make the first tab active)  */
    if (!!tabsInfo && !!activeTab && (activeTab > tabsInfo.length - 1)) {
      setActiveTab(0)
    }
    if (!!props.feature) {
      prepareTabsColumns(props.featureType)
    }
  }, [props.feature, props.inferredFeatures])

  const { t }: { t: any } = useTranslation()
  const classes = useStyles()

  const users = useCollectionStore(state => state.users)

  const BooleanValues = [
    {code: true, label: t('btn.yes')},
    {code: false, label: t('btn.no')},
  ]
  const OnOffValues = [
    {code: 1, label: t('btn.on')},
    {code: 0, label: t('btn.off')},
  ]
  const prepareCol = (vt:any, mTypeCode:string, readOnly?:boolean) => {
    let translationPath = mTypeCode === 'plcHours' ? 'msr.fld.irrigation.' : 'msr.fld.'
    let col: any = { code: vt.fieldname, label: t(`${translationPath}${mTypeCode}.${vt.fieldname}`, t(`${translationPath}${vt.fieldname}`, vt.label)) }
    col.editable = !readOnly
    col.searchable = true
    col.filterable = true
    col.required = !vt.isOptional
    switch (vt.datatype) {
      case 'Number':
        // console.log(col)
        // console.log(vt)
        /** In irrigation(PLC Measurements) duration and value are stored in seconds but we want to display them in hours:minutes
         * Master Detail doesn't cover this case or any other custom formatting, so we store the custom formatting 
         * function inside col.type that will be invoked by processRawValue() inside MasterDetails
        */
        if ((col.code === 'value' ||col.code === 'duration') && vt.label !== "Hours") {
          col.type = formatSecondsToHoursMinuts
        } else {
          col.type = 'number'
          col.format = '9.99'
          if (vt.lookup === 'OnOffValues') {
            col.lookup = ['OnOffValues']
            col.format = ''
          }
        }
        break;
      case 'Date':
        col.type = 'date'
        col.disableFuture = !!vt.disableFuture
        break;
      case 'Datetime':
        col.type = 'datetime'
        col.disableFuture = !!vt.disableFuture
        break;
      case 'Time':
        col.type = 'datetime'
        col.disableFuture = !!vt.disableFuture
        break;
      case 'Boolean':
        col.type = 'boolean'
        col.lookup = ['BooleanValues']
        break;
      case 'Link':
        col.type = 'link'
        col.editable = false
        col.searchable = false
        col.filterable = false
        col.required = false
        break;
      default:
        col.type = 'string'
        break;
    }
    return col
  }
  const prepareTabsColumns = (featureType) => {
    // console.log(`prepareColumns ${featureType}`)
    if (!featureType) return
    let tabinfos: any[] = []
    let tabcolumns = {}
    let featureMeasurementTypes
    if (!props.feature) {
        showAlert(t(`msr.fld.switch.switchType.error`, {val:props.feature.properties.label}), 'W')
        featureMeasurementTypes = props.measurementTypes
        .filter(mt => mt.featureType === props.featureType && !mt.group)
    } else if (props.featureType === 'switch') { 
      switch (props.feature.properties.switchType) {
        case 'digital':
          featureMeasurementTypes = props.measurementTypes
          .filter(mt => mt.featureType === props.featureType && mt.group === 'plcHours')
          break
        case 'analog':
          featureMeasurementTypes = props.measurementTypes
          .filter(mt => mt.featureType === props.featureType && mt.group === 'switchHours')
          break
        case 'smartCard':
          /*for now leave it with default value when smartCard is implemented expand the filter */
          featureMeasurementTypes = props.measurementTypes
          .filter(mt => mt.featureType === props.featureType)
          break
        default:
          /**In this case the switchType is undefined (not set by the user) 
           * so just to be safe remove all groups and display a warning to the user*/
          console.log(t(`msr.fld.switch.switchType.error`, {val:props.feature.properties.label}), 'W')
          featureMeasurementTypes = props.measurementTypes
          .filter(mt => mt.featureType === props.featureType && !mt.group)
          break
      }
    } else if (props.featureType === "landparcel") {
      /* Deduce the correct tabs based on whether the connected to the landparcel water outlets are digital or analog  */
      let mtGroups = props.inferredFeatures?.reduce(reduceInferredFeaturesToMtGroup, [])
      featureMeasurementTypes = props.measurementTypes.filter(
        mt => mt.featureType === 'switch'  && mtGroups.includes(mt.group)
      )
    } else if (props.featureType === 'consumer') {
      let mtGroups = props.inferredFeatures?.reduce(reduceInferredFeaturesToMtGroup, [])
      featureMeasurementTypes = props.measurementTypes
      .filter(mt => mt.featureType === 'switch'  && mtGroups.includes(mt.group))
    }
    featureMeasurementTypes
    .forEach(mType => {
      let useGroupMeta = false
      if (!!mType.group) {
        useGroupMeta = featureMeasurementTypes.findIndex(mt => mt.group === mType.group && mt._id !== mType._id) > -1
      }
      if (useGroupMeta) {
        let gType = props.measurementTypes.find(gt => gt.code === mType.group)
        if (tabinfos.findIndex(ti => ti?.code === gType?.code) < 0) {
          let columns:any;
          if (gType?.code === "switchHours") {
            let enchancedValueTypes = gType.valueTypes.map(el=>el)
            enchancedValueTypes.push({
              datatype: "String",
              description: "User that added the measurement",
              fieldname: "addedBy",
              label: "personnel.specialty.inspection",
              level: ".",
              readOnly:true,
            })
            let enchangedGType = {...gType, valueTypes: enchancedValueTypes}
            tabinfos.push(enchangedGType)
            columns = enchangedGType.valueTypes.map(vt => {
              return prepareCol(vt, gType.code, vt.readOnly)
            })
          } else {
            tabinfos.push(gType)
            columns = gType.valueTypes.map(vt => {
              return prepareCol(vt, gType.code)
            })
          }
          let mtlookup = {}
          mtlookup[mType.code] = t(`msr.fld.${mType.code}`, mType.label)
          columns.push({
            code: 'measurementType',
            label: t('msr.fld.measurementType', 'Measure'),
            type: 'string',
            lookup: [gType.code],
            editable: 'onAdd',
            searchable: true,
            filterable: true,
            required: true,
          })
          tabcolumns[gType.code] = columns
        }
      } else {
        if (tabinfos.findIndex(ti => ti.code === mType.code) < 0) {
          tabinfos.push(mType)
          let columns = mType.valueTypes.map(vt => {
            return prepareCol(vt, mType.code)
          })
          if (!!mType.computedFields) {
            let computedColumns = mType.computedFields.map(cf => {
              return prepareCol(cf, mType.code, true)
            })
            //add
            columns.push(...computedColumns)
          }
          tabcolumns[mType.code] = columns
        }
      }
    })
    // if (tabinfos.length > 0) {
      setTabsInfo(tabinfos)
      setTabsColumns(tabcolumns)
    // }
  }
  const prepareTabsData = (series) => {
    if (!series) return
    let tabdata = {}
    let featureMeasurementTypes = (props.inferredFeatures && props.inferredFeatures?.length > 0 ) 
      ? props.measurementTypes.filter(mt => props.inferredFeatures.map((el:GenericFeature)=>el.properties.featureType).includes(mt.featureType) ) 
      : props.measurementTypes.filter(mt => mt.featureType === props.featureType)
    series.forEach(s => {
      let mType = featureMeasurementTypes?.find(mt => mt?.code === s?.measurementType?.code)
      if (!mType) return
      if (!!s.measurements) {
        s.measurements.forEach(m => {
          let useGroupMeta = !!mType.group
          if (!!mType.group) {
            useGroupMeta = featureMeasurementTypes.findIndex(mt => mt.group === mType.group && mt._id !== mType._id) > -1
          }
          if (useGroupMeta) {
            let gType = props.measurementTypes.find(gt => gt.code === mType.group)
            let rec:any;
            if (gType.code === "switchHours") {
              const user = users?.find(u=>u.user_id === m.addedBy)
              rec = {
                measurementType: mType.code,// or code
                _id: m._id,
                series: m.series,
                addedBy: user ? user.auth0User.given_name + " " + user.auth0User.family_name : m.addedBy,
              }
            } else {
              rec = {
                measurementType: mType.code,// or code
                _id: m._id,
                series: m.series,
              }
            }
            mType.valueTypes.forEach(vt => {
              let val
              if (!vt.level || vt.level === '.') {
                val = m[vt.fieldname]
              } else {
                val = !!m[vt.level] ? m[vt.level][vt.fieldname] : ''
              }
              rec[vt.groupfieldname] = !!val ? val : ''
              if (val === 0 && vt.datatype === 'Number') {
                rec[vt.groupfieldname] = val
              }
            })
            if (!!mType.computedFields) {
              mType.computedFields.forEach(cf => {
                switch (cf.fieldname) {
                  case 'consumption':
                    rec[cf.groupfieldname] = !!m.computedValues ? m.computedValues[cf.fieldName] : 0;
                    break;
                  case 'hasAttachment':
                    if (m[cf.basedOnField1] != null) {
                      rec[cf.groupfieldname] = `${API_URL}attachments/${m[cf.basedOnField1].attachment}`
                    } else {
                      rec[cf.groupfieldname] = ''
                    }
                    break;
                  default:
                    rec[cf.groupfieldname] = !!m.computedValues ? m.computedValues[cf.fieldName] : '';
                }
              })
            }
            if (!tabdata[gType.code]) {
              tabdata[gType.code] = []
            }
            tabdata[gType.code].push(rec)
          } else {
            let rec = {
              _id: m._id,
              series: m.series
            }
            mType.valueTypes.forEach(vt => {
              let val
              if (!vt.level || vt.level === '.') {
                val = m[vt.fieldname]
              } else {
                val = !!m[vt.level] ? m[vt.level][vt.fieldname] : ''
              }
              rec[vt.fieldname] = !!val ? val : ''
              if (val === 0 && vt.datatype === 'Number') {
                rec[vt.fieldname] = val
              }
            })

            if (!!mType.computedFields) {
              mType.computedFields.forEach(cf => {
                switch (cf.fieldname) {
                  case 'consumption':
                    // console.log(`Computed fields found !! ${JSON.stringify(cf)} look in measurement ${JSON.stringify(m)} `)
                    rec[cf.fieldname] = !!m.computedValues ? m.computedValues[cf.fieldname] : 0;
                    break;
                  case 'hasAttachment':
                    if (m[cf.basedOnField1] != null) {
                      rec[cf.fieldname] = `${API_URL}attachments/${m[cf.basedOnField1].attachment}`
                    } else {
                      rec[cf.fieldname] = ''
                    }
                    break;
                  default:
                    rec[cf.fieldname] = !!m.computedValues ? m.computedValues[cf.fieldname] : ''
                }
              })
            }
            if (!tabdata[mType.code]) {
              tabdata[mType.code] = []
            }
            tabdata[mType.code].push(rec)
          }
        })
      }
    }
    )
    setTabsData(tabdata)
  }
  const prepareData = () => {
    let measurements:any[] = []
    if (!!tabsData && !!tabsInfo) {
      measurements = tabsData[tabsInfo[activeTab]?.code] ? tabsData[tabsInfo[activeTab]?.code] : tabsData[tabsInfo[0]?.code]
    }
    // data = !!tabsData && !!tabsInfo && tabsData[tabsInfo[activeTab]?.code] ? tabsData[tabsInfo[activeTab].code] : [] // 👈 LEGACY 
    data = !!tabsData && !!tabsInfo && !!measurements ? measurements : []
  }
  const prepareMeta = () => {
    console.log(`prepareMeta`)
    if (!tabsColumns || !tabsInfo || tabsInfo.length === 0) return
   /* in case the previsously selected measurement tab was a type (digital/analog) that doesn't exist on current 
    * element, select the first tab to avoid crash */
    const tabsColumnsCode = !!tabsColumns[tabsInfo[activeTab]?.code] 
    ? tabsColumns[tabsInfo[activeTab]?.code]
    : tabsColumns[tabsInfo[0].code]
    meta = metadata
    meta.columns = [...meta.columns, ...tabsColumnsCode]
  }

  const [activeTab, setActiveTab] = useState(0)
  const [tabsColumns, setTabsColumns] = useState<any | null>(null)
  const [tabsData, setTabsData] = useState<any | null>(null)
  const [tabsInfo, setTabsInfo] = useState<any | null>(null)
  const [selectedMeasurement, setSelectedMeasurement] = React.useState<any | null>(null)
  const [isMeasurementsFilterOpened, setIsMeasurementsFilterOpened] = React.useState(false)
  const [isGraphDialogOpened, setIsGraphDialogOpened] = React.useState(false)
  const [loadingMeasurements, setLoadingMeasurements] = useState(false)

  useEffect(()=>{
    /* This effect solves the issue where a measurement could be updated once, 
     * but threw an error if tried to edit it for the second time without unselecting it
     * -this bug also appears when creating a new measurment and imidiately editing it without unselecting it
     * Save measurement is triggered from the MasterDetail 
     * so the selectedMeasurement is populated by the default response of the MeasurementsManager.actSaveMeasurement
     * which is a raw measurement which lacks the measurementType property
     * so as when the selectedMeasurement by the save measurement and the component re-renders
     * and updates the tabsData the selectedMeasurement(raw measurement) is replaced by the 'enchanced' measurement */
    if (!!selectedMeasurement && !!tabsData && !!tabsInfo) {
      const updatedMeasurement = tabsData[tabsInfo[activeTab].code]?.find(el=>{
        return el._id === selectedMeasurement._id
      })
      if (!!updatedMeasurement && updatedMeasurement !== -1) {
        setSelectedMeasurement(updatedMeasurement)
      }
    }
  }, [selectedMeasurement])

  const getRowLabel = (fieldProcessedValues: any[]) => {
    if (fieldProcessedValues.length === 2) {
      let refDatetime = (typeof fieldProcessedValues[0] === 'string' ? moment(fieldProcessedValues[0], t(`datetime.format`) as string) : moment(fieldProcessedValues[0])).format(t(`datetime.format`) as string)
      return `${refDatetime} - ${fieldProcessedValues[1]}`
    }
    return ''
  }
  const metadata = {
    // title: 'net.entity.plural',
    defaultSortBy: 'refDatetime',
    defaultSortOrder: 'desc',
    rowLabel: getRowLabel,
    rowLabelFields: ['refDatetime', 'value'],
    noFilter: true,
    noAdd: (!!tabsInfo && tabsInfo[activeTab]?.code === 'plcHours') || props.noEdit,
    noEdit: (!!tabsInfo && tabsInfo[activeTab]?.code === 'plcHours') || props.noEdit,
    noDelete: (!!tabsInfo && tabsInfo[activeTab]?.code === 'plcHours') || props.noDelete,
    noDeletionReasonNote: true,
    // checkboxed: true,
    hideDetails: true,
    editOnMaster: true,
    compact: true,
    columns: [
      { code: '_id', label: '_id', type: 'string', hidden: true, isId: true },
      { code: 'series', label: 'series', type: 'string', hidden: true },
    ],
  }

  let data:any[] = []
  let meta
  prepareMeta()
  prepareData()

  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex)
  }

  const getMeasurementTypeId = (mlean) =>{
    let mType = tabsInfo[activeTab]
    if (!!mlean.measurementType) {//this is actually a group
      mType = props.measurementTypes.find(mt => mt.code === mlean.measurementType)
    }
    console.log(`mType ${JSON.stringify(mType,null,2)}`)
    if (mType.isGroup) {//this should not be a group, otherwise something went wrong
      return null
    }
    return mType._id
  }
  const doSaveMeasurement = (mlean) => {
    // console.log(`doSaveMeasurement mlean ${JSON.stringify(mlean,null,2)}`)
    let measurement = buildMeasurement(mlean)
    if (!measurement) {
      return Promise.reject('Could not build measurement')
    } else if (!!measurement.series) {
      return props.saveMeasurement(measurement)
    } else {
      return props.saveMeasurement(measurement, getMeasurementTypeId(mlean))
    }
    // console.log(`tabdata ${JSON.stringify(meas,null,2)}`)
  }
  const doDeleteMeasurement = (mlean) => {
    // console.log(`tabdata ${JSON.stringify(mlean,null,2)}`)
    // let meas = buildMeasurement(mlean)
    // console.log(`tabdata ${JSON.stringify(meas,null,2)}`)
    let series = props.series.find(s => s._id === mlean.series)
    return props.deleteMeasurement(series.measurements.find(m => m._id === mlean._id))
  }

  const cloneMeasurement = (measurement) => {
    let clone = { ...measurement }
    clone.extraValues = { ...measurement.extraValues }
    return clone
  }
  const buildMeasurement = (mlean) => {
    // console.log(`buildMeasurement mlean ${JSON.stringify(mlean,null,2)}`)
    let measurement: any
    let isNewRow = !mlean._id
    let mType = !mlean.measurementType ? tabsInfo[activeTab] : props.measurementTypes.find(mt => mt.code === mlean.measurementType)
    if (mType.isGroup) {//this should not be a group, otherwise something went wrong
      showAlert(`${t('error.message')} : Cannot identify measurement type`, 'E')
      return null
    }
    let series = props?.series?.find(s => !!mlean.series ? s._id === mlean.series : s.measurementType.code === mType.code)
    let seriesId = !!series ? series._id : null
    if (isNewRow) {
      measurement = {
        series: seriesId,
        notes: [],
        attachmentMetadata: {}
      }
    } else {
      measurement = cloneMeasurement(series.measurements.find(m => m._id === mlean._id))
    }
    //console.log(`mType ${JSON.stringify(mType,null,2)}`)
    mType.valueTypes.forEach(vt => {
      let name = !!mType.group ? vt.groupfieldname : vt.fieldname
      let value = mlean[name]
      if (!value && !(vt.datatype === 'Boolean' || (vt.datatype === 'Number' && value === 0) ) ) {
        value = !!vt.default || vt.default === 0 ? vt.default : ''
      }
      let valueHost = measurement
      if (!!vt.level && vt.level !== ".") {
        if (!measurement[vt.level]) {
          measurement[vt.level] = {}
        }
        valueHost = measurement[vt.level]
      }
      valueHost[vt.fieldname] = value
    })
    // console.log(`measurement ${JSON.stringify(measurement,null,2)}`)
    return measurement
  }

  const getDateFormat = () => {
    //console.log(`getDateformat: ${JSON.stringify(tabsInfo[activeTab])}`)
    let currentValueType = tabsInfo[activeTab]?.valueTypes?.filter(vt => (vt.fieldname === 'refDatetime'))
    let format = 'date.format'
    if (currentValueType.length > 0) {
      format = currentValueType[0].datatype === 'Datetime' ? 'datetime.format' : 'date.format'
    }
    console.log(`getDateformat: ${format}`)
    return format
  }
  const loadFilterFromSeries = (series) => {
    console.log(`IrrigationMeasurement.loadFilterFromSeries`)
    if (!!series && series.length > 0 && !!series[0].mfilter) {
      let loadedFilter = {
        series: series.map(s => { return { _id: s._id, selected: !s.seriesHidden } }),
        refDatetimeTo: !!series[0].mfilter && !!series[0].mfilter.refDatetime ? series[0].mfilter.refDatetime['$lte'] : '',
        refDatetimeFrom: !!series[0].mfilter && !!series[0].mfilter.refDatetime ? series[0].mfilter.refDatetime['$gte'] : '',
      }
      //console.log(`loadFilterFromSeries: ${JSON.stringify(loadedFilter)}`)
      return loadedFilter
    } else {
      return {
        series: filterSeriesByMTypes().map(s => { return { _id: s._id, selected: true } }),
        refDatetimeTo: '',
        refDatetimeFrom: '',
      }
    }

  }
  const loadCurrentFilter = () =>{
    let filteredSeriesByMtypes = filterSeriesByMTypes()
    let currentFilter = loadFilterFromSeries(filteredSeriesByMtypes)
    //console.log(`Load current filter from series:${JSON.stringify(currentFilter)}`)
    return currentFilter
  }

  const handleOnFiltersClick = () => {
    //setFilter(buildFilters(filter))
    setIsMeasurementsFilterOpened(true)
  }
  const handleOnGraphClick = () => {
    let filteredSeriesByMtypes = filterSeriesByMTypes()
    let seriesToBeDisplayed = filteredSeriesByMtypes.filter(s => !s.seriesHidden)
    let canShowGraph = false
    if (!!seriesToBeDisplayed && seriesToBeDisplayed.length > 0) {
      let tooManyDataSeries = seriesToBeDisplayed.filter(s => s.measurements && s.measurements.length >= MAX_GRAPH_DATA_LIMIT)
      let noDataSeries = seriesToBeDisplayed.filter(s => s.measurements && s.measurements.length === 0)
      let okDataSeries = seriesToBeDisplayed.filter(s => s.measurements && s.measurements.length > 0 && s.measurements.length < MAX_GRAPH_DATA_LIMIT)
      if (tooManyDataSeries.length > 0) {
        let mts = tooManyDataSeries.map(s => props.measurementTypes.find(mt => mt._id === s.measurementType._id))
        // showAlert(`${t('net.measurements')} : ${mts.map(mt => t(`msr.fld.${mt.code}`, mt.label)).join('\n')}\n${t(`msr.graph.alert.maxDataExceeded`)} (${MAX_GRAPH_DATA_LIMIT})`, 'W')
      }
      if (noDataSeries.length > 0) {
        let mts = noDataSeries.map(s => props.measurementTypes.find(mt => mt._id === s.measurementType._id))
        // showAlert(`${t('net.measurements')} : ${mts.map(mt => t(`msr.fld.${mt.code}`, mt.label)).join('\n')}\n${t(`msr.graph.alert.nodata`)}`, 'W')
      }
      if (okDataSeries.length > 0) {
        canShowGraph = true
      }
    } else {
      showAlert(t(`msr.graph.alert.nodata`), 'W')
    }
    if (canShowGraph){
      setIsGraphDialogOpened(true)
    }
  }
  const handleCloseFilterDialog = (filter?) => {
    if (!!filter) {
      console.log(`handleCloseFilterDialog reply: ${JSON.stringify(filter)}`)
      if (!!filter.refDatetimeFrom && !!filter.refDatetimeTo) {
        if (moment(filter.refDatetimeTo).isBefore(moment(filter.refDatetimeFrom))) {
          showAlert(t('msr.filter.refDatetimeToBeforeRefDatetimeFrom'), 'E') 
          return
        }
        if (moment(filter.refDatetimeFrom).isBefore(moment(currentAgriculturalPeriod?.startDate))) {
          return showAlert(t('agriculturalPeriod.validation.filters.dateFrom.isBefore', {date:moment(currentAgriculturalPeriod?.startDate).format(t('date.format')!)}), 'E')
        }
        if (moment(filter.refDatetimeTo).isAfter(moment(currentAgriculturalPeriod?.endDate))) {
          return showAlert(t('agriculturalPeriod.validation.filters.dateTo.isAfter', {date:moment(currentAgriculturalPeriod?.endDate).format(t('date.format')!)}), 'E')
        }
      }
      setLoadingMeasurements(true)
      props.setGroupOfMeasurementsFilter(filter)  
      .then(
        res => {
          setLoadingMeasurements(false)
        },
        err => {
          setLoadingMeasurements(false)
        }
      )
    }
    setIsMeasurementsFilterOpened(false)
  }

  const handleCloseGraphDialog = () => {
    setIsGraphDialogOpened(false)
  }
  const overrideDefaultFilter = (options: any) => {
    /**When overriding default filter for irrigation measurements (regardless of the element - switch,hourmeter,landparcel,consumer,cultivation) always pass {featureType: 'switch'} as options
     * this enforces that the dateTo/dateFrom will be fetched based on agricultural period */
    return measurementsManager.getDefaultFilter({featureType: 'switch'})//({days: 30 * 8})
  }
  const clearFilter = () => {
    let clearedFilter:any={}
    let defaultFilter = overrideDefaultFilter({
      isScadaEnabled: filterSeriesByMTypes().some(s => s.scadaDeviceCode),
      featureType: props.featureType
    })
    clearedFilter.refDatetimeFrom = defaultFilter.refDatetime['$gte']
    clearedFilter.refDatetimeTo = defaultFilter.refDatetime['$lte']
    clearedFilter.series = []
    filterSeriesByMTypes().forEach(fs => {
      clearedFilter.series.push({_id:fs._id, selected:true})
    })
    return clearedFilter
  }
  const filterSeriesByMTypes = () => {
    let mts = props.measurementTypes
    .filter(mt => (!mt.isGroup && (mt.group === tabsInfo[activeTab]?.code || mt.code === tabsInfo[activeTab]?.code)))
    // let result = props.series.filter(s => mts.map(mt => mt.code).indexOf(s.measurementType.code) !== -1)
    if (!props.series) {
      console.log(`filterSeriesByMTypes ERROR props.series is ${props.series}`)
    }
    let result = !!props.series ? props.series.filter(s => mts.findIndex(mt => mt.code === s.measurementType.code) > -1) : []
    //console.log(`filterSeriesByMTypes : ${JSON.stringify(result)}`)
    return result
  }

  const filterSeriesByFilter = (filter) => {
    console.log(`filterSeriesByFilter`)
    if (!!filter && !!filter.series && filter.series.length > 0) {
      return filter.series
      .filter(s => s.selected)
      .map(s => props.series.find(fs => fs._id === s._id))
    } else {
      return props.series
    }
  }

  const findFieldtoDisplay = () => {
    let result
    let mts = props.measurementTypes.filter(mt => (!mt.isGroup && (mt.group === tabsInfo[activeTab]?.code || mt.code === tabsInfo[activeTab]?.code)))
    let vtFound = mts[0].valueTypes.find(vt => vt.isPrimary === true)
    if (!!vtFound) {
      result = vtFound
    } else {
      let cfFound = !!mts[0].computedFields?mts[0].computedFields.find(cf => cf.isPrimary === true):null
      if (!!cfFound) {
        cfFound.level='computedValues'
        result = cfFound
      } else {
        let defaultVt = mts[0].valueTypes.find(vt => vt.fieldname === 'value')
        result = defaultVt
      }
    }
    return result
  }
  const buildGraphDialogTitle = () => {
    return `${t(`btn.graph`)} ${t(`msr.fld.${tabsInfo[activeTab]?.code}`)} ${t('msr.fld.irrigation.unit')}`
  }
  const buildGraphDialogSubTitle: any = (filter) => {

    let featureLabel = props.parentEntityLabel

    let fromDate = ' - '
    let toDate = ' - '
    if (!!filter && !!filter.refDatetimeFrom) {
      fromDate = (typeof filter.refDatetimeFrom === 'string' ? moment(filter.refDatetimeFrom, t(`date.format`) as string) : moment(filter.refDatetimeFrom)).format(t(`date.format`) as string)
    }
    if (!!filter && !!filter.refDatetimeTo) {
      toDate = (typeof filter.refDatetimeTo === 'string' ? moment(filter.refDatetimeTo, t(`date.format`) as string) : moment(filter.refDatetimeTo)).format(t(`date.format`) as string)
    }

    // return [typesLiteral, fromDate, toDate]
    // return {entityLabel: typesLiteral, periodFrom: fromDate, periodTo: toDate}
    return {entityLabel: featureLabel, periodFrom: fromDate, periodTo: toDate}
  }
  /* Display an UI warning if the selected feature is a switch with undefined switchType */
  if (!props.feature?.properties?.switchType && props.featureType !== "landparcel" && props.featureType !== "consumer") {
    return <Alert style={{marginTop:'auto', marginBottom:'auto'}} severity="warning">
      {t(`msr.fld.switch.switchType.error`, {val:props.feature?.properties?.label || props.feature?.firstname + props.feature?.lastname})}
      </Alert>
  }

  let compo:any = null
  if (!!meta) {
    let lookups = {
      BooleanValues: BooleanValues,
      OnOffValues: OnOffValues,
    }
    let tabInfo = tabsInfo[activeTab]
    if (tabInfo?.isGroup) {
      let featureType = props.featureType
      switch (featureType) {
        case 'landparcel':
          featureType = 'switch'
          break;
        case 'consumer':
          featureType = 'switch'
          break;
        default:
          break;
      }
      lookups[tabInfo.code] = props.measurementTypes
      .filter(mt => mt.featureType === featureType && mt.group === tabInfo.code)
      .map(mt => {
        let li = {code: mt.code, label: t(`msr.fld.irrigation.${mt.code}`, mt.label)}
        return li
      })
    }
    let heightCss
    switch (props.parent) {
      case 'ConsumersCatalog':
        heightCss = 'calc(100vh - 185px)'
        break
      case 'CultivationCatalog':
        heightCss = 'calc(100vh - 185px)'
        break
      case 'FeaturesCatalog':
        heightCss = 'calc(100vh - 240px)'
        break;
      case 'HydrometersCatalog':
        heightCss = 'calc(100vh - 240px)'
        break;
      case 'LandparcelsCatalog':
        heightCss = 'calc(100vh - 185px)'
        break;
      case 'SwitchSlotCatalog':
        heightCss = 'calc(100vh - 185px)'
        break;
      case 'SwitchesCatalog':
        heightCss = 'calc(100vh - 240px)'
        break
      default:
        heightCss = 'calc(100vh - 240px)'
        break;
    }
   
    compo = (
      <Box
        display='flex'
        flexDirection='column'
        // height='calc(100vh - 120px)'
        // height={props.parent === 'HydrometersCatalog' ? 'calc(100vh - 240px)' : (props.parent === 'FeaturesCatalog' ? 'calc(100vh - 195px)' : (props.parent === 'SwitchSlotCatalog' ? 'calc(100vh - 240px)' :'calc(100vh - 120px)'))}
        height={heightCss}
        className={classes.irriRoot}
      >
        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          value={activeTab}
          indicatorColor="primary"
          className={classes.tabsRoot}
          onChange={handleTabChange}
        >
          {!!tabsInfo && tabsInfo.map(ti => (
            <Tab
              key={ti.code}
              label={t(`msr.fld.${ti.code}`, ti.label)}
              className={ti.code === tabsInfo[activeTab]?.code ? clsx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}
            />
          ))}
        </Tabs>
        {!!tabsInfo && <Divider variant='middle' />}
        <Box px={1} pt={1} mb={0}
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
        >
          {!loadingMeasurements && <Box mr={2} display='flex'>
            <Button
              className={classes.graphBtn}
              startIcon={(
                <WrmIcon
                  icon='graph'
                  className={classes.graphIconActive}
                />
              )}
              onClick={handleOnGraphClick}
              disabled={false}
            >
              {t('btn.graph')}
            </Button>
          </Box>}
          {!loadingMeasurements && <Box mr={2} display='flex'>
            <Button variant='outlined'
              className={classes.filtersBtn}
              startIcon={(
                <WrmIcon
                  icon='filter'
                  className={classes.filterIconActive}
                />
              )}
              onClick={handleOnFiltersClick}
              disabled={false}
            >
              {t('btn.filters')}
            </Button>
          </Box>}
        </Box>
        {loadingMeasurements ? (
        <Box p={5}>
          <CircularProgress className={classes.circular} size={200} />
        </Box>
        ) : (
/*
        <Box p={5}>
          <CircularProgress className={classes.circularRed} size={200} />
        </Box>
*/
        <MasterDetail
          data={data}
          meta={meta}
          selectedDataItem={selectedMeasurement}
          setSelectedDataItem={setSelectedMeasurement}
          users={users}
          lookups={lookups}
          actSaveDetail={doSaveMeasurement}
          actDeleteDetail={doDeleteMeasurement}
        >
        </MasterDetail>
      )}
        {isMeasurementsFilterOpened &&
        <MeasurementsFilter
          series={props.series}
          open={isMeasurementsFilterOpened}
          loadCurrentFilter={loadCurrentFilter}
          handleCloseDialog={handleCloseFilterDialog}
          seriesMTypes={filterSeriesByMTypes()}
          clearFilter={clearFilter}
        />}
        {isGraphDialogOpened &&
        <Dialog
          open={isGraphDialogOpened}
          onClose={(event, reason) => (reason === 'backdropClick') ? null : handleCloseGraphDialog()}
          classes={{ paper: classes.dlgpaper }}
        >
          {/* <DialogTitle
            classes={{ root: classes.dlgtitleroot }}
          >
            {props.title}
          </DialogTitle> */}
          <DialogContent
            classes={{ root: classes.dlgcontroot }}
          >
            <IrrigationMeasurementsGraph
              parentEntityType={props.parentEntityType}
              parentEntityLabel={props.parentEntityLabel}
              // open={isGraphDialogOpened}
              feature={props.feature}
              series={filterSeriesByFilter(loadFilterFromSeries(filterSeriesByMTypes())).filter(s => s.measurements.length > 0 && s.measurements.length < MAX_GRAPH_DATA_LIMIT)}
              // title={buildGraphDialogTitle()}
              subtitle={buildGraphDialogSubTitle(loadFilterFromSeries(filterSeriesByMTypes()))}
              // filter={loadFilterFromSeries(filterSeriesByMTypes())}
              fieldName={t(`msr.fld.${tabsInfo[activeTab]?.code}`, `msr.fld.value`)}
              valueFieldToDisplay={findFieldtoDisplay()}
              // dateFormat={getDateFormat()}
              // dateFormat='date.format'
              inferredFeatures={props.inferredFeatures}
              graphValueFormatFunction={formatSecondsToHoursToFixed}
            />
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button
                className={classes.closeBtn}
                onClick={handleCloseGraphDialog}
              >
                {t('btn.close')}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        }
      </Box>
    )
  }
  return compo
}

export default IrrigationMeasurementsMd
