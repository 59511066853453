import axios from 'axios';

axios.defaults.timeout = 1200000

const API_URL = process.env.REACT_APP_WRM_API_URL + 'map/'

const MapService = () => {

  const getPointElevation = (lngLatCoords) => {
    let data = {
      operation: 'getPointElevation',
      lngLatCoords: lngLatCoords,
    }
    return sendRequest(data)
  }

  const getPointAddress = (lngLatCoords) => {
    let data = {
      operation: 'getPointAddress',
      lngLatCoords: lngLatCoords,
    }
    return sendRequest(data)
  }

  const getAddressPoint = (address) => {
    let data = {
      operation: 'getAddressPoint',
      address: address,
    }
    return sendRequest(data)
  }

  const sendRequest = (data, path?) => {
    let url = `${API_URL}${path ? path : ''}`
    // console.log(`sendRequest data${JSON.stringify(data,null,2)}`)
    return axios.post(url, data)
  }

  return({
    getPointElevation: getPointElevation,
    getPointAddress: getPointAddress,
    getAddressPoint: getAddressPoint,
  })

}

export default MapService
