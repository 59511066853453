import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import WrmIcon from '../WrmIcon'

const useStyles = makeStyles(theme => ({
  // xIcon: {
  //   width: 256,
  //   height: 256,
  //   position: 'relative',
  //   top: 128,
  //   left: -384,
  //   marginRight: -256,
  // },
  returnBtn: {
    minWidth: 256,
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 'normal',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  stackBox: {
    whiteSpace: 'pre',
    maxHeight: 256,
    minWidth: 256,
    paddingRight: theme.spacing(3),
    overflowY: 'auto',
    // overflowX: 'hidden',
  },
}))

const SimpleErrorBoundaryContent = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (!!props.errorInfo) {
   console.log(`props.errorInfo ${JSON.stringify(props.errorInfo,null,2)}`)
  }

  return (
    <Box p={3}
      display='flex'
      justifyContent='center'
      // fontSize={20}
    >
      <WrmIcon
        icon='pipe'
        style={{ width: 512, height: 512 }}
        color='#5DCFCF'
      />
      <WrmIcon
        icon='x-icon'
        // className={classes.xIcon}
        style={{
          width: 256,
          height: 256,
          position: 'relative',
          top: 128,
          left: -384,
          marginRight: -256,
        }}
        color='red'
      />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        maxWidth={768}
      >
        <Box p={3}
          display='flex'
          justifyContent='center'
          fontSize={64}
          color='#979797'
        >
          {t('error.message')}
        </Box>
        <Box py={2} px={8}
          display='flex'
          justifyContent='left'
          fontSize={32}
          color='#979797'
        >
          {t('error.message.detail')}
        </Box>
        {props.accessGranted('genAdm') && <Box p={1}
          display='flex'
          justifyContent='left'
          fontSize={16}
        >
          {props.error}
        </Box>}
        {props.accessGranted('genAdm') && <Box p={1}
          display='flex'
          textAlign='left'
          fontSize={14}
          className={classes.stackBox}
        >
          {props.errorInfo}
        </Box>}
        <Box p={8}>
          <Button
            className={classes.returnBtn}
            onClick={props.handleReturnClick}
          >
            {t('btn.return')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SimpleErrorBoundaryContent
