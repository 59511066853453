import { alphabeticalSorter } from '../utils/helpers';
import TemplateService from "./TemplateService";
import { useCollectionStore } from "../state/collectionStore";
import { useCommonStore } from '../state/commonStore';
import i18n from '../i18n'

const PersonnelService = () => {
  const templateService = TemplateService("personnel");

  const getPersonnels = (filter?) => {
    let data: any = {
      operation: 'getPersonnels',
    }
    if (!!filter) {
      data.filter = filter
    }
    const options = {
      cachePromise: true,
      doReload: false,
    }
    return templateService.getEntities(filter, options)
    .then(
      res => {
        // console.log(`getPersonnels sorting`)
        res.data.sort((a, b) => alphabeticalSorter(`${a.lastname} ${a.firstname} ${a.middlename} ${a.code}`, `${b.lastname} ${b.firstname} ${b.middlename} ${b.code}`))
        return res
      }
    )
  }
  const getPersonnelsWithUser = () => {
    let filter = {
      users_user_id: { $exists: true },
    };
    return getPersonnels(filter);
  };
  const getPersonnelByUser_id = (user_id) => {
    let personnel;
    let filter = {
      users_user_id: user_id,
    };
    return templateService.getEntities(filter).then((res) => {
      if (!res.data.error) {
        if (res.data.length > 0) {
          personnel = res.data[0];
        }
      } else {
        console.log(
          `ERROR getPersonnelByUser_id res.data: ${JSON.stringify(
            res.data,
            null,
            2
          )}`
        );
      }
      return personnel;
    });
  };
  const getPersonnelByVatNo = (vatNo) => {
    let personnel;
    let filter = {
      vatNo: vatNo,
    };
    return templateService.getEntities(filter).then((res) => {
      if (!res.data.error) {
        if (res.data.length > 0) {
          personnel = res.data[0];
        }
      } else {
        console.log(
          `ERROR getPersonnelByVatNo res.data: ${JSON.stringify(
            res.data,
            null,
            2
          )}`
        );
      }
      return personnel;
    });
  };

  const deletePersonnelWithWarning = (personnel, note?) => {
    /** Before Deleting a personnel entry check if it is tied to an alarm */
    const alarms = useCollectionStore.getState().alarms
    const showAlert = useCommonStore.getState().showAlert
    const allarmsTiedToPersonnel = alarms?.filter(al=>
      al.assignedTo.some(el=>
        el.personnelsId===personnel._id
        ))

    const alarmNames = allarmsTiedToPersonnel?.map(el=>el.name).join(", ")
    if (!!alarmNames) {
      showAlert(i18n.t("net.features.confirm.delete.feature.tied.to.alarm") + alarmNames, 'W')
      return Promise.resolve({ personnel })
    }
    return templateService.deleteEntity(personnel,note)
  }

  return {
    getPersonnelsWithUser: getPersonnelsWithUser,
    getPersonnelByUser_id: getPersonnelByUser_id,
    getPersonnelByVatNo: getPersonnelByVatNo,
    getEntities: getPersonnels,
    saveEntity: templateService.saveEntity,
    deleteEntity: deletePersonnelWithWarning,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  };
};

export default PersonnelService;
