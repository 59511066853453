import { Typography, TypographyProps, makeStyles, createStyles,Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**Blocks the text from becoming selectable -
     * should be applied to all components where `<TextNt>` is used
     * otherwise Safari will select them even if their parent is an unselectable component (eg text inside the buttons) */
    noSelect:{
      userSelect: 'none', /* Standard syntax */
      '-webkit-user-select': 'none', /* WebKit (Safari, Chrome) */
      '-moz-user-select': 'none', /* Mozilla Firefox */
      '-ms-user-select': 'none', /* Internet Explorer/Edge */
    }
  })
)

export interface TextNtProps extends TypographyProps {
  /**Defaults to body2 (fontSize:14px, fontWeight:400, letterSpacing: 0.17136px , lineHeight:1.43)  */
  variant?: 'h1' | 'h2' | 'h3' | 'body1' | 'body2' | 'caption'
  noSelect?: boolean
  trailChar?: string
  dontTranslate?: boolean
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
}

/**Renders a stripped down material-ui `<Typography />` 
 *
 * ❗If `children` is a string - it will be wrapped in a useTranslation function t(),
 * if `children` is a component ReactNode then it will be rendered without applying the translation
 *
 * Eg: `<TextNt>btn.add</TextNt>` will return the translation -> `Προσθήκη` (in case we don't have a key for 'btn.add' then the plain string `btn.add` will be rendered)
 * 
 * ❗Also accepts all default Material-ui props for `<Typography>`
 */
export function TextNt({noSelect=false, trailChar="", dontTranslate=false, ...props}: TextNtProps) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Typography {...props} className={clsx(noSelect ? classes.noSelect : "", props.className)} variant={props.variant ? props.variant : 'body2'}>
      {(typeof props.children === 'string' && !dontTranslate) ? t(props.children) : props.children}
      {trailChar && trailChar }
    </Typography>
  )
}

export default TextNt
