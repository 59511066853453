import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'

import useFeatureCollection from '../../hooks/useFeatureCollection'
import { WaterNetworksSubTypes } from '../../utils/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    justifyContent: 'center',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  },
  lilab: {
    display: 'flex',
    minWidth: 180,
    maxWidth: 180,
    fontWeight: 700,
  },
  lival: {
    minWidth: 370,
    maxWidth: 370,
  },
  livallean: {
    minWidth: 90,
    maxWidth: 90,
  },
  livalfullname: {
    minWidth: 180,
    maxWidth: 180,
    marginRight: 10,
  },
  livalemail: {
    minWidth: 180,
    maxWidth: 180,
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  autoinputroot: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
    paddingLeft: '14px !important',
    fontSize: 14,
  },
  autoinput: {
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
  },
  inputlean: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 14,
  },
  dlgpaper: {
    maxWidth: '80vw',
  },
  dlgcontroot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.tertiary.light}`,
    borderBottom: `solid 1px ${theme.palette.tertiary.light}`,
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.tertiary.main,
  },
  uploadbtn: {
    overflow: 'hidden',
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(15),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  uploadinput: {
    cursor: 'pointer',
    position: 'absolute',
    top: -22,
    width: theme.spacing(15),
    height: 70,
    opacity: 0,
  },
  chip: {
    margin: 1,
  },
  nofill10x6: {
    fill: 'none',
    width: 10,
    height: 6,
    marginRight: 8,
  },
  fs62: {
    fontSize: '0.625rem',
  },
  mw62: {
    minWidth: '0.625rem',
  },
  editIcon: {
    fontSize: '0.875rem',
  },
  mi: {
    whiteSpace: 'pre-wrap',
  },
}))

const EditProperty = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const networkElementCollection = useFeatureCollection()

  const formatEditingValue = (val, info) => {
    console.log(`formatValue val ${val}`)
    let fval = val
    if (!!val && !!info.format) {
      switch (info.type) {
        // case 'date':
        //   fval = moment(val).format(info.format)
        //   break;
        case 'number':
        case 'posnumber':
          if (!isNaN(fval)) {
            let fractionDigits = info.format.length - info.format.indexOf('.') - 1
            fval = parseFloat(fval.toFixed(fractionDigits))
          }
          break;
        default:
      }
    }
    console.log(`formatValue val ${fval}`)
    return fval
  }

  const handleChange = (event, newVal?) => {
    // console.log(`handleChange newVal: ${JSON.stringify(newVal)}`)

    let value: any = !!event.target.value ? event.target.value : ''
    let fvalue
    if (props.info.type === 'boolean') {
      value = event.target.checked
    } else if (props.info.type === 'number' || props.info.type === 'posnumber') {
      fvalue = parseFloat(value)
      if (!isNaN(value)) {
        if (props.info.type === 'posnumber' && fvalue < 0) {
          fvalue = -fvalue
        }
        value = fvalue
      } else {
        value = ''
      }
    }
    if (!!newVal) value = newVal.code
    let updatedFeature = { ...props.feature }
    // console.log(`handleChange props.info.code ${props.info.code} value: ${value}`)

    //editedObj is not a clone so updating it will update the updatedFeature
    let editedObj = updatedFeature
    if (!!props.info.nested) {
      // f[propInfo.nested][propInfo.code] = value
      let pathElements = props.info.nested.split('.')
      pathElements.forEach(p => {
        if (!editedObj[p]) {
          editedObj[p] = {}
        }
        editedObj = editedObj[p]
      })
    }
    if (!!props.info.format) {
      value = formatEditingValue(value, props.info)
    }
    if (!!newVal && !!newVal.type && props.info.aggregates) {
      editedObj[props.info.aggregates] = newVal.code
      editedObj[props.info.code] = newVal.type
    } else {
      editedObj[props.info.code] = value
    }

    /** If networkSubType value does not belong to its parent list set it to ""
     * e.g. original state {network:recreation, networkSubType:sports}
     * the user changes network's value to 'waterSupply'
     * -> new state {network:waterSupply, networkSubType:""}  */
    if (
      props.editingProp === 'network' &&
      !!updatedFeature.properties.networkSubType &&
      !!updatedFeature.properties.network
    ) {
      const correctValues = Object.values(WaterNetworksSubTypes).filter(
        type => type.parentValue === updatedFeature.properties.network
      )
      updatedFeature = correctValues.some(t => t.code === updatedFeature.properties.networkSubType)
        ? updatedFeature
        : { ...updatedFeature, properties: {...updatedFeature.properties, networkSubType: ''} }
    }
    props.setFeature(updatedFeature)
  }

  const buildListOptions = (): any[] => {
    let validValues = Object.values<any>(values)
    if (props.info.parentListCode) {
      validValues = validValues.filter(v => props.feature.properties[props.info.parentListCode] === v.parentValue)
    }
    let options: any[] = Object.values<any>(validValues)
    .map(c => {
      return {
        code: c.code,
        label: t(`${c.transKey}.${c.code}`)
      }
    })
    return options
  }

  const buildFeatureOptions = (): any[] => {
    let options: any[] = []
    featureTypes.forEach(ft => {
      if (!!networkElementCollection[ft]) {
        networkElementCollection[ft].forEach(f => {options.push({ code: f.properties.code, label: f.properties.label, type: f.properties.featureType }) })//
      }
    })
    return options
    // return props[values].features.map((wz) => ({ code: wz.properties.code, label: wz.properties.label }))
  }

  const buildGroupedOptions = (): any[] => {
    //console.log(`buildGroupedOptions`)
    let options: any[] = []
    if (!!props.info.selectionListGroups) {
      props.info.selectionListGroups.forEach(g => {
        // console.log(`buildGroupedOptions - group ${g}`)
        if (!!networkElementCollection[g.values]) {
          // console.log(`buildGroupedOptions - elemenst ${JSON.stringify(props[g].features)}`)
          networkElementCollection[g.values].forEach(f => {
            let o = {
              type: g.type,
              code: f.properties.code,
              label: f.properties.label,
            }
            options.push(o)
          })
        }
      })
    }
    return options
  }

  const extractValueFromFeature = (key) => {
    let tval: null | any = null
    if (!props.info.nested) {
      tval = props.feature[key]
    } else {
      let pathElements = props.info.nested.split('.')
      let parent = props.feature
      pathElements.forEach(p => {
        if (!parent[p]) {
          parent[p] = {}
        }
        parent = parent[p]
      })
      tval = parent[key]
    }
    if (!tval) tval = ''
    return tval
  }

  let propertyValue: null | any = extractValueFromFeature(props.editingProp)

  let isList: boolean = props.info.type === 'selectionList'
  let isDynamicList: boolean = props.info.type === 'dynamicSelectionList'
  let isBoolean: boolean = props.info.type === 'boolean'
  let isGroupedDynamicSelectionList: boolean = props.info.type === 'groupedDynamicSelectionList'
  let values: any[] = !!props.info.values ? props.info.values : []
  let featureTypes: string[] = !!props.info.featureTypes ? props.info.featureTypes : []
  let options: any[] = isDynamicList ? buildFeatureOptions() : (isList ? buildListOptions() : [])
  let groupedBy: boolean = isGroupedDynamicSelectionList && props.info.groupedBy
  let groupedOptions: any[] = isGroupedDynamicSelectionList && groupedBy ? buildGroupedOptions() : []
  let aggregateValue = isGroupedDynamicSelectionList && groupedBy ? extractValueFromFeature(props.info.aggregates) : ''
  let value = propertyValue
  if (isList) {
    // value = propertyValue
    let option = options.find(o => o.code === propertyValue)
    value = !!option ? option : null
  } else if (isDynamicList) {
    let option = options.find(o => o.code === propertyValue)
    value = !!option ? option : null
  } else if (groupedBy) {
    let option = groupedOptions.find(o => o.code === aggregateValue)
    value = !!option ? option : null
  } else if (isBoolean) {
    value = !!propertyValue
  } else {
    // value = formatValue(propertyValue, props.info)
  }

  return (
    <ListItem className={classes.li}
      key={props.info.code}>
      <Box className={classes.lilab}>
        {!!props.info.aggregates ? t(props.info.aggregateLabel) : t(props.info.label)}
      </Box>
      {!(isList || isDynamicList || isBoolean || isGroupedDynamicSelectionList) &&
        <TextField
          id={props.info.code}
          variant='outlined'
          type={props.info.type === 'posnumber' ? 'number' : props.info.type}
          required
          fullWidth
          className={classes.lival}
          inputProps={{
            className: classes.input,
            readOnly: false,
          }}
          // value={formatValue(propertyValue, props.info)}
          value={value}
          disabled={!!props.info.readonly}
          onChange={event => handleChange(event)}
        />
      }
      {isList &&
        <Autocomplete
          className={classes.lival}
          clearOnEscape
          options={options}
          value={value}
          onChange={(event, newVal) => handleChange(event, newVal)}
          getOptionLabel={(option: any) => !!option.label ? option.label : 'NO LABEL'}
          getOptionSelected={(o, v) => (o.code === v.code)}
          renderInput={(params) => (
            <TextField {...params}
              variant='outlined'
            />
          )}
          classes={{ inputRoot: classes.autoinputroot, input: classes.autoinput, }}
        />
      }
      {isDynamicList &&
        <Autocomplete
          className={classes.lival}
          clearOnEscape
          options={options}
          groupBy={featureTypes.length === 1 ? undefined : (option) => t(`net.${option.type}.plural`)}
          // value={!!options.find(o => o.code === propertyValue) ? options.find(o => o.code === propertyValue) : null}
          value={value}
          onChange={(event, newVal) => handleChange(event, newVal)}
          getOptionLabel={(option: any) => !!option.label ? option.label : 'NO LABEL'}
          getOptionSelected={(o, v) => (o.code === v.code)}
          renderInput={(params) => (
            <TextField {...params}
              variant='outlined'
            />
          )}
          classes={{ inputRoot: classes.autoinputroot, input: classes.autoinput, }}
        />
      }
      {isGroupedDynamicSelectionList && groupedBy &&
        <Autocomplete
          id='partof'
          className={classes.lival}
          clearOnEscape
          options={groupedOptions}
          // groupBy={(option) => FTC[option.type].label}
          groupBy={(option) => t(`net.${option.type}.plural`)}
          getOptionSelected={(o, v) => o.code === v.code}
          disabled={props.info.code === "outNodeFeatureType" || props.info.code === "inNodeFeatureType"}
          // value={groupedOptions.find(o => o.code === aggregateValue)}
          value={value}
          onChange={handleChange}
          getOptionLabel={(option: any) => option.label}
          renderInput={(params) => (
            <TextField {...params}
              variant='outlined'
            />
          )}
        />
      }
      {isBoolean &&
        <CheckBoxNt
          checked={value}
          onChange={event => handleChange(event)}
          name={props.info.code}
          color='primary'
        />
      }

    </ListItem>
  )
}
export default EditProperty
