const MeasurementSeriesService = (apiClient?) => {

    const path = 'measurementSeries/'

    const getMeasurementSeriesById = (id:string)=>{
      let fullpath = `${path}${id}`
      let cachePromise = true
      return apiClient.sendGetRequest(cachePromise, fullpath, null, true);
    }
    const getMeasurementSeriesByFeature = (featureType, featureCode) => {
      let fullpath = `${path}findByFeature/${featureType}/${featureCode}`
      //console.log(`getMeasurementSeriesByFeature fullpath is ${fullpath}`)
      let cachePromise = true
      return apiClient.sendGetRequest(cachePromise, fullpath, null, true);
    }
    const getMeasurementSeriesByFeatureAndMsType = (featureType, featureCode, msType) => {
      let fullpath = `${path}findByFeature/${featureType}/${featureCode}/${msType}`
      //console.log(`getMeasurementSeriesByFeature fullpath is ${fullpath}`)
      let cachePromise = true
      return apiClient.sendGetRequest(cachePromise, fullpath, null, true);
    }
    const createMeasurementSeries = (series) => {
      let fullpath = `${path}`
      console.log(`createMeasurementSeries fullpath is ${fullpath}`)
      return apiClient.sendRequest(series, false, fullpath, null, true);
    }
    const updateMeasurementSeries = (series) => {
      let fullpath = `${path}${series._id}`
      console.log(`updateMeasurementSeries fullpath is ${fullpath}`)
      return apiClient.sendPutRequest(series, fullpath, null, true);
    }
    const deleteMeasurementSeries = (series) => {
      let fullpath = `${path}${series._id}`
      console.log(`deleteMeasurementSeries fullpath is ${fullpath}`)
      return apiClient.sendDeleteRequest(fullpath, null, true);
    }

    const getMeasurementSeriesByIds = (ids:string[])=>{
      const data:any = {
        ids:ids
      }
      let fullpath = `${path}findMeasurementSeriesByIds`
      // console.log(`getMeasurementSeriesByIds fullpath is ${fullpath}`)
      return apiClient.sendRequest(data, false, fullpath, null, true);
    }
    return({
      getMeasurementSeriesById:getMeasurementSeriesById,
      getMeasurementSeriesByFeature: getMeasurementSeriesByFeature,
      createMeasurementSeries: createMeasurementSeries,
      updateMeasurementSeries: updateMeasurementSeries,
      deleteMeasurementSeries: deleteMeasurementSeries,
      getMeasurementSeriesByFeatureAndMsType: getMeasurementSeriesByFeatureAndMsType,
      getMeasurementSeriesByIds:getMeasurementSeriesByIds,
    })
}

export default MeasurementSeriesService
