import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Box,
} from '@material-ui/core/'
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import {
  GraphColorsPool,
  GraphColorsPool_2,
} from '../../../utils/constants'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  graphContainer: {
    backgroundColor: '#E8E8E8',
    margin: '1.5rem 0',
    breakInside: 'avoid-page',
  },
  graphContainerDark: {
    backgroundColor: '#a8a8a8',
    margin: '1.5rem 0',
    breakInside: 'avoid-page',
  },
  graphTitle: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: '1rem',
    fontWeight: 700,
    fontSize: 24,
  },
  graphTitleDark: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: '1rem',
    fontWeight: 700,
    fontSize: 24,
  },
  graphTooltip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'white',
    fontWeight: 400,
  },
  graphTooltipDark: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'black',
    color: 'white',
    fontWeight: 700,
  },
  brush: {
    '@media print': {
      display: 'none',
    },
  }
}))

interface Props {
  /** You can pass the translation string directly, since the title is passed into a t(title) internally in ButtonHDR  */
  chartType?: 'bar' | 'line' | 'pie'
  title: string
  data: any[]
  xAxisDataKey: string
  yAxisWidth?: number
  dataKeys: string[]
  dataLabels?: string[]
  dataStackIds?: string[]
  width?: number
  height?: number
  colors?: string[]
  dark?: boolean
  scaleX?: 'auto' | 'linear' | 'pow' | 'sqrt' | 'log' | 'identity' | 'time' | 'band' | 'point' | 'ordinal' | 'quantile' | 'quantize' | 'utc' | 'sequential' | 'threshold' | Function
  scaleY?: 'auto' | 'linear' | 'pow' | 'sqrt' | 'log' | 'identity' | 'time' | 'band' | 'point' | 'ordinal' | 'quantile' | 'quantize' | 'utc' | 'sequential' | 'threshold' | Function
  layout?: 'horizontal' | 'vertical'
  classesXtra?: {graphContainer?: any, graphTitle?: any, graphTooltip?: any}
  legendFormatter?: (...args: any) => any
  // domainY?: any[] | Function
  // domainY?: [any, any] | Function
  domainY?: any
  tickCount?: number
  ticks?: number[]
  tickFormatterX?: (value: any, index: number) => string
  tickFormatterY?: (value: any, index: number) => string
  customTooltip?: any
  noLegend?: boolean
  maxBars?: number
  maxBarSize?: number
  maxHorizontalTickLabels?: number
  brushExtraRightMargin?: number
}

const PcChart: React.FC<Props> = ({
  chartType = 'bar',
  title,
  data,
  xAxisDataKey,
  yAxisWidth,
  dataKeys,
  dataLabels,
  dataStackIds,
  width = 800,
  height = 600,
  colors = GraphColorsPool_2,
  dark = false,
  scaleX = 'auto',
  scaleY = 'auto',
  layout = 'horizontal',
  classesXtra = {},
  legendFormatter,
  domainY,
  tickCount = 10,
  ticks,
  tickFormatterX,
  tickFormatterY,
  customTooltip,
  noLegend = false,
  maxBars,
  maxBarSize,
  maxHorizontalTickLabels = 8,
  brushExtraRightMargin,
}: Props) => {
  const classes = useStyles()

  let barDivider = dataKeys.length
  if (dataStackIds) {
    const distinctStackIds = new Set()
    dataStackIds.forEach(sid => distinctStackIds.add(sid))
    barDivider = distinctStackIds.size || 1
  }
  const [barCount, setBarCount] = useState(data?.length > 0 ? (maxBars && (maxBars / barDivider) < data.length ? (maxBars / barDivider) : (data.length / barDivider)) : 0)
  const actualMaxBars = barCount
  // const [barCount, setBarCount] = useState(data?.length > 0 ? (maxBars && maxBars < data.length ? maxBars : data.length) : 0)
  // const actualMaxBars = maxBars

  const showHorizontalXAxisTickLabels = maxHorizontalTickLabels >= barCount
  // console.log(`barCount ${barCount} showHorizontalXAxisTickLabels ${showHorizontalXAxisTickLabels}`)

  const CustomTooltip = (ctprops) => {
    const { active, payload } = ctprops
    if (active && payload && payload.length) {
      // console.log(`CustomTooltip payload ${JSON.stringify(payload,null,2)}`)
      return (
        <Box
          className={ctprops.classesXtra?.graphTooltip 
            ? clsx((ctprops.dark ? classes.graphTooltipDark: classes.graphTooltip), ctprops.classesXtra?.graphTooltip) : 
            ctprops.dark ? classes.graphTooltipDark: classes.graphTooltip
          }
        >
          <Box>{payload[0].payload.label}</Box>
          {payload.map(p => {
            let periodStr = p.name.substring(p.name.lastIndexOf('_') + 1)
            const valueOptions = {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }
            let formattedValue = `${new Intl.NumberFormat('el', valueOptions).format(p.value)}`
            return (
          <Box key={p.dataKey} style={{color: p.color}}>{`${periodStr} ${formattedValue}`}</Box>
            )
          })}
        </Box>
      )
    }
    return null
  }

  const getDefaultLinearMax = (dataMax: number): number => {
    let ceil = Math.ceil(dataMax)
    let pow = ceil.toString().length - 1
    let max = (parseInt(ceil.toString().substring(0, 1)) + 1) * Math.pow(10, pow)
    if (dataMax / max < 0.6) {
      max = max * 0.6
    }
    return max
  }
  const getDefaultLinearMaxFromData = (data: any[], dataKeys: string[], dataStackIds?: string[]): number => {
    if (!data || data.length === 0) return 0
    let max = data[0][dataKeys[0]] || 0
    data.forEach(d => {
      let val: number
      let stackVals: any = {}
      dataKeys.forEach((dk, i) => {
        if (d[dk]) {
          if (dataStackIds) {
            if (!stackVals[dataStackIds[i]]) {
              stackVals[dataStackIds[i]] = 0
            }
            stackVals[dataStackIds[i]] = stackVals[dataStackIds[i]] + d[dk]
            val = stackVals[dataStackIds[i]]
          } else {
            val = d[dk]
          }
          max = Math.max(max, val)
        }
      })
    })
    return getDefaultLinearMax(max)
  }

  let domainYToUse: any = [0, 'auto']
  // let domainYToUse: any = [0, 'dataMax']
  if (!!domainY) {
    domainYToUse = domainY
  } else if (scaleY === 'linear') {
    // domainYToUse = [0, getDefaultLinearMax]
    domainYToUse = [0, getDefaultLinearMaxFromData(data, dataKeys, dataStackIds)]
  }
  let customTooltipToUse: any = CustomTooltip
  if (customTooltip) {
    customTooltipToUse = customTooltip
  }

  const CustomizedAxisTick = (props) => {
    const { x, y, payload, angle, tickFormatter } = props
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={0} dy={0} fontSize={12} textAnchor='end' fill='#666' transform={`rotate(${angle})`} >
          {tickFormatter ? tickFormatter(payload.value) : payload.value}
        </text>
      </g>
    );
  }

  const handleBrushChange = ({startIndex, endIndex}) => {
    let bc = endIndex - startIndex + 1
    if (bc !== barCount) {
    // console.log(`handleBrushChange bc ${bc} barCount ${barCount}`)
      setBarCount(bc)
    }
    // console.log(`handleBrushChange ${bc} ${endIndex} ${startIndex}`)
  }

  const renderCustomizedPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.875
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const options = { minimumFractionDigits: 1, maximumFractionDigits: 1, }

    return (
      <text x={x} y={y}
        fill='black'
        // textAnchor={x > cx ? 'start' : 'end'}
        textAnchor='middle'
        dominantBaseline='central'
        fontSize={12}
      >
        {`${new Intl.NumberFormat('el', options).format(percent * 100)}%`}
      </text>
    )
  }
  const chartDataCompos = dataKeys.map((k, i) => {
    if (chartType === 'bar') {
      return (
        <Bar key={k}
          dataKey={k}
          name={dataLabels ? dataLabels[i] : ''}
          fill={colors[i]}
          minPointSize={1}
          stackId={dataStackIds ? dataStackIds[i] : undefined}
        />
      )
    } else if (chartType === 'line') {
      return (
        <Line key={k}
          dataKey={k}
          name={dataLabels ? dataLabels[i] : ''}
          fill={colors[i]}
          strokeWidth={3}
        />
      )
    } else {//if (chartType === 'pie') {
      return (
        <Pie key={k}
          data={data}
          dataKey={k}
          nameKey={dataLabels ? dataLabels[i] : ''}
          // label
          label={renderCustomizedPieLabel}
          labelLine={false}
          outerRadius='100%'
        >
          {data.map((d, di) => (
            <Cell key={di} fill={colors[di]} />
          ))}
        </Pie>
      )
    }

  })

  const cartesianGrid = (
    <CartesianGrid vertical={layout === 'vertical'} horizontal={layout !== 'vertical'}/>
  )
  // console.log(`Math.floor(barCount / 30) ${Math.floor(barCount / 30)} barCount / 30 ${barCount / 30} barCount ${barCount}`)
  const xAxis = layout === 'horizontal' ? (
    <XAxis
      scale={scaleX}
      axisLine={false}
      tickFormatter={tickFormatterX}
      dataKey={xAxisDataKey}

      interval={maxBars ? Math.floor(barCount / (maxBars + 1)) : 0}
      padding={chartType === 'line' ? { left: 50, right: 50 } : undefined}
      tick={showHorizontalXAxisTickLabels ? undefined : <CustomizedAxisTick angle={-60} tickFormatter={tickFormatterX} />}
    />
  ) : (
    <XAxis
      type='number'
      scale={scaleY}
      axisLine={false}
      tickLine={false}
      ticks={ticks}
      tickCount={ticks ? ticks.length : tickCount}
      domain={domainYToUse}
      tickFormatter={tickFormatterY}
      padding={chartType === 'line' ? { left: 50, right: 50 } : undefined}
    />
  )
  const yAxis = layout === 'horizontal' ? (
    <YAxis
      scale={scaleY}
      axisLine={false}
      tickLine={false}
      ticks={ticks}
      tickCount={ticks ? ticks.length : tickCount}
      domain={domainYToUse}
      tickFormatter={tickFormatterY}
      width={yAxisWidth}
    />
  ) : (
    <YAxis
      type='category'
      scale={scaleX}
      axisLine={false}
      // tickLine={false}
      tickFormatter={tickFormatterX}
      dataKey={xAxisDataKey}
      width={yAxisWidth}

      interval={0}
      minTickGap={2}
    />
  )
  const brush = maxBars && (maxBars / barDivider) < data.length ? (
    <Brush
      //'recharts-brush-bar' class is added so that <Brush> can be temporary removed(hidden) when the user downloads the image
      className={clsx(classes.brush, 'recharts-brush-bar')}
      dataKey={xAxisDataKey}
      height={20}
      width={width - ((yAxisWidth || 60) + 35 + (brushExtraRightMargin || 0))} //yAxisWidth+35 or 155 is the default. We add optionally brushExtraRightMargin so there's enough space for a long label
      stroke='#111111'
      travellerWidth={10}
      startIndex={0}
      endIndex={(maxBars / barDivider) - 1}
      // tickFormatter={(xval => '')}
      tickFormatter={tickFormatterX}

      y={height - 30}
      // y={200}
      onChange={_.debounce(handleBrushChange, 100)}
    />
  ) : undefined
  const tooltip = (
    <Tooltip
      content={customTooltipToUse}
    />
  )
  const legend = !noLegend ? (
    <Legend
      formatter={legendFormatter}
      wrapperStyle={{paddingLeft: 16, maxWidth: 300}}

      layout='vertical'
      align='right'
      verticalAlign='middle'
      iconType='square'
      iconSize={8}
    />
  ) : undefined

  return (
    <Box
      className={classesXtra?.graphContainer 
        ? clsx((dark ? classes.graphContainerDark : classes.graphContainer), classesXtra.graphContainer ) 
        : dark ? classes.graphContainerDark : classes.graphContainer
      }
    >
      <Box
        className={classesXtra?.graphTitle 
          ? clsx((dark ? classes.graphTitleDark : classes.graphTitle), classesXtra.graphTitle) 
          : dark ? classes.graphTitleDark : classes.graphTitle
        }
      >
        {title}
      </Box>
      {/* <ChartCompo > */}

      {chartType === 'bar' &&
      <BarChart
        layout={layout}
        width={width}
        height={height}
        data={data}
        margin={{top: 30, right: 25, left: 10, bottom: 60, }}
        // barCategoryGap={'25%'}
        barCategoryGap={`${Math.floor(50 / (!!dataStackIds ? dataStackIds.reduce((sids: any, id) => sids.add(id), new Set()).size + 1 : dataKeys.length + 1))}%`}
        maxBarSize={maxBarSize}
        // barSize={70}
        // barGap={'10%'}
      >
        {cartesianGrid}
        {xAxis}
        {yAxis}
        {brush}
        {tooltip}
        {legend}
        {chartDataCompos}
      </BarChart>
      }
      {chartType === 'line' &&
      <LineChart
        layout={layout}
        width={width}
        height={height}
        data={data}
        margin={{top: 30, right: 25, left: 10, bottom: 60, }}
      >
        {cartesianGrid}
        {xAxis}
        {yAxis}
        {brush}
        {tooltip}
        {legend}
        {chartDataCompos}
      </LineChart>
      }
      {chartType === 'pie' &&
      <PieChart
        layout={layout}
        width={width}
        height={height}
        data={data}
        margin={{top: 30, right: 25, left: 10, bottom: 60, }}
      >
        {tooltip}
        {legend}
        {chartDataCompos}
      </PieChart>
      }

    </Box>
  )

}

export default PcChart
