import { useCommonStore } from '../state/commonStore'
import { useIrriStatusStore } from '../state/irriStatusStore'
import TemplateService from './TemplateService'

const AssetService = () => {

  const templateService: any = TemplateService('asset')

  const profile = useCommonStore.getState().profile
  const apiClient = useCommonStore.getState().apiClient
  const path = 'asset/'

  const getInvertersState = (options?: any) => {
    let data: any = {
      operation: 'getInvertersState',
    }
    if (options) {
      data.options = options
    }
    if (options?.global) {
      options.tenants = ['nm-tp', null].concat(profile?.tenants || [])
    }
    let cachePromise = true
    let _path = 'irriCommand/'
    return sendRequest(data, cachePromise, _path)
    .then(
      res => {
        if (!res.data.error) {
          console.log(`getInvertersState cnt ${Object.keys(res.data).length}`)

          //store ctrlrs state data in useIrriStatusStore only if this is not a request for global ctrlrs state
          if (!options?.global && profile) {
            let tenantCtrlsState = res.data[profile.selectedTenant]
            const oldState = useIrriStatusStore.getState().stationInverters
            const newState = {...oldState, ...tenantCtrlsState}
            useIrriStatusStore.getState().setStationInverters(newState)
          }
          return res
        } else {
          console.log(`ERROR getInvertersState ${JSON.stringify(res.data,null,2)}`)
          return { data: {} }
        }
      },
      err => {
        console.log(`ERROR getInvertersState ${JSON.stringify(err, null, 2)}`)
        return { data: {} }
      }
    )
  }
  const getIrriCtrlsState = (options?: any) => {
    let data: any = {
      operation: 'getIrriCtrlsState',
    }
    if (options) {
      data.options = options
    }
    if (options?.global) {
      options.tenants = ['nm-tp', null].concat(profile?.tenants || [])
    }
    let cachePromise = true
    let _path = 'irriCommand/'
    return sendRequest(data, cachePromise, _path)
    .then(
      res => {
        if (!res.data.error) {
          console.log(`getIrriCtrlsState cnt ${Object.keys(res.data).length}`)

          //store ctrlrs state data in useIrriStatusStore only if this is not a request for global ctrlrs state
          if (!options?.global && profile) {
            let tenantCtrlsState = res.data[profile.selectedTenant]
            const oldState = useIrriStatusStore.getState().stations
            const newState = {...oldState, ...tenantCtrlsState}
            useIrriStatusStore.getState().setStations(newState)
          }
          return res
        } else {
          console.log(`ERROR getIrriCtrlsState ${JSON.stringify(res.data,null,2)}`)
          return { data: {} }
        }
      },
      err => {
        console.log(`ERROR getIrriCtrlsState ${JSON.stringify(err, null, 2)}`)
        return { data: {} }
      }
    )
  }
  /**
   * 
   * @returns 
   */
  const clearCache = (keys?: string[]) => {
    let data: any = {
      operation: 'clearCache',
      keys: keys,
    }
    return sendRequest(data)
    .then(
      res => {
        if (!res.data.error) {
          console.log(`clearCache res ${JSON.stringify(res.data,null,2)}`)
          return res.data
        } else {
          console.log(`ERROR clearCache ${JSON.stringify(res.data,null,2)}`)
          return res.data
        }
      },
      err => {
        console.log(`ERROR clearCache`)
        console.log(err)
        return err
      }
    )
  }
  /**
   * 
   * @param filter 
   * @param options 
   * @returns 
   */
  const getGlobalIrriCtrls = (filter?: any, options?: any) => {
    let data: any = {
      operation: 'getGlobalIrriCtrls',
      filter: filter
    }
    if (options) {
      data.options = options
    }
    return sendRequest(data)
    .then(
      res => {
        if (!res.data.error) {
          console.log(`getGlobalIrriCtrls cnt ${res.data.length}`)
          const vissibleTenants = ['nm-tp', null].concat(profile?.tenants || [])
          const ctrls = res.data.filter(c => !c.tenantCode || vissibleTenants.includes(c.tenantCode))
          return { data: ctrls }
        } else {
          console.log(`ERROR getGlobalIrriCtrls ${JSON.stringify(res.data,null,2)}`)
          return { data: [] }
        }
      },
      err => {
        console.log(`ERROR getGlobalIrriCtrls ${JSON.stringify(err, null, 2)}`)
        return { data: [] }
      }
    )
  }

  /**
   * 
   * @param irriCtrl_url - the url that the controller listens to
   * @param attachment - the attachment metadata object
   */
  const updateIrriCtrlFirmware = (irriCtrl_url, attachment) => {
    let data: any = {
      operation: 'updateIrriCtrlFirmware',
      irriCtrl_url: irriCtrl_url,
      attachment: attachment,
    }
    return sendRequest(data)
  }

  const sendRequest = (data: any, cachePromise?: boolean, _path?: string) => {
    // console.log(`IrriProgService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, cachePromise, _path || path, null, true)
  }

  return({
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
    getGlobalIrriCtrls: getGlobalIrriCtrls,
    updateIrriCtrlFirmware: updateIrriCtrlFirmware,
    getIrriCtrlsState: getIrriCtrlsState,
    getInvertersState: getInvertersState,
    clearCache: clearCache,
  })

}

export default AssetService
