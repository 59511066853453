// src/index.ts

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from './react-auth0-spa'
import config from './auth_config.json'
import history from './utils/history'
import cleanCookies from "./utils/cookieCleaner";
import './App.css'
import './i18n'
import axios from 'axios'

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

axios.interceptors.request.use(request => {
  // console.log(request);
  return request
}, error => {
  console.error(`axios.interceptors.request error`)
  console.log(error)
  return Promise.reject(error);
}
);

axios.interceptors.response.use(response => {
  // console.log(response);
  return response
}, error => {
  // console.error(`axios.interceptors.response error`)
  // console.log(error)
  if (!!error.response && !!error.response.data && !!error.response.data.error && !!error.response.data.error.message) {
    console.error(`axios.interceptors.response error.response.data.error.message ${error.response.data.error.message}`)
  }
  return Promise.reject(error);
}
);

cleanCookies();

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={config.audience}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
// serviceWorker.register()
