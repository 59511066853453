import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Backdrop,
  Paper,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import {useCollectionStore} from "../../state/collectionStore"
import { useCommonStore } from '../../state/commonStore'
import { getLocalizedNotificationContent, notificationDataType, stripHtmlTags } from '../../utils/helpers'

function createMarkup(str) {
  return { __html: str }
}

const useStyles = makeStyles(theme => ({
  paperRoot: {
    width: '350px',
    maxHeight: '250px',
    top: '8%',
    display: 'flex',
    //@ts-ignore
    // margin: containerWidth => `3rem ${containerWidth / 2 - 175}px auto auto`,
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  alarmRoot: {
    width: '300px',
    padding: '0 .75rem 0',
  },
  header: {
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: '0.5rem',
  },
  headerTitle: {
    margin: '0 !important',
  },
  headerTitleMP: {
    margin: '0 !important',
    fontSize: 16,
  },
  headerTitleDeleted: {
    margin: '0 !important',
    color: 'red',
    textShadow: '0px 0px 17px rgba(50, 50, 50, 1)',
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    overflowY: 'auto',
    fontSize: '14px',
    '& p': {
      margin: "2px 0",
    },
  },
  typography: {
    margin: '0 0 0.5rem !important',
    textAlign: 'justify',
    '& .red': {
      color: 'red',
    },
  },
  typographyLeft: {
    margin: '0 0 0.5rem !important',
    textAlign: 'left',
    '& .red': {
      color: 'red',
    },
  },
  arrowDown: {
    width: '0px',
    height: '0px',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '20px solid #fff',
    transform: 'translateY(100%)',
  },
  backdrop: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    zIndex: 1300,
    top: 0,
    left: 0,
    backgroundColor: '#6960606e',
  },
}))

export default function NotificationPopUp({
  handleClosePopUp,
  // containerWidth,
  selectedFeatureWithActiveAlarms,
  inventoryItems,
  repairs,
  maintenanceService,
}) {
  // const classes = useStyles(containerWidth)
  const classes = useStyles()
  const { t } = useTranslation()
  const locale = useCommonStore(state => state.locale)
  const personnels = useCollectionStore(state => state.personnels)
  const alarms = useCollectionStore(state=>state.alarms)

  if (!selectedFeatureWithActiveAlarms?.notification) {
    /**selectedFeatureWithActiveAlarms is an object {feature:{...},notification:{...}}
     * so even if there is no feature (the sampling point was deleted)
     * the popup will still open, no zoom/pan to will be triggered
     * it will popup in the middle of the map
     */
    return null
  }

  const alarm = alarms?.filter(a => a._id === selectedFeatureWithActiveAlarms.notification.alarm)[0]

  const AssignedToHTML = props => {
    const [loading, setLoading] = useState<boolean>(false)
    const [maintenanceEvent, setMaintenanceEvent] = useState<any>(null)

    const notification = selectedFeatureWithActiveAlarms.notification
    // console.log(notification)
    switch (notification.notificationType) {
      case 'inventoryItem': {
        const notificationsInventoryItem = inventoryItems.find(
          invItem => invItem._id === notification.inventoryItemId
        )
        const personnel = personnels?.find(
          p => p._id === notificationsInventoryItem?.responsible_id
        )
        return personnel ? (
         <Box display={"flex"} flexDirection="column">
          <span>{` ${t('alarm.operator')}: `}<b>{`${personnel.firstname} ${personnel.lastname}`}</b></span>
          <span>{`${t('alarm.contactPhone')}: `}<b>{`${personnel.mobile ? personnel.mobile : ''}`}</b></span>
         </Box>
        ) : <React.Fragment/>
      }
      case 'networkRepair': {
        /**NETWORK REPAIR NOTIFICATIONS ARE NOT READY... */
        const repair = repairs.find(
          rep => rep._id === notification.networkRepairId
        )
        const personnel = personnels?.find(p => p._id === repair?.responsible_id)
        return personnel ? (
          <Box display={"flex"} flexDirection="column">
            <span>{` ${t('alarm.repairResponsible')}: `}<b>{`${personnel.firstname} ${personnel.lastname}`}</b></span>
            <span>{`${t('alarm.contactPhone')}: `}<b>{`${personnel.mobile ? personnel.mobile : ''}`}</b></span>
         </Box>
        ) : <React.Fragment/>
      }
      case 'maintenancePlan': {
        let personnel: any = undefined
        if (!maintenanceEvent && !loading) {
          setLoading(true)
          maintenanceService
            .getMaintenanceEventByID('maintenanceplans', {
              _id: notification.maintenanceId,
              // _id: notification.maintenanceId || '6172b6393ff003002fa7d292',
            })
            .then(res => {
              setMaintenanceEvent(res.data)
              setLoading(false)
            })
            .catch(err => {
              setMaintenanceEvent('error')
              setLoading(false)
            })
        } else {
          personnel = personnels?.find(
            p => p._id === maintenanceEvent?.responsible_id
          )
        }

        return (
          <div>
            {loading && 'Loading...'}
            {!loading && personnel ? (
               <Box display={"flex"} flexDirection="column">
                <span>{` ${t('alarm.maintenanceResponsible')}: `}<b>{`${personnel.firstname} ${personnel.lastname}`}</b></span>
                <span>{`${t('alarm.contactPhone')}: `}<b>{`${personnel.mobile ? personnel.mobile : ''}`}</b></span>
              </Box>
            ) : null}
          </div>
        )
      }
      case 'alarmFired':
      case 'invrtAlarmFired': {
        const alarm = alarms?.filter(
          a => a._id === selectedFeatureWithActiveAlarms.notification.alarm
        )[0]
        return (
          <div>
            {alarm
            ? alarm?.assignedTo.map((a, ind) => {
                const per = personnels?.find(p => p._id === a.personnelsId)
                return per ? (
                  <span key={a.personnelsId ? a.personnelsId : ind}>{` ${
                    per.firstname
                  } ${per.lastname} - ${t('alarms.assignedTo.phone')}: ${
                    per.mobile ? per.mobile : '""'
                  }. `}</span>
                ) : <React.Fragment/>
              })
            : <React.Fragment/>
            }
          </div>
        )
      }
      default:
        return <React.Fragment/>
    }
  }

  let locdata: notificationDataType = getLocalizedNotificationContent(selectedFeatureWithActiveAlarms.notification, locale)
  const NotificationContent = ()=>{
    return (
      <Paper className={classes.paperRoot}>
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={1}
          className={classes.alarmRoot}>
          <Box
            display='flex'
            px={1}
            py={2}
            alignItems='center'
            className={classes.header}
            justifyContent={'space-between'}>
            {['alarmFired', 'invrtAlarmFired'].includes(selectedFeatureWithActiveAlarms.notification.notificationType) && (
              <Typography
                className={alarm?.name ? classes.headerTitle : classes.headerTitleDeleted}
                component='h2'
                variant='h6'>
                {alarm?.name ? alarm.name : t('alarm.deleted')}
              </Typography>
            )}
            {selectedFeatureWithActiveAlarms.notification.notificationType === 'maintenancePlan' && (
              <Typography
                className={classes.headerTitleMP}
                component='h2'
                variant='h6'>
                {locdata.title}
              </Typography>
            )}
            {selectedFeatureWithActiveAlarms.notification.notificationType === 'irriEventPersonnel' && (
              <Typography
                className={classes.headerTitleMP}
                component='h2'
                variant='h6'>
                {stripHtmlTags(locdata.title)}
              </Typography>
            )}
            <IconButton
              aria-label='close'
              size='small'
              onClick={() => handleClosePopUp(selectedFeatureWithActiveAlarms.notification)}
            >
              <Close fontSize='inherit' />
            </IconButton>
          </Box>
          <Box
            display='flex'
            px={1}
            alignItems={['alarmFired', 'invrtAlarmFired'].includes(selectedFeatureWithActiveAlarms.notification.notificationType) ? 'center': "left"}
            className={classes.content}
          >
            {['alarmFired', 'invrtAlarmFired'].includes(selectedFeatureWithActiveAlarms.notification.notificationType) &&
            <p
              className={classes.typography}
              dangerouslySetInnerHTML={createMarkup(locdata.title)}
            />}
            <p
              className={classes.typography}
              dangerouslySetInnerHTML={createMarkup(locdata.content)}
            />
            {['alarmFired', 'invrtAlarmFired'].includes(selectedFeatureWithActiveAlarms.notification.notificationType) &&
            <Typography className={classes.typographyLeft} variant='body2'>
              {t('alarms.assignedTo2')}:
              <AssignedToHTML />
            </Typography>}
            {!['alarmFired', 'invrtAlarmFired'].includes(selectedFeatureWithActiveAlarms.notification.notificationType) &&
            <Typography className={classes.typographyLeft} variant='body2'>
              <AssignedToHTML />
            </Typography>}
          </Box>
          {/* {selectedFeatureWithActiveAlarms.notification.notificationType ===
            "alarmFired" && (
            <Box className={classes.arrowContainer}>
              <div className={classes.arrowDown}></div>
            </Box>
          )} */}
        </Box>
      </Paper>
    )
  }
  if (!!selectedFeatureWithActiveAlarms.feature) {
    return <NotificationContent />
  } else {
    return (<Backdrop
      open={!!selectedFeatureWithActiveAlarms}
      className={classes.backdrop}>
      <NotificationContent />
    </Backdrop>)
  }
}
