import {shallow} from 'zustand/shallow'
import { useNetworkElementStore } from '../state/networkElementStore'
import { FTC } from '../utils/constants'
import { NetworkElementCollection } from '../utils/types/networkElementTypes'

const useFeatureCollection = () => {
  const NETWORK_ELEMENTS: NetworkElementCollection = useNetworkElementStore(
    state => {
      const result: any = {}
      Object.values(FTC).forEach(ftc => {
        result[ftc.collection] = state[ftc.collection]
      })
      return result
    },
    shallow
  )

  return NETWORK_ELEMENTS
}

export default useFeatureCollection
