import React, { useState, useEffect } from 'react'
import { Button, makeStyles, Box } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import { useCommonStore } from '../../state/commonStore'
import { getLocalizedNotificationContent, notificationDataType } from '../../utils/helpers'
import ButtonHDR from '../presentationalComponents/ButtonHDR'
import { TabCode } from './NotificationsList'

function createMarkup(str: string, str2: string, trimText?: boolean, n?: any) {
  if (trimText) {
    //if trimText is true then cut the message after the first <strong> closing tag
    //this will cut the content 5chars after the alarm date and add text ellipsis (...)

    if (n.notificationType === 'maintenancePlan') {
      const index = str2.indexOf('</strong>')
      str2 = str2.slice(0, index + 10) + '...'
    } else if (n.notificationType === 'alarmFired') {
      const index = str.indexOf('</strong>')
      str2 = str2.slice(0, index + 5) + '...'
    } else if (n.notificationType === 'invrtAlarmFired') {
      const index = str.indexOf('</strong>')
      str2 = str2.slice(0, index + 5) + '...'
    } else if (n.notificationType === 'irriEventPersonnel') {
      const index = 60
      str2 = str2.slice(0, index + 10) + '...'
    }
  }
  if (n.notificationType === 'alarmFired') {
    return { __html: str.concat(str2) }
  } else if (n.notificationType === 'invrtAlarmFired') {
    return { __html: str.concat(str2) }
  } else if (n.notificationType === 'irriEventPersonnel') {
    return { __html: str2 }
  } else {
    return { __html: str.concat('. ' + str2) }
  }
}
/**Sorts notifications by sentDate from the newest to the oldest */
function sortNotificationsBySentDate(notifications:any[]) {
  // return array.sort((a, b) => new Date(a?.sentDate)?.getTime() - new Date(b?.sentDate)?.getTime());
  return notifications.sort((a, b) => new Date(b?.sentDate)?.getTime() - new Date(a?.sentDate)?.getTime());
}

const useStyles = makeStyles(theme => ({
  label: {
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonAccordion: {
    marginBottom: '1.25rem',
  },
  ul: {
    listStyle: 'none',
    paddingLeft: '0.25rem',
    textAlign: 'left',
  },
  customLi: {
    display: 'flex',
    marginBottom: '1rem',
    // cursor: 'pointer',
  },
  listDotContainer: {
    display: 'flex',
    justifyItems: 'center',
    marginRight: '1.5rem',
    alignItems: 'center',
  },
  liDotNew: {
    border: '3px solid #e95a0ccc',
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    background: '#ff0000',
    boxShadow: '0px 0px 6px 0px #E95A0C',
  },
  liDotRead: {
    border: '3px solid #808080',
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    background: '#808080',
    boxShadow: '0px 0px 6px 0px #756156',
  },
  typography: {
    margin: '0 0 0rem !important',
    textAlign: 'justify',
    '& p': {
      margin: 0,
    },
  },
  notificationTextContainer: {
    cursor: 'pointer',
    width: '100%',
  },
}))

type NotificationType = 'urgent' | 'warning' | 'update'
interface NotificationState {
  urgent: any[]
  warning: any[]
  update: any[]
}
interface NotificationAccordionProps {
  openPanel: any
  tab: TabCode
  handleExpandPannelClick: (val: string) => void
  notifications: any[]
  handleOpenAlarmPopUp: (notification: any, feature: any) => void
  trimText: boolean
  accessGranted: (right: string) => boolean
  doUpdateMultipleNotificationWebReadDate?: (notifications: any[], webReadDate: string | Date ) => any
}

export default function NotificationAccordion({
  openPanel,
  tab,
  handleExpandPannelClick,
  notifications,
  handleOpenAlarmPopUp,
  trimText,
  accessGranted,
  doUpdateMultipleNotificationWebReadDate,
}: NotificationAccordionProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const locale = useCommonStore(state => state.locale)
  const [notificationsSorted, setNotificationsSorted] = useState<NotificationState>({
    urgent: [],
    warning: [],
    update: [],
  })
  const [selectedNotifications, setSelectedNotifications] = useState<NotificationState>({
    urgent: [],
    warning: [],
    update: [],
  })

  useEffect(() => {
    let filteredNotifications = [...notifications]
    if (!accessGranted('vwqlty'))
      filteredNotifications = filteredNotifications.filter(n => n.notificationType !== 'alarmFired')
    if (!accessGranted('Vwrrgt9'))
      filteredNotifications = filteredNotifications.filter(n => n.notificationType !== 'invrtAlarmFired')
    if (!accessGranted('VwMntn'))
      filteredNotifications = filteredNotifications.filter(n => n.notificationType !== 'maintenancePlan')
    if (!accessGranted('Vwrrgt3'))
      filteredNotifications = filteredNotifications.filter(n => n.notificationType !== 'irriEventPersonnel')

    // const urgent_temp = filteredNotifications?.filter(n => n.severity === 1)
    // const warning_temp = filteredNotifications?.filter(n => n.severity === 2)
    // const update_temp = filteredNotifications?.filter(n => n.severity === 3)

    let urgent_temp:any[] = []
    let warning_temp:any[] = []
    let update_temp:any[] = []

    filteredNotifications.forEach(notification => {
      const severity = notification.severity;
  
      if (severity === 1) {
          urgent_temp.push(notification);
      } else if (severity === 2) {
          warning_temp.push(notification);
      } else if (severity === 3) {
          update_temp.push(notification);
      }
    });

    setNotificationsSorted({
      urgent: sortNotificationsBySentDate(urgent_temp),
      warning: sortNotificationsBySentDate(warning_temp),
      update: sortNotificationsBySentDate(update_temp),
    })
    // filter out the selected notfications (in case on or more of the selected notifications have been read by the user by clicking on it and closing the popup on the map)
    setSelectedNotifications(prevState=>{
      const newState = {
        urgent: !!prevState?.urgent?.length ? prevState.urgent.filter(code=>urgent_temp.some(n=>n.code === code)) : [],
        warning: !!prevState?.warning?.length ? prevState.warning.filter(code=>warning_temp.some(n=>n.code === code)) : [],
        update: !!prevState?.update?.length ? prevState.update.filter(code=>update_temp.some(n=>n.code === code)) : [],
      }
      return newState
    })
  }, [notifications])


  const handleSelectAllNotifications = (notificationType: NotificationType) => {
    switch (notificationType) {
      case 'urgent':
        /**If all notifications are slected, unselect them instead */
        if (selectedNotifications?.urgent?.length === notificationsSorted.urgent.length) {
          setSelectedNotifications(prevState => {
            const newState = {
              urgent: [],
              warning: prevState.warning,
              update: prevState.update,
            }
            return newState
          })
          break
        }
        setSelectedNotifications(prevState => {
          const newState = {
            urgent: notificationsSorted?.urgent?.map(n => n.code),
            warning: prevState.warning,
            update: prevState.update,
          }
          return newState
        })
        break
      case 'warning':
        /**If all notifications are slected, unselect them instead */
        if (selectedNotifications?.warning?.length === notificationsSorted.warning.length) {
          setSelectedNotifications(prevState => {
            const newState = {
              urgent: prevState.update,
              warning: [],
              update: prevState.update,
            }
            return newState
          })
          break
        }
        setSelectedNotifications(prevState => {
          const newState = {
            urgent: prevState.urgent,
            warning: notificationsSorted?.warning?.map(n => n.code),
            update: prevState.update,
          }
          return newState
        })
        break
      case 'update':
        /**If all notifications are slected, unselect them instead */
        if (selectedNotifications?.update?.length === notificationsSorted.update.length) {
          setSelectedNotifications(prevState => {
            const newState = {
              urgent: prevState.update,
              warning: prevState.update,
              update: [],
            }
            return newState
          })
          break
        }
        setSelectedNotifications(prevState => {
          const newState = {
            urgent: prevState.urgent,
            warning: prevState.warning,
            update: notificationsSorted?.update?.map(n => n.code),
          }
          return newState
        })
        break
      default:
        break
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, notificationType: NotificationType) => {
    const notificationCode = e.target.name
    const isChecked = e.target.checked
    if (isChecked) {
      setSelectedNotifications(prevState => {
        const newState = {
          ...prevState,
        }
        newState[notificationType].push(notificationCode)
        return newState
      })
    } else {
      setSelectedNotifications(prevState => {
        const newState = {
          ...prevState,
        }
        newState[notificationType] = newState[notificationType].filter(n => n !== notificationCode)
        return newState
      })
    }
  }

  const handleClickMarkAllSelectedNotificationsAsRead = (notificationType:NotificationType)=> {
    const notifications = notificationsSorted[notificationType]?.filter(no=> selectedNotifications[notificationType]?.includes(no.code))
    doUpdateMultipleNotificationWebReadDate && doUpdateMultipleNotificationWebReadDate(notifications, new Date())
  }

  return (
    <>
      <Button
        variant='contained'
        color='default'
        fullWidth
        classes={{
          label: classes.label,
        }}
        className={classes.buttonAccordion}
        onClick={handleExpandPannelClick.bind(this, 'urgent')}
        endIcon={openPanel.urgent ? <ArrowDropUp fontSize='small' /> : <ArrowDropDown fontSize='small' />}
      >
        {`${t('notificationsCatalog.emergency')} (${notificationsSorted.urgent.length})`}
      </Button>
      {openPanel.urgent && (
        <Box>
          {tab === 'tabNew' && !!notificationsSorted?.urgent?.length && (
            <Box display={'flex'}>
              <ButtonHDR
                size='small'
                variant='outlined'
                color='default'
                title='btn.selectAll'
                onClick={() => handleSelectAllNotifications('urgent')}
              />
              <ButtonHDR
                disabled={!selectedNotifications?.urgent?.length}
                size='small'
                variant='outlined'
                color='default'
                title='notificationsCatalog.btn.readAll'
                onClick={() => handleClickMarkAllSelectedNotificationsAsRead('urgent')}
              />
            </Box>
          )}
          {/* Content 1- */}
          <ul className={classes.ul}>
            {notificationsSorted.urgent
              .map(n => {
                let locdata: notificationDataType = getLocalizedNotificationContent(n, locale)
                return (
                  <li className={classes.customLi} key={n.code}>
                    <div className={classes.listDotContainer}>
                      {tab === 'tabNew' && (
                        <CheckBoxNt
                          name={n.code}
                          checked={selectedNotifications.urgent.includes(n.code)}
                          onChange={e => handleCheckboxChange(e, 'urgent')}
                          inputProps={{ 'aria-label': 'notification' }}
                        />
                      )}
                      {tab !== 'tabNew' && <div className={classes.liDotRead}></div>}
                    </div>
                    <Box className={classes.notificationTextContainer} onClick={e => handleOpenAlarmPopUp(n, null)}>
                      <p
                        className={classes.typography}
                        dangerouslySetInnerHTML={createMarkup(locdata.title, locdata.content, trimText, n)}
                      />
                    </Box>
                  </li>
                )
              })}
          </ul>
        </Box>
      )}
      <Button
        variant='contained'
        color='default'
        fullWidth
        classes={{
          label: classes.label,
        }}
        className={classes.buttonAccordion}
        onClick={handleExpandPannelClick.bind(this, 'warning')}
        endIcon={openPanel.warning ? <ArrowDropUp fontSize='small' /> : <ArrowDropDown fontSize='small' />}
      >
        {`${t('notificationsCatalog.warning')} (${notificationsSorted.warning.length})`}
      </Button>
      {openPanel.warning && (
        <Box>
          {tab === 'tabNew' && !!notificationsSorted?.warning?.length && (
            <Box display={'flex'}>
              <ButtonHDR
                size='small'
                variant='outlined'
                color='default'
                title='btn.selectAll'
                onClick={() => handleSelectAllNotifications('warning')}
              />
              <ButtonHDR
                disabled={!selectedNotifications?.warning?.length}
                size='small'
                variant='outlined'
                color='default'
                title='notificationsCatalog.btn.readAll'
                onClick={() => handleClickMarkAllSelectedNotificationsAsRead('warning')}
              />
            </Box>
          )}
          {/* Content 2- */}
          <ul className={classes.ul}>
            {notificationsSorted.warning
              .map(n => {
                let locdata: notificationDataType = getLocalizedNotificationContent(n, locale)
                return (
                  <li className={classes.customLi} key={n.code}>
                    <div className={classes.listDotContainer}>
                      {tab === 'tabNew' && (
                        <CheckBoxNt
                          name={n.code}
                          checked={selectedNotifications.warning.includes(n.code)}
                          onChange={e => handleCheckboxChange(e, 'warning')}
                          inputProps={{ 'aria-label': 'notification' }}
                        />
                      )}
                      {tab !== 'tabNew' && <div className={classes.liDotRead}></div>}
                    </div>
                    <Box className={classes.notificationTextContainer} onClick={e => handleOpenAlarmPopUp(n, null)}>
                      <p
                        className={classes.typography}
                        dangerouslySetInnerHTML={createMarkup(locdata.title, locdata.content, trimText, n)}
                      />
                    </Box>
                  </li>
                )
              })}
          </ul>
        </Box>
      )}
      <Button
        variant='contained'
        color='default'
        fullWidth
        classes={{
          label: classes.label,
        }}
        className={classes.buttonAccordion}
        onClick={handleExpandPannelClick.bind(this, 'update')}
        endIcon={openPanel.update ? <ArrowDropUp fontSize='small' /> : <ArrowDropDown fontSize='small' />}
      >
        {`${t('notificationsCatalog.briefing')} (${notificationsSorted.update.length})`}
      </Button>
      {openPanel.update && (
        <Box>
          {tab === 'tabNew' && !!notificationsSorted?.update?.length && (
            <Box display={'flex'} >
              <ButtonHDR
                size='small'
                variant='outlined'
                color='default'
                title='btn.selectAll'
                onClick={() => handleSelectAllNotifications('update')}
              />
              <ButtonHDR
                disabled={!selectedNotifications?.update?.length}
                size='small'
                variant='outlined'
                color='default'
                title='notificationsCatalog.btn.readAll'
                onClick={() => handleClickMarkAllSelectedNotificationsAsRead('update')}
              />
            </Box>
          )}
          {/* Content 3- */}
          <ul className={classes.ul}>
            {notificationsSorted.update
              .map(n => {
                let locdata: notificationDataType = getLocalizedNotificationContent(n, locale)
                return (
                  <li className={classes.customLi} key={n.code}>
                    <div className={classes.listDotContainer}>
                      {tab === 'tabNew' && (
                        <CheckBoxNt
                          name={n.code}
                          checked={selectedNotifications.update.includes(n.code)}
                          onChange={e => handleCheckboxChange(e, 'update')}
                          inputProps={{ 'aria-label': 'notification' }}
                        />
                      )}
                      {tab !== 'tabNew' && <div className={classes.liDotRead}></div>}
                    </div>
                    <Box className={classes.notificationTextContainer} onClick={e => handleOpenAlarmPopUp(n, null)}>
                      <p
                        className={classes.typography}
                        dangerouslySetInnerHTML={createMarkup(locdata.title, locdata.content, trimText, n)}
                      />
                    </Box>
                  </li>
                )
              })}
          </ul>
        </Box>
      )}
    </>
  )
}
