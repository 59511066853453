import React, { ReactElement } from "react";
import { makeStyles, Theme, createStyles, Button, CircularProgress } from "@material-ui/core/";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contained: {
      textTransform: "none",
      minWidth: 100,
      fontSize: "0.875rem",
      margin: "0.25rem",
      maxHeight: "40px",
      color: theme.palette.common.white,
    },
    outlined: {
      textTransform: "none",
      minWidth: 100,
      fontSize: "0.875rem",
      margin: "0.25rem",
      maxHeight: "40px",
      //color hotfix (theme changed)
      // borderRadius: '8px', //border is different in our new design system untill it is implement it leave it be
      borderColor: theme.palette.custom.grey2,
      color: theme.palette.custom.grey2,
      '&:hover': {
        borderColor: theme.palette.custom.grey2,
        backgroundColor: theme.palette.custom.grey4,
        boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
      },
    },
    text: {
      textTransform: "none",
      minWidth: 100,
      fontSize: "0.875rem",
      margin: "0.25rem",
      maxHeight: "40px",
      fontWeight: "bold",
    },
    disabled: {
      backgroundColor: `${theme.palette.primary.main}!important`,
    },
    spinner: {
      color: "#00004a",
      width: "1.5rem",
      height: "1.5rem",
      maxWidth: "1.5rem",
      maxHeight: "1.5rem",
      marginRight: "0.5rem",
    },
  })
);

interface Props {
  /** You can pass the translation string directly, since the title is passed into a t(title) internally in ButtonHDR  */
  title: string;
  onClick: (...args: any) => void;
  disabled?: boolean;
  style?: any; //*inlineStyle object eg {backgroundColor:'red'}
  variant?: "contained" | "outlined" | "text";
  color?: "default" | "inherit" | "primary" | "secondary";
  startIcon?: ReactElement | null;
  endIcon?: ReactElement | null;
  /**If true will render a spinner inside the button (similar to startIcon) */
  loading?: boolean;
  size?: "small" | "medium" | "large";
}

const ButtonHDR: React.FC<Props> = ({
  title,
  onClick,
  disabled = false,
  style = {},
  variant = "contained",
  color = "primary",
  startIcon = null,
  endIcon = null,
  loading = false,
  size = "medium",
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant={variant}
        color={color}
        className={classes[variant]}
        classes={variant === "contained" ? { disabled: classes.disabled } : {}}
        onClick={onClick}
        disabled={disabled}
        style={style}
        startIcon={startIcon}
        endIcon={endIcon}
        size={size}
      >
        {loading && <CircularProgress className={classes.spinner} />}
        {t(title)}
      </Button>
    </>
  );
};

export default ButtonHDR;
