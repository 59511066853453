function markCookies(): void {
  let allCookies = document.cookie.split(';').map(c => c.trim());
  let markerCookies = allCookies.filter(p => p.startsWith('marker_'));
  let appCookies = allCookies.filter(p => !p.startsWith('marker_'));
  appCookies.forEach(a => {
    let key = a.split('=')[0];
    let markerKey = `marker_${key}`;
    if (!markerCookies.find(m => markerKey === m.split('=')[0])) {
      document.cookie = `marker_${key}=${Date.now()}`;
    }
  })
}

function clearOrphanMarkers(): void {
  let allCookies = document.cookie.split(';').map(c => c.trim());
  let markerCookies = allCookies.filter(p => p.startsWith('marker_'))
  let appCookies = allCookies.filter(p => !p.startsWith('marker_'))
  markerCookies.forEach(m => {
    let markerKey = m.split('=')[0];
    let key = markerKey.substring('marker_'.length);
    if (!appCookies.find(a => a.split('=')[0] === key)) {
      document.cookie = `${markerKey}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  })
}

function removeOldCookies(): void {
  let maxSize = 6;
  let allCookies = document.cookie.split(';').map(c => c.trim());
  let markerCookies = allCookies.filter(p => p.startsWith('marker_'));
  markerCookies.sort((a: any, b: any) => {
    return Number.parseInt(b.split('=')[1]) - Number.parseInt(a.split('=')[1]);
  }).forEach((m,i) => {
    if (i >= maxSize) {
      let markerKey = m.split('=')[0];
      let key = markerKey.substring('marker_'.length);
      document.cookie = `${markerKey}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  })
}

export default function cleanCookies(): void {
  clearOrphanMarkers();
  markCookies();
  removeOldCookies();
}
