import React, { useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from '@material-ui/pickers'

import { alphabeticalSorter } from '../../utils/helpers'

const AUTOCOMPLETE_OPTIONS_MAX_SIZE: number = 2000
const useStyles = makeStyles(theme => ({
  li: {
    padding: theme.spacing(0.5),
  },
  liRowsLimit: {
    padding: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderTop: `thin solid ${theme.palette.tertiary.light}`,
  },
  lilab: {
    minWidth: 170,
    maxWidth: 170,
  },
  lival: {
    minWidth: 300,
    maxWidth: 300,
  },
  livaldate: {
    minWidth: 145,
    maxWidth: 145,
  },
  livalcbgrp: {
    minWidth: 300,
    maxWidth: 300,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    padding: 8,
  },
  livalcb: {
    minWidth: 250,
    maxWidth: 250,
    margin: 0,
  },
  livalcblab: {
    fontSize: 14,
  },
  livalcbchk: {
    padding: 2,
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  inputdate: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  inputdateKeyboard: {
    padding: '0px !important',
  },
  autoInputRoot: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
  },
  autoInput: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    fontSize: 14,
  },
  autoPopper: {
    whiteSpace: 'pre-wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  title: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.tertiary.dark,
  },
  content: {
    borderTop: `thin solid ${theme.palette.tertiary.light}`,
    borderBottom: `thin solid ${theme.palette.tertiary.light}`,
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.tertiary.main,
  },
  clearBtn: {
    marginRight: 'auto',
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.tertiary.main,
  },
}))

const MdFilterDialog = (props) => {

  const { t } = useTranslation()
  const classes = useStyles()

  const [filter, setFilter] = useState<any | null>(props.itemFilter)
  const handleFilterFieldChange = (event, field, met?, cbval?) => {
    let value = event.target.value
    // console.log(`event.target.value H${event.target.value}H`)
    // console.log(`event.target.checked ${event.target.checked}`)
    let clone = { ...filter }
    if (!!met) {
      if (met.multiselect && !met.isAutocomplete) {
        clone[field] = [...filter[field]]
        if (event.target.checked) {
          clone[field].push(cbval)
        } else {
          let x = clone[field].findIndex(v => v === cbval)
          clone[field].splice(x, 1)
        }
        // let x = clone[field].findIndex(v => v === cbval)
        // if (x > -1) {
        //   clone[field].splice(x, 1)
        // } else {
        //   clone[field].push(value)
        // }
      } else if (met.multiselect && met.isAutocomplete) {
        // MULTISELECT + AUTOCOMPLETE
        if (!!value) {
          const ids = value.map(el=>el.code)
          clone[field]= [...ids]
        }
      } else if (met.type === 'boolean') {
        // value = event.target.checked
        clone[field] = value
      } else if (met.type === 'date') {
        if (field.endsWith('_from')) {
          clone[field] = value.startOf('day')
        } else if (field.endsWith('_to')) {
          clone[field] = value.endOf('day')
        } else {
          clone[field] = value
        }
      } else if (met.isAutocomplete) {
        clone[field] = !!value ? value.code : ''
        if (!!met.featureTypeField) {
          if (!value) {
            // clone[met.featureTypeField] = ''
          } else {
            clone[met.featureTypeField] = value.type
          }
        }
      } else if (!!met.featureTypeField) {
        if (value === '') {
          // clone[met.featureTypeField] = ''
          clone[field] = ''
        } else {
          let parts = value.split('___')
          clone[met.featureTypeField] = parts[0]
          clone[field] = parts[1]
        }
      } else if (met.isFeatureTypeField) {
        if (value === '') {
          // clone[met.featureTypeField] = ''
          clone[field] = ''
        } else {
          console.log(`value ${value} clone[field] ${clone[field]}`)
          if (clone[field] !== value) {
            console.log(`!== value ${value} clone[field] ${clone[field]}`)
            let featureFieldMeta = props.meta.columns.find(m => m.featureTypeField === met.code)
            if (!!featureFieldMeta) {
              clone[featureFieldMeta.code] = ''
            }
            clone[field] = value
          } else {
            console.log(`else value ${value} clone[field] ${clone[field]}`)
          }
        }
      } else {
        clone[field] = value
      }
    } else {
      clone[field] = value
    }
    // console.log(`value H${value}H`)
    // console.log(`clone ${JSON.stringify(clone,null,2)}`)
    setFilter(clone)
  }
  const handleClearFilterClick = (event) => {
    // props.prepareFilter()
    let cleanFilter = {}
    props.meta.columns.forEach(m => {
      if (m.multiselect) {
        cleanFilter[m.code] = []
      } else if (m.type === 'date' || m.type === 'datetime') {
        cleanFilter[`${m.code}_from`] = ''
        cleanFilter[`${m.code}_to`] = ''
      // } else if (m.type === 'boolean') {
      //   filter[m.code] = false
      } else {
        cleanFilter[m.code] = ''
      }
    })
    if (props.meta.rowsLimit || props.meta.rowsLimit === 0) {
      cleanFilter['rowsLimit'] = props.meta.rowsLimit
    }
    setFilter(cleanFilter)
  }

  const [autoInputTexts, setAutoInputTexts] = useState({})
  const [autoInputOptions, setAutoInputOptions] = useState({})
  const handleAutocompleteInputChange = (event, value, reason, colmeta) => {
    // console.log(`onInputChange value ${value} reason ${reason}`)
    // if (reason === 'input') {
      let o = {...autoInputTexts}
      o[colmeta.code] = value
      setAutoInputTexts(o)
    // }
  }
  const buildAutoInputOptions = (colmeta) => {
    let options: any[] = []
    // let noOptionsText = 'No options'
    // let autoFilter = !!autoInputTexts[colmeta.code] ? autoInputTexts[colmeta.code] : ''
    colmeta.lookup.forEach(lookup => {
      if (!!props.lookups[lookup]) {
        let lookupField = colmeta.lookupField || 'code'
        let lookupFetch = colmeta.lookupFetch || ['label']
        if (lookupFetch.length > 0) {
          let lookoptions: any[] = []
          props.lookups[lookup].forEach(look => {
            let mkey = props.getLookupFld(look, lookupField)
            let lab = ''
            if (lookupFetch.length === 1) {
              lab = props.getLookupFld(look, lookupFetch[0])
            } else if (!colmeta.lookupTemplate) {
              lab = lookupFetch.map(f => props.getLookupFld(look, f)).join(' ')
            } else {
              lab = colmeta.lookupTemplate
              lookupFetch.forEach((f, i) => {
                lab = lab.replace(`f${i+1}`, props.getLookupFld(look, f))
              })
            }
            let o: any = {
              code: mkey,
              label: lab,
            };
            if (!!colmeta.featureTypeField) {
              let ft = look['properties']['featureType']
              o.type = ft
            }
            if (!!colmeta.lookupGroupByField) {
              o.type = look[colmeta.lookupGroupByField]
            }
            lookoptions.push(o)
          })
          lookoptions.sort((a, b) => alphabeticalSorter(a.label, b.label))
          options = [...options, ...lookoptions]
        }
      }
    })
    // console.log(`${colmeta.code} ${colmeta.label} options.length ${options.length}`)
    let o = {...autoInputOptions}
    o[colmeta.code] = options
    setAutoInputOptions(o)
    return options
  }
  // const comparator = (a, b) => {
  //   // console.log(`descendingComparator a ${a[orderBy]} b ${b[orderBy]} orderBy ${orderBy}`)
  //   let aval = a.toLowerCase()
  //   let bval = b.toLowerCase()
  //   let result
  //   if (!aval) {
  //     result = -1
  //   } else if (!bval) {
  //     result = 1
  //   } else if (bval < aval) {
  //     result = 1
  //   } else if (bval > aval) {
  //     result = -1
  //   } else {
  //     result = 0
  //   }
  //   // console.log(`comparator \t${aval}\t${result === 1 ? '<' : (result === -1 ? '>' : '=')}\t ${bval}`)
  //   return result
  // }

  // console.log(`filter ${JSON.stringify(filter,null,2)}`)
  // console.log(`props.editingDataItem ${JSON.stringify(props.editingDataItem,null,2)}`)
  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => (reason === 'backdropClick') ? null : props.handleCloseFilterDialog('no')}
    >
      <DialogTitle
        disableTypography={true}
        className={classes.title}
      >
        {t('md.filters.title')}
      </DialogTitle>
      <DialogContent
        className={classes.content}
      >
        <List>
          {!!props.itemFilter && props.meta.columns.filter(colmeta => colmeta.filterable).map(colmeta => {

            let compo
            let colvalue = !!filter ? filter[colmeta.code] : ''
            let groupBy: any

            if (!filter) {
              compo = null
            // } else if (!!colmeta.lookup && colmeta.multiselect && colmeta.isAutocomplete) {
              // Multiple + AutoComplete
            } else if (!!colmeta.lookup && colmeta.isAutocomplete) {
              let oval: any = colmeta.multiselect ? [] : null
              let alloptions: any[] = []
              let options: any[] = []
              if (!autoInputOptions[colmeta.code]) {
                alloptions = buildAutoInputOptions(colmeta)
                return compo
              } else {
                alloptions = autoInputOptions[colmeta.code]
              }
              if (!!colmeta.featureTypeField) {
                let ftvalue = !!filter ? filter[colmeta.featureTypeField] : ''
                if (!!ftvalue) {
                  alloptions = alloptions.filter(o => o.type === ftvalue)
                }
                groupBy = (option) => t(`net.${option.type}.plural`)
              }
              if (!!colmeta.lookupGroupByField) {
                groupBy = (option) => t(option[colmeta.lookupGroupByField])
              }
              // let noOptionsText = 'No options'
              let useOptionsFiltering = alloptions.length > AUTOCOMPLETE_OPTIONS_MAX_SIZE
              let autoFilter = !!autoInputTexts[colmeta.code] ? autoInputTexts[colmeta.code].toLowerCase() : ''
              alloptions.forEach(o => {
                if (colmeta.multiselect) {
                  if (colvalue.includes(o.code)) {
                    oval.push(o)
                  }
                } else {
                  if (o.code === colvalue) {
                    oval = {...o}
                  }
                }
                if (useOptionsFiltering) {
                  if (autoFilter === '' || o.label.toLowerCase().indexOf(autoFilter) > -1) {
                    options.push(o)
                  }
                }
              })
              if (useOptionsFiltering) {
                console.log('if useOptionsFiltering')
                if (options.length > AUTOCOMPLETE_OPTIONS_MAX_SIZE) {
                  options.splice(AUTOCOMPLETE_OPTIONS_MAX_SIZE)
                  options.splice(0, 0, {code: '', label: `${t('md.autocomplete.tooManyOptions')} ${autoFilter}`, type: 'tooManyOptionsGroup'})
                }
                if (!!oval && options.findIndex(o => o.code === oval.code && (!colmeta.featureTypeField || o.type === oval.type)) === -1) {
                  options.splice(1, 0, oval)
                }
              } else {
                options = alloptions
              }
              compo = (
                <Autocomplete
                  multiple={colmeta.multiselect}
                  className={classes.lival}
                  classes={{inputRoot: classes.autoInputRoot, input: classes.autoInput, popper: classes.autoPopper}}
                  clearOnEscape
                  options={options}
                  noOptionsText={t('md.autocomplete.noOptions')}
                  groupBy={groupBy}
                  getOptionSelected={(o, v) => o.code === v.code}
                  value={oval}
                  onChange={(event, newVal) => handleFilterFieldChange({target: {value: newVal}}, colmeta.code, colmeta)}
                  onInputChange={!useOptionsFiltering ? undefined : (event, value, reason) => handleAutocompleteInputChange(event, value, reason, colmeta)}
                  getOptionLabel={(option:any) => option.label}
                  renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                />
              )
            // } else if (!!colmeta.lookup && !colmeta.multiselect && colmeta.isAutocomplete) {
            //   let oval: any = null
            //   let alloptions: any[] = []
            //   let options: any[] = []
            //   if (!autoInputOptions[colmeta.code]) {
            //     alloptions = buildAutoInputOptions(colmeta)
            //     return compo
            //   } else {
            //     alloptions = autoInputOptions[colmeta.code]
            //   }
            //   if (!!colmeta.featureTypeField) {
            //     let ftvalue = !!filter ? filter[colmeta.featureTypeField] : ''
            //     if (!!ftvalue) {
            //       alloptions = alloptions.filter(o => o.type === ftvalue)
            //     }
            //   }
            //
            //   // let noOptionsText = 'No options'
            //   let useOptionsFiltering = alloptions.length > AUTOCOMPLETE_OPTIONS_MAX_SIZE
            //   let autoFilter = !!autoInputTexts[colmeta.code] ? autoInputTexts[colmeta.code].toLowerCase() : ''
            //   alloptions.forEach(o => {
            //     if (o.code === colvalue) {
            //       oval = {...o}
            //     }
            //     if (useOptionsFiltering) {
            //       if (autoFilter === '' || o.label.toLowerCase().indexOf(autoFilter) > -1) {
            //         options.push(o)
            //       }
            //     }
            //   })
            //   if (useOptionsFiltering) {
            //     if (options.length > AUTOCOMPLETE_OPTIONS_MAX_SIZE) {
            //       options.splice(AUTOCOMPLETE_OPTIONS_MAX_SIZE)
            //       options.splice(0, 0, {code: '', label: `${t('md.autocomplete.tooManyOptions')} ${autoFilter}`, type: 'tooManyOptionsGroup'})
            //     }
            //     if (!!oval && options.findIndex(o => o.code === oval.code && (!colmeta.featureTypeField || o.type === oval.type)) === -1) {
            //       options.splice(1, 0, oval)
            //     }
            //   } else {
            //     options = alloptions
            //   }
            //   // console.log(`options.length ${options.length}`)
            //   // console.log(`oval ${JSON.stringify(oval)}`)
            //   compo = (
            //     <Autocomplete
            //       className={classes.lival}
            //       classes={{inputRoot: classes.autoInputRoot, input: classes.autoInput, popper: classes.autoPopper}}
            //       clearOnEscape
            //       options={options}
            //       noOptionsText={t('md.autocomplete.noOptions')}
            //       groupBy={!colmeta.featureTypeField ? undefined : (option) => t(`net.${option.type}.plural`)}
            //       getOptionSelected={(o, v) => o.code === v.code}
            //       value={oval}
            //       onChange={(event, newVal) => handleFilterFieldChange({target: {value: newVal}}, colmeta.code, colmeta)}
            //       onInputChange={!useOptionsFiltering ? undefined : (event, value, reason) => handleAutocompleteInputChange(event, value, reason, colmeta)}
            //       getOptionLabel={(option:any) => option.label}
            //       renderInput={(params) => (<TextField {...params} variant="outlined" />)}
            //     />
            //   )
            } else if (!!colmeta.lookup && !colmeta.multiselect && !colmeta.isAutocomplete) {
              let sval = colvalue
              if (!!colmeta.featureTypeField && !!colvalue) {
                // console.log(`filter ${JSON.stringify(filter,null,2)}`)
                let sft = filter[colmeta.featureTypeField]
                sval = `${sft}___${sval}`
              }
              let mitems:any[] = [
                <MenuItem key='none' value=''>
                  <em>{t('none-neutral')}</em>
                </MenuItem>
              ]
              let mitemsMap:any[] = []
              // console.log(`sval ${JSON.stringify(sval,null,2)}`)
              colmeta.lookup.forEach(lookup => {
                if (!!props.lookups[lookup]) {
                  let lookupField = colmeta.lookupField || 'code'
                  let lookupFetch = colmeta.lookupFetch || ['label']
                  if (lookupFetch.length > 0) {
                    props.lookups[lookup].forEach(look => {
                      let mkey = props.getLookupFld(look, lookupField)
                      let mval = mkey
                      if (!!colmeta.featureTypeField) {
                        let ft = look['properties']['featureType']
                        mval = `${ft}___${mkey}`
                        // console.log(`mval ${JSON.stringify(mval,null,2)} mval === sval ${mval === sval}`)
                      }
                      // console.log(`mkey ${mkey} mkey === colvalue ${mkey === colvalue}`)
                      let lab = ''
                      if (lookupFetch.length === 1) {
                        lab = props.getLookupFld(look, lookupFetch[0])
                      } else if (!colmeta.lookupTemplate) {
                        lab = lookupFetch.map(f => props.getLookupFld(look, f)).join(' ')
                      } else {
                        lab = colmeta.lookupTemplate
                        lookupFetch.forEach((f, i) => {
                          lab = lab.replace(`f${i+1}`, props.getLookupFld(look, f))
                        })
                      }
                      mitemsMap.push({
                        key: lab,
                        item: (<MenuItem key={mkey} value={mval}>{lab}</MenuItem>)
                      })
                    })
                  }
                }
              })
              mitemsMap.sort((a, b) => alphabeticalSorter(a.key, b.key))
              mitems = mitems.concat(mitemsMap.map(m => m.item))
              compo = (
                <FormControl className={classes.lival}>
                  <Select
                    variant="outlined"
                    displayEmpty
                    classes={{root: classes.input,}}
                    value={sval}
                    // onChange={props.onChange}
                    onChange={event => handleFilterFieldChange(event, colmeta.code, colmeta)}
                  >
                    {mitems}
                  </Select>
                </FormControl>
              )
            } else if (!!colmeta.lookup && colmeta.multiselect) {
              let sval = !!colvalue ? colvalue : []
              let checkboxes:any[] = []
              let checkboxesMap:any[] = []
              // console.log(`sval ${JSON.stringify(sval,null,2)}`)
              colmeta.lookup.forEach(lookup => {
                if (!!props.lookups[lookup]) {
                  let lookupField = colmeta.lookupField || 'code'
                  let lookupFetch = colmeta.lookupFetch || ['label']
                  if (lookupFetch.length > 0) {
                    props.lookups[lookup].forEach(look => {
                      let mkey = props.getLookupFld(look, lookupField)
                      let mval = mkey
                      let lab = ''
                      if (lookupFetch.length === 1) {
                        lab = props.getLookupFld(look, lookupFetch[0])
                      } else if (!colmeta.lookupTemplate) {
                        lab = lookupFetch.map(f => props.getLookupFld(look, f)).join(' ')
                      } else {
                        lab = colmeta.lookupTemplate
                        lookupFetch.forEach((f, i) => {
                          lab = lab.replace(`f${i+1}`, props.getLookupFld(look, f))
                        })
                      }
                      checkboxesMap.push({
                        key: lab,
                        item: (
                          <FormControlLabel
                            key={mkey}
                            className={classes.livalcb}
                            classes={{label: classes.livalcblab,}}
                            control={
                              <CheckBoxNt
                                classes={{root: classes.livalcbchk,}}
                                checked={sval.includes(mval)}
                                onChange={event => handleFilterFieldChange(event, colmeta.code, colmeta, mval)}
                              />
                            }
                            label={lab}
                          />
                        )
                      })
                    })
                  }
                }
              })
              checkboxesMap.sort((a, b) => alphabeticalSorter(a.key, b.key))
              checkboxes = checkboxesMap.map(m => m.item)
              compo = (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  flexWrap='wrap'
                  className={classes.livalcbgrp}
                >
                  {checkboxes}
                </Box>
              )
            } else if (['date', 'datetime', 'string', 'number', 'posnumber'].includes(colmeta.type)) {
              if (colmeta.type === 'date') {
                let val_from = filter[`${colmeta.code}_from`]
                let val_to = filter[`${colmeta.code}_to`]
                // val_from = !!val_from ? moment(val_from).format(t('date.format.pickers') as string) : null
                // val_to = !!val_to ? moment(val_to).format(t('date.format.pickers') as string) : null
                val_from = !!val_from ? moment(val_from) : null
                val_to = !!val_to ? moment(val_to) : null
                compo = (
                  <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.lival}>
                  <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DatePicker
                      autoOk
                      // disableToolbar
                      variant='inline'
                      inputVariant='outlined'
                      className={classes.livaldate}
                      inputProps={{
                        className: classes.inputdate,
                      }}
                      // KeyboardButtonProps={{
                      //   className: classes.inputdateKeyboard,
                      // }}
                      emptyLabel={t('date.format.emptyLabel') as string}
                      format={t('date.format') as string}
                      value={val_from}
                      onChange={date => handleFilterFieldChange({target: {value: date}}, `${colmeta.code}_from`, colmeta)}
                    />
                    <DatePicker
                      autoOk
                      // disableToolbar
                      variant='inline'
                      inputVariant='outlined'
                      className={classes.livaldate}
                      inputProps={{
                        className: classes.inputdate,
                      }}
                      // KeyboardButtonProps={{
                      //   className: classes.inputdateKeyboard,
                      // }}
                      emptyLabel={t('date.format.emptyLabel') as string}
                      format={t('date.format') as string}
                      value={val_to}
                      onChange={date => handleFilterFieldChange({target: {value: date}}, `${colmeta.code}_to`, colmeta)}
                    />
                  </MuiPickersUtilsProvider>
                  </Box>
                )
              } else if (colmeta.type === 'datetime') {
                let val_from = filter[`${colmeta.code}_from`]
                let val_to = filter[`${colmeta.code}_to`]
                // val_from = !!val_from ? moment(val_from).format(t('datetime.format.pickers') as string) : null
                // val_to = !!val_to ? moment(val_to).format(t('datetime.format.pickers') as string) : null
                val_from = !!val_from ? moment(val_from) : null
                val_to = !!val_to ? moment(val_to) : null
                compo = (
                  <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.lival}>
                  <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DateTimePicker
                      autoOk
                      // disableToolbar
                      hideTabs={true}
                      ampm={false}
                      variant='inline'
                      inputVariant='outlined'
                      className={classes.livaldate}
                      inputProps={{
                        className: classes.inputdate,
                      }}
                      // KeyboardButtonProps={{
                      //   className: classes.inputdateKeyboard,
                      // }}
                      emptyLabel={t('datetime.format.emptyLabel') as string}
                      format={t('datetime.format') as string}
                      value={val_from}
                      onChange={date => handleFilterFieldChange({target: {value: date}}, `${colmeta.code}_from`)}
                    />
                    <DateTimePicker
                      autoOk
                      // disableToolbar
                      hideTabs={true}
                      ampm={false}
                      variant='inline'
                      inputVariant='outlined'
                      className={classes.livaldate}
                      inputProps={{
                        className: classes.inputdate,
                      }}
                      // KeyboardButtonProps={{
                      //   className: classes.inputdateKeyboard,
                      // }}
                      emptyLabel={t('datetime.format.emptyLabel') as string}
                      format={t('datetime.format') as string}
                      value={val_to}
                      onChange={date => handleFilterFieldChange({target: {value: date}}, `${colmeta.code}_to`)}
                    />
                  </MuiPickersUtilsProvider>
                  </Box>
                )
              } else {
                let val = colvalue
                compo = (
                  <TextField
                    variant='outlined'
                    type={colmeta.type === 'posnumber' ? 'number' : colmeta.type}
                    // required={true}
                    className={classes.lival}
                    inputProps={{
                      className: classes.input,
                    }}
                    value={val}
                    onChange={event => handleFilterFieldChange(event, colmeta.code)}
                  />
                )
              }
            }
            // return compo

            return (
            <ListItem key={colmeta.code} className={classes.li}>
              <Box className={classes.lilab}>
                {t(colmeta.label)}
              </Box>
              {compo}
            </ListItem>
          )})}
          {(props.meta.rowsLimit || props.meta.rowsLimit === 0) && (
            <ListItem key={'rowsLimit'} className={classes.liRowsLimit}>
              <Box className={classes.lilab}>
                {t('md.filters.rowsLimit')}
              </Box>
              <TextField
                variant='outlined'
                type='number'
                // required={true}
                className={classes.lival}
                inputProps={{
                  className: classes.input,
                  step: '1',
                  min: '10',
                  // max: '1000000',
                }}
                value={!!filter ? filter['rowsLimit'] : props.meta.rowsLimit}
                onChange={event => handleFilterFieldChange(event, 'rowsLimit')}
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          className={classes.clearBtn}
          onClick={handleClearFilterClick}
        >
          {t('btn.clear')}
        </Button>
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          onClick={() => props.handleCloseFilterDialog('no')}
        >
          {t('btn.cancel')}
        </Button>
        <Button
          className={classes.applyBtn}
          onClick={() => props.handleCloseFilterDialog('yes', filter)}
        >
          {t('btn.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default MdFilterDialog
