import { SvgIcon } from '@material-ui/core'

const BackIcon = props => {
  return (
    <SvgIcon
      style={props.style}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_997_502)'>
        <path
          d='M10 -4.68893e-06C8.02219 -4.86183e-06 6.08879 0.586486 4.4443 1.6853C2.79981 2.78411 1.51808 4.3459 0.761205 6.17316C0.00432882 8.00042 -0.193706 10.0111 0.192147 11.9509C0.578 13.8907 1.53041 15.6725 2.92893 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.80429 17.0711 2.92893C15.1957 1.05356 12.6522 -4.45707e-06 10 -4.68893e-06ZM11.289 15.7L5.589 9.93399L11.3 4.28899L12.7 5.711L8.411 9.95299L12.711 14.3L11.289 15.7Z'
          fill={props.fill ? props.fill : 'white'}
        />
      </g>
      <defs>
        <clipPath id='clip0_997_502'>
          <rect width='20' height='20' fill={props.fill ? props.fill : 'white'} transform='translate(20 20) rotate(-180)' />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default BackIcon
