import React from 'react'
import { makeStyles, createStyles, Theme, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import TextNt from '../reusableComponents/TextNt'
import ButtonOutlined from '../reusableComponents/BtnOutlined'
import ButtonContained from '../reusableComponents/BtnContained'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContentRoot: {
      padding: '8px 16px',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
  })
)

interface ConfirmationDialogMobileProps {
  dialogOpen: boolean
  title: string
  handleButtonNo: () => void
  handleButtonYes: () => void
  btnNoText?: string
  btnYesText?: string
}

/**Returns a simple confirmation `<Dialog>` with a title and two `Yes` `No` Buttons */
export default function ConfirmationDialogMobile({
  dialogOpen,
  title,
  handleButtonNo,
  handleButtonYes,
  btnNoText = 'btn.no',
  btnYesText = 'btn.yes',
}: ConfirmationDialogMobileProps) {
  const classes = useStyles()

  return (
    <Dialog open={dialogOpen} maxWidth='sm'>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <TextNt align='center' variant='h2'>
          {title}
        </TextNt>
      </DialogContent>
      <DialogActions className={classes.justifyContentCenter}>
        <ButtonOutlined title={btnNoText} onClick={handleButtonNo} />
        <ButtonContained title={btnYesText} onClick={handleButtonYes} />
      </DialogActions>
    </Dialog>
  )
}
