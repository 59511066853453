import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import TemplateService from './TemplateService'

const IrriProgService = () => {

  const apiClient = useCommonStore.getState().apiClient
  const profile = useCommonStore.getState().profile

  const templateService: any = TemplateService('irriProg')

  const path = 'irriProg/'

  const saveIrriProgSlot = (entity: any, slot: any) => {
    let isNewSlot = !slot._id
    if (isNewSlot) {
      slot.addedBy = profile?.user_id
    } else {
      slot.modifiedBy = profile?.user_id
    }
    let data: any = {
      irriProg_id: entity._id,
      slot: slot,
    }
    if (isNewSlot) {
      data.operation = 'addIrriProgSlot'
    } else {
      data.operation = 'updateIrriProgSlot'
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('irriProgs', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }
  const deleteIrriProgSlot = (entity: any, slot: any) => {
    let data: any = {
      operation: 'deleteIrriProgSlot',
      irriProg_id: entity._id,
      slot: slot,
    }
    return sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem('irriProgs', entity)
        return entity
      },
      err => {
        return templateService.errorHandler(err)
      }
    )
  }

  const sendRequest = (data: any) => {
    // console.log(`IrriProgService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
    saveIrriProgSlot: saveIrriProgSlot,
    deleteIrriProgSlot: deleteIrriProgSlot,
  })

}

export default IrriProgService
