import HttpStatusCode from '../services/HttpStatusCode';
import i18n from '../i18n';
import moment from 'moment'
import {
  FTC,
  IRRIGATION_FREQUENCIES_6,
  IRRIGATION_FREQUENCIES_8,
  IRRIGATION_FREQUENCIES_12,
  IRRIGATION_FREQUENCIES_24,
} from './constants';
import { AgriculturalPeriod } from './types/collectionStoreTypes';

// Genarates a Random Hex color
export const hexColorGenerator = () => {
  function randomHex() {
    var hexNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F']
    // picking a random item of the array
    return "" + hexNumbers[Math.floor(Math.random() * hexNumbers.length)]
  }
  let hexValue:string[] = ['#']
  for (var i = 0; i < 6; i += 1) {
    hexValue.push(randomHex())
  }
  return hexValue.join('')
}

/**Checks if tenant is Gortynia('dmgrty') - 'tnnOne' & 'dmwrm' are here just for testing purposes */
export const checkTenant = (tenants:string[] | undefined)=>{
  if (!tenants || tenants.length===0) return false
  return tenants?.some(tenant => {
    return (
      tenant === 'dmgrty' || tenant === 'dmwrm' || tenant === 'tnnOne'
    )
  })
}

export const alphabeticalSorter = (a: string, b: string, descendingOrder?: boolean) => {
  // console.log(`descendingComparator a ${a[orderBy]} b ${b[orderBy]} orderBy ${orderBy}`)
  let aval = !!a ? a.toLowerCase() : a
  let bval = !!b ? b.toLowerCase() : b
  let result: number
  if (!aval) {
    result = -1
  } else if (!bval) {
    result = 1
  } else if (bval < aval) {
    result = 1
  } else if (bval > aval) {
    result = -1
  } else {
    result = 0
  }
  if (descendingOrder) {
    result = -result
  }
  // console.log(`comparator \t${aval}\t${result === 1 ? '<' : (result === -1 ? '>' : '=')}\t ${bval}`)
  return result
}

export const numericalSorter = (a: number, b: number, descendingOrder?: boolean) => {
  // console.log(`descendingComparator a ${a[orderBy]} b ${b[orderBy]} orderBy ${orderBy}`)
  let aval = a
  let bval = b
  let result: number
  if (!aval) {
    result = -1
  } else if (!bval) {
    result = 1
  } else if (bval < aval) {
    result = 1
  } else if (bval > aval) {
    result = -1
  } else {
    result = 0
  }
  if (descendingOrder) {
    result = -result
  }
  // console.log(`comparator \t${aval}\t${result === 1 ? '<' : (result === -1 ? '>' : '=')}\t ${bval}`)
  return result
}
/**Performs numerical primary sorting on the first two args (a,b) 
 * if a and b are equal then it will perform the secondary sorting according to c and d arguments
 * usefull when sorting timeslots that have startDatetime and endDatetime where if startDatetime is equal 
 * the endDatetime is used for comparisson  */
export const numericalSorterWithSecondary = (a:number, b:number, c:number, d:number, descendingOrder=false) => {
  let result:number = 0
  // First, compare the 'start' dates
  if (!result && a < b) result = -1;
  if (!result && a > b) result = 1;
  // If 'start' dates are equal, compare the 'end' dates
  if (!result && c < d) result = -1;
  if (!result && c > d) result = 1;

  if (descendingOrder) result = -result
  return result
}
/**Performs string `primary` sorting on the first two args (a,b) 
 * if a and b are equal then it will perform the `secondary` sorting according to c and d arguments
 * usefull when sorting by lastName and and first name (in case last names are equal, first name will be used for secondary sorting) 
 */
export const stringSorterWithSecondary = (a:string, b:string, c:string="", d:string="", descendingOrder=false) => {
  let result:number = 0
  if (!result && a < b) result = -1;
  if (!result && a > b) result = 1;
  if (!result && c < d) result = -1;
  if (!result && c > d) result = 1;

  if (descendingOrder) result = -result
  return result
}
export const buildErrorMessage = (error: any, defaultErrorMessage?: null | string, entityKey?: string) => {
  console.error(`buildErrorMessage error`)
  console.log(error)
  let errorMessage = !!defaultErrorMessage
    ? defaultErrorMessage
    : 'An error occured.'
  if (isNetworkError(error)) {
    errorMessage = error.message
  } else if (
    error.response &&
    error.response.status === HttpStatusCode.BAD_REQUEST
  ) {
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message
    ) {
      console.error(`buildErrorMessage error.response.data.error`)
      console.log(error.response.data.error)
      errorMessage = error.response.data.error.message
    } else if (
      error.response.data &&
      error.response.data.error &&
      (error.response.data.error.name === 'MongoError' || error.response.data.error.name === 'MongoServerError')
    ) {
      console.error(`buildErrorMessage ${error.response.data.error.name} error.response.data.error`)
      console.log(error.response.data.error)
      let nestedError = error.response.data.error
      switch (nestedError.code) {
        case 11000:
          // errorMessage = `Duplicate index keys: ["${Object.values(nestedError.keyValue).join('", "')}"]`
          errorMessage = getDuplicateKeyError(nestedError, entityKey)
          break
        default:
          break
      }
    } else if (error.response.data && error.response.data.errorMessage) {
      console.error(`buildErrorMessage error.response.data`)
      console.log(error.response.data)
      errorMessage = error.response.data.errorMessage
    }
  } else if (error.request) {
    // console.error(`ERROR REQUEST IS :${JSON.stringify(error.request, null, 2)}`)
    if (error.request.message) errorMessage = error.request.message
  }
  console.error(`buildErrorMessage errorMessage ${errorMessage}`)
  return errorMessage
}
const isNetworkError = err => {
  return !!err.isAxiosError && !err.response
}
const getDuplicateKeyError = (error: any, entityKey?: string) => {
  let errorMessage = `Duplicate index keys: ["${Object.values(
    error.keyValue
  ).join('", "')}"]`
  let keys = Object.keys(error.keyPattern).filter(
    k => k !== 'tenantCode' && k !== 'properties.tenantCode'
  )
  if (keys.length === 1) {
    let fieldname = getFieldTranslation(keys[0], entityKey)
    errorMessage = `${i18n.t('error.valueOfField')} "${fieldname}" ${i18n.t(
      'error.mustbe.unique'
    )}`
  } else if (keys.length > 1) {
    let fieldnames = keys.map(key => {
      return getFieldTranslation(key, entityKey)
    })
    errorMessage = `${i18n.t('error.valueOfField.plural')} "${fieldnames.join(
      '", "'
    )}" ${i18n.t('error.mustbe.unique.plural')}`
  }
  return errorMessage
}
const getFieldTranslation = (fieldname: string, entityKey?: string) => {
  let trans = fieldname
  switch (fieldname) {
    case 'code':
    case 'properties.code':
      trans = i18n.t('com.code')
      break
    case 'label':
    case 'properties.label':
      trans = i18n.t('com.label')
      break
    case 'name':
      trans =
        entityKey === 'indicators' ? i18n.t('indicator.name') : i18n.t('com.label')
      break
    default:
      if (fieldname.startsWith('properties.')) {
        fieldname = fieldname.substring('properties.'.length)
        let info = Object.values(FTC).find(f => f.collection === entityKey)
        if (info) {
          fieldname = `${info.code}.${fieldname}`
          trans = i18n.t(fieldname)
        }
      }
      break
  }
  return trans
}

/**Takes an html string as input
 * and returns the string without html tags
 * for example  <strong>bla bla<strong/>  is returned as 'bla bla'
 */
export const stripHtmlTags = (html: string) => {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  //  console.log(tmp.textContent || tmp.innerText || "")
  return tmp.textContent || tmp.innerText || ''
}

export type notificationDataType = {title: string, content: string}
export const getLocalizedNotificationContent = (notification: any, locale: string | undefined, suffix?: 'Sms' | 'Ssml'): notificationDataType => {
  if (!locale) {
    locale = 'el'//default
  }
  let data: notificationDataType = {title: '', content: ''}
  //get localized data
  let localized = notification?.localizedData?.find(ld => ld?.locale === locale)
  if (!localized && notification?.localizedData?.length > 0) {//if not found try the first available locale
    localized = notification?.localizedData[0]
  }
  if (localized) {
    if (suffix) {
      data.title = localized[`title${suffix}`]
      data.content = localized[`content${suffix}`]
    }
    // if no suffixed data exist, get the plain data
    if (!data.title) {
      data.title = localized.title
    }
    if (!data.content) {
      data.content = localized.content
    }
  } else {//temporary-to-be-removed method for old notification data-model (no localizedData)
    data.title = notification.title
    data.content = notification.content
  }
  return data
}

/**
* Formats a duration in seconds into a readable format (string)
* returns a string of the following shape hh:mm 
* eg with input 10050 (seconds) the return string will be '02:47' (2 hours and 47 minutes)
* @param seconds - seconds either as a string or a number 
*/
export function formatSecondsToHoursMinuts(seconds: number | string): string {
  seconds = Number(seconds)
  if (!seconds || isNaN(+seconds)) {
    return `00:00`
  }
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.round((seconds % 3600) / 60)
  return (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes)
}
/**
* Formats a duration in seconds into a readable format (number)
* returns a string of the following shape hh.mm 
* eg with input 10050 (seconds) the return string will be '02:47' (2 hours and 47 minutes)
* @param seconds - seconds either as a string or a number 
*/
export function formatSecondsToHoursMinutsAsNumber(seconds: number | string): number {
  seconds = Number(seconds)
  if (!seconds || isNaN(+seconds)) {
    return 0.00
  }
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.round((seconds % 3600) / 60)
  const result = hours + (minutes/100)
  return +result.toFixed(2) || 0.00
}

export function formatSecondsToHoursToFixed(seconds: number | string, rounding=2): number {
  seconds = Number(seconds)
  if (!seconds || isNaN(+seconds)) {
    return 0.00
  }
  const hoursRounded =  (+seconds / 3600).toFixed(rounding)
  return +hoursRounded
}
/**Transforms greek characters to english
 * and also replaces any special characters connected to Greek alphabet word stress (τόνος,διαλυτικά)
 * also ensures that the text is a valid filename for a windows operating system
 * special characters (outside greek alphabet) are replaced with "-"
 * @param str - the string to be transformed
 * @param keepSpecialCharacters - if true will keep special characters
 * @param returnUpperCase - if true the returned string will be upercase
 */
export function greekToEnglish(str: string, keepSpecialCharacters = false, returnUpperCase=false) {
  let s1 = str.toUpperCase()
  s1 = s1.replace(/Α/g, 'A')
  s1 = s1.replace(/Ά/g, 'A')
  s1 = s1.replace(/Β/g, 'B')
  s1 = s1.replace(/Ψ/g, 'C')
  s1 = s1.replace(/Δ/g, 'D')
  s1 = s1.replace(/Ε/g, 'E')
  s1 = s1.replace(/Έ/g, 'E')
  s1 = s1.replace(/Φ/g, 'F')
  s1 = s1.replace(/Γ/g, 'G')
  s1 = s1.replace(/Η/g, 'H')
  s1 = s1.replace(/Ή/g, 'H')
  s1 = s1.replace(/Ι/g, 'I')
  s1 = s1.replace(/Ί/g, 'I')
  s1 = s1.replace(/Ϊ/g, 'I')
  s1 = s1.replace(/Ξ/g, 'J')
  s1 = s1.replace(/Κ/g, 'K')
  s1 = s1.replace(/Λ/g, 'L')
  s1 = s1.replace(/Μ/g, 'M')
  s1 = s1.replace(/Ν/g, 'N')
  s1 = s1.replace(/Ο/g, 'O')
  s1 = s1.replace(/Ό/g, 'O')
  s1 = s1.replace(/Π/g, 'P')
  s1 = s1.replace(/;/g, 'Q')
  s1 = s1.replace(/Ρ/g, 'R')
  s1 = s1.replace(/Σ/g, 'S')
  s1 = s1.replace(/Τ/g, 'T')
  s1 = s1.replace(/Θ/g, 'U')
  s1 = s1.replace(/Ω/g, 'V')
  s1 = s1.replace(/Ώ/g, 'V')
  s1 = s1.replace(/Σ/g, 'S')
  s1 = s1.replace(/Χ/g, 'X')
  s1 = s1.replace(/Υ/g, 'Y')
  s1 = s1.replace(/Ύ/g, 'Y')
  s1 = s1.replace(/Ϋ/g, 'Y')
  s1 = s1.replace(/Ζ/g, 'Z')

  if (!keepSpecialCharacters) {
    s1 = s1.replace(/¨/g, '')
    s1 = s1.replace(/΄/g, '')
    s1 = s1.replace(/'/g, '')
    s1 = s1.replace(/"/g, '')

    // https://stackoverflow.com/questions/54804674/regex-remove-special-characters-in-filename-except-extension
    //replace any char other than a word char and a dot char
    return returnUpperCase ? s1.replace(/([^\w.])+/g, "-") : s1.toLowerCase().replace(/([^\w.])+/g, "-")
  }
  return returnUpperCase ? s1 : s1.toLocaleLowerCase()
}

/**Legacy function, cycleDuration is now stored inside the agricultural period */
// export function getCycleDurationFromTenant(tenant: string) {
//   switch (tenant) {
//     case 'tvfrsl':
//       return '24'
//     case 'tnnOne':
//       return '24'
//     case 'tSchdT':
//       return '24'
//     case 'tvpnlr':
//       return '12'
//     case 'dmwrm':
//       return '12'
//     case 'Trrprs':
//       return '12'
//     default:
//       return '12'
//   }
// }

/** Returns true for tenants (currentyly: tvfrsl,tnnOne,Trrprs) 
 * that have the expanded irrigation methods used in the advanced irrigation schdedule algorithm,
 * used to show/hide advanced irrigation methods based on tenant  */
export function showExpandedIrrigationMethods(tenant:string):boolean {
  switch (tenant) {
    case 'tvfrsl':
      return true
    case 'tnnOne':
      return true
    case 'Trrprs':
      return true
    // case 'tvpnlr':
    //   return false
    // case 'dmwrm':
    //   return false
    default:
      return false
  }
}

/**Returns either 'hours'(Tenant: Irrigation Presentation) or 'days' which is default for all tenants */
export function getCycleUnitsFromTenant(tenant: string) {
  switch (tenant) {
    case 'Trrprs':
      return 'days'
    default:
      return 'days'
  }
}

/**Checks weather a string representing a number is a valid float,
 * isFloat should not to be used for live validation(input) since it will reject some real values
 * isFloat is only usefull for a static/final validation before submitting a form, or sending a request
 * @param value string - the value to be checked
 * @param treatEmptyStringAsFloat boolean - optional param default = false, if true will return true for empty strings
 * @param discardExponent boolean - optional param default = true, if true will treat Exponent e as a nonvalid number and return false
 * (e is a valid number in JS it represents 'exponent' eg: 4*10**4 == 4e4 //40000) 
 */
export function isFloat(value:string, treatEmptyStringAsFloat: boolean = false, discardExponent:boolean = true):boolean {
  if (typeof(value) !== 'string') {
    //if we passed a non string value by accident or ommission, transform it into a string, so nothing breaks
    //@ts-ignore
    value = value.toString()
  }
  if (value === '' && !treatEmptyStringAsFloat) {
    return false
  }
  if (value.includes('e') && discardExponent) {
    return false
  }

  const trimmedValue = value.trim()
  if (trimmedValue === '-' || trimmedValue === '.' || trimmedValue === '-.') {
    return false
  }

  const number = Number(trimmedValue)
  return !isNaN(number) && Number.isFinite(number)
}

/**Legacy Function that used to fetch the agricultural period (before the Agricultural Period US was implemented) */
// export function getCurrentAgriculturalPeriod(tenant: string) {
//   switch (tenant) {
//     case 'tvfrsl':
//       // return moment('2023-08-10').startOf('day')
//       return moment('2023-08-14').set({
//         hour: 6,
//         minute: 0,
//         second: 0,
//         millisecond: 0
//       })
//     case 'tnnOne':
//       // return moment('2023-08-10').startOf('day')
//       const startDate = moment('2023-08-14').set({
//         hour: 6,
//         minute: 0,
//         second: 0,
//         millisecond: 0
//       })
//       return startDate
//     case 'tSchdT':
//       // return moment('2023-08-10').startOf('day')
//       return moment('2023-08-14').set({
//         hour: 6,
//         minute: 0,
//         second: 0,
//         millisecond: 0
//       })
//     // case 'tvpnlr':
//     //   return '12'
//     // case 'dmwrm':
//     //   return '12'
//     // case 'Trrprs':
//     //   return '6'
//     default:
//       return moment().startOf('year')
//   }
// }
/** Checks if Create IrriForms,IrriScheds,Cultivations,IrriProgs should be disabled based on current Agricultural Period  */
export function getDisabledFromAgriculturalPeriod(agrPeriod: AgriculturalPeriod | undefined) {
  const disabled =
    !agrPeriod || !agrPeriod.isVisible || agrPeriod.status === 'completed' || moment().isAfter(moment(agrPeriod.endDate))
  return disabled
}

/**Returns the valid irrigation frequency values based on the cycleDuration of the currentAgriculturalPeriod
 * In case the AgriculturalPeriod is not 12 or 24 days will return an empty array
 * In case the AgriculturalPeriod is undefined it will return an empty array
 * 
 * (usually used in a select or autocomplete component) */
export function getIrrigationFrequencies(curAgriPeriod: AgriculturalPeriod | undefined) {
  const cycleDuration = curAgriPeriod?.cycleDuration
  switch (cycleDuration) {
    case 6:
      return [...IRRIGATION_FREQUENCIES_6]
    case 8:
      return [...IRRIGATION_FREQUENCIES_8]
    case 12:
      return [...IRRIGATION_FREQUENCIES_12]
    case 24:
      return [...IRRIGATION_FREQUENCIES_24]
    default:
      return []
  }
}