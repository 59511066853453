import { numericalSorter } from '../utils/helpers'
import TemplateService from './TemplateService'

const IndicatorService = () => {
  const templateService = TemplateService('indicator')

  const getIndicators = (filter?) => {
    let data: any = {
      operation: 'getIndicators',
    }
    if (!!filter) {
      data.filter = filter
    }
    const options = {
      cachePromise: true,
      doReload: false,
    }
    return templateService.getEntities(filter, options)
    .then(
      res => {
        // console.log(`getIndicators sorting`)
        res.data.sort((a, b) => numericalSorter(a.rank, b.rank))
        return res
      }
    )
  }

  return({
    getEntities: getIndicators,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  })

}

export default IndicatorService
