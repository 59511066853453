import { SvgIcon } from '@material-ui/core'

const BellIcon = props => (
  <SvgIcon
    style={props.style}
    width='16'
    height='19'
    viewBox='0 0 16 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.2144 14.7829C14.885 14.7829 14.5691 14.6521 14.3362 14.4192C14.1033 14.1862 13.9724 13.8703 13.9724 13.5409V7.95196C13.9725 6.41248 13.4006 4.92786 12.3679 3.78614C11.3352 2.64442 9.91523 1.92699 8.38346 1.77304V0.5H7.14147V1.77304C5.6097 1.92699 4.18971 2.64442 3.157 3.78614C2.12429 4.92786 1.55247 6.41248 1.55249 7.95196V13.5409C1.55249 13.8703 1.42164 14.1862 1.18872 14.4192C0.955802 14.6521 0.639896 14.7829 0.310499 14.7829H0V16.0249H6.01125C5.90912 16.3063 5.8763 16.6082 5.91558 16.9049C5.95485 17.2017 6.06506 17.4846 6.23687 17.7298C6.40868 17.9749 6.63704 18.1751 6.9026 18.3132C7.16816 18.4514 7.46311 18.5235 7.76246 18.5235C8.06182 18.5235 8.35676 18.4514 8.62232 18.3132C8.88789 18.1751 9.11624 17.9749 9.28806 17.7298C9.45987 17.4846 9.57008 17.2017 9.60935 16.9049C9.64863 16.6082 9.61581 16.3063 9.51367 16.0249H15.5249V14.7829H15.2144ZM2.79449 13.5409V7.95196C2.79449 6.63438 3.3179 5.37075 4.24957 4.43907C5.18125 3.5074 6.44487 2.98399 7.76246 2.98399C9.08005 2.98399 10.3437 3.5074 11.2754 4.43907C12.207 5.37075 12.7304 6.63438 12.7304 7.95196V13.5409C12.7323 13.9787 12.8524 14.4079 13.0782 14.7829H2.44673C2.67249 14.4079 2.79265 13.9787 2.79449 13.5409ZM8.38346 16.6459C8.38346 16.7687 8.34704 16.8888 8.2788 16.9909C8.21057 17.0931 8.11358 17.1726 8.00011 17.2197C7.88664 17.2667 7.76177 17.2789 7.64131 17.255C7.52085 17.231 7.4102 17.1719 7.32335 17.085C7.2365 16.9982 7.17736 16.8875 7.1534 16.7671C7.12944 16.6466 7.14174 16.5218 7.18874 16.4083C7.23574 16.2948 7.31533 16.1978 7.41746 16.1296C7.51958 16.0613 7.63964 16.0249 7.76246 16.0249C7.92716 16.0249 8.08512 16.0904 8.20158 16.2068C8.31803 16.3233 8.38346 16.4812 8.38346 16.6459Z'
      fill='#3CADAD'
    />
  </SvgIcon>
)

export default BellIcon
