import TemplateService from './TemplateService'
import { useCommonStore } from '../state/commonStore'
import { useCollectionStore } from '../state/collectionStore'
import { useNetworkElementStore } from '../state/networkElementStore'
import { AgriculturalPeriod } from '../utils/types/collectionStoreTypes'
import i18n from '../i18n'

const AgriculturalPeriodService = () => {
  const apiClient = useCommonStore.getState().apiClient
  const path = 'agriculturalPeriod/'
  const templateService: any = TemplateService('agriculturalPeriod')

  /**
   * Create a backup db collection with all irriProgs of the given agricultural period
   * @param agriculturalPeriod : the given agricaltural period
   */
  const backupAllPeriodCycles = (agriculturalPeriod: number) => {
    let data: any = {
      agriculturalPeriod: agriculturalPeriod,
      operation: 'backupAllPeriodCycles',
    }
    return sendRequest(data)
  }

  const getEntities = (filter?: any, options?: any) => {
    /**Also check <AgriculturalPeriodCatalogs> -> handleSwitchPeriod() */
    return templateService.getEntities(filter, options).then(res => {
      let currentAgPeriod = res?.data?.find((ap: AgriculturalPeriod) => ap.isVisible === true)
      /**pseudoCurrentAgPeriod - is the period that will be displayed as current (for historic data viewing) and styling in Agricultural Period Catalog*/
      let pseudoCurrentAgPeriod = res?.data?.find((ap: AgriculturalPeriod) => ap.viewAsCurrent === true)
      /**Since the viewAsCurrent property doesn't exist on the backend model
       * On first load there should be no agr. period with viewAsCurrent property
       * so we append viewAsCurrent to the currentAgrPeriod - this is necessary for styling and consistency
       * If the user has switched off to a different agricultural period to view the historical data
       * then we will have to set the pseudoCurrentAgPeriod as the currentAgPeriod in the state
       * ⚠️ Notice that we are mutating the state (isVisible, viewAsCurrent) without notifying Zustand/React
       * this is done intentionaly in order not to mutate the data on the backend as an unwanted sideffect */
      
      /**In case the logged user has no agricultural period we create a virtual one the fly */
      if (!pseudoCurrentAgPeriod && !currentAgPeriod) {
        const TEMP_AGRI_PERIOD = {
          /**code: `VIRTUAL_PERIOD` is used by the AgriculturalPeriodInfo
           * if the code is 'VIRTUAL_PERIOD' the info will display the status as 'virtual'  */
          code: 'VIRTUAL_PERIOD',
          label: i18n.t('agriculturalPeriod.status.virtual.label'),
          status: 'completed',
          isVisible: true,
          startDate: new Date(new Date().getFullYear(), 0, 1),
          endDate: new Date(new Date().getFullYear(), 11, 31),
          cultivationPeriod: new Date().getFullYear(),
          cycleDuration: 12,
        }
        pseudoCurrentAgPeriod = TEMP_AGRI_PERIOD
      } else if (!pseudoCurrentAgPeriod) {
        currentAgPeriod.viewAsCurrent = true
      } else {
        currentAgPeriod.isVisible = false
        pseudoCurrentAgPeriod.isVisible = true
      }
      /**Flush the state if pseudoCurrentAgPeriod is true */
      if (!!pseudoCurrentAgPeriod && pseudoCurrentAgPeriod?.code !== currentAgPeriod?.code) {
        useCommonStore.getState().setCurrentAgriculturalPeriod(pseudoCurrentAgPeriod)
        useCollectionStore.getState().setItems('irriForms', undefined)
        useCollectionStore.getState().setItems('irriProgs', undefined)
        useCollectionStore.getState().setItems('irriScheds', undefined)
        useCollectionStore.getState().setItems('cultivations', undefined)
        useCollectionStore.getState().setItems('irriCommands', undefined)
        useCollectionStore.getState().setItems('irriEvents', undefined)
      } else {
        useCommonStore.getState().setCurrentAgriculturalPeriod(currentAgPeriod)
      }
      return res
    })
  }

  const saveEntity = (entity: any, doNotSelect?: boolean) => {
    return templateService.saveEntity(entity, doNotSelect).then((res: AgriculturalPeriod) => {
      //if we 'activated a new' period we need to flush the state at front end
      if (res.status === 'active' && !!res.isVisible) {
        useCommonStore.getState().setCurrentAgriculturalPeriod(res)
        useCollectionStore.getState().setItems('irriForms', undefined)
        useCollectionStore.getState().setItems('irriProgs', undefined)
        useCollectionStore.getState().setItems('irriScheds', undefined)
        useCollectionStore.getState().setItems('cultivations', undefined)
        useNetworkElementStore.getState().setItems('landparcels', undefined)
      }
      return res
    })
  }

  const sendRequest = (data: any) => {
    // console.log(`AgriculturalPeriodService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return {
    getEntities: getEntities, //templateService.getEntities,
    saveEntity: saveEntity, //templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
    backupAllPeriodCycles: backupAllPeriodCycles,
  }
}

export default AgriculturalPeriodService
