import moment from 'moment'

import TemplateService from './TemplateService'
import { useCommonStore } from '../state/commonStore'

const IrriEventService = () => {
  const templateService: any = TemplateService('irriEvent')

  const getEntities = (filter?: any, options?: any) => {
    const currentAgriculturalPeriod = useCommonStore.getState().currentAgriculturalPeriod
    /**Use Current year as fallback in case the currentAgriculturalPeriod is undefined */
    let cultivationPeriod: number = new Date().getFullYear()
    if (currentAgriculturalPeriod?.cultivationPeriod) {
      cultivationPeriod = currentAgriculturalPeriod?.cultivationPeriod
    }
    const startOfYear = moment().year(cultivationPeriod).startOf('year').toDate()
    const endOfYear = moment().year(cultivationPeriod).endOf('year').toDate()
    if (!filter) {
      filter = {
        'createdAt': {
          $gte: startOfYear,
          $lte: endOfYear,
        },
      }
    } else if (!filter['createdAt']) {
      filter['createdAt'] = {
        $gte: startOfYear,
        $lte: endOfYear,
      }
    }
    return templateService.getEntities(filter, options)
  }

  return ({
    getEntities: getEntities, //templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  })
}

export default IrriEventService
