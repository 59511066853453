import React, { useState } from 'react'
import { useCommonStore } from '../../state/commonStore'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  Typography,
  Button,
  Box,
  TextField,
} from '@material-ui/core'

import WrmIcon from '../WrmIcon'

const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0.25rem 1rem',
  },
  tabPanelTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    margin: '1rem 0',
    alignSelf: 'flex-start',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 16,
    width: '100%',
  },
  lilab: {
    minWidth: 220,
    maxWidth: 220,
    textAlign: 'left',
  },
  lival: {
    minWidth: 220,
    maxWidth: 220,
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  identityButtonContainer: {
    marginLeft: 'auto',
  },
  infoBox: {
    marginLeft: 8,
    padding: 8,
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  infoMsg: {
    marginLeft: 8,
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
  wrmIcon:{
    alignSelf:'flex-start',
    marginTop:'0.5rem'
  },
}))

export default function IdentitySettings(props) {
  const showAlert = useCommonStore(state => state.showAlert)
  const profile = useCommonStore(state => state.profile)
  const classes = useStyles()
  const { t } = useTranslation()

  const [username, setUsername] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [email, setEmail] = useState('')

  const handleChangeUsername = event => {
    let val = event.target.value
    setUsername(val)
  }
  const handleChangeOldPassword = event => {
    let val = event.target.value
    setOldPassword(val)
  }
  const handleChangePassword = event => {
    let val = event.target.value
    setPassword(val)
  }
  const handleChangePassword2 = event => {
    let val = event.target.value
    setPassword2(val)
  }
  const handleChangeEmail = event => {
    let val = event.target.value
    setEmail(val)
  }

  const validateUsernameCharacters = (test: string): string => {
    let validErrMsg = ''
    let regexOnValidate = /[^a-z0-9_+\-.!#$^`~@]/ig//Auth0 mask: Alphanumeric and any of these: '_', '+', '-', '.', '!', '#', '$', '^', '`', '~', '@'
    let validCharsMsg = `a-z, A-Z, 0-9,\n\t${t('md.validation.anyOfThese')}: '_', '+', '-', '.', '!', '#', '$', '^', '\`', '~', '@'`
    let invalidChars = test.match(regexOnValidate)
    if (!!invalidChars) {
      let msg = `${t('md.validation.error.invalidChars.found')} '${t('cp.myAccount.userNameNew')}':\n`
      msg = msg + `\t'${invalidChars.join("', '")}' \n`
      msg = msg + `\t${t('md.validation.error.invalidChars.validAre')}:\n`
      msg = msg + `\t${validCharsMsg}`
      validErrMsg = msg
    }
    return validErrMsg
  }
  const validatePasswordCharacters = (test: string): string => {
    let validErrMsg = ''
    let regexOnValidate = /[^a-z0-9_+\-.!#$^`~@]/ig//Auth0 mask: Alphanumeric and any of these: '_', '+', '-', '.', '!', '#', '$', '^', '`', '~', '@'
    let validCharsMsg = `a-z, A-Z, 0-9,\n\t${t('md.validation.anyOfThese')}: '_', '+', '-', '.', '!', '#', '$', '^', '\`', '~', '@'`
    let invalidChars = test.match(regexOnValidate)
    if (!!invalidChars) {
      let msg = `${t('md.validation.error.invalidChars.found')} '${t('cp.myAccount.acchurpsw.new')}'.\n`
      // msg = msg + `\t'${invalidChars.join("', '")}' \n`
      msg = msg + `\t${t('md.validation.error.invalidChars.validAre')}:\n`
      msg = msg + `\t${validCharsMsg}`
      validErrMsg = msg
    }
    return validErrMsg
  }
  const validateEmail = email => {
    let validErrMsg = ''
    // https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
    // No silver bullet here, the validation is super basic and only checks if the pattern looks like an email address
    // it will accept a lot of not-valid email adresses, but it safeguards the user from basic typos (eg spaces, no domain, no @ char)
    const regEx = new RegExp(/^\S+@\S+\.\S+$/)
    let mailTestResult = regEx.test(email)
    if (!mailTestResult) {
      validErrMsg = t('toaster.message.emailValidationError')
    }
    return validErrMsg
  }

  const handleUpdateUsername = () => {
    if (!username || username.length < 6 || username.length > 15) {
      showAlert(t('cp.myAccount.acchurnm.error.length'), 'E')
    } else {
      let msg = validateUsernameCharacters(username)
      if (msg) {
        showAlert(msg, 'E')
      } else {
      props.actUpdateUserUsernameOrPasswordOrEmail({username: username}).then(
          res => {
            showAlert(t('cp.myAccount.acchurnm.success'), 'S')
            setUsername('')
          },
          err => {
            let errMessage = `${t('cp.myAccount.acchurnm.error.notChanged')}:\n`
            if (err?.response?.data?.error?.message) {
              errMessage += t(err?.response?.data?.error?.message)
            } else {
              errMessage += err
            }
            showAlert(errMessage, 'E')
          }
        )
      }
    }
  }
  const handleUpdatePassword = () => {
    if (!oldPassword || oldPassword.length < 6 || oldPassword.length > 15) {
      showAlert(t('cp.myAccount.acchurpsw.error.current.length'), 'E')
    } else if (password !== password2) {
      showAlert(t('cp.myAccount.acchurpsw.error.match'), 'E')
    } else if (!password || password.length < 6 || password.length > 15) {
      showAlert(t('cp.myAccount.acchurpsw.error.length'), 'E')
    } else {
      let msg = validatePasswordCharacters(password)
      if (msg) {
        showAlert(msg, 'E')
      } else {
        props.actUpdateUserUsernameOrPasswordOrEmail({password: password, oldPassword: oldPassword})
        .then(
          res => {
            showAlert(t('cp.myAccount.acchurpsw.success'), 'S')
            setPassword('')
            setPassword2('')
          },
          err => {
            let errMessage = `${t('cp.myAccount.acchurpsw.error.notChanged')}:\n`
            if (err?.response?.data?.error?.message) {
              errMessage += t(err?.response?.data?.error?.message)
            } else if (err?.response?.data?.error?.error === 'invalid_grant') {
              errMessage += t(`cp.myAccount.acchurpsw.error.notChanged.invalid_grant`)
            } else {
              errMessage += err
            }
            showAlert(errMessage, 'E')
          }
        )
      }
    }
  }
  const handleUpdateEmail = () => {
    let msg = validateEmail(email)
    if (msg) {
      showAlert(msg, 'E')
    } else {
      props.actUpdateUserUsernameOrPasswordOrEmail({email: email})
      .then(
        res => {
          showAlert(t('userSettings.email.success'), 'S')
          setEmail('')
        },
        err => {
          let errMessage = `${t('userSettings.email.error.notChanged')}:\n`
          if (err?.response?.data?.error?.message) {
            errMessage += t(err?.response?.data?.error?.message)
          } else {
            errMessage += err
          }
          showAlert(errMessage, 'E')
        }
      )
    }
  }

  return (
    <>
      <Box className={classes.column}>
        <Typography
          align='left'
          className={classes.tabPanelTitle}
          component='h2'>
          {t('cp.myAccount.acchurnm')}
        </Typography>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('cp.myAccount.userName')}</Box>
          <TextField
            variant='outlined'
            disabled={true}
            type='text'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            value={profile?.auth0User?.username}
          />
        </Box>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('cp.myAccount.userNameNew')}</Box>
          <TextField
            variant='outlined'
            type='text'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            placeholder={t('cp.myAccount.userNameNew.placeholder')!}
            value={username}
            onChange={handleChangeUsername}
          />
        </Box>
        <Box className={classes.li}>
          <Box className={classes.identityButtonContainer}>
            <Button
              className={classes.applyBtn}
              disabled={username.length < 6}
              onClick={handleUpdateUsername}>
              {t('btn.update')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.column}>
        <Typography
          align='left'
          className={classes.tabPanelTitle}
          component='h2'>
          {t('cp.myAccount.acchurpsw')}
        </Typography>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('cp.myAccount.acchurpsw.current')}</Box>
          <TextField
            variant='outlined'
            type='password'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            placeholder={t('cp.myAccount.acchurpsw.current.placeholder')!}
            value={oldPassword}
            onChange={handleChangeOldPassword}
          />
        </Box>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('cp.myAccount.acchurpsw.new')}</Box>
          <TextField
            variant='outlined'
            type='password'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            placeholder={t('cp.myAccount.acchurpsw.new.placeholder')!}
            value={password}
            onChange={handleChangePassword}
          />
        </Box>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('cp.myAccount.acchurpsw.new2')}</Box>
          <TextField
            variant='outlined'
            type='password'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            placeholder={t('cp.myAccount.acchurpsw.new.placeholder')!}
            value={password2}
            onChange={handleChangePassword2}
          />
        </Box>
        <Box className={classes.li}>
          <Box className={classes.identityButtonContainer}>
            <Button
              className={classes.applyBtn}
              disabled={password.length < 6 || password !== password2}
              onClick={handleUpdatePassword}>
              {t('btn.update')}
            </Button>
          </Box>
        </Box>
        <Box className={classes.infoBox}>
          <WrmIcon icon='info' className={classes.wrmIcon} />
          <Typography variant='subtitle2' className={classes.infoMsg}>
            {t('userSettings.identity.info')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.column}>
        <Typography
          align='left'
          className={classes.tabPanelTitle}
          component='h2'>
          {t('userSettings.email.change')}
        </Typography>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('userSettings.email')}</Box>
          <TextField
            variant='outlined'
            disabled={true}
            type='text'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            value={profile?.auth0User?.email}
          />
        </Box>
        <Box className={classes.li}>
          <Box className={classes.lilab}>{t('userSettings.email.new')}</Box>
          <TextField
            variant='outlined'
            type='text'
            className={classes.lival}
            inputProps={{
              className: classes.input,
            }}
            placeholder={t('userSettings.email.new.placeholder')!}
            value={email}
            onChange={handleChangeEmail}
          />
        </Box>
        <Box className={classes.li}>
          <Box className={classes.identityButtonContainer}>
            <Button
              className={classes.applyBtn}
              disabled={email.length < 6}
              onClick={handleUpdateEmail}>
              {t('btn.update')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
