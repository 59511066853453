import { makeStyles, Theme, createStyles, Button, CircularProgress } from '@material-ui/core/'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    font: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
      textTransform: 'none',
    },
    contained: {
      textTransform: 'none',
      borderRadius: '8px',
      margin: '0.25rem',
      color: `${theme.palette.common.white}!important`,
    },
    outlined: {
      borderRadius: '8px',
      margin: '0.25rem',
      borderColor: theme.palette.custom.grey2,
      color: theme.palette.custom.grey2,
      '&:hover': {
        borderColor: theme.palette.custom.grey2,
        backgroundColor: theme.palette.custom.grey4,
        boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
      },
    },
    outlinedprimaryIsActive: {
      color: `${theme.palette.primary.main}!important`,
    },
    outlinedsecondaryIsActive: {
      color: `${theme.palette.secondary.main}!important`,
    },
    containedprimaryIsActive: {
      color: `${theme.palette.secondary.main}!important`,
    },
    containedsecondaryIsActive: {
      color: `${theme.palette.primary.main}!important`,
    },
    text: {
      minWidth: 113,
      fontSize: '0.875rem',
      margin: '0.25rem',
      maxHeight: '40px',
      height: 40,
      fontWeight: 'bold',
    },
    primaryDisabled: {
      backgroundColor: `${theme.palette.primary.disabled}!important`,
    },
    secondaryDisabled: {
      backgroundColor: `${theme.palette.secondary.disabled}!important`,
    },
    outlinedDisabled: {
      borderColor: theme.palette.tertiary.disabled,
      color: `${theme.palette.tertiary.disabled}!important`,
    },
    spinner: {
      width: '1.5rem',
      height: '1.5rem',
      maxWidth: '1.5rem',
      maxHeight: '1.5rem',
    },
    white: {
      color: theme.palette.common.white,
    },
    black: {
      color: theme.palette.custom.grey2,
    },
    small: {
      fontSize: '0.875rem', //14px
      maxHeight: '48px',
      minWidth: 100,
      height: 32,
    },
    medium: {
      fontSize: '0.875rem', //14px
      maxHeight: '40px',
      minWidth: 113,
      height: 40,
    },
    large: {
      fontSize: '1rem', //16px
      maxHeight: '48px',
      minWidth: 180,
      height: 48,
    },
    noSelectText:{
      userSelect: 'none', /* Standard syntax */
      '-webkit-user-select': 'none', /* WebKit (Safari, Chrome) */
      '-moz-user-select': 'none', /* Mozilla Firefox */
      '-ms-user-select': 'none', /* Internet Explorer/Edge */
    }
  })
)

export interface BtnGenericNtProps {
  /** You can pass the translation string directly, since the title is passed into a t(title) internally in ButtonHDR  */
  title?: string
  /** 'primary' | 'secondary' | 'default' | 'inherit' the material ui options 'default' & 'inherit' won't break anything but should not be used since they are not part of the design system */
  color?: 'primary' | 'secondary' | 'default' | 'inherit'
  /** 'contained' | 'outlined' */
  variant?: 'contained' | 'outlined' | 'text'
  /** 'small' | 'medium' | 'large' */
  size?: 'small' | 'medium' | 'large'
  /** Applied the disabled status to the button */
  disabled?: boolean
  /** onClick function that fires when the button is pressed */
  onClick?: (...args: any) => void
  /** If true the  `active` style is applied (used in the filter button)  */
  isActive?: boolean
  /** A custom css style object that can be applied to the button */
  style?: React.CSSProperties
  /** Renders an icon before the text(title). Should be a react component that returns an icon (you can use either < WrmIcon /> or a material-icon) */
  startIcon?: React.ReactElement | null
  /** Renders an icon after the text(title). Should be a react component that returns an icon (you can use either < WrmIcon /> or a material-icon) */
  endIcon?: React.ReactElement | null
  /** If true will render a spinner inside the button (if startIcon is also true will replace the start icon with a spinner) */
  loading?: boolean
}

/**Renders a material-ui Button that has various stylings in accordance to our Design System */
export function BtnGenericNt({
  title,
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  disabled = false,
  onClick = () => {},
  isActive = false,
  style = {},
  startIcon = null,
  endIcon = null,
  loading = false,
}: BtnGenericNtProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  if (loading && !endIcon)
    startIcon = (
      <CircularProgress className={clsx(classes.spinner, variant === 'outlined' ? classes.black : classes.white)} />
    )

  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
      classes={
        variant === 'contained' ? { disabled: classes[`${color}Disabled`] } : { disabled: classes.outlinedDisabled }
      }
      className={clsx(
        classes[variant],
        classes[size],
        classes.font,
        classes.noSelectText,
        isActive ? classes[`${variant}${color}IsActive`] : ''
      )}
      style={style}
      startIcon={startIcon}
      endIcon={
        loading && endIcon ? (
          <CircularProgress className={clsx(classes.spinner, variant === 'outlined' ? classes.black : classes.white)} />
        ) : (
          endIcon
        )
      }
    >
      {title ? t(title) : 'Button'}
    </Button>
  )
}

export default BtnGenericNt
