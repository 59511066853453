import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Tooltip, Typography, TypographyProps } from '@material-ui/core'

import TextNt from '../reusableComponents/TextNt'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    valueContainer: {
      display: 'flex',
      minWidth: 250,
      maxWidth: 250,
      paddingLeft: 8,
      overflowWrap: 'break-word',
      overflow: 'auto', //in case overflowWrap does not do the trick e.g. a long url (i.e. no blank spaces to break on)
    },
    valueContainer_left: {
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
    valueContainer_right: {
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
    valueContainer_center: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    tooltip: {
      maxWidth: 220,
      maxHeight: 650,
      padding: '8px 14px 6px',
      borderRadius: '8px',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      whiteSpace: 'pre-wrap',
    },
    arrow: {
      color: theme.palette.primary.main,
      fontSize: 18,
    },
  })
)

interface MdValueProps {
  /** Content of the value */
  // value: any
  value: any
  // // valueText: string
  // valueText: string
  /** Content of the value tooltip */
  tipText?: string
  /** weather info icon appears on the left or right side of the value, defaults to `right` */
  valueAlignment?: 'left' | 'right' | 'center'
  /** override value's mui box container className */
  valueClassName?: any,
  /** override tooltip's mui classes */
  tooltipClasses?: any,
}

/** keep tooltip popup open for debug (e.g. css tinkering) */
// const DEBUG: true | undefined = true
const DEBUG: true | undefined = undefined

/** Renders a value that may optionally have a tooltip */
export const MdValue = ({
  value,
  // valueText = '',
  tipText,
  valueAlignment = 'right',
  valueClassName,
  tooltipClasses,
}: MdValueProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box
      display='flex'
      alignItems='center'
    >
      {!!tipText ? (
      <Tooltip
        open={DEBUG}//for css debug
        arrow={true}
        classes={tooltipClasses ? {...{ tooltip: classes.tooltip, arrow: classes.arrow }, ...tooltipClasses} : { tooltip: classes.tooltip, arrow: classes.arrow }}
        placement='top'
        title={<TextNt variant='caption'>{t(tipText)}</TextNt>}
      >
        <Box
          className={valueClassName
            ? clsx (classes.valueContainer, classes[`valueContainer_${valueAlignment}`], valueClassName)
            : clsx (classes.valueContainer, classes[`valueContainer_${valueAlignment}`])}
        >
          {/* <TextNt variant='body2'>{valueText}</TextNt> */}
          {typeof value === 'string' ? (
          <Typography variant='body2'>
            {value}
          </Typography>
          ) : (
            value
          )}
        </Box>
      </Tooltip>
      ) : (
      <Box
        className={valueClassName
          ? clsx (classes.valueContainer, classes[`valueContainer_${valueAlignment}`], valueClassName)
          : clsx (classes.valueContainer, classes[`valueContainer_${valueAlignment}`])}
      >
        {/* <TextNt variant='body2'>{valueText}</TextNt> */}
        {typeof value === 'string' ? (
        <Typography variant='body2'>
          {value}
        </Typography>
        ) : (
          value
        )}
      </Box>
      )}
    </Box>
  )
}

export default MdValue
