export interface Measurement {
    tank_id: string,
    timeUnit: string,
    consumptionStartDate: string
    consumptionEndDate: string
}

export class SimulationNew {
    title: string
    tank: any
    pumps: any
    consumptionOptions: any
    core: any
}

export class Simulation {
    _id: string
    code: string
    title: string
    tank: any
    pumps: any
    consumptionOptions: any
    core: any
}

export interface EnergySimulationNoteAdd {
    simulation_id: string,
    text: string
}