import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'
import TemplateService from './TemplateService'

const MsrmService = () => {

  const apiClient = useCommonStore.getState().apiClient
  const profile = useCommonStore.getState().profile
  const path = 'msrm/'

  const templateService: any = TemplateService('msrm')

  /**
   * @param options: expandable object currently could be { docsLimit: <a number> }. TODO: add sortedBy if usefull
   * @param filter: the filter for measurements e.g. { refDatetime: { $gte: moment(someDate), $lte: moment(someOtherDate), }}
   */
  const getMsrms = (filter?: any, options?: any) => {
    //console.log(`MeasurementService getMeasurements sfilter:${JSON.stringify(sfilter)} mfilter:${JSON.stringify(mfilter)}`)
    let data: any = {
      operation: 'getMsrms',
    }
    if (filter) {
      data.filter = filter
    }
    if (options) {
      data.options = options
    }
    return sendRequest(data)
  }
  
  const saveMsrm = (msrm) => {
    let data: any = {
      msrm: msrm,
    }
    let isNewMeasurement = !msrm._id

    //checkForDuplicates filter is added in backend TODO: maybe define a request param to set/unset this check
    // let mfilter:any = {
    //   'series': measurement.series,
    //   'refDatetime': measurement.refDatetime,
    //   'isDeleted' : { $in: [null, false]}
    // }
    // data.filter = mfilter

    if (isNewMeasurement) {
      data.operation = 'saveMsrm'
    } else {
      data.operation = 'updateMsrm'
    }
    return sendRequest(data)
  }

  const deleteMsrm = (msrm) => {
    let data = {
      operation: 'deleteMsrm',
      msrm: msrm,
    }
    return sendRequest(data)
  }

  const sendRequest = (data) => {
    // console.log(`MeasurementService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getEntities: getMsrms,
    saveEntity: saveMsrm,
    deleteEntity: deleteMsrm,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  })

}

export default MsrmService
