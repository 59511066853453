import React, { Fragment } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'

import WrmIcon from '../WrmIcon'

const useStyles = makeStyles(theme => ({
  accordionRoot: {
    backgroundColor: 'inherit',
  },
  accordionExpanded: {
    margin: '0px !important',
  },
  accordionSummaryExpanded: {
    minHeight: 'inherit !important',
  },
  accordionSummaryContentExpanded: {
    margin: '12px 0 !important',
  },
  notesTab: {
  },
  notesBar: {
  },
  notesListBox: {
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.tertiary.dark,
  },
  noteList: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  noteText: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
  noteActions: {
    minWidth: theme.spacing(9),
    maxWidth: theme.spacing(9),
  },
  noteTag: {
    color: theme.palette.primary.dark,
    fontSize: '0.875rem !important',
  },
  actionBtn: {
    padding: '0px 0px 0px 0px',
  },
  actionIcon: {
    color: '#828282',
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
  },
  trashIcon: {
    width: 14,
    height: 18,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(12),
  },
  noBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: theme.spacing(9),
  },
  blueBtn: {
    textTransform: 'none',
    minWidth: 100,
    width: theme.spacing(9),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

const MdNotes = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [msgDialog, setMsgDialog] = React.useState('... Ahh ... I forgot what I wanted to ask!')
  const [idDialog, setIdDialog] = React.useState('')
  const [dialogNote, setDialogNote] = React.useState <any | null>(null)
  const [openNoteDialog, setOpenNoteDialog] = React.useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
  const handleOpenNoteDialog = (dlgId, question, content?) => {
    setIdDialog(dlgId)
    setMsgDialog(question)
    if (!!content) setDialogNote(content)
    switch (dlgId) {
      case 'editNote':
        console.log(`handleOpenNoteDialog editNote: ${JSON.stringify(props.selectedDataItem.notes.find(n => n._id === content._id),null,2)}`)
        break;
      case 'addNote':
        content = { text: ''}
        console.log(`handleOpenNoteDialog addNote: ${JSON.stringify(content,null,2)}`)
        setDialogNote(content)
        break;
      default:

    }
    setOpenNoteDialog(true)
  }
  const handleCloseNoteDialog = (reply) => {
    if (reply === 'yes') {
      console.log(`handleCloseNoteDialog dialogNote: ${JSON.stringify(dialogNote,null,2)}`)
      if (!validateNote(dialogNote)) {
        return
      }
      switch (idDialog) {
        case 'editNote':
          saveNote(dialogNote)
          break;
        case 'addNote':
          saveNote(dialogNote)
          break;
        default:
      }
      setDialogNote(null)
      setOpenNoteDialog(false)
    } else {
      setDialogNote(null)
      setOpenNoteDialog(false)
    }
  }
  const handleOpenConfirmDialog = (dlgId, question, content?) => {
    setIdDialog(dlgId)
    setMsgDialog(question)
    if (!!content) setDialogNote(content)
    setOpenConfirmDialog(true)
  }
  const handleCloseConfirmDialog = (reply) => {
    setOpenConfirmDialog(false)
    if (typeof reply !== 'string') reply = 'no'
    if (reply === 'yes') {
      switch (idDialog) {
        case 'confirmDeleteNote':
          deleteNote(dialogNote)
          break;
        default:
      }
    }
    setDialogNote(null)
  }

  const handleNoteTextChange = event => {
    let val = event.target.value
    let note: any = Object.assign({}, dialogNote)
    note.text = val
    setDialogNote(note)
  }
  const validateNote = (note) => {
    let valid = true
    dialogNote.text = dialogNote.text.trim()
    if (!dialogNote.text) {
      props.showAlert(t('md.notes.add.text.validation.empty'))
      valid = false
    }
    return valid
  }
  const saveNote = (note) => {
    props.actSaveDetailNote(props.selectedDataItem, note)
  }
  const deleteNote = (note) => {
    props.actDeleteDetailNote(props.selectedDataItem, note)
  }

  return (
    <Box
      // p={2}
      mt={1}
      className={classes.notesTab}
      display='flex'
      flexGrow={1}
      flexDirection='column'
      height='1px'
      // bgcolor={blueGrey['200']}
    >
      <Box
        // p={2}
        mb={1}
        className={classes.notesBar}
        display='flex'
        alignItems='center'
        // bgcolor={blueGrey['100']}
      >
        <Box mr='auto' display='flex'>
          {t('md.notes.title')}
        </Box>
        <Box ml={2} display='flex'>
          <Button
            className={classes.blueBtn}
            disabled={!props.selectedDataItem}
            onClick={(e) => {e.stopPropagation(); handleOpenNoteDialog('addNote', t('md.notes.add.title'));}}
          >
            {t('btn.add')}
          </Button>
        </Box>
      </Box>
      <Box
        // p={2}
        mt={1}
        className={classes.notesListBox}
        display='flex'
        flexGrow={1}
        height='1px'
        // bgcolor={blueGrey['100']}
      >
      {!!props.selectedDataItem &&
        <Box
          className={classes.noteList}
        >
        {props.selectedDataItem.notes &&
            props.selectedDataItem.notes
            .sort((a, b) => parseInt(b._id, 16) - parseInt(a._id, 16))
            .map((n, i) => {
              let timestamp = moment(n.updatedAt).format(t('datetime.format')!)
              let userId = n.modifiedBy ? n.modifiedBy : (n.addedBy ? n.addedBy : '-')
              let noteUser = props.users.find(u => u.user_id === userId)
              let by = !!noteUser ? noteUser.auth0User.name : userId.substring(userId.length - 20)
              return (
          <Fragment
            key={n._id}
          >
            <Accordion
              defaultExpanded={i === 0}
              elevation={0}
              classes={{root: classes.accordionRoot, expanded: classes.accordionExpanded }}
            >
              <AccordionSummary
                expandIcon={<Icon className={clsx(classes.actionIcon, classes.trashIcon, 'fas fa-chevron-down')} />}
                classes={{expanded: classes.accordionSummaryExpanded, content: classes.accordionSummaryContentExpanded }}
              >
                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='center'
                >
                  <Typography className={classes.noteTag}>{`${timestamp} ${n.modifiedBy ? t('md.notes.modifiedBy') : t('md.notes.addedBy')} ${by}`}</Typography>
                  {!(typeof props.noEditNote === 'function' ? props.noEditNote(n) : props.noEditNote) &&
                  <Box
                    ml='auto'
                    className={classes.noteActions}
                  >
                    <IconButton
                      className={classes.actionBtn}
                      onClick={(event) => {event.stopPropagation(); handleOpenNoteDialog('editNote', t('md.notes.edit.title'), n);}}
                    >
                      <Icon
                        className={clsx(classes.actionIcon, classes.trashIcon, 'fas fa-pen')}
                      />
                    </IconButton>
                    <IconButton
                      className={classes.actionBtn}
                      onClick={(event) => {event.stopPropagation(); handleOpenConfirmDialog('confirmDeleteNote', t('md.notes.confirm.delete.note'), n)}}
                    >
                      <WrmIcon
                        icon='trash'
                        className={clsx(classes.actionIcon, classes.trashIcon)}
                      />
                    </IconButton>
                  </Box>}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.noteText}>{n.text}</Typography>
              </AccordionDetails>
            </Accordion>
            {i < (props.selectedDataItem.notes.length - 1) && <Divider />}
          </Fragment>)})}
        </Box>}
      </Box>
      <Dialog
        id="noteDialog"
        open={openNoteDialog}
        onClose={() => handleCloseNoteDialog('no')}
      >
        <DialogTitle>{msgDialog}</DialogTitle>
        <DialogContent>
          {!!dialogNote && <Box display='flex' flexDirection='column' flexGrow={1}>
            <TextField
              label={t('md.notes.add.text')}
              fullWidth
              multiline
              minRows={5}
              value={dialogNote.text}
              className={classes.formControl}
              onChange={handleNoteTextChange}
            />
          </Box>}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            className={classes.cancelBtn}
            onClick={() => handleCloseNoteDialog('no')}
          >
            {t('btn.cancel')}
          </Button>
          <Button
            className={classes.applyBtn}
            onClick={() => handleCloseNoteDialog('yes')}
          >
            {t('btn.apply')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="confirmDialog"
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>{msgDialog}</DialogTitle>
        {!!dialogNote && <DialogContent>
          <Box
            display='flex'
            className={classes.title}
          >
            {`"${dialogNote.text.substring(0, 10)}..."`}
          </Box>
        </DialogContent>}
        <DialogActions>
          <Button
            autoFocus
            variant='outlined'
            className={classes.noBtn}
            onClick={() => handleCloseConfirmDialog('no')}
          >
            {t('btn.no')}
          </Button>
          <Button
            variant='outlined'
            className={classes.noBtn}
            color="primary"
            onClick={() => handleCloseConfirmDialog('yes')}
          >
            {t('btn.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )

}

export default MdNotes
