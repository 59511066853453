const FileImportSeriesService = apiClient => {
  const path = 'fileImportSeries/'

  const getFileImportSeries = (filter?) => {
    let data: any = {
      operation: 'getFileImportSeries',
    }
    if (filter) {
      data.filter = filter
    }
    return sendRequest(data)
  }

  const getCollectionByUploadedFileID = (fileImportSerie,filter?)=>{
    let data: any = {
      operation: 'getCollectionByUploadedFileID',
      fileImportSerie,
    }
    if (filter) {
      data.filter = filter
    }
    return sendRequest(data)
  }

  const deleteFileImportSeriesByUploadedFileID = fileImportSerie => {
    //will delete the FileImportSeries and the associated Invoices/ElectricityBills/NetworkElements/AMRs etc
    let data = {
      operation: 'deleteFileImportSeriesByUploadedFileID',
      fileImportSerie,
    }
    return sendRequest(data)
  }

  const sendRequest = data => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return {
    getFileImportSeries: getFileImportSeries,
    getCollectionByUploadedFileID: getCollectionByUploadedFileID,
    deleteFileImportSeriesByUploadedFileID: deleteFileImportSeriesByUploadedFileID,
  }
}

export default FileImportSeriesService
