import { SvgIcon } from '@material-ui/core'

const PrintIconNt = props => (
  <SvgIcon
    style={props.style}
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_57_8572)'>
      <path
        d='M13.3612 4.49091C13.1481 4.27727 12.8508 4.14408 12.5251 4.1443H11.1842V2.12901L9.05511 0H8.93958H2.8165V4.14433H1.4757C1.14968 4.14411 0.852203 4.27727 0.639059 4.49094C0.425614 4.70416 0.29275 5.00147 0.292969 5.32695V10.001C0.29275 10.3269 0.425504 10.6246 0.639168 10.8374C0.852285 11.0511 1.14989 11.1838 1.4757 11.1836H2.81647V14H11.1842V11.1836H12.5251C12.8505 11.1838 13.1479 11.0511 13.3611 10.8374C13.5748 10.6246 13.7079 10.3269 13.7077 10.001V5.32695C13.7079 5.00145 13.5748 4.70416 13.3612 4.49091ZM8.84659 0.580344L10.5508 2.28457H8.84659V0.580344ZM3.37436 0.557758L8.28872 0.558168V2.84252H10.6264V4.14433H3.37436V0.557758ZM10.6264 13.4422H3.37436V10.9047H3.37393V8.26227H10.626V11.1836H10.6264V13.4422ZM13.1499 10.001C13.1498 10.1747 13.0802 10.329 12.9668 10.4432C12.8529 10.5562 12.6985 10.6255 12.5251 10.6259H11.1839V7.7041H2.81606V10.6259H1.4757C1.30165 10.6255 1.14738 10.5562 1.03366 10.4432C0.920344 10.329 0.850946 10.1747 0.850836 10.001V5.32695C0.850946 5.15364 0.920235 4.99915 1.03366 4.88521C1.1476 4.77189 1.30187 4.7025 1.4757 4.70206H2.81647H11.1842H12.5251C12.6982 4.7025 12.8528 4.77168 12.9667 4.88521C13.0803 4.99934 13.1497 5.15364 13.1498 5.32695V10.001H13.1499Z'
        fill='#5DCFCF'
      />
      <path d='M9.41695 9.19141H4.58203V9.74936H9.41695V9.19141Z' fill='#5DCFCF' />
      <path d='M9.41695 10.627H4.58203V11.1847H9.41695V10.627Z' fill='#5DCFCF' />
      <path d='M6.86677 11.9023H4.58203V12.4601H6.86677V11.9023Z' fill='#5DCFCF' />
      <path
        d='M9.65379 5.83398C9.4005 5.83398 9.19531 6.03928 9.19531 6.29265C9.19531 6.54561 9.4005 6.7509 9.65379 6.7509C9.90707 6.7509 10.1123 6.54561 10.1123 6.29265C10.1123 6.03928 9.9071 5.83398 9.65379 5.83398Z'
        fill='#5DCFCF'
      />
      <path
        d='M11.605 5.83398C11.3518 5.83398 11.1465 6.03928 11.1465 6.29265C11.1465 6.54561 11.3518 6.7509 11.605 6.7509C11.8582 6.7509 12.0635 6.54561 12.0635 6.29265C12.0635 6.03928 11.8582 5.83398 11.605 5.83398Z'
        fill='#5DCFCF'
      />
    </g>
    <defs>
      <clipPath id='clip0_57_8572'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default PrintIconNt
