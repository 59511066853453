import proj4 from 'proj4'
var _ = require('lodash');

export type CoordinateSystem = 'EPSG:4326' | 'EPSG:2100'

proj4.defs(
  'EPSG:2100',
  '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs'
)
proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs')
/**
 * Reprojects coordinates from one Coordinate System to another
 * Default behaviour with no parameters apart from coordinates:
 * is from "EPSG:4326" (WGS84) to "EPSG:2100" (ΕΓΣΑ87)
 * @param coords - array of coordinates [x,y] or [λ/longitude, φ/latitude]
 * @param from - Optional, a string representing the original coordinate system
 * @param to - Optional, the target coordinate system that we want to transform the coords
 * Both from and to are of type CoordinateSystem (a string either 'EPSG:4326' | 'EPSG:2100') 
 * in case new coordinate systems are added to the hook they should be defined as defs first
 *  -> https://github.com/derhuerst/epsg-index repo can be used to fetch varius projections
 *  -> https://github.com/derhuerst/transform-coordinates if cloned and installed there is a JSON file with
 * all the parameters for all coordinate sytems from https://epsg.io/
 *  */
const useReproject = () => {
  const R = (
    coords: number[],
    from: CoordinateSystem = 'EPSG:4326',
    to: CoordinateSystem = 'EPSG:2100'
  ) => {
    //For some reason proj4 mutates the original array (it removes the altitude), so clone before reprojecting
    const clonedCoods = _.cloneDeep(coords)
    return proj4(from, to, clonedCoods) as [number, number]
  }
  return R
}

export default useReproject
