import React, { ReactNode } from 'react'
import { makeStyles, createStyles, Theme, Select, SelectProps, MenuItem, FormControl } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      '&.Mui-focused fieldset': {
        borderColor: `${theme.palette.primary.dark} !important`,
      },
      '& input::placeholder': {
        fontStyle: 'italic',
      },
    },
    formControl: {
      minWidth: 320,
      width: 320,
      textAlign: 'left',
      marginTop: '8px',
    },
    label: {
      ...theme.typography.h3,
      marginBottom: 8,
      display: 'inline-box',
    },
    inputRoot: {
      paddingTop: 14.5,
      paddingBottom: 14.5,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.h3.fontWeight,
    },
    menuItem: {
      border: `1px solid ${theme.palette.common.white}`,
      borderRadius: '8px',
      margin: '0 8px',
      height: 48,
      ...theme.typography.h3,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.lighter} !important`,
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#fff',
        color: theme.palette.primary.dark,
      },
    },
  })
)

export interface SelectNtProps extends SelectProps {
  /**The title of the label that will be displayed above the `<Select>` */
  title?: string
  /**If true will display an `*` next to the label (label also needs to be non empty)  */
  required?: boolean
  /**Callback function fired when a menu item is selected.
  @param event
  The event source of the callback. You can pull out the new value by accessing event.target.value (any).
  @param child — The react element that was selected when native is false (default).
  @document */
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    child: React.ReactNode
  ) => void
  /**	Array of options. (should be `<MenuItemNt>` that will be used as Select dropdown options)
   *
   * you can use either `children` or `options` to render the  Select dropdwon options
   */
  children?: ReactNode
  /**	Array of options. (will be mapped to  `<MenuItem>` that will be used as Select dropdown options)
   *
   * an option should be an object with the following properties `{value:'the value of the option', label: 'the label of the option'}`
   *
   * you can use either `children` or `options` to render the  Select dropdwon options
   */
  options?: any[]
  /**The value of the `<Select>` */
  value: any
}

/**Renders a styled  `<Select>`, accepts all default material-ui `<Select>` props
 *
 * If you want to pass the select options as children you should use the `<MenuItemNt>` instead of `<MenuItem>`
 *
 * alternatively you can skipp the children and pass an array of `options` as a prop, the options MUST have the following properties (value,label):
 * {value:'someValue', label:'TheDisplayLabel'}
 */
export function SelectNt({ title, required, value, onChange, children, options, ...props }: SelectNtProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <FormControl className={classes.formControl}>
      {title && (
        <div>
          <label className={classes.label}>{`${t(title)}${required ? ' *' : ''}`}</label>
        </div>
      )}
      <Select
        className={classes.root}
        value={value}
        onChange={onChange}
        variant='outlined'
        classes={{
          root: classes.inputRoot,
        }}
        {...props}
      >
        {options
          ? options.map((el, i) => (
              <MenuItem key={i} value={el.value} className={classes.menuItem}>
                {t(el.label) as string}
              </MenuItem>
            ))
          : children}
      </Select>
    </FormControl>
  )
}

export default SelectNt
