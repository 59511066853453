import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, List, ListItem, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        justifyContent: 'center',
    },
    li: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 8,
        paddingRight: 8,
    },
    lilab: {
        display: 'flex',
        minWidth: 150,
        maxWidth: 150,
        fontWeight: 700,
    },
    lival: {
        minWidth: 370,
        maxWidth: 370,
    },
    dlgpaper: {
        maxWidth: '80vw',
    },
    dlgcontroot: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderTop: `solid 1px ${theme.palette.tertiary.light}`,
        borderBottom: `solid 1px ${theme.palette.tertiary.light}`,
    },
    input: {
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: 14,
    },  applyBtn: {
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 700,
        width: theme.spacing(12),
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    cancelBtn: {
        textTransform: 'none',
        fontSize: '0.875rem',
        fontWeight: 700,
        width: theme.spacing(12),
    },
}))

const SetSeriesScadaCode = (props) => {

  const { t } = useTranslation()
  const classes = useStyles();

  const tSeries = props.selectedSeries

  const cloneSeries = (series) => {
    let clone = Object.assign({}, series)
    return clone
  }

  const handleChange = (event) => {
    let value = !!event.target.value ? event.target.value : ''
    let updatedSeries = {...series}
    updatedSeries.scadaDeviceCode = value
    setSeries(updatedSeries)
  }
  const [series, setSeries] = useState(cloneSeries(tSeries))

  console.log(`series ${JSON.stringify(series,null,2)}`)
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dlgpaper }}
      onClose={(event, reason) => (reason === 'backdropClick') ? null : {}}
    >
      <DialogTitle >{t('ne.dlg.edit')}</DialogTitle>
      <DialogContent
        classes={{ root: classes.dlgcontroot }}
      >
        <List>
          <ListItem className={classes.li}>
            <Box className={classes.lilab}>
              {t(`msr.series.id`)}
            </Box>
            <TextField
              id={'seriesId'}
              variant="outlined"
              required
              fullWidth
              className={classes.lival}
              inputProps={{
                className: classes.input,
                readOnly: true,
              }}
              value={series._id}
              disabled={true}
              //onChange={event => handleChange(event)}
            />
          </ListItem>
          {!!series.measurementType && !!series.measurementType.group &&
          <ListItem className={classes.li}>
            <Box className={classes.lilab}>
              {t(`msr.group`)}
            </Box>
            <TextField
              id={'groupName'}
              variant="outlined"
              required
              fullWidth
              className={classes.lival}
              inputProps={{
                className: classes.input,
                readOnly: true,
              }}
              value={t(`msr.fld.${series.measurementType.group}`)}
              disabled={true}
              //onChange={event => handleChange(event)}
            />
          </ListItem>}
          <ListItem className={classes.li}>
            <Box className={classes.lilab}>
              {t(`msr.type`)}
            </Box>
            <TextField
              id={'measurementTypeCode'}
              variant="outlined"
              required
              fullWidth
              className={classes.lival}
              inputProps={{
                className: classes.input,
                readOnly: true,
              }}
              value={t(`msr.fld.${series.measurementType.code}`, series.measurementType.label)}
              disabled={true}
            />
          </ListItem>
          <ListItem className={classes.li}>
            <Box className={classes.lilab}>
              {t(`msr.series.scadacode`)}
            </Box>
            <TextField
              id={'scadaDeviceCode'}
              variant="outlined"
              required
              fullWidth
              className={classes.lival}
              inputProps={{
                className: classes.input,
                readOnly: false,
              }}
              value={series.scadaDeviceCode}
              disabled={false}
              onChange={event => handleChange(event)}
            />
          </ListItem>
        </List>
      </DialogContent >
      <DialogActions>
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          onClick={() =>props.onClose(null)}
        >
          {t('btn.cancel')}
        </Button>
        <Button
          className={classes.applyBtn}
          onClick={() => props.onClose(series)}
        >
          {t('btn.update')}
        </Button>
        </DialogActions>
    </Dialog >
  )

}

export default SetSeriesScadaCode
