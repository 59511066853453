import React from 'react'
import { makeStyles, createStyles, Theme, TextField, Chip, Tooltip } from '@material-ui/core'
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import CheckBoxNt from './CheckBoxNt'
import TextNt from './TextNt'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxRoot: {
      marginTop:12,
    },
    autoRoot: {
      flexGrow: 1,
      width: '100%',
      alignSelf: 'flex-start',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        // Styles here will be applied to the outlined input when it's focused
        // border must be removed since we use the `inputRoot`'s border when AutocompleteNt is focused
        border: 'none !important',
      },
    },
    /**Popper is the expanded list with the options */
    popper: {
      marginLeft: '1rem',
      whiteSpace: 'pre-wrap',
      flexGrow: 1,
      width: '320px',
      minWidth: 320,
    },
    inputRoot: {
      height: 48,
      width: 320,
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
      alignItems: 'center',
      justifyItems: 'center',
      fontWeight: theme.typography.h3.fontWeight,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      '&.Mui-focused': {
        border: '2px solid #3CADAD !important',
        maxHeight: 48,
        overflowY: 'scroll',
      },
    },
    option: {
      height: '48px',
      border: `1px solid ${theme.palette.custom.transparent}`,
      borderRadius: '8px',
      margin: '0 4px',
      fontWeight: theme.typography.h3.fontWeight,
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      letterSpacing: 0,
      '&[aria-selected="true"]': {
        backgroundColor: '#fff',
        color: theme.palette.primary.dark,
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.primary.lighter,
      },
    },
    autocompleteExternalLabel: {
      marginBottom: 8,
      textAlign:'left'
    },
  })
)

const useChipStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipRoot: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      height: 24,
      width: '6.3rem',
    },
    chipLabel: {
      fontWeight: theme.typography.caption.fontWeight,
      fontSize: theme.typography.caption.fontSize,
      letterSpacing: 0,
      paddingLeft: 4,
      paddingRight: 4,
      flexGrow: 1,
    },
    deleteIcon: {
      marginRight: 2,
      color: theme.palette.common.white,
    },
  })
)

const MyChip = props => {
  const classes = useChipStyles()
  return (
    <Tooltip title={props.label || ''}>
      <Chip
        // className={classes.chipRoot}
        // deleteIcon={<CustomDeleteIcon onClick={props.onDelete}/>}
        classes={{
          label: classes.chipLabel,
          root: classes.chipRoot,
          deleteIcon: classes.deleteIcon,
        }}
        {...props}
      />
    </Tooltip>
  )
}

interface AutocompleteNtProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  /**	Array of options. (Values of the autocomplete dropdown) */
  options: T[]
  /**The value of the autocomplete.
   * The value must have reference equality with the option in order to be selected.
   * You can customize the equality behavior with the getOptionSelected prop. */
  value: any
  /**Callback fired when the value changes.
   * Signature: `function(event: object, value: T | T[], reason: string) => void`
   * @param event: The event source of the callback.
   * @param value: The new value of the component.
   * @param reason: One of "create-option", "select-option", "remove-option", "blur" or "clear". */
  onChange: (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T> | undefined
  ) => void
  getOptionLabel: (option: T) => string
  getOptionSelected: (option: T, value: T | T[] | null) => boolean
  /** Mandatory by default, optional in AutocompleteNt */
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
  /**The Description for the Autocomplete that will be displayed above the the `<input>` */
  label?: string
  /**If true will display an `*` next to the label (label also needs to be non empty)  */
  required?:boolean
  /**Should be used only when `multiple` is true, if the function returns true render the 
   * tagLimit text normaly if false(it should return false when the 'select all' option is checked) subtrack -1 from the tagLimit   */
  validateLimitTags?:()=>boolean
}

/**Renders a styled labeled-Autocomplete, accepts all default props from material-ui Autocompete */
export function AutocompleteNt({
  multiple = false,
  limitTags = 2,
  clearOnEscape = false,
  options,
  value,
  onChange,
  getOptionLabel,
  getOptionSelected,
  getOptionDisabled = () => false,
  noOptionsText,
  label,
  required,
  validateLimitTags,
  ...props
}: AutocompleteNtProps<any, any, any, any>) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getLimitTagsText = (more) => {
    if (!!validateLimitTags && validateLimitTags()) return `+${more -1}`
    return `+${more}`;
  };

//   <Box
//   mb={3}
//   display='flex'
//   flexDirection={'column'}
//   alignItems='flex-start'
//   className={classes.boxRoot}
//   maxHeight={48}
// >
  return (
    <div className={classes.boxRoot}>
      {label && (
        <TextNt variant='h3' className={classes.autocompleteExternalLabel}>
          {`${t(label)}${required ? ' *' : ''}`}
        </TextNt>
      )}
      <Autocomplete
        multiple={multiple}
        limitTags={limitTags}
        size='small'
        className={classes.autoRoot}
        classes={{
          inputRoot: classes.inputRoot,
          popper: classes.popper,
          option: classes.option,
        }}
        ChipProps={{
          variant: 'outlined',
        }}
        clearOnEscape={clearOnEscape}
        disableCloseOnSelect={multiple ? true : false}
        options={options}
        noOptionsText={noOptionsText ? t(noOptionsText as string) : t('md.autocomplete.noOptions')}
        getOptionSelected={getOptionSelected}
        getOptionDisabled={getOptionDisabled}
        value={value}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        renderInput={params => <TextField {...params} variant='outlined' />}
        {...props}
        // Conditionally apply getLimitTagsText only when multiple is true
        {...(multiple ? { getLimitTagsText } : {})}
        // Conditionally apply renderOption (Custom CheckBoxes) only if multiple is true
        {...(multiple
          ? {
              renderOption: (option, { selected }) => (
                <>
                  <CheckBoxNt checked={selected} />
                  {getOptionLabel(option)}
                </>
              ),
            }
          : {})}
        // Conditionally apply renderTags (Custom Chips) only if multiple is true
        {...(multiple
          ? {
              renderTags: (tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return <MyChip {...getTagProps({ index })} label={getOptionLabel(option)} disabled={getOptionDisabled(option)}/>
                }),
            }
          : {})}
      />
    </div>
  )
}

export default AutocompleteNt
