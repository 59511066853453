const ElectricityBillsService = apiClient => {
  const path = 'electricityBills/'

  const getElectricityBills = (filter?,isIrrigation?:boolean) => {
    let data: any = {
      operation: 'getElectricityBills',
      isIrrigation: isIrrigation,
    }
    if (filter) {
      data.filter = filter
    }
    return sendRequest(data)
  }

  const saveElectricityBills = (electricityBills, fileMeta) => {
    let data: any = {
      electricityBills,
      fileMeta,
      operation: 'saveElectricityBills',
    }
    return sendRequest(data)
  }

  const deleteElectricityBillByID = (electricityBill,fileMeta) => {
    let data = {
      operation: 'deleteElectricityBillByID',
      electricityBill,
      fileMeta,
    }
    return sendRequest(data)
  }

  const deleteElectricityBillsByUploadedFileID = (uploadedFileID,fileMeta) => {
    let data = {
      operation: 'deleteElectricityBillsByUploadedFileID',
      uploadedFileID,
      fileMeta,
    }
    return sendRequest(data)
  }

  const sendRequest = data => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return {
    getElectricityBills: getElectricityBills,
    saveElectricityBills: saveElectricityBills,
    deleteElectricityBillByID: deleteElectricityBillByID,
    deleteElectricityBillsByUploadedFileID: deleteElectricityBillsByUploadedFileID,
  }
}

export default ElectricityBillsService
