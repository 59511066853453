export default function resizeImage(arrayBufferIMG, fileType) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    // convert the file into an image that can be used by the canvas
    const image = new Image()
    //image is an arrayBuffer, convert it to a blob
    const arrayBufferView = new Uint8Array( arrayBufferIMG );
    const blob = new Blob( [ arrayBufferView ], { type: `image/${fileType}` } );
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL( blob );
    image.src = imageUrl

    image.onload = () => {
      const initialWidth = image.width
      const initialHeight = image.height
  
      const MAX_WIDTH = 800
      const MAX_HEIGHT = 800

      let scale
      //scale the image so it's not larger than 800px in either dimension
      if (initialWidth > initialHeight) {
        scale = MAX_WIDTH / initialWidth
        canvas.width = MAX_WIDTH
        canvas.height = initialHeight * scale
      } else {
        scale = MAX_HEIGHT / initialHeight
        canvas.width = initialWidth * scale
        canvas.height = MAX_HEIGHT
      }
      //draw the image on the canvas
      const ctx = canvas.getContext('2d')
      //@ts-ignore
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

      // return the resized image from the canvas as blob
      // @ts-ignore
      return ctx.canvas.toBlob(blobImg => {
        resolve(blobImg)
      }, `image/${fileType}`)
    }
  })
}
