import React, { useRef } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeletonRoot: {
      width: 'calc(100vw - 32px)',
      height: 'calc(100dvh - 108px)',
      paddingTop: 4,
      marginTop: 120,
    },
    skeleton: {
      width: '100%',
      borderRadius: 8,
    },
    skeletonRect: {
      height: '94% !important',
    },
  })
)

export default function SkeletonMainMobile(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const rootRef: any = useRef(null)
  return (
    <div className={classes.skeletonRoot} ref={rootRef}>
      <Skeleton
        variant='rect'
        animation='wave'
        height={rootRef?.current?.clientHeight || '85vh'}
        className={classes.skeleton}
        classes={{ rect: classes.skeletonRect }}
      />
    </div>
  )
}
