import { SvgIcon } from '@material-ui/core'

const BurgerIcon = props => {
  return (
    <SvgIcon style={props.style} width='32' height='24' viewBox='0 0 36 28'>
      <path d='M2 2H34' stroke='white' strokeWidth='3' strokeLinecap='round' />
      <path d='M2 14H34' stroke='white' strokeWidth='3' strokeLinecap='round' />
      <path d='M2 26H34' stroke='white' strokeWidth='3' strokeLinecap='round' />
    </SvgIcon>
  )
}

export default BurgerIcon
