import i18n from '../i18n'

import { buildErrorMessage } from '../utils/helpers'
import { useCollectionStore } from '../state/collectionStore'
import { useCommonStore } from '../state/commonStore'

const TemplateService = (entityName: string, entityPlural?: string, apiClient?: any) => {

  if (!apiClient) {
    apiClient = useCommonStore.getState().apiClient
  }

  const profile = useCommonStore.getState().profile
  const showAlert = useCommonStore.getState().showAlert
  const currentAgriculturalPeriod = useCommonStore.getState().currentAgriculturalPeriod

  const _entityPlural = entityPlural ?? `${entityName}s`
  const entityCap = `${entityName.substring(0,1).toUpperCase()}${entityName.substring(1)}`
  const entityPluralCap = `${_entityPlural.substring(0,1).toUpperCase()}${_entityPlural.substring(1)}`
  const path = `${entityName}/`

  /**
   * 
   * @param filter 
   * @param doReload - if true the data will be fetched from the db even if they exist in state.
   * @param cachePromise - if true (and the data should be fetch from db) then the db fetch promise will be cached untill resolved
   * (thus any subsequent fetch requests will return that same promise, and so no unnecessary actual db fetch request will be made).
   * @param doNotStore - if true the result will not be stored in state
   * (useful when the provided filter param does not result in the full set of data that we want to store in state).
   * @returns 
   */
  const getEntities = (filter?: any, options?: any) => {
  // const getEntities = (filter?: any, doReload?: boolean, cachePromise?: boolean, doNotStore?: boolean) => {
    let entities: any[] = useCollectionStore.getState()[_entityPlural]
    if (!!entities && !options?.doReload) {
      return Promise.resolve({ data: entities })
    } else {
      let data: any = {
        operation: `get${entityPluralCap}`,
      }
      if (!!filter) {
        data.filter = filter
      }
      if (!!options) {
        let backendOptions = {...options}
        delete backendOptions.doReload
        delete backendOptions.doNotStore
        delete backendOptions.cachePromise
        if (Object.keys(backendOptions).length > 0) {
          data.options = backendOptions
        }
      }
      return _sendRequest(data, !!options?.cachePromise)
      .then(
        res => {
          if (!res.data.error) {
            console.log(`getEntities ${_entityPlural} cnt ${res.data.length}`)
            // console.log(`loadCollection ${_entityPlural} ${JSON.stringify(res.data,null,2)}`)
            if (!options?.doNotStore) {
              useCollectionStore.getState().setItems(_entityPlural, res.data)
            }
            return res
          } else {
            console.log(`ERROR getEntities ${_entityPlural} ${JSON.stringify(res.data,null,2)}`)
            return { data: [] }
          }
        },
        err => {
          console.log(`ERROR getEntities ${_entityPlural} ${JSON.stringify(err, null, 2)}`)
          return { data: [] }
        }
      )
    }
  }
  const saveEntity = (entity: any, doNotSelect?: boolean) => {
    let data: any = {}
    data[`${entityName}`] = entity
    let isNewEntity = !entity._id
    if (isNewEntity) {
      data.operation = `save${entityCap}`
      entity.addedBy = profile?.user_id
      if (entityName === 'consumer' && profile?.selectedTenant === 'nm-tp' && !!entity.tenantCode) {
        /** If a new consumer is beeing created (registered as an app user) do not set the tenantCode based on profile.selectedTenant */
      } else if (entityName === 'irriSoft') {
        entity.tenantCode = 'nm-tp'
      } else {
        entity.tenantCode = profile?.selectedTenant
      }
    } else {
      data.operation = `update${entityCap}`
      entity.modifiedBy = profile?.user_id
    }

    /** It is possible that this method has been called by a component
    * before the entities collection has been loaded,
    * therefore we call 'getEntities()' first **/
    return getEntities()
    .then(
      res => {
        return _sendRequest(data)
      }
    )

    .then(
      res => {
        entity = res.data

        if (isNewEntity) {
          if (_entityPlural === 'irriCommands') {
            //Check that the new IrriCommand belongs to the currentAgriculturalPeriod
            const itemAgrPeriod = new Date(entity?.createdAt).getFullYear()
            if (itemAgrPeriod == currentAgriculturalPeriod?.cultivationPeriod) {
              useCollectionStore.getState().addItem(_entityPlural, entity)
            }
          } else {
            useCollectionStore.getState().addItem(_entityPlural, entity)
          }
        } else {
          useCollectionStore.getState().updateItem(_entityPlural, entity)
        }
        if (!doNotSelect) {
          // meta.service[meta.selector](item)
        }
        return entity
      },
      err => {
        return errorHandler(err)
      }
    )
  }
  const deleteEntity = (entity: any, note?: any) => {
    let promise: any
    if (!note) {
      promise = Promise.resolve({ data: entity })//if no need to add note then promise the entity as is.
    } else {
      console.log(`deleteEntity ${_entityPlural} saving note: note ${note}`)
      note.addedBy = profile?.user_id
      note.text = `${i18n.t('md.confirm.delete.item.noteReasonPrefix')}: ${
        note.text
      }`
      // promise = saveEntityNote(entity, note)
      let data: any = {
        operation: `add${entityCap}Note`,
        note: note,
      }
      data[`${entityName}_id`] = entity._id
      promise = _sendRequest(data)
    }
    return promise
    .then(
      res => {
        console.log(`deleteEntity ${_entityPlural} after saving note: res.data ${JSON.stringify(res.data,null,2)}`)
        entity = res.data
        let data = {
          operation: `delete${entityCap}`,
        }
        data[`${entityName}`] = entity

        /** It is possible that this method has been called by a component
        * before the entities collection has been loaded,
        * therefore we call 'getEntities()' first **/
        return getEntities()
        .then(
          res => {
            return _sendRequest(data)
          }
        )

      }
    )
    .then(
      res => {
        console.log(`deleteEntity ${_entityPlural} res.data ${JSON.stringify(res.data,null,2)}`)
        entity = res.data
        useCollectionStore.getState().deleteItem(_entityPlural, entity)
        return entity
      }
      , err => {
        return errorHandler(err)
      }
    )
  }

  const saveEntityNote = (entity: any, note: any) => {
    let data: any = {
      note: note,
    }
    data[`${entityName}_id`] = entity._id
    let isNewNote = !note._id
    if (isNewNote) {
      data.operation = `add${entityCap}Note`
      note.addedBy = profile?.user_id
    } else {
      data.operation = `update${entityCap}Note`
      note.modifiedBy = profile?.user_id
      note.updatedAt = Date.now()
    }
    return _sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem(_entityPlural, entity)
        return entity
      },
      err => {
        return errorHandler(err)
      }
    )
  }
  const deleteEntityNote = (entity: any, note: any) => {
    let data: any = {
      operation: `delete${entityCap}Note`,
      note: note,
    }
    data[`${entityName}_id`] = entity._id
    return _sendRequest(data)
    .then(
      res => {
        // console.log(`deleteCollectionItemNote ${key} res.data ${JSON.stringify(res.data,null,2)}`)
        entity = res.data
        useCollectionStore.getState().updateItem(_entityPlural, entity)
        return entity
      },
      err => {
        return errorHandler(err)
      }
    )
  }

  const saveEntityAttachment = (entity: any, attachment: any) => {
    let data: any = {
      attachment: attachment,
    }
    data[`${entityName}_id`] = entity._id
    let isNewAttachment = !attachment._id
    if (isNewAttachment) {
      data.operation = `add${entityCap}Attachment`
      attachment.addedBy = profile?.user_id
      attachment.ownerCode = entity.code
    } else {
      data.operation = `update${entityCap}Attachment`
      attachment.modifiedBy = profile?.user_id
      attachment.ownerCode = entity.code
    }
    return _sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem(_entityPlural, entity)
        return entity
      },
      err => {
        return errorHandler(err)
      }
    )
  }
  const deleteEntityAttachment = (entity: any, attachment: any) => {
    let data: any = {
      operation: `delete${entityCap}Attachment`,
      attachment: attachment,
    }
    data[`${entityName}_id`] = entity._id
    return _sendRequest(data)
    .then(
      res => {
        entity = res.data
        useCollectionStore.getState().updateItem(_entityPlural, entity)
        return entity
      },
      err => {
        return errorHandler(err)
      }
    )
  }

  const errorHandler = (err: any) => {
    let errorMessage = buildErrorMessage(err, null, _entityPlural)
    showAlert(i18n.t(errorMessage), 'E')
    return Promise.reject(err)
  }

  const _sendRequest = (data: any, cachePromise?: boolean) => {
    return apiClient.sendRequest(data, cachePromise, path, null, true)
  }

  return({
    getEntities: getEntities,
    saveEntity: saveEntity,
    deleteEntity: deleteEntity,
    saveEntityNote: saveEntityNote,
    deleteEntityNote: deleteEntityNote,
    saveEntityAttachment:saveEntityAttachment,
    deleteEntityAttachment:deleteEntityAttachment,
    errorHandler: errorHandler,
  })

}

export default TemplateService
