import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { ListItemButton } from '@mui/material/'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles'
import WrmIcon from '../../WrmIcon'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    height: 'auto',
    maxHeight: 'calc(100vh - 64px)',
  },
  menuItem: {
    marginLeft: '1rem',
  },
  listRoot:{
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 112px)'
  },
  selectedMenuItem:{
    textDecoration: 'underline cyan 8px'
  }
}))

export default function CPMobileNavigationDrawer({
  accessGranted,
  isOpen,
  toggleDrawer,
  verticalNavigate,
  setActiveList,
  activeList,
  logOut,
  activeContentKey
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [selected,setSelected] = React.useState(activeList)
  React.useEffect(()=>{
    if (activeContentKey==='cp.network') {
      setSelected('cp.network')
    } else {
      setSelected(activeList)
    }
  },[activeList,activeContentKey])

  const handleDrawerNavigation = (navKey: string) => {
    switch (navKey) {
      case 'cp.myAccount.acdtl':
        toggleDrawer(false)
        setActiveList('cp.myAccount.acdtl')
        verticalNavigate('cp.myAccount')
        break
      case 'cp.myAccount.accnsmp':
        toggleDrawer(false)
        setActiveList('cp.myAccount.accnsmp')
        verticalNavigate('cp.myAccount')
        break
      case 'cp.myAccount.acntf':
        toggleDrawer(false)
        setActiveList('cp.myAccount.acntf')
        verticalNavigate('cp.myAccount')
        break
      case 'cp.myAccount.acchurnm':
        toggleDrawer(false)
        setActiveList('cp.myAccount.acchurnm')
        verticalNavigate('cp.myAccount')
        break
      case 'cp.myAccount.acchurpsw':
        toggleDrawer(false)
        setActiveList('cp.myAccount.acchurpsw')
        verticalNavigate('cp.myAccount')
        break
      case 'cp.myAccount.changeLng':
        toggleDrawer(false)
        setActiveList('cp.myAccount.changeLng')
        verticalNavigate('cp.myAccount')
        break
      case 'cp.network':
        toggleDrawer(false)
        verticalNavigate('cp.network')
        break
      case 'cp.report':
        toggleDrawer(false)
        setActiveList('cp.report')
        verticalNavigate('cp.report')
        break
      case 'cp.report.newIssue':
        toggleDrawer(false)
        setActiveList('cp.report.newIssue')
        verticalNavigate('cp.report')
        break
      case 'logout':
        logOut('logout')
        break
      default:
        toggleDrawer(false)
        break
    }
  }

  const list = () => (
    <Box role='presentation'>
      <List className={classes.listRoot}>
        {/* ACCOUNT */}
        <ListItem divider>
          <ListItemIcon>
            <WrmIcon
              icon='myAccountMobile'
              style={{
                width: 32,
                height: 32,
                color: '#009494',
                strokeWidth: 2,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={t('cp.myAccount')}
            primaryTypographyProps={{ fontWeight: 'bold' }}
          />
          <IconButton onClick={e=>toggleDrawer(false)}>
            <ChevronRightIcon />
          </IconButton>
        </ListItem>

        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.myAccount.acdtl')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.myAccount.acdtl')} //account details
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.myAccount.acdtl' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        {accessGranted('VwMyCn1') && (
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.myAccount.accnsmp')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.myAccount.accnsmp')} //consumption
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.myAccount.accnsmp' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        )}
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.myAccount.acntf')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.myAccount.acntf')} //notifications
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.myAccount.acntf' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.myAccount.acchurnm')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.myAccount.acchurnm')} //: "Αλλαγή Κωδικού Χρήστη"
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.myAccount.acchurnm' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.myAccount.acchurpsw')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.myAccount.acchurpsw')} //: : "Αλλαγή Συνθηματικού",
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.myAccount.acchurpsw' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.myAccount.changeLng')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.myAccount.changeLng')} //: "Αλλαγή γλώσσας
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.myAccount.changeLng' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        {/* Issue Report */}
        {accessGranted('VwMyCn') && (
        <>
        <Divider />
        <ListItem divider>
          <ListItemIcon>
            <WrmIcon
              icon='reportAnIssue'
              style={{
                width: 32,
                height: 32,
                color: '#009494',
                strokeWidth: 2,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={t('cp.report')}
            primaryTypographyProps={{ fontWeight: 'bold' }}
          />
        </ListItem>
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.report')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.report.rplstcir')} // Υποβεβλημένες Βλάβες
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.report' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.report.newIssue')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.report.newIssueReport')} //create new issue report
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.report.newIssue' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        </>
        )}
        {/* Notifications */}
        <Divider />
        <ListItem divider>
          <ListItemIcon>
            <WrmIcon
              icon='networkUpdatesMobile'
              style={{
                width: 32,
                height: 32,
                color: '#009494',
                strokeWidth: 1.2,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={t('cp.network')}
            primaryTypographyProps={{ fontWeight: 'bold' }}
          />
        </ListItem>
        <ListItemButton
          onClick={() => handleDrawerNavigation('cp.network')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('cp.notifications')} // notifications
            primaryTypographyProps={{ marginLeft: '4.5rem' }}
            className={selected === 'cp.network' ? classes.selectedMenuItem : ''}
          />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => handleDrawerNavigation('logout')}
          className={classes.menuItem}>
          <ListItemText
            primary={t('signOut')} // "signOut"
            primaryTypographyProps={{
              marginLeft: '4.5rem',
              fontWeight: 'bold',
            }}
          />
        </ListItemButton>
      </List>
    </Box>
  )

  return (
    <div>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            height: 'auto',
            minWidth: '60vw',
            marginTop: '56px', //appBar height
            maxHeight: 'calc(100vh - 64px)',
          },
        }}
        className={classes.drawerPaper}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        anchor='right'>
        {list()}
      </Drawer>
    </div>
  )
}
