import { useState, useRef } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Box } from '@material-ui/core'

import PcChart from '../../presentationalComponents/graphs/PcChart'
import PrintButtonHDR from '../../presentationalComponents/PrintButtonHDR'
import DownloadImageButtonHDR from '../../presentationalComponents/DownloadImageButtonHDR'
import { alphabeticalSorter, formatSecondsToHoursMinuts } from '../../../utils/helpers'

const NUMBER_OF_GRAPH_X_AXIS_INTERVALS = 5

const graphWindowWidth = 1064
const useStyles = makeStyles((theme) => ({
  root:{
    padding:'0 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  headerLabel: {
    width: 150,
    fontSize: 14,
    fontWeight: 600,
  },
  headerValue: {
    width: 180,
    fontSize: 14,
    fontWeight: 400,
  },
  headerValueWide: {
    width: 360,
    fontSize: 14,
    fontWeight: 400,
  },
  arrowBtn: {
    textTransform: 'none',
    fontSize: '1.375rem',
    lineHeight: 1.375,
    fontWeight: 400,
    minHeight: theme.spacing(4),
    minWidth: theme.spacing(4),
    padding: 0,
    marginRight: 4,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '@media print': {
      display: 'none',
    },
  },
  graphContainerOverride: {
    backgroundColor: 'white',
    margin: '1.0rem 2.0rem',
    // padding: '0 50px 0 50px',
  },
  graphTitleOverride: {
    position: 'absolute',
    top: 140,
    left: `calc(${graphWindowWidth / 2}px - 250px)`,
    width: 500,
  },
  graphTooltip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'white',
    fontWeight: 400,
  },
  graphTooltipItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  graphTooltipItemNoDatLabel: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  graphTooltipFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

const IrrigationMeasurementsGraph = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const printRef = useRef<HTMLDivElement>(null)
  let chartRefs: any[] = []

  const [graphTypeIndex, setGraphTypeIndex] = useState(0)
  const _graphTypes: any[] = [
    {code: 'cultivatorHoursIP', entity: 'switch', chartType: 'bar', layout: 'vertical'},
    {code: 'cultivatorPctsIP', entity: 'switch', chartType: 'pie'},
    {code: 'dailyHoursIP', entity: 'switch', chartType: 'bar'},
    {code: 'dailyHoursHM', entity: 'switchSlot', chartType: 'bar'},
    {code: 'totalHoursHM', entity: 'switchSlot', chartType: 'bar'},
    {code: 'dailyHoursCL', entity: 'cultivation', chartType: 'bar'},
    {code: 'totalHoursCL', entity: 'cultivation', chartType: 'bar'},
    {code: 'cultivationHoursLP', entity: 'landparcel', chartType: 'bar', layout: 'vertical'},
    {code: 'dailyHoursLP', entity: 'landparcel', chartType: 'bar'},
    {code: 'totalHoursLP', entity: 'landparcel', chartType: 'bar'},
    {code: 'cultivationHoursCN', entity: 'consumer', chartType: 'bar'},
    {code: 'cultivatorHoursCN', entity: 'consumer', chartType: 'bar', stacked: true},
  ]

  let graphTypes: any[] = _graphTypes.filter(gt => gt.entity === props.parentEntityType)
  // let graphTypes: any[] = _graphTypes
  const graphCnt = graphTypes.length
  const handleGraphTypeClick = (event, val) => {
    let newix = graphTypeIndex + val
    if (newix === -1) {
      newix = graphCnt - 1
    } else {
      newix = newix % graphCnt
    }
    setGraphTypeIndex(newix)
  }

  const addDuration = (dataMap: any, ms: moment.Moment, me: moment.Moment, dataKey?: string) => {
    let dur = me.diff(ms, 'seconds')
    let key: number | string = ms.dayOfYear()
    if (!dataMap[key]) {
      let newItem: any = {
        dayOfYear: key,
        dayStart: ms.clone().startOf('day').valueOf(),
        daySecs: 0,
        totalSecs: 0,
      }
      if (dataKey) {
        delete newItem.daySecs
        delete newItem.totalSecs
        newItem[dataKey] = 0
        // newItem[`${dataKey}_total`] = 0
      }
      dataMap[key] = newItem
    }
    let item = dataMap[key]
    if (dataKey) {
      if (!item[dataKey] && item[dataKey] !== 0) {
        item[dataKey] = 0
        // item[`${dataKey}_total`] = 0
      }
      item[dataKey] = item[dataKey] + dur
    } else {
      item.daySecs = item.daySecs + dur
    }
  }
  const prepareDataPerDay = (series: any[]): any[] => {
    const dataMap: any = {}
    series.forEach(s => {
      s.measurements.forEach(m => {
        let ms = moment(m.extraValues.startDatetime)
        let me = moment(m.refDatetime)
        while (ms.dayOfYear() !== me.dayOfYear()) {
          let tme = ms.clone().endOf('day')
          addDuration(dataMap, ms, tme)
          ms = ms.add(1, 'day').startOf('day')
        }
        addDuration(dataMap, ms, me)
      })
    })
    let total = 0
    const data: any[] = Object.values<any>(dataMap)
    .sort((a, b) => a.dayOfYear - b.dayOfYear)
    .map(v => {
      total = total + v.daySecs
      v.totalSecs = total
      return v
    })

    //add zero-value measurements for intermediate days
    const enhancedData: any[] = []
    if (data.length > 0) {
      let prev: any = data[0]
      data.forEach(d => {
        while (d.dayOfYear - prev.dayOfYear > 1) {
          let newItem: any = {
            dayOfYear: prev.dayOfYear + 1,
            dayStart: moment(prev.dayStart).clone().add(1, 'days').valueOf(),
            daySecs: 0,
            totalSecs: prev.totalSecs,
          }
          enhancedData.push(newItem)
          prev = newItem
        }
        enhancedData.push(d)
        prev = d
      })
    }
    return enhancedData
    // return data
  }
  const prepareDataPerDayAndCultivation = (series: any[]): any[] => {
    const dataMap: any = {}
    series.forEach(s => {
      s.measurements.forEach(m => {
        let dataKey = `${props.parentEntityLabel} - ${m.extraValues.cultivationLabel}`
        let ms = moment(m.extraValues.startDatetime)
        let me = moment(m.refDatetime)
        while (ms.dayOfYear() !== me.dayOfYear()) {
          let tme = ms.clone().endOf('day')
          addDuration(dataMap, ms, tme, dataKey)
          ms = ms.add(1, 'day').startOf('day')
        }
        addDuration(dataMap, ms, me, dataKey)
      })
    })
    const data: any[] = Object.values<any>(dataMap)
    .sort((a, b) => a.dayOfYear - b.dayOfYear)

    //add zero-value measurements for intermediate days
    const enhancedData: any[] = []
    if (data.length > 0) {
      let prev: any = data[0]
      data.forEach(d => {
        while (d.dayOfYear - prev.dayOfYear > 1) {
          let newItem: any = {
            dayOfYear: prev.dayOfYear + 1,
            dayStart: moment(prev.dayStart).clone().add(1, 'days').valueOf(),
            'noData': 0,
          }
          enhancedData.push(newItem)
          prev = newItem
        }
        enhancedData.push(d)
        prev = d
      })
    }
    return enhancedData
    // return data
  }
  const prepareDataPerCultivation = (series: any[]): any[] => {
    const dataMap: any = {}
    series.forEach(s => {
      s.measurements.forEach(m => {
        let key = m.extraValues.cultivationCode
        if (!dataMap[key]) {
          dataMap[key] = {
            cultivation: `${m.extraValues.cultivator} - ${m.extraValues.cultivationLabel}`,
            totalSecs: 0,
          }
        }
        let item = dataMap[key]
        item.totalSecs = item.totalSecs + m.extraValues.duration
      })
    })
    const data: any[] = Object.values<any>(dataMap)
    .sort((a, b) => alphabeticalSorter(a.cultivation, b.cultivation))
    return data
  }
  const prepareDataPerCultivator = (series: any[]): any[] => {
    const dataMap: any = {}
    series.forEach(s => {
      s.measurements.forEach(m => {
        let key = m.extraValues.cultivator ? m.extraValues.cultivator : ` ${t('irri.graph.consumer.unknown')}` //prepend with an empty space to short first
        if (!dataMap[key]) {
          dataMap[key] = {
            cultivator: key,
            totalSecs: 0,
          }
        }
        let item = dataMap[key]
        item.totalSecs = item.totalSecs + m.extraValues.duration
      })
    })
    let data: any[] = Object.values<any>(dataMap)
    let total = data.reduce((tot, d) => tot + d.totalSecs, 0)
    data = data.map(d => {
      let g: any = {...d}
      let pct = (d.totalSecs / total) * 100
      const options = {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }
      g.pct = `${new Intl.NumberFormat('el', options).format(pct)}%`
      return g
    })
    .sort((a, b) => alphabeticalSorter(a.cultivator, b.cultivator))
    return data
  }

  const mtickDateFormatter = (timestamp: number) => {
    return moment(timestamp).format(t('date.format')!)
  }
  const tickDateFormatter = secs => {
    return moment(secs).format(t('date.format')!)
  }

  function formatSecondsToHours(seconds: number): number {
    seconds = Number(seconds)
    if (!seconds || isNaN(+seconds)) {
      return 0
    }
    return seconds / 3600
  }
  const getDefaultLinearMax = (dataMax: number): number => {
    let ceil = Math.ceil(formatSecondsToHours(dataMax))
    let pow = ceil.toString().length - 1
    let max = (parseInt(ceil.toString().substring(0, 1)) + 1) * Math.pow(10, pow)
    max = max * 3600
    if (dataMax / max < 0.6) {
      max = max * 0.6
    }
    return max
  }
  const getDefaultLinearMaxFromData = (data: any[], dataKeys: string[], dataStackIds?: string[]): number => {
    if (!data || data.length === 0) return 0
    let max = data[0][dataKeys[0]] || 0
    data.forEach(d => {
      let val: number
      let stackVals: any = {}
      dataKeys.forEach((dk, i) => {
        if (d[dk]) {
          if (dataStackIds) {
            if (!stackVals[dataStackIds[i]]) {
              stackVals[dataStackIds[i]] = 0
            }
            stackVals[dataStackIds[i]] = stackVals[dataStackIds[i]] + d[dk]
            val = stackVals[dataStackIds[i]]
          } else {
            val = d[dk]
          }
          max = Math.max(max, val)
        }
      })
    })
    return getDefaultLinearMax(max)
  }

  const dateHeaderFunction = (payload: any[], headerKey: string) => {
    let header: string = '--'
    if (payload && payload.length) {
      header = moment(payload[0].payload[headerKey]).format(t('date.format')!)
    }
    return header
  }
  const totalFooterFunction = (payload: any[], totalKeys: string[]) => {
    let footer: string = '--'
    if (payload && payload.length) {
      let total = 0
      totalKeys.forEach(k => {
        if (payload[0].payload[k]) {
          total += payload[0].payload[k]
        }
      })
      footer = total.toString()
    }
    return footer
  }
  const IrriCustomTooltip = ctprops => {
    const { t } = useTranslation()
    const { active, payload } = ctprops
    if (active && payload && payload.length) {
      // console.log(`IrriCustomTooltip payload ${JSON.stringify(payload,null,2)}`)
      return (
        <Box
          className={classes.graphTooltip}
        >
          {ctprops.headerFunction &&
          <Box>{ctprops.headerFunction(payload)}</Box>
          }
          {payload.map(pl => {
            let val = pl.value
            if (!val && val !== 0 && !!pl.payload) {
              val = pl.payload[pl.dataKey]
            }
            if (!!ctprops.valueFormatter) {
              val = ctprops.valueFormatter(val)
            }
            return (
          <Box key={pl.dataKey} className={pl.name === 'no-label' ? classes.graphTooltipItemNoDatLabel : classes.graphTooltipItem}>
            {pl.name !== 'no-label' && `${pl.name}:`}
            <strong style={{paddingLeft: 4, color: pl.color}}>{val}</strong>
          </Box>
            )
          })}
          {ctprops.footerFunction &&
          <Box
            className={classes.graphTooltipFooter}
          >
            {t('irri.graph.cultivation.total')}
            <strong style={{color: '#666'}}>{ctprops.valueFormatter ? ctprops.valueFormatter(ctprops.footerFunction(payload)) : ctprops.footerFunction(payload)}</strong>
          </Box>
          }
        </Box>
      )
    }
    return null
  }
  const IrriCustomPieTooltip = ctprops => {
    const { t } = useTranslation()
    const { active, payload } = ctprops
    if (active && payload && payload.length) {
      // console.log(`IrriCustomPieTooltip payload ${JSON.stringify(payload,null,2)}`)
      return (
        <Box
          className={classes.graphTooltip}
        >
          {ctprops.headerFunction &&
          <Box>{ctprops.headerFunction(payload)}</Box>
          }
          <Box className={classes.graphTooltipItem}>
            {`${t('irri.graph.consumer.pct')}:`}
            <strong style={{paddingLeft: 4, color: payload[0].payload.fill}}>{payload[0].payload.pct}</strong>
          </Box>
        </Box>
      )
    }
    return null
  }
  const IrriBarChart = (props) => {
    let dataMaxY = getDefaultLinearMaxFromData(props.data, props.dataKeys, props.dataStackIds)
    const domainY = props.xAxisDataKey === 'dayStart' ? [0, dataMaxY] : null
    let step = Math.floor(dataMaxY / 10)
    let remain = dataMaxY - step
    let ticks: number[] = [dataMaxY, remain]
    while (step < remain) {
      remain = remain - step
      ticks.push(remain)
    }
    ticks.push(0)
    const customTooltip = props.chartType === 'pie' ? (
      <IrriCustomPieTooltip
        valueFormatter={props.customTooltipValueFormatter}
        headerFunction={props.customTooltipHeaderFunction}
        footerFunction={props.customTooltipFooterFunction}
      />
    ) : (
      <IrriCustomTooltip
        valueFormatter={props.customTooltipValueFormatter}
        headerFunction={props.customTooltipHeaderFunction}
        footerFunction={props.customTooltipFooterFunction}
      />
    )
    return (
      <Box
        display='flex'
        flexDirection='row'
        flexWrap='wrap'
        overflow='auto'
        width={graphWindowWidth}
      >
        <PcChart
          width={1000}
          height={400}
          title={props.title}
          data={props.data}
          dataKeys={props.dataKeys}
          dataLabels={props.dataLabels}
          dataStackIds={props.dataStackIds}
          xAxisDataKey={props.xAxisDataKey}
          yAxisWidth={props.yAxisWidth}
          tickCount={11}
          ticks={ticks}
          // scaleY='linear'
          domainY={domainY}
          tickFormatterX={props.tickFormatterX}
          tickFormatterY={props.tickFormatterY}
          customTooltip={customTooltip}
          noLegend={!props.withLegend}
          legendFormatter={props.legendFormatter}
          layout={props.layout}
          chartType={props.chartType}
          maxBars={30}
          // maxBars={10}
          maxBarSize={50}
          maxHorizontalTickLabels={props.maxHorizontalTickLabels}
          brushExtraRightMargin={64} // We add 64px so there's enough space for a date
          classesXtra={{graphContainer: classes.graphContainerOverride}}
          // classesXtra={{graphContainer: classes.graphContainerOverride, graphTitle: classes.graphTitleOverride}}
        />
      </Box>
    )
  }
  const pieLegendFormatter = (value, entry: any) => {
    // let pct = entry?.payload?.percent * 100
    // const options = { minimumFractionDigits: 1, maximumFractionDigits: 1, }
    // let legentpct = `${new Intl.NumberFormat('el', options).format(pct)}%`
    // return `${entry?.payload?.cultivator} - ${entry?.payload?.pct}`
    return <span style={{marginLeft: 4, position: 'relative', top: 2, color: '#666', fontSize: 12}}>{`${entry?.payload?.cultivator}`}</span>
  }

  // let {entityLabel, periodFrom, periodTo} = {...props.subtitle}
  let graphType: any = graphTypes.length > 0 ? graphTypes[graphTypeIndex] : null

  let data: any[]
  let dataKeys: string[]
  let dataLabels: string[]
  let chartCompo: any
  switch (graphType.code) {
    case 'dailyHoursIP':
      data = prepareDataPerDay(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['daySecs']}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          yAxisWidth={80}
        />
      )
      break;
    case 'cultivatorHoursIP':
      data = prepareDataPerCultivator(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['totalSecs']}
          xAxisDataKey='cultivator'
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => payload[0].payload['cultivator']}
          yAxisWidth={200}
        />
      )
      break;
    case 'cultivatorPctsIP':
      data = prepareDataPerCultivator(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['totalSecs']}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => payload[0].payload['cultivator']}
          customTooltipFooterFunction={(payload) => payload[0].payload['totalSecs']}
          withLegend
          legendFormatter={pieLegendFormatter}
        />
      )
      break;
    case 'dailyHoursHM':
      data = prepareDataPerDay(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['daySecs']}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          yAxisWidth={80}
        />
      )
      break;
    case 'totalHoursHM':
      data = prepareDataPerDay(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['totalSecs']}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          yAxisWidth={80}
        />
      )
      break;
    case 'dailyHoursCL':
      data = prepareDataPerDay(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['daySecs']}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          yAxisWidth={80}
        />
      )
      break;
    case 'totalHoursCL':
      data = prepareDataPerDay(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['totalSecs']}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          yAxisWidth={80}
        />
      )
      break;
    case 'dailyHoursLP':
      data = prepareDataPerDay(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['daySecs']}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          yAxisWidth={80}
        />
      )
      break;
    case 'totalHoursLP':
      data = prepareDataPerDay(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['totalSecs']}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          yAxisWidth={80}
        />
      )
      break;
    case 'cultivationHoursLP':
      data = prepareDataPerCultivation(props.series)
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          // dataLabels={[t('units.time.hour')]}
          dataLabels={['no-label']}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={['totalSecs']}
          xAxisDataKey='cultivation'
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => payload[0].payload['cultivation']}
          yAxisWidth={250}
        />
      )
      break;
    case 'cultivationHoursCN':
      data = prepareDataPerDayAndCultivation(props.series)
      dataKeys = !(data?.length > 0)
        ? []
        : Array.from<string>(data.reduce(
            (acc, d) => Object.keys(d).reduce((acc2, dk) => acc2.add(dk), acc), new Set()
          ))
          .filter(k => !['dayOfYear', 'dayStart', 'noData'].includes(k) && !k.endsWith('total'))
          .sort((a, b) => alphabeticalSorter(a, b))
      dataLabels = dataKeys
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          dataLabels={dataLabels}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={dataKeys}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          maxHorizontalTickLabels={6}
          withLegend
        />
      )
      break;
    case 'cultivatorHoursCN':
      data = prepareDataPerDayAndCultivation(props.series)
      dataKeys = !(data?.length > 0)
        ? []
        : Array.from<string>(data.reduce(
            (acc, d) => Object.keys(d).reduce((acc2, dk) => acc2.add(dk), acc), new Set()
          ))
          .filter(k => !['dayOfYear', 'dayStart', 'noData'].includes(k) && !k.endsWith('total'))
          .sort((a, b) => alphabeticalSorter(a, b))
      dataLabels = dataKeys
      let dataStackIds: string[] = dataKeys.map(dk => 'singleStack')
      chartCompo = (
        <IrriBarChart
          title={t(`irri.graph.type.${graphType.code}.title`)}
          dataLabels={dataLabels}
          layout={graphType.layout}
          chartType={graphType.chartType}

          data={data}
          dataKeys={dataKeys}
          dataStackIds={dataStackIds}
          xAxisDataKey='dayStart'
          tickFormatterX={mtickDateFormatter}
          tickFormatterY={formatSecondsToHoursMinuts}
          customTooltipValueFormatter={formatSecondsToHoursMinuts}
          customTooltipHeaderFunction={(payload) => dateHeaderFunction(payload, 'dayStart')}
          customTooltipFooterFunction={(payload) => totalFooterFunction(payload, dataKeys)}
          maxHorizontalTickLabels={6}
          withLegend
        />
      )
      break;
    default:
      break;
  }

  return graphTypes.length === 0 ? null :  (
    <>
    <div className={classes.root} ref={printRef} >
      <Box
        p={1}
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Box className={classes.headerLabel} >{t(`irri.general.period`)}</Box>
        <Box className={classes.headerValue} >{`${t(`com.fromDate`)}: ${props.subtitle.periodFrom}`}</Box>
        <Box className={classes.headerValue} >{`${t(`com.toDate`)}: ${props.subtitle.periodTo}`}</Box>
      </Box>
      <Box
        p={1}
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
        width='100%'
      >
        <Box className={classes.headerLabel} >{t(`irri.graph.${props.parentEntityType}`)}</Box>
        <Box className={classes.headerValueWide} >{`${props.parentEntityLabel}`}</Box>
        <Box
          ml='auto'
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
        >
          {graphCnt > 1 &&
          <Button
            className={classes.arrowBtn}
            disabled={graphTypeIndex === 0}
            onClick={(e) => handleGraphTypeClick(e, -1)}
          >
            &lt;
          </Button>}
          {graphCnt > 1 &&
          <Button
            className={classes.arrowBtn}
            disabled={graphTypeIndex === graphCnt - 1}
            onClick={(e) => handleGraphTypeClick(e, 1)}
          >
            &gt;
          </Button>}
          { graphCnt > 1 && (
            <>
              <PrintButtonHDR ref={printRef} />
              <DownloadImageButtonHDR elementsRefs={chartRefs} />
            </>)
          }   
        </Box>
      </Box>
      <div ref={chart => chartRefs.push(chart)}>
        {chartCompo}
      </div>
    </div>
    </>
  )
}

export default IrrigationMeasurementsGraph
