import React, { Fragment } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/'
import { Box, List, ListItem, ListItemText, ListItemIcon, Collapse, SvgIcon } from '@material-ui/core/'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import WrmIcon from '../WrmIcon'
import FeaturePropsFilter from './FeaturePropsFilter'

const useStyles = makeStyles(theme => ({
  byFeaturePropsRoot: {
    display: 'flex',
    flexFlow: 'column',
  },
  contentRaw: {
    display: 'flex',
    width: '100%',
  },
  list: {
    width: '100%',
    fontSize: 14,
  },
  entIcon: {
    color: theme.palette.primary.light,
  },
  chevronIcon: {
    fill: 'none',
    width: 10,
    height: 6,
    marginRight: 8,
  },
  li: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 8,
    marginBottom: 8,
    background: theme.palette.tertiary.light,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  lichk: {
    padding: 2,
  },
  litxt: {},
  litxtpri: {
    fontSize: 14,
  },
}))

interface SearchByFeatureAttributes {
  handleCheckOrExpandedChange: (featureType: string, field: string, value: any) => void
  handleFeatureFilterChange: (featureType: string, featureTypeFilter: any) => void
  featuresFilterLocal: any
  metadata: any
  lookups: any
}
export default function SearchByFeatureProps({
  handleCheckOrExpandedChange,
  handleFeatureFilterChange,
  featuresFilterLocal,
  metadata,
  lookups,
}: SearchByFeatureAttributes) {
  const classes = useStyles()
  const { t } = useTranslation()

  const ChevronIcon = ciprops => {
    return ciprops.open ? (
      <SvgIcon viewBox='0 0 10 6' classes={{ root: classes.chevronIcon }}>
        <path d='M9 4.89941L5.1 1.09941' stroke='#BABABA' strokeWidth='1.9843' strokeLinecap='round' />
        <path d='M5.1 1.1L1.2 5' stroke='#BABABA' strokeWidth='1.9843' strokeLinecap='round' />
      </SvgIcon>
    ) : (
      <SvgIcon viewBox='0 0 10 6' classes={{ root: classes.chevronIcon }}>
        <path d='M1 1.1001L4.9 4.9001' stroke='#BABABA' strokeWidth='1.9843' strokeLinecap='round' />
        <path d='M4.89999 4.9L8.79999 1' stroke='#BABABA' strokeWidth='1.9843' strokeLinecap='round' />
      </SvgIcon>
    )
  }

  return !metadata ? null : (
    <Box className={classes.byFeaturePropsRoot}>
      <Box className={classes.contentRaw}>
        <List className={classes.list}>
          {Object.keys(metadata)
            .filter(mk => metadata[mk].length > 0)
            .map((mk, i) => {
              return (
                <Fragment key={mk}>
                  <ListItem className={classes.li} button>
                    <ListItemIcon>
                      {mk !== 'generic' && (
                        <CheckBoxNt
                          classes={{ root: classes.lichk }}
                          checked={
                            featuresFilterLocal[mk].checked === undefined ? false : featuresFilterLocal[mk].checked
                          }
                          onChange={e => handleCheckOrExpandedChange(mk, 'checked', e.target.checked)}
                          // onChange={(e) => {e.stopPropagation(); handleCheckOrExpandedChange(mk, 'checked', e.target.checked);}}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemIcon
                      style={{ minWidth: 'unset', marginRight: 8 }}
                      onClick={e => handleCheckOrExpandedChange(mk, 'expanded', !featuresFilterLocal[mk].expanded)}
                    >
                      <WrmIcon icon={mk} className={classes.entIcon} />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.litxt}
                      classes={{ primary: classes.litxtpri }}
                      primary={t(`net.${mk}.plural`)}
                      onClick={e => handleCheckOrExpandedChange(mk, 'expanded', !featuresFilterLocal[mk].expanded)}
                    />
                    <ListItemIcon
                      style={{ minWidth: 'unset' }}
                      onClick={e => handleCheckOrExpandedChange(mk, 'expanded', !featuresFilterLocal[mk].expanded)}
                    >
                      <ChevronIcon open={featuresFilterLocal[mk].expanded} alt='' />
                    </ListItemIcon>
                  </ListItem>
                  <Collapse
                    in={featuresFilterLocal[mk].expanded}
                    timeout='auto'
                    // unmountOnExit
                  >
                    <Box display='flex' flexDirection='column' alignItems='center'>
                      <FeaturePropsFilter
                        meta={{ columns: metadata[mk] }}
                        itemFilter={featuresFilterLocal[mk]}
                        lookups={lookups}
                        onFilterChange={filter => handleFeatureFilterChange(mk, filter)}
                      />
                    </Box>
                  </Collapse>
                </Fragment>
              )
            })}
        </List>
      </Box>
    </Box>
  )
}
