const InvoiceService = apiClient => {
  const path = 'invoice/'

  const getInvoices = (filter?) => {
    let data: any = {
      operation: 'getInvoices',
    }
    if (filter) {
      data.filter = filter
    }
    return sendRequest(data)
  }

  const saveInvoices = (invoices, fileMeta,doNotCreateFileImportSerie) => {
    let data: any = {
      invoices,
      fileMeta,
      operation: 'saveInvoices',
      doNotCreateFileImportSerie:doNotCreateFileImportSerie,
    }
    return sendRequest(data)
  }

  const deleteInvoiceByID = invoice => {
    let data = {
      operation: 'deleteInvoiceByID',
      invoice,
    }
    return sendRequest(data)
  }

  const deleteInvoicesByUploadedFileID = uploadedFileID => {
    let data = {
      operation: 'deleteInvoicesByUploadedFileID',
      uploadedFileID,
    }
    return sendRequest(data)
  }

  const sendRequest = data => {
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return {
    getInvoices: getInvoices,
    saveInvoices: saveInvoices,
    deleteInvoiceByID: deleteInvoiceByID,
    deleteInvoicesByUploadedFileID: deleteInvoicesByUploadedFileID,
  }
}

export default InvoiceService
