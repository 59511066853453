import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
} from '@material-ui/core/'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingTop: 6,
      paddingBottom: 6,
      fontSize: 14,
      textAlign: 'left',
    },
    autoRoot: {
      minWidth: 350,
      maxWidth: 350,
      textAlign: 'left',
    },
    autoInputRoot: {
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
    },
    autoInput: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      fontSize: 14,
    },
    autoPopper: {
      whiteSpace: 'pre-wrap',
    },
    option: {
      '&:hover': {
        backgroundColor: '#DBFFFF',
      },
    },
    autoList: {
      fontSize: 14,
    },
  })
)

export interface Props {
  options: any[]
  onChange: (option: any, ...args:any) => void
  getOptionSelected: (option: any, value: any) => boolean
  getOptionLabel:((option: any) => string) | undefined
  groupBy?: (option:any) => string
  value: any
  getOptionDisabled?: ((option: any, ...args:any) => boolean) | undefined
  multiple?: boolean
  fullWidth?: boolean
  noOptionsText?: string
  rootStyle?:any
  disabled?:boolean
  disableCloseOnSelect?: boolean
  classesOverrides?: any
  blurOnSelect?: boolean
  size?: 'small' | 'medium'
  chipVariant?: 'default' | 'outlined'
  limitTags?: number
}

const AutocompleteHDR: React.FC<Props> = ({
  options,
  getOptionSelected,
  onChange,
  getOptionLabel,
  value,
  getOptionDisabled = undefined,
  groupBy = undefined,
  multiple = true,
  fullWidth = false,
  noOptionsText = '',
  rootStyle={},
  disabled=false,
  disableCloseOnSelect=true,
  classesOverrides,
  blurOnSelect=false,
  size='medium',
  chipVariant='default',
  limitTags=-1,
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Autocomplete
      size={size}
      options={options}
      limitTags={limitTags}
      onChange={onChange}
      multiple={multiple}
      fullWidth={fullWidth}
      className={classesOverrides?.autoRoot ? classesOverrides.autoRoot : classes.autoRoot}
      disabled={disabled}
      style={rootStyle}
      classes={{
        inputRoot: !!classesOverrides?.autoInputRoot ? classesOverrides.autoInputRoot : classes.autoInputRoot,
        input: classes.autoInput,
        popper: classes.autoPopper,
        listbox: classes.autoList,
        option: classes.option,
      }}
      ChipProps={{
        variant: chipVariant,
        // size: 'small',
        // color: 'secondary',
        // classes: {
        //   root: classes.autoChipRoot,
        // }
      }}
      clearOnEscape
      disableCloseOnSelect={disableCloseOnSelect}
      noOptionsText={
        !!noOptionsText ? t(noOptionsText) : t('md.autocomplete.noOptions')
      }
      getOptionSelected={getOptionSelected} //{(o, v) => o.code === v.code}
      getOptionDisabled={getOptionDisabled} //{o => !o.code}
      groupBy={groupBy}
      value={value}
      getOptionLabel={getOptionLabel}//{(option: any) => option.label}
      blurOnSelect={blurOnSelect}
      renderInput={params => <TextField {...params} variant='outlined' />}
    />
  )
}
export default AutocompleteHDR