export class SimulationNew {
    title: string
    core: any
    consumptionOptions: any
    junctions: any
    reservoirs: any
    tanks: any
    pipes: any
    pumps: any
    valves: any
    controls: any
}

export class Simulation {
    _id: string
    tenantCode: string
    code: string
    title: string
    core: any
    consumptionOptions: any
    junctions: any
    reservoirs: any
    tanks: any
    pipes: any
    pumps: any
    valves: any
    controls: any
}

export interface EpanetSimulationNoteAdd {
    simulation_id: string,
    text: string
}
