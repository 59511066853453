import useReproject from './useReproject'
import geoUtils from './geometry_utils'

/**useCoords returns a function(XYZ)
 * XYZ Reprojects point coordinates from the default wgs84 to the chosen coordinate system
 * use XYZ to wrap a set of coordinates: XYZ([number,number]) in order to format the coords based on coordinate system
 * @param coordinatesSystem string ( 'wgs84'|| 'wgs84DMS' || 'egsa87')
 * @param coords [number,number] an array of two numbers the structure must be [lng, lat]
 *
 * by default it trims the coords to 7 decimal places (wgs) and 3 decimal places (egsa87)
 * useCoords is used only for display purposes
 * !Important wgs84DMS returns coordinates as an array of strings not numbers!
 */
const useCoords = () => {
  const R = useReproject()
  const XYZ = (coordinatesSystem: string, coords: number[]) => {
    switch (coordinatesSystem) {
      case 'wgs84':
        return coords.map(n => +n.toFixed(7))
      case 'wgs84DMS':
        return geoUtils.toDMS(coords)
      case 'egsa87':
        return R(coords).map(n => +n.toFixed(3))
      default:
        return coords
    }
  }
  return XYZ
}

export default useCoords
