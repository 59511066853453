import TemplateService from './TemplateService'

const DeviceLinkService = () => {

  const templateService: any = TemplateService('deviceLink')

  return({
    getEntities: templateService.getEntities,
    saveEntity: templateService.saveEntity,
    deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  })

}

export default DeviceLinkService
