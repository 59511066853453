import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, List } from '@material-ui/core';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Box from '@material-ui/core/Box/Box';
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    justifyContent: 'center',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  },
  lilab: {
    display: 'flex',
    minWidth: 150,
    maxWidth: 150,
    fontWeight: 700,
  },
  lival: {
    minWidth: 370,
    maxWidth: 370,
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  dlgpaper: {
    maxWidth: '80vw',
  },
  dlgcontroot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.tertiary.light}`,
    borderBottom: `solid 1px ${theme.palette.tertiary.light}`,
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.tertiary.main,
  },
  clearBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.primary.main,
    marginRight: 'auto',
  },
}));

export default function MeasurementsFilter(props) {
  const { t } = useTranslation()
  const classes = useStyles();

  const [filter, setFilter] = useState(props.loadCurrentFilter)

  useEffect(() => {
    if (!!props.series) {
      setFilter(props.loadCurrentFilter)
    }
  }, [props.series])

  let localFilter = {...filter}

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(`event.target.value ${JSON.stringify(event.target.value)}`)
    let updatedFilter = { ...filter }
    let selections = event.target.value as string[]

   selections.forEach(element => {
     if(!!element){
      let index = updatedFilter.series.findIndex(s=> s._id === element)
      updatedFilter.series[index].selected = true
     }
    });
    updatedFilter.series.forEach((s, i) =>{
      let index = selections.findIndex(element=>s._id === element)
      if (index ===-1){
        updatedFilter.series[i].selected = false
      }
    })
    // console.log(`updatedFilter ${JSON.stringify(updatedFilter)}`)
    localFilter = updatedFilter
    setFilter(updatedFilter)
  }


  const handlerefDatetimeFromChange = (date) => {
    if (!date?.isValid()) return
    let updatedFilter = {...filter }
    updatedFilter['refDatetimeFrom'] = date?.toDate()//date is a moment
    localFilter = updatedFilter
    // console.log(`handlerefDatetimeFromChange updatedFilter ${JSON.stringify(updatedFilter)}`)
    setFilter(updatedFilter)
  }

  const handlerefDatetimeToChange = (date) => {
    if (!date?.isValid()) return
    let updatedFilter = { ...filter }
    updatedFilter['refDatetimeTo'] = date.endOf('day').toDate()
    localFilter = updatedFilter
    // console.log(`handlerefDatetimeToChange updatedFilter ${JSON.stringify(updatedFilter)}`)
    setFilter(updatedFilter)
  }

  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => (reason === 'backdropClick') ? null : props.handleCloseDialog(null)}
      classes={{ paper: classes.dlgpaper }}
    >
      <DialogTitle >{t('msr.dlg.filters.title')}</DialogTitle>
      <DialogContent
        classes={{ root: classes.dlgcontroot }}
      >
        <List>
          <ListItem className={classes.li}>
            <Box className={classes.lilab}>
              {t('msr.type')}
            </Box>
            <FormControl className={classes.lival}>
              <Select
                variant="outlined"
                //displayEmpty
                multiple
                fullWidth
                classes={{ root: classes.input, }}
                value={!!filter.series ? filter.series.filter(s => s.selected).map(s => s._id) : []}
                onChange={event => handleChange(event)}
              >
                {props.seriesMTypes.map(s =>
                <MenuItem key={s._id} value={s._id}>{t(`msr.fld.${s.featureType === 'switch' ? 'irrigation.' : ''}${s.measurementType.code}`, s.measurementType.label)}</MenuItem>
                )}
                {props.seriesMTypes.length === 0 && (
                <MenuItem key={-1} value={-1} disabled>{t('msr.graph.alert.noSeriesForSelectedElementsAndMeasurementTypes')}</MenuItem>
                )}
              </Select>
            </FormControl>
          </ListItem>

          <ListItem className={classes.li}>
            <MuiPickersUtilsProvider utils={MomentUtils} >
              <Box display='flex' flexGrow={1} justifyContent='space-between'>
                <KeyboardDatePicker
                  autoOk={true}
                  emptyLabel={t('date.format.pickers.emptyLabel')!}
                  disableToolbar
                  variant="inline"
                  format={t('date.format.pickers')!}
                  margin="normal"
                  label={t('msr.filter.refDatetimeFrom')}
                  value={!!localFilter.refDatetimeFrom ? localFilter.refDatetimeFrom : null}
                  onChange={handlerefDatetimeFromChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  autoOk={true}
                  emptyLabel={t('date.format.pickers.emptyLabel')!}
                  disableToolbar
                  variant="inline"
                  format={t('date.format.pickers')!}
                  margin="normal"
                  label={t('msr.filter.refDatetimeTo')}
                  value={!!localFilter.refDatetimeTo ? localFilter.refDatetimeTo  : null}
                  onChange={handlerefDatetimeToChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
             </Box>
            </MuiPickersUtilsProvider>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
      <Button
          variant='outlined'
          className={classes.clearBtn}
          onClick={() => {
            //props.setIsMeasurementsFilterEnabled(false)
            setFilter(props.clearFilter())
            }
          }
        >
          {t('btn.clear')}
        </Button>
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          onClick={() => props.handleCloseDialog()}
        >
          {t('btn.cancel')}
        </Button>
        <Button
          className={classes.applyBtn}
          onClick={() => {
            //props.setIsMeasurementsFilterEnabled(true)
            props.handleCloseDialog(filter)}
          }
        >
          {t('btn.apply')}
        </Button>


      </DialogActions>
    </Dialog>
  )
}
