import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxNt from '../reusableComponents/CheckBoxNt'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from '@material-ui/pickers'

import { alphabeticalSorter } from '../../utils/helpers'

const AUTOCOMPLETE_OPTIONS_MAX_SIZE: number = 2000

const useStyles = makeStyles(theme => ({
  root: {
  },
  lival: {
    minWidth: 250,
    maxWidth: 250,
  },
  input: {
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 14,
  },
  multiline: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
  },
  inputKeyboard: {
    padding: '0px !important',
  },
  autoInputRoot: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
  },
  autoInput: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    fontSize: 14,
  },
  autoPopper: {
    whiteSpace: 'pre-wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  title: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.tertiary.dark,
  },
  content: {
    borderTop: `thin solid ${theme.palette.tertiary.light}`,
    borderBottom: `thin solid ${theme.palette.tertiary.light}`,
  },
  li: {
    padding: theme.spacing(0.5),
  },
  lilab: {
    minWidth: 170,
    maxWidth: 170,
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 700,
    width: theme.spacing(12),
    color: theme.palette.tertiary.main,
  },
  loadingBtn: {
    position: 'absolute',
    height: '2rem !important',
    width: '2rem !important',
    color: '#000',
  },
}))

const MdEditingDialog = (props) => {

  useEffect(() => {
    if (!props.meta?.columns) return
    let allAutoInputOptions: any = {}
    props.meta.columns
    .filter(colmeta => !!colmeta.lookup && colmeta.isAutocomplete && !colmeta.parentLookupField)
    .forEach(colmeta => {
      let options: any[] = buildAutoInputOptions(colmeta)
      allAutoInputOptions[colmeta.code] = options
    })
    setAutoInputOptions(allAutoInputOptions)
  }, [props.meta])

  const { t } = useTranslation()
  const classes = useStyles()

  const [editingItem, setEditingItem] = useState<any | null>(props.editingDataItem)
  const handleDetailFieldChange = (event, field, met?) => {
    let value = event.target.value
    // console.log(`event.target.value H${event.target.value}H`)
    // console.log(`event.target.checked ${event.target.checked}`)
    let clone = { ...editingItem }
    clone[field] = value
    if (!!met) {
      if (met.type === 'boolean' && !met.doNotRenderAsCheckBox) {
        value = event.target.checked
        clone[field] = value
      } else if (met.isAutocomplete) {
        if (met.isArray) {
          const codes = value.map(el => el.code)
          clone[field] = [...codes]
        } else {
          clone[field] = !!value ? value.code : ''
          if (!!met.featureTypeField) {
            if (!value) {
              clone[met.featureTypeField] = ''
            } else {
              clone[met.featureTypeField] = value.type
            }
          }
        }
      } else if (!!met.featureTypeField) {
        if (value === '') {
          clone[met.featureTypeField] = ''
          clone[field] = ''
        } else {
          let parts = value.split('___')
          clone[met.featureTypeField] = parts[0]
          clone[field] = parts[1]
        }
      } else if (met.isFeatureTypeField) {
        let fmet = props.meta.columns.find(c => c.featureTypeField === met.code)
        if (!!fmet) {
          clone[fmet.code] = ''
        }
      } else if (!!met.regexOnEntry) {
        console.log(`met.regex ${met.regexOnEntry}`)
        clone[field] = value.replace(met.regexOnEntry, '')
      }
      if (met.isParentLookupField) {
        let fmet = props.meta.columns.find(c => c.parentLookupField === met.code)
        if (!!fmet) {
          clone[fmet.code] = ''
        }
      }
    }
    // console.log(`clone[field] H${clone[field]}H`)
    // console.log(`clone ${JSON.stringify(clone,null,2)}`)
    setEditingItem(clone)
  }

  const [autoInputOptions, setAutoInputOptions] = useState({})
  const buildAutoInputOptions = (colmeta) => {
    let options: any[] = []
    colmeta.lookup.forEach(lookup => {
      if (!!props.lookups[lookup]) {
        let lookupField = colmeta.lookupField || 'code'
        let lookupFetch = colmeta.lookupFetch || ['label']
        if (lookupFetch.length > 0) {
          let lookoptions: any[] = []
          let lookupArr = props.lookups[lookup]
          if (colmeta.parentLookupField) {
            lookupArr = lookupArr.filter(look => look.parent === editingItem[colmeta.parentLookupField])
          }
          lookupArr.forEach(look => {
            let mkey = props.getLookupFld(look, lookupField)
            let lab = ''
            if (lookupFetch.length === 1) {
              lab = props.getLookupFld(look, lookupFetch[0])
            } else if (!colmeta.lookupTemplate) {
              lab = lookupFetch.map(f => props.getLookupFld(look, f)).join(' ')
            } else {
              lab = colmeta.lookupTemplate
              lookupFetch.forEach((f, i) => {
                lab = lab.replace(`f${i+1}`, props.getLookupFld(look, f))
              })
            }
            let o: any = {
              code: mkey,
              label: lab,
            };
            if (!!colmeta.featureTypeField) {
              let ft = look['properties']['featureType']
              o.type = ft
            }
            if (!!colmeta.lookupGroupByField) {
              o.type = look[colmeta.lookupGroupByField]
            }
            lookoptions.push(o)
          })
          if (!!colmeta.lookupGroupByField) {
            lookoptions.sort((a, b) => alphabeticalSorter(`${a.type}_${a.label}`, `${b.type}_${b.label}`))
          } else {
            lookoptions.sort((a, b) => alphabeticalSorter(a.label, b.label))
          }
          if (!!colmeta.lookupFilter && typeof colmeta.lookupFilter === 'function') {
            let originalItem = editingItem._id ? props.selectedDataItem : undefined //id this is a new item, then the props.selectedDataItem is irrelevant
            lookoptions = colmeta.lookupFilter(editingItem, originalItem, colmeta, lookoptions)
          }
          options = [...options, ...lookoptions]
        }
      }
    })

    return options
  }
  const filterAutoInputOptions = (alloptions, autoState, oval, colmeta) => {
    let options = alloptions
    // get filter text
    let autoFilter = autoState.inputValue.toLowerCase()
    // apply filter 
    if (!!autoFilter) {
      options = alloptions.filter(o => o.label.toLowerCase().indexOf(autoFilter) > -1)
    }
    // if options are still more than the AUTOCOMPLETE_OPTIONS_MAX_SIZE limit
    let useOptionsFiltering = alloptions.length > AUTOCOMPLETE_OPTIONS_MAX_SIZE
    if (useOptionsFiltering) {
      // get the first AUTOCOMPLETE_OPTIONS_MAX_SIZE remaining options
      options.splice(AUTOCOMPLETE_OPTIONS_MAX_SIZE)
      // append a disabled option that informs the user on the fact that not all optons are visible
      options.splice(0, 0, {code: '', label: `${t('md.autocomplete.tooManyOptions')}`, type: 'tooManyOptionsGroup'})
      // and then, if any selectons already exist which pass the filter condition but were cut by the previous trimming process,
      // add them to the visible options as well.
      if (colmeta.isArray) {
        oval.forEach(ovalElem => {
          if ((autoFilter === '' || ovalElem.label.toLowerCase().indexOf(autoFilter) > -1)
            && options.findIndex(o => o.code === ovalElem.code && (!colmeta.featureTypeField || o.type === ovalElem.type)) === -1)
          {
            options.splice(1, 0, ovalElem)
          }
        })
      } else {
        if (!!oval && (autoFilter === '' || oval.label.toLowerCase().indexOf(autoFilter) > -1)
          && options.findIndex(o => o.code === oval.code && (!colmeta.featureTypeField || o.type === oval.type)) === -1)
        {
          options.splice(1, 0, oval)
        }
      }
    }
    return options
  }

  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => (reason === 'backdropClick') ? null : props.handleCloseDetailDialog('no')}
    >
      <DialogTitle
        disableTypography={true}
        className={classes.title}
      >
        {!!editingItem._id ? t('md.edit.title') : t('md.add.title')}
      </DialogTitle>
      <DialogContent
        className={classes.content}
      >
        <List>
          {!!props.editingDataItem && props.meta.columns
          .filter(colmeta => {
            let editable
            if (typeof colmeta.editable === 'function') {
              editable = colmeta.editable(editingItem, colmeta.code, props.selectedDataItem)
            } else {
              editable = colmeta.editable
            }
            // console.log(`editable ${typeof editable} ${editable}`)
            return editable === true || editable === 'always'
              || (!!editingItem._id && editable === 'onEdit')
              || (!editingItem._id && editable === 'onAdd')
          })
          .map(colmeta => {

            let compo
            let colvalue = editingItem[colmeta.code]
            let groupBy: any

            if (!editingItem) {
              compo = null
            } else if (colmeta.type === 'boolean' && !colmeta.doNotRenderAsCheckBox) {
              compo = (
                <CheckBoxNt
                  checked={!!colvalue}
                  onChange={event => handleDetailFieldChange(event, colmeta.code, colmeta)}
                />
              )
            } else if (!!colmeta.lookup && colmeta.isAutocomplete) {
              let oval: any = colmeta.isArray ? [] : null
              let options: any[] = []
              if (colmeta.parentLookupField || colmeta.lookupFilter) {
                options = buildAutoInputOptions(colmeta)
              } else if (!autoInputOptions[colmeta.code]) {
                return compo
              } else {
                options = autoInputOptions[colmeta.code]
              }
              if (!!colmeta.featureTypeField) {
                let ftvalue = editingItem[colmeta.featureTypeField]
                if (!!ftvalue) {
                  options = options.filter(o => o.type === ftvalue)
                }
                groupBy = (option) => t(`net.${option.type}.plural`)
              }
              if (!!colmeta.lookupGroupByField) {
                groupBy = (option) => t(option[colmeta.lookupGroupByField])
              }
              if (colmeta.isArray) {

                // if fixed loolup values exist and are not already selected, do select them
                if (colmeta.lookupFixed) {
                  colmeta.lookupFixed.forEach(lfv => {
                    if (!colvalue.includes(lfv)) {
                      colvalue.push(lfv)
                    }
                  })
                }

                oval = options.filter(o => colvalue.includes(o.code))
              } else {
                oval = options.find(o => o.code === colvalue)
                if (!oval) oval = null
              }
              let renderTags: any
              if (colmeta.isArray && colmeta.lookupFixed) {
                renderTags = (value, getTagProps) => (
                  value.map((option, i) =>(
                    <Chip
                      key={option.code}
                      {...getTagProps({ index: i })}
                      label={option.label}
                      disabled={colmeta.lookupFixed.includes(option.code)}
                    />                  
                  ))
                )
              }
              compo = (
                <Autocomplete
                  clearOnEscape
                  multiple={colmeta.isArray}
                  disableCloseOnSelect={colmeta.isArray}
                  className={classes.lival}
                  classes={{inputRoot: classes.autoInputRoot, input: classes.autoInput, popper: classes.autoPopper}}
                  value={oval}
                  options={options}
                  renderTags={renderTags}
                  filterOptions={options.length < 100 ? undefined : (all, acstate) => filterAutoInputOptions(all, acstate, oval, colmeta)}
                  noOptionsText={t('md.autocomplete.noOptions')}
                  groupBy={groupBy}
                  getOptionSelected={(o, v) => o.code === v.code}
                  getOptionLabel={(option:any) => option?.label || ''}
                  renderInput={(params) => (<TextField {...params} variant="outlined" />)}
                  onChange={(event, newVal) => handleDetailFieldChange({target: {value: newVal}}, colmeta.code, colmeta)}
                />
              )
            } else if (!!colmeta.lookup && !colmeta.isAutocomplete) {
              let sval = colvalue
              if (!!colmeta.featureTypeField && !!colvalue) {
                // console.log(`editingItem ${JSON.stringify(editingItem,null,2)}`)
                let sft = editingItem[colmeta.featureTypeField]
                sval = `${sft}___${sval}`
              }
              let mitems:any[] = [
                <MenuItem key='none' value=''>
                  <em>{t('none-neutral')}</em>
                </MenuItem>
              ]
              let mitemsMap:any[] = []
              // console.log(`sval ${JSON.stringify(sval,null,2)}`)
              colmeta.lookup.forEach(lookup => {
                if (!!props.lookups[lookup]) {
                  let lookupField = colmeta.lookupField || 'code'
                  let lookupFetch = colmeta.lookupFetch || ['label']
                  if (lookupFetch.length > 0) {
                    let lookupArr = props.lookups[lookup]
                    if (colmeta.parentLookupField) {
                      lookupArr = lookupArr.filter(look => look.parent === editingItem[colmeta.parentLookupField])
                    }
                    lookupArr.forEach(look => {
                      let mkey = props.getLookupFld(look, lookupField)
                      let mval = mkey
                      if (!!colmeta.featureTypeField) {
                        let ft = look['properties']['featureType']
                        mval = `${ft}___${mkey}`
                        // console.log(`mval ${JSON.stringify(mval,null,2)} mval === sval ${mval === sval}`)
                      }
                      // console.log(`mkey ${mkey} mkey === colvalue ${mkey === colvalue}`)
                      let lab = ''
                      if (lookupFetch.length === 1) {
                        lab = props.getLookupFld(look, lookupFetch[0])
                      } else if (!colmeta.lookupTemplate) {
                        lab = lookupFetch.map(f => props.getLookupFld(look, f)).join(' ')
                      } else {
                        lab = colmeta.lookupTemplate
                        lookupFetch.forEach((f, i) => {
                          lab = lab.replace(`f${i+1}`, props.getLookupFld(look, f))
                        })
                      }
                      mitemsMap.push({
                        key: lab,
                        item: (<MenuItem key={mkey} value={mval}>{lab}</MenuItem>)
                      })
                    })
                  }
                }
              })
              mitemsMap.sort((a, b) => alphabeticalSorter(a.key, b.key))
              mitems = mitems.concat(mitemsMap.map(m => m.item))
              compo = (
                <FormControl className={classes.lival}>
                  <Select
                    variant="outlined"
                    displayEmpty
                    classes={{root: classes.input,}}
                    value={sval}
                    // onChange={props.onChange}
                    onChange={event => handleDetailFieldChange(event, colmeta.code, colmeta)}
                  >
                    {mitems}
                  </Select>
                </FormControl>
              )
            } else if (['date', 'datetime', 'string', 'number', 'posnumber'].includes(colmeta.type)) {
              let val = colvalue
              if (colmeta.type === 'date') {
                // val = !!val ? moment(colvalue).format(t('date.format.pickers') as string) : null
                val = !!val ? moment(colvalue) : null
              } else if (colmeta.type === 'datetime') {
                // val = !!val ? moment(colvalue).format(t('datetime.format.pickers') as string) : null
                val = !!val ? moment(colvalue) : null
              } else if (colmeta.type === 'number' || colmeta.type === 'posnumber') {
                if (editingItem[colmeta.code] === 0) {
                  val = 0
                }
              }
              if (colmeta.type === 'date') {
                compo = (
                  <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DatePicker
                      autoOk
                      // disableToolbar
                      variant='inline'
                      inputVariant='outlined'
                      className={classes.lival}
                      inputProps={{
                        className: classes.input,
                      }}
                      // KeyboardButtonProps={{
                      //   className: classes.inputKeyboard,
                      // }}
                      emptyLabel={t('date.format.emptyLabel') as string}
                      format={t('date.format') as string}
                      value={val}
                      disableFuture={!!colmeta.disableFuture}
                      onChange={date => handleDetailFieldChange({target: {value: date}}, colmeta.code)}
                    />
                  </MuiPickersUtilsProvider>
                )
              } else if (colmeta.type === 'datetime') {
                compo = (
                  <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DateTimePicker
                      autoOk
                      // disableToolbar
                      hideTabs={true}
                      ampm={false}
                      disableFuture={!!colmeta.disableFuture}
                      variant='inline'
                      // showTodayButton//only with variant dialog
                      inputVariant='outlined'
                      className={classes.lival}
                      inputProps={{
                        className: classes.input,
                      }}
                      // KeyboardButtonProps={{
                      //   className: classes.inputKeyboard,
                      // }}
                      emptyLabel={t('datetime.format.emptyLabel') as string}
                      format={t('datetime.format') as string}
                      value={val}
                      onChange={date => handleDetailFieldChange({target: {value: date}}, colmeta.code)}
                    />
                  </MuiPickersUtilsProvider>
                )
              } else if (colmeta.customEditCompo) {
                compo = colmeta.customEditCompo({meta: colmeta, editedItem: editingItem, setEditingItem: setEditingItem})
              } else if (colmeta.multiline) {
                compo = (
                  <TextField
                    variant='outlined'
                    type={colmeta.type === 'posnumber' ? 'number' : colmeta.type}
                    className={classes.lival}
                    inputProps={{
                      className: classes.input,
                    }}
                    multiline={colmeta.multiline}
                    minRows={colmeta.minRows}
                    InputProps={{classes: {multiline: classes.multiline}}}
                    value={val}
                    onChange={event => handleDetailFieldChange(event, colmeta.code, colmeta)}
                    helperText={!!colmeta.helperText ? colmeta.helperText : null}
                  />
                )
              } else {
                compo = (
                  <TextField
                    variant='outlined'
                    type={colmeta.type === 'posnumber' ? 'number' : colmeta.type}
                    className={classes.lival}
                    inputProps={{
                      className: classes.input,
                    }}
                    value={val}
                    onChange={event => handleDetailFieldChange(event, colmeta.code, colmeta)}
                    helperText={!!colmeta.helperText ? colmeta.helperText : null}
                  />
                )
              }
            }
            // return compo

            return (
            <ListItem key={colmeta.code} className={classes.li}>
              <Box className={classes.lilab}>
                {`${t(colmeta.label)}${!!colmeta.required ? ' *' : ''}`}
              </Box>
              {compo}
            </ListItem>
          )})}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          className={classes.cancelBtn}
          onClick={() => props.handleCloseDetailDialog('no')}
        >
          {t('btn.cancel')}
        </Button>
        <Button
          disabled={props.isSavingEdits}
          className={classes.applyBtn}
          onClick={() => props.handleCloseDetailDialog('yes', editingItem)}
        >
          {props.isSavingEdits && (
            <CircularProgress className={classes.loadingBtn} />)}
          {t('btn.update')}
        </Button>
      </DialogActions>
    </Dialog>
  )

}

export default MdEditingDialog
