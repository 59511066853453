import React, { useEffect } from 'react'
import { useCollectionStore } from '../state/collectionStore'
import { useNetworkElementStore } from '../state/networkElementStore'
import SwitchSlotService from '../services/SwitchSlotService'
const useLandParcelMeasurements = (landParcelCode: string) => {

  // typeof(landParcelCode) === 'string' ? [landParcelCode] : landParcelCode
  const cultivations = useCollectionStore(state => state.cultivations)
  const switchSlots = useCollectionStore(state => state.switchSlots)
  const switches = useNetworkElementStore(state => state.switches)
  const switchSlotService = SwitchSlotService()
  // console.log(switchSlots)
  /**Fetch the switchSlots if they are not fetched yet */
  useEffect( ()=>{
    if (!switchSlots || switchSlots?.length === 0 ) {
      const getSlots = async ()=> await switchSlotService.getEntities()
      getSlots()
    }
  },[switchSlots?.length])
  
  if (!landParcelCode) {
    return {
      _cultivations: [],
      _switches: [],
      _hourMeters: [],
    }
  }
 
  const connectedCultivations = cultivations?.filter(
    c => c.landparcelCode === landParcelCode
  )


  let landParcelSwitchesSet = new Set()
  connectedCultivations?.forEach(cc =>
    landParcelSwitchesSet.add(cc.irrigationPointCode)
  )
  const landParceSwitches = Array.from(landParcelSwitchesSet)
    .map(switchCode => {
      return switches?.find(el => {
        return el.properties.code === switchCode
      })
    })
    .filter(sw => !!sw)
  if (!landParcelCode) {
    return {
      _cultivations: [],
      _switches: [],
      _hourMeters: [],
    }
  }
  let connectedHourMetersSet = new Set()
  connectedCultivations?.forEach(c =>
    // switchSlots?.find(slot=>slot.code === c.hourmeterCode)
    connectedHourMetersSet.add(c.hourmeterCode)
  )

  const connectedHourMeters = Array.from(connectedHourMetersSet)
    .map(slotCode => switchSlots?.find(slot => slot.code === slotCode))
    .filter(sw => !!sw)

  return {
    _cultivations: connectedCultivations,
    _switches: landParceSwitches,
    _hourMeters: connectedHourMeters || [],
  }
}
export default useLandParcelMeasurements
