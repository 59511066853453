const MeasurementTypesService = (apiClient?) => {

    const path = 'measurementTypes/'

    const getMeasurementTypes = () => {
      let fullpath = path

      let cachePromise = true
      return apiClient.sendGetRequest(cachePromise, fullpath, null, true);
    }
    const getMeasurementTypeById = (type_id) => {
      let fullpath = `${path}${type_id}`
      let cachePromise = true
      return apiClient.sendGetRequest(cachePromise, fullpath, null, true);
    }

    return({
        getMeasurementTypes: getMeasurementTypes,
        getMeasurementTypeById: getMeasurementTypeById,
    })
}

export default MeasurementTypesService
