import {create} from 'zustand'
import { devtools } from 'zustand/middleware'
import { Profile } from '../utils/types/collectionStoreTypes'
import { AgriculturalPeriod } from '../utils/types/collectionStoreTypes'

interface CommonStore {
  profile: Profile | undefined
  /**When the agriculturalPeriods are loaded from the server the period with the property `isVisible:true`
   * is treated as the `currentAgriculturalPeriod`, it becomes visible for the user 
   * all irrigation elements cultivations,scheds,progs,etc that are loaded in the app will be from that period's year (property `cultivationPeriod` is used for the filtering)
   * Programmatically the given period(currentAgriculturalPeriod) gets the runtime property `viewAsCurrent:true`)
   * This can be either a period with the status `active` or `completed` 
   * if the status is `active` the user can interact with the elements (eg add new cultivations, link/unlink hydrometers) 
   * if the status is `completed` then the period is view Only (some functionalities like create schedules,cultivations etc are locked)
   * ⚠️ Alternatively when the user `switches over` to a past period(`historic` period from past years) from the `System Settings` -> `Agricultural Periods` -> `Switch Over`
   * then the currentAgriculturalPeriod becomes the period that the user switched to, it gets the `isVisible:true` status, etc
   * when the user views a `historic` period, then almost all irrigation functionalities are  locked, the user can only view the data */
  currentAgriculturalPeriod: AgriculturalPeriod | undefined
  showAlert: (message: string, severity?: 'E' | 'W' | 'S' | 'I') => void
  accessGranted: (right: string) => boolean
  apiClient: any
  locale: 'el' | 'en' | undefined
  setProfile: (profile: Profile) => void
  setShowAlert: (showAlert: any) => void
  setCurrentAgriculturalPeriod: (agriculturalPeriod: AgriculturalPeriod | undefined) => void
  setAccessGranted: (accessGranted: any) => void
  setApiClient: (apiClient: any) => void
  setLocale: (locale: 'el' | 'en' | undefined) => void
}

const useCommonStore = create<CommonStore>()(
  devtools(
    set => ({
      profile: undefined,
      currentAgriculturalPeriod: undefined,
      showAlert: (m: string, s?: string) => {
        console.log(m, s)
      }, //just a placeholder function so that typescript doesn't complain about invoking a possibly undefined function
      accessGranted: (r: string) => {
        return false
      }, //placeholder
      apiClient: null,
      locale: undefined,
      setProfile: profile => set(state => ({ profile: profile })),
      setShowAlert: showAlert => set(state => ({ showAlert: showAlert })),
      setCurrentAgriculturalPeriod: agriculturalPeriod => set(state => ({ currentAgriculturalPeriod: agriculturalPeriod })),
      setAccessGranted: accessGrantedImplementation => set(state => ({ accessGranted: accessGrantedImplementation })),
      setApiClient: apiClient => set(state => ({ apiClient: apiClient })),
      setLocale: locale => set(state => ({ locale: locale })),
    }),
    {
      name: 'commonStore',
      enabled: process.env.NODE_ENV === 'development' ? true : false,
    }
  )
)

export { useCommonStore }
