import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import ConfirmationDialogMobile from './ConfirmationDialogMobile'
import { useUiStore } from '../../state/uiStore'
import { MobileScreens } from '../../utils/types/commonTypes'
import BellIcon from './svgIcons/BellIcon'
import GearIcon from './svgIcons/GearIcon'
import IrrigationIcon from './svgIcons/IrrigationIcon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      height: 'auto',
      maxHeight: 'calc(100dvh - 64px)',
    },
    menuItem: {
      marginLeft: '65px',
    },
    listRoot: {
      overflowY: 'clip',
      maxHeight: 'calc(100dvh - 112px)',
    },
    selectedMenuItem: {
      // textDecoration: 'underline cyan 8px',
      color: theme.palette.primary.main,
    },
    drawerRoot: {
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        height: 'auto',
        minWidth: '70vw',
        marginTop: '56px', //appBar height
        maxHeight: 'calc(100dvh - 64px)',
      },
    },
    paper: {
      borderRadius: '0 8px 8px 0',
      overflowY: 'clip',
    },
    listItemTextPrimary: {
      ...theme.typography.h3,
    },
    listItemTextSecondary: {
      ...theme.typography.body2,
    },
    listItemIcon: {
      minWidth: '45px',
    },
  })
)

interface NavDrawerMobileProps {
  isOpen: boolean
  toggleDrawer: (open: boolean) => void
  logOut: (action: string) => void
}
export default function NavDrawerMobile({ isOpen, toggleDrawer, logOut }: NavDrawerMobileProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const currentScreen = useUiStore(state => state.contentKeyLev2)
  const setCurrentScreen = useUiStore(state => state.setContentKeyLev2)
  const previousScreens = useUiStore(state => state.previousScreens)
  const setPreviousScreens = useUiStore(state => state.setPreviousScreens)
  const [dialogOpen, setDialogOpen] = useState(false)
  /**Set the filterNotificationsBySelectedPump to false on each navigation to newNotificationsMobile & readNotificationsMobile */
  const setFilterNotificationsBySelectedElement = useUiStore(state => state.setFilterNotificationsBySelectedElement)

  const handleDrawerNavigation = (navKey: string) => {
    switch (navKey) {
      case MobileScreens.automationCenterMobile:
        toggleDrawer(false)
        setPreviousScreens(previousScreens.concat(MobileScreens[currentScreen]))
        setCurrentScreen(MobileScreens.automationCenterMobile)
        break
      case MobileScreens.newNotificationsMobile:
        setFilterNotificationsBySelectedElement(false)
        toggleDrawer(false)
        setPreviousScreens(previousScreens.concat(MobileScreens[currentScreen]))
        setCurrentScreen(MobileScreens.newNotificationsMobile)
        break
      case MobileScreens.readNotificationsMobile:
        setFilterNotificationsBySelectedElement(false)
        toggleDrawer(false)
        setPreviousScreens(previousScreens.concat(MobileScreens[currentScreen]))
        setCurrentScreen(MobileScreens.readNotificationsMobile)
        break
      case MobileScreens.userSettingsMobile:
        toggleDrawer(false)
        setPreviousScreens(previousScreens.concat(MobileScreens[currentScreen]))
        setCurrentScreen(MobileScreens.userSettingsMobile)
        break
      case 'logout':
        toggleDrawer(false)
        setDialogOpen(true)
        break
      default:
        toggleDrawer(false)
        break
    }
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }
  const handleLogOut = () => {
    logOut('logout')
  }

  const list = () => (
    <Box role='presentation'>
      <List className={classes.listRoot}>
        {/* ACCOUNT */}
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            <IrrigationIcon style={{ width: '24', height: '24' }} />
          </ListItemIcon>
          <ListItemText primary={t('mvb.irrigation')} classes={{ primary: classes.listItemTextPrimary }} />
        </ListItem>

        <ListItem
          button
          onClick={() => handleDrawerNavigation(MobileScreens.automationCenterMobile)}
          className={classes.menuItem}
        >
          <ListItemText
            primary={t('irriControlRoom')} //: " Κέντρο αυτοματισμού
            classes={{ primary: classes.listItemTextSecondary }}
            className={
              [MobileScreens.automationCenterMobile, 'pumpManagementMobile', 'pumpSettingsMobile'].includes(
                currentScreen
              )
                ? classes.selectedMenuItem
                : ''
            }
          />
        </ListItem>
        {/* Notifications */}
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            <BellIcon style={{ width: '24', height: '24' }} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary={t('cp.notifications')} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleDrawerNavigation(MobileScreens.newNotificationsMobile)}
          className={classes.menuItem}
        >
          <ListItemText
            primary={t('notifications.unread')} // Νέες ειδοποιήσεις
            classes={{ primary: classes.listItemTextSecondary }}
            className={currentScreen === MobileScreens.newNotificationsMobile ? classes.selectedMenuItem : ''}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => handleDrawerNavigation(MobileScreens.readNotificationsMobile)}
          className={classes.menuItem}
        >
          <ListItemText
            primary={t('notifications.read')} // Διαβασμένες ειδοποιήσεις
            classes={{ primary: classes.listItemTextSecondary }}
            className={currentScreen === MobileScreens.readNotificationsMobile ? classes.selectedMenuItem : ''}
          />
        </ListItem>
        {/* Settings */}
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            <GearIcon style={{ width: '24', height: '24' }} />
          </ListItemIcon>
          <ListItemText primary={t('mvb.settings')} classes={{ primary: classes.listItemTextPrimary }} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleDrawerNavigation(MobileScreens.userSettingsMobile)}
          className={classes.menuItem}
        >
          <ListItemText
            primary={t('mvb.userSettings')} // Ρυθμίσεις Χρήστη
            classes={{ primary: classes.listItemTextSecondary }}
            className={currentScreen === MobileScreens.userSettingsMobile ? classes.selectedMenuItem : ''}
          />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleDrawerNavigation('logout')} className={classes.menuItem}>
          <ListItemText
            classes={{ primary: classes.listItemTextPrimary }}
            primary={t('signOut')} // "signOut"
          />
        </ListItem>
      </List>
    </Box>
  )

  return (
    <div>
      <Drawer
        classes={{
          paper: classes.paper,
          root: classes.drawerRoot,
        }}
        className={classes.drawerPaper}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        anchor='left'
      >
        {list()}
      </Drawer>
      {/* Confirm LogOut Dialog */}
      {dialogOpen && (
        <ConfirmationDialogMobile
          dialogOpen={dialogOpen}
          title='confirm.logout'
          handleButtonNo={handleCloseDialog}
          handleButtonYes={handleLogOut}
        />
      )}
    </div>
  )
}
