import { useCommonStore } from "../state/commonStore"

const NotificationService = (apiClient?) => {
  if (!apiClient) {
    apiClient = useCommonStore.getState().apiClient
  }

  const path = 'notification/'

  const getNotifications = (filter?, options?) => {
    let data: any = {
      operation: 'getNotifications',
    }
    if (!!filter) {
      data.filter = filter
    }
    if (!!options) {
      data.options = options
    }
    return sendRequest(data)
  }

  const saveNotification = (notification) => {
    let data: any = {
      notification: notification,
    }
    let isNewNotification = !notification._id
    if (isNewNotification) {
      data.operation = 'saveNotification'
    } else {
      data.operation = 'updateNotification'
    }
    return sendRequest(data)
  }
  const deleteNotification = (notification) => {
    let data = {
      operation: 'deleteNotification',
      notification: notification,
    }
    return sendRequest(data)
  }

  const saveNotificationNote = (notification, note) => {
    let data: any = {
      notification_id: notification._id,
      note: note,
    }
    let isNewNote = !note._id
    if (isNewNote) {
      data.operation = 'addNotificationNote'
    } else {
      data.operation = 'updateNotificationNote'
    }
    return sendRequest(data)
  }
  const deleteNotificationNote = (notification, note) => {
    let data: any = {
      operation: 'deleteNotificationNote',
      notification_id: notification._id,
      note: note,
    }
    return sendRequest(data)
  }

  const sendRequest = (data) => {
    // console.log(`NotificationService sendRequest data${JSON.stringify(data,null,2)}`)
    return apiClient.sendRequest(data, false, path, null, true)
  }

  return({
    getNotifications: getNotifications,
    saveNotification: saveNotification,
    deleteNotification: deleteNotification,
    saveNotificationNote: saveNotificationNote,
    deleteNotificationNote: deleteNotificationNote,
  })

}

export default NotificationService
