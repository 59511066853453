import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Tooltip } from '@material-ui/core/'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'

import TextNt from './TextNt'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelContainer: {
      maxWidth: 170,
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
    tooltip: {
      maxWidth: 220,
      maxHeight: 650,
      padding: '8px 14px 6px',
      borderRadius: '8px',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      whiteSpace: 'pre-wrap',
    },
    infoTooltip: {
      maxWidth: 300,
      maxHeight: 650,
      padding: '6px 12px 4px',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.custom.grey2,
    },
    arrow: {
      color: theme.palette.primary.main,
      fontSize: 18,
    },
    infoContainer: {
      display: 'flex',
      alignSelf: 'center',
      marginLeft: '0.5rem',
    },
    infoContainerLeft: {
      display: 'flex',
      alignSelf: 'center',
      marginRight: '0.5rem',
    },
  })
)

interface LabelNtProps {
  /** Content of the label */
  labelText: string
  /** Content of the label tooltip */
  tipText?: string
  /** Content of the info icon tooltip */
  infoText?: string
  /** label tooltip placement, defaults to `top` */
  tipPlacement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined
  /** weather info icon appears on the left or right side of the label, defaults to `right` */
  infoPlacement?: 'left' | 'right'
  /** info icon tooltip placement, defaults to `right` */
  infoTipPlacement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined
  /** InlineStyle for the label */
  labelStyle?: any
  /** Info Icon size, defaults to `small` */
  infoFontSize?: 'inherit' | 'medium' | 'default' | 'large' | 'small' | undefined
  /** override label's mui box container className */
  labelClassName?: any,
  /** override tooltip's mui classes */
  tooltipClasses?: any,
  /** override info tooltip's mui classes */
  infoTooltipClasses?: any,
}

/** keep tooltip popup open for debug (e.g. css tinkering) */
// const DEBUG: true | undefined = true
const DEBUG: true | undefined = undefined

/** Renders a label that may optionally have a tooltip and/or an `info` icon with (another) tooltip */
export const LabelNt = ({
  labelText = '',
  tipText,
  infoText,
  tipPlacement = 'top',
  infoPlacement = 'right',
  infoTipPlacement = 'right',
  labelStyle = {},
  infoFontSize = 'small',
  labelClassName,
  tooltipClasses,
  infoTooltipClasses,
}: LabelNtProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box
      display='flex'
      flexDirection={infoPlacement === 'right' ? 'row' : 'row-reverse'}
      alignItems='center'
    >
      {!!tipText ? (
      <Tooltip
        open={DEBUG}//for css debug
        arrow={true}
        classes={tooltipClasses ? {...{ tooltip: classes.tooltip, arrow: classes.arrow }, ...tooltipClasses} : { tooltip: classes.tooltip, arrow: classes.arrow }}
        placement={tipPlacement}
        title={<TextNt variant='caption'>{t(tipText)}</TextNt>}
      >
        <Box className={labelClassName ? clsx (classes.labelContainer, labelClassName) : classes.labelContainer}>
          <TextNt variant='h3' style={labelStyle}>{t(labelText)}</TextNt>
        </Box>
      </Tooltip>
      ) : (
      <Box className={labelClassName ? clsx (classes.labelContainer, labelClassName) : classes.labelContainer}>
        <TextNt variant='h3' style={labelStyle}>{t(labelText)}</TextNt>
      </Box>
      )}
      {!!infoText && (
      <Tooltip
        open={DEBUG}//for css debug
        arrow={true}
        classes={infoTooltipClasses ? {...{ tooltip: classes.infoTooltip, arrow: classes.arrow }, ...infoTooltipClasses} : { tooltip: classes.infoTooltip, arrow: classes.arrow }}
        placement={infoTipPlacement}
        title={<TextNt variant='caption'>{t(infoText)}</TextNt>}
      >
        <Box className={infoPlacement === 'right' ? classes.infoContainer : classes.infoContainerLeft}>
          <InfoRoundedIcon color='primary' fontSize={infoFontSize} />
        </Box>
      </Tooltip>
      )}
    </Box>
  )
}

export default LabelNt
