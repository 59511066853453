import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Box, Tooltip } from '@material-ui/core/'
import TextNt from './TextNt'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleTooltip: {
      padding: '0px 8px',
      color: theme.palette.custom.grey1,
    },
    contentContainer: {
      padding: theme.spacing(1),
      display: 'flex',
    },
    mainTextContainer: {
      display: 'flex',
      width: '100%',
      marginLeft: '8px',
      textAlign: 'left',
    },
    infoIcon: {
      height: '1.2rem',
      width: '1.2rem',
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.custom.grey2,
      maxWidth: 300,
      maxHeight: 650,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '8px',
      minWidth: '100px',
      minHeight: '30px',
      padding: '0.25rem',
    },
    iconContainer: {
      display: 'flex',
      alignSelf: 'center',
      marginLeft: '0.5rem',
    },
    arrow: {
      color: theme.palette.primary.main,
      fontSize: 18,
    },
  })
)

interface InfoNtProps {
  title: string
  content: string
}
const TooltipContent = ({ title, content }: InfoNtProps) => {
  const classes = useStyles()
  return (
    <>
      {title && (
        <TextNt variant='h3' className={classes.titleTooltip}>
          {title}
        </TextNt>
      )}
      <Box className={classes.contentContainer}>
        <TextNt variant='caption'>
          {content}
        </TextNt>
      </Box>
    </>
  )
}

interface InfoProps {
  /** Title of the Tooltip(on hover) and description text(label) beside the icon */
  title?: string
  /** Content of the Tooltip */
  content: string
  /** Tooltip placement, defaults to `right` */
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined
  /** The text that will be rendered next to the tooltip icon  */
  label?: string
  /** InlineStyle for the label */
  labelStyle?: any
  /**weather label appears on the left or right side of the icon, defaults to `right` */
  labelPlacement?: 'left' | 'right'
  /**Info Icon size, defaults to `small` */
  fontSize?: 'inherit' | 'medium' | 'default' | 'large' | 'small' | undefined
}

/** Renders an `info` icon that has a styled `<Tooltip/>`, optionally you can add a label next to the `info` icon */
export const InfoNt = ({
  title,
  content,
  placement = 'right',
  label = '',
  labelStyle = {},
  labelPlacement = 'right',
  fontSize = 'small',
}: InfoProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box display='flex'>
      {labelPlacement === 'left' && !!label && (
        <Box className={classes.mainTextContainer}>
          <TextNt style={labelStyle}>{t(label)}</TextNt>
        </Box>
      )}
      <Tooltip
        arrow={true}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        placement={placement}
        title={<TooltipContent title={title ? t(title) : ''} content={content ? t(content) : ''} />}
      >
        <Box className={classes.iconContainer}>
          <InfoRoundedIcon color='primary' fontSize={fontSize} />
        </Box>
      </Tooltip>
      {labelPlacement === 'right' && !!label && (
        <Box className={classes.mainTextContainer}>
          <TextNt style={labelStyle}>{t(label)}</TextNt>
        </Box>
      )}
    </Box>
  )
}

export default InfoNt
