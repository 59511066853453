import React, { useState } from 'react'
import { useCommonStore } from '../state/commonStore';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Icon from '@material-ui/core/Icon'
import Avatar from '@material-ui/core/Avatar'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { useAuth0 } from "../react-auth0-spa"
import { useCollectionStore } from '../state/collectionStore'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular as number,
  },
  table: {
  },
  large: {
    width: theme.spacing(19),
    height: theme.spacing(19),
    alignSelf: 'center',
  },
}))

const Profile = (props) => {
  const profile = useCommonStore(state => state.profile)
  const classes = useStyles()
  const { t } = useTranslation();

  const roles = useCollectionStore(state => state.roles)
  const rights = useCollectionStore(state => state.rights)
  const tenants = useCollectionStore(state => state.tenants)

  const { loading, user } = useAuth0()

  const [open1, setOpen1] = useState(true)
  const [open2, setOpen2] = useState(true)
  const [open3, setOpen3] = useState(true)
  const [open4, setOpen4] = useState(true)

  if (loading || !user) {
    return <div>Loading...</div>
  }
  console.log(`profile: ${JSON.stringify(profile,null,2)}`)
  return (
    <div className={classes.root}>
      <Avatar className={classes.large} style={{ fontSize: '4.5rem' }} >{profile?.auth0User.name.split(' ').map(n => n.substring(0,1)).join('')}</Avatar>
      {/*
      <Avatar alt={profile.auth0User.name} src={profile.auth0User.picture} className={classes.large} />
      {profilePicture ? profilePicture : <Avatar alt="Remy Sharp" src={profile.auth0User.picture} className={classes.large} />}
      {profilePicture}
      <Accordion expanded={open1} onChange={() => setOpen1(!open1)}>
      */}
      <Accordion expanded={open1} onChange={() => setOpen1(!open1)}>
        <AccordionSummary
          expandIcon={<Icon className={'fas fa-chevron-down ' + classes.heading} />}
          aria-controls="panel1a-content"
          id="panel1-header"
        >
          <Typography className={classes.heading}>Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow key='name'>
                <TableCell component="th" scope="row">Name</TableCell>
                <TableCell align="right">{profile?.auth0User.name}</TableCell>
              </TableRow>
              <TableRow key='user_id'>
                <TableCell component="th" scope="row">user_id</TableCell>
                <TableCell align="right">{profile?.user_id}</TableCell>
              </TableRow>
              <TableRow key='createdAt'>
                <TableCell component="th" scope="row">Created At</TableCell>
                <TableCell align="right">{profile?.createdAt}</TableCell>
              </TableRow>
              <TableRow key='updatedAt'>
                <TableCell component="th" scope="row">Updated At</TableCell>
                <TableCell align="right">{profile?.updatedAt}</TableCell>
              </TableRow>
              <TableRow key='email'>
                <TableCell component="th" scope="row">email</TableCell>
                <TableCell align="right">{profile?.auth0User.email}</TableCell>
              </TableRow>
              <TableRow key='email_verified'>
                <TableCell component="th" scope="row">Verified email</TableCell>
                <TableCell align="right">{profile?.auth0User.email_verified ? t('btn.yes') : t('btn.no')}</TableCell>
              </TableRow>
              <TableRow key='provider'>
                <TableCell component="th" scope="row">ID provider</TableCell>
                <TableCell align="right">{profile?.auth0User.identities[0].provider}</TableCell>
              </TableRow>
              <TableRow key='username'>
                <TableCell component="th" scope="row">Username</TableCell>
                <TableCell align="right">{profile?.auth0User.username}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={open2} onChange={() => setOpen2(!open2)}>
        <AccordionSummary
          expandIcon={<Icon className={'fas fa-chevron-down ' + classes.heading} />}
          aria-controls="panel2a-content"
          id="panel2-header"
        >
          <Typography className={classes.heading}>Tenants</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
            {profile?.tenants.map(f => (
              <TableRow key={f}>
                <TableCell component="th" scope="row">{tenants?.find(p => p.code === f)?.label}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={open3} onChange={() => setOpen3(!open3)}>
        <AccordionSummary
          expandIcon={<Icon className={'fas fa-chevron-down ' + classes.heading} />}
          aria-controls="panel3a-content"
          id="panel3-header"
        >
          <Typography className={classes.heading}>Roles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
            {profile?.roles.map(f => (
              <TableRow key={f.role}>
                <TableCell component="th" scope="row">{roles?.find(p => p.code === f.role)?.label}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={open4} onChange={() => setOpen4(!open4)}>
        <AccordionSummary
          expandIcon={<Icon className={'fas fa-chevron-down ' + classes.heading} />}
          aria-controls="panel3a-content"
          id="panel4-header"
        >
          <Typography className={classes.heading}>Access Rights</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
            {profile?.deducedRights.map(f => (
              <TableRow key={f.right}>
                <TableCell component="th" scope="row">{rights?.find(p => p.code === f.right)?.label}</TableCell>
                <TableCell align="right">[from {f.sources.join()}]</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Profile
